import { IReport, ISignerReport } from '@literax/models/reports.model';

export interface IReportState {
  data_results: IReport[];
  signers: ISignerReport[];
  month_filter: number;
  year_filter: number;
  user_department_filter: number;
  departments_filter: number[];
  companies_filter: string[];
  signers1_filter: string[];
  signers2_filter: string[];
}

export const initialReportState: IReportState = {
  data_results: [],
  signers: [],
  month_filter: (new Date()).getMonth() + 1,
  year_filter: (new Date()).getFullYear(),
  user_department_filter: 0,
  departments_filter: [],
  companies_filter: [],
  signers1_filter: [],
  signers2_filter: []
};
