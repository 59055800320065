import * as TokenRSAActions from './token-rsa.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  ITokenRSAConfigurations,
  initialTokenRSAConfigurationsState,
} from './token-rsa.state';

const reducer = createReducer(
  initialTokenRSAConfigurationsState,
  on(TokenRSAActions.SaveHeaders, (state, action) => ({
    ...state,
    tokenRSA: {
      ...state.tokenRSA,
      httpHeaders: action.headers,
    },
  })),
  on(TokenRSAActions.SaveForms, (state, action) => ({
    ...state,
    tokenRSA: {
      ...state.tokenRSA,
      httpPayload: {
        ...state.tokenRSA.httpPayload,
        formContent: action.forms,
      },
    },
  })),
  on(TokenRSAActions.GetSuccess, (state, action) => ({
    ...state,
    tokenRSA: action.tokenRSA,
  })),
  on(TokenRSAActions.PutSuccess, (state, action) => ({
    ...state,
    tokenRSA: action.tokenRSA,
  }))
);

export function tokenRSAConfigurationsReducer(
  state: ITokenRSAConfigurations,
  action: Action
) {
  return reducer(state, action);
}
