<nz-table 
    #fixedTable
    [nzScroll]="{ x: '100vh' }"
    [nzData]="rowsData"
    nzTableLayout="fixed"
    [nzShowPagination]="false"
>
    <thead>
        <tr>
            <th *ngFor="let header of headers"
                [nzWidth]="'15rem'"
                [nzSortOrder]="header.sort.sortOrder"
                (nzSortOrderChange)="sortOrderChange($event, header.sort.columnName)"
                scope="col"
            >
                {{ header.header }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let rowData of rowsData">
            <td *ngFor="let header of headers"
            >
                {{ rowData[header.header] }}
            </td>
        </tr>
    </tbody>
</nz-table>
