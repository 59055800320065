<div class="col-2">
  <label class="hoverable" for="fileInput">
    <img *ngIf="urlImage" [src]="urlImage" />
    <div *ngIf="!urlImage" class="initial">
      <span class="text">{{ name | nameInitials }}</span>
    </div>
    <div class="hover-text">
      <i class="icon far fa-pencil-alt"></i>
      <span class="block-boton"></span
      >{{ 'CONFIGURATIONS_MODULE.PROFILES.BUTTONS.EDIT' | translate }}
    </div>
    <div class="background"></div>
  </label>
  <input
    id="fileInput"
    class="space-12"
    type="file"
    accept=".jpeg,.jpg,.png"
    (change)="onSelectFile($event)"
  />
</div>
