import { HttpErrorResponse } from '@angular/common/http';
import { ITabSigner } from '@literax/components/platform/promissory-note-configuration/promissory-note-configuration.component';
import { ServiceResponse } from '@literax/interfaces/service-response';
import {
  IAddMeToSigner,
  IAPISignRequest,
  IAPISignRequestMessageConfig,
  ICreateSignRequestResponse,
  SignRequestOrder
} from '@literax/models/http/api/sign-request/sign-request.model';
import { createAction, props } from '@ngrx/store';
import { IUser } from '../../../../components/configurations/users/models/users.interface';
import { ISignRequest } from '../document/document.state';

export const error = createAction(
  '[SignRequest Component] error',
  props<{ error: any; typeAction: string }>()
);

export enum ESignRequestActions {
  CreateSignRequest = '[SignRequest] Create SR',
  UpdateSignRequest = '[SignRequest] Update SR',
  UpdateSignRequestsOrder = '[SignRequest] UpdateOrder SR',
  UpdateSignRequestsOrderAfterDelete = '[SignRequest] UpdateOrderAfterDelete SR',
  UpdateSignRequestsOrderSuccess = '[SignRequest] UpdateOrder Success SR',
  DeleteSignRequest = '[SignRequest] Delete SR',
  DeleteMultipleSignRequest = '[SignRequest] Delete Multiple SR',
  IndexSignRequest = '[SignRequest] Index SR',
  IndexSignRequestSuccess = '[SignRequest] Index SR Success',
  CreateSignRequestSuccess = '[SignRequest] Create SR Success',
  DeleteSignRequestSuccess = '[SignRequest] Delete SR Success',
  CreateSignRequestError = '[SignRequest] Create SR Error',
  UpdateSignRequestError = '[SignRequest] Update SR Error',
  UpdateSignRequestsError = '[SignRequest] Updates SR Error',
  DeleteSignRequestError = '[SignRequest] Delete SR Error',
  CleanSignRequestFormStates = '[SignRequest] Clean SR Form States',
  InitializeSignRequestFormStates = '[SignRequest] Initialize SR Form States',
  SendSignRequestNotification = '[SignRequest] Send SR Notification',
  SendSignRequestNotificationSuccess = '[SignRequest] Send SR Notification Success',
  AddMeToSigners = '[SignRequest] Add me to SR',
  AddMeToSignersSuccess = '[SignRequest] Add me to SR Success',
  AddMeToSignersError = '[SignRequest] Add me to SR Error',
  GetContacts = '[SignRequest] Get contacts',
  GetContactsResponse = '[SignRequest] Get contacts response',

  CreateLegalRepresentatives = '[SignRequest] Create legal representative',
  CreateLegalRepresentativesSuccess = '[SignRequest] Create legal representative success',
  CreateLegalRepresentativesError = '[SignRequest] Create legal representative error',

  UpdateLegalRepresentatives = '[SignRequest] Update legal representative',
  UpdateLegalRepresentativesSuccess = '[SignRequest] Update legal representative success',
  UpdateLegalRepresentativesError = '[SignRequest] Update legal representative error',

  CreateNaturalPerson = '[SingRequest] Create Natural Person',
  CreateNaturalPersonSuccess = '[SingRequest] Create Natural Person Success',
  CreateNaturalPersonError = '[SingRequest] Create Natural Person error',

  UpdateNaturalPerson = '[SignRequest] Update natural person',
  UpdateNaturalPersonSuccess = '[SignRequest] Update natural person success',
  UpdateNaturalPersonError = '[SignRequest] Update natural person error',

  GetHistoryEmail = '[SignRequest] GetHistoryEmail',
  GetHistoryEmailResponse = '[SignRequest] GetHistoryEmailResponse',

  GetCollaborators = '[SignRequest] GetCollaborators',
  GetCollaboratorsSuccess = '[SignRequest] GetCollaboratorsSuccess',
  GetCollaboratorsError = '[SignRequest] GetCollaboratorsError',

  ChangeExpiryAndIdOfficialPN = '[SignRequest] change expiry and id official promissoryNote',
  ChangeExpiryAndIdOfficialPNSuccess = '[SignRequest] change expiry and id official promissoryNote success',
  ChangeExpiryAndIdOfficialPNError = '[SignRequest] change expiry and id official promissoryNote error'
}

export const AddMeToSigners = createAction(
  ESignRequestActions.AddMeToSigners,
  props<{ payload: IAddMeToSigner }>()
);

export const AddMeToSignersSuccess = createAction(
  ESignRequestActions.AddMeToSignersSuccess,
  props<{
    payload: { request: IAddMeToSigner; response: ICreateSignRequestResponse };
  }>()
);

export const AddMeToSignersError = createAction(
  ESignRequestActions.AddMeToSignersError,
  props<{ payload: HttpErrorResponse }>()
);

export const GetSignRequests = createAction(
  ESignRequestActions.IndexSignRequest,
  props<{ payload: { id: number; page: number; sort?: string } }>()
);

export const GetSignRequestsSuccess = createAction(
  ESignRequestActions.IndexSignRequestSuccess,
  props<{ payload: IAPISignRequest }>()
);

export const CreateSignRequest = createAction(
  ESignRequestActions.CreateSignRequest,
  props<{ payload: IAPISignRequest | IAPISignRequest[] }>()
);

export const createNaturalPerson = createAction(
  ESignRequestActions.CreateNaturalPerson,
  props<{
    payload: {
      documentId: number;
      naturalPerson: IAPISignRequest;
      withSignatureQuotes?: boolean;
    };
  }>()
);

export const createNaturalPersonSuccess = createAction(
  ESignRequestActions.CreateNaturalPersonSuccess
);

export const createNaturalPersonError = createAction(
  ESignRequestActions.CreateNaturalPersonError,
  props<{ payload: HttpErrorResponse }>()
);

export const UpdateNaturalPerson = createAction(
  ESignRequestActions.UpdateNaturalPerson,
  props<{ payload: IAPISignRequest }>()
);

export const UpdateNaturalPersonSuccess = createAction(
  ESignRequestActions.UpdateNaturalPersonSuccess
);

export const UpdateNaturalPersonError = createAction(
  ESignRequestActions.UpdateNaturalPersonError,
  props<{ payload: HttpErrorResponse }>()
);

export const UpdateSignRequest = createAction(
  ESignRequestActions.UpdateSignRequest,
  props<{ payload: IAPISignRequest | IAPISignRequest[] }>()
);

export const UpdateSignRequestsOrder = createAction(
  ESignRequestActions.UpdateSignRequestsOrder,
  props<{ payload: SignRequestOrder[] }>()
);

export const UpdateSignRequestsOrderAfterDelete = createAction(
  ESignRequestActions.UpdateSignRequestsOrderAfterDelete,
  props<{ payload: SignRequestOrder[] }>()
);

export const UpdateSignRequestsOrderSuccess = createAction(
  ESignRequestActions.UpdateSignRequestsOrderSuccess
);

export const CreateSignRequestSuccess = createAction(
  ESignRequestActions.CreateSignRequestSuccess,
  props<{
    payload: {
      uuid: string;
      payload: ICreateSignRequestResponse | ICreateSignRequestResponse[];
    };
  }>()
);

export const CreateSignRequestError = createAction(
  ESignRequestActions.CreateSignRequestError,
  props<{ payload: HttpErrorResponse }>()
);

export const UpdateSignRequestsError = createAction(
  ESignRequestActions.UpdateSignRequestsError,
  props<{ payload: HttpErrorResponse }>()
);

export const CleanSignRequestFormStates = createAction(
  ESignRequestActions.CleanSignRequestFormStates
);

export const InitializeSignRequestFormStates = createAction(
  ESignRequestActions.InitializeSignRequestFormStates,
  props<{ payload: ISignRequest[] }>()
);

export const DeleteSignRequest = createAction(
  ESignRequestActions.DeleteSignRequest,
  props<{ payload: { documentId: number; payload: IAPISignRequest } }>()
);

export const DeleteMultipleSignRequest = createAction(
  ESignRequestActions.DeleteMultipleSignRequest,
  props<{ payload: IAPISignRequest }>()
);

export const DeleteSignRequestSuccess = createAction(
  ESignRequestActions.DeleteSignRequestSuccess,
  props<{ payload: { uuid: string; payload: ICreateSignRequestResponse } }>()
);

export const SendSignRequestNotification = createAction(
  ESignRequestActions.SendSignRequestNotification,
  props<{
    payload: { documentId: number; payload: IAPISignRequestMessageConfig[] };
  }>()
);

export const SendSignRequestNotificationSuccess = createAction(
  ESignRequestActions.SendSignRequestNotificationSuccess,
  props<{ payload: ICreateSignRequestResponse }>()
);

export const GetContacts = createAction(
  ESignRequestActions.GetContacts,
  props<{ payload: string }>()
);

export const GetContactsResponse = createAction(
  ESignRequestActions.GetContactsResponse,
  props<{ payload: ServiceResponse }>()
);

export const CreateLegalRepresentatives = createAction(
  ESignRequestActions.CreateLegalRepresentatives,
  props<{ payload: IAPISignRequest[] }>()
);

export const CreateLegalRepresentativesSuccess = createAction(
  ESignRequestActions.CreateLegalRepresentativesSuccess,
  props<{
    payload: { uuid: string; payload: ICreateSignRequestResponse | [] };
  }>()
);

export const CreateLegalRepresentativesError = createAction(
  ESignRequestActions.CreateLegalRepresentativesError,
  props<{ payload: HttpErrorResponse }>()
);

export const UpdateLegalRepresentatives = createAction(
  ESignRequestActions.UpdateLegalRepresentatives,
  props<{ payload: IAPISignRequest | IAPISignRequest[] }>()
);

export const UpdateLegalRepresentativesSuccess = createAction(
  ESignRequestActions.UpdateLegalRepresentativesSuccess,
  props<{ payload: ICreateSignRequestResponse | [] }>()
);

export const UpdateLegalRepresentativesError = createAction(
  ESignRequestActions.UpdateLegalRepresentativesError,
  props<{ payload: HttpErrorResponse }>()
);

export const GetHistoryEmail = createAction(
  ESignRequestActions.GetHistoryEmail,
  props<{ payload: { id: number; payload: IAPISignRequest } }>()
);

export const GetHistoryEmailResponse = createAction(
  ESignRequestActions.GetHistoryEmailResponse,
  props<{ payload: IAPISignRequest }>()
);

export const GetCollaborators = createAction(
  ESignRequestActions.GetCollaborators,
  props<{ payload: boolean }>()
);
export const GetCollaboratorsSuccess = createAction(
  ESignRequestActions.GetCollaboratorsSuccess,
  props<{ payload: IUser[] }>()
);

export const ChangeExpiryAndIdOfficialPN = createAction(
  ESignRequestActions.ChangeExpiryAndIdOfficialPN,
  props<{ payload: { body: ITabSigner; documentId: number } }>()
);

export const ChangeExpiryAndIdOfficialPNSuccess = createAction(
  ESignRequestActions.ChangeExpiryAndIdOfficialPNSuccess
);

export const ChangeExpiryAndIdOfficialPNError = createAction(
  ESignRequestActions.ChangeExpiryAndIdOfficialPNError
);
