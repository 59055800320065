<div class="tab-container">
  <div
    class="tab"
    [ngClass]="activeTab == -1 ? 'active' : ''"
    (click)="onTabClicked(-1, -1)"
  >
    <p>{{ 'STATUS.TABS.ALL' | translate }}</p>
  </div>
  <div
    *ngFor="let tab of tabs; let i = index"
    class="tab"
    [ngClass]="activeTab == i ? 'active' : ''"
    (click)="onTabClicked(i, tab.id)"
  >
    <p>{{ tab.name }}</p>
  </div>
</div>
<div class="main-container">
  <ng-content></ng-content>
</div>
