import { saveAs } from 'file-saver';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EDeviceType } from '@literax/enums/application.enum';
import { IAppState } from '@literax/store';
import { Store, select } from '@ngrx/store';
import { Attachment, XmlValidationResponse } from '../../validator.interfaces';
import { ValidatedFilesResponse } from '../../store/validator.actions';
import { TranslateService } from '@ngx-translate/core';

enum ETabTypes {
  FILES = 0,
  COLLABORATORS,
  INFO,
  DOCUMENT,
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-xml-validation-documents',
  templateUrl: './xml-validation-documents.component.html',
  styleUrls: ['./xml-validation-documents.component.scss'],
})
export class XmlValidationDocumentsComponent implements OnInit, OnDestroy {
  isVisible = false;
  tabs = ETabTypes;
  currentTab: ETabTypes = ETabTypes.FILES;
  currentTabIndex: number;
  tabOptions: Array<{ key: ETabTypes; icon: string; label: string }> = [
    {
      key: ETabTypes.DOCUMENT,
      icon: 'fal fa-file',
      label: 'XML_SCREEN.TABS.DOCUMENT.TITLE',
    },
    {
      key: ETabTypes.FILES,
      icon: 'fal fa-paperclip',
      label: 'XML_SCREEN.TABS.ATTACHMENTS.TITLE',
    },
    {
      key: ETabTypes.COLLABORATORS,
      icon: 'fal fa-users',
      label: 'XML_SCREEN.TABS.COLLABORATORS.TITLE',
    },
    {
      key: ETabTypes.INFO,
      icon: 'fal fa-info-circle',
      label: 'XML_SCREEN.TABS.INFO.TITLE',
    },
  ];
  deviceTypes = EDeviceType;
  deviceType: EDeviceType;
  isDesktopDevice = true;
  document: XmlValidationResponse;
  mainAttachment: Attachment;
  currentAttachment: Attachment;
  attachments: Attachment[];
  verifiedAttachments: Attachment[];

  currentLang: string;

  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLang = this.translateService.currentLang;
    });

    this.store
      .pipe(select((state) => state.validator.XMLValidatedDocument))
      .subscribe((data) => {
        if (!data) {
          this.router.navigate(['/validator/xml']);
        } else {
          this.document = data;
          this.isVisible = true;
          this.verifiedAttachments = this.document?.attachments.filter(
            (attachment) => attachment.verified
          );
          this.currentAttachment = [...[], ...this.verifiedAttachments].shift();
          this.mainAttachment = this.document?.attachments.find(
            (attachment) => attachment.primary === true
          );
          this.attachments = this.document?.attachments.filter(
            (attachment) => attachment.primary !== true
          );
        }
      });

    this.store
      .pipe(
        select((state) => ({
          isDesktopDevice: state.core.isDesktop,
          deviceType: state.core.deviceType,
        }))
      )
      .subscribe(({ isDesktopDevice, deviceType }) => {
        this.deviceType = deviceType;
        this.isDesktopDevice = isDesktopDevice;
        this.setCurrentTab(
          deviceType === EDeviceType.DESKTOP
            ? ETabTypes.FILES
            : ETabTypes.DOCUMENT
        );
      });
  }
  ngOnDestroy() {}

  downloadFile() {
    saveAs(this.document.verifiedPath, this.document.name);
  }

  setCurrentTab(tabKey: ETabTypes) {
    this.currentTab = tabKey;
    this.currentTabIndex = this.tabOptions.findIndex(
      (tab) => tab.key === this.currentTab
    );
  }

  viewVerifiedAttachment(attachment: Attachment) {
    if (attachment.verified) {
      if (this.isDesktopDevice === false) {
        this.currentTab = this.tabs.DOCUMENT;
        this.currentTabIndex = this.tabOptions.findIndex(
          (tab) => tab.key === this.currentTab
        );
      }

      this.currentAttachment = this.verifiedAttachments.find(
        (a) => a.id === attachment.id
      );
    }
  }
}
