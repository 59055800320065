<div class="validator-selector">
  <div nz-row>
    <div nz-col nzSpan="24">
      <h4 class="validator-selector-title">
        {{ 'MAIN_SCREEN.HEADLINE' | translate }}
      </h4>
    </div>
  </div>
  <div nz-row [nzGutter]="[32, 32]">
    <div nz-col nzMd="24" nzLg="12">
      <nz-card
        [nzTitle]="'MAIN_SCREEN.OPTIONS.UUID.TITLE'|translate"
        [nzActions]="[actionSetting, actionUuid, actionEllipsis]"
      >
        <nz-card-meta
          [nzDescription]="'MAIN_SCREEN.OPTIONS.UUID.MESSAGE'|translate"
        ></nz-card-meta>
      </nz-card>

      <ng-template #actionSetting> </ng-template>
      <ng-template #actionUuid>
        <button nz-button nzType="primary" routerLink="uuid">
          {{ 'MAIN_SCREEN.ACTION_BUTTON' | translate }}
        </button>
      </ng-template>
      <ng-template #actionEllipsis> </ng-template>
    </div>
    <div nz-col nzMd="24" nzLg="12">
      <nz-card
        [nzTitle]="'MAIN_SCREEN.OPTIONS.XML.TITLE'|translate"
        [nzActions]="[actionSetting, actionXml, actionEllipsis]"
      >
        <nz-card-meta
          [nzDescription]="'MAIN_SCREEN.OPTIONS.XML.MESSAGE'|translate"
        ></nz-card-meta>
      </nz-card>

      <ng-template #actionSetting> </ng-template>
      <ng-template #actionXml>
        <button nz-button nzType="primary" routerLink="xml">
          {{ 'MAIN_SCREEN.ACTION_BUTTON' | translate }}
        </button>
      </ng-template>
      <ng-template #actionEllipsis> </ng-template>
    </div>
  </div>
</div>
