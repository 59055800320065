import * as documentActions from './document.actions';
import * as signatureActions from '@literax/modules/documents/store/signature/signature.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  EDocumentStatus,
  EDocumentUpdatePaths,
} from '@literax/enums/document.enum';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import {
  IDocumentResponse,
  IDocumentViewSignaturesResponse,
} from '@literax/models/document.model';
import { Store, select } from '@ngrx/store';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { AppUtils } from '@literax/utils/app.utils';
import { AssignmentModalSuccessComponent } from '../../../shared/assignment-dialog/assignment-dialog.component';
import {
  CurrentClientV3
} from '../../../configurations/store/client/client.selectors';
import { DashboardService } from '@literax/services/platform/dashboard.service';
import { DocumentActions } from '../workspace/actions/document.actions';
import { DocumentService } from '@literax/services/document/document.service';
import { DocumentsService } from '@literax/services/platform/documents.service';
import { ErrorHandlerService } from '@literax/modules/shared/services/error-handler/error-handler.service';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IAppState } from '../../../../store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { ICreateDocumentResponse } from '@literax/models/http/document/create-document.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISearch } from '../../../../models/http/document/document.model';
import { Injectable } from '@angular/core';
import { LoadingService } from '@literax/services/loading/loading.service';
import { Location } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { ServiceResponse } from '../../../../interfaces/service-response';
import { SetDocumentAttachmentBase64Data } from './document.actions';
import { TranslateService } from '@ngx-translate/core';
import { selectCurrentLang } from '@literax/modules/configurations/store/config/config.selectors';
import { selectDocument } from './document.selectors';

@Injectable()
export class DocumentEffects {
  getDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        documentActions.GetDocuments,
        documentActions.SetFilterParams
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.queryParameters)
      ),
      distinctUntilChanged(),
      exhaustMap(([action, queryParameters]) => {
        return this.docService.getDocuments(
          queryParameters.pageNumber,
          queryParameters.pageSize,
          queryParameters.type,
          queryParameters.show,
          queryParameters.sortField,
          queryParameters.filterFields
        );
      }),
      map((response: any) => {
        const res = response;
        return documentActions.GetDocumentsSuccess({
          documents: res.body,
          pagination: {
            nextPage: res.headers.get('origon-paged-next-page'),
            prevPage: res.headers.get('origon-paged-prev-page'),
            currentPage: res.headers.get('origon-paged-current-page'),
            sizePage: res.headers.get('origon-paged-size-page'),
            totalRecords: res.headers.get('origon-paged-total-records'),
            pages: res.headers.get('origon-paged-pages'),
          },
        });
      })
    )
  );

  getDocumentsHidden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        documentActions.DocumentsHidden,
        documentActions.DocumentsHiddenTypeSuccess
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.pagination?.currentPage)
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.queryParameters)
      ),
      withLatestFrom(
        this.store$.select((state) => state.documents.currentSort)
      ),
      withLatestFrom(this.store$.select((state) => state.documents.hidden)),

      switchMap(([[[[action, page], filter], sort], hidden]) => {
        return this.docService.getDocuments(
          filter.pageNumber,
          filter.pageSize,
          filter.type,
          filter.show,
          filter.sortField,
          filter.filterFields
        );
      }),
      map((response: any) => {
        const res = response;
        return documentActions.GetDocumentsSuccess({
          documents: res.body,
          pagination: {
            nextPage: res.headers.get('origon-paged-next-page'),
            prevPage: res.headers.get('origon-paged-prev-page'),
            currentPage: res.headers.get('origon-paged-current-page'),
            sizePage: res.headers.get('origon-paged-size-page'),
            totalRecords: res.headers.get('origon-paged-total-records'),
            pages: res.headers.get('origon-paged-pages'),
          },
        });
      })
    )
  );

  downloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DownloadDocument),
      switchMap((action) =>
        this.documentService.getDocumentUrlForDownload(action.payload.id).pipe(
          map((response: ServiceResponse) =>
            documentActions.SaveBlobDocument({ payload: response.document })
          ),
          catchError((err: HttpErrorResponse) => {
            this.getLanguage();
            forkJoin([
              this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.BODY'),
              this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.TITLE'),
            ]).subscribe(([message, title]) => {
              this.toastr.error(message, title);
            });
            return of(null);
          })
        )
      )
    )
  );

  saveBlobDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.SaveBlobDocument),
        switchMap((action) =>
          this.documentService.getBlob(action.payload.url).pipe(
            tap((blob: Blob) => {
              const extension = AppUtils.extensionFromMimeType(blob.type);
              this.documentService.saveDownloadFileAs(
                blob,
                `${action.payload.name}.${extension}`
              );
            }),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.BODY'),
                this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(null);
            })
          )
        )
      ),
    { dispatch: false }
  );

  downloadDocuments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DownloadDocuments),
        withLatestFrom(
          this.store$.select((state) => state.documents.queryParameters)
        ),
        switchMap(([action, filter]) =>
          this.docService
            .exportListDocuments(
              filter.pageNumber,
              filter.pageSize,
              filter.type,
              filter.show,
              filter.sortField,
              filter.filterFields
            )
            .pipe(
              tap((response: Blob) =>
                this.documentService.saveDownloadFileAs(
                  response,
                  'documents.xlsx'
                )
              )
            )
        ),
        catchError((err: HttpErrorResponse) => {
          this.getLanguage();
          forkJoin([
            this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.BODY'),
            this.translate.get('DOCUMENTS.ERROR.DOWNLOAD.TITLE'),
          ]).subscribe(([message, title]) => {
            this.toastr.error(message, title);
          });
          return of(null);
        })
      ),
    { dispatch: false }
  );

  markAs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DocumentMarkAs),
        withLatestFrom(this.store$.select(selectDocument)),
        switchMap(([action, currentDocument]) =>
          this.documentService.markAs(currentDocument.id, action.payload).pipe(
            map((response: ServiceResponse) => {
              this.getLanguage();
              return documentActions.DocumentMarkAsSuccess({
                payload: response.document as IDocument,
              });
            }),
            catchError((response) => {
              this.getLanguage();
              this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                let message = '';
                response.error.error.detail?.status?.forEach(
                  (data) =>
                    (message =
                      message +
                      data.charAt(0).toUpperCase() +
                      data.slice(1) +
                      '. ')
                );
                response.error.error.detail?.signing_method?.forEach(
                  (data) =>
                    (message =
                      message +
                      data.charAt(0).toUpperCase() +
                      data.slice(1) +
                      '. ')
                );
                this.toastr.error(
                  response.error.error.detail
                    ? message
                    : response.error.error.title,
                  title
                );
              });
              return of(
                documentActions.DocumentMarkAsError({ payload: response })
              );
            }),
            finalize(() => {
              this.loading.hide();
            })
          )
        )
      ),
    { dispatch: true }
  );

  setDocumentMinApprovers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentSetMinApprovers),
      withLatestFrom(this.store$.select(selectDocument)),
      switchMap(([action, currentDocument]) =>
        this.documentService
          .setMinApprovers(currentDocument.id, action.payload)
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetDocumentSuccess({
                payload: response.document as IDocument,
              })
            ),
            catchError((response) => {
              this.getLanguage();
              this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                this.toastr.error(
                  response.error.error.detail['signing_method'][0],
                  title
                );
              });
              return of(null);
            })
          )
      )
    )
  );

  markAsReviewed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DocumentMarkAsReviewed),
        withLatestFrom(this.store$.select(selectDocument)),
        switchMap(([action, currentDocument]) =>
          this.documentService
            .markAsReviewed(
              currentDocument.id,
              action.payload.action,
              action.payload.sign_request
            )
            .pipe(
              map((_: ServiceResponse) =>
                documentActions.GetDocument({ payload: currentDocument.id })
              ),
              catchError((response) => {
                this.getLanguage();
                this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                  this.toastr.error(
                    response.error.error.detail['signing_method'][0],
                    title
                  );
                });
                return of(null);
              })
            )
        )
      ),
    { dispatch: true }
  );

  updateSelectedDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        documentActions.UpdateSelectedDocument,
        signatureActions.RejectSignatureSuccess
      ),
      withLatestFrom(this.store$.select(selectDocument)),
      map(([action, currentDocument]) => {
        return documentActions.GetDocument({
          payload: currentDocument && (currentDocument as IDocument).id,
        });
      })
    )
  );

  UpdateSelectedDocumentHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UpdateSelectedDocumentHierarchy),
      switchMap((action) =>
        this.documentService
          .updateSelectedDocumentHierarchy(
            action.payload.documentId,
            action.payload.hierarchy
          )
          .pipe(
            map((response: ServiceResponse) => {
              return documentActions.GetDocument({
                payload: action.payload.documentId,
              });
            }),
            catchError(() => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(null);
            })
          )
      )
    )
  );

  updateFreeDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.UpdateFreeDocument),
        tap(() => this.router.navigate(['/signed']))
      ),
    { dispatch: false }
  );

  updateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UpdateDocument),
      filter((action) => action.payload != null),
      switchMap((action) =>
        this.documentService
          .updateDocument(action.payload.documentId, action.payload.document)
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetDocumentSuccess({
                payload: response.document as IDocument,
              })
            ),

            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(
                documentActions.GetDocumentError({
                  payload: err.error as IAPIResponseError,
                })
              );
            })
          )
      )
    )
  );

  getDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetDocument),
      filter((action) => action.payload != null),
      withLatestFrom(this.store$.select(CurrentClientV3)),
      switchMap(([action, client]) => {
        return this.documentService.getDocumentDetail(action.payload).pipe(
          map((response: ServiceResponse) =>
            documentActions.GetDocumentSuccess({
              payload: response.document as IDocument,
            })
          ),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 404) {
              this.router.navigate(['platform', 'home']);
            }
            return of(
              documentActions.GetDocumentError({
                payload: err.error as IAPIResponseError,
              })
            );
          })
        );
      })
    )
  );

  getViewDocumentSignatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetViewDocumentSignatures),
      switchMap((action) => {
        return this.documentService
          .getViewDocumentSignatures(action.payload)
          .pipe(
            map((response) =>
              documentActions.GetViewDocumentSignaturesSuccess({
                payload: response as IDocumentViewSignaturesResponse,
              })
            ),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 404) {
                this.router.navigate(['platform', 'home']);
              }
              return of(
                documentActions.GetViewDocumentSignaturesError({
                  payload: err.error as IAPIResponseError,
                })
              );
            })
          );
      })
    )
  );

  // ! Deprecated: change effect for createDocumentV3$
  createDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.CreateDocument),
      switchMap((action) =>
        this.documentService.createDocument(action.payload).pipe(
          map((response: ICreateDocumentResponse) =>
            documentActions.CreateDocumentSuccess({ payload: response })
          ),
          catchError((err: HttpErrorResponse) => {
            return of(
              documentActions.CreateDocumentError({ payload: err.error })
            );
          })
        )
      )
    )
  );

  // ! Deprecated: change effect for CreateDocumentV3Success$
  CreateDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.CreateDocumentSuccess),
        tap((action) =>
          this.router.navigate([
            `platform/workspace/${action.payload.document.transaction_type}/${action.payload.document.id}`,
          ])
        )
      ),
    { dispatch: false }
  );

  // TODO: Remove suffix V3
  createDocumentV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.CreateDocumentV3),
      switchMap((action) =>
        this.docService.createDocument(action.payload).pipe(
          map((response: IDocumentResponse) =>
            documentActions.CreateDocumentV3Success({ payload: response })
          ),
          catchError((err) =>
            this._handleErrorAndShowModal(
              err,
              documentActions.CreateDocumentV3Error({ payload: err })
            )
          )
        )
      )
    )
  );

  // TODO: Remove suffix V3
  CreateDocumentV3Success$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.CreateDocumentV3Success),
        tap((action) =>
          this.router.navigate(
            [
              `platform/documents/${action.payload.flowName}/${action.payload.id}`,
            ],
            { replaceUrl: true }
          )
        )
      ),
    { dispatch: false }
  );

  updateAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.EditAttachment),
      switchMap((action) =>
        this.docService
          .replaceAttachment(
            action.documentId,
            action.attachmentId,
            action.promissory
          )
          .pipe(
            map((response: IDocumentResponse) =>
              documentActions.EditAttachmentSuccess({ payload: response })
            ),
            catchError((err: HttpErrorResponse) =>
              of(documentActions.EditAttachmentError({ payload: err.error }))
            )
          )
      )
    )
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DeleteDocument),
      switchMap((action) =>
        this.documentService.deleteDocument(action.payload.documentId).pipe(
          map((response: ServiceResponse) =>
            documentActions.DeleteDocumentSuccess()
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              documentActions.DeleteDocumentError({ payload: err.error.error })
            )
          )
        )
      )
    )
  );

  deleteDocumentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DeleteDocumentList),
      tap(() => {
        this.loading.show();
      }),
      switchMap((action) =>
        this.docService
          .process(action.payload.documentId, { action: 'delete' })
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.DeleteDocumentListSuccess()
            ),
            catchError((err: HttpErrorResponse) =>
              of(
                documentActions.DeleteDocumentError({
                  payload: err.error.error,
                })
              )
            ),
            finalize(() => {
              this.getLanguage();
              forkJoin([
                this.translate.get('TOAST_NOTIFICATIONS.DELETE_SUCCESS'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([messageSuccess, title]) => {
                this.toastr.success(messageSuccess, title);
              });
              this.loading.hide();
            })
          )
      )
    )
  );

  getDocumentAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetDocumentAttachment),
      withLatestFrom(this.store$.select(selectDocument)),
      tap(() => {
        this.loading.show();
      }),
      switchMap(([action, currentDocument]) =>
        this.documentService
          .getDocumentAttachment(
            action.payload.documentId,
            action.payload.attachmenId
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetDocumentAttachmentSuccess({
                payload: response.attachment as IAttachment,
              })
            ),
            tap((actionType) => {
              if (
                actionType.type ===
                documentActions.EDocumentActions.GetDocumentAttachmentSuccess
              ) {
                const loadedAttachment = currentDocument?.attachments.find(
                  (attachment) => attachment.id === actionType.payload.id
                );
                if (
                  (loadedAttachment.text === null &&
                    loadedAttachment?.id === action.payload.attachmenId) ||
                  (loadedAttachment.id !== action.payload.attachmenId &&
                    loadedAttachment.text === null)
                ) {
                  const attachment = actionType.payload;
                  this.httpClient
                    .get(attachment?.file_path, {
                      reportProgress: true,
                      observe: 'events',
                      responseType: 'blob',
                    })
                    .subscribe((response) => {
                      if (response.type === HttpEventType.Response) {
                        const file_reader = new FileReader();
                        file_reader.onloadend = (ev) => {
                          if (
                            ev.loaded &&
                            typeof file_reader.result === 'string'
                          ) {
                            this.store$.dispatch(
                              SetDocumentAttachmentBase64Data({
                                payload: {
                                  attachmentId: attachment.id,
                                  data: file_reader.result,
                                },
                              })
                            );
                          }
                        };
                        file_reader.readAsDataURL(response.body);
                      }
                    });
                }
              }
            }),
            catchError((err: HttpErrorResponse) => {
              this.loading.hide();
              return of(
                documentActions.GetDocumentAttachmentError({
                  payload: err.error.error,
                })
              );
            }),
            finalize(() => {
              this.loading.hide();
            })
          )
      )
    )
  );

  DeleteDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DeleteDocumentSuccess),
        withLatestFrom(
          this.store$.select((state) => state.documents.queryParameters)
        ),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  updateDocumentSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.UpdateDocumentSelected),
      filter((action) => action.payload != null),
      switchMap((action) =>
        this.documentService
          .updateDocumentSelected(
            action.payload.documentId,
            action.payload.document
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.UpdateDocumentSelectedSuccess()
            ),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(
                documentActions.GetDocumentError({
                  payload: err.error as IAPIResponseError,
                })
              );
            })
          )
      )
    )
  );

  getSearchDocumentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetNavbarDocument),
      switchMap((action) =>
        this.documentService.getSearchDocument(action.payload).pipe(
          map((response: ServiceResponse) =>
            documentActions.GetNavbarDocumentSuccess({
              payload: response as ISearch[],
            })
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              documentActions.GetNavbarDocumentError({
                payload: err?.error?.error,
              })
            )
          )
        )
      )
    )
  );

  getDocumentHiddeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentsHiddenType),
      switchMap((action) =>
        this.docService
          .updateDocument(action.documentId, [
            {
              op: 'replace',
              path: EDocumentUpdatePaths.HIDDEN,
              value: action.hidden,
            },
          ])
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.DocumentsHiddenTypeSuccess()
            ),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(documentActions.DocumentsHiddenTypeError());
            })
          )
      )
    )
  );

  documentMarkAsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentMarkAsList),
      switchMap((action) =>
        this.documentService
          .markAs(action.payload.id, action.payload.action)
          .pipe(
            map((response: ServiceResponse) => {
              if (action.payload.action === EDocumentStatus.CANCEL_SIGNATURE) {
                this.store$.dispatch(
                  documentActions.ClearDocumentAttachmentsBase64Data()
                );
              }
              return documentActions.DocumentMarkAsSuccess({
                payload: response.document as IDocument,
              });
            }),
            catchError((response) => {
              this.getLanguage();
              this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                this.toastr.error(
                  response.error.error?.detail?.signing_method
                    ? response.error.error.detail['signing_method'][0]
                    : response.error.error.title,
                  title
                );
              });
              return of(
                documentActions.DocumentMarkAsError({ payload: response })
              );
            })
          )
      )
    )
  );

  MarkAsApproved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentActions.DocumentMarkAsApproved),
        withLatestFrom(this.store$.select(selectDocument)),
        switchMap(([action, currentDocument]) =>
          this.documentService
            .markAsApproved(
              currentDocument.id,
              action.payload.action,
              action.payload.sign_request
            )
            .pipe(
              map((_: ServiceResponse) =>
                documentActions.GetDocument({ payload: currentDocument.id })
              ),
              catchError((response) => {
                this.getLanguage();
                this.translate.get('DOCUMENTS.TITLE').subscribe((title) => {
                  this.toastr.error(
                    response.error.error.detail['signing_method'][0],
                    title
                  );
                });
                return of(null);
              })
            )
        )
      ),
    { dispatch: true }
  );

  DocumentCreateAssigment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentCreateAssigment),
      switchMap((action) =>
        this.documentService
          .createAssignment(action.payload.documentId, action.payload.receiver)
          .pipe(
            map((response: any) => {
              this.modalService.create({
                nzWidth: 800,
                nzContent: AssignmentModalSuccessComponent,
                nzClosable: false,
                nzFooter: null,
                nzComponentParams: {
                  userName: action.payload.userName,
                },
              });

              return DocumentActions.getDocumentSuccess({
                payload: { document: response },
              });
            }),
            catchError((err) =>
              this._handleErrorAndShowModal(
                err,
                documentActions.GetDocumentError({ payload: err })
              )
            )
          )
      )
    )
  );

  hideLoad$ = createEffect(
    () =>
      this.actions$.pipe(ofType(documentActions.UpdateDocumentSelectedSuccess)),
    { dispatch: false }
  );

  CancelDocumentProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.DocumentCancelProcess),
      withLatestFrom(this.store$.select(selectDocument)),
      switchMap(([action, currentDocument]) =>
        this.documentService
          .documentCancelProcess(currentDocument.id, action.payload)
          .pipe(
            map(() => documentActions.DocumentCancelProcessSuccess()),
            catchError(() => of(documentActions.DocumentCancelProcessError()))
          )
      )
    )
  );

  GetCancellationReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetCancellationReasons),
      withLatestFrom(this.store$.select(selectDocument)),
      exhaustMap(([action, currentDocument]) =>
        this.documentService.getCommentRejected(currentDocument.id).pipe(
          map((response) =>
            documentActions.GetCancellationReasonsSuccess({
              payload: response.cancellation_reasons,
            })
          ),
          catchError(() => of(documentActions.GetCancellationReasonsError()))
        )
      )
    )
  );

  saveDateGenerate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.SaveDateGenerate),
      filter((action) => action.payload != null),
      switchMap((action) =>
        this.documentService
          .saveDateGenerate(
            action.payload.documentId,
            action.payload.attachmentId,
            action.payload.attachment
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.SaveDateGenerateSuccess()
            ),
            catchError((err: HttpErrorResponse) => {
              this.getLanguage();
              forkJoin([
                this.translate.get('DOCUMENTS.DOCUMENTS_GENERATE_ERROR'),
                this.translate.get('DOCUMENTS.TITLE'),
              ]).subscribe(([message, title]) => {
                this.toastr.error(message, title);
              });
              return of(
                documentActions.SaveDateGenerateError({
                  payload: err.error as IAPIResponseError,
                })
              );
            })
          )
      )
    )
  );

  getPendingMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentActions.GetPendingMessages),
      exhaustMap((action) =>
        this.documentService
          .getPendingMessages(
            action.payload.chat,
            action.payload.app,
            action.payload.token
          )
          .pipe(
            map((response: ServiceResponse) =>
              documentActions.GetPendingMessagesSuccess({
                payload: response.pending_seend,
              })
            ),
            catchError((err: HttpErrorResponse) => {
              return of(
                documentActions.GetPendingMessagesError({
                  payload: err.error || err.message,
                })
              );
            })
          )
      )
    )
  );

  getAcumulatedStatusses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        documentActions.GetDocumentAcumulatedStatusses,
        documentActions.SetFlowId
      ),
      withLatestFrom(this.store$.select((state) => state.documents.flowId)),
      withLatestFrom(this.store$.select((state) => state.documents.type)),
      switchMap(([[action, flowId], type]) => {
        return this.documentService
          .getAcumulatedStatusses({
            flowId,
            type,
            filterParams: action.payload.filters,
            show: action.payload.show,
          })
          .pipe(
            map((res) =>
              documentActions.GetDocumentAcumulatedStatussesSuccess({
                payload: res,
              })
            ),
            catchError((error) => of(error))
          );
      })
    )
  );

  getAcumulatedStatussesTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        documentActions.GetDocumentAcumulatedStatusses,
        documentActions.SetFlowIdTab
      ),
      withLatestFrom(this.store$.select((state) => state.documents.flowId)),
      withLatestFrom(this.store$.select((state) => state.documents.type)),
      switchMap(([[action, flowId], type]) => {
        return this.documentService
          .getAcumulatedStatusses({
            flowId,
            type,
            filterParams: action.payload.filters,
            show: action.payload.show,
          })
          .pipe(
            map((res) =>
              documentActions.SetFlowIdTabSuccess({
                payload: res,
              })
            ),
            catchError((error) => of(error))
          );
      })
    )
  );

  getLanguage = () =>
    this.store$
      .pipe(select(selectCurrentLang))
      .subscribe((lang) => this.translate.use(lang));

  private _handleErrorAndShowModal(error, action) {
    return this.errorHandler.handle(error, action);
  }

  constructor(
    private docService: DocumentService,
    private documentService: DocumentsService,
    private dashboardService: DashboardService,
    private translate: TranslateService,
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private toastr: I18nToastrService,
    private store$: Store<IAppState>,
    private loading: LoadingService,
    private httpClient: HttpClient,
    private modalService: NzModalService,
    private errorHandler: ErrorHandlerService
  ) {}
}
