import { Directive, ElementRef, HostListener } from '@angular/core';
import { FileInputComponent } from '../../file-input/file-input.component';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[fileDnD]'
})
export class FileDndDirective {
  @HostListener('dragover', ['$event']) public onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files.length > 0) {
      this.element.setFiles(event.dataTransfer.files);
    }
  }

  constructor(private host: FileInputComponent) {}

  get element() {
    return this.host;
  }
}
