import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ISignatureResponse } from '@literax/models/http/api/signature.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IUser } from '@literax/models/user.model';
import { ISignRequest } from '@literax/modules/documents/store/document/document.state';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-applicants-list-container',
  templateUrl: './applicants-list-container.component.html',
  styleUrls: ['./applicants-list-container.component.scss']
})
export class ApplicantsListContainerComponent implements OnInit {
  @Input() document: IDocument;
  @Input() applicantRequest: ISignRequest[];
  @Input() user: IUser;

  contentVisible = true;
  signatures: { [key: string]: ISignatureResponse } = {};

  isUserLoginObserver = false;

  constructor() {}

  ngOnInit(): void {}

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }
}
