import { AcceptTerms, GetTerms } from '@literax/store/terms/terms.actions';
import {
  AcceptTermsFree,
  GetTermsFree,
} from '@literax/store/free-terms/free-terms.actions';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store, select } from '@ngrx/store';

import { IAppState } from '@literax/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { selectDocTerms } from '@literax/store/terms/terms.selector';
import { selectFreeDocTerms } from '@literax/store/free-terms/free-terms.selector';


import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-tyc-modal',
  templateUrl: './tyc-modal.component.html',
  styleUrls: ['./tyc-modal.component.scss'],
})
export class TycModalComponent implements OnInit, OnDestroy {
  @Input() isGuest = false;
  @Input() token = '';
  @Input() signRequestId?: number;
  @Input() documentId?: number;
  @Input() isRegister = false;

  @Output() accepted: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  hasBiometrics = false;
  checked = false;
  documentB64: string;

  constructor(
    private store: Store<IAppState>,
    private translate: TranslateService,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {
    if (this.isGuest || this.isRegister) {
      this.store.dispatch(GetTermsFree({ token: this.token }));
      this.store
        .pipe(untilDestroyed(this), select(selectFreeDocTerms))
        .subscribe((docTerms) => {
          const docCurrentLang =
            this.translate.currentLang === 'es'
              ? docTerms?.file_base64
              : docTerms?.translate_file_base64;
          if (docCurrentLang) {
            this.documentB64 = docCurrentLang;
          }
        });
    } else {
      this.store.dispatch(GetTerms());
      this.store
        .pipe(untilDestroyed(this), select(selectDocTerms))
        .subscribe((docTerms) => {
          const docCurrentLang =
            this.translate.currentLang === 'es'
              ? docTerms?.file_base64
              : docTerms?.translate_file_base64;
          if (docCurrentLang) {
            this.documentB64 = docCurrentLang;
          }
        });
    }
  }

  ngOnDestroy() {}

  cancel() {
    this.close.emit(true);
  }

  accept() {
    if (this.isGuest) {
      this.store.dispatch(
        AcceptTermsFree({
          signRequestId: this.signRequestId,
          documentId: this.documentId,
          token: this.token,
        })
      );
    } else {
      this.store.dispatch(AcceptTerms());
    }
    this.accepted.emit(true);
    this.modal.closeAll();
  }
}
