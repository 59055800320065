import { createAction, props } from '@ngrx/store';

import { IApiDetailedError } from '../../../../../models/common';
import { IEmailClientConfig } from '../models/personalization.model';
import { IPatch } from '@literax/models/http/api/client/client.model';

export enum EPersonalizationActions {
  Get = '[Personalization] Get',
  GetSuccess = '[Personalization] GetSuccess',
  GetError = '[Personalization] GetError',

  Patch = '[Personalization] Patch',
  PatchSuccess = '[Personalization] PatchSuccess',
  PatchError = '[Personalization] PatchError',

  Post = '[Personalization] Post',
  PostSuccess = '[Personalization] PostSuccess',
  PostError = '[Personalization] PostError',
}

export const Get = createAction(
  EPersonalizationActions.Get,
  props<{ clientId: number }>()
);

export const GetSuccess = createAction(
  EPersonalizationActions.GetSuccess,
  props<{ emailConfig: IEmailClientConfig }>()
);

export const GetError = createAction(
  EPersonalizationActions.GetError,
  props<{ payload: { error: IApiDetailedError } }>()
);

export const Patch = createAction(
  EPersonalizationActions.Patch,
  props<{ clientId: number; payload: IPatch[] }>()
);

export const PatchSuccess = createAction(
  EPersonalizationActions.PatchSuccess,
  props<{ emailConfig: IEmailClientConfig }>()
);

export const PatchError = createAction(
  EPersonalizationActions.PatchError,
  props<{ payload: { error: IApiDetailedError } }>()
);

export const Post = createAction(
  EPersonalizationActions.Post,
  props<{ clientId: number; payload: IEmailClientConfig }>()
);

export const PostSuccess = createAction(
  EPersonalizationActions.PostSuccess,
  props<{ emailConfig: IEmailClientConfig }>()
);

export const PostError = createAction(
  EPersonalizationActions.PostSuccess,
  props<{ payload: { error: IApiDetailedError } }>()
);
