import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-confirmation-tyc-modal',
  templateUrl: './confirmation-tyc-modal.component.html',
  styleUrls: ['./confirmation-tyc-modal.component.scss']
})
export class ConfirmationTycModalComponent implements OnInit {

  @Output() return: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() reject: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor() { }

  ngOnInit(): void {
  }

  clickReject() {
    this.reject.emit(true);
  }

  clickReturn() {
    this.return.emit(true);
  }

}
