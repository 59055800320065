<nz-page-header *ngIf="!visibleInput" [nzBackIcon]="backIconTemplate">
  <nz-page-header-title>
    <nz-badge class="badge-custom" nzColor="{{ colorStatusDoc }}"> </nz-badge>
    {{ title }}
    <i *ngIf="icon" [class]="icon"></i>
  </nz-page-header-title>
  <nz-page-header-subtitle>
    <nz-badge nzColor="{{ statusColor }}"> </nz-badge>
    {{ status | translate }}
    <nz-tag *ngIf="tag" [nzColor]="tagColor">{{ tag }}</nz-tag>
  </nz-page-header-subtitle>
  <nz-page-header-extra *ngIf="isEditable">
    <button nz-button nzType="primary" (click)="editName()">
      <i class="fal fa-edit"></i>
      <span>{{ 'WORKSPACE_PROMISSORY.UI.BUTTONS.PENCIL' | translate }}</span>
    </button>
  </nz-page-header-extra>
</nz-page-header>
<ng-template #backIconTemplate>
  <literax-back-button [params]="paramsBackButton"></literax-back-button>
</ng-template>

<section nz-row *ngIf="visibleInput" class="edit-mode">
  <div nz-col nzSpan="8">
    <input nz-input placeholder="Nombre del documento" [(ngModel)]="title" />
  </div>
  <div nz-col nzSpan="16" class="page-header-left-side">
    <button nz-button nzType="primary" (click)="saveName()">
      <i class="fal fa-save"></i>
      <span>{{ 'WORKSPACE_PROMISSORY.UI.BUTTONS.SAVE' | translate }}</span>
    </button>
  </div>
</section>
