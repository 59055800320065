<div class="clientContainer">
  <div class="background">
    <div>
      <ul>
        <li>
          <p>
            {{
            'CONFIGURATIONS_MODULE.USERS.REGISTER.INFO_MESSAGE' | translate
            }}
          </p>
        </li>
      </ul>
    </div>
    <div id="divSessionWelcome">
      <div id="divWelcome">
        <p class="text-welcome">
          {{
          'CONFIGURATIONS_MODULE.USERS.REGISTER.WELCOME_REGISTER' | translate
          }}
        </p>
      </div>
      <div id="imgLiterax">
        <img src="https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white@3x.png"
          class="img-literax" alt="img-litera" />
      </div>
    </div>
  </div>

  <div class="formRegister">
    <div>
      <img src="https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_black@2x.png"
        class="img-literax-form" alt="img-litera-form" />
    </div>
    <div class="text-center">
      <div class="title-button"> 
        {{
        'CONFIGURATIONS_MODULE.USERS.REGISTER.NEW_ACCOUNT' | translate
        }}
      </div>
      <div class="subtitle-button"> 
        {{
        'CONFIGURATIONS_MODULE.USERS.REGISTER.SUBTITLE_NEW_ACCOUNT' | translate
        }}</div>
      <div class="button">
        <button class="interfirma-button primary block" tabindex="4" (click)="onBoarding()">
          {{
            'CONFIGURATIONS_MODULE.USERS.REGISTER.BUTTON_NEW_ACCOUNT' | translate
          }}
        </button>
      </div>

    </div>
    <div class="text-center mt-5">
      <div class="title-button">
        {{
          'CONFIGURATIONS_MODULE.USERS.REGISTER.EXISTING_USER' | translate
        }}
      </div>
      <div class="subtitle-button">
        {{
          'CONFIGURATIONS_MODULE.USERS.REGISTER.SUBTITLE_EXISTING_USER' | translate
        }}
      </div>
      <div class="button">
        <button class="interfirma-button primary block" tabindex="4" (click)="login()">
          {{
            'CONFIGURATIONS_MODULE.USERS.REGISTER.BUTTON_EXISTING_USER' | translate
          }}
        </button>
      </div>
    </div>
    <div class="footer">
      <div class="col-12 privacy d-flex justify-content-center">
        <a href="https://www.literax.com/wp-content/uploads/2021/06/aviso_de_privacidad.pdf" target="_blank"><span>{{
            'CONFIGURATIONS_MODULE.USERS.REGISTER.NOTE_OF_PRIVACY'
            | translate
            }}</span></a>
        <span class="mr-1 ml-1">|</span>
        <a href="https://www.literax.com/wp-content/uploads/2021/06/Terminos-y-condiciones.pdf" target="_blank"><span>{{
            'CONFIGURATIONS_MODULE.USERS.REGISTER.TERMS_CONDITIONS'
            | translate
            }}</span></a>
      </div>
      <div class="col-12 rights mt-3">
        {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.RIGTHS_COPY' | translate }}
      </div>
    </div>
  </div>


</div>