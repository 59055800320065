export const TemplateColumnSorting = {
  NAME: 'name',
  KIND: 'kind',
  DESCRIPTION: 'description',
  CREATED_AT: 'created_at',
  STATUS_NAME: 'status_name',
};

export const TemplateFilterColumn = {
  NAME: 'name',
  CREATED_AT: 'created_at',
  STATUS_NAME: 'status_name',
};

export const TemlateFilterFieldTypeData = {
  TEXT: 'text',
  NUMERIC: 'numeric',
  BOOLEAN: 'boolean',
  TIMESTAMP: 'timestamp',
};

export const TemplateFilterFieldTypeOperator = {
  EQ: 'eq',
  ILI: 'ili',
  LT: 'lt',
  LTE: 'lte',
  GT: 'gt',
  GTE: 'gte',
};

export const TemplateStatus = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished'
};
