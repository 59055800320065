import * as templatesActions from '@literax/modules/templates/store/templates.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { ETemplateFlow, ETemplateProcessKind } from '@literax/enums/template.enum';
import {
  ITemplatesState,
  initialTemplatesState,
} from '@literax/modules/templates/store/templates.state';
import {
  TemlateFilterFieldTypeData,
  TemplateFilterColumn,
  TemplateFilterFieldTypeOperator,
} from '@literax/constans/template.constants';

import { ITemplateFilterField } from '@literax/models/templates';

const templateReducer = createReducer(
  initialTemplatesState,
  on(templatesActions.indexTemplate, (state, action) => {
    const templates = [];
    const pagination = action.pagination
      ? { ...state.pagination, ...action.pagination }
      : state.pagination;
    return { ...state, templates, pagination };
  }),
  on(templatesActions.indexTemplateSuccess, (state, action) => {
    const templates = [...state.templates, ...action.payload.templates];
    return {
      ...state,
      templates,
      pagination: {
        currentPage: state.pagination.currentPage,
        pageSize: state.pagination.pageSize,
        total_count: action.payload.pagination.total_count,
        total_pages: action.payload.pagination.total_pages,
      },
    };
  }),
  on(templatesActions.CreateTemplateError, (state, action) => ({
    ...state,
    error: action.payload,
  })),
  on(templatesActions.getFlowsSuccess, (state, action) => ({
    ...state,
    flows: action.payload,
  })),
  on(templatesActions.changeFlow, (state, action) => {
    const pagination = action.pagination
      ? { ...state.pagination, ...action.pagination }
      : state.pagination;
    return {
      ...state,
      currentPage: 1,
      templates: [],
      selectedFlow: action.templateFlow,
      pagination,
    };
  }),
  on(templatesActions.getTemplateFieldsSuccess, (state, action) => {
    const templateFields = [...state.templateFields, ...action.payload];
    const templateFieldsCurrentPage =
      action.payload.length > 0
        ? state.templateFieldsCurrentPage + 1
        : state.templateFieldsCurrentPage;
    return { ...state, templateFields, templateFieldsCurrentPage };
  }),
  on(templatesActions.showTemplate, (state, _) => ({
    ...state,
    templateUpdate: {},
  })),
  on(templatesActions.showTemplateSuccess, (state, action) => ({
    ...state,
    templateUpdate: { ...action.payload },
    fieldsOnlyOffice: action.payload.tags,
    currentPage: 1,
    templates: [],
  })),
  on(templatesActions.relationshipTemplateFieldsSuccess, (state, action) => ({ //hacer ajuste para la v3
    ...state,
    templateUpdate: action.payload,
    fieldsOnlyOffice: action.payload?.tags,
  })),
  on(templatesActions.IndexTemplateFieldsSuccess, (state, action) => ({
    ...state,
    fieldsOnlyOffice: action.payload,
  })),
  on(templatesActions.latestSuccess, (state, action) => ({
    ...state,
    latest: action.payload,
  })),
  on(templatesActions.changeDepartment, (state, action) => ({
    ...state,
    currentDepartment: action.payload,
  })),
  on(templatesActions.changeKind, (state, action) => ({
    ...state,
    currentPage: 1,
    templates: [],
    kind: action.payload,
  })),
  on(templatesActions.GetKindsSuccess, (state, action) => ({
    ...state,
    templateKinds: action.payload,
  })),
  on(templatesActions.FindTemplateFieldsSuccess, (state, action) => ({
    ...state,
    fieldsOnlyOffice: action.payload,
  })),
  on(templatesActions.MetadataTemplateProfileSuccess, (state, action) => {
    const MetadataTemplateProfileCurrentPage =
      action.payload.length > 0
        ? state.metadataTemplateProfilesCurrentPage + 1
        : state.metadataTemplateProfilesCurrentPage;
    return {
      ...state,
      metadataTemplateProfiles: action.payload,
      MetadataTemplateProfileCurrentPage,
    };
  }),
  on(templatesActions.ClearMetadataTemplateProfile, (state, action) => ({
    ...state,
    metadataTemplateProfiles: [],
    metadataTemplateProfilesCurrentPage: 1,
  })),
  on(templatesActions.GetProfilesSuccess, (state, action) => ({
    ...state,
    profiles: action.payload,
  })),
  on(templatesActions.GetTemplateStadistics, (state, action) => {
    return {
      ...state,
      templateFieldData: null,
    };
  }),
  on(templatesActions.GetTemplateStadisticsSuccess, (state, action) => {
    const templateFieldData = action.payload;
    return {
      ...state,
      templateFieldData,
    };
  }),
  on(templatesActions.GetCollectorsByTemplateSuccess, (state, action) => ({
    ...state,
    collectors: action.payload,
  })),
  on(templatesActions.GetSignersByTemplateSuccess, (state, action) => ({
    ...state,
    signers: action.payload,
  })),
  on(templatesActions.ApplyFilter, (state, action) => ({
    ...state,
    templateFieldData: null,
    templateFieldDataActiveFilter: action.filterParams,
    templateFieldDataCurrentPage: 1,
  })),
  on(templatesActions.ChangeCurrentSort, (state, action) => ({
    ...state,
    templateFieldDataCurrentSort: action.columnSort,
    templateFieldData: null,
    templateFieldDataCurrentPage: 1,
  })),
  on(templatesActions.CleanTemplateStadistics, (state, _) => ({
    ...state,
    templateFieldData: null,
    templateFieldDataCurrentPage: 1,
  })),
  on(templatesActions.CreateTemplateSuccess, (state, action) => ({
    ...state,
    templateUpdate: action.payload.template,
  })),
  on(templatesActions.DeleteTemplate, (state, action) => ({
    ...state,
    currentPage: 1,
    templates: [],
    selectedFlow: ETemplateFlow.TEMPLATE_DRAFT,
  })),
  on(templatesActions.Get, (state, action) => {
    const pageN = action.payload?.pageNumber ? action.payload?.pageNumber : state.queryParameters?.pageNumber;
    const pageS = action.payload?.pageSize ? action.payload?.pageSize : state.queryParameters?.pageSize;
    let filters: ITemplateFilterField[] = state.queryParameters?.filterFields ?  state.queryParameters?.filterFields : [];
    if (action?.payload?.filterFields && action?.payload?.filterFields?.length > 0) {
      filters = filters.concat(action.payload?.filterFields);
    }
    const sorts = action?.payload?.sortField ?
      { ...state.queryParameters?.sortField, ...action.payload?.sortField } :
      null;
    return {
      ...state,
      queryParameters: {
        pageNumber: pageN,
        pageSize: pageS,
        filterFields: filters,
        sortField: sorts
      }
    }
  }),
  on(templatesActions.GetSuccess, (state, action) => ({
    ...state,
    ...action.payload,
  })),
  on(templatesActions.ChangeStatus, (state, action) => ({
    ...state,
    queryParameters:
      action?.status !== null && action?.status !== undefined
        ? {
            pageNumber: 1,
            pageSize: 10,
            sortField: null,
            filterFields: [
              {
                name: TemplateFilterColumn.STATUS_NAME,
                term: action.status,
                type: TemlateFilterFieldTypeData.TEXT,
                operator: TemplateFilterFieldTypeOperator.EQ,
              },
            ],
          }
        : {
            pageNumber: 1,
            pageSize: 10,
            sortField: null,
            filterFields: [],
          },
  })),
  on(templatesActions.ChangeTemplateKind, (state, action) => ({
    ...state,
    currentPage: 1,
    templates: [],
    kindV3: action.kind === ETemplateProcessKind.DOCUMENT ? '0' : action.kind,
    forceDepartments: action.forceDepartments
  })),
  on(templatesActions.CreateTemplateV3Success, (state, action) => ({
    ...state,
    templateById: action.payload,
    templateUpdateV3: { ...action.payload }
  })),
  on(templatesActions.CreateTemplateV3Error, (state, action) => ({
    ...state,
    error: action.payload,
  })),
  on(templatesActions.GetTemplateByIdSuccess, (state, action) => ({
    ...state,
    templateById: action.payload,
    templateUpdateV3: { ...action.payload },
  })),
  on(templatesActions.GetTemplateByIdError, (state, action) => ({
    ...state,
    error: action.payload,
  })),
  on(templatesActions.CreateelationTemplateMetaDataSuccess, (state, action) => ({
    ...state,
    templateUpdateV3: action.payload,
    fieldsOnlyOfficeV3: action.payload?.fields,
  })),
  on(templatesActions.updateTemplateNameDepartmentsSuccess, (state, action) => ({
    ...state,
    templateById: action.payload,
    templateUpdateV3: { ...action.payload },
  })),
  on(templatesActions.ResetTemplatesData, (state, action) => ({
    ...state,
    templatesV3: [],
    paginationV3: null,
    queryParameters: {
      pageNumber: 1,
      pageSize: 10,
      sortField: null,
      filterFields: [],
    }
  }))
);

export function templatesReducer(state: ITemplatesState, action: Action) {
  return templateReducer(state, action);
}
