<div class="header mb-0">
  <h6>{{ label | translate }}</h6>
</div>
<div *ngIf="identification">
  <literax-file-input
    #fileInput
    [formControl]="form.get('id_image')"
    [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
    [fileTypes]="permittedFiles"
    [labelTag]="'FORM.SIGNATURE.IDENTIFICATION' | translate"
    [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
    [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
  >
  </literax-file-input>
</div>
<div class="file-drop">
  <literax-file-input
    #cert
    [formControl]="form.get('certificate')"
    [fileTypes]="permittedCertificateFiles"
    [labelTag]="'PROFILE.FORM.CERT' | translate"
    [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
    [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
    [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
    [returnBase64]="true"
  >
  </literax-file-input>
  <literax-api-error
    [control]="form.controls.certificate"
    [errors]="signDataAPIErrors"
  >
  </literax-api-error>
  <label class="api error" *ngIf="serverErrorVisible">
    <i class="material-icons">cancel</i>
    <span class="text">{{ getErrors(serverErrors.error) }}</span>
  </label>

  <div class="file-drop">
    <literax-file-input
      [formControl]="form.get('key')"
      [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
      [fileTypes]="permittedKeyFiles"
      [labelTag]="'FORM.FILE_INPUT.LABEL_KEY' | translate"
      [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
      [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
      [returnBase64]="true"
    >
    </literax-file-input>
    <literax-api-error
      [control]="form?.controls?.certificate"
      [errors]="signDataAPIErrors"
    >
    </literax-api-error>
    <label class="api error" *ngIf="serverErrorVisible">
      <i class="material-icons">cancel</i>
      <span class="text">{{ getErrors(serverErrors.error) }}</span>
    </label>
    <div class="input">
      <literax-form-field 
        type="password"
        [placeholder]="'FORM.FILE_INPUT.PASSWORD' | translate"
        [formControl]="form?.get('password')"
      >
      </literax-form-field>
    </div>
    <div *ngIf="serverErrorPassword">
      <label class="api error" *ngIf="serverErrorVisible">
        <i class="material-icons">cancel</i>
        <span class="text">{{ serverErrors.error[0] }}</span>
      </label>
    </div>
  </div>
</div>
