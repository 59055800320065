import {
  IAPIError,
  IAPIResponseError,
  IAPIResponseErrors
} from '@literax/models/http/api/error.model';
import {
  IAPISignRequest,
  IAddMeStatus,
  IContact
} from '@literax/models/http/api/sign-request/sign-request.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '../../../../components/configurations/users/models/users.interface';

export interface ISignRequestState {
  documentSelected: number;
  signRequests: IAPISignRequest[] | any;
  createNaturalPersonErrors: IAPIResponseErrors;
  updateNaturalPersonErrors: IAPIResponseErrors;

  createLegalRepresentativeErrors: IAPIResponseErrors;
  updateLegalRepresentativeErrors: IAPIResponseErrors;
  successSignRequestResponse: boolean;
  successSignRequestNotificationSuccess: boolean;
  addMeStatus: IAddMeStatus;
  contacts: IContact[];
  error: HttpErrorResponse;
  signRequest: IAPISignRequest;
  //collaborator
  collaborators: IUser[];
}

export const initialSignRequestState: ISignRequestState = {
  documentSelected: null,
  signRequests: [],
  createNaturalPersonErrors: {},
  updateNaturalPersonErrors: {},
  createLegalRepresentativeErrors: {},
  updateLegalRepresentativeErrors: {},
  successSignRequestResponse: null,
  successSignRequestNotificationSuccess: null,
  addMeStatus: { payload: null, success: false },
  contacts: [],
  error: null,
  signRequest: null,
  //collaborator
  collaborators: []
};
