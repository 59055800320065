import { TitleNavbarConfig } from '@literax/models/title-navbar-config.model';
import { createSelector } from '@ngrx/store';
import { IAppState } from '@literax/store';

const selectTitleNavbar = (state: IAppState) => state.titleNavbar;

export const selectStack = createSelector(selectTitleNavbar, (state: any) => {
  return state && state.nav_nodes
    ? (Object.values(state?.nav_nodes) as TitleNavbarConfig[])
    : [];
});
