import { Component, Input, OnInit } from '@angular/core';

import { EButtonActions } from '@literax/modules/shared/compose-attachment-file/enums/button-actions.enum';
import { IAppState } from '@literax/store';
import { IDialogData } from '@literax/modules/shared/compose-attachment-file/models/dialog-data';
import { ModalAttachmentComponent } from '@literax/modules/shared/compose-attachment-file/components/modal-attachment/modal-attachment.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { createAttachment } from '@literax/modules/documents/store/attachment/attachment.actions';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-compose-attachment-file',
  templateUrl: './compose-attachment-file.component.html',
  styleUrls: ['./compose-attachment-file.component.scss']
})
export class ComposeAttachmentFileComponent implements OnInit {
  @Input()
  visibleButtons: EButtonActions[] = [];

  @Input()
  documentId = 0;

  @Input()
  title = '';

  EButtonActions = EButtonActions;

  dialogData: IDialogData;
  dialogWidth: number;

  constructor(
    private translate: TranslateService,
    private modal: NzModalService,
    private store: Store<IAppState>
  ) {}

  ngOnInit(): void {}

  buttonClicked(action: EButtonActions): void {
    switch (action) {
      case EButtonActions.TEMPLATE:
        this.templateAction();
        break;
      case EButtonActions.UPLOAD:
        this.uploadAction();
        break;
      case EButtonActions.WRITE:
        this.writeAction();
        break;
    }
  }

  templateAction(): void {}

  uploadAction(): void {
    this.dialogWidth = 450;
    this.dialogData = {
      title: 'COMPOSE_ATTACHMENT_FILE.MODAL.NEW.UPLOAD',
      action: EButtonActions.UPLOAD,
      callback: (data): void => {
        this.store.dispatch(
          createAttachment({ documentId: this.documentId, data })
        );
      }
    };
    this.openDialog();
  }

  writeAction(): void {
    this.dialogWidth = 360;
    this.dialogData = {
      title: 'COMPOSE_ATTACHMENT_FILE.MODAL.NEW.WRITE',
      action: EButtonActions.WRITE,
      callback: (data): void => {
        this.store.dispatch(
          createAttachment({ documentId: this.documentId, data })
        );
      }
    };
    this.openDialog();
  }

  openDialog(): void {
    this.translate.get(this.dialogData.title).subscribe((nzTitle) => {
      const modalAttachment = this.modal.create({
        nzMask: false,
        nzTitle,
        nzContent: ModalAttachmentComponent,
        nzWidth: this.dialogWidth,
        nzComponentParams: {
          data: this.dialogData
        },
        nzBodyStyle: {
          'background-color': '#f5f5f5'
        }
      });
    });
  }
}
