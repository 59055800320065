import {
  IOperation,
  IOperationResponse,
  IUserIntegrator,
  IUserIntegratorResponse,
  IWebServiceConfigResponse,
  IWebhookEvent,
  IWebhookEventQueryParams
} from '../models/api.model';
import { createAction, props } from '@ngrx/store';

import { ServiceResponse } from '@literax/interfaces/service-response';
import { IPagination } from '@literax/models/pagination.model';

export enum EApiActions {
  GetWebServiceConfigIndex = '[Api] Get WebServiceConfig',
  GetWebServiceConfigIndexSuccess = '[Api] Get WebServiceConfig Success',
  GetWebServiceConfigIndexError = '[Api] Get WebServiceConfig Error',

  ShowWebServiceConfig = '[Api] Show WebServiceConfig',
  ShowWebServiceConfigSuccess = '[Api] Show WebServiceConfig Success',
  ShowWebServiceConfigError = '[Api] Show WebServiceConfig Error',

  CreateWebServiceConfig = '[Api] Create WebServiceConfig',
  CreateWebServiceConfiguSuccess = '[Api] Create WebServiceConfig Success',
  CreateWebServiceConfigError = '[Api] Create WebServiceConfig Error',

  UpdateWebServiceConfig = '[Api] Update WebServiceConfig',
  UpdateWebServiceConfigSuccess = '[Api] Update WebServiceConfig Success',
  UpdateWebServiceConfigError = '[Api] Update WebServiceConfig Error',

  DeleteWebServiceConfig = '[Api] Delete WebServiceConfig',
  DeleteWebServiceConfigSuccess = '[Api] Delete WebServiceConfig Success',
  DeleteWebServiceConfigError = '[Api] Delete WebServiceConfig Error',

  GetOperationsIndex = '[Api] Get Operation',
  GetOperationsIndexSuccess = '[Api] Get Operation Success',
  GetOperationsIndexError = '[Api] Get Operation Error',

  ShowOperation = '[Api] Show Operation',
  ShowOperationSuccess = '[Api] Show Operation Success',
  ShowOperationError = '[Api] Show Operation Error',

  CreateOperation = '[Api] Create Operation',
  CreateOperationuSuccess = '[Api] Create Operation Success',
  CreateOperationError = '[Api] Create Operation Error',

  UpdateOperation = '[Api] Update Operation',
  UpdateOperationSuccess = '[Api] Update Operation Success',
  UpdateOperationError = '[Api] Update Operation Error',

  DeleteOperation = '[Api] Delete Operation',
  DeleteOperationSuccess = '[Api] Delete Operation Success',
  DeleteOperationError = '[Api] Delete Operation Error',

  setWebServiceConfigurationsSort = '[Api] Set WebServiceConfigSort',
  setOperationsSort = '[Api] Set OperationsSort',
  setUserIntegratorSort = '[Api] Set UserIntegratorSort',

  ClearSelectedWebService = '[Api] Clear SelectedWebService',
  ClearSelectedOperation = '[Api] Clear SelectedOperation',
  ClearUserSelectedIntegrator = '[Api] Clear SelectedUserIntegrator',

  // CreateUserIntegrator
  CreateUserIntegrator = '[Api] Create UserIntegrator',
  CreateUserIntegratorSuccess = '[Api] Create UserIntegrator Success',
  CreateUserIntegratorError = '[Api] Create UserIntegrator Error',

  ShowUserIntegrator = '[Api] Show UserIntegrator',
  ShowUserIntegratorSuccess = '[Api] Show UserIntegrator Success',
  ShowUserIntegratorError = '[Api] Show UserIntegrator Error',

  UpdateUserIntegrator = '[Api] Update UserIntegrator',
  UpdateUserIntegratorSuccess = '[Api] Update UserIntegrator Success',
  UpdateUserIntegratorError = '[Api] Update UserIntegrator Error',

  DeleteUserIntegrator = '[Api] Delete UserIntegrator',
  DeleteUserIntegratorSuccess = '[Api] Delete UserIntegrator Success',
  DeleteUserIntegratorError = '[Api] Delete UserIntegrator Error',

  GetUserIntegratorIndex = '[Api] Get UserIntegrator',
  GetUserIntegratorIndexSuccess = '[Api] Get UserIntegrator Success',
  GetUserIntegratorIndexError = '[Api] Get UserIntegrator Error',

  GetEventsLogIndex = '[Api] Get Event log index',
  GetEventsLogIndexSuccess = '[Api] Get Event log index success',
  GetEventsLogIndexError = '[Api] Get Event log index error',

  setEventsLogSort = '[Api] Set events log sort params',

  clearEventLogSortParams = '[Api] clear events log sort params',

  ResendEvent = '[Api] Resend event webhook',
  ResendEventSuccess = '[Api] Resend event webhook success',

  ResetDefaults = '[Api] Reset initial store'
}

export const setEventsLogSort = createAction(
  EApiActions.setEventsLogSort,
  props<{ payload: Partial<IWebhookEventQueryParams> }>()
);
export const clearEventLogSortParams = createAction(
  EApiActions.clearEventLogSortParams
);
export const GetEventsLogIndex = createAction(EApiActions.GetEventsLogIndex);
export const GetEventsLogIndexSuccess = createAction(
  EApiActions.GetEventsLogIndexSuccess,
  props<{ payload: IWebhookEvent[] }>()
);
export const GetEventsLogIndexError = createAction(
  EApiActions.GetEventsLogIndexError,
  props<{ payload: ServiceResponse }>()
);
export const ResendEvent = createAction(
  EApiActions.ResendEvent,
  props<{ payload: IWebhookEvent }>()
);

export const ResendEventSuccess = createAction(
  EApiActions.ResendEventSuccess,
  props<{ payload: ServiceResponse }>()
);

export const ResetDefaults = createAction(EApiActions.ResetDefaults);

export const CreateUserIntegrator = createAction(
  EApiActions.CreateUserIntegrator,
  props<{ payload: IUserIntegrator }>()
);
export const GetUserIntegratorIndex = createAction(
  EApiActions.GetUserIntegratorIndex
);

export const ShowUserIntegrator = createAction(
  EApiActions.ShowUserIntegrator,
  props<{ payload: { userId: number } }>()
);

export const ShowUserIntegratorSuccess = createAction(
  EApiActions.ShowUserIntegratorSuccess,
  props<{ payload: { client_user: IUserIntegratorResponse } }>()
);

export const UpdateUserIntegrator = createAction(
  EApiActions.UpdateUserIntegrator,
  props<{ payload: IUserIntegratorResponse }>()
);
export const DeleteUserIntegrator = createAction(
  EApiActions.DeleteUserIntegrator,
  props<{ payload?: { userId: number } }>()
);

export const CreateUserIntegratorSuccess = createAction(
  EApiActions.CreateUserIntegratorSuccess,
  props<{ payload: boolean }>()
);
export const UpdateUserIntegratorSuccess = createAction(
  EApiActions.UpdateUserIntegratorSuccess,
  props<{ payload: boolean }>()
);
export const DeleteUserIntegratorSuccess = createAction(
  EApiActions.DeleteUserIntegratorSuccess,
  props<{ payload: boolean }>()
);

export const DeleteUserIntegratorError = createAction(
  EApiActions.DeleteUserIntegratorError,
  props<{ payload: IUserIntegratorResponse }>()
);

export const GetUserIntegratorIndexError = createAction(
  EApiActions.GetUserIntegratorIndexError,
  props<{ payload: ServiceResponse }>()
);

export const GetUserIntegratorIndexSuccess = createAction(
  EApiActions.GetUserIntegratorIndexSuccess,
  props<{
    payload: {
      client_users: IUserIntegrator[];
    };
  }>()
);

export const CreateUserIntegratorError = createAction(
  EApiActions.CreateUserIntegratorError,
  props<{ payload: ServiceResponse }>()
);

export const ShowUserIntegratorError = createAction(
  EApiActions.ShowUserIntegratorError,
  props<{ payload: ServiceResponse }>()
);

export const UpdateUserIntegratorError = createAction(
  EApiActions.UpdateUserIntegratorError,
  props<{ payload: ServiceResponse }>()
);

export const ClearSelectedWebService = createAction(
  EApiActions.ClearSelectedWebService
);

export const ClearSelectedOperation = createAction(
  EApiActions.ClearSelectedOperation
);

export const ClearUserSelectedIntegrator = createAction(
  EApiActions.ClearUserSelectedIntegrator
);

export const setWebServiceConfigurationsSort = createAction(
  EApiActions.setWebServiceConfigurationsSort,
  props<{ payload: { sort: string } }>()
);
export const setOperationsSort = createAction(
  EApiActions.setOperationsSort,
  props<{ payload: { sort: string } }>()
);

export const setUserIntegratorSort = createAction(
  EApiActions.setUserIntegratorSort,
  props<{ payload: { sort: string } }>()
);

// acciones de entrada de datos webservice_configs
export const CreateWebServiceConfig = createAction(
  EApiActions.CreateWebServiceConfig,
  props<{ payload: IWebServiceConfigResponse }>()
);
export const GetWebServiceConfigIndex = createAction(
  EApiActions.GetWebServiceConfigIndex,
  props< {
    payload:{
      sort: string,
      page: number
    }
  } >()
);

export const ShowWebServiceConfig = createAction(
  EApiActions.ShowWebServiceConfig,
  props<{ payload: { webhookId: number } }>()
);

export const UpdateWebServiceConfig = createAction(
  EApiActions.UpdateWebServiceConfig,
  props<{ payload: IWebServiceConfigResponse }>()
);
export const DeleteWebServiceConfig = createAction(
  EApiActions.DeleteWebServiceConfig,
  props<{ payload?: { webhookId: number } }>()
);

// acciones de entrada de datos operaciones
export const CreateOperation = createAction(
  EApiActions.CreateOperation,
  props<{ payload: IOperation }>()
);
export const GetOperationsIndex = createAction(
  EApiActions.GetOperationsIndex,
  props<{payload:{ sort:string, page:number}}>()
);

export const ShowOperation = createAction(
  EApiActions.ShowOperation,
  props<{ payload: { operationId: number } }>()
);

export const UpdateOperation = createAction(
  EApiActions.UpdateOperation,
  props<{ payload: IOperationResponse }>()
);
export const DeleteOperation = createAction(
  EApiActions.DeleteOperation,
  props<{ payload?: { operationId: number } }>()
);

// acciones de exito para los servicios
export const GetWebServiceConfigIndexSuccess = createAction(
  EApiActions.GetWebServiceConfigIndexSuccess,
  props<{
    payload: {
      webservice_configs: IWebServiceConfigResponse[];
      pagination: IPagination
    };
  }>()
);

export const ShowWebServiceConfigSuccess = createAction(
  EApiActions.ShowWebServiceConfigSuccess,
  props<{
    payload: {
      webservice_config: IWebServiceConfigResponse;
    };
  }>()
);

export const ShowOperationSuccess = createAction(
  EApiActions.ShowOperationSuccess,
  props<{
    payload: {
      operation: IOperationResponse;
    };
  }>()
);

export const CreateWebServiceConfiguSuccess = createAction(
  EApiActions.CreateWebServiceConfiguSuccess,
  props<{ payload: boolean }>()
);
export const UpdateWebServiceConfigSuccess = createAction(
  EApiActions.UpdateWebServiceConfigSuccess,
  props<{ payload: boolean }>()
);
export const DeleteWebServiceConfigSuccess = createAction(
  EApiActions.DeleteWebServiceConfigSuccess
);

// acciones de exito para los servicios
export const GetOperationsIndexSuccess = createAction(
  EApiActions.GetOperationsIndexSuccess,
  props<{
    payload: {
      operations: IOperationResponse[];
      pagination: IPagination;
    };
  }>()
);
export const CreateOperationuSuccess = createAction(
  EApiActions.CreateOperationuSuccess,
  props<{ payload: boolean }>()
);
export const UpdateOperationSuccess = createAction(
  EApiActions.UpdateOperationSuccess,
  props<{ payload: boolean }>()
);
export const DeleteOperationSuccess = createAction(
  EApiActions.DeleteOperationSuccess
);

// acciones para errores

export const GetWebServiceConfigIndexError = createAction(
  EApiActions.GetWebServiceConfigIndexError,
  props<{ payload: ServiceResponse }>()
);

export const CreateWebServiceConfigError = createAction(
  EApiActions.CreateWebServiceConfigError,
  props<{ payload: ServiceResponse }>()
);

export const ShowWebServiceConfigError = createAction(
  EApiActions.ShowWebServiceConfigError,
  props<{ payload: ServiceResponse }>()
);

export const ShowOperationError = createAction(
  EApiActions.ShowOperationError,
  props<{ payload: ServiceResponse }>()
);

export const UpdateWebServiceConfigError = createAction(
  EApiActions.UpdateWebServiceConfigError,
  props<{ payload: ServiceResponse }>()
);

export const DeleteWebServiceConfigError = createAction(
  EApiActions.DeleteWebServiceConfigError,
  props<{ payload: ServiceResponse }>()
);

export const GetOperationsIndexError = createAction(
  EApiActions.GetOperationsIndexError,
  props<{ payload: ServiceResponse }>()
);

export const CreateOperationError = createAction(
  EApiActions.CreateOperationError,
  props<{ payload: ServiceResponse }>()
);

export const UpdateOperationError = createAction(
  EApiActions.UpdateOperationError,
  props<{ payload: ServiceResponse }>()
);

export const DeleteOperationError = createAction(
  EApiActions.DeleteOperationError,
  props<{ payload: ServiceResponse }>()
);
