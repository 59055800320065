import { IDepartment } from '@literax/components/configurations/users/models/users.interface';
import { IAPIResponseError } from '@literax/models/http/api/error.model';

export interface IDepartmentState {
  departments: IDepartment[];
  departmentError: IAPIResponseError;
  currentPage: number;
  sortColumn: string;
}

export const initialDepartmentState: IDepartmentState = {
  departments: [],
  departmentError: null,
  currentPage: 1,
  sortColumn: 'id:desc'
};
