import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ColorsHex]',
})
export class ColorsHex {
  constructor(private readonly control: NgControl) {}
  @Input() ColorsHex: boolean;

  @HostListener('input', ['$event.target'])
  public onInput(input: HTMLInputElement): void {
    if (this.ColorsHex) {
      if (input.value !== '#') {
        const newValue = input.value
          .replace(/[^a-z0-9]/gi, '')
          .toUpperCase()
          .substring(0, 6);

        this.control.control.setValue(newValue ? `#${newValue}` : '');
      } else {
        this.control.control.setValue(`#`);
      }
    }
  }

  @HostListener('blur', ['$event']) _($event) {
    if (this.ColorsHex) {
      const repeatCero = 7 - $event.target.value.length;

      $event.target.value =
        repeatCero == 7 || repeatCero == 6
          ? `#000000`
          : `${$event.target.value}${'0'.repeat(repeatCero)}`;

      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      event.target.dispatchEvent(evt);
    }
  }
}
