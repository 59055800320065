<div *ngIf="participantsPromissoryNote.length <= 0">
  <ng-container *ngIf="signerRequest.length <= 0">
    <div class="empty-attachments">
      <img src="../../../../assets/icon/signers.svg" class="image-upload" />
      <p class="empty-message">
        {{ 'DOCUMENT-PREVIEW.DOCUMENTS-TAB.EMPTY_PARTICIPANS' | translate }}
      </p>
    </div>
  </ng-container>
  
  <div 
    *ngIf="signerRequest.length" 
  >
    <ng-container *ngFor="let signer of signerRequest">
      <literax-signers
        [status]="document.status.key"
        [actionButtonEnable]="signer.email !== user.email"
        [signer]="signer"
        [typePromissoryNote]="typePromissoryNote"
        [hasObservers]="hasObservers"
        [user]="user"
        [userDocument]="document.user"
        [labelButton]="'DOCUMENT-PREVIEW.SIGNERS.RESEND' | translate"
        (actionButton)="signatureAction.emit($event)"
        (historyButton)="historyButton.emit($event)"
        [userLoginObservable]="isUserLoginObserver"
        [comment]="comment"
      >
      </literax-signers>
    </ng-container>
  </div>
</div>

<div *ngIf="participantsPromissoryNote.length > 0">
  <ng-container *ngFor="let signer of participantsPromissoryNote">
    <literax-signers
      [status]="document.status.key"
      [actionButtonEnable]="signer.email !== user.email"
      [signer]="signer"
      [typePromissoryNote]="typePromissoryNote"
      [hasObservers]="hasObservers"
      [user]="user"
      [userDocument]="document.user"
      [labelButton]="'DOCUMENT-PREVIEW.SIGNERS.RESEND' | translate"
      (actionButton)="signatureAction.emit($event)"
      (historyButton)="historyButton.emit($event)"
      [userLoginObservable]="isUserLoginObserver"
      [comment]="comment"
    >
    </literax-signers>
  </ng-container>
</div>
