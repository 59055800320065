<div class="validator-wrapper">
  <div class="validator-navbar">
    <div class="logo">
      <img [src]="logoUrl" />
    </div>
    <div class="actions">
      <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        <i nz-icon nzType="global" nzTheme="outline"></i>
      </a>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item *ngFor="let lang of langs" (click)="setLang(lang)">
            {{ lang | uppercase }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
