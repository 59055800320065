import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IOption } from '@literax/models/options.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() options: IOption[];
  @Output() optionChanged = new EventEmitter();
  @Input() set placeholder(data: string) {
    if (this.selectedOption === this.label) {
      this.selectedOption = data;
    }
    this.label = data;
  }
  @Input() readonly: boolean;

  private label = '';
  selectActive = false;
  selectedOptions = [];
  selectedIds = [];
  selectedOption = this.label;
  overlayActive = true;

  constructor() {}

  ngOnInit() {
    this.selectedOption = this.label;
  }

  toggleSelect() {
    if (!this.readonly) {
      this.selectActive = !this.selectActive;
    }
  }

  optionSelected(option) {
    this.selectedOption = option.name;
    if (this.selectedOptions.includes(option.name)) {
      const position = this.selectedOptions.findIndex(
        (value) => value === option.name
      );
      this.selectedOptions.splice(position, 1);
      this.selectedIds.splice(position, 1);
    } else {
      this.selectedOptions = this.selectedOptions.concat(option.name);
      this.selectedIds = this.selectedIds.concat(option.id);
    }
    this.overlayActive = this.selectedOptions.length > 0;
    this.selectedOption = this.overlayActive
      ? this.selectedOptions.join(', ')
      : this.label;
    this.optionChanged.emit(this.selectedIds);
  }
}
