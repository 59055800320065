import { IDepartment } from '@literax/components/configurations/users/models/users.interface';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { createAction, props } from '@ngrx/store';

export enum EDepartmentActions {
  GetDepartments = '[Department] Get departments',
  GetDepartmentsSuccess = '[Department] Get department success',
  GetDepartmentsError = '[Department] Get departments error',
  CreateDepartment = '[Department] Create department',
  CreateDepartmentSuccess = '[Department] Create department success',
  CreateDepartmentError = '[Department] Create department error',
  UpdateDepartment = '[Department] Update department',
  UpdateDepartmentSuccess = '[Department] Update department success',
  UpdateDepartmentError = '[Department] Update department error',
  DeleteDepartment = '[Department] Delete department',
  DeleteDepartmentSuccess = '[Department] Delete department success',
  DeleteDepartmentError = '[Department] Delete department error',
  ChangeSortColumn = '[Department] Change sort column',
  ClearDepartments = '[Department] Clear departments'
}

export const GetDepartments = createAction(EDepartmentActions.GetDepartments);
export const ClearDepartments = createAction(
  EDepartmentActions.ClearDepartments
);

export const GetDepartmentSuccess = createAction(
  EDepartmentActions.GetDepartmentsSuccess,
  props<{ payload: IDepartment[] }>()
);

export const GetDepartmentError = createAction(
  EDepartmentActions.GetDepartmentsError,
  props<{ payload: IAPIResponseError }>()
);

export const CreateDepartment = createAction(
  EDepartmentActions.CreateDepartment,
  props<{ payload: IDepartment }>()
);

export const CreateDepartmentSuccess = createAction(
  EDepartmentActions.CreateDepartmentSuccess,
  props<{ payload: IDepartment }>()
);

export const CreateDepartmentError = createAction(
  EDepartmentActions.CreateDepartmentError,
  props<{ payload: IAPIResponseError }>()
);

export const UpdateDepartment = createAction(
  EDepartmentActions.UpdateDepartment,
  props<{ payload: IDepartment }>()
);

export const UpdateDepartmentSuccess = createAction(
  EDepartmentActions.UpdateDepartmentSuccess,
  props<{ payload: IDepartment }>()
);

export const UpdateDepartmentError = createAction(
  EDepartmentActions.UpdateDepartmentError,
  props<{ payload: IAPIResponseError }>()
);

export const DeleteDepartment = createAction(
  EDepartmentActions.DeleteDepartment,
  props<{ payload: number }>()
);

export const DeleteDepartmentSuccess = createAction(
  EDepartmentActions.DeleteDepartmentSuccess
);

export const DeleteDepartmentError = createAction(
  EDepartmentActions.DeleteDepartmentError,
  props<{ payload: IAPIResponseError }>()
);

export const ChangeSortColumn = createAction(
  EDepartmentActions.ChangeSortColumn,
  props<{ payload: string }>()
);
