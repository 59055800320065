import * as attachmentActions from './attachment.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { IAttachmentState, initialAttachmentState } from './attachment.state';

import { IAttachment } from '@literax/models/http/attachment.model';

const attachmentReducer = createReducer(
  initialAttachmentState,
  on(attachmentActions.deleteAttachmentError, (state, action) => ({ ...state, deleteAttachmentError: action.payload })),
  on(attachmentActions.createAttachmentSuccess, (state, action) => {
    const attachments: IAttachment[] = [
      action.payload.attachment,
      ...state.attachments
    ];
    const uploadErrors = {
      ...state.uploadErrors,
      success: action.payload.success
    };
    return {
      ...state,
      attachments,
      uploadErrors,
      lastAttachment: action.payload.attachment
    };
  }),
  on(attachmentActions.createAttachmentError, (state, action) => ({ ...state, uploadErrors: action.payload })),
  on(attachmentActions.updateAttachmentSuccess, (state, action) => {
    const attachments: IAttachment[] = [
      action.payload.attachment,
      ...state.attachments
    ];
    const uploadErrors = {
      ...state.uploadErrors,
      success: action.payload.success
    };
    return {
      ...state,
      attachments,
      uploadErrors,
      lastAttachment: action.payload.attachment
    };
  }),
  on(attachmentActions.updateAttachmentError, (state, action) => ({ ...state, uploadErrors: action.payload }))
);

export function attachmentReducers(state: IAttachmentState | undefined, action: Action) {
  return attachmentReducer(state, action);
}
