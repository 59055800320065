import { Injectable } from '@angular/core';
import * as companyActions from './company.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CompanyService } from '@literax/services/company/company.service';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { ICompanyAdd, ICompanyList } from '@literax/models/company.model';
import { of } from 'rxjs';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService
  ) {}

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.CreateCompany),
      switchMap((action) =>
        this.companyService.createCompany(action.payload).pipe(
          map((response: ServiceResponse) =>
            companyActions.CreateCompanySuccess({
              payload: response.company as ICompanyAdd
            })
          ),
          catchError((error) =>
            of(companyActions.CreateCompanyError({ payload: error }))
          )
        )
      )
    )
  );

  getCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        companyActions.GetCompanies,
        companyActions.CreateCompanySuccess,
        companyActions.DeleteCompanySuccess
      ),
      switchMap((action) =>
        this.companyService.getCompanies().pipe(
          map((response: ServiceResponse) =>
            companyActions.GetCompaniesSuccess({
              payload: response.companies as ICompanyList[]
            })
          ),
          catchError((error) =>
            of(companyActions.GetCompaniesError({ payload: error }))
          )
        )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.DeleteCompany),
      switchMap((action) =>
        this.companyService.deleteCompany(action.payload).pipe(
          map((response: ServiceResponse) =>
            companyActions.DeleteCompanySuccess()
          ),
          catchError((error) =>
            of(companyActions.DeleteCompanyError({ payload: error }))
          )
        )
      )
    )
  );
}
