import { ICreateClient, ICreateClientResponse, IValidateClient } from '@literax/models/client.model';
import { IPlan, IReferenceRequest, IReferenceResponse } from '@literax/models/plan.model';
import { createAction, props } from '@ngrx/store';

import { IApiDetailedError } from '@literax/models/common';

export const validateClient = createAction(
  '@registration/validateClient',
  props<{ dataClient: IValidateClient }>()
);

export const validateClientSuccess = createAction(
  '@registration/validateClientSuccess',
  props<{ validCustomer: boolean }>()
);

export const validateClientError = createAction(
  '@registration/validateClientError',
  props<{ validCustomer: boolean }>()
);

export const getPlans = createAction(
  '@registration/getPlans'
);

export const getPlansSuccess = createAction(
  '@registration/getPlansSuccess',
  props<{ plans: IPlan[] }>()
);

export const getPlansError = createAction(
  '@registration/getPlansError',
  props<{ error: IApiDetailedError | string }>()
);

export const getPlanReference = createAction(
  '@registration/getPlanReference',
  props<{ productId: string; refRequest: IReferenceRequest }>()
);

export const getPlanReferenceSuccess = createAction(
  '@registration/getPlanReferenceSuccess',
  props<{ refResponse: IReferenceResponse }>()
);

export const getPlanReferenceError = createAction(
  '@registration/getPlanReferenceError',
  props<{ error: IApiDetailedError | string }>()
);

export const createClient = createAction(
  '@registration/createClient',
  props<{ dataClient: ICreateClient }>()
);

export const createClientSuccess = createAction(
  '@registration/createClientSuccess',
  props<{ createClientResponse: ICreateClientResponse }>()
);

export const createClientError = createAction(
  '@registration/createClientError',
  props<{ error: IApiDetailedError | string }>()
);

export const cleanRegistrationState = createAction('@registration/cleanRegistrationState');
