import * as registrationActions from './registration.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IPlan, IReferenceResponse } from '@literax/models/plan.model';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ClientService } from '@literax/services/clientV3/client.service';
import { IApiDetailedError } from '@literax/models/common';
import { ICreateClientResponse } from '@literax/models/client.model';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PlanService } from '@literax/services/plan/plan.service';
import { of } from 'rxjs';

@Injectable()
export class RegistrationEffects {
  constructor(
    private action$: Actions,
    private clientService: ClientService,
    private planService: PlanService,
    private modalService: NzModalService
  ) {}

  validateClient$ = createEffect(() =>
    this.action$.pipe(
      ofType(registrationActions.validateClient),
      switchMap((action) =>
        this.clientService.validateClient(action.dataClient)
          .pipe(
            map((resp) => registrationActions.validateClientSuccess({ validCustomer: true })),
            catchError((error: IApiDetailedError) =>{
              this.modalService.error({
                nzTitle: 'Error!',
                nzContent: error.message,
              });
              return of(
                registrationActions.validateClientError({ validCustomer: false })
              );
            })
          )
      )
    )
  );

  getPlans$ = createEffect(() =>
    this.action$.pipe(
      ofType(registrationActions.getPlans),
      switchMap((action) =>
        this.planService.getPlans()
          .pipe(
            map((plans: IPlan[]) => registrationActions.getPlansSuccess({ plans })),
            catchError((error: IApiDetailedError) =>{
              this.modalService.error({
                nzTitle: 'Error!',
                nzContent: error.message,
              });
              return of(
                registrationActions.getPlansError({ error })
              );
            })
          )
      )
    )
  );

  getPlanReference$ = createEffect(() =>
    this.action$.pipe(
      ofType(registrationActions.getPlanReference),
      switchMap((action) =>
        this.planService.getReference(action.productId, action.refRequest)
          .pipe(
            map((refResponse: IReferenceResponse) => registrationActions.getPlanReferenceSuccess({ refResponse })),
            catchError((error: IApiDetailedError) =>{
              this.modalService.error({
                nzTitle: 'Error!',
                nzContent: error.message,
              });
              return of(
                registrationActions.getPlanReferenceError({ error })
              );
            })
          )
      )
    )
  );

  createClient$ = createEffect(() =>
    this.action$.pipe(
      ofType(registrationActions.createClient),
      switchMap((action) =>
        this.clientService.createClient(action.dataClient)
          .pipe(
            map((resp: ICreateClientResponse) =>
              registrationActions.createClientSuccess({ createClientResponse: resp })
            ),
            catchError((error: IApiDetailedError) =>{
              this.modalService.error({
                nzTitle: 'Error!',
                nzContent: error.message,
              });
              return of(
                registrationActions.createClientError({ error })
              );
            })
          )
      )
    )
  );
}
