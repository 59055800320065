import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from '@literax/store';
import { Store, select } from '@ngrx/store';
import { Attachment, XmlValidationResponse } from '../../validator.interfaces';
import { EDeviceType } from '@literax/enums/application.enum';
import { ESigningMethod } from '@literax/enums/document.enum';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

enum tabTypes {
  SIGNATURES = 0,
  ATTACHMENTS,
}

interface Tab {
  icon: string;
  key: tabTypes;
  label: string;
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-show-signatures',
  templateUrl: './show-signatures.component.html',
  styleUrls: ['./show-signatures.component.scss'],
})
export class ShowSignaturesComponent implements OnInit, OnDestroy {
  document: XmlValidationResponse;
  signingMethods = ESigningMethod;
  tabs: Tab[] = [
    {
      icon: 'fal fa-file',
      label: 'SIGNATURES_SCREEN.TABS.SIGNATURES',
      key: tabTypes.SIGNATURES,
    },
    {
      icon: 'fal fa-paperclip',
      label: 'SIGNATURES_SCREEN.TABS.ATTACHMENTS',
      key: tabTypes.ATTACHMENTS,
    },
  ];
  currentTabIndex: number;
  currentTab: Tab;
  tabTypes = tabTypes;
  currentAttachment: Attachment;
  currentAttachmentIndex: number;
  attachments: Attachment[] = [];

  constructor(private store: Store<IAppState>, private router: Router) {}

  ngOnInit() {
    this.setCurrentTab(tabTypes.SIGNATURES);

    this.store
      .pipe(select((state) => state.validator.XMLValidatedDocument))
      .subscribe((data) => {
        if (!data) {
          this.router.navigate(['/validator/xml']);
        } else {
          this.document = data;
          this.attachments = this.document.attachments.map((attachment) => {
            return {
              ...attachment,
              signRequestSignatures: attachment.signRequestSignatures.map(
                (signature) => ({
                  ...signature,
                  signature: signature.signatures.reduce(
                    (acc, current) => ({ ...acc, ...current }),
                    {}
                  ),
                })
              ),
            };
          });
          this.currentAttachment = this.attachments[0];
        }
      });
  }
  ngOnDestroy() {}

  setCurrentTab(tabkey: tabTypes) {
    this.currentTabIndex = this.tabs.findIndex((tab) => tab.key === tabkey);
    this.currentTab = this.tabs[this.currentTabIndex];
  }

  setCurrentAttachment(attachment: Attachment) {
    this.currentAttachment = attachment;
    this.setCurrentTab(tabTypes.SIGNATURES);
  }
}
