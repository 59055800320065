import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { ValidatorService } from '../../validator.service';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { IAppState } from '@literax/store';
import {
  SendFilesToValidate,
  ValidatedFilesResponse,
  XmlValidationError,
} from '../../store/validator.actions';
import { ofType } from '@ngrx/effects';
import { NzModalService } from 'ng-zorro-antd/modal';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-xml-validation',
  templateUrl: './xml-validation.component.html',
  styleUrls: ['./xml-validation.component.scss'],
})
export class XmlValidationComponent implements OnInit, OnDestroy {
  permitedFiles =
    'text/xml,application/xml,application/atom+xml,application/zip,application/x-zip-compressed';

  captchaKey = environment.captchaKey;
  captchaSolved: boolean | null = false;
  files: Array<{ kind: string; file: File }> = [];
  verifyButtonDisabled = true;

  isZip: boolean;
  isXml: boolean;

  errorModalShow: boolean;
  errorMessage: string;

  constructor(
    private store: Store<IAppState>,
    private actions$: ActionsSubject,
    private router: Router
  ) {}

  ngOnInit() {
    this.actions$.pipe(ofType(ValidatedFilesResponse)).subscribe(() => {
      this.router.navigate(['validator/xml/document']);
    });

    this.actions$.pipe(ofType(XmlValidationError)).subscribe(() => {
      this.store
        .pipe(select((state) => state.validator.errors))
        .subscribe((errors) => {
          this.errorModalShow = true;
          this.errorMessage = errors.message;
        });
    });
  }

  ngOnDestroy() {}

  checkIfCanVerifyFiles() {
    this.verifyButtonDisabled = !(
      this.captchaSolved === true && this.files.length > 0
    );
  }

  testFiles(): void {
    this.store.dispatch(
      SendFilesToValidate({ payload: { files: this.files.map((f) => f.file) } })
    );
  }

  resolved(captchaResponse: string) {
    this.captchaSolved = captchaResponse !== null ? true : false;
    this.checkIfCanVerifyFiles();
  }

  nzCustomRequest = (item: any) => {
    return null;
  };

  removeFile(file: File, index: number) {
    this.files.splice(index, 1);
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    if (
      this.files.some(
        (loadedFile) =>
          loadedFile.file.type.indexOf('zip') !== -1 &&
          file.originFileObj.type.indexOf('zip') !== -1
      )
    ) {
      alert('solo esta permitido cargar un archivo zip por vez');
    } else if (
      this.files.some(
        (loadedFile) =>
          loadedFile.file.type.indexOf('zip') !== -1 &&
          file.originFileObj.type.indexOf('xml') !== -1
      )
    ) {
      alert('No se pueden mezclar archivos de tipo zip y xml');
    } else {
      this.files.push({
        kind: file.originFileObj.type.indexOf('zip') !== -1 ? 'zip' : 'xml',
        file: file.originFileObj,
      });
    }
    this.checkIfCanVerifyFiles();
  }
}
