import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { signaturePositionActions } from './singature-position.actions';
import {
  switchMap,
  catchError,
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import { SignaturePositionService } from '@literax/services/platform/signature-position.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { ServiceResponse } from '@literax/interfaces/service-response';

@Injectable()
export class SignaturePositionEffects {
  updateSignerPosition$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(signaturePositionActions.UpdateSignaturePosition),
      withLatestFrom(
        this.store$.select((state) => state.documents.selectedDocument)
      ),
      switchMap(([action, document]) =>
        this.signaturePositionService
          .updateSignaturePosition(document.id, action.payload)
          .pipe(
            map((response: ServiceResponse) =>
              signaturePositionActions.UpdateSignaturePositionSuccess({
                payload: response.signature_positions
              })
            ),
            catchError((err: HttpErrorResponse) => {
              return of(
                signaturePositionActions.UpdateSignaturePositionError({
                  payload: err.error as IAPIResponseError
                })
              );
            })
          )
      )
    );
  });

  updateSignaturePositionError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(signaturePositionActions.UpdateSignaturePositionError),
        tap((action) => {
          const message =
            (action.payload &&
              action.payload.error &&
              action.payload.error.title) ||
            'unknow';
          this.toastr.error('SIGNATURE_POSITION.ERROR.TITLE', message);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private store$: Store<IAppState>,
    private actions$: Actions,
    private toastr: I18nToastrService,
    private signaturePositionService: SignaturePositionService
  ) {}
}
