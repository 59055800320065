import {
  DocumentsResponse,
  ICreateDocumentRequest,
  IDocFilterField,
  IDocumentAcumulatedStatusses,
  IDocumentResponse,
  IDocumentViewSignaturesResponse,
  IEditAttachmentRequest,
  IQueryParameters,
} from '@literax/models/document.model';
import {
  IAPIError,
  IAPIResponseError,
} from '@literax/models/http/api/error.model';
import {
  ICancelatioReason,
  IDocumentDownload,
  IUpdateDocument,
  IUpdateDocumentSelected,
} from '../../../../models/http/document/document.model';
import {
  ICreateDocument,
  ICreateDocumentResponse,
} from '@literax/models/http/document/create-document.model';
import { createAction, props } from '@ngrx/store';

import { IAttachment } from '@literax/models/http/attachment.model';
import { IAttachmentDateGenerate } from '../../../../models/http/attachment.model';
import { ICreateSignRequestResponse } from '@literax/models/http/api/sign-request/sign-request.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IPaginationV3 } from '@literax/models/pagination.model';
import { ISearch } from '../../../../models/http/document/document.model';
import { IFlowCatalog } from '@literax/models/catalog.model';

export enum EDocumentActions {
  GetDocuments = '[Document] GetDocuments',
  GetDocumentsSuccess = '[Document] GetDocumentsSuccess',
  GetDocument = '[Document] GetDocument',
  GetDocumentSuccess = '[Document] GetDocumentSuccess',
  GetDocumentAttachment = '[Document] GetDocumentAttachment',
  GetDocumentAttachmentSuccess = '[Document] GetDocumentAttachmentSuccess',
  GetDocumentAttachmentError = '[Document] GetDocumentAttachmentError',
  ChangeAttachmentSelect = '[Document] ChangeAttachmentSelect',
  CreateDocument = '[Document] CreateDocument',
  CreateDocumentSuccess = '[Document] CreateDocumentSuccess',
  CreateDocumentError = '[Document] CreateDocumentError',
  CleanDocumentFormStates = '[Document] CleanDocumentFormStates',
  CleanDocumentsState = '[Document] CleanDocumentsState',
  UpdateFilterState = '[Document] UpdateFilterState',
  UpdateSelectedDocument = '[Document] Refresh Selected Document',
  UpdateSelectedDocumentHierarchy = '[Document] update Selected Document',
  UpdateFreeDocument = '[Document] Refresh Free Document',
  UpdateDocument = '[Document] UpdateDocument',
  UpdateLocalDocument = '[Document] UpdateLocalDocument',
  CleanSelectedDocumentState = '[Document] Clean Selected Document State',
  DeleteDocument = '[Document] Delete Document',
  DeleteDocumentSuccess = '[Document] Delete Document Success',
  DeleteDocumentError = '[Document] Delete Document Error',
  GetDocumentError = '[Document] GetDocumentError',
  DocumentMarkAs = '[Document] MarkAs',
  DocumentMarkAsSuccess = '[Document] MarkAsSuccess',
  DocumentMarkAsError = '[Document] MarkAsError',
  DownloadDocuments = '[Document] DownloadDocuments',
  DownloadDocumment = '[Document] DownloadDocument',
  SaveBlobDocument = '[Document] SaveBlobDocument',
  UpdateSignRequestSuccess = '[SignRequest] Update SR Success',
  UpdateDocumentSelected = '[Document] UpdateDocumentSelected',
  UpdateDocumentSelectedSuccess = '[Document] UpdateDocumentSelectedSuccess',
  DeleteDocumentList = '[Document] Delete document from list',
  DeleteDocumentListSuccess = '[Document] Delete document from list success',
  ChangeSort = '[Document] Change sort column',
  GetNavbarDocument = '[Document] GetNavbarDocument',
  GetNavbarDocumentSuccess = '[Document] GetNavbarDocumentSuccess',
  GetNavbarDocumentError = '[Document] GetNavbarDocumentError',
  DocumentMarkAsList = '[Document] MarkAsList',
  DocumentMarkAsReviewed = '[Document] MarkAsReviewed',
  SetOnlyOfficeKey = '[Document] Set onlyoffice key',
  DocumentsHidden = '[Document] DocumentsHidden',
  DocumentsHiddenSuccess = '[Document] DocumentsHiddenSuccess',
  DocumentHiddenType = '[Document] DocumentHiddenType',
  DocumentHiddenTypeSuccess = '[Document] DocumentHiddenTypeSuccess',
  DocumentHiddenTypeError = '[Document] DocumentHiddenTypeError',
  DocumentMarkAsApproved = '[Document] MarkAsApproved',
  DocumentSetMinApprovers = '[Document] SetMinApprovers',

  DocumentCancelProcess = '[Document] Document process cancel',
  DocumentCancelProcessSuccess = '[Document] Document process cancel success',
  DocumentCancelProcessError = '[Document] Document process cancel error',

  GetCancellationReasons = '[Document] Get cancellation reasons',
  GetCancellationReasonsSuccess = '[Document] Get cancellation reasons success',
  GetCancellationReasonsError = '[Document] Get cancellation reasons error',

  SaveDateGenerate = '[Document] SaveDateGenerate',
  SaveDateGenerateSuccess = '[Document] SaveDateGenerateSuccess',
  SaveDateGenerateError = '[Document] SaveDateGenerateError',
  DocumentCreateAssigment = '[Document] Create assigment',

  SetDocumentAttachmentBase64Data = '[Document] SetDocumentAttachmentBase64Data',
  ClearDocumentAttachmentsBase64Data = '[Document] ClearDocumentAttachmentsBase64Data',
  SetLastUploadedAttachment = '[Document] SetLastUploadedAttachment',
  ClearLastUploadedAttachment = '[Document] ClearLastUploadedAttachment',

  GetPendingMessages = '[Chat] GetPendingMessages',
  GetPendingMessagesSuccess = '[Chat] GetPendingMessagesSuccess',
  GetPendingMessagesError = '[Chat] GetPendingMessagesError',

  //getdocument view document with signatures
  GetViewDocumentSignatures = '[Document] GetViewDocumentSignatures',
  GetViewDocumentSignaturesSuccess = '[Document] GetViewDocumentSignaturesSuccess',
  GetViewDocumentSignaturesError = '[Document] GetViewDocumentSignaturesError',
  CleanSelectDocumentViewSignatureState = '[Document] Clean Selected Document view signatures State',

  GetDocumentAcumulatedStatusses = '[Document] GetDocumentAcumulatedStatusses',
  GetDocumentAcumulatedStatussesSuccess = '[Document] GetDocumentAcumulatedStatussesSuccess',
  GetDocumentAcumulatedStatussesError = '[Document] GetDocumentAcumulatedStatussesError',

  SetFlowId = '[Document] SetFlowId',
  SetFlowIdSuccess = '[Document] SetFlowIdSuccess',
  SetFlowIdError = '[Document] SetFlowIdError',

  SetProcessType = '[Document] SetProcessType',
  SetProcessTypeSuccess = '[Document] SetProcessTypeSuccess',
  SetProcessTypeError = '[Document] SetProcessTypeError',

  SetFilterParams = '[Document] SetFilterParams',
  SetFilterParamsSuccess = '[Document] SetFilterParamsSuccess',
  SetFilterParamsError = '[Document] SetFilterParamsError',

  ClearDocumentListsState = '[Document] ClearDocumentListsState',

  SetFlowIdTab = '[Document] SetFlowIdTab',
  SetFlowIdTabSuccess = '[Document] SetFlowIdTabSuccess',
  SetFlowIdTabError = '[Document] SetFlowIdTabError',
}

export const ClearDocumentListsState = createAction(
  EDocumentActions.ClearDocumentListsState
);

export const SetFlowId = createAction(
  EDocumentActions.SetFlowId,
  props<{ payload: { id: number; flow: IFlowCatalog; filters: IDocFilterField[]; show:number } }>()
);

export const SetProcessType = createAction(
  EDocumentActions.SetProcessType,
  props<{ payload: { type: string } }>()
);

export const SetFilterParams = createAction(
  EDocumentActions.SetFilterParams,
  props<{ payload: { filters: IDocFilterField[] } }>()
);

// =====

export const GetDocumentAcumulatedStatusses = createAction(
  EDocumentActions.GetDocumentAcumulatedStatusses,
  props<{ payload: { id: number; flow: IFlowCatalog; filters: IDocFilterField[]; show:number } }>()
);

export const GetDocumentAcumulatedStatussesSuccess = createAction(
  EDocumentActions.GetDocumentAcumulatedStatussesSuccess,
  props<{ payload: IDocumentAcumulatedStatusses }>()
);

export const ClearLastUploadedAttachment = createAction(
  EDocumentActions.ClearLastUploadedAttachment
);

export const ClearDocumentAttachmentsBase64Data = createAction(
  EDocumentActions.ClearDocumentAttachmentsBase64Data
);

export const SetLastUploadedAttachment = createAction(
  EDocumentActions.SetLastUploadedAttachment,
  props<{ payload: IAttachment }>()
);

export const SetDocumentAttachmentBase64Data = createAction(
  EDocumentActions.SetDocumentAttachmentBase64Data,
  props<{ payload: { attachmentId: number; data: string } }>()
);

export const DocumentSetMinApprovers = createAction(
  EDocumentActions.DocumentSetMinApprovers,
  props<{ payload: number }>()
);

export const SetOnlyOfficeKey = createAction(
  EDocumentActions.SetOnlyOfficeKey,
  props<{ payload: string }>()
);

export const UpdateSignRequestsSuccess = createAction(
  EDocumentActions.UpdateSignRequestSuccess,
  props<{ payload: ICreateSignRequestResponse }>()
);

export const CleanDocumentFormStates = createAction(
  EDocumentActions.CleanDocumentFormStates
);
export const CleanDocumentsState = createAction(
  EDocumentActions.CleanDocumentsState
);
export const DocumentMarkAs = createAction(
  EDocumentActions.DocumentMarkAs,
  props<{ payload: string }>()
);
export const DocumentMarkAsSuccess = createAction(
  EDocumentActions.DocumentMarkAsSuccess,
  props<{ payload: IDocument }>()
);
export const DocumentMarkAsError = createAction(
  EDocumentActions.DocumentMarkAsError,
  props<{ payload: IAPIError }>()
);
export const DocumentMarkAsReviewed = createAction(
  EDocumentActions.DocumentMarkAsReviewed,
  props<{ payload: { action: string; sign_request: number } }>()
);

export const GetDocuments = createAction(
  EDocumentActions.GetDocuments,
  props<{ queryParameters?: IQueryParameters }>()
);
export const GetDocumentsSuccess = createAction(
  EDocumentActions.GetDocumentsSuccess,
  props<{ documents: DocumentsResponse; pagination: IPaginationV3 }>()
);
export const DownloadDocuments = createAction(
  EDocumentActions.DownloadDocuments
);
export const SaveBlobDocument = createAction(
  EDocumentActions.SaveBlobDocument,
  props<{ payload: IDocumentDownload }>()
);
export const DownloadDocument = createAction(
  EDocumentActions.DownloadDocumment,
  props<{ payload: { id: number } }>()
);
export const GetDocument = createAction(
  EDocumentActions.GetDocument,
  props<{ payload: number | string }>()
);
export const GetDocumentSuccess = createAction(
  EDocumentActions.GetDocumentSuccess,
  props<{ payload: IDocument }>()
);
export const GetDocumentError = createAction(
  EDocumentActions.GetDocumentError,
  props<{ payload: IAPIResponseError }>()
);

// ! Deprecated: change action for CreateDocumentV3
export const CreateDocument = createAction(
  EDocumentActions.CreateDocument,
  props<{ payload: ICreateDocument }>()
);
// ! Deprecated: change action for CreateDocumentV3Success
export const CreateDocumentSuccess = createAction(
  EDocumentActions.CreateDocumentSuccess,
  props<{ payload: ICreateDocumentResponse }>()
);
// ! Deprecated: change action for CreateDocumentV3Error
export const CreateDocumentError = createAction(
  EDocumentActions.CreateDocumentError,
  props<{ payload: IAPIResponseError }>()
);

// TODO: Remove suffix V3
export const CreateDocumentV3 = createAction(
  '[Document] CreateDocumentV3',
  props<{ payload: ICreateDocumentRequest }>()
);
// TODO: Remove suffix V3
export const CreateDocumentV3Success = createAction(
  '[Document] CreateDocumentV3Success',
  props<{ payload: IDocumentResponse }>()
);
// TODO: Remove suffix V3
export const CreateDocumentV3Error = createAction(
  '[Document] CreateDocumentV3Error',
  props<{ payload: IAPIResponseError }>()
);

export const CleanSelectedDocumentState = createAction(
  EDocumentActions.CleanSelectedDocumentState
);
export const UpdateSelectedDocument = createAction(
  EDocumentActions.UpdateSelectedDocument
);
export const UpdateFreeDocument = createAction(
  EDocumentActions.UpdateFreeDocument,
  props<{ payload: string }>()
);
export const UpdateLocalDocument = createAction(
  EDocumentActions.UpdateLocalDocument,
  props<{ payload: ICreateDocumentResponse }>()
);

export const GetNavbarDocumentSuccess = createAction(
  EDocumentActions.GetNavbarDocumentSuccess,
  props<{ payload: ISearch[] }>()
);
export const GetNavbarDocumentError = createAction(
  EDocumentActions.GetNavbarDocumentError,
  props<{ payload: IAPIResponseError }>()
);
export const GetNavbarDocument = createAction(
  EDocumentActions.GetNavbarDocument,
  props<{ payload: string }>()
);

export const DocumentMarkAsList = createAction(
  EDocumentActions.DocumentMarkAsList,
  props<{ payload: { id: number; action: string } }>()
);

export const UpdateDocument = createAction(
  EDocumentActions.UpdateDocument,
  props<{ payload: { documentId: number; document: IUpdateDocument } }>()
);

export const UpdateSelectedDocumentHierarchy = createAction(
  EDocumentActions.UpdateSelectedDocumentHierarchy,
  props<{ payload: { documentId: number; hierarchy: boolean } }>()
);

export const DeleteDocument = createAction(
  EDocumentActions.DeleteDocument,
  props<{ payload: { documentId: number } }>()
);

export const DeleteDocumentSuccess = createAction(
  EDocumentActions.DeleteDocumentSuccess
);

export const DeleteDocumentError = createAction(
  EDocumentActions.DeleteDocumentError,
  props<{ payload: IAPIError }>()
);

export const DeleteDocumentList = createAction(
  EDocumentActions.DeleteDocumentList,
  props<{ payload: { documentId: number } }>()
);

export const DeleteDocumentListSuccess = createAction(
  EDocumentActions.DeleteDocumentListSuccess
);

export const GetDocumentAttachment = createAction(
  EDocumentActions.GetDocumentAttachment,
  props<{ payload: { documentId: number; attachmenId: number } }>()
);

export const GetDocumentAttachmentSuccess = createAction(
  EDocumentActions.GetDocumentAttachmentSuccess,
  props<{ payload: IAttachment }>()
);
export const GetDocumentAttachmentError = createAction(
  EDocumentActions.GetDocumentAttachmentError,
  props<{ payload: IAPIResponseError }>()
);

export const ChangeAttachmentSelect = createAction(
  EDocumentActions.ChangeAttachmentSelect,
  props<{ payload: IAttachment }>()
);

export const UpdateDocumentSelected = createAction(
  EDocumentActions.UpdateDocumentSelected,
  props<{
    payload: { documentId: number; document: IUpdateDocumentSelected };
  }>()
);

export const UpdateDocumentSelectedSuccess = createAction(
  EDocumentActions.UpdateDocumentSelectedSuccess
);

export const ChangeSort = createAction(
  EDocumentActions.ChangeSort,
  props<{ payload: string }>()
);

export const DocumentsHidden = createAction(
  EDocumentActions.DocumentsHidden,
  props<{ payload: boolean }>()
);

export const DocumentsHiddenSuccess = createAction(
  EDocumentActions.DocumentsHiddenSuccess,
  props<{ payload: IDocument[] }>()
);

export const DocumentsHiddenType = createAction(
  EDocumentActions.DocumentHiddenType,
  props<{ documentId: number; hidden: boolean }>()
);

export const DocumentsHiddenTypeSuccess = createAction(
  EDocumentActions.DocumentHiddenTypeSuccess
);
export const DocumentsHiddenTypeError = createAction(
  EDocumentActions.DocumentHiddenTypeError
);

export const DocumentMarkAsApproved = createAction(
  EDocumentActions.DocumentMarkAsApproved,
  props<{ payload: { action: string; sign_request: number } }>()
);

export const DocumentCancelProcess = createAction(
  EDocumentActions.DocumentCancelProcess,
  props<{ payload: string }>()
);

export const DocumentCancelProcessSuccess = createAction(
  EDocumentActions.DocumentCancelProcessSuccess
);

export const DocumentCancelProcessError = createAction(
  EDocumentActions.DocumentCancelProcessError
);

export const GetCancellationReasons = createAction(
  EDocumentActions.GetCancellationReasons
);

export const GetCancellationReasonsSuccess = createAction(
  EDocumentActions.GetCancellationReasonsSuccess,
  props<{ payload: ICancelatioReason[] }>()
);

export const GetCancellationReasonsError = createAction(
  EDocumentActions.GetCancellationReasonsError
);

export const SaveDateGenerate = createAction(
  EDocumentActions.SaveDateGenerate,
  props<{
    payload: {
      documentId: number;
      attachmentId: number;
      attachment: IAttachmentDateGenerate;
    };
  }>()
);

export const SaveDateGenerateSuccess = createAction(
  EDocumentActions.SaveDateGenerateSuccess
);

export const SaveDateGenerateError = createAction(
  EDocumentActions.SaveDateGenerateError,
  props<{ payload: IAPIResponseError }>()
);

export const DocumentCreateAssigment = createAction(
  EDocumentActions.DocumentCreateAssigment,
  props<{
    payload: {
      documentId: number;
      receiver: { userId: number };
      userName: string;
    };
  }>()
);

export const GetPendingMessages = createAction(
  EDocumentActions.GetPendingMessages,
  props<{
    payload: { chat: string; app: string; token: string; userName: string };
  }>()
);

export const GetPendingMessagesSuccess = createAction(
  EDocumentActions.GetPendingMessagesSuccess,
  props<{ payload: number }>()
);

export const GetPendingMessagesError = createAction(
  EDocumentActions.GetPendingMessagesError,
  props<{ payload: IAPIResponseError }>()
);

export const EditAttachment = createAction(
  '[Document] EditAttachment',
  props<{
    promissory: IEditAttachmentRequest;
    documentId: number;
    attachmentId: number;
  }>()
);

export const EditAttachmentSuccess = createAction(
  '[Document] EditAttachmentSuccess',
  props<{ payload: IDocumentResponse }>()
);

export const EditAttachmentError = createAction(
  '[Document] EditAttachmentError',
  props<{ payload: IAPIResponseError }>()
);

export const GetViewDocumentSignatures = createAction(
  EDocumentActions.GetViewDocumentSignatures,
  props<{ payload: number | string }>()
);
export const GetViewDocumentSignaturesSuccess = createAction(
  EDocumentActions.GetViewDocumentSignaturesSuccess,
  props<{ payload: IDocumentViewSignaturesResponse }>()
);

export const GetViewDocumentSignaturesError = createAction(
  EDocumentActions.GetViewDocumentSignaturesError,
  props<{ payload: IAPIResponseError }>()
);

export const CleanSelectDocumentViewSignatureState = createAction(
  EDocumentActions.CleanSelectDocumentViewSignatureState
);

export const SetFlowIdTab = createAction(
  EDocumentActions.SetFlowIdTab,
  props<{ payload: { id: number; flow: IFlowCatalog; filters: IDocFilterField[]; show:number } }>()
);

export const SetFlowIdTabSuccess = createAction(
  EDocumentActions.SetFlowIdTabSuccess,
  props<{ payload: IDocumentAcumulatedStatusses }>()
);