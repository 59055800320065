import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

import { LiteraxStepperComponent } from '../literax-stepper.component';

@Directive({
  selector: '[literaxStepperNext]'
})
export class LiteraxStepperNextDirective {

  constructor(
    private literaxStepper: LiteraxStepperComponent
  ) { }

  @HostListener('click')
  private onClick() {
    this.literaxStepper.nextStep()
  }
}
