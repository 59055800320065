<form [formGroup]="signatureForm" #f="ngForm" (ngSubmit)="onSubmit(f.value)">
  <div class="container">
    <div class="row">
      <div formGroupName="electronicSignature" style="width: 100%">
        <div class="col-12">
          <literax-form-field
            [label]="'FORM.SIGNATURE.NAME' | translate"
            [apiError]="namesErrors$ | async"
            formControlName="name"
          >
          </literax-form-field>
        </div>
        <div class="col-12">
          <literax-form-field
            [label]="'FORM.SIGNATURE.EMAIL' | translate"
            [apiError]="emailsErros$ | async"
            formControlName="email"
          >
          </literax-form-field>
        </div>
        <div
          *ngFor="let company of moralInfo.controls as compa; let i = index"
          formArrayName="moralInfo"
          style="width: 100%"
        >
          <div
            *ngIf="company.get('companyName')"
            [formGroupName]="i"
            class="business-info-container"
          >
            <div class="col-12">
              <literax-form-field
                [label]="'FORM.SIGNATURE.BUSINESS_NAME' | translate"
                formControlName="companyName"
              >
              </literax-form-field>
            </div>
            <div class="col-12">
              <literax-form-field
                [label]="'FORM.SIGNATURE.ROL' | translate"
                formControlName="role"
              >
              </literax-form-field>
            </div>
          </div>
        </div>
        <div class="col-12 terms-container">
          <span>
            {{
              'FORM.SIGNATURE.TERMS_AND_CONDITIONS'
                | translate : { name: nameDocument }
            }}
          </span>
        </div>
        <div class="col-12">
          <span class="nameSignature title-font mt-2">
            {{ 'FORM.SIGNATURE.DIGITAL_TRACE' | translate }}</span
          >
          <div class="signatureContainer">
            <literax-signature-canvas
              (signatureDone)="saveSignature($event)"
            ></literax-signature-canvas>
          </div>
        </div>

        <div
          class="col-12 confirmationCode"
          *ngIf="
            plusSignature &&
            signatureVerificationType === eSignatureVerificationTypeId.EMAIL
          "
        >
          <literax-form-field
            [label]="'FORM.SIGNATURE.AUTHENTICATION_CODE' | translate"
            [apiError]="tokenErrors$ | async"
            formControlName="twoFactorConfirmationToken"
            [hint]="
              showHint
                ? ('FORM.SIGNATURE.AUTENTHICATION_CODE_TEXT' | translate)
                : null
            "
          >
          </literax-form-field>
          <span class="terminos title-font" *ngIf="!showHint">
            {{ 'FORM.SIGNATURE.AUTENTHICATION_CODE_TEXT' | translate }}
          </span>
          <button
            nz-button
            nzType="primary"
            type="button"
            (click)="twoFactorToken()"
          >
            {{ 'FORM.SIGNATURE.TWO_FACTOR_TOKEN_BUTTON' | translate }}
          </button>
        </div>

        <div
          class="col-12 confirmationCode"
          *ngIf="
            plusSignature &&
            signatureVerificationType === eSignatureVerificationTypeId.SMS
          "
        >
          <nz-form-item>
            <nz-form-label nzFor="smsField" nzRequired>{{
              'FORM.SIGNATURE.AUTHENTICATION_CODE' | translate
            }}</nz-form-label>
            <nz-form-control
              [nzErrorTip]="smsErrors"
              [nzExtra]="
                'FORM.SIGNATURE.AUTENTHICATION_CODE_TEXT_SMS' | translate
              "
              [nzValidateStatus]="
                signatureForm
                  .get('electronicSignature')
                  .get('twoFactorConfirmationToken').errors?.smsError
                  ? 'error'
                  : signatureForm
                      .get('electronicSignature')
                      .get('twoFactorConfirmationToken')
              "
            >
              <input
                nz-input
                formControlName="twoFactorConfirmationToken"
                type="text"
                id="smsField"
              />

              <ng-template #smsErrors let-control>
                <ng-container
                  *ngIf="
                    signatureForm
                      .get('electronicSignature')
                      .get('twoFactorConfirmationToken').errors?.smsError
                  "
                >
                  {{
                    signatureForm
                      .get('electronicSignature')
                      .get('twoFactorConfirmationToken').errors?.smsError
                      ?.message
                  }}
                </ng-container>
                <ng-container *ngIf="control?.hasError('required')">
                  {{
                    'MODULES.CONFIGURATIONS.FORM.PARTICIPANTS.ERRORS.REQUIRED'
                      | translate
                  }}
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <button
            class="mt-3"
            nz-button
            nzType="default"
            type="button"
            (click)="twoFactorToken()"
            [disabled]="smsCounter < 15"
          >
            <span *ngIf="smsCounter < 15"
              >({{ smsCounter }}) {{ 'FORM.SIGNATURE.TWO_FACTOR_TOKEN_BUTTON_NEW' | translate }}</span
            ><span *ngIf="smsCounter === 15">{{
              'FORM.SIGNATURE.TWO_FACTOR_TOKEN_BUTTON' | translate
            }}</span>
          </button>
        </div>

        <br />
        <div
          class="col-12"
          *ngIf="
            plusSignature &&
            signatureVerificationType === eSignatureVerificationTypeId.TOKEN
          "
        >
          <label class="label-wizard-form">
            {{ verificationTokenLabel | async | translate }}
          </label>
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              nz-input
              placeholder="{{
                'CONFIGURATIONS_MODULE.TOKEN-RSA.FORM.PLACEHOLDER' | translate
              }}"
              formControlName="twoFactorConfirmationToken"
            />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <span
              nz-icon
              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="passwordVisible = !passwordVisible"
            ></span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</form>
