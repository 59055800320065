import * as UsersActions from '@literax/components/configurations/users/states/users.actions';

import { Actions, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  getErrorRegisterTitle,
  getErrorRegistro,
  selectEmailError,
  selectPasswordError,
  selectRFCError
} from '@literax/components/configurations/users/states/users.selector';
import { map, tap } from 'rxjs/operators';

import { BaseService } from '@literax/services/base-config/base.service';
import { ConfirmationTycModalComponent } from '@literax/modules/shared/confirmation-tyc-modal/confirmation-tyc-modal.component';
import { CustomFormValidator } from '@literax/modules/shared/form-lib/custom-form.validator';
import { IAppState } from '@literax/store';
import { IUserConfirmation } from '@literax/components/configurations/users/models/users.interface';
import { Logout } from '@literax/modules/auth/store/auth.actions';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { RegistrationSuccessModalComponent } from '@literax/modules/shared/registration-success-modal/registration-success-modal.component';
import { SignService } from '@literax/services/sign/sign.service';
import { TranslateService } from '@ngx-translate/core';
import { TycModalComponent } from '@literax/modules/shared/tyc-modal/tyc-modal.component';
import { environment } from '@environments/environment';
import { selectCurrentLang } from '@literax/modules/configurations/store/config/config.selectors';
import { setLang } from '@literax/modules/configurations/store/config/config.actions';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-register-clientusers',
  templateUrl: './register-clientusers.component.html',
  styleUrls: ['./register-clientusers.component.scss']
})
export class RegisterClientusersComponent implements OnInit {
  registerClientForm: FormGroup;
  tokenActivate: string;
  errorEmail$ = this.store.pipe(select(selectEmailError));
  errorPassword$ = this.store.pipe(select(selectPasswordError));
  errorRFC$ = this.store.pipe(select(selectRFCError));
  error$ = this.store.pipe(select(getErrorRegistro));
  errorTitle$ = this.store.pipe(select(getErrorRegisterTitle));
  url: string = environment.literaxUrl;
  lang$: Observable<string>;
  errorTitle = '';

  /*
    wip: preparacion para modificacion de endpoint
      formErrors$ = this.store.pipe(select(getFormErrors));
    :wip preparacion para modificacion de endpoint
  */
  submitted = false;

  ngOnInit() {
    this.reactiveForm();

    this.route.queryParams.subscribe((params) => {
      const locale = params['locale'];
      if (locale) {
        this.setLang({ event: 'set lang', lang: locale });
        this.updateCurrentLang(locale);
      }
    });

    this.lang$ = this.store.pipe(
      select(selectCurrentLang),
      map((lang: string) => lang || 'es'),
      tap((lang: string) => this.updateCurrentLang(lang))
    );

    this.errorTitle$.pipe().subscribe((data) => {
      this.errorTitle = data[1];
    });
  }

  updateCurrentLang(lang: string) {
    if (lang) {
      this.translate.use(lang);
      this.signService.locale = lang;
      this.translate.currentLang = lang;
    }
  }

  setLang(e: { event: string; lang: string }) {
    this.store.dispatch(setLang({ payload: e.lang }));
    this.updateCurrentLang(e.lang);
  }

  onRegistrationClicked() {
    this.tokenActivate = this.route.snapshot.params.token;
    this.router.navigate(['/login_client/', this.tokenActivate]);
  }

  onSubmit(data: IUserConfirmation) {
    this.tokenActivate = this.route.snapshot.params.token;
    this.translate.get('TYC_MODAL.TITLE').subscribe((nzTitle) => {
      const modalTyC = this.modal.create({
        nzMask: false,
        nzTitle,
        nzContent: TycModalComponent,
        nzWidth: 800,
        nzComponentParams: {
          isRegister: true,
          token: this.tokenActivate
        },
        nzOnCancel: () => {
          this.modal.closeAll();
        }
      });
      modalTyC.componentInstance.close.subscribe((result) => {
        if (result) {
          modalTyC.close();
          const modalConfirm = this.modal.create({
            nzMask: false,
            nzContent: ConfirmationTycModalComponent,
            nzWidth: 800,
            nzClosable: false
          });
          modalConfirm.componentInstance.reject.subscribe((reject) => {
            if (reject) {
              this.store.dispatch(Logout());
            }
          });
          modalConfirm.componentInstance.return.subscribe((back) => {
            if (back) {
              modalConfirm.close();
              this.onSubmit(data);
            }
          });
        }
      });
      modalTyC.componentInstance.accepted.subscribe((accepted) => {
        if (accepted) {
          this.store.dispatch(
            UsersActions.registerClientUsersConfirmations({
              user: data,
              token: this.tokenActivate
            })
          );
          this.actions$
            .pipe(ofType(UsersActions.registerClientUsersConfirmationsSucess))
            .subscribe(() => {
              this.openDialog();
            });
        }
      });
    });
  }

  openDialog(): void {
    const modalSuccess = this.modal.create({
      nzMask: false,
      nzContent: RegistrationSuccessModalComponent,
      nzWidth: 800,
      nzClosable: false,
      nzFooter: null
    });
  }

  reactiveForm() {
    this.registerClientForm = this.formBuild.group({
      name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      rfc: ['', [CustomFormValidator.naturalPersonRFC]],
      join_to_client_user: false,
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  constructor(
    private router: Router,
    private formBuild: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<IAppState>,
    private actions$: Actions,
    private translate: TranslateService,
    private baseService: BaseService,
    private signService: SignService,
    private modal: NzModalService
  ) {}
}
