import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import {fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';


import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() searchLabel = 'search';
  @Input() debounceTime = 400;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  @Input() set readonly(disabled: boolean) {
    this.readOnly = disabled;
  }
  divActive = false;
  readOnly = false;
  @ViewChild('searchBar', { static: true }) input: ElementRef<HTMLInputElement>;

  constructor() { }

  ngAfterViewInit() {

    fromEvent(this.input.nativeElement, 'keypress')
    .subscribe((e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.keyCode === 13 || this.isTextSelected()) {
        this.input.nativeElement.value = '';
      } else {        
        this.input.nativeElement.focus()
        this.input.nativeElement.setSelectionRange(this.input.nativeElement.value.length, this.input.nativeElement.value.length)
      }
    })

    fromEvent(this.input.nativeElement,'keyup')
      .pipe(
          filter(Boolean),
          debounceTime(this.debounceTime),
          distinctUntilChanged(),
          tap((text) => {
            this.input.nativeElement.focus()
            this.valueChanged.emit(this.input.nativeElement.value)
          })
      )
      .subscribe();
  }

  ngOnInit() {
  }

  isTextSelected() {
    var selecttxt: any = '';
    if (window.getSelection) {
        selecttxt = window.getSelection();
    }
 
    if (selecttxt == '') {
        return false;
    }
    return true;
 
}

  ngOnDestroy() { }

  toggleActive() {
    this.divActive = !this.divActive;
  }
  buttonSearch() {
    this.valueChanged.emit(this.input.nativeElement.value);
  }
}
