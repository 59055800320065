export interface IPaymentConf {
    clientId: string,
    client: string,
    reference: string,
    amount: string,
    paymentProviders: string,
    accessToDomiciliation: string,
    forcedDomiciliation: string,
    customerId: string,
    domiciledName: string,
    conceptId: string,
    concept: string,
    currency: string
}


export interface IPaymentConfWidget {
    mode: string,
    data: IPaymentConfWidgetData,
    access_token: string,
    refresh_token: string
}

export interface IPaymentConfWidgetData {
    clientId: string,
    externalClientId: string, 
    reference: string,
    currency: string,
    amount: string,
    recurring: boolean,
    recurringData: IPaymentConfWidgetRecurringData,
    provider: string
}

export interface IPaymentConfWidgetRecurringData {
    name: string,
    conceptId: string,
    conceptName: string
}

export const testValidPlans = [
  'b0e7e764-b508-eb11-a813-000d3a3682ac',
  'd9405922-b60c-ea11-a811-000d3a368e62',
  'c4d7175b-b80c-ea11-a811-000d3a368e62',
  '45c78ca0-78b1-ea11-a812-000d3a37ef43',
  'ffaa3948-79b1-ea11-a812-000d3a37ef43',
  'e822fd9f-b608-eb11-a813-000d3a3682ac',
  'c053112a-c508-eb11-a813-000d3a37ef43',
  '9f76a5c6-e208-eb11-a813-000d3a37ef43',
  'f90ddc2f-e308-eb11-a813-000d3a37ef43',
  'c553f994-e308-eb11-a813-000d3a37ef43',
  'b064c0da-4d09-eb11-a813-000d3a37ef43',
  '6d58578c-4e09-eb11-a813-000d3a37ef43',
  'a6e14946-a5b0-ea11-a812-000d3a3682ac',
  'a0627c89-6fb1-ea11-a812-000d3a37ef43',
  '9e8f050c-a5eb-ea11-a817-000d3a37ef43',
  '7e9f9347-1b7d-eb11-b1ab-000d3a378f1b',
  '3c9f9347-1b7d-eb11-b1ab-000d3a378f1b',
  '309f9347-1b7d-eb11-b1ab-000d3a378f1b',
  '5e9f9347-1b7d-eb11-b1ab-000d3a378f1b',
  '529f9347-1b7d-eb11-b1ab-000d3a378f1b',
  '729f9347-1b7d-eb11-b1ab-000d3a378f1b',
  'f9259860-d8b8-eb11-8236-000d3a5b5caf',
  'ffaa3948-79b1-ea11-a812-000d3a37ef43',
];