import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { ISignerCoordinatesUpdater } from '@literax/models/http/api/client/pdf.model';
import { Observable } from 'rxjs';
import { BaseService } from '../base-config/base.service';

@Injectable({
  providedIn: 'root'
})
export class SignaturePositionService {
  updateSignaturePosition(
    documentId: number,
    payload: ISignerCoordinatesUpdater
  ): Observable<ServiceResponse> {
    const body = {
      signature_positions: {
        ...payload.coordinates,
        sign_request_ids: payload.sign_request_id
      }
    };
    return this.base.put(
      `/documents/${documentId}/attachments/${payload.attachment_id}/signature_positions`,
      body
    );
  }

  constructor(private base: BaseService) {}
}
