import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ETransactionType } from '@literax/enums/document.enum';
import { IAppState } from '@literax/store';
import { IDocumentResponse } from '@literax/models/document.model';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { filter } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-document-title',
  templateUrl: './document-title.component.html',
  styleUrls: ['./document-title.component.scss'],
})
export class DocumentTitleComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() process: string;
  @Input() colorStatusDoc: string;
  @Input() icon: string;
  @Input() colorProcess: string;
  @Input() isEditable = false;
  @Input() tag?: string;
  @Input() tagColor = 'success';
  @Input() backIcon = false;
  @Input() status: string;
  @Input() statusColor: string;

  document$: Observable<IDocumentResponse> = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.selectDocument),
    filter((document) => document !== null)
  );

  @Output() newName: EventEmitter<string> = new EventEmitter<string>();

  visibleInput = false;
  paramsBackButton = {};

  constructor(private store: Store<IAppState>, private location: Location) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.document$.subscribe((document) => {
      this.title = document.name;
      this.process = `WIZARD.PROCESSES.${
        ETransactionType[document?.transactionType]
      }`.toUpperCase();
      this.status = `DOCUMENT-CONFIGURATION.PROCESS.${document?.status?.key.toUpperCase()}`;
      this.statusColor = document?.status?.color;
      this.colorStatusDoc = document.status.color;
      this.colorProcess = document.status.color;
      this.icon = document.attachments.length > 1 ? 'fal fa-paperclip' : '';

      const stateNavigation: any = this.location.getState();
      this.paramsBackButton = {
        flow: document.flowName,
        process: stateNavigation.process,
      };
    });
  }

  /**
   * editName
   * * Función para cambiar la bandera que indica
   * * si se muestra el input para editar el title
   */
  editName() {
    this.visibleInput = !this.visibleInput;
  }

  /**
   * saveName
   * * Función que emite el nuevo nombre del documento
   */
  saveName() {
    this.visibleInput = !this.visibleInput;
    this.newName.emit(this.title);
  }
}
