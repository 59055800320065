import { ActionsSubject, Store } from '@ngrx/store';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AttachmentActions } from '@literax/modules/documents/store/workspace/actions/attachment.actions';
import { BehaviorSubject } from 'rxjs';
import { CustomFormValidator } from '@literax/modules/shared/form-lib/custom-form.validator';
import { EButtonActions } from '@literax/modules/shared/compose-attachment-file/enums/button-actions.enum';
import { EFileTypes } from '@literax/components/platform/wizard-v3/enums/file-types.enum';
import { EKindAttachment } from '@literax/enums/attachment.enum';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/attachment.model';
import { ICreateAttachment } from '@literax/models/http/Attachment/create-attachment.model';
import { IDialogData } from '@literax/modules/shared/compose-attachment-file/models/dialog-data';
import { IAttachment as LegacyIAttachment } from '@literax/models/http/attachment.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';
import { ofType } from '@ngrx/effects';


import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-modal-attachment',
  templateUrl: './modal-attachment.component.html',
  styleUrls: ['./modal-attachment.component.scss'],
})
export class ModalAttachmentComponent implements OnInit, OnDestroy {
  // TODO depercated prop
  @Input() data: IDialogData;
  @Input() attachment: Partial<IAttachment> | Partial<LegacyIAttachment>;
  legacyAttachment: LegacyIAttachment;
  @Input() action: EButtonActions;
  EButtonActions = EButtonActions;

  attachmentForm: FormGroup;
  permittedFiles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([
    '.pdf',
    '.docx',
  ]);

  fileType: string;

  constructor(
    private formBuilder: FormBuilder,
    private modal: NzModalService,
    private store: Store<IAppState>,
    private actions$: ActionsSubject
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.actions$
      .pipe(untilDestroyed(this), ofType(WorkspaceActions.getDocumentSuccess))
      .subscribe(() => this.modal.closeAll());
  }

  ngOnDestroy(): void {}

  initForm(): void {
    this.typePermittedFiles();
    switch (this.action) {
      case EButtonActions.WRITE:
        this.attachmentForm = this.formBuilder.group({
          name: new FormControl(null, [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(70),
            CustomFormValidator.documentNameFormat,
          ]),
        });
        break;
      case EButtonActions.UPLOAD:
        this.attachmentForm = this.formBuilder.group({
          name: new FormControl(this.attachment ? this.attachment.name.split(".")[0] : '', [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(70),
            CustomFormValidator.documentNameFormat,
          ]),
          file: new FormControl(null, [
            Validators.required,
            CustomFormValidator.requiredFileType(...this.permittedFiles$.value),
            CustomFormValidator.maxFileSize(70),
          ]),
        });
        break;
    }
  }

  typePermittedFiles(): void {
    if (this.attachment && this.attachment.kind === EKindAttachment.PDF) {
      this.permittedFiles$.next(['.pdf']);
    }
  }

  closeDialog(): void {
    this.modal.closeAll();
  }

  saveAction(): void {
    this.store.dispatch(
      this.attachment
        ? WorkspaceActions.replaceAttachment({
            payload: {
              attachment: this.attachment,
              newAttachment: this.generateBodyCreateAttachment(),
            },
          })
        : WorkspaceActions.redactAttachment({
            payload: {
              attachment: this.generateBodyCreateAttachment(),
            },
          })
    );
  }

  changeFile(event){
    if(!event){
      this.attachmentForm.get('file').setValue(null);
    }
  }

  private generateBodyCreateAttachment(): ICreateAttachment {
    const body: ICreateAttachment = {
      name: this.attachmentForm.value.name,
      kind: 'docx',
    };
    if (this.action === EButtonActions.UPLOAD) {
      body.file = this.attachmentForm.value.file;
      body.kind = this.fileType === EFileTypes.pdf ? 'pdf' : 'docx';
    }
    return body;
  }
}
