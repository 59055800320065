import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-step',
  templateUrl: './literax-step.component.html',
  styleUrls: ['./literax-step.component.scss']
})
export class LiteraxStepComponent implements OnInit {
  @Input() activeStep = false;

  constructor() { }

  ngOnInit(): void { }

}
