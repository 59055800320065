import { BaseService } from '../base-config/base.service';
import { DocumentSignature } from '@literax/models/document-signature';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KEYUTIL } from 'jsrsasign';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../../interfaces/service-response';
import { SingRequestForm } from '@literax/models/http/api/signature.model';
import { serialize } from '@literax/utils/json-to-form-data';
import { ISignatureValidatorResponse } from '@literax/models/signature.model';

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  readonly prefixes = {
    cer: '-----BEGIN CERTIFICATE-----\r\n',
    key: '-----BEGIN ENCRYPTED PRIVATE KEY-----\r\n',
  };

  readonly suffixes = {
    cer: '\r\n-----END CERTIFICATE-----\r\n',
    key: '\r\n-----END ENCRYPTED PRIVATE KEY-----\r\n',
  };

  constructor(private base: BaseService, private httpClient: HttpClient) {}

  /**
   * Send signature to specific document
   * @param documentId The document id to sign
   * @param params The parameter to send specified in ISignature model
   * @returns An observable of ServiceResponse
   */
  sendSignature(
    documentId: number,
    params: SingRequestForm
  ): Observable<ServiceResponse> {
    const path = `/documents/${documentId}/signatures`;
    return this.base.post(path, params);
  }

  openKey(privateKey: string, password: string) {
    try {
      const pemKey = this.formatFile(privateKey, 'key');
      return KEYUTIL.getKeyFromEncryptedPKCS8PEM(pemKey, password);
    } catch (error) {
      return null;
    }
  }

  private formatFile(fileBase64: string, fileType: string) {
    const fileString = fileBase64.split(',')[1];
    return (
      this.getFilePrefix(fileType) +
      this.formatPEM(fileString) +
      this.getFileSuffix(fileType)
    );
  }

  private getFilePrefix(fileType: string): string {
    return this.prefixes[fileType] || '';
  }

  private getFileSuffix(fileType: string): string {
    return this.suffixes[fileType] || '';
  }

  private formatPEM(pem: string) {
    return pem.match(/.{1,64}/g).join('\r\n');
  }

  createSimpleSignature(
    documentID: number,
    simpleSignature: DocumentSignature
  ): Observable<ServiceResponse> {
    const path = `/documents/${documentID}/signatures`;
    const formData = serialize(simpleSignature, { returnAsFormData: true });
    return this.base.post(path, formData);
  }

  showSignature(hashId: string): Observable<ServiceResponse> {
    const path = `/signature_validator/${hashId}`;
    return this.base.get(path);
  }

  showSignatureV3(hashId: string, entityType: number): Observable<ISignatureValidatorResponse> {
    const path = `/guests/signature_validator/${hashId}/${entityType}`;
    return this.base.get(path, null, true);
  }

  getSignatureQuote(searchValue: string) {
    return this.base.get(`/signature_quotes?rfc_or_email=${searchValue}`);
  }
}
