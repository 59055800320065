import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-tabbed-container',
  templateUrl: './tabbed-container.component.html',
  styleUrls: ['./tabbed-container.component.scss'],
})
export class TabbedContainerComponent implements OnInit {
  @Input() set tabsList(tabs: ITab[]) {
    tabs.sort((a: ITab, b: ITab) => a.id - b.id);
    this.tabs = tabs;
  }
  @Output() tabChanged: EventEmitter<number> = new EventEmitter();
  activeTab = -1;
  tabs = [];

  constructor() {}

  ngOnInit() {}

  onTabClicked(index: number, statusId: number) {
    this.activeTab = index;
    this.tabChanged.emit(statusId);
  }
}

export interface ITab {
  id: number;
  name: string;
}
