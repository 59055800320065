<div class="sign-request">
  <div class="check-container">
    <div class="circle" [ngClass]="signer?.signed ? 'signed' : 'pending'">
      <mat-icon [ngStyle]="{ color: signer?.signed ? 'white' : 'black' }">
        {{ signer.signed ? 'check' : 'access_time' }}
      </mat-icon>
    </div>
  </div>

  <div class="signers">
    <div class="signer-info">
      <p class="signer" [class.name]="!signer?.signed">{{ signer?.name }}</p>
      <p [class.name]="!signer?.signed">{{ signer?.company_name }}</p>
      <span class="signer-rfc">{{ signer?.rfc }}</span>
      <p
        *ngIf="signature"
        class="show-signature"
        (click)="showSignature($event)"
      >
        {{
          (!showInfo
            ? 'SIGNATURES.INFO.SHOW_BUTTON'
            : 'SIGNATURES.INFO.HIDE_BUTTON'
          ) | translate
        }}
      </p>
    </div>

    <div *ngIf="signature && showInfo" class="signature-info">
      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.SERIAL_NUMBER' | translate }}</p>
        <p class="info">{{ signature?.serial_number }}</p>
      </div>

      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.OCSP_TIMESTAMP' | translate }}</p>
        <p class="info">
          {{ signature?.ocsp_request_date | date: 'dd-MM-yyyy HH:mm' }} /
          {{ signature?.ocsp_request_date }}
        </p>
      </div>

      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.TIME' | translate }}</p>
        <p class="info">
          {{ signature?.created_at | date: 'dd-MM-yyyy HH:mm' }} /
          {{ signature?.created_at }}
        </p>
      </div>

      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.SIGNATURE' | translate }}</p>
        <p class="info">{{ signature?.sign_data }}</p>
      </div>

      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.TIMESTAMP' | translate }}</p>
        <p class="info">{{ signature?.psc_timestamp }}</p>
      </div>

      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.PSC_POLICY' | translate }}</p>
        <p class="info">{{ signature?.policy }}</p>
      </div>

      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.FINGER_PRINT' | translate }}</p>
        <p class="info">{{ signature?.finger_print }}</p>
      </div>

      <div class="singature-row">
        <p class="label">{{ 'SIGNATURES.INFO.PSC_SIGNATURE' | translate }}</p>
        <p class="info">{{ signature?.psc_signature }}</p>
      </div>
    </div>
  </div>

  <div class="actions-container">
    <button
      *ngIf="!free && !signer?.signed"
      (debounceClick)="actionClicked($event)"
    >
      {{ labelButton }}
    </button>
  </div>
</div>
