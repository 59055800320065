import { BehaviorSubject, Subscription } from 'rxjs';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  EDocumentStatus,
  ESigningMethod,
  ETransactionType,
} from '@literax/enums/document.enum';
import { Store, select } from '@ngrx/store';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
} from 'rxjs/operators';

import { AttachmentActions } from '@literax/modules/documents/store/workspace/actions/attachment.actions';
import { EAttachmentReadStatus } from '@literax/enums/attachment.enum';
import { EParticipantKind } from '@literax/enums/participant.enum';
import { IAppState } from '@literax/store';
import { IViewingAttachment } from '@literax/models/attachment.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';

export interface ISignatureCard {
  attachmentId: number;
  owner: {
    id: number;
    name: string;
    email: string;
  };
  page: number;
  coords: {
    x: number;
    y: number;
  };
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-if-pdf',
  template: '',
})
export class IFPDFComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  private pdfViewer: HTMLElement;

  __url: BehaviorSubject<string> = new BehaviorSubject(null);
  __showSignatures: BehaviorSubject<boolean> = new BehaviorSubject(false);
  __xmlData: BehaviorSubject<string> = new BehaviorSubject(null);
  __signatures: BehaviorSubject<ISignatureCard[]> = new BehaviorSubject([]);
  __raw: BehaviorSubject<string> = new BehaviorSubject('');

  @Input() set url(url: string) {
    this.__url.next(url);
  }

  @Input() set base64(data: string) {
    this.__raw.next(data);
  }

  @Input() set showSigners(show: boolean) {
    this.__showSignatures.next(show);
  }

  @Input() set xmlData(data: string) {
    this.__xmlData.next(data);
  }

  @Input() standalone = false;

  depts = {
    codeHiglight: 'https://origon-cdn.azureedge.net/ecosystem/platforms/Literax/js/prismjs_1.15.0.js',
    wcShim:
      'https://origon-cdn.azureedge.net/ecosystem/platforms/Literax/js/web-component_native-shim.js',
    wcPollyfill:
      'https://origon-cdn.azureedge.net/ecosystem/platforms/Literax/js/web-component_custom-elements.js',
    cePdfViewer: 'https://origon-cdn.azureedge.net/ecosystem/platforms/Literax/js/literax-pdf-viewer_1.0.7_bundle.js',
  };

  attachment: IViewingAttachment = null;

  @Output() pdfData: EventEmitter<string> = new EventEmitter();

  constructor(
    private store: Store<IAppState>,
    private hostElement: ElementRef,
    private translateService: TranslateService,
    private modalService: NzModalService
  ) {}

  ngOnDestroy(): void {
    if (this.pdfViewer) {
      this.pdfViewer.removeAllListeners();
    }
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.injectScripts(() => {
      this.pdfViewer = document.createElement('o-viewer');
      this.pdfViewer.style.display = 'block';
      this.pdfViewer.setAttribute('lang', this.translateService.currentLang);
      this.translateService.onLangChange.subscribe((event) => {
        this.pdfViewer.setAttribute('lang', event.lang);
      });

      this.pdfViewer.addEventListener('error', (e: CustomEvent) => {
        if (e.detail.code && e.detail.code === 'signature.positions') {
          this.modalService.error({
            nzTitle: 'Error',
            nzContent: e.detail.message,
          });
        }
      });

      this.pdfViewer.addEventListener('signature.changed', (e: CustomEvent) => {
        this.store.dispatch(
          AttachmentActions.moveParticipantCanvas({
            payload: {
              signRequestId: Number(e.detail.owner.id),
              pageNumber: Number(e.detail.coords.page),
              positionX: Number(e.detail.coords.x),
              positionY: Number(e.detail.coords.y),
            },
          })
        );
      });
      this.pdfViewer.addEventListener('last.page.render', () => {
        if (this.standalone === false) {
          this.store.dispatch(
            WorkspaceActions.sendReadStatus({
              readStatus: EAttachmentReadStatus.READED,
            })
          );
        }
      });
      this.pdfViewer.addEventListener(
        'pdf.data',
        ({ detail: base64 }: CustomEvent) => {
          if (this.standalone === false) {
            this.store.dispatch(
              WorkspaceActions.setAttachmentBase64({
                payload: {
                  id: this.attachment?.id,
                  base64,
                },
              })
            );
          } else {
            this.pdfData.emit(base64);
          }
        }
      );

      this.hostElement.nativeElement.appendChild(this.pdfViewer);

      this.subscriptions.push(
        this.__url
          .pipe(
            filter((url) => !!url),
            distinctUntilChanged()
          )
          .subscribe((url) => this.pdfViewer.setAttribute('url', url))
      );

      this.pdfViewer.setAttribute('raw', this.attachment?.base64);

      this.subscriptions.push(
        this.__xmlData.subscribe((xmlData) =>
          this.pdfViewer.setAttribute('xml-data', xmlData)
        )
      );

      this.subscriptions.push(
        this.__showSignatures.subscribe((show) => {
          this.pdfViewer.setAttribute('display-signatures', String(show));
        })
      );

      this.subscriptions.push(
        this.__signatures
          .pipe(filter((signatures) => !!signatures))
          .subscribe((signatures) => {
            this.pdfViewer.setAttribute(
              'signatures',
              encodeURIComponent(JSON.stringify(signatures))
            );
          })
      );

      this.subscriptions.push(
        this.__raw.subscribe((raw) =>
          this.pdfViewer.setAttribute('raw', encodeURIComponent(raw))
        )
      );
    });
    /**
     * Al tener esta propiedad en true el componente deberia de ser totalmente funcional sin depender del store
     * aceptando por atributo input los datos necesarios
     */
    if (this.standalone === false) {
      this.subscriptions.push(
        this.store
          .pipe(
            select((state) => ({
              state: state.workspace.document?.status?.key,
              transaction: state.workspace.document?.transactionType,
              signingMethod:
                state.workspace.document?.signingConfiguration?.signingMethod,
            }))
          )
          .subscribe(({ state, transaction, signingMethod }) => {
            this.__showSignatures.next(
              state === EDocumentStatus.DRAFT &&
                transaction === ETransactionType.SIGNATURE &&
                signingMethod !== ESigningMethod.DIGITAL_SIGNATURE
            );
          })
      );

      /**
       * este subscribe se realiza para pasar al componente por primera vez la url
       * del anexo seleccionado, el componente se encarga de hacer la descarga para alacenar
       * en store la data base64, para utilizarlo posteriormente en la generacion de la firma digital
       */
      this.subscriptions.push(
        this.store
          .pipe(
            select((state) => state.workspace.attachment),
            filter(
              (attachment) =>
                attachment &&
                attachment.url !== undefined &&
                attachment.url !== null
            ),
            distinctUntilKeyChanged('url')
          )
          .subscribe((attachment) => {
            this.attachment = attachment;
            this.__url.next(attachment.url);
            this.__xmlData.next(attachment.filePlainText);
            this.store
              .pipe(
                select((state) => state.workspace.configuration),
                map((configurations) => [
                  ...configurations.legalPersons,
                  ...configurations.naturalPersons,
                ])
              )
              .subscribe((participants) => {
                const positions: ISignatureCard[] = [];
                participants.forEach((participant) => {
                  if (participant.kind === EParticipantKind.LEGAL_PERSON) {
                    participant.legalRepresentatives.forEach(
                      (legalRepresentative) => {
                        legalRepresentative.signaturePositions.forEach(
                          (position) => {
                            const legalRepresentativePosition: ISignatureCard =
                              {
                                attachmentId: position.attachmentId,
                                owner: {
                                  id: legalRepresentative.signRequestId,
                                  name: legalRepresentative.name,
                                  email: legalRepresentative.email,
                                },
                                page: position.pageNumber,
                                coords: {
                                  x: position.positionX,
                                  y: position.positionY,
                                },
                              };

                            positions.push(legalRepresentativePosition);
                          }
                        );
                      }
                    );
                  } else {
                    participant.signaturePositions.forEach((position) => {
                      const participantPosition: ISignatureCard = {
                        attachmentId: position.attachmentId,
                        owner: {
                          id: participant.signRequestId,
                          name: participant.name,
                          email: participant.email,
                        },
                        page: position.pageNumber,
                        coords: {
                          x: position.positionX,
                          y: position.positionY,
                        },
                      };

                      positions.push(participantPosition);
                    });
                  }
                });
                const signatures = positions.filter(
                  (position) => position.attachmentId === this.attachment.id
                );
                this.__signatures.next(signatures);
              });
          })
      );

      this.subscriptions.push(
        this.store
          .pipe(
            select((state) => state.workspace.attachment?.base64),
            filter((data) => !!data)
          )
          .subscribe((b64) => {
            this.pdfViewer.setAttribute('raw', encodeURIComponent(b64));
          })
      );
    }
  }

  injectScripts(callback: () => void) {
    const head = document.getElementsByTagName('head')[0];
    Object.entries(this.depts).forEach(([key, value]) => {
      if (!document.getElementById(key)) {
        const script = document.createElement('script');
        script.src = value;
        script.id = key;
        script.setAttribute('defer', '');
        head.appendChild(script);
      }
    });
    callback.call(this);
  }
}
