<ng-container>
  <literax-applicant
    [document]="document"
    [applicant]="document?.user"
    [user]="user"
    [userDocument]="document?.user"
    [isOwner]="true"
  >
  </literax-applicant>
</ng-container>
<ng-container *ngFor="let applicant of applicantRequest">
  <literax-applicant
    [document]="document"
    [applicant]="applicant"
    [user]="user"
    [userDocument]="document?.user"
  >
  </literax-applicant>
</ng-container>
