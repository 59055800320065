import { Component, OnDestroy, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss'],
})
export class ValidatorComponent implements OnInit, OnDestroy {
  logoUrl =
    'https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white.svg';

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
