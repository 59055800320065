import {
  IDefaultUser,
  IUser,
  IUserConfirmation,
  IUserLoginConfirmation,
} from '../models/users.interface';
import {
  IInfo,
  ILookFeelConfig,
  IDepartmentReceivers,
} from '../models/users.interface';
import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IAuthLogin } from '@literax/models/http/auth/login.model';
import { IPagination } from '@literax/models/pagination.model';
import { IProcess } from '@literax/components/platform/profiles/types/user-ptofiles';
import { IProfile } from '../../profiles/models/profiles';
import { UserKindEnum } from '../enums/user-kind.enum';

export const getAllUsers = createAction(
  '[Users Component] getAllUsers',
  props<{ pagination?: IPagination }>()
);

export const getAllUsersSuccess = createAction(
  '[Users Components] getAllUsersSuccess',
  props<{ client_users: IUser[]; pagination: IPagination }>()
);

export const errorConf = createAction(
  '[Users Component] errorConf',
  props<{ error: IAPIResponseError }>()
);

export const errorloginConf = createAction(
  '[Users Component] errorloginConf',
  props<{ error: IAPIResponseError }>()
);

export const updateUsersbyId = createAction(
  '[Users Component] updateUsersbyId',
  props<{ users: IUser }>()
);
export const updateUsersbyIdSucess = createAction(
  '[Users COmponent] updateUsersbyIdSucess'
);

export const deleteUsers = createAction(
  '[Users component] delete',
  props<{ userId: number }>()
);
export const deleteUsersSuccess = createAction(
  '[Users component] deleteSuccess'
);
export const saveUsers = createAction(
  '[Users Component] saveUsers',
  props<{ user: IUser }>()
);
export const saveUsersSucess = createAction(
  '[Users Component] saveUsersSucess'
);

export const getUserProfiles = createAction(
  '[Users Component] GetUserProfiles'
);

export const getUserProfilesSuccess = createAction(
  '[Users Component] GetUserProfilesSuccess',
  props<{ payload: { profiles: IProfile[] } }>()
);

export const registerClientUsersConfirmations = createAction(
  '[Users Component] registerClientUsersConfirmations',
  props<{ user: IUserConfirmation; token: string }>()
);
export const registerClientUsersConfirmationsSucess = createAction(
  '[Users Component] regisrterClientUsersConfirmationsSucess'
);

export const loginClientUsers = createAction(
  '[Users Component] loginClientUsers',
  props<{
    user: IAuthLogin;
    token: string;
    clientUserJoin: IUserLoginConfirmation;
  }>()
);

export const loginClientUsersConfirmations = createAction(
  '[Users Component] loginClientUsersConfirmations',
  props<{ user?: IUserLoginConfirmation; token: string; b2cToken?: string }>()
);
export const loginClientUsersConfirmationsSucess = createAction(
  '[Users Component] loginClientUsersConfirmationsSucess'
);

export const GetInfo = createAction(
  '[User] GetInfo',
  props<{ payload: string }>()
);

export const GetInfoSuccess = createAction(
  '[User] GetInfoSuccess',
  props<{ payload: { info: IInfo } }>()
);

export const GetInfoError = createAction(
  '[User] GetInfoError',
  props<{ error: IAPIResponseError }>()
);

export const GetLookFeelSuccess = createAction(
  '[User] GetLookFeelSuccess',
  props<{ payload: { lookFeel: ILookFeelConfig } }>()
);

export const ResetPage = createAction('[User] ResetPage');

export const SetDefaultUser = createAction(
  '[User] SetDefaultUser',
  props<{ payload: IDefaultUser }>()
);
export const SetDefaultUserSuccess = createAction(
  '[User] SetDefaultUserSuccess'
);

export const changeSortColumn = createAction(
  '[User] changeSort',
  props<{ payload: string }>()
);

export const changeUserKindFilter = createAction(
  '[User] changeUserKindFilter',
  props<{ payload: UserKindEnum }>()
);

//#region Dashboard users
export const getUsersDashboard = createAction('[User] getUsersDashboard');
export const getUsersDashboardSuccess = createAction(
  '[User] getUsersDashboardSuccess',
  props<{ payload: IProcess }>()
);
export const getUsersDashboardError = createAction(
  '[User] getUsersDashboardError',
  props<{ payload: IAPIResponseError }>()
);

export const DisableTaxMessage = createAction('[User] DisableTaxMessage');
export const DisableTaxMessageSuccess = createAction(
  '[User] DisableTaxMessageSuccess'
);
export const DisableTaxMessageError = createAction(
  '[User] DisableTaxMessageError',
  props<{ payload: IAPIResponseError }>()
);

export const getReceivers = createAction(
  '[Users] getReceivers',
  props<{ excludeOneself?: boolean }>()
);

export const getReceiversSuccess = createAction(
  '[Users] getReceiversSuccess',
  props<{ payload: { departmentReceivers: IDepartmentReceivers[] } }>()
);
//#endregion Dashboard users
