import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getCookie, setCookie } from '@literax/utils/cookie.utils';

import { GuestService } from '@literax/services/guest/guest.service';
import { ILinkValidateResponse } from '@literax/models/document.model';
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class ValidateLinkGuard implements CanActivate {

  token: string;
  TokenDecoded: string;

  constructor(
    private guestService: GuestService,
    private router: Router
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot): 
    Observable<boolean
    | UrlTree> 
    | Promise<boolean 
    | UrlTree> 
    | boolean 
    | UrlTree {
    this.token = route.paramMap.get('token');
    return this.isValid();
  }

  /**
   * The function checks if the link is valid, if it is, it checks if the link is a single use link, if
   * it is, it checks if the link has been visited, if it hasn't, it updates the link to visited and
   * sets a cookie, if it has, it checks the cookie
   * @param {string} token - The token that was sent to the user in the email.
   * @returns A boolean value.
   */
  isValid() {
    return this.guestService.validateLink(this.token).pipe(
      map((response: ILinkValidateResponse) => {
        this.TokenDecoded = jwt_decode(this.token);
        if (response.isSingleUseLinkConfigActive && !response.visited) {
          this.guestService.updateLink(true, this.token).subscribe((res) => {
            setCookie(`VISIT_DOCUMENT_${this.TokenDecoded["uuid"]}_${this.TokenDecoded["request"]}`, 
              `${this.TokenDecoded["uuid"]}_${this.TokenDecoded["request"]}`);
          });
        } 
        if (response.isSingleUseLinkConfigActive && response.visited) {
          this.checkCookie();
        }
        return true;
      }),
      catchError((error) => {
        if (error.status === 401) {
            this.router.navigate(['/e404-token']);
        }
        return of(error);
      })
    );
  }

  /**
   * It checks if the cookie exists and if it does, it checks if the cookie's value is equal to the
   * token's value
   * @param {string} token - the token that was sent to the user
   * @returns a boolean value.
   */
  checkCookie() {
    let cookie = getCookie(`VISIT_DOCUMENT_${this.TokenDecoded["uuid"]}_${this.TokenDecoded["request"]}`);
    let splitted = cookie.split("_", 2);
    if (splitted[0] === this.TokenDecoded["uuid"] && splitted[1] === this.TokenDecoded["request"]) {
      return true;
    } else {
      this.router.navigate(['/expired-link', { token: this.token }]);
    }
  }
}
