import { IPaginationV3 } from '@literax/models/pagination.model';
import { IUserProfile, IUserProfileDashboard } from '../types/user-ptofiles';
import { IProfile } from '@literax/models/profile.model';
import { IQueryParameters } from '../models/interfaces/profile.interface';

export interface IUserProfiles {
  profiles: IProfile[];
  profile: IProfile;
  dashboard: IUserProfileDashboard;
  userProfiles: IUserProfile[];
  userProfile: IUserProfile;
  currentPage: number;
  urlSorts: string;
  currentFilter: string;
  pagination: IPaginationV3;
  queryParameters: IQueryParameters;
}

export const initiaUserProfileslState: IUserProfiles = {
  profiles: [],
  profile: null,
  dashboard: null,
  userProfiles: [],
  userProfile: null,
  currentPage: 1,
  urlSorts: '',
  currentFilter: '',
  pagination: null,
  queryParameters: {
    pageNumber: 1,
    pageSize: 10,
    sortField: null,
    filterFields: [],
  },
};
