import * as validatorActions from '@literax/modules/validator/store/validator.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ValidatorService } from '../validator.service';
import { SignatureService } from '@literax/services/platform/signatures.service';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { Router } from '@angular/router';
import { ISignatureValidatorResponse } from '@literax/models/signature.model';

@Injectable()
export class ValidatorEffects {
  constructor(
    private service: ValidatorService,
    private actions$: Actions,
    private signatureService: SignatureService,
    private toastr: I18nToastrService,
    private router: Router
  ) {}

  validateFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validatorActions.SendFilesToValidate),
      switchMap((action) =>
        this.service.uploadXmlFiles(action.payload.files).pipe(
          map((response) =>
            validatorActions.ValidatedFilesResponse({ payload: response })
          ),
          catchError((error) =>
            of(validatorActions.XmlValidationError({ payload: error }))
          )
        )
      )
    )
  );

  showSignature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validatorActions.ShowSignature),
      switchMap((action) =>
        this.signatureService.showSignatureV3(action.payload.hashId, action.payload.entityType).pipe(
          map((response: ISignatureValidatorResponse) =>
            validatorActions.ShowSignatureSuccess({
              payload: response,
            })
          ),
          catchError((error) =>
            of(validatorActions.ShowSignatureError({ payload: error }))
          )
        )
      )
    )
  );

  redireShowErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(validatorActions.ShowSignatureError),
        tap((action) => {
          this.router.navigate(['e404']);
        })
      ),
    { dispatch: false }
  );

  hideLoad$ = createEffect(
    () => this.actions$.pipe(ofType(validatorActions.ShowSignatureSuccess)),
    { dispatch: false }
  );
}
