import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-validator-container',
  templateUrl: './validator-container.component.html',
  styleUrls: ['./validator-container.component.scss'],
})
export class ValidatorContainerComponent implements OnInit {
  logoUrl =
    'https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white.svg';
  constructor(public translateService: TranslateService) {}
  langs: string[] = [];
  ngOnInit() {
    this.langs = this.translateService.getLangs();
  }

  setLang(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('locale', lang);
  }
}
