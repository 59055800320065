import { EDeviceType } from '@literax/enums/application.enum';
import { createAction, props } from '@ngrx/store';

export enum ECoreActions {
  setIsDesktop = '@core/setIsDesktop',
  setDeviceType = '@core/setDeviceType',
}

export const CoreActions = {
  setIsDesktop: createAction(
    ECoreActions.setIsDesktop,
    props<{ payload: boolean }>()
  ),
  setDeviceType: createAction(
    ECoreActions.setDeviceType,
    props<{ payload: EDeviceType }>()
  ),
};
