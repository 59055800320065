<div class="buttons">
  <div class="left">
    <button
      nz-button
      nzType="primary"
      nzDanger
      [ngClass]="button?.class"
      [nzSize]="isMobileDevice ? 'small' : 'default'"
      [disabled]="button?.disabled"
      [hidden]="button?.hidden"
      *ngFor="let button of buttons.left"
      (click)="action.emit(button)"
    >
      <i *ngIf="button.icon !== ''" [ngClass]="button.icon"></i>
      {{ button.label | uppercase | translate }}
    </button>
  </div>
  <div class="right">
    <ng-container *ngFor="let button of buttons.right">
      <ng-container *ngIf="button?.withActions">
        <nz-dropdown-menu #menu4="nzDropdownMenu">
          <ul nz-menu class="mt-1 dashboard-custompopover">
            <li nz-menu-item *ngFor="let btn of button.action">
              <button
                nz-button
                nzType="primary"
                [ngClass]="btn?.class"
                type="button"
                [disabled]="btn?.disabled"
                [hidden]="btn?.hidden"
                [nzSize]="isMobileDevice ? 'small' : 'default'"
                (click)="action.emit(btn)"
              >
                <i
                  *ngIf="btn.icon !== ''"
                  [ngClass]="btn.icon"
                  class="mr-1"
                ></i>
                <span>{{ btn.label | uppercase | translate }}</span>
              </button>
            </li>
          </ul>
        </nz-dropdown-menu>

        <button
          nzTrigger="click"
          nz-button
          nz-dropdown
          [nzDropdownMenu]="menu4"
          class="interfirma-button"
          [ngClass]="button?.class"
          type="button"
          [disabled]="button?.disabled"
          [hidden]="button?.hidden"
          [nzSize]="isMobileDevice ? 'small' : 'default'"
        >
          <i *ngIf="button.icon !== ''" [ngClass]="button.icon"></i>
          <span>{{ button.label | uppercase | translate }}</span>
        </button>
      </ng-container>
      <button
        nz-button
        *ngIf="!button?.withActions"
        class="interfirma-button"
        [ngClass]="button?.class"
        matRipple
        type="button"
        (click)="action.emit(button)"
        [disabled]="button?.disabled"
        [hidden]="button?.hidden"
        [attr.data-label]="button.label"
        [attr.data-in_status]="button.status | json"
        [nzSize]="isMobileDevice ? 'small' : 'default'"
      >
        <i *ngIf="button.icon !== ''" [ngClass]="button.icon"></i>
        <span>{{ button.label | uppercase | translate }}</span>
      </button>
    </ng-container>
  </div>
</div>
