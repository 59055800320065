import { TitleNavbarConfig } from '@literax/models/title-navbar-config.model';

export interface ITitleNavbarState {
  stack: TitleNavbarConfig[];
  nav_nodes: Partial<{
    firstLevel: TitleNavbarConfig;
    secondLevel: TitleNavbarConfig;
    thirdLevel: TitleNavbarConfig;
    fourthLevel: TitleNavbarConfig;
  }>;
}

export const initialTitleNavbarState: ITitleNavbarState = {
  stack: [],
  nav_nodes: null
};
