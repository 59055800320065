import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[onDrop]'
})
export class OnDropDirective {
  @Output() onDrop = new EventEmitter();

  @HostListener('drop', ['$event']) public onDropDirective(event: any) {
    event.preventDefault();
    this.onDrop.emit(event);
  }
}
