import { Component, Input, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-section-toggle',
  templateUrl: './section-toggle.component.html',
  styleUrls: ['./section-toggle.component.scss'],
})
export class SectionToggleComponent implements OnInit {
  @Input() title: string;
  @Input() expanded: boolean;

  collapser: string;

  constructor() {}

  ngOnInit(): void {
    this.collapser = Math.random().toString(36).substr(2, 9);
  }
}
