import { Action, createReducer, on } from '@ngrx/store';
import {
  ChangeTab,
  GetListProfiles,
  GetListProfilesSuccess,
  GetProfileSuccess,
  GetUsersProfilesDashboardSuccess,
  ProcessProfileSuccess,
} from './user-profiles.actions';
import { IUserProfiles, initiaUserProfileslState } from './user-profiles.state';
import {
  ProfilesFilterColumn,
  ProfilesFilterFieldTypeData,
  ProfilesFilterFieldTypeOperator,
} from '../models/constants/profile.constants';

const reducers = createReducer(
  initiaUserProfileslState,
  on(GetUsersProfilesDashboardSuccess, (state: IUserProfiles, action) => ({
    ...state,
    dashboard: action.payload,
  })),
  on(GetProfileSuccess, (state: IUserProfiles, action) => ({
    ...state,
    profile: action.profile,
  })),
  on(ProcessProfileSuccess, (state: IUserProfiles, action) => ({
    ...state,
    profile: action.profile,
  })),
  on(GetListProfiles, (state, action) => ({
    ...state,
    queryParameters:
      action?.payload !== null && action?.payload !== undefined
        ? { ...state.queryParameters, ...action.payload }
        : {
            pageNumber: 1,
            pageSize: 10,
            sortField: null,
            filterFields: [],
          },
  })),
  on(GetListProfilesSuccess, (state, action) => ({
    ...state,
    ...action.payload,
  })),
  on(ChangeTab, (state, action) => ({
    ...state,
    queryParameters:
      action?.status !== null &&
      action?.status !== undefined &&
      action?.status !== 'profile_all'
        ? {
            pageNumber: 1,
            pageSize: 10,
            sortField: null,
            filterFields: [
              {
                name: ProfilesFilterColumn.STATUS_NAME,
                term: action.status,
                type: ProfilesFilterFieldTypeData.TEXT,
                operator: ProfilesFilterFieldTypeOperator.EQ,
              },
            ],
          }
        : {
            pageNumber: 1,
            pageSize: 10,
            sortField: null,
            filterFields: [],
          },
  }))
);

export function userProfileReducer(state: IUserProfiles, action: Action) {
  return reducers(state, action);
}
