<div class="col-auto p-0 navbar-button">
  <button
    nz-button nzType="primary"
    class="nav-button mr-3"
  >
    {{ lang | uppercase }}
  </button>
  <!-- TODO: Reemplazar por dreopdown de ant <mat-menu #translateMenu="matMenu">
    <button *ngIf="lang != 'en'" mat-menu-item (click)="setLang('en')">
      ENGLISH
    </button>
    <button *ngIf="lang != 'es'" mat-menu-item (click)="setLang('es')">
      ESPAÑOL
    </button>
  </mat-menu> -->
</div>
