import * as backButtonActions from '@literax/store/back-button/back-button.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { IBackButtonState, initialBackButtonState } from './back-button.state';

const reducer = createReducer(
  initialBackButtonState,
  on(backButtonActions.GoPreviousUrl, (state, action) => {
    if (action.params) {
      return {
        ...state,
        params: action.params,
      };
    }
  }),
  on(backButtonActions.ClearParams, (state) => ({
    ...state,
    params: null,
  }))
);

export function backButtonReducer(state: IBackButtonState, action: Action) {
  return reducer(state, action);
}
