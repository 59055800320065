import { IViewingAttachment } from './../../models/attachment.model';
import { IAPIError, IAPIResponseError } from '@literax/models/http/api/error.model';
import { ICancelatioReason, IDocument } from '@literax/models/http/document/document.model';

import { IAttachment } from '@literax/models/attachment.model';
import { IHolder } from '@literax/models/promissory-notes.model';

export interface IUser {
  id: number;
  name: string;
}

export interface IFilterParams {
  name?: string;
  status_id?: number[];
  user_name?: string;
  signer?: string;
  created_at?: string;
  flow?: string;
  process?: string;
}

export interface ISignRequest {
  id?: number;
  date_signed?: Date;
  is_author?: boolean;
  expiry?: Date;
  signed_date?: Date;
  email?: string;
  name?: string;
  rfc?: string;
  role?: string;
  signed?: boolean;
  signature_type_id?: number;
  company_rfc?: string;
  company_name?: string;
  document_id?: number;
  created_at?: Date;
  updated_at?: Date;
}

export const nullIDocument: IDocument = {
  id: 0,
  created_at: new Date(),
  name: '',
  sign_requests: [],
  status: { id: 0, name: '', color: '', key: null },
  status_name: '',
  updated_at: new Date(),
  expiry: new Date(),
  user: null,
  signatures: [],
  attachments: [
    {
      id: 0,
      name: '',
      file_path: null,
      created_at: new Date(),
      updated_at: new Date(),
      kind: 'pdf',
      primary: null,
      multilanguage: null
    }
  ],
  process_name: '',
  rule: '',
  signing_method: null
};

export interface IFreeDocumentState {
  documents: IDocument[];
  attachments: any[];
  selectedDocument: any;
  selectAttachment: IViewingAttachment;
  lastDocument: Partial<IDocument>;
  uploadErrors: IAPIResponseError;
  currentPage: number;
  activeFilter: IFilterParams;
  deleteDocumentError: IAPIError;
  getDocumentError: IAPIResponseError;
  holder?: IHolder;
  token?: string;
  onlyoffice_token?: string;
  currencies?: [];
  commentRejected: ICancelatioReason[];
}

export const initialFreeDocumentState: IFreeDocumentState = {
  documents: [],
  attachments: [],
  selectedDocument: null,
  selectAttachment: null,
  lastDocument: null,
  uploadErrors: null,
  currentPage: 1,
  activeFilter: null,
  deleteDocumentError: null,
  getDocumentError: null,
  holder: null,
  token: '',
  onlyoffice_token: '',
  currencies: [],
  commentRejected: []
};
