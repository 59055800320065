import { ActionsSubject, Store, select } from '@ngrx/store';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IAppState } from '@literax/store';
import { ClientsCurrentUserV3, CurrentClientV3 } from '@literax/modules/configurations/store/client/client.selectors';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { IClient } from '@literax/models/http/api/client/client.model';
import {
  EDocumentStatus,
  ETransactionType,
} from '@literax/enums/document.enum';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { B2CAuthService } from './../../../b2c-auth/b2c-auth.service';
import { EKindAttachment } from '@literax/enums/attachment.enum';
import { EWorkingMode } from '@literax/modules/documents/store/workspace/workspace.state';
import { IDocumentResponse } from '@literax/models/document.model';
import { INaturalPerson } from '@literax/models/participant.model';
import { IViewingAttachment } from './../../../models/attachment.model';
import { WorkspaceSelectors } from './../../documents/store/workspace/workspace.selectors';
import { environment } from '@environments/environment';

export interface OnlyOfficeEditorProps {
  mode?: EOnlyOfficeModes;
  owner?: boolean;
  username?: string;
  uuid?: number;
  url?: string;
  key?: string;
  'subscription-ids'?: number | number;
  lang?: string;
  title?: string;
  token?: string;
  'user-id'?: number;
}

export enum EOnlyOfficeModes {
  // "collaborative" | "edit" | "view"
  EDIT = 'edit',
  VIEW = 'view',
  COLLABORATIVE = 'collaborative',
}
import { IInfo } from '@literax/components/configurations/users/models/users.interface';
import { selectUserInfo } from '@literax/components/configurations/users/states/users.selector';
@UntilDestroy()
@Component({
  selector: 'literax-onlyoffice-editor',
  template: `<div style="height: 100%; width: 100%;" #onlyOffice></div>`,
})
export class OnlyofficeEditorComponent implements OnInit, OnDestroy {
  onlyOfficeScript: HTMLScriptElement;
  onlyOffice: HTMLElement;
  @ViewChild('onlyOffice', { static: true })
  container: ElementRef<HTMLDivElement>;
  @ViewChild('loader', { static: true }) loader: TemplateRef<any>;

  // escuchadores del store
  attachment$: Observable<any> = this.store.pipe(
    select(WorkspaceSelectors.selectAttachment),
    untilDestroyed(this)
  );

  guestToken$: Observable<string> = this.store.pipe(
    select(WorkspaceSelectors.selectAccessTokens),
    map((data) => data?.textEditor),
    untilDestroyed(this)
  );
  
  document$ = this.store.pipe(select(WorkspaceSelectors.selectDocument));

  isGuest$: Observable<EWorkingMode> = this.store.pipe(
    select(WorkspaceSelectors.selectWorkingMode),
    untilDestroyed(this)
  );

  subscriptions: Subscription[] = [];

  // TODO: selectores v3 clients
  clientsCurrentUserV3$ = this.store.pipe(
    untilDestroyed(this),
    select(ClientsCurrentUserV3)
  );
  currentClientV3$ = this.store.pipe(
    untilDestroyed(this),
    select(CurrentClientV3)
  );

  userInfo$ = this.store.pipe(untilDestroyed(this), select(selectUserInfo));

  //
  emailToken: string;
  accesTokentChat: string;
  accesTokentEditor: string;


  constructor(
    private actions$: ActionsSubject,
    private renderer: Renderer2,
    private store: Store<IAppState>,
    private b2cAuthService: B2CAuthService
  ) {

    this.guestToken$.pipe().subscribe((response) =>{

      if(response){
        this.accesTokentEditor = response;
      }
    });

    if (!document.querySelector('#onlyOfficeScript')) {
      this.onlyOfficeScript = document.createElement('script');
      this.onlyOfficeScript.type = 'text/javascript';
      this.onlyOfficeScript.src = `${environment.onlyOfficeUrl}`;
      this.onlyOfficeScript.setAttribute('defer', '');
      this.onlyOfficeScript.setAttribute('id', 'onlyOfficeScript');
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(this.onlyOfficeScript);
    }
  }

  ngOnDestroy(): void { }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([
        this.document$,
        this.attachment$,
        this.guestToken$,
        this.isGuest$,
        this.clientsCurrentUserV3$,
        this.currentClientV3$,
        this.userInfo$
      ])
        .pipe(
          filter(
            ([document, 
              attachment, 
              token,  
              workingMode,
              clientsCurrentUserV3,
              currentClientV3, 
              userInfo]) =>
              attachment && attachment?.textEditorConfig?.key !== null
          ),
          distinctUntilChanged(
            (
              [document, 
                attachment, 
                token, 
                workingMode,
                clientsCurrentUserV3,
                currentClientV3,
                userInfo],
              [
                documentCurrent,
                attachmentCurrent,
                tokenCurrent,
                workingModeCurrent,
                clientsCurrentUserDataV3,
                currentClientDataV3,
                userInfoData
              ]
            ) =>
              attachment?.textEditorConfig?.key ===
              attachmentCurrent?.textEditorConfig?.key
          )
        )
        .subscribe(
          ([document, 
            attachment, 
            token, 
            workingMode,
            clientsCurrentUserV3,
            currentClientV3,userInfo]) => {
            if (workingMode === EWorkingMode.GUEST) {
              const guest = document?.signRequests.naturalPersons.find(
                (participant) => participant.isOneSelf
              );
              
              const accessToken = token === undefined ? this.accesTokentEditor : token;

              this.generateOnlyOffice(
                document,
                attachment,
                accessToken,
                currentClientV3,
                guest,
                workingMode
              );
            } else {
              this.b2cAuthService.getToken().subscribe((b2cToken) => {
                this.generateOnlyOffice(
                  document,
                  attachment,
                  b2cToken,
                  currentClientV3,
                  userInfo,
                  workingMode
                );
              });
            }
          }
        )
    );
  }

  generateOnlyOffice(
    document: IDocumentResponse,
    attachment: IViewingAttachment,
    token: string,
    client: IClient,
    user: IInfo | INaturalPerson,
    workingMode: EWorkingMode
  ) {
    if (attachment?.url === null || attachment?.url === undefined) return;
    const config: OnlyOfficeEditorProps = {};
    config.title = attachment?.name;
    config['subscription-ids'] = attachment?.textEditorConfig?.subscriberId;
    config.key = attachment?.textEditorConfig?.key;
    config.username =
      workingMode === EWorkingMode.GUEST ? user.name : client?.name;
    config.owner = user?.userId === document?.author?.id;
    config['user-id'] = client?.id;
    config.uuid = attachment?.id;
    config.url = attachment?.url;
    config.token = token;

    // modo visual si el documento ya se ha generado
    if (document?.transactionType === ETransactionType.REQUEST) {
      if (document?.status.key === EDocumentStatus.GENERATED) {
        config.mode = EOnlyOfficeModes.VIEW;
      }
      if (
        document?.status.key === EDocumentStatus.IN_PROCESS ||
        (document?.status.key === EDocumentStatus.DRAFT &&
          attachment?.kind === EKindAttachment.DOCX &&
          user?.userId !== document?.author?.id)
      ) {
        config.mode = EOnlyOfficeModes.EDIT;
      }

      if (user?.userId === document?.author?.id) {
        config.mode = EOnlyOfficeModes.VIEW;
      }
    }

    if (document?.transactionType === ETransactionType.REVIEW) {
      if (document?.status.key === EDocumentStatus.DRAFT) {
        config.mode = EOnlyOfficeModes.EDIT;
      }

      if (document?.status.key === EDocumentStatus.REVIEW) {
        config.mode = EOnlyOfficeModes.COLLABORATIVE;
      }

      if (
        document?.status?.key === EDocumentStatus.REVIEWED &&
        user &&
        user?.userId === document?.author?.id
      ) {
        config.mode = EOnlyOfficeModes.COLLABORATIVE;
      }
    }

    if (document?.transactionType === ETransactionType.SIGNATURE) {
      if (
        [EDocumentStatus.DRAFT, EDocumentStatus.IN_SIGN].includes(
          document?.status?.key
        )
      ) {
        config.mode = EOnlyOfficeModes.EDIT;
      }
    }

    if (document?.transactionType === ETransactionType.APPROVAL) {
      if (document?.status?.key !== EDocumentStatus.APPROVED) {
        config.mode = EOnlyOfficeModes.EDIT;
      }

      if (document?.status?.key === EDocumentStatus.APPROVED) {
        if (user && user?.userId === document?.author?.id) {
          config.mode = EOnlyOfficeModes.VIEW;
        } else {
          config.mode = EOnlyOfficeModes.VIEW;
        }
      }
    }

    this.onlyOffice = window.document.createElement('if-onlyoffice');

    Object.entries(config).forEach(([attr, value]) => {
      this.onlyOffice.setAttribute(attr, encodeURIComponent(value));
    });
    this.container.nativeElement.innerHTML = '';
    this.container.nativeElement.appendChild(this.onlyOffice);
  }

  generateTransformScreen() {
    const loaderElement: HTMLDivElement = this.renderer.createElement('div');
    loaderElement.style.display = 'flex';
    loaderElement.style.width = '100%';
    loaderElement.style.height = '100%';
    loaderElement.style.alignItems = 'center';
    loaderElement.style.justifyContent = 'center';

    loaderElement.innerHTML = '<i class="fas fa-spin fa-spinner-third"></i>';

    this.container.nativeElement.innerHTML = '';
    this.container.nativeElement.appendChild(loaderElement);
  }
}
