import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dynamic'
})
export class DynamicPipe implements PipeTransform {
  transform(value: unknown, pipes: string[]) {
    let result;
    if (pipes && pipes.length) {
      pipes.forEach((pipe) => {
        const pipeWithArguments = pipe.split(':');
        if (pipeWithArguments.length > 1) {
          result = eval(
            `this.${pipeWithArguments[0]}('${value}', '${pipeWithArguments[1]}')`
          );
        } else {
          result = eval(`this.${pipeWithArguments[0]}('${value}')`);
        }
      });
      return result;
    } else {
      return value;
    }
  }

  date(value: string, format: string) {
    return moment(value).format(format);
  }
}
