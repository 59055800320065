<div class="clientContainer">
  <div class="background">
    <div>
      <ul>
        <li>
          <p>
            {{
              'CONFIGURATIONS_MODULE.USERS.REGISTER.INFO_MESSAGE' | translate
            }}
          </p>
        </li>
      </ul>
    </div>
    <div id="divSessionWelcome">
      <div id="divWelcome">
        <p class="text-welcome">
          {{
            'CONFIGURATIONS_MODULE.USERS.REGISTER.WELCOME_REGISTER' | translate
          }}
        </p>
      </div>
      <div id="imgLiterax">
        <img
          src="https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white@3x.png"
          class="img-responsive img-literax"
          alt="img-litera"
        />
      </div>
    </div>
  </div>
  <div class="formRegister">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="header d-flex">
            <div class="col-10 img-content">
              <img
                src="https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_black.svg"
                class="img-responsive ml-3"
                alt="Company logo"
              />
            </div>
            <div class="col-2">
              <div class="language-selector">
                <literax-lenguage
                  (SetLang)="setLang($event)"
                  [lang]="lang$ | async"
                ></literax-lenguage>
              </div>
            </div>
          </div>
          <form
            [formGroup]="registerClientForm"
            (ngSubmit)="onSubmit(f.value)"
            #f="ngForm"
          >
            <div class="col-12 login justify-content-center align-self-center">
              {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.HEADER' | translate }}
            </div>
            <div class="col-12 account-associated">
              {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.TITLE' | translate }}
            </div>
            <div
              *ngIf="errorTitle$ | async"
              class="col-12 d-flex justify-content-center"
            >
              <div class="form-error">
                {{ errorTitle }}
              </div>
            </div>
            <div class="col-12">
              <literax-form-field 
                [label]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.NAME' | translate"
                [placeholder]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.NAME' | translate"
                formControlName="name"
              >
              </literax-form-field>
            </div>
            <div class="col-12">
              <literax-form-field 
                [label]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.LASTNAME' | translate"
                [placeholder]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.LASTNAME' | translate"
                formControlName="last_name"
              >
              </literax-form-field>
            </div>
            <div class="col-12">
              <literax-form-field 
                [label]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.RFC' | translate"
                [placeholder]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.RFC' | translate"
                [upperCase]="true"
                formControlName="rfc"
              >
              </literax-form-field>
            </div>
            <div class="col-12">
              <literax-form-field 
                [label]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.EMAIL' | translate"
                [placeholder]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.EMAIL' | translate"
                formControlName="email"
              >
              </literax-form-field>
            </div>
            <div class="col-12">
              <literax-form-field 
                type="password"
                [label]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.PASSWORD' | translate"
                [placeholder]="'CONFIGURATIONS_MODULE.USERS.REGISTER.FORM.PASSWORD' | translate"
                formControlName="password"
              >
              </literax-form-field>
            </div>
            <div class="col-12 mt-3">
              <button
                nz-button nzType="primary"
                class="interfirma-button primary block"
                type="submit"
                tabindex="3"
                [disabled]="!this.registerClientForm.valid"
              >
                {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.REGISTER' | translate }}
              </button>
            </div>
            <div class="col-12 not-account">
              {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.NO_ACCOUNT' | translate }}
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button
                nz-button nzType="default"
                class="register"
                (click)="onRegistrationClicked()"
                tabindex="4"
              >
                {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.LOGIN' | translate }}
              </button>
            </div>
            <div class="col-12 privacy d-flex justify-content-center">
              <a href="{{ url }}/aviso-de-privacidad"
                ><span>{{
                  'CONFIGURATIONS_MODULE.USERS.REGISTER.NOTE_OF_PRIVACY'
                    | translate
                }}</span></a
              >
              <span class="mr-1 ml-1">|</span>
              <a href=" {{ url }}/terminos-y-condiciones"
                ><SPAN>{{
                  'CONFIGURATIONS_MODULE.USERS.REGISTER.TERMS_CONDITIONS'
                    | translate
                }}</SPAN></a
              >
            </div>
            <div class="col-12 rights mt-3">
              {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.RIGTHS_COPY' | translate }}
            </div>
          </form>
        </div>
      </div>
  </div>
</div>
