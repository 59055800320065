import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IPaginationV3 } from '@literax/models/pagination.model';
import {
  IField,
  IGeneralMetadata,
  IQueryParameters,
} from '@literax/models/templates-fields.model';

export interface ITemplatesFieldsState {
  fields: IField[];
  field: IGeneralMetadata;
  error: IAPIResponseError;
  pagination: IPaginationV3;
  queryParameters: IQueryParameters;
}

export const initialTemplatesFieldsState: ITemplatesFieldsState = {
  fields: [],
  field: null,
  error: null,
  pagination: null,
  queryParameters: {
    pageNumber: 1,
    pageSize: 10,
    sortField: null,
    filterFields: [],
  },
};
