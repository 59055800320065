import { createAction, props } from '@ngrx/store';
import {IProfile, IValidateRFC, IPersonalInfo} from '../models/profiles';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IEmail } from '@literax/components/configurations/profiles/models/emails';
import { HttpErrorResponse } from '@angular/common/http';

export const getAllProfiles = createAction(
  '[Profiles Component] getAllProfiles'
);
export const getAllProfilesSucces = createAction(
  '[Profiles Components] getAllProfilesSucces',
  props<{ profile: IProfile }>()
);
export const errorConf = createAction(
  '[Profiles Component] errorConf',
  props<{ error: IAPIResponseError }>()
);
export const errorloginConf = createAction(
  '[Profiles Component] errorloginConf',
  props<{ error: IAPIResponseError }>()
);

export const updateProfilesbyId = createAction(
  '[Profiles Component] updateProfilesbyId',
  props<{ profile: IProfile }>()
);
export const updateProfilesbyIdSucess = createAction(
  '[Profiles COmponent] updateProfilesbyIdSucess'
);

export const getEmails = createAction('[Profiles component] get emails');

export const getEmailsSuccess = createAction(
  '[Profiles component] get emails success',
  props<{ payload: IEmail[] }>()
);

export const getEmailError = createAction(
  '[Profiles component] get emails error',
  props<{ payload: IAPIResponseError }>()
);

export const registerEmail = createAction(
  '[Profiles component] register email',
  props<{ payload: { name: string; email: string } }>()
);

export const registerEmailSuccess = createAction(
  '[Profiles component] register email success'
);

export const registerEmailError = createAction(
  '[Profiles component] register email error',
  props<{ payload: HttpErrorResponse }>()
);

export const deleteEmail = createAction(
  '[Profiles component] delete email',
  props<{ payload: number }>()
);

export const deleteEmailSuccess = createAction(
  '[Profiles component] delete email success'
);

export const deleteEmailError = createAction(
  '[Profiles component] delete email error',
  props<{ payload: HttpErrorResponse }>()
);

export const sendNotification = createAction(
  '[Profiles component] send notification',
  props<{ payload: number }>()
);

export const sendNotificationSuccess = createAction(
  '[Profiles component] send notification success'
);

export const sendNotificationError = createAction(
  '[Profiles component] send notification error',
  props<{ payload: HttpErrorResponse }>()
);

export const sendToValidateRFC = createAction(
  '[Profiles component] send to validate rfc',
  props<{payload: IValidateRFC}>()
);

export const sendToValidateRFCSuccess = createAction(
  '[Profiles component] send to validate rfc success'
);

export const sendToValidateRFCError = createAction(
  '[Profiles component] send to validate rfc error',
  props<{payload: IValidateRFC}>()
);

