import { Component } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { environment } from '@environments/environment';

/**
 * Not found component displayed when the user enters an invalid url request
 */
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-reviewed',
  templateUrl: './reviewed.component.html',
  styleUrls: ['./reviewed.component.scss'],
})
export class ReviewedComponent {
  private route: ActivatedRouteSnapshot;
  process: string;
  title: string;
  message: string;
  invitation: string;
  company: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.route = activatedRoute.snapshot;
    this.process = (this.route.data['process'] as string).toUpperCase();

    this.title = `FREE_DOCUMENT.PROCCESS.${this.process}.TITLE`;
    this.message = `FREE_DOCUMENT.PROCCESS.${this.process}.MESSAGE`;
    this.invitation = `FREE_DOCUMENT.PROCCESS.${this.process}.INVITATION`;
    this.company = `FREE_DOCUMENT.PROCCESS.${this.process}.COMPANY`;
  }

  link = environment.domain + '/auth/registration';
}
