<div class="ltx-container">
    <literax-auth-navbar
      [title]="'AUTH.TITLE.NAVBAR' | translate"
    ></literax-auth-navbar>
    
    <div class="container">
      <div class="ltx-card">
          <i class="fal fa-check-circle fa-3x"></i>
          <h2>{{ 'HTTP.ERRORS.VALIDATE.TITLE' | translate }}</h2>
          <h5>{{ docName }}</h5>
          <button
            class="interfirma-button primary button-color"
            nz-button nzType="primary"
            (click)="goToUrl()"
          >
            {{ 'HTTP.ERRORS.VALIDATE.AWARE' | translate }}
          </button>
      </div>
    </div>
  </div>