import { IPlan, IReferenceRequest, IReferenceResponse } from '@literax/models/plan.model';

import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanService extends BaseService<IPlan[]> {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * It returns an observable of an array of IPlan objects
   * @returns An observable of an array of IPlan objects.
   */
  public getPlans(): Observable<IPlan[]> {
    return this.get('/plans');
  }

  /**
   * This function takes a productId and a requestBody and returns an Observable of type IReferenceResponse
   * @param {string} productId - The product id of the plan you want to get a reference for.
   * @param {IReferenceRequest} requestBody - IReferenceRequest
   * @returns Observable<IReferenceResponse>
   */
  public getReference(productId: string, requestBody: IReferenceRequest): Observable<IReferenceResponse> {
    return this.post(`/plans/${productId}/reference`, requestBody, true);
  }
}
