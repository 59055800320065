import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAppState } from '@literax/store';
import { ICLientUsersState } from '@literax/components/configurations/users/states/users.state';
import { ITermsState } from './terms.state';

const getUserState = createFeatureSelector<ICLientUsersState>('users');
const selectTerms = (state: IAppState) => state.terms;

export const termsAccepted = createSelector(
    getUserState,
    (state: any) => state?.userInfo?.terms_accepted
);

export const selectDocTerms = createSelector(
    selectTerms,
    (state: ITermsState) => state.terms_of_service
);
