<div
  class="
    background
    d-flex
    flex-direction
    justify-content-center
    align-items-center
  "
>
  <div class="support-info">
    <p>
      {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.INFO_MESSAGE' | translate }}
    </p>
  </div>
  <div
    class="
      welcome-screen
      d-flex
      flex-column
      align-items-center
      justify-content-center
      vh-100
    "
  >
    <p class="text-welcome mt-auto mb-0 font-big">
      {{ 'CONFIGURATIONS_MODULE.USERS.REGISTER.WELCOME_REGISTER' | translate }}
    </p>

    <img
      src="https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white@3x.png"
      class="img-responsive img-literax"
      alt="img-litera"
    />

    <div class="joining-info">
      <p class="font-big">
        {{
          'CONFIGURATIONS_MODULE.USERS.REGISTER.JOINING_MESSAGE' | translate
        }}
        <a [href]="login">{{
          'CONFIGURATIONS_MODULE.USERS.REGISTER.LOG_IN' | translate
        }}</a>
      </p>
      <div class="loader-icon">
        <i class="fal fa-sync fa-spin"></i>
      </div>
    </div>

    <div class="mt-auto">
      <a
        class="text-danger"
        target="_blank"
        href="{{ url }}/aviso-de-privacidad"
        ><span>{{
          'CONFIGURATIONS_MODULE.USERS.AUTH.NOTE_OF_PRIVACY' | translate
        }}</span></a
      >
      <span class="mr-1 ml-1"> | </span>
      <a
        class="text-danger"
        target="_blank"
        href="{{ url }}/terminos-y-condiciones"
        ><SPAN>{{
          'CONFIGURATIONS_MODULE.USERS.AUTH.TERMS_CONDITIONS' | translate
        }}</SPAN></a
      >
    </div>

    <div class="mb-4 w-50 mt-2 text-center">
      {{ 'CONFIGURATIONS_MODULE.USERS.AUTH.RIGTHS_COPY' | translate }}
    </div>
  </div>
</div>
