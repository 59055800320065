import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service used to display our loading component
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _activeProcesses = 0;
  private loadingSubject = new BehaviorSubject<boolean>(false);

  set activeProcesses(value: number) {
    if (value < 0) {
      this._activeProcesses = 0;
      return;
    }

    this._activeProcesses = value;
  }
  get activeProcesses() {
    return this._activeProcesses;
  }

  loading$ = this.loadingSubject.asObservable().pipe(
    map((isLoading) => {
      return isLoading || this.activeProcesses !== 0;
    })
  );

  show() {
    if (!this.activeProcesses) {
      this.loadingSubject.next(true);
    }
    this.activeProcesses++;
  }

  hide() {
    this.activeProcesses--;
    if (!this.activeProcesses) {
      this.loadingSubject.next(false);
    }
  }
}
