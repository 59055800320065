<div class="wrapper" *ngIf="(history$ | async)?.lenght > 0">
  <span class="col bottom text-center">
    {{ 'DOCUMENTS.SIGNERS.HISTORY.EMPTY' | translate }}
  </span>
</div>
<div *ngFor="let item of history$ | async">
  <div class="wrapper">
    <div class="vertical col-1">
      <div class="row h-100 pl-2">
        <span class="fa-stack fa-lg icon m-auto">
          <i [class]="secondHistoyIcons[item?.status]"></i>
          <i [class]="histoyIcons[item?.status]"></i>
        </span>
      </div>
    </div>
    <span class="col-11 bottom">
      <span class="row">
        <div class="p-2 align-middle col bold m-auto">
          {{ 'EMAIL_EVENT.STATUSES.' + item?.status | uppercase | translate }}
        </div>
        <div
          class="py-2 pr-1 align-middle float-right col-5 info text-right m-auto"
        >
          {{ item.createdAt * 1000 | date: 'dd-MM-y, h:mm a' }}
        </div>
      </span>
    </span>
  </div>
</div>

<div *nzModalFooter>
  <button nz-button nzType="default" (click)="modal.close()">
    <i class="fal fa-times"></i> &nbsp;{{
      'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.CLOSE' | translate
    }}
  </button>
  <button
    nz-button
    nzType="primary"
    *ngIf="
      statusDocument !== documentStatus.EXPIRED &&
      statusDocument !== documentStatus.APPROVED
    "
    [disabled]="isDisabled"
    (debounceClick)="sendMailClicked()"
  >
    <i class="far fa-paper-plane"></i>&nbsp;{{
      'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.RESEND' | translate
    }}
  </button>
</div>
