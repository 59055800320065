import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { LegalColumns } from '@literax/models/legal-columns.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-legal-grid',
  templateUrl: './legal-grid.component.html',
  styleUrls: ['./legal-grid.component.scss'],
})
export class LegalGridComponent implements OnInit {
  @Input() columns: LegalColumns[];
  @Input() rowTemplate: TemplateRef<any>;
  @Input() list = [];
  @Output() nexBatch: EventEmitter<number> = new EventEmitter<number>();
  @Output() sortColumn: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}
}
