import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select, ActionsSubject } from '@ngrx/store';

import {
  EKindAttachment,
  EViewerType,
} from './../../../../enums/attachment.enum';
import { IAppState } from '@literax/store';
import { IViewingAttachment } from '@literax/models/attachment.model';
import { OnlyOfficeEditorProps } from '@literax/modules/shared/onlyoffice-editor/onlyoffice-editor.component';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import * as signalR from '@microsoft/signalr';
import { AttachmentActions } from '@literax/modules/documents/store/workspace/actions/attachment.actions';
import { environment } from '@environments/environment';
import { CoreSelectors } from '@literax/store/core/core.selectors';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';
import {
  EDocumentStatus,
  ETransactionType,
  EWorkspaceDocumentTabs,
} from '@literax/enums/document.enum';
import { ofType } from '@ngrx/effects';
import { EAttachmentKind } from '@literax/enums/attachment-kind.enum';

export interface IViewerOptions {
  docxVisible: boolean;
  attachmentKind: string;
  attachmentUrl: string;
  attachmentType: string;
  attachmentData: string;
  attachmentDocxConfig: Partial<OnlyOfficeEditorProps>;
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
})
export class ViewerComponent implements OnInit, OnDestroy {
  viewerTypes = EViewerType;
  viewerType: EViewerType = EViewerType.LOADING;
  socket: signalR.HubConnection;

  attachment$: Observable<IViewingAttachment> = this.store.pipe(
    select(WorkspaceSelectors.selectAttachment)
  );

  subscriptions: Subscription[] = [];
  markdownData: string;

  isMobile$: Observable<boolean> = this.store.pipe(
    select(CoreSelectors.isDesktop),
    map((isDesktop) => !isDesktop)
  );

  constructor(
    private store: Store<IAppState>,
    private actions$: ActionsSubject
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.socket) {
      this.socket.off('FinishProcessAttachment');
    }
  }

  ngOnInit(): void {
    this.actions$
      .pipe(ofType(WorkspaceActions.viewAttachment))
      .subscribe((action) => {
        if (action.payload.attachment?.kind !== EKindAttachment.DOCX) {
          if (this.socket) {
            this.socket.off('FinishProcessAttachment');
          }
        }
      });
    this.subscriptions.push(
      this.actions$
        .pipe(ofType(AttachmentActions.refreshOnlyOffice))
        .subscribe((action) => {
          this.store.dispatch(
            WorkspaceActions.viewAttachment({
              payload: {
                attachment: action.payload.attachment,
                options: {
                  requestOnlyOfficeConfig: true,
                  requestPdfVersion: false,
                },
              },
            })
          );
        })
    );
    this.subscriptions.push(
      this.attachment$.subscribe((attachment) => {
        this.viewerType = attachment?.viewerType;
        this.markdownData = attachment?.filePlainText;
      })
    );

    this.subscriptions.push(
      this.store
        .pipe(
          select((state) => state?.workspace?.attachment),
          filter(
            (attachment) =>
              attachment &&
              attachment.id !== null &&
              attachment.kind === EKindAttachment.DOCX
          ),
          distinctUntilKeyChanged('id'),
          withLatestFrom(this.store.pipe(untilDestroyed(this), select((state) => state.workspace.document?.status?.key)))
        )
        .subscribe(([attachment, documentStatus]) => {
          
          this.socket = new signalR.HubConnectionBuilder()
            .withUrl(
              `${environment.guestApiEndpoint}/migr/textEditorHub?attachmentId=${attachment?.id}`,
              {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
              }
            )
            .build();
          this.subscriptions.push(
            this.store
              .pipe(
                select((state) => state.workspace.document),
                filter((document) => !!document),
                distinctUntilKeyChanged('id')
              )
              .subscribe((document) => {
                this.socket.start();
                this.socket.on('FinishProcessAttachment', () => {
                  if (
                    ![
                      ETransactionType.REVIEW,
                      ETransactionType.REQUEST,
                    ].includes(document?.transactionType)
                  ) {
                    this.store.dispatch(
                      AttachmentActions.transformDocxToPDFSuccess()
                    );
                  } else {
                    if (
                      document.transactionType === ETransactionType.REQUEST &&
                      document?.signRequests.naturalPersons[0].isOneSelf
                    ) {
                      this.store.dispatch(
                        AttachmentActions.refreshOnlyOffice({
                          payload: { attachment },
                        })
                      );
                    }

                    if (document.transactionType === ETransactionType.REVIEW) {
                      this.store.dispatch(
                        AttachmentActions.refreshOnlyOffice({
                          payload: { attachment },
                        })
                      );
                    }
                  }
                  this.store.dispatch(
                    WorkspaceActions.getDocument({
                      payload: { id: document.id },
                    })
                  );
                });
              })
          );
        })
    );
  }

  navigateToAttachments(): void {
    this.store.dispatch(
      WorkspaceActions.setCurrentWorkspaceTab({
        payload: { id: EWorkspaceDocumentTabs.ATTACHMENTS },
      })
    );
  }
}
