import {
  IAttachment,
  IAttachmentRequest,
  IAttachmentRequestParams,
  IViewingAttachment,
} from '@literax/models/attachment.model';
import { createAction, props } from '@ngrx/store';

import {
  EAttachmentReadStatus,
  EViewerType,
} from '@literax/enums/attachment.enum';
import { IApiDetailedError } from '@literax/models/common';
import { IDocumentResponse } from '@literax/models/document.model';
import { IUpdateSignaturePosition } from '@literax/models/participant.model';
import { IAttachment as LegacyIAttachment } from '@literax/models/http/attachment.model';
import { EWorkspaceDocumentTabs } from '@literax/enums/document.enum';

export enum EAttachmentActionTypes {
  getAttachment = '@workspace/getAttachment',
  getAttachmentSuccess = '@workspace/getAttachmentSuccess',
  getAttachmentError = '@workspace/getAttachmentError',

  replaceAttachment = '@workspace/replaceAttachment',
  replaceAttachmentSuccess = '@workspace/replaceAttachmentSuccess',
  replaceAttachmentError = '@workspace/replaceAttachmentError',

  viewAttachment = '@workspace/viewAttachment',
  viewAttachmentSuccess = '@workspace/viewAttachmentSuccess',
  viewAttachmentError = '@workspace/viewAttachmentError',

  redactAttachment = '@workspace/redactAttachment',
  redactAttachmentSuccess = '@workspace/redactAttachmentSuccess',
  redactAttachmentError = '@workspace/redactAttachmentError',

  moveParticipantCanvas = '@workspace/moveParticipantCanvas',
  moveParticipantCanvasSuccess = '@workspace/moveParticipantCanvasSuccess',

  deleteAttachment = '@workspace/removeAttachment',
  deleteAttachmentSuccess = '@workspace/deleteAttachmentSuccess',

  setAttachmentBase64 = '@workspace/setAttachmentBase64',

  sendReadStatus = '@workspace/sendReadStatus',

  setOnlyOfficeEditorState = '@workspace/setOnlyOfficeEditorState',

  setViewerType = '@workspace/setViewerType',

  transformDocxToPDF = '@workspace/transformDocxToPDF',
  transformDocxToPDFSuccess = '@workspace/transformDocxToPDFSuccess',
  refreshOnlyOffice = '@workspace/refreshOnlyOffice',
}

export const AttachmentActions = {
  moveParticipantCanvas: createAction(
    EAttachmentActionTypes.moveParticipantCanvas,
    props<{ payload: IUpdateSignaturePosition }>()
  ),

  moveParticipantCanvasSuccess: createAction(
    EAttachmentActionTypes.moveParticipantCanvasSuccess,
    props<{ payload: { document: IDocumentResponse } }>()
  ),

  deleteAttachment: createAction(
    EAttachmentActionTypes.deleteAttachment,
    props<{ payload: { id: number } }>()
  ),

  deleteAttachmentSuccess: createAction(
    EAttachmentActionTypes.deleteAttachmentSuccess,
    props<{ idAttachment: number }>()
  ),

  setAttachmentBase64: createAction(
    EAttachmentActionTypes.setAttachmentBase64,
    props<{ payload: { id: number; base64: string } }>()
  ),

  getAttachment: createAction(
    EAttachmentActionTypes.getAttachment,
    props<{ payload: IAttachmentRequest }>()
  ),

  getAttachmentSuccess: createAction(
    EAttachmentActionTypes.getAttachmentSuccess,
    props<{ payload: IAttachment }>()
  ),

  getAttachmentError: createAction(EAttachmentActionTypes.getAttachmentError),
  redactAttachment: createAction(
    EAttachmentActionTypes.redactAttachment,
    props<{ payload: { attachment: Partial<LegacyIAttachment> } }>()
  ),
  redactAttachmentSuccess: createAction(
    EAttachmentActionTypes.redactAttachmentSuccess,
    props<{ payload: { attachment: IAttachment } }>()
  ),
  redactAttachmentError: createAction(
    EAttachmentActionTypes.redactAttachmentError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),
  viewAttachment: createAction(
    EAttachmentActionTypes.viewAttachment,
    props<{
      payload: {
        attachment: IViewingAttachment;
        options?: IAttachmentRequestParams;
        showTab?: EWorkspaceDocumentTabs;
      };
      token?: string;
    }>()
  ),
  viewAttachmentSuccess: createAction(
    EAttachmentActionTypes.viewAttachmentSuccess,
    props<{
      payload: {
        attachment: Partial<IAttachment>;
        showTab?: EWorkspaceDocumentTabs;
      };
    }>()
  ),
  viewAttachmentError: createAction(
    EAttachmentActionTypes.viewAttachmentError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),
  replaceAttachment: createAction(
    EAttachmentActionTypes.replaceAttachment,
    props<{
      payload: {
        attachment: Partial<LegacyIAttachment> | Partial<IAttachment>;
        newAttachment: Partial<LegacyIAttachment>;
      };
    }>()
  ),
  replaceAttachmentSuccess: createAction(
    EAttachmentActionTypes.replaceAttachmentSuccess,
    props<{ payload: { attachment: IAttachment } }>()
  ),
  replaceAttachmentError: createAction(
    EAttachmentActionTypes.replaceAttachmentError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),
  sendReadStatus: createAction(
    EAttachmentActionTypes.sendReadStatus,
    props<{ readStatus: EAttachmentReadStatus }>()
  ),

  setOnlyOfficeEditorState: createAction(
    EAttachmentActionTypes.setOnlyOfficeEditorState,
    props<{ payload: IAttachmentRequestParams }>()
  ),

  setViewerType: createAction(
    EAttachmentActionTypes.setViewerType,
    props<{ payload: { attachment: IViewingAttachment; type: EViewerType } }>()
  ),

  transformDocxToPDF: createAction(EAttachmentActionTypes.transformDocxToPDF),
  refreshOnlyOffice: createAction(
    EAttachmentActionTypes.refreshOnlyOffice,
    props<{ payload: { attachment: IViewingAttachment } }>()
  ),
  transformDocxToPDFSuccess: createAction(
    EAttachmentActionTypes.transformDocxToPDFSuccess
  ),
};
