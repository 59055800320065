import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';

import { EButtonActions } from '@literax/modules/shared/compose-attachment-file/enums/button-actions.enum';
import { EDocumentStatus } from '@literax/enums/document.enum';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDialogData } from '@literax/modules/shared/compose-attachment-file/models/dialog-data';
import { IDocument } from '@literax/models/http/document/document.model';
import { IDocumentResponse } from '@literax/models/document.model';
import { IProfile } from '@literax/components/configurations/profiles/models/profiles';
import { ModalAttachmentComponent } from '@literax/modules/shared/compose-attachment-file/components/modal-attachment/modal-attachment.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { SetLastUploadedAttachment } from '@literax/modules/documents/store/document/document.actions';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { selectCurrentUser } from '@literax/modules/auth/store/auth.selectors';
import { selectLastAttachmentUploaded } from '@literax/modules/documents/store/attachment/attachment.selectors';

import { updateAttachment } from '@literax/modules/documents/store/attachment/attachment.actions';

// ! Depercated: this component is replaced by workspace-document-attachments
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-document-attachments-container',
  templateUrl: './document-attachments-container.component.html',
  styleUrls: ['./document-attachments-container.component.scss'],
})
export class DocumentAttachmentsContainerComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() authUser: IProfile;
  @Input() document: IDocumentResponse;
  @Input() documentsSecondary: IDocument[];
  @Input() mainDocument: IDocument;
  @Input() typePromissoryNote: boolean;
  @Output() attachmentSelected: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() checkAttachmentId: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() editAttachment = new EventEmitter();
  contentVisible = true;
  showAttachmentAlert = false;

  buttonsAttachment: EButtonActions[] = [
    EButtonActions.UPLOAD,
    EButtonActions.WRITE,
  ];

  dialogData: IDialogData;
  dialogWidth: number;

  document$: Observable<IDocumentResponse> = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.selectDocument)
  );
  user$: Observable<IProfile> = this.store.pipe(
    untilDestroyed(this),
    select(selectCurrentUser)
  );

  constructor(
    private store: Store<IAppState>,
    private translate: TranslateService,
    private modal: NzModalService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.document$.subscribe((document) => {
      this.document = document;
      this.setShowAttachmetAlert();
    });
    this.user$.subscribe((user) => (this.authUser = user));

    this.store
      .pipe(select(selectLastAttachmentUploaded))
      .subscribe((attachment) => {
        if (
          attachment &&
          attachment.file_docx_url === null &&
          attachment.kind === 'docx'
        ) {
          this.store.dispatch(
            SetLastUploadedAttachment({ payload: attachment })
          );
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.authUser || changes.document) {
      this.setShowAttachmetAlert();
    }
  }

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }

  private setShowAttachmetAlert() {
    this.showAttachmentAlert =
      this.document?.attachments?.length > 1 &&
      this.document?.status.key === EDocumentStatus.IN_SIGN;
  }

  replaceAttachment(attachment: IAttachment): void {
    if (attachment?.kind === 'pdf') {
      this.uploadAction(
        attachment.id,
        attachment?.kind,
        attachment?.name,
        attachment?.primary
      );
    } else {
      this.writeAction(attachment.id, attachment?.kind);
    }
  }

  uploadAction(
    id: number,
    kind: string,
    name?: string,
    primary?: boolean
  ): void {
    this.dialogWidth = 450;
    this.dialogData = {
      title: primary
        ? 'COMPOSE_ATTACHMENT_FILE.MODAL.EDIT.UPLOAD_PRIMARY'
        : 'COMPOSE_ATTACHMENT_FILE.MODAL.EDIT.UPLOAD',
      action: EButtonActions.UPLOAD,
      attachmentId: id,
      documentId: this.document.id,
      kind,
      name,
      callback: (data, documentId, attachmentId): void => {
        this.store.dispatch(
          updateAttachment({ documentId, attachmentId, data })
        );
      },
    };
    this.openDialog();
  }

  writeAction(id: number, kind: string): void {
    this.dialogWidth = 360;
    this.dialogData = {
      title: 'COMPOSE_ATTACHMENT_FILE.MODAL.EDIT.WRITE',
      action: EButtonActions.WRITE,
      attachmentId: id,
      documentId: this.document.id,
      callback: (data, documentId, attachmentId): void => {
        this.store.dispatch(
          updateAttachment({ documentId, attachmentId, data })
        );
      },
    };
    this.openDialog();
  }

  openDialog(): void {
    this.translate.get(this.dialogData.title).subscribe((nzTitle) => {
      const modalAttachment = this.modal.create({
        nzMask: false,
        nzTitle,
        nzContent: ModalAttachmentComponent,
        nzWidth: this.dialogWidth,
        nzComponentParams: {
          data: this.dialogData,
        },
      });
    });
  }
}
