import { Component, OnInit, Input } from '@angular/core';
import { IDocument } from '@literax/models/http/document/document.model';
import { IFolder } from '@literax/models/promissory-notes.model';
import { ISignRequest } from '@literax/modules/documents/store/document/document.state';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-document-detail-information',
  templateUrl: './document-detail-information.component.html',
  styleUrls: ['./document-detail-information.component.scss']
})
export class DocumentDetailInformationComponent implements OnInit {
  @Input() document: IDocument;
  @Input() promissoryNoteCicle: IFolder;
  @Input() detailPromissory = false;
  beneficiaryName: string;
  balanceAmount: number;
  subscriberName: string;
  guaranteeName: string;
  contentVisible = true;
  attachmentNames: string;
  attachmentCount: number;
  arrayGuarantor: ISignRequest[];
  isDateVisible = false;
  delivery_date: Date;

  ngOnInit(): void {
    if (this.document && this.document.attachments) {
      this.attachmentNames = this.document.attachments
        .filter((a) => !a.primary)
        .map((a) => a.name + '.' + a.kind)
        .join(', ');
      this.attachmentCount = this.document.attachments.filter(
        (a) => !a.primary
      ).length;

      this.isDateVisible =
        this.document.attachments[this.attachmentCount]?.delivery_date === null;
      if (!this.isDateVisible) {
        this.delivery_date =
          this.document.attachments[this.attachmentCount]?.delivery_date;
      }
    }
    if (this.detailPromissory && this.document) {
      this.searchParticipants();
    }
  }

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }

  searchParticipants() {
    this.arrayGuarantor = [];
    if (this.promissoryNoteCicle) {
      this.beneficiaryName = this.promissoryNoteCicle?.holder?.client?.name;
      this.balanceAmount = this.promissoryNoteCicle?.balance_amount;
      this.subscriberName = this.promissoryNoteCicle?.subscriptor_name;
    }

    if (this.document && this.document.sign_requests) {
      this.document.sign_requests.forEach((signer) => {
        switch (signer.signatory_role.name) {
          case 'subscriptor':
            this.subscriberName = signer.name;
            break;
          case 'guarantor':
            this.arrayGuarantor.push(signer);
            break;
        }
      });
    }
  }

  constructor() {}
}
