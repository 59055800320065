export const OpPatchProfile = {
  REPLACE: 'replace',
};

export const PathsPatchProfile = {
  NAME: 'name',
  DESCRIPTION: 'description',
};

export const ProccessActionProfile = {
  TO_DRAFT: 'to_draft',
  TO_PUBLISHED: 'to_published',
  TO_UNPUBLISHED: 'to_unpublished',
};

export const ProfilesColumnSorting = {
  NAME: 'name',
  KIND: 'kind',
  DESCRIPTION: 'description',
  CREATED_AT: 'created_at',
  STATUS_NAME: 'status_name',
};

export const ProfilesFilterColumn = {
  NAME: 'name',
  CREATED_AT: 'created_at',
  STATUS_NAME: 'status_name',
};

export const ProfilesFilterFieldTypeData = {
  TEXT: 'text',
  NUMERIC: 'numeric',
  BOOLEAN: 'boolean',
  TIMESTAMP: 'timestamp',
};

export const ProfilesFilterFieldTypeOperator = {
  EQ: 'eq',
  ILI: 'ili',
  LT: 'lt',
  LTE: 'lte',
  GT: 'gt',
  GTE: 'gte',
};

export const ProfilesStatus = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished'
};
