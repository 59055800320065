export const environment = {
  logStackTrace: true,
  production: false,
  driveAppID: 7,
  apiVersion: 'v2',
  apiEndpoint: 'https://api.literaxdemo.origon.app/api',
  apiVersionMigr: 'v3',
  apiEndpointMigr: 'https://api.literaxdemo.origon.app/migr/api',
  guestApiEndpoint: 'https://api.literaxdemo.origon.app',
  sentryDsn: 'https://03ac1d6dd5de4ee49125af60d6bdf2e7@sentry.io/1528761',
  domain: 'https://literaxdemo.origon.cloud',
  servicesDomain: 'https://services.literaxdemo.origon.app',
  paymentWidgetSource:
    'https://mediosdepagoqa.interfactura.com/script/paymentmanager-wd.js',
  paymentClientId: '79422d23-fcd7-40a2-8032-f7a9e930ca6b',
  paymentCustomerId: 'INT020124V62',
  urlAnalytics: 'https://www.googletagmanager.com/gtag/js?id=',
  analyticsId: 'UA-174567452-3',
  widgetUrl: 'https://gendocsqa.origon.app/web/scripts/w3/gdd-widget.js',
  recordUrl: 'https://services.literaxdemo.origon.app/fb/v1',
  driveUrl: 'https://front.literaxqa.origon.app/widget/lit-drive.min.js',
  onlyOfficeUrl:
    'https://texteditor.literaxqa.origon.app/web/js/webcomponent.onlyoffice.min.js',
  sendToMailSuggestions: 'buzondemo@mailinator.com',
  chatUrl: 'https://front.literaxdemo.origon.app/chat_element/lit-chat.min.js',
  literax: 'https://www.literax.com/',
  privacy: 'https://www.literax.com/aviso-de-privacidad/',
  terms: 'https://www.literax.com/terminos-y-condiciones/',
  b2cConfig: {
    clientId: '236b998e-a21e-4224-aaf5-14303692ba5d',
    authority:
      'https://loginstage.origon.cloud/qaidp.origon.cloud/B2C_1A_signup_signin/',
    knownAuthorities: ['https://loginstage.origon.cloud'],
    scopes: [
      'https://qaidp.origon.cloud/236b998e-a21e-4224-aaf5-14303692ba5d/basic',
      'openid',
      'offline_access',
    ],
  },
  literaxUrl: 'https://www.literax.com',
  onboarding365JoinQueryKey: 'azb2Data',
  captchaKey: '6LfAZ58lAAAAABiKXFFC3O5zschlA5Luij9X58wO',
  enableHotjarTracking: true,
  hotjarVersion: 6,
  hotjarSiteId: 3534844,
  paymentWidgetGetnetConfig:{
    url: "https://demojuno.interfactura.com/Banks.Widget",
    b2cCredentials:{
      url: "https://qagdxecosystem.b2clogin.com/qaidp.origon.cloud/B2C_1A_ROPC_Auth/oauth2/v2.0/token",
      user: "literax.demo.integration@origon.io",
      pwd: "cJsBeO6rU9CzzGD",
      grant_type: "password",
      scope: "openid https://qaidp.origon.cloud/fcdf0a75-ab67-497c-b9c6-b312bdb5a97c/basic offline_access",
      client_id: "fcdf0a75-ab67-497c-b9c6-b312bdb5a97c",
      response_type: "token id_token"
    }
  }
};
