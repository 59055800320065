import * as usersActions from '../states/users.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { ICLientUsersState, initialClientUserState } from './users.state';

import { UserKindEnum } from '../enums/user-kind.enum';
import { getReceivers } from './users.actions';

const usersReducer = createReducer(
  initialClientUserState,
  on(usersActions.getAllUsers, (state, action) => {
    const users = [];
    const pagination = action.pagination
      ? { ...state.pagination, ...action.pagination }
      : state.pagination;
    return { ...state, users, pagination };
  }),
  on(usersActions.getAllUsersSuccess, (state, action) => {
    const newUsers = [...state.users, ...action.client_users];
    return {
      ...state,
      users: newUsers,
      pagination: {
        currentPage: state.pagination.currentPage,
        pageSize: state.pagination.pageSize,
        total_count: action.pagination.total_count,
        total_pages: action.pagination.total_pages,
      },
    };
  }),
  on(usersActions.ResetPage, (state, action) => ({
    ...state,
    pagination: {
      currentPage: 1,
      pageSize: 10,
    },
    users: [],
  })),
  on(usersActions.updateUsersbyId, (state, action) => ({
    ...state,
    userIdEdit: action.users.id,
  })),
  on(usersActions.updateUsersbyIdSucess, (state, action) => ({
    ...state,
    pagination: {
      currentPage: 1,
      pageSize: state.pagination.pageSize,
    },
    users: [],
  })),
  on(usersActions.saveUsersSucess, (state, action) => ({
    ...state,
    pagination: {
      currentPage: 1,
      pageSize: state.pagination.pageSize,
    },
    users: [],
  })),
  on(usersActions.deleteUsersSuccess, (state, action) => ({
    ...state,
    pagination: {
      currentPage: 1,
      pageSize: state.pagination.pageSize,
    },
    users: [],
  })),
  on(usersActions.errorConf, (state, action) => ({
    ...state,
    erroRegister: action.error,
    errors: action?.error?.error?.detail,
  })),
  on(usersActions.errorloginConf, (state, action) => ({
    ...state,
    errorLogin: action.error,
  })),
  on(usersActions.changeSortColumn, (state, action) => ({
    ...state,
    sortColumn: action.payload,
    pagination: {
      currentPage: 1,
      pageSize: state.pagination.pageSize,
    },
    users: [],
  })),
  on(usersActions.changeUserKindFilter, (state, action) => ({
    ...state,
    userKind: action.payload,
    pagination: {
      currentPage: 1,
      pageSize: state.pagination.pageSize,
    },
    users: [],
  })),
  on(usersActions.getUserProfilesSuccess, (state, action) => ({
    ...state,
    profiles: action?.payload?.profiles,
  })),
  on(usersActions.getUsersDashboardSuccess, (state, action) => {
    const flows = [...action.payload.flows].map((flow) => {
      if (flow.flow_key === UserKindEnum.Responsible) {
        flow = { ...flow, color: '#dc3545' };
      }
      if (flow.flow_key === UserKindEnum.Collaborator) {
        flow = { ...flow, color: '#6c757d' };
      }
      return flow;
    });
    return {
      ...state,
      dashboard: { ...action.payload, flows },
    };
  }),
  on(usersActions.GetInfoSuccess, (state, action) => ({
    ...state,
    info: action.payload.info,
  })),
  on(usersActions.getReceiversSuccess, (state, action) => ({
    ...state,
    departmentReceivers: action.payload.departmentReceivers,
  })),
  on(usersActions.GetInfoError, (state, action) => ({
    ...state,
    info: {
      lookFeelConfig: {
        emailEmphasisColor: null,
        emailLogoName: null,
        emailLogo64: null,
        webEmphasisColor: null,
        webNavigationColor: null,
        webLogoName: null,
        webLogo64: null,
      },
    },
  })),
  on(usersActions.GetLookFeelSuccess, (state, action) => ({
    ...state,
    info: {
      ...state.info,
      lookFeelConfig: action.payload.lookFeel,
    },
  }))
);

export function clientUserReducer(state: ICLientUsersState, action: Action) {
  return usersReducer(state, action);
}
