import {
  Directive,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  OnDestroy,
  Input
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[debounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 500;
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  @HostListener('click', ['$event'])
  clickEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  ngOnInit(): void {
    this.clicks
      .pipe(debounceTime(this.debounceTime))
      .subscribe((event: Event) => this.debounceClick.emit(event));
  }

  ngOnDestroy(): void {
    this.clicks.unsubscribe();
  }

  constructor() {}
}
