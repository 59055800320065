import * as _ from 'lodash';

import {
  EAttachmentReadStatus,
  EKindAttachment,
} from '@literax/enums/attachment.enum';
import {
  EDocumentStatus,
  ETransactionType,
} from '@literax/enums/document.enum';
import {
  ILegalPerson,
  INaturalPerson,
} from '@literax/models/participant.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState } from '@literax/modules/auth/store/auth.state';
import { DocumentSelectors } from './selectors/document.selectors';
import { EParticipantKind } from '@literax/enums/participant.enum';
import { OnlyOfficeEditorProps } from '@literax/modules/shared/onlyoffice-editor/onlyoffice-editor.component';
import { SignatureConfigurationSelectors } from './selectors/signature-configuration.selectors';
import { WorkspaceState } from './workspace.state';
import jwt_decode from 'jwt-decode';

export const selectWorkspace =
  createFeatureSelector<WorkspaceState>('workspace');
const selectAuth = createFeatureSelector<AuthState>('auth');

export const WorkspaceSelectors = {
  ...SignatureConfigurationSelectors(selectWorkspace),
  ...DocumentSelectors(selectWorkspace),
  // TODO[document]: > remover y reemplazar por selectores devueltos por DocumentSelectors l:19
  selectDocument: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.document
  ),
  selectDocumentStatus: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.document?.status
  ),
  selectDocumentTransaction: createSelector(
    selectWorkspace,
    (state: WorkspaceState) =>
      state?.document
        ? ETransactionType[state?.document?.transactionType].toLowerCase()
        : null
  ),
  // TODO[document]: < remover y reemplazar por selectores devueltos por DocumentSelectors l:19

  selectSigningMethod: createSelector(
    selectWorkspace,
    (state: WorkspaceState) =>
      state.document?.signingConfiguration?.signingMethod
  ),
  selectActionToExecute: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.actionToDispatch
  ),
  selectDocumentParticipants: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.configuration
  ),

  selectCurrentTab: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.activeTab
  ),

  /**
   * Selecciona el anexo principal
   */
  selectMainAttachment: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.mainAttachment
  ),

  selectDocumentTransactionType: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state.document?.transactionType
  ),
  selectAttachmentKind: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.attachment?.kind
  ),

  selectAttachmentKindString: createSelector(
    selectWorkspace,
    (state: WorkspaceState) =>
      EKindAttachment[state?.attachment?.kind].toLowerCase()
  ),

  selectRecordState: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.formBuilderRequiredFieldsFilled
  ),

  selectIsPromissoryNote: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.isPromissoryNoteFlow
  ),

  selectAttachmentUrl: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.attachment?.url || null
  ),

  selectAttachmentData: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.attachment?.xml
  ),

  selectOnlyOfficeConfig: createSelector(
    selectWorkspace,
    (state: WorkspaceState): Partial<OnlyOfficeEditorProps> => {
      const key = state?.attachment?.textEditorConfig?.key;
      const url = state?.attachment?.url;
      const textEditorSubscriberId =
        state?.attachment?.textEditorConfig?.subscriberId;
      const uuid = state?.attachment?.id;
      const title = state?.attachment?.name;
      return {
        title,
        uuid,
        key,
        url,
        'subscription-ids': textEditorSubscriberId,
      };
    }
  ),

  selectViewerOptions: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.viewerOptions
  ),

  /**
   * Anexo seleccionado para ser visualizado en workspace
   */
  selectAttachment: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.attachment
  ),

  /**
   * Selecciona solo los anexos que no sean primary
   */
  selectAttachments: createSelector(
    selectWorkspace,
    (state: WorkspaceState) =>
      state?.document?.attachments?.filter(
        (attachment) => attachment.primary === false
      ) || []
  ),

  selectShowMetadataTab: createSelector(
    selectWorkspace,
    selectAuth,
    (state: WorkspaceState, authState: AuthState) => {
      if (state.document) {
        const DocTransactionType = state.document.transactionType;
        const DocStatus = state.document.status.key;
        const CurrentParticipant: INaturalPerson | ILegalPerson =
          state.configuration.participants.find((participant) => {
            // ! se busca el participante por el email de invitado
            if (state.guestEmail) {
              if (participant.kind === EParticipantKind.LEGAL_PERSON) {
                return participant.legalRepresentatives.find(
                  (legalRepresentative) =>
                    legalRepresentative.email === state.guestEmail
                );
              } else {
                return participant.email === state.guestEmail;
              }
            } else {
              const TokenDecoded = jwt_decode(authState.accessToken);
              const [email] = [TokenDecoded['email']];

              if (participant.kind === EParticipantKind.LEGAL_PERSON) {
                return participant.legalRepresentatives.find(
                  (legalRepresentative) => legalRepresentative.email === email
                );
              } else {
                return participant.email === email;
              }
            }
          });
        // * Tab de metadatos visible solo cuando:
        // * 1= Es proceso de firma, es autor, el documento esta en draft y el kind del attachment principal sea de algún tipo template (template, ticket, form)
        // * 2= Es proceso de firma, es participante y el documento esta en data_collection
        // * 3= Es proceso de firma, es participante y el particpante tiene signature_quotes y esta en in_sign (leyendas)
        if (DocTransactionType === ETransactionType.SIGNATURE) {
          // estatus draft solo se ve por el autor, valida el tipo attachment
          if (
            (DocStatus === EDocumentStatus.DRAFT &&
              [
                EKindAttachment.TEMPLATE,
                EKindAttachment.TICKET,
                EKindAttachment.FORM,
              ].includes(state.mainAttachment.kind)) ||
            (DocStatus === EDocumentStatus.DATA_COLLECTION &&
              CurrentParticipant)
          ) {
            return true;
          }
          // si es participante
          if (
            DocStatus === EDocumentStatus.IN_SIGN &&
            CurrentParticipant &&
            CurrentParticipant.quote
          ) {
            return true;
          }

          return false;
        }

        if (
          DocTransactionType === ETransactionType.REQUEST &&
          DocStatus !== EDocumentStatus.EXPIRED
        ) {
          return true;
        }

        return false;
      }
    }
  ),

  selectLookFeel: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state.lookFeel
  ),

  selectAccessTokens: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state.tokens
  ),

  selectAttachmentsReaded: createSelector(
    selectWorkspace,
    (state: WorkspaceState) =>
      !!state?.document?.attachments?.find(
        (attachment) => attachment.readStatus !== EAttachmentReadStatus.READED
      )
  ),
  selectSignatureVerificationType: createSelector(
    selectWorkspace,
    (state: WorkspaceState) =>
      state.document?.signingConfiguration?.signatureVerificationTypeId
  ),

  selectWorkingMode: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.workingModeType
  ),

  signatureQuotes: createSelector(
    selectWorkspace,
    (state: WorkspaceState) => state?.configuration?.participantSignatureQuotes
  ),
};

export const selectCurrentParticipant = createSelector(
  selectWorkspace,
  (state: WorkspaceState) => {
    return mergeSignRequests(state?.document?.signRequests);
  }
);

export type MergedSignRequest = {
  [key: string]: any;
  taxId?: string | null;
  email?: string;
  isOneSelf?: boolean;
};

const isValidString = (str: string | null | undefined): str is string => {
  return typeof str === 'string' && str.trim().length > 0;
};

const getKeyForPerson = (person: MergedSignRequest): string => {
  if (isValidString(person.taxId)) {
    return person.taxId;
  } else if (isValidString(person.email)) {
    return person.email;
  }
  return '';
};

const processAndAddPerson = (
  person: MergedSignRequest,
  mergedRequests: { [key: string]: MergedSignRequest }
) => {
  if (!person.isOneSelf) return;

  const key = getKeyForPerson(person);
  if (key) {
    mergedRequests[key] = person;
  }
};

const mergeSignRequests = (signRequests: {
  naturalPersons: MergedSignRequest[];
  legalPersons: MergedSignRequest[];
}): MergedSignRequest => {
  const mergedRequests: { [key: string]: MergedSignRequest } = {};
  if (!signRequests) {
    return;
  }

  signRequests.naturalPersons.forEach((person) => {
    processAndAddPerson(person, mergedRequests);
  });

  signRequests.naturalPersons.forEach((person) => {
    if (person.substitute === null) {
      return;
    }
    processAndAddPerson(person.substitute, mergedRequests);
  });

  signRequests?.legalPersons.forEach((person) => {
    processAndAddPerson(person, mergedRequests);
  });

  return Object.values(mergedRequests).reduce(
    (acc, curr) => ({ ...acc, ...curr }),
    {}
  );
};
