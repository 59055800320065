import * as moment from 'moment';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {
  IEmailHistory,
  ILastEmailEvent,
  ILegalPerson,
  INaturalPerson,
} from '@literax/models/participant.model';
import { EDocumentStatus } from '@literax/enums/document.enum';

import { ParticipantActions } from '@literax/modules/documents/store/workspace/actions/participant.actions';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { IDocumentConfiguration } from '@literax/modules/documents/store/workspace/workspace.state';
import { EParticipantKind } from '@literax/enums/participant.enum';
import { ofType } from '@ngrx/effects';

export interface HisotryDialogData {
  payload: any;
  signer: any;
}

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  documentStatus = EDocumentStatus;
  @Input() emailEvent: ILastEmailEvent;
  @Input() signer: INaturalPerson | ILegalPerson;
  @Input() statusDocument: string;
  @Output() resend: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  history$: Observable<IEmailHistory[]> = this.store.pipe(
    select(WorkspaceSelectors.selectDocumentParticipants),
    map((data: IDocumentConfiguration) => {
      const participant = data.participants
        .map((participant) => {
          if (participant.kind === EParticipantKind.LEGAL_PERSON) {
            return participant.legalRepresentatives.find(
              (legalRepresentative) => {
                if (
                  legalRepresentative.signRequestId ===
                  this.signer.signRequestId
                ) {
                  return legalRepresentative;
                }
              }
            );
          }
          if (participant.signRequestId === this.signer.signRequestId) {
            return participant;
          }
        })
        .$flat(1)
        .filter((element) => element !== undefined);

      return participant[0] ? participant[0].lastEmailEvent.history : [];
    })
  );
  sendEmail = false;
  histoyIcons = {
    processed: 'fal fa-check neutro fa-stack-1x',
    dropped: 'fal fa-exclamation-triangle fa-stack-1x',
    deferred: 'fal fa-exclamation-triangle fa-stack-1x',
    bounce: 'fal fa-exclamation-triangle fa-stack-1x',
    delivered: 'fal fa-check neutro fa-stack-1x',
    opened: 'fal fa-check green fa-stack-1x',
    clicked: 'fal fa-check green fa-stack-1x',
    unsubscribed: 'fal fa-exclamation-triangle fa-stack-1x', //no implementado
    spam_report: 'fal fa-exclamation-triangle fa-stack-1x',
  };

  secondHistoyIcons = {
    processed: 'fa fa-circle neutro fa-stack-2x',
    dropped: 'fa fa-circle yellow fa-stack-2x',
    deferred: 'fa fa-circle yellow fa-stack-2x',
    bounce: 'fa fa-circle yellow fa-stack-2x',
    delivered: 'fa fa-circle neutro fa-stack-2x',
    opened: 'fa fa-circle green fa-stack-2x',
    clicked: 'fa fa-circle green fa-stack-2x',
    unsubscribed: 'fa fa-circle yellow fa-stack-2x', //no implementado
    spam_report: 'fa fa-circle yellow fa-stack-2x',
  };

  isDisabled: boolean;

  constructor(
    private modal: NzModalRef,
    private store: Store<IAppState>,
    private actions$: ActionsSubject
  ) {}

  ngOnInit() {
    this.sendEmail = false;
    this.isDisabled = this.signer?.status?.key === EDocumentStatus.SIGNED;

    this.actions$
      .pipe(ofType(ParticipantActions.sendSignerEmailSuccess), take(1))
      .subscribe(() => {
        this.modal.close();
      });

    this.store.dispatch(
      ParticipantActions.getSignerEmailHistory({
        payload: { signerId: this.signer.signRequestId },
      })
    );
  }

  sendMailClicked() {
    if (!this.isDisabled) {
      this.store.dispatch(
        ParticipantActions.sendSignerEmail({
          payload: {
            signerId: this.signer.signRequestId,
            name: this.signer.name,
          },
        })
      );
    }
  }
}
