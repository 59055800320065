import { Component, OnDestroy, OnInit } from '@angular/core';
import { EDocumentStatus } from '@literax/enums/document.enum';
import {
  Attachment,
  XmlValidationResponse,
} from '@literax/modules/validator/validator.interfaces';
import { IAppState } from '@literax/store';
import { Store, select } from '@ngrx/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-xml-validation-info',
  templateUrl: './xml-validation-info.component.html',
  styleUrls: ['./xml-validation-info.component.scss'],
})
export class XmlValidationInfoComponent implements OnInit, OnDestroy {
  document: XmlValidationResponse;
  documentStatus = EDocumentStatus;
  attachments: Attachment[];
  mainAttachment: Attachment;
  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.store
      .pipe(select((state) => state.validator.XMLValidatedDocument))
      .subscribe((document) => {
        this.document = document;
        this.attachments = document?.attachments;
        this.mainAttachment = document.attachments.find(
          (attachment) => attachment?.primary
        );
      });
  }

  ngOnDestroy() {}
}
