<div nz-row>
  <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
    <div class="signers-content" *ngIf="signature$ | async as signature">
      <div class="pdd-12 validation-participants">
        {{ 'VALIDATOR_UUID.FORM.SIGNS.TITLE' | translate }}
      </div>
      <div *ngIf="signers?.length > 0">
        <nz-collapse>
          <nz-collapse-panel *ngFor="let signatures of signers" [nzHeader]="signatures?.name" [nzActive]="false"
            [nzExtra]="extraSigners" [nzDisabled]="false">
            <div nz-row>
              <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
                <nz-alert *ngIf="signatures?.companyName !== null" nzType="error" nzDescription="{{
                    'VALIDATOR_UUID.MATCARD.TITLE_PERSONAL_ELECTRONIC'
                      | translate
                  }}">
                </nz-alert>
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical"
                  *ngIf="signatures?.companyName !== null">
                  <nz-descriptions-item nzTitle="{{ 'VALIDATOR_UUID.FORM.BUSINESS_NAME' | translate }}">
                    {{ signatures?.companyName }}
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="{{ 'VALIDATOR_UUID.FORM.RFC' | translate }}">
                    {{ signatures?.rfc }}
                  </nz-descriptions-item>
                </nz-descriptions>
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SERIE_NUMBER'
                        | translate
                    }}">
                    {{ signatures.serialNumber }}
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.DATE_OCSP'
                        | translate
                    }}">
                    {{ signatures.ocspRequestDate * 1000| date:'yyyy-MM-ddTHH:mm:ss.SSS':'+0:00' }}Z
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>

              <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.HOUR' | translate
                    }}">
                    {{ signatures.createdAt * 1000| date:'yyyy-MM-ddTHH:mm:ss.SSS':'+0:00' }}Z
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.GEO' | translate
                    }}">
                    {{ signatures?.clientIp }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>

              <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGNS.DIGITAL'
                        | translate
                    }}">
                    {{ signatures.signData }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
            </div>

            <hr />

            <div>NOM 151</div>
            <div nz-row>
              <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.DATE_CERT'
                        | translate
                    }}">
                    {{ signatures.pscTimestamp * 1000| date:'yyyy-MM-ddTHH:mm:ss.SSS':'+0:00' }}Z
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.PSC_POLICY'
                        | translate
                    }}">
                    {{ signatures.policy }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>

              <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.FINGER_PRINT'
                        | translate
                    }}">
                    {{ signatures.fingerPrint }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>

              <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item nzTitle="{{
                      'VALIDATOR_UUID.FORM.SIGNS.MAT-EXPANSION.SIGN_PSC'
                        | translate
                    }}">
                    {{ signatures.pscSignature }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>

        <ng-template #extraSigners>
          <span nz-icon>
            <i class="fal fa-pen-fancy"></i>
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>