export enum EKindAttachment {
  PDF = 0,
  MD,
  XML,
  PROMISSORY_NOTE,
  ENDORSEMENT,
  PROMISSORY_NOTE_EXTINCTION,
  ENDORSEMENT_REVOCATION,
  RECEIPT,
  PROMISSORY_NOTE_PRESENTATION_FOR_PAYMENT,
  ASSIGNMENT_OF_RIGHTS,
  TEMPLATE,
  DOCX,
  TICKET,
  SUPPORT,
  FORM,
  CONTRACT,
  CONTRACT_ATTACHMENT
}

export enum EAttachmentReadStatus {
  NONE = 0,
  PENDING,
  READED,
}

export enum EViewerType {
  PDF = 0,
  DOCX,
  MD,
  LOADING,
}
