<canvas #signaturePad class="drawer" width="100px" height="200px"></canvas>
<div class="clean-drawer">
  <button
    nz-button
    nzType="primary"
    nzSize="small"
    (click)="canvasElement?.clear()"
    *ngIf="!canvasElement?.isEmpty()"
  >
    {{ 'REPORTS.FILTER.CLEAR' | translate }}
  </button>
</div>
