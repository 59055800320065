import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[onDragLeave]'
})
export class OnDragLeaveDirective {
  @Output() onDragLeave = new EventEmitter();

  @HostListener('dragleave', ['$event']) public onDragLeaveDirective(
    event: any
  ) {
    event.stopPropagation();
    event.preventDefault();
    this.onDragLeave.emit(event);
  }
}
