import * as EFreeDocumentActions from './free-document.actions';
import * as signatureActions from '@literax/modules/documents/store/signature/signature.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { DocumentService } from '@literax/services/document/document.service';
import { DocumentsSignService } from '@literax/services/sign/documents-sign.service';
import { GuestService } from '@literax/services/guest/guest.service';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/attachment.model';
import { ICreateSignRequestResponse } from '@literax/models/http/api/sign-request/sign-request.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { Injectable } from '@angular/core';
import { LiteraxNotificationServiceService } from '@literax/services/literax-notification-service.service';
import { Router } from '@angular/router';
import { ServiceResponse } from './../../interfaces/service-response';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { selectDocumentFree } from './free-document.selectors';

@Injectable()
export class FreeDocumentEffects {
  updateFreeDocument$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EFreeDocumentActions.UpdateFreeDocument),
        map((action) => {
          if (action.payload.docName) {
            this.router.navigate([
              '/to-validate',
              { docName: action.payload.docName },
            ]);
          } else {
            this.router.navigate(['/signed']);
          }
        })
      ),
    { dispatch: false }
  );

  getFreeDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EFreeDocumentActions.GetFreeDocument,
        EFreeDocumentActions.changeStatusTemplateSuccess,
        signatureActions.RejectSignatureSuccess
      ),
      withLatestFrom(this.store$.select((state) => state.freeDocuments.token)),
      switchMap(([action, token]) =>
        this.documentService.getDocumentDetail(token).pipe(
          map((response: ServiceResponse) =>
            EFreeDocumentActions.GetDocumentSuccess({
              payload: response.document as IDocument,
            })
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              EFreeDocumentActions.GetDocumentError({
                payload: err.error as IAPIResponseError,
              })
            )
          )
        )
      )
    )
  );

  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.DeleteDocument),
      switchMap((action) =>
        this.documentService.deleteDocument(action.payload.documentId).pipe(
          map((response: ServiceResponse) =>
            EFreeDocumentActions.DeleteDocumentSuccess()
          ),
          catchError((err: HttpErrorResponse) =>
            of(EFreeDocumentActions.DeleteDocumentError(err.error.error))
          )
        )
      )
    )
  );

  DeleteDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EFreeDocumentActions.DeleteDocumentSuccess),
        withLatestFrom(
          this.store$.select((state) => state.documents.activeFilter)
        ),
        tap(([action, filter]) => {
          this.router.navigate([`/platform/process/${filter.process}`]);
        })
      ),
    { dispatch: false }
  );

  getBeneficiary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.GetBeneficiary),
      switchMap((action) =>
        this.documentService.getBeneficiary(action.payload.token).pipe(
          map((response) =>
            EFreeDocumentActions.GetBeneficiarySuccess(response.holder)
          ),
          catchError((err: HttpErrorResponse) =>
            of(
              EFreeDocumentActions.GetDocumentError({
                payload: err.error.error,
              })
            )
          )
        )
      )
    )
  );

  getCurrencies = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.GetCurrencies),
      switchMap((action) =>
        this.documentService.getCurrencies(action.payload.token).pipe(
          map((response) =>
            EFreeDocumentActions.GetCurrenciesSuccess(response.currencies)
          ),
          catchError((err: HttpErrorResponse) =>
            of(EFreeDocumentActions.GetDocumentError(err.error.error))
          )
        )
      )
    )
  );

  registerPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.RegisterPaymentFree),
      switchMap((action) =>
        this.documentService
          .savePromissoryNotePayment(
            action.payload.promissoryNotePayment,
            action.payload.token
          )
          .pipe(
            map((response) =>
              EFreeDocumentActions.RegisterPaymentFreeSuccess({
                payload: response.promissory_note_payment,
              })
            ),
            tap(() => this.toast.success('TOAST_NOTIFICATIONS.SAVE_SUCCESS')),
            catchError((err: HttpErrorResponse) =>
              of(
                EFreeDocumentActions.GetDocumentError({
                  payload: err.error.error,
                })
              )
            )
          )
      )
    )
  );

  getDocumentAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.GetDocumentAttachment),
      withLatestFrom(this.store$.select(selectDocumentFree)),
      switchMap(([action, currentDocument]) =>
        this.docService
          .getAttachmentById(
            action.payload.documentId,
            action.payload.attachmentId,
            { requestOnlyOfficeConfig: false, requestPdfVersion: false },
            action.payload.token
          )
          .pipe(
            map((response: IAttachment) =>
              EFreeDocumentActions.GetDocumentAttachmentSuccess({
                payload: response,
              })
            ),
            tap((actionType) => {
              if (
                actionType.type ===
                EFreeDocumentActions.EFreeDocumentActions
                  .GetDocumentAttachmentSuccess
              ) {
                const loadedAttachment = currentDocument?.attachments.find(
                  (attachment) => attachment.id === actionType.payload.id
                );
                if (
                  (loadedAttachment.text === null &&
                    loadedAttachment?.id === action.payload.attachmentId) ||
                  (loadedAttachment.id !== action.payload.attachmentId &&
                    loadedAttachment.text === null)
                ) {
                  const attachment = actionType.payload;
                  this.httpClient
                    .get(attachment?.filePath, {
                      reportProgress: true,
                      observe: 'events',
                      responseType: 'blob'
                    })
                    .subscribe((response) => {
                      if (response.type === HttpEventType.Response) {
                        const file_reader = new FileReader();
                        file_reader.onloadend = (ev) => {
                          if (
                            ev.loaded &&
                            typeof file_reader.result === 'string'
                          ) {
                            this.store$.dispatch(
                              EFreeDocumentActions.SetFreeDocumentAttachmentBase64Data(
                                {
                                  payload: {
                                    attachmentId: attachment.id,
                                    data: file_reader.result,
                                  },
                                }
                              )
                            );
                          }
                        };
                        file_reader.readAsDataURL(response.body);
                      }
                    });
                }
              }
            }),
            catchError((err: HttpErrorResponse) =>
              of(
                EFreeDocumentActions.GetDocumentAttachmentError({
                  payload: err.error.error,
                })
              )
            )
          )
      )
    )
  );
  changeStatusTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.changeStatusTemplate),
      switchMap((action) =>
        this.documentService
          .changeStatusTemplate(action.payload.state, action.payload.token)
          .pipe(map(() => EFreeDocumentActions.changeStatusTemplateSuccess()))
      )
    )
  );

  changeStatusReviewer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EFreeDocumentActions.changeStatusReviewer),
        switchMap((action) =>
          this.documentService
            .changeStatusReviewer(action.payload.state, action.payload.token)
            .pipe(
              tap(() => {
                if (action.payload.state === 'to_reviewed')
                  this.router.navigate(['/reviewed']);
                if (action.payload.state === 'to_approved')
                  this.router.navigate(['/approved']);
              })
            )
        )
      ),
    { dispatch: false }
  );

  CancelDocumentProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.DocumentCancelProcess),
      withLatestFrom(this.store$.select(selectDocumentFree)),
      switchMap(([action, document]) =>
        this.documentService
          .documentCancelProcess(
            document?.id,
            action.payload.comment,
            action.payload.token
          )
          .pipe(
            map(() => EFreeDocumentActions.DocumentCancelProcessSuccess()),
            catchError(() =>
              of(EFreeDocumentActions.DocumentCancelProcessError())
            )
          )
      )
    )
  );

  checkAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.CheckAttachment),
      switchMap((action) =>
        this.documentService
          .checkAttachment(
            action.payload.documentId,
            action.payload.attachmentId,
            action.payload.token
          )
          .pipe(
            map((response: ServiceResponse) =>
              EFreeDocumentActions.CheckAttachmentSuccess({
                payload: response.attachment,
              })
            ),
            catchError(() => of(EFreeDocumentActions.CheckAttachmentError()))
          )
      )
    )
  );

  GetCancellationReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.GetCancellationReasons),
      exhaustMap((action) =>
        this.documentService
          .getCommentRejected(action.payload.documentId, action.payload.token)
          .pipe(
            map((response) =>
              EFreeDocumentActions.GetCancellationReasonsSuccess({
                payload: response.cancellation_reasons,
              })
            ),
            catchError(() =>
              of(EFreeDocumentActions.GetCancellationReasonsError())
            )
          )
      )
    )
  );

  getNewLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EFreeDocumentActions.GetNewLink),
      switchMap((action) =>
        this.guestService
          .sendNewLink(
            action.payload.documentId,
            action.payload.signRequestId,
            action.payload.token
          )
          .pipe(
            map((response: ICreateSignRequestResponse) =>
              EFreeDocumentActions.GetNewLinkSuccess({
                payload: response,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(EFreeDocumentActions.GetNewLinkError(error.error))
            )
          )
      )
    )
  );

  constructor(
    private documentService: DocumentsSignService,
    private docService: DocumentService,
    private actions$: Actions,
    private router: Router,
    private toast: LiteraxNotificationServiceService,
    private store$: Store<IAppState>,
    private httpClient: HttpClient,
    private guestService: GuestService
  ) {}
}
