import { Action, createReducer, on } from '@ngrx/store';
import {
  ConfigState,
  initialConfigState
} from '@literax/modules/configurations/store/config/config.state';

import { setLang } from '@literax/modules/configurations/store/config/config.actions';

const configReducer = createReducer(
  initialConfigState,
  on(setLang, (state, action) => ({ ...state, lang: action.payload as string }))
);

export function configReducers(state: ConfigState | undefined, action: Action) {
  return configReducer(state, action);
}
