import { Directive, ElementRef, Optional } from '@angular/core';
import { fromEvent } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { NgControl, FormGroupDirective, NgForm } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'form'
})
export class FormSubmitDirective {
  submit$ = fromEvent(this.element, 'submit').pipe(shareReplay(1));

  constructor(
    private host: ElementRef<HTMLFormElement>,
    @Optional() public formGroupDirective: FormGroupDirective
  ) {}

  get element() {
    return this.host.nativeElement;
  }
}
