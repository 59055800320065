import { IDocument } from './http/document/document.model';
import { IPaginationV3 } from './pagination.model';
import { ISignRequest } from '@literax/modules/documents/store/document/document.state';
import { IUserInfoPromissoryNoteTemplate } from '../components/configurations/users/models/users.interface';

export interface IPromissoryNotes {
  params: PromissoryParams;
  defaultData?: PromissoryDefaultData;
  configData?: PromissoryConfigData[];
}

export interface PromissoryParams {
  layoutId: string;
  applicationId: string;
  SignAndStamp: boolean;
  makePDF: boolean;
  isLegal: boolean;
  cfdiMx: boolean;
  validateXml: boolean;
  XmlData: string;
  theme: string;
  sections: { [key: string]: boolean };
  token: string;
  refreshToken: string;
  isCollapsible: boolean;
  primaryColor: string;
  cultureInfoCode: string;
}

export interface PromissoryConfigData {
  key: string;
  valueProp: string;
  labelProp: string;
  options: PromissoryConfigDataOptions[];
}

export interface PromissoryConfigDataOptions {
  name: string;
  value: string;
}

export interface PromissoryDefaultData {
  Document: {
    id: string;
  };
}

export interface IPermittedActions {
  [key: string]: boolean;
}

export interface IFolder {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
  expiry?: Date;
  documents?: IDocument[];
  main_document: IDocument;
  holder: IHolder;
  root_holder: IHolder;
  actions?: IPermittedActions;
  amount?: number | string;
  balance_amount?: number;
  currency?: string;
  subscriptor_name: string;
  participants?: ISignRequest[];
  has_documents?: boolean;
}

export interface IFlows {
  flow_key?: string;
  name?: string;
  color?: string;
  count: number;
  notifications: number;
}

export interface IRowFieldsBeneficiary {
  suscriptor?: boolean;
  ammount?: boolean;
  author?: boolean;
  createdAt?: boolean;
  expiry?: boolean;
  status?: boolean;
}

export interface IRowActionsBeneficiary {
  share: boolean;
  notification: boolean;
}

export interface IHolder {
  id: number;
  status: string;
  kind: string;
  name?: string; // TODO: Verificar
  client: IClient;
}

export interface IClient {
  uuid: string;
  tax_id: string;
  name: string;
  kind: string;
}
export interface PromissoryNotePayment {
  id: number;
  rfc_beneficiary?: string;
  name?: string;
  payment_date: Date;
  capital_amount: number;
  ordinary_interest_amount: number;
  moratory_interest_amount: number;
  payment_method: string;
  voucher_name: string;
  voucher_url: string;
  reference: string;
  currency: string;
  signed: boolean;
  document_id: number;
}

export enum PromissoryNotesRole {
  beneficiary = 'holder',
  proxy = 'proxy_holder',
  property = 'property_holder',
  shared = 'observer',
  guarantor = 'guarantor',
  subscriber = 'subscriber'
}

export interface IPromissoryNoteTemplate extends ISavePromissoryNoteTemplate {
  id: number;
  deleted: boolean;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  user: IUserInfoPromissoryNoteTemplate;
}

export interface ISavePromissoryNoteTemplate {
  name?: string;
  editable: boolean;
  default: boolean;
  plain_text?: string;
  multilanguage: boolean;
}

//* Modelos para versión 3 (V3)
export enum ETypePromissoryNote {
  AUTHOR = 'author',
  HOLDER = 'holder',
  PROXY_HOLDER = 'proxy_holder',
  SUBSCRIPTOR = 'subscriptor'
}

export interface IPromissoryNoteStatusCounterInfo {
  id: number;
  kind: string;
  key: string;
  description: string;
  color: string;
  total: number;
}

interface IPromissoryNoteStatusCounter {
  inEffect: IPromissoryNoteStatusCounterInfo;
  expired: IPromissoryNoteStatusCounterInfo;
  extinct: IPromissoryNoteStatusCounterInfo;
}

export interface IPromissoryNoteCountersResponse {
  [x: string]: any;
  author: IPromissoryNoteStatusCounter | null;
  holder: IPromissoryNoteStatusCounter | null;
  proxyHolder: IPromissoryNoteStatusCounter | null;
  subscriptor: IPromissoryNoteStatusCounter | null;
}

export interface IPromissoryNote {
  id: number;
  folderId: number;
  name: string;
  totalAmount: number;
  currency: string;
  currentHolderName: string;
  rootHolderName: string;
  subscriptorName: string;
  createdAt: number;
  expiryAt: number;
  flow: {
    name: string;
  };
  status: IStatus;
}

export interface IStatus {
  id: number;
  name: string;
  color: string;
};

export interface IPromissoryNoteList {
  promissoryNoteItems: IPromissoryNote[];
  pagination: IPaginationV3;
}

export enum EAccumulatedStatesKeyPromissoryNote {
  IN_EFFECT = 'in_effect',
  EXPIRED = 'expired',
  EXTINC = 'extinc'
}
