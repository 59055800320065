<div nz-row class="compose-attachment-file">
  <div class="title">
    <span>
      {{ title | translate }}
    </span>
  </div>
  <div class="buttons">
    <button
      *ngIf="visibleButtons.includes(EButtonActions.WRITE)"
      nz-button
      nzType="primary"
      (click)="buttonClicked(EButtonActions.WRITE)"
    >
      <i class="fal fa-file-edit"></i>
      <span class="icon-separator"
        >{{ 'COMPOSE_ATTACHMENT_FILE.BUTTONS.WRITE' | translate }}
      </span>
    </button>

    <button
      *ngIf="visibleButtons.includes(EButtonActions.TEMPLATE)"
      nz-button
      nzType="primary"
      (click)="buttonClicked(EButtonActions.TEMPLATE)"
    >
      <i class="fal fa-file-spreadsheet"></i>
      <span class="icon-separator">
        {{ 'COMPOSE_ATTACHMENT_FILE.BUTTONS.TEMPLATE' | translate }}
      </span>
    </button>

    <button
      *ngIf="visibleButtons.includes(EButtonActions.UPLOAD)"
      nz-button
      nzType="primary"
      (click)="buttonClicked(EButtonActions.UPLOAD)"
    >
      <i class="fal fa-file-upload"></i>
      <span class="icon-separator"
        >{{ 'COMPOSE_ATTACHMENT_FILE.BUTTONS.UPLOAD' | translate }}
      </span>
    </button>
  </div>
</div>
