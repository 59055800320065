<div nz-row class="body-background">
  <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
    <div [class.validation-container]="isDesktopDevice">
      <div nz-row>
        <div
          nz-col
          nzXs="24"
          nzSm="24"
          nzMd="12"
          nzLg="12"
          nzXl="12"
          class="page-header-left-side"
        >
          <nz-page-header [nzBackIcon]="backIconTemplate">
            <nz-page-header-title>
              <button
                *ngIf="!isMobileDevice"
                nz-button
                nzType="primary"
                nzShape="circle"
              >
                <i class="fal fa-pen-nib"></i>
              </button>
              {{ 'VALIDATOR_UUID.TITLE' | translate }}
            </nz-page-header-title>
            <ng-template #backIconTemplate>
              <literax-back-button></literax-back-button>
            </ng-template>
          </nz-page-header>
        </div>

        <div
          nz-col
          nzXs="24"
          nzSm="24"
          nzMd="12"
          nzLg="12"
          nzXl="12"
          class="page-header-right-side"
        >
          <div class="date">
            {{ 'VALIDATOR_UUID.DATE' | translate }}
          </div>
          <div class="date-text">
            {{ today | translateMomentDate }}
          </div>
        </div>
      </div>

      <div class="inner-content">
        <nz-card>
          <div nz-row *ngIf="signature$ | async as signature">
            <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
              <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
                <nz-descriptions-item
                  nzTitle="{{
                    'VALIDATOR_UUID.FORM.DOCUMENT_NAME' | translate
                  }}"
                  [nzSpan]="3"
                >
                  <div class="validation-text">
                    {{ signature?.name }}
                  </div>
                </nz-descriptions-item>
                <nz-descriptions-item
                  nzTitle="{{ 'VALIDATOR_UUID.FORM.FOLIO' | translate }}"
                  [nzSpan]="3"
                >
                  <div class="validation-text">
                    <p>{{ signature?.uuid }}</p>
                  </div>
                </nz-descriptions-item>
                <nz-descriptions-item
                  nzTitle="{{
                    'VALIDATOR_UUID.FORM.TYPES_OF_SIGNATURES' | translate
                  }}"
                  [nzSpan]="3"
                >
                  <div class="validation-text">
                    {{
                      'VALIDATOR_UUID.FORM.TRADUCTIONS.TYPES_SIGNS.' +
                        signature?.signatureType.toUpperCase() | translate
                    }}
                  </div>
                </nz-descriptions-item>
                <nz-descriptions-item
                  nzTitle="{{ 'VALIDATOR_UUID.FORM.DATE' | translate }}"
                  [nzSpan]="3"
                >
                  <div class="validation-text">
                    {{ signature?.createdAt * 1000 | date : 'dd-MM-yyyy hh:mm' }}
                    {{ ' / ' }} {{ signature?.createdAt * 1000| date:'yyyy-MM-ddTHH:mm:ss.SSS':'+0:00' }}Z
                  </div>
                </nz-descriptions-item>
                <nz-descriptions-item
                  nzTitle="{{ 'VALIDATOR_UUID.FORM.STATUS' | translate }}"
                  [nzSpan]="3"
                >
                  <nz-badge
                    nzStatus="processing"
                    nzText="{{ signature?.status.toUpperCase() }}"
                  >
                  </nz-badge>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
              <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
                <nz-descriptions-item
                  nzTitle="{{ 'VALIDATOR_UUID.FORM.PARTICIPANTS' | translate }}"
                >
                  <div class="validation-row">
                    <div *ngIf="signature?.participants?.length > 0">
                      <div *ngFor="let participant of signature?.participants">
                        <div>
                          <span class="validation-text">{{
                            participant.name
                          }}</span>

                          <span class="validation-text-related-info">
                            |
                            {{
                              'VALIDATOR_UUID.FORM.TRADUCTIONS.PARTICIPANTS.' +
                                participant.role.toUpperCase() | translate
                            }}</span
                          >
                        </div>
                        <div
                          *ngIf="
                            signature.signatureType === 'digital_signature' &&
                            participant.rfc !== null
                          "
                        >
                          <span class="validation-rfc">
                            {{ 'VALIDATOR_UUID.FORM.RFC' | translate }}: </span
                          >
                          <span class=".validation-text-related-info">{{
                            participant.rfc
                          }}</span>
                        </div>
                        <div
                          *ngIf="
                            signature.signatureType !== 'digital_signature' ||
                            (signature.signatureType === 'digital_signature' &&
                              participant.rfc === null)
                          "
                        >
                          <span class="validation-rfc">
                            {{ 'VALIDATOR_UUID.FORM.EMAIL' | translate }}:
                          </span>
                          <span class="validation-text-related-info">{{
                            participant.email
                          }}</span>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="8" nzXl="8">
              <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
                <nz-descriptions-item
                  nzTitle="{{
                    'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.TITLE' | translate
                  }}"
                >
                  <div class="validation-row">
                    <nz-collapse>
                      <nz-collapse-panel
                        *ngFor="let related of signature?.relatedDocuments"
                        [nzHeader]="related.name"
                        [nzActive]="false"
                        [nzExtra]="extraTpl"
                        [nzDisabled]="false"
                      >
                        <div class="validation-text">
                          <span class="validation-label-related">
                            {{
                              'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.INFO.CREATE_AT'
                                | translate
                            }}:
                          </span>
                          <span class="validation-text-related-info">{{
                            related.createdAt * 1000 | date : 'dd-MM-yyyy'
                          }}</span>
                        </div>
                        <div class="validation-text">
                          <span class="validation-label-related">
                            {{
                              'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.INFO.DOCUMENT_TYPE'
                                | translate
                            }}:
                          </span>
                          <span
                            class="validation-text-related-info capitalize"
                            >{{
                              'VALIDATOR_UUID.FORM.TRADUCTIONS.KINDS.' +
                                related?.kind.toUpperCase() | translate
                            }}</span
                          >
                        </div>
                        <div class="validation-text">
                          <span class="validation-label-related">
                            {{
                              'VALIDATOR_UUID.FORM.RELATIONS_UUIDS.INFO.STATUS'
                                | translate
                            }}:
                          </span>
                          <span class="validation-text-related-info">
                            {{ related?.status.toUpperCase() }}
                          </span>
                        </div>
                        <div class="validation-text">
                          <a
                            class="validation-text-related underline"
                            (click)="onVerification(related.uuid)"
                          >
                            {{
                              'VALIDATOR_UUID.FORM.VIEW_DOCUMENT' | translate
                            }}
                            <i class="far fa-external-link"></i>
                          </a>
                        </div>
                      </nz-collapse-panel>
                    </nz-collapse>
                  </div>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
          </div>

          <div *ngIf="signature$ | async as signatureType">
            <literax-validator-signers></literax-validator-signers>
          </div>
        </nz-card>
      </div>
    </div>
    <div class="footer-content">
      <div class="footer-verification">
        {{ 'VALIDATOR_UUID.FOOTER.VERIFICATION_SIGN' | translate }}
      </div>
      <div class="footer-login">
        <a (click)="onLogin()" class="footer-login">
          {{ 'VALIDATOR_UUID.FOOTER.SIGNER' | translate }}
        </a>
      </div>
      <div class="footer-register">
        <a (click)="onRegister()" class="footer-register">{{
          'VALIDATOR_UUID.FOOTER.REGISTER' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
