import * as dashboardActions from './dashboard.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { IDashboardState, initialDashboardState } from './dashboard.state';

const dashboardReducer = createReducer(
  initialDashboardState,
  on(dashboardActions.getDashboardSuccess, (state, action) => ({ ...state, dashboard: action.payload })),
  on(dashboardActions.getDashboardError, (state, action) => ({ ...state, errorResponse: action.payload }))
);

export function dashboardReducers(state: IDashboardState | undefined, action: Action) {
  return dashboardReducer(state, action);
}
