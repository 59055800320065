import { Action, createReducer, on } from '@ngrx/store';
import {
  initialReportState,
  IReportState
} from '@literax/store/report/report.state';
import * as reportActions from './report.actions';

const reducer = createReducer(
  initialReportState,
  on(reportActions.ChangeMonth, (state, action) => ({
    ...state,
    month_filter: action.payload
  })),
  on(reportActions.ChangeYear, (state, action) => ({
    ...state,
    year_filter: action.payload
  })),
  on(reportActions.ChangeUserDepartment, (state, action) => ({
    ...state,
    user_department_filter: action.payload
  })),
  on(reportActions.ChangeDepartments, (state, action) => ({
    ...state,
    departments_filter: action.payload
  })),
  on(reportActions.ChangeCompanies, (state, action) => ({
    ...state,
    companies_filter: action.payload
  })),
  on(reportActions.ChangeSigners1, (state, action) => ({
    ...state,
    signers1_filter: action.payload
  })),
  on(reportActions.ChangeSigners2, (state, action) => ({
    ...state,
    signers2_filter: action.payload
  })),
  on(reportActions.GetReportSuccess, (state, action) => ({
    ...state,
    data_results: action.payload
  })),
  on(reportActions.GetSignersSuccess, (state, action) => ({
    ...state,
    signers: action.payload
  }))
);

export function reportReducers(state: IReportState, action: Action) {
  return reducer(state, action);
}
