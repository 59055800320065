import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IReviewer } from '@literax/models/reviewer.model';

export interface IReviewRequestWorkspaceState {
  reviewers: IReviewer[];
  newReviewerCreationErrors: Partial<IAPIResponseError>;
  newReviewerCreationSuccess: Partial<IReviewer>;
}

export const initialReviewRequestWorkspaceState: IReviewRequestWorkspaceState = {
  reviewers: [],
  newReviewerCreationErrors: null,
  newReviewerCreationSuccess: null
};
