import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEmail } from '@literax/components/configurations/profiles/models/emails';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { IAppState } from '@literax/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as profilesActions from '../states/profiles.actions';
import { of } from 'rxjs';
import { catchError, map, mergeMap, pluck, tap } from 'rxjs/operators';
import { IProfile } from '../models/profiles';
import { ProfilesService } from '../services/profiles.service';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { LiteraxNotificationServiceService } from '@literax/services/literax-notification-service.service';
import { UpdateProfileInfo } from '@literax/modules/auth/store/auth.actions';

@Injectable()
export class ProfilesEffect {
  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private profilesServices: ProfilesService,
    private toastr: LiteraxNotificationServiceService,
    private toast: I18nToastrService
  ) {}

  getAllProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilesActions.getAllProfiles),
      mergeMap((action) =>
        this.profilesServices.getAllProfiles().pipe(
          pluck('user'),
          map((response: IProfile) =>
            profilesActions.getAllProfilesSucces({ profile: response })
          ),
          catchError((error) => of(profilesActions.errorConf({ error })))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilesActions.updateProfilesbyId),
      mergeMap((action) =>
        this.profilesServices.update(action.profile).pipe(
          map((response: ServiceResponse) => {
            this.store.dispatch(UpdateProfileInfo({ payload: response.user }));
            return profilesActions.updateProfilesbyIdSucess();
          }),
          tap(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.UPDATE_SUCCESS');
            this.store.dispatch(profilesActions.getAllProfiles());
          }),
          catchError((error) =>
            of(profilesActions.errorConf({ error: error.error }))
          )
        )
      )
    )
  );

  getEmails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        profilesActions.getEmails,
        profilesActions.registerEmailSuccess,
        profilesActions.deleteEmailSuccess
      ),
      mergeMap((action) =>
        this.profilesServices.getEmails().pipe(
          map((response: ServiceResponse) =>
            profilesActions.getEmailsSuccess({
              payload: response.user_emails as IEmail[]
            })
          ),
          catchError((error) =>
            of(profilesActions.getEmailError({ payload: error }))
          )
        )
      )
    )
  );

  registerEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilesActions.registerEmail),
      mergeMap((action) =>
        this.profilesServices.registerEmail(action.payload).pipe(
          map((response: ServiceResponse) =>
            profilesActions.registerEmailSuccess()
          ),
          catchError((error: HttpErrorResponse) =>
            of(profilesActions.registerEmailError({ payload: error }))
          )
        )
      )
    )
  );

  deleteEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilesActions.deleteEmail),
      mergeMap((action) =>
        this.profilesServices.deleteEmail(action.payload).pipe(
          map((response: ServiceResponse) =>
            profilesActions.deleteEmailSuccess()
          ),
          catchError((error) =>
            of(profilesActions.deleteEmailError({ payload: error }))
          )
        )
      )
    )
  );

  sendNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilesActions.sendNotification),
      mergeMap((action) =>
        this.profilesServices.sendNotification(action.payload).pipe(
          map((response: ServiceResponse) =>
            profilesActions.sendNotificationSuccess()
          ),
          catchError((error) =>
            of(profilesActions.sendNotificationError({ payload: error }))
          )
        )
      )
    )
  );

  sendToValidateRFC$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilesActions.sendToValidateRFC),
      mergeMap((action) =>
        this.profilesServices.sendToValidateRFC(action.payload).pipe(
          map((response) => {
            this.toast.success(
              'PROFILE.FORM.SUCCESS_TITLE',
              'PROFILE.FORM.SUCCESS_MESSAGE'
            );
            return profilesActions.sendToValidateRFCSuccess();
          }),
          catchError((err) =>
            of(
              profilesActions.sendToValidateRFCError({
                payload: err.error.error.detail
              })
            )
          )
        )
      )
    )
  );
}
