<div class="main-container">
  <div
    class="show"
    [ngClass]="{
      'item-selected': attachment?.id === currentAttachmentId,
      'pr-16': (document$ | async)?.status?.key === 'draft'
    }"
  >
    <i
      *ngIf="
        attachment?.kind === 'pdf' ||
        ((document$ | async)?.status?.key !== 'draft' &&
          attachment?.kind === 'docx')
      "
      class="fal fa-file-pdf icon type-file"
      (click)="attachmentDetail()"
    ></i>
    <i
      *ngIf="
        attachment?.kind === 'docx' &&
        (document$ | async)?.status?.key === 'draft'
      "
      class="fal fa-file-word icon type-file"
      (click)="attachmentDetail()"
    ></i>
    <div class="name" (click)="attachmentDetail()">{{ attachment?.name }}</div>
    <div
      *ngIf="
        attachment?.kind == 'pdf' ||
        attachment?.kind == 'md' ||
        attachment?.kind == 'xml' ||
        attachment?.kind == 'docx'
      "
    >
      <i
        *ngIf="
          actions &&
          attachment.kind === 'pdf' &&
          attachment?.id === currentAttachmentId
        "
        [title]="'COMMON.TOOLTIPS.ACTIONS.REPLACE' | translate"
        class="fal fa-redo-alt icon action"
        (click)="replaceAttachment.emit(attachment)"
      ></i>

      <i
        *ngIf="
          actions &&
          attachment.kind === 'docx' &&
          attachment?.id === currentAttachmentId
        "
        [title]="'COMMON.TOOLTIPS.ACTIONS.EDIT' | translate"
        class="fal fa-pencil icon action"
        [ngClass]="{ action: attachment?.id === currentAttachmentId }"
        (click)="
          attachment?.id === currentAttachmentId &&
            editAttachment.emit(attachment)
        "
      ></i>
    </div>
    <i
      *ngIf="
        actions &&
        attachment &&
        delete &&
        attachment?.id === currentAttachmentId
      "
      [title]="'COMMON.TOOLTIPS.ACTIONS.DELETE' | translate"
      class="fal fa-trash-alt icon action"
      [ngClass]="{ action: attachment?.id === currentAttachmentId }"
      (click)="attachment?.id === currentAttachmentId && deleteAttachment()"
    ></i>
    <div *ngIf="!isMainDocumentKind && !isDocumentPath">
      <button *ngIf="canSign" class="go-to-sign" (click)="emitSign()">
        {{ 'WORKSPACE.FOOTER.SIGN' | translate }}
      </button>
    </div>

    <!-- * Check para saber si se han visto los anexos o no -->
    <div
      class="check-state"
      [ngClass]="{
        '': !attachment?.text,
        checked: attachment?.text
      }"
      *ngIf="
        !disabledActions &&
        attachment?.documentInfo?.status?.key !== 'draft' &&
        !showCheckAttachment
      "
      [title]="
        (attachment?.text
          ? 'COMMON.TOOLTIPS.ACTIONS.VIEWED'
          : 'COMMON.TOOLTIPS.ACTIONS.VIEW'
        ) | translate
      "
      (click)="
        attachment?.id === currentAttachmentId &&
          showCheckAttachment &&
          checkAttachment()
      "
    >
      <i class="fal fa-check-circle icon green" *ngIf="attachment?.text"></i>
      <i class="fal fa-circle icon gray" *ngIf="!attachment?.text"></i>
    </div>

    <!-- * Check para aprovar/revisar los anexos en status REVIEW || APPROVAL -->
    <div
      class="check-state"
      [ngClass]="{
        current: attachment?.text,
        '': !attachment?.text,
        checked: attachment?.checked,
        action: attachment?.id === currentAttachmentId && showCheckAttachment
      }"
      *ngIf="showCheckAttachment"
      (click)="
        attachment?.id === currentAttachmentId &&
          showCheckAttachment &&
          checkAttachment()
      "
      [title]="
        (!attachment?.checked &&
        attachment?.documentInfo?.status?.key === 'APPROVAL'
          ? 'COMMON.TOOLTIPS.ACTIONS.APPROVE_ATTACHMENT'
          : attachment?.checked &&
            attachment?.documentInfo?.status?.key === 'APPROVAL'
          ? 'COMMON.TOOLTIPS.ACTIONS.DISAPPROVE_ATTACHMENT'
          : !attachment?.checked &&
            attachment?.documentInfo?.status?.key === 'REVIEW'
          ? 'COMMON.TOOLTIPS.ACTIONS.CHECK_ATTACHMENT'
          : attachment?.checked &&
            attachment?.documentInfo?.status?.key === 'REVIEW'
          ? 'COMMON.TOOLTIPS.ACTIONS.UNCHECK_ATTACHMENT'
          : ''
        ) | translate
      "
    >
      <i class="fal fa-check-circle icon green" *ngIf="attachment?.checked"></i>
      <i
        class="fal fa-circle icon gray"
        *ngIf="!attachment?.text && !attachment?.checked"
      ></i>
      <i
        class="fal fa-circle icon yellow"
        *ngIf="attachment?.text && !attachment?.checked"
      ></i>
    </div>
  </div>
</div>
