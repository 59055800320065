import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-show-comment-rejected',
  templateUrl: './show-comment-rejected.component.html',
  styleUrls: ['./show-comment-rejected.component.scss'],
})
export class ShowCommentRejectedComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ShowCommentRejectedComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  getFirstLetters(): string {
    return this.data.user
      ?.split(' ')
      .map((x) => x[0])
      .join('');
  }
}
