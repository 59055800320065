<div class="container">
  <div class="row">
    <div id="sign-document">
      <form id="signerForm" #fileForm [formGroup]="form" (ngSubmit)="generateSignature()">
        <div formGroupName="representative">
          <literax-digital-signature-form [form]="form.get('representative')" [identification]="identification"
            [label]="representativeLabel" [certificateAPIErrors]="certificateAPIErrors$ | async"
            [signDataAPIErrors]="signDataAPIErrors$ | async"
            [keyAndSignDataAPIErrors]="keyAndSignDataAPIErrors$ | async" [serverErrors]="serverErrors$ | async">
          </literax-digital-signature-form>
        </div>
        <div *ngIf="form.get('moral')">
          <div *ngFor="let item of form.get('moral')?.controls">
            <literax-digital-signature-form [form]="item" [certificateAPIErrors]="certificateAPIErrors$ | async"
              [label]="moralLabel" [signDataAPIErrors]="signDataAPIErrors$ | async"
              [keyAndSignDataAPIErrors]="keyAndSignDataAPIErrors$ | async" [serverErrors]="serverErrors$ | async">
            </literax-digital-signature-form>
          </div>
        </div>
        <div class="block">
          <label nz-checkbox formControlName="acceptTerms">
            {{ 'PROFILE.FORM.ACEPT_TERMS' | translate }} {{ document?.name }}
            <span *ngIf="document?.attachments.length > 1">
              {{ 'PROFILE.FORM.ARTICULE' | translate }} {{ attachmentCount }}
              {{ 'PROFILE.FORM.ANNEXES' | translate }}
            </span>
          </label>
        </div>
      </form>
    </div>
  </div>
</div>
<div *nzModalFooter class="footer-custom">
  <button *ngIf="showCancel" nz-button nzType="default" (click)="cancel($event)">
    <i class="fal fa-times-circle"></i>{{ 'FORM.FILE_INPUT.CANCEL' | translate }}
  </button>
  <button *ngIf="showSubmit" nz-button nzType="primary" type="submit" [disabled]="!this.form.valid" form="signerForm">
    <i class="fal fa-pen-fancy"></i>{{ 'FORM.FILE_INPUT.SIGN' | translate }}
  </button>
</div>