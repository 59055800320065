import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { IAppState } from '@literax/store';
import { MsalService } from '@azure/msal-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceButtonBar } from '@literax/modules/documents/workspace/components/buttons-bar-layout/workspace-button-bar';

@UntilDestroy()
@Component({
  selector: 'literax-review-buttons',
  templateUrl: './review-buttons.component.html',
  styleUrls: ['./review-buttons.component.scss'],
})
export class ReviewButtonsComponent
  extends WorkspaceButtonBar
  implements OnInit
{

  isHidden = true;

  constructor(
    store: Store<IAppState>,
    router: Router,
    modalService: NzModalService,
    translateService: TranslateService,
    b2cAuthService: B2CAuthService,
    msalService: MsalService
  ) {
    super({ store, router, modalService, translateService, b2cAuthService, msalService });
  }

  ngOnInit(): void {}
}
