<div nz-row class="body-background">
  <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
    <div class="top-divider">
    </div>
    <div class="validation-container">
      <div nz-row>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <h5 class="title">{{ 'VALIDATOR_UUID.TITLE' | translate }}</h5>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24" class="main-container">
            <nz-card
                [nzTitle]="cardTitle"
            >
                <nz-result
                    nzStatus="404" 
                    nzTitle="{{ 'VALIDATOR_UUID.E404.MESSAGE_DENIED' | translate }}" 
                    nzSubTitle="{{ 'VALIDATOR_UUID.E404.MESSAGE_INVITATION' | translate }}"
                >
                    <div nz-result-extra>
                        <button nz-button nzType="primary" (click)="onBack()">{{ 'VALIDATOR_UUID.E404.MESSAGE_BUTTON' | translate }}</button>
                    </div>
                </nz-result>
            </nz-card>
            <ng-template #cardTitle>
                <h4 style="text-align: center;">{{ 'VALIDATOR_UUID.E404.MESSAGE_DENIED_TITLE' | translate }}</h4>
            </ng-template>
        </div>
      </div>
    </div>
    <div class="footer-content">
      <div class="footer-verification">
        {{ 'VALIDATOR_UUID.FOOTER.VERIFICATION_SIGN' | translate }}
      </div>
      <div class="footer-login">
        <a (click)="onLogin()" class="footer-login">
          {{ 'VALIDATOR_UUID.FOOTER.SIGNER' | translate }}
        </a>
      </div>
      <div class="footer-register">
        <a (click)="onRegister()" class="footer-register">{{
          'VALIDATOR_UUID.FOOTER.REGISTER' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
