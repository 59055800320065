<details #envelop ngDefaultControl data-popover>
  <summary>
    <i
      *ngIf="
        signer?.status?.key !== 'signed' &&
        signer?.isAuthor === false &&
        statusDocument !== 'draft'
      "
      class="fal fa-envelope clickable"
      [ngStyle]="{ 'font-size': '16px' }"
      [class.fa-envelope-open]="emailDelivered"
      [title]="
        'EMAIL_EVENT.STATUSES.' + emailStatusName | uppercase | translate
      "
      [ngbPopover]="popover"
      container="body"
      popoverClass="email-events-popover"
      placement="bottom-right"
    ></i>
  </summary>

  <ng-template #popover>
    <div id="popover-content">
      <div class="py-2 pl-3 bottom-notification">
        <span class="notification_name">
          {{
            'DOCUMENTS.SIGNERS.HISTORY.SENDER_TITLE'
              | translate: { name: signer.name }
          }}</span
        >
      </div>
      <div class="wrapper">
        <div class="vertical col-1">
          <div class="row h-100 pl-2">
            <span class="fa-stack fa-lg icon m-auto">
              <i [class]="secondHistoyIcons[emailStatusName]"></i>
              <i [class]="histoyIcons[emailStatusName]"></i>
            </span>
          </div>
        </div>
        <span class="col-11 bottom">
          <span class="row">
            <div class="p-2 align-middle col bold m-auto">
              {{
                'EMAIL_EVENT.STATUSES.' + emailStatusName
                  | uppercase
                  | translate
              }}
            </div>
            <div
              class="py-2 pr-3 align-middle float-right col-5 info text-right m-auto"
            >
              {{ lastEventEmail?.createdAt * 1000 | date: 'dd-MM-y, h:mm a' }}
            </div>
          </span>
        </span>
      </div>
      <div class="wrapper">
        <div class="vertical col-1">
          <div class="row h-100 pl-2">
            <span class="fa-stack fa-lg icon m-auto">
              <i class="fa fa-circle black fa-stack-2x"></i>
              <i class="fa fa-plus fa-stack-1x"></i>
            </span>
          </div>
        </div>
        <span class="col-11 bottom">
          <span class="row">
            <div class="p-2 align-middle col bold m-auto">
              {{ 'DOCUMENTS.SIGNERS.HISTORY.DIALOG_TITTLE' | translate }}
            </div>
            <div class="pr-3 align-middle m-auto">
              <button
                nz-button
                nzSize="small"
                nzType="primary"
                type="button"
                (click)="showHistory()"
              >
                {{ 'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.SHOW' | translate }}
              </button>
            </div>
          </span>
        </span>
      </div>
      <div class="d-flex p-2 align-items-center justify-content-end">
        <button
          nz-button
          nzType="primary"
          nzSize="small"
          type="button"
          (click)="actionClicked($event)"
          [disabled]="isDisabled"
          *ngIf="
            statusDocument !== documentStatus.EXPIRED &&
            statusDocument !== documentStatus.APPROVED &&
            emailDelivered
          "
        >
          <i class="far fa-paper-plane"></i>&nbsp;{{
            'DOCUMENTS.SIGNERS.HISTORY.ACTIONS.RESEND' | translate
          }}
        </button>
      </div>
    </div>
  </ng-template>
</details>
