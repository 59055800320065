import { Action, createReducer, on } from '@ngrx/store';
import {
  Get
} from './appearance.actions';
import {
  IAppearanceConfigurationsInterface,
  initialAppearanceConfigurationsState
} from './appearance.state';

const reducer = createReducer(
  initialAppearanceConfigurationsState,

);

export function appearanceConfigurationsReducer(
  state: IAppearanceConfigurationsInterface,
  action: Action
) {
  return reducer(state, action);
}
