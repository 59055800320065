import {
  IAPIError,
  IAPIResponseError
} from '@literax/models/http/api/error.model';

import { IProfile } from '@literax/components/configurations/profiles/models/profiles';

export interface AuthState {
  currentUser: IProfile;
  loginError: IAPIError;
  updateProfileError: IAPIResponseError;
  updatePasswordSuccess: boolean;
  accessToken: string;
}

export const initialAuthState: AuthState = {
  currentUser: null,
  loginError: null,
  updateProfileError: null,
  updatePasswordSuccess: null,
  accessToken: null,
};
