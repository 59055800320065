import { IBeneficiaries } from '@literax/models/report-promissory-note.model';
import { stringToColor } from '@literax/utils/app.utils';
import {
  initialPromissoryNoteReportState,
  IPromissoryNoteReportState,
} from './report-promissory-note.state';

export const reduceBeneficieariesData = (
  data: IBeneficiaries[]
): IPromissoryNoteReportState['beneficiaries'] => {
  const returnData: IPromissoryNoteReportState['beneficiaries'] = {
    chart: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
      centerData: {
        total: 0,
        label: 'REPORTS.PROMISSORY_NOTE.LABEL',
      },
    },
    total: [],
  };

  returnData.chart.centerData.total = data?.reduce(
    (acc, current) => acc + current.promissory_notes,
    0
  );

  data?.forEach((beneficiary) => {
    const record = {
      name: beneficiary._id,
      count: beneficiary.promissory_notes,
      amount: beneficiary.total_amount,
      color: stringToColor(beneficiary._id),
    };
    returnData.chart.labels.push(record.name);
    returnData.chart.datasets[0].data.push(record.count);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    returnData.chart.datasets[0].backgroundColor.push(record.color);
    returnData.total.push(record);
  });
  return { ...returnData };
};
