import { createAction, props } from '@ngrx/store';
import { XmlValidationResponse } from '../validator.interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { ISignatureValidatorRequest, ISignatureValidatorResponse } from '@literax/models/signature.model';

export const SendFilesToValidate = createAction(
  '@validator/SendFilesToValidate',
  props<{ payload: { files: File[] } }>()
);

export const ValidatedFilesResponse = createAction(
  '@validator/ValidatedFilesResponse',
  props<{ payload: XmlValidationResponse }>()
);

export const DissmisErrorMessage = createAction(
  '@validator/DissmisErrorMessage'
);

export const XmlValidationError = createAction(
  '@validator/XmlValidationError',
  props<{ payload: any }>()
);

export const ShowSignature = createAction(
  '[Signature] Show Signature',
  props<{ payload: ISignatureValidatorRequest }>()
);

export const ShowSignatureSuccess = createAction(
  '[Signature] Show Signature Success',
  props<{ payload: ISignatureValidatorResponse }>()
);

export const ShowSignatureError = createAction(
  '[Signature] Show Signature Error',
  props<{ payload: HttpErrorResponse }>()
);
