import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DocumentService } from '@literax/services/document/document.service';
import { EWorkingMode } from '@literax/modules/documents/store/workspace/workspace.state';
import Hashids from 'hashids';
import { IAppState } from '@literax/store';
import { IDocumentResponse } from '@literax/models/document.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ValidateDocumentSignatureSuccess } from '@literax/store/free-document/free-document.actions';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class ValidateTokenDocumentFreeGuard implements CanActivate {
  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private docService: DocumentService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {
    const token = next.paramMap.get('token');
    return this.isValid(token);
  }

  isValid(token: string) {
    this.store.dispatch(WorkspaceActions.setGuestToken({ payload: token }));
    const TokenDecoded = jwt_decode(token);
    const [hashedDuid, hashedSrid] = [
      TokenDecoded['uuid'],
      TokenDecoded['request'],
    ];
    const hashids = new Hashids(
      'ce3da9a74158e0ab11288fef3f8b0ffbafd18127ab2865b9889c6196c5f57e938f964b65fa5fafe2701c0bc555dbef3706a3e063d4dc24fd3b5a814f6783183b',
      15
    );
    let documentId = null;
    if (isNaN(hashedDuid)) {
      documentId = hashids.decode(hashedDuid)?.pop();
    } else {
      documentId = hashedDuid;
    }

    return this.docService.getDocumentById(documentId, token).pipe(
      map((document: IDocumentResponse) => {
        this.store.dispatch(
          WorkspaceActions.setWorkspaceMode({
            payload: { mode: EWorkingMode.GUEST },
          })
        );

        this.store.dispatch(
          WorkspaceActions.getUserInfo({
            payload: { email: TokenDecoded['email'], token: token },
          })
        );

        this.store.dispatch(
          WorkspaceActions.getAccessTokens({
            payload: {
              body: {
                textEditor: true,
                chat: true,
              },
              token: token,
            },
          })
        );

        this.store.dispatch(
          ValidateDocumentSignatureSuccess({
            payload: { document, token },
          })
        );
        this.store.dispatch(
          WorkspaceActions.getDocumentSuccess({
            payload: { document, getAttachment: true, token },
          })
        );

        return true;
      }),
      catchError((error) => {
        this.store.dispatch(
          WorkspaceActions.getDocumentError({
            payload: { error },
          })
        );

        if(error.message.code === 0){
          this.router.navigate(['/signed']);
        }

        if (error.code === 1) {
          this.router.navigate(['/signed']);
        }
        if (error && error.code === 2) {
          this.router.navigate(['/expired'])
        }
        const err = error.error.error;
        if (error.status === 403) {
          if (err.code === 8002) {
            this.router.navigate([
              '/to-validate',
              { docName: err.detail.document_name },
            ]);
          } else {
            this.router.navigate(['/signed']);
          }
        } else if (error.status === 401) {
          this.router.navigate(['/e404-token']);
        }
        return of(error);
      })
    );
  }
}
