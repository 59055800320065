import * as templatesFieldsActions from '@literax/store/template-fields/templates-fields.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  ITemplatesFieldsState,
  initialTemplatesFieldsState,
} from '@literax/store/template-fields/templates-fields.state';

const templateFieldReducer = createReducer(
  initialTemplatesFieldsState,
  on(templatesFieldsActions.Get, (state, action) => ({
    ...state,
    queryParameters:
      action?.payload !== null && action?.payload !== undefined
        ? { ...state.queryParameters, ...action.payload }
        : {
            pageNumber: 1,
            pageSize: 10,
            sortField: null,
            filterFields: [],
          },
  })),
  on(templatesFieldsActions.GetSuccess, (state, action) => ({
    ...state,
    ...action.payload,
  })),
  on(templatesFieldsActions.GetFieldSuccess, (state, action) => ({
    ...state,
    ...action.payload,
  }))
);

export function templatesFieldsReducer(
  state: ITemplatesFieldsState,
  action: Action
) {
  return templateFieldReducer(state, action);
}
