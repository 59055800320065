import {
  AuthRedirectComponent,
  LoginFailedComponent,
} from './b2c-auth/components';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { ConfirmEmailGuard } from './guard/confirm-email.guard';
import { E404Component } from './modules/shared/e404/e404.component';
import { E404ValidateTokenComponent } from './modules/shared/e404-validate-token/e404-validate-token.component';
import { ErrorResetPasswordComponent } from './components/auth/error-password/error-password.component';
import { ExpiredLinkComponent } from './modules/shared/expired-link/expired-link.component';
import { LoginClientusersComponent } from './components/auth/login-clientusers/login-clientusers.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './modules/shared/not-found/not-found.component';
import { OnboardingComponent } from './components/auth/onboarding/onboarding.component';
import { RegisterClientusersComponent } from './components/auth/register-clientusers/register-clientusers.component';
import { RegisterComponent } from './b2c-auth/components/register/register.component';
import { ReviewedComponent } from './modules/shared/reviewed/reviewed.component';
import { SignaturesComponent } from './components/signatures/signatures/signatures.component';
import { SignedComponent } from './modules/shared/signed/signed.component';
import { ToValidateComponent } from './modules/shared/to-validate/to-validate.component';
import { ValidateLinkGuard } from './guard/validate-link.guard';
import { ValidateTokenDocumentFreeGuard } from './guard/validate-token-document-free.guard';
import { ExpiredComponent } from './components/sign/expired/expired.component';
import { RejectedComponent } from './modules/shared/rejected/rejected.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'platform/home',
    pathMatch: 'full',
  },
  {
    path: 'auth/b2c-login',
    component: AuthRedirectComponent,
  },
  {
    path: 'auth/login-failed',
    component: LoginFailedComponent,
  },
  {
    path: 'auth/registration',
    component: RegisterComponent,
  },
  {
    path: 'auth/onboarding',
    component: OnboardingComponent,
  },
  {
    path: 'auth/onboarding/active',
    component: OnboardingComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'password/reset/error',
    component: ErrorResetPasswordComponent,
  },
  {
    path: 'auth/login',
    redirectTo: 'platform/home',
  },
  {
    path: 'auth',
    redirectTo: '/auth/registration',
  },
  {
    path: 'platform',
    loadChildren: () =>
      import('@literax/components/platform/platform.module').then(
        (m) => m.PlatformModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'sign/:token',
    loadChildren: () =>
      import('@literax/components/sign/sign.module').then((m) => m.SignModule),
    canActivate: [ValidateTokenDocumentFreeGuard, ValidateLinkGuard],
  },
  {
    path: 'review/:token',
    loadChildren: () =>
      import('@literax/components/sign/sign.module').then((m) => m.SignModule),
    canActivate: [ValidateTokenDocumentFreeGuard],
  },
  {
    path: 'approve/:token',
    loadChildren: () =>
      import('@literax/components/sign/sign.module').then((m) => m.SignModule),
    canActivate: [ValidateTokenDocumentFreeGuard],
  },
  {
    path: 'promissory_note/:token',
    loadChildren: () =>
      import('@literax/components/sign/sign.module').then((m) => m.SignModule),
    canActivate: [ValidateTokenDocumentFreeGuard],
  },
  {
    path: 'observer/:token',
    loadChildren: () =>
      import('@literax/components/sign/sign.module').then((m) => m.SignModule),
    canActivate: [ValidateTokenDocumentFreeGuard],
  },
  {
    path: 'validator',
    loadChildren: () =>
      import('@literax/modules/validator/validator.module').then((m) => m.ValidatorModule)
  },
  {
    path: 'e404',
    component: E404Component,
  },
  {
    path: 'e404-token',
    component: E404ValidateTokenComponent,
  },
  { path: 'expired', component: ExpiredComponent },
  {
    path: 'expired-link',
    component: ExpiredLinkComponent,
  },
  {
    path: 'signed',
    component: SignedComponent,
  },
  {
    path: 'rejected',
    component: RejectedComponent,
  },
  {
    path: 'to-validate',
    component: ToValidateComponent,
  },
  {
    path: 'reviewed',
    component: ReviewedComponent,
    data: { process: 'reviewed' },
  },
  {
    path: 'approved',
    component: ReviewedComponent,
    data: { process: 'approved' },
  },
  {
    path: 'signatures/:id',
    component: SignaturesComponent,
  },
  {
    path: 'client_user/:token',
    component: AppComponent,
    canActivate: [ConfirmEmailGuard],
  },
  {
    path: 'register_client/:token',
    component: RegisterClientusersComponent,
  },
  {
    path: 'login_client/:token',
    component: LoginClientusersComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
