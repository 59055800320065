<div class="dialog">
  <div class="message-container">
    <p class="message">
      {{ message | translate }}
    </p>
  </div>
</div>
<div *nzModalFooter class="footer-custom-buttons">
  <button nz-button nzType="default" (click)="cancelEvent()">
      {{ cancelButton | translate }}
  </button>
  <button nz-button nzType="primary" (click)="deleteEvent()">
      {{ acceptButton | translate }}
  </button>
</div>
