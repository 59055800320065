import * as documentActions from '@literax/modules/documents/store/document/document.actions';

import { Actions, ofType } from '@ngrx/effects';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  IAttachment,
  IAttachmentDateGenerate,
} from '../../../../../models/http/attachment.model';

import { IAppState } from '@literax/store';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Store } from '@ngrx/store';
import { differenceInCalendarDays } from 'date-fns';
import { ToUnixTimestamp } from '@literax/utils/app.utils';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-add-modal-date',
  templateUrl: './add-modal-date.component.html',
  styleUrls: ['./add-modal-date.component.scss'],
})
export class AddModalDateComponent implements OnInit {
  @Input() documentId: number;
  @Input() attachment: IAttachment;

  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  form: FormGroup;
  attachmentDelivery: IAttachmentDateGenerate;

  today = new Date();
  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(this.today, current) > 0;

  delivery_date: Date = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<IAppState>,
    private actions$: Actions,
    private modal: NzModalRef
  ) {
    this.generateForm();
  }

  ngOnInit(): void {
    this.delivery_date.setDate(this.delivery_date.getDate() + 10);
  }

  generateForm() {
    this.form = this.formBuilder.group({
      delivery_date: [this.delivery_date, [Validators.required]],
    });
  }

  cancel() {
    this.modal.triggerCancel();
  }

  saveGenerateDate() {
    if (this.form.valid) {
      const data = this.form.value;
      this.modal.close({
        requestInfo: {
          deliveryDate: ToUnixTimestamp(data.delivery_date),
        },
      });
    }
  }
}
