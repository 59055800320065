import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  IAttachmentDateGenerate,
  IAttachment as LegacyIAttachment,
} from '@literax/models/http/attachment.model';
import {
  IUpdateDocument,
  IUpdateDocumentSelected,
} from '@literax/models/http/document/document.model';

import { AppUtils } from '@literax/utils/app.utils';
import { BaseService } from '../base-config/base.service';
import { IAttachment } from '@literax/models/attachment.model';
import { ICreateDocument } from '@literax/models/http/document/create-document.model';
import { IFilterParams } from '@literax/modules/documents/store/document/document.state';
import { ISort } from '@literax/interfaces/sort-descriptor.interface';
import { IUserActiveDelegateItem } from '@literax/models/user.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from './../../interfaces/service-response';
import { environment } from '@environments/environment';
import { saveAs } from 'file-saver';
import { serialize } from '@literax/utils/json-to-form-data';
import { IDocFilterField, IDocumentAcumulatedStatusses } from '@literax/models/document.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  // perPage = 20;

  constructor(private base: BaseService, private httpClient: HttpClient) {}

  getQueryParamsFromFilterParams(filterParams: IFilterParams): string {
    const queryParams: string[] = [];
    for (const key in filterParams) {
      if (filterParams.hasOwnProperty(key)) {
        const element = filterParams[key];
        if (element instanceof Array) {
          element.forEach((id) =>
            queryParams.push(
              `filter_params[${key}][]=${encodeURIComponent(id)}`
            )
          );
        } else if (key === 'created_at') {
          queryParams.push(
            `daterange_params[${key}]=${encodeURIComponent(element)}`
          );
        } else {
          switch (key) {
            case 'process': {
              queryParams.push(`process=${encodeURIComponent(element)}`);
              break;
            }
            case 'status_key': {
              if (element !== 'shared') {
                queryParams.push(
                  `filter_params[${key}]=${encodeURIComponent(element)}`
                );
              }
              break;
            }
            case 'transaction_type': {
              queryParams.push(
                `filter_params[${key}]=${encodeURIComponent(element)}`
              );
              break;
            }
            default: {
              queryParams.push(
                `search_params[${key}]=${encodeURIComponent(element)}`
              );
              break;
            }
          }
        }
      }
    }
    return queryParams.join('&');
  }

  // ! ELIMINAR ESTE SERVICIO Y CORREGIR DONDE TRUENE
  /**
   * Gets all documents from the specified page
   * @param page The current page
   * @param sort The parameter to sort by, divided with a semicolon with the sorting
   * way (asc or desc). Ex: name:asc. Defaults to id:desc
   * @returns An observable of ServiceResponse
   */
  getDocuments(
    page: number,
    filterParams?: IFilterParams,
    sort: ISort[] = [],
    hidden = false,
    pageSize = 10
  ): Observable<ServiceResponse> {
    const sortStr = AppUtils.parseSortParams(sort || []);
    let path = `/documents?page=${page}&per_page=${pageSize}&hidden=${hidden}${sortStr}`;
    if (filterParams) {
      const queryFilterParams =
        this.getQueryParamsFromFilterParams(filterParams);
      path = `${path}&${queryFilterParams}`;
    }
    return this.base.get(path);
  }

  getBlob(url: string): Observable<Blob> {
    return this.base.getExternalBlob(url);
  }

  getAcumulatedStatusses({
    type,
    flowId,
    filterParams,
    show,
  }: {
    type: string;
    flowId: number;
    filterParams: IDocFilterField[];
    show:number;
  }): Observable<IDocumentAcumulatedStatusses> {
    const filter = AppUtils.parseParams(filterParams || []);

    const path = `/documents/accumulated_statuses?type=${type}&show=${show}${filter}`;
    return this.base.get(path, null, true);
  }

  downloadDocuments(filterParams: IFilterParams) {
    let path = `/documents.xlsx`;
    if (filterParams) {
      const queryFilterParams =
        this.getQueryParamsFromFilterParams(filterParams);
      path = `${path}?${queryFilterParams}`;
    }
    return this.base.getBlob(path);
  }

  saveDownloadFileAs(response: Blob, filename: string) {
    saveAs(response, filename);
  }

  getDocumentDetail(id: number | string): Observable<ServiceResponse> {
    return this.base.get(`/documents/${id}`);
  }

  //v3 ver firmas
  getViewDocumentSignatures(id: number | string): Observable<ServiceResponse> {
    return this.base.get(`/documents/${id}/signatures`, new HttpParams(), true);
  }

  getDocumentUrlForDownload(id: number): Observable<ServiceResponse> {
    return this.base.get(`/documents/${id}?download=true`);
  }

  createDocument(document: ICreateDocument): Observable<ServiceResponse> {
    return this.base.post(
      `/documents`,
      serialize({ document }, { returnAsFormData: true })
    );
  }

  deleteDocument(documentId: number): Observable<ServiceResponse> {
    return this.base.delete(`/documents/${documentId}`);
  }

  markAs(documentId: number, action: string): Observable<ServiceResponse> {
    return this.base.put(`/documents/${documentId}/transaction/${action}`, {});
  }

  setMinApprovers(
    documentId: number,
    minApprovers: number
  ): Observable<ServiceResponse> {
    return this.base.put(`/documents/${documentId}`, {
      document: {
        minimum_approvals: minApprovers,
      },
    });
  }

  markAsReviewed(
    documentId: number,
    action: string,
    sign_request: number
  ): Observable<ServiceResponse> {
    return this.base.put(
      `/documents/${documentId}/sign_requests/${sign_request}/transaction/${action}`,
      {}
    );
  }

  getDocumentAttachment(
    documentId: number,
    attachmentId: number
  ): Observable<any> {
    return this.base.get(
      `/documents/${documentId}/attachments/${attachmentId}`
    );
  }

  replaceAttachment(
    documentId: number,
    attachmentId: number,
    attachment: Partial<LegacyIAttachment>
  ): Observable<any> {
    return this.base.put<IAttachment>(
      `/documents/${documentId}/attachments/${attachmentId}`,
      serialize({ attachment })
    );
  }

  redactAttachment(
    documentId: number,
    attachment: Partial<LegacyIAttachment>
  ): Observable<any> {
    return this.base.post(
      `/documents/${documentId}/attachments/`,
      serialize({ attachment })
    );
  }

  updateDocument(
    documentId: number,
    document: IUpdateDocument
  ): Observable<ServiceResponse> {
    return this.base.patch(`/documents/${documentId}`, { document });
  }

  updateSelectedDocumentHierarchy(
    documentId: number,
    hierarchy: boolean
  ): Observable<ServiceResponse> {
    return this.base.patch(`/documents/${documentId}`, {
      document: { hierarchy },
    });
  }

  updateDocumentSelected(
    documentId: number,
    document: IUpdateDocumentSelected
  ): Observable<ServiceResponse> {
    return this.base.put(`/documents/${documentId}`, {
      document,
    });
  }

  getSearchDocument = (val: string) =>
    this.base.get(`/documents/search?q=${val}`, new HttpParams(), true);

  updateDocumentHidden(documentId: number) {
    return this.base.put(`/documents/${documentId}/hidden`, {});
  }

  markAsApproved(
    documentId: number,
    action: string,
    sign_request: number
  ): Observable<ServiceResponse> {
    return this.base.put(
      `/documents/${documentId}/sign_requests/${sign_request}/transaction/${action}`,
      {}
    );
  }

  documentCancelProcess(
    documentId: number,
    comments: string
  ): Observable<ServiceResponse> {
    return this.base.post(`/documents/${documentId}/cancellation_reasons`, {
      cancellation_reason: {
        description: comments,
      },
    });
  }

  getCommentRejected(documentId: number): Observable<ServiceResponse> {
    return this.base.get(`/documents/${documentId}/cancellation_reasons`);
  }

  saveDateGenerate(
    documentId: number,
    attachmentId: number,
    attachment: IAttachmentDateGenerate
  ) {
    return this.base.put(
      `/documents/${documentId}/attachments/${attachmentId}`,
      { attachment }
    );
  }

  createAssignment = (
    documentId: number,
    receiver: { userId: number }
  ): Observable<ServiceResponse> =>
    this.base.post(`/documents/${documentId}/receivers`, receiver, {}, true);

  getPendingMessages(chat: string, app: string, token: string) {
    return this.httpClient.get<ServiceResponse>(
      `${environment.servicesDomain}/chat/public/v1/apps/${app}/chats/${chat}/messages/pending_seens`,
      { headers: new HttpHeaders({ Authorization: `Bearer ${token}` }) }
    );
  }

  delegateProcess(
    documentId: number,
    userSignerId: number,
    delegate: IUserActiveDelegateItem
  ) {
    const sign_request = {
      user_id: delegate.id,
    };
    return this.base.post(
      `/documents/${documentId}/sign_requests/${userSignerId}/delegate`,
      { sign_request }
    );
  }
}
