import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-digital-signature-form',
  templateUrl: './digital-signature-form.component.html',
  styleUrls: ['./digital-signature-form.component.scss'],
})
export class DigitalSignatureFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() certificateAPIErrors: string;
  @Input() signDataAPIErrors: string;
  @Input() keyAndSignDataAPIErrors: string;
  @Input() label = '';
  @Input() identification: boolean;
  @Input() serverErrors;
  permittedCertificateFiles = ['.cer'];
  permittedKeyFiles = ['.key'];
  serverErrorVisible: boolean;
  permittedFiles = ['.pdf', '.jpg', '.png'];
  serverErrorPassword: boolean;
  constructor() {}

  ngOnChanges(): void {
    this.serverErrorPassword = this.form.get('password').value === '';
    this.serverErrorVisible =
      this.serverErrors?.uuid === 'cert' ||
      this.serverErrors?.uuid === this.form.get('resource_uuid').value;
  }
  changeFile(event: { type: string; value: string }) {
    this.form.get('id_image').setValue(event.value);
  }

  ngOnInit(): void {}

  getErrors(errors: any): string {
    let errorMessage = '';
    if (errors) {
      if (typeof errors === 'string') {
        errorMessage += `${errors}`;
      } else if (Array.isArray(errors)) {
        errorMessage += errors.map((err) => this.getErrors(err)).join(', ');
      } else if (typeof errors === 'object') {
        errorMessage += Object.entries(errors)
          .map(([key, value]) => this.getErrors(value))
          .join(', ');
      }
    }

    return errorMessage;
  }
}
