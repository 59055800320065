<ng-container *ngFor="let reviewer of reviewerRequest">
  <literax-reviewer
    [document]="document"
    [status]="document.status.key"
    [reviewer]="reviewer"
    [user]="user"
    [userDocument]="document.user"
    [actionButtonEnable]="reviewer.email !== user.email"
    (historyButton)="historyButton.emit($event)"
    (actionButton)="actionButton.emit($event)"
  >
  </literax-reviewer>
</ng-container>