import { Injectable } from '@angular/core';
import { ITabSigner } from '@literax/components/platform/promissory-note-configuration/promissory-note-configuration.component';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { IApproverRequest } from '@literax/models/approver.model';
import {
  IAddMeToSigner,
  IAPISignRequest,
  IAPISignRequestMessageConfig,
  SignRequestOrder
} from '@literax/models/http/api/sign-request/sign-request.model';
import { IReviewRequest } from '@literax/models/reviewer.model';
import { IReviewerToSignerList } from '@literax/modules/documents/store/document/document.state';
import { Observable } from 'rxjs';
import { BaseService } from '../base-config/base.service';

@Injectable({
  providedIn: 'root'
})
export class SignRequestService {
  private perPage = 7;
  constructor(private base: BaseService) {}

  getSignRequests(
    documentId: number,
    page: number,
    sort: string = 'id:desc'
  ): Observable<ServiceResponse> {
    return this.base.get(
      `/documents/${documentId}/sign_requests?sort=${sort}&page=${page}&per_page=${this.perPage}`
    );
  }

  createApproveRequest(
    documentId: number,
    approveRequest: IApproverRequest
  ): Observable<ServiceResponse> {
    const dataToSend = { sign_request: approveRequest };
    return this.base.post(`/documents/${documentId}/sign_requests`, dataToSend);
  }

  updateApproveRequest(
    documentId: number,
    approveRequest: IApproverRequest
  ): Observable<ServiceResponse> {
    return this.base.put(
      `/documents/${documentId}/sign_requests/${approveRequest.id}`,
      {
        sign_request: approveRequest
      }
    );
  }

  completeApproveRequest(token: string): Observable<ServiceResponse> {
    return this.base.put(
      `/signatories/sign_requests/transaction/to_approved`,
      token
    );
  }

  deleteApproveRequest(
    documentId: number,
    signRequest: IApproverRequest
  ): Observable<ServiceResponse> {
    return this.base.delete(
      `/documents/${documentId}/sign_requests/${signRequest.id}`,
      {}
    );
  }

  /**
   * servicio para la creacion de un revisor
   * @param documentId {number} id del documento al que se realiza la peticion de revision
   */
  createReviewRequest(
    documentId: number,
    reviewRequest: IReviewRequest
  ): Observable<ServiceResponse> {
    const dataToSend = { sign_request: reviewRequest };
    return this.base.post(`/documents/${documentId}/sign_requests`, dataToSend);
  }

  updateReviewRequest(
    documentId: number,
    reviewRequest: IReviewRequest
  ): Observable<ServiceResponse> {
    return this.base.put(
      `/documents/${documentId}/sign_requests/${reviewRequest.id}`,
      {
        sign_request: reviewRequest
      }
    );
  }

  /**
   * servicio para marcar una revision como aprovada
   * @param documentId {number} id del documento al que se actualizara la peticion de revision
   */
  approveReviewRequest(token: string): Observable<ServiceResponse> {
    return this.base.put(
      `/signatories/sign_requests/transaction/to_reviewd`,
      token
    );
  }

  deleteReviewRequest(
    documentId: number,
    signRequest: IReviewRequest
  ): Observable<ServiceResponse> {
    return this.base.delete(
      `/documents/${documentId}/sign_requests/${signRequest.id}`,
      {}
    );
  }

  createLegalSignRequest(
    documentId: number,
    signRequest: IAPISignRequest[]
  ): Observable<ServiceResponse> {
    const params = `?collection=true`;
    const dataToSend = { sign_requests: signRequest };
    return this.base.post(
      `/documents/${documentId}/sign_requests${params}`,
      dataToSend
    );
  }

  createNaturalPerson(
    documentId: number,
    naturalPerson: IAPISignRequest,
    have_signature_quote?: boolean
  ) {
    let params = '';
    let dataToSend = {};

    if (have_signature_quote) {
      params = '?collection=false';
      dataToSend = { sign_request: naturalPerson };
    } else {
      params = '?collection=true';
      dataToSend = { sign_requests: [naturalPerson] };
    }
    return this.base.post(
      `/documents/${documentId}/sign_requests${params}`,
      dataToSend
    );
  }

  updateNaturalPerson(documentId: number, naturalPerson: IAPISignRequest) {
    const params = '?collection=true';
    const dataToSend = { sign_requests: [naturalPerson] };
    return this.base.put(
      `/documents/${documentId}/sign_requests${params}`,
      dataToSend
    );
  }

  updateLegalRepresentatives(
    documentId: number,
    legalPerson: IAPISignRequest | IAPISignRequest[]
  ): Observable<any> {
    const params = '?collection=true';
    const dataToSend = { sign_requests: legalPerson };
    return this.base.put(
      `/documents/${documentId}/sign_requests${params}`,
      dataToSend
    );
  }

  updateSignRequests(
    documentId: number,
    requests: IAPISignRequestMessageConfig[]
  ): Observable<ServiceResponse> {
    return this.base.put(
      `/documents/${documentId}/sign_requests/collection=true`,
      { sign_requests: requests }
    );
  }

  updateSignRequestsOrder(
    documentId: number,
    requests: SignRequestOrder[]
  ): Observable<ServiceResponse> {
    return this.base.put(
      `/documents/${documentId}/sign_requests?collection=true`,
      { sign_requests: requests }
    );
  }

  sendNotificationEmail(
    documentId: number,
    params: IAPISignRequestMessageConfig[]
  ): Observable<ServiceResponse> {
    return this.base.put(`/documents/${documentId}/sign_requests/send_email`, {
      notifications: params
    });
  }

  deleteSignRequest(
    documentId: number,
    signRequest: IAPISignRequest
  ): Observable<ServiceResponse> {
    return this.base.delete(
      `/documents/${documentId}/sign_requests/${signRequest.id}`,
      {}
    );
  }

  addMeToSigners(payload: IAddMeToSigner): Observable<ServiceResponse> {
    return this.base.post(
      `/documents/${payload.documentId}/sign_requests/add_me`,
      { add_me: payload.addMe }
    );
  }

  getContacts(rfc: string) {
    return this.base.get(`/contacts?search_params[rfc]=${rfc}`);
  }

  getHistoryEmails(documentId: number, signRequest: IAPISignRequest) {
    return this.base.get(
      `/documents/${documentId}/sign_requests/${signRequest.id}`
    );
  }

  sendReviewersToSigners(
    documentId: number,
    reviewers: IReviewerToSignerList[]
  ) {
    const params = '?collection=true';
    const dataToSend = { sign_requests: reviewers };
    return this.base.post(
      `/documents/${documentId}/sign_requests${params}`,
      dataToSend
    );
  }

  getCollaborators(isRecive: boolean) {
    return this.base.get(
      `/client_users?sort=id:desc&filter_params[receive_documents]=${isRecive}`
    );
  }

  changeExpiryAndOfficialIdPN(body: ITabSigner, documentId: number) {
    return this.base.put(`/promissory_notes/configuration/${documentId}`, body);
  }
}
