import * as promissoryNotesActions from '@literax/modules/promissory-notes/store/promissory-notes.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { EAccumulatedStatesKeyPromissoryNote, IPromissoryNoteStatusCounterInfo } from '@literax/models/promissory-notes.model';
import { EOperatorFilterField, ETypeFilterField } from '@literax/enums/query-parameters.enum';
import { IFilterField, IQueryParametersPromissoryNoteCounters } from '@literax/interfaces/query-parameters.interface';
import {
  IPromissoryNotesState,
  initialPromissoryNotesState
} from './promissory-notes.state';

export const initialState = initialPromissoryNotesState;
const promisoryNotesReducers = createReducer(
  initialState,
  on(promissoryNotesActions.GetDataConfig, (state, action) => ({
    ...state,
    configData: null,
    promissoryNotes: null
  })),
  on(promissoryNotesActions.GetDataConfigSuccess, (state, action) => ({
    ...state,
    configData: action.payload
  })),
  on(promissoryNotesActions.GetDataConfigError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.GetDataConfigEndoserment, (state, action) => ({
    ...state,
    configData: null,
    promissoryNotes: null
  })),
  on(
    promissoryNotesActions.GetDataConfigEndosermentSuccess,
    (state, action) => ({ ...state, configData: action.payload })
  ),
  on(promissoryNotesActions.GetDataConfigEndosermentError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.SavePromissoryNotesError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.updatePromissoryNotesError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.GetPromissoryNoteSuccess, (state, action) => ({
    ...state,
    selectPromissoryNote: action.payload
  })),
  on(promissoryNotesActions.GetPromissoryNoteError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.getPromissoryView, (state, action) => ({
    ...state,
    promissoryView: action.payload
  })),
  on(promissoryNotesActions.ResetPage, (state, action) => ({
    ...state,
    promissoryNotes: [],
    pagination: {
      currentPage: 1,
      pageSize: 10,
      total_count: null,
      total_pages: null
    }
  })),
  on(promissoryNotesActions.GetCurrencySuccess, (state, action) => ({
    ...state,
    currencys: action.payload
  })),
  on(promissoryNotesActions.GetCurrencyError, (state, action) => ({
    ...state,
    error: action.payload
  })),
  on(promissoryNotesActions.GetPaymentsSuccess, (state, action) => ({
    ...state,
    payments: action.payload
  })),
  on(promissoryNotesActions.ShowPaymentSuccess, (state, action) => ({
    ...state,
    selectPayment: action.payload
  })),
  on(promissoryNotesActions.ClearSelectPayment, (state, action) => ({
    ...state,
    selectPayment: null
  })),
  on(promissoryNotesActions.GetPromissoryNoteTemplates, (state, action) => {
    const promissoryNoteTemplates = [];
    const pagination = action.pagination
      ? { ...state.pagination, ...action.pagination }
      : state.pagination;
    return {
      ...state,
      promissoryNoteTemplates,
      pagination
    };
  }),
  on(
    promissoryNotesActions.GetPromissoryNoteTemplatesSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteTemplates: action.payload.promissoryNoteTemplates,
      pagination: {
        currentPage: state.pagination.currentPage,
        pageSize: state.pagination.pageSize,
        total_count: action.payload.pagination.total_count,
        total_pages: action.payload.pagination.total_pages
      }
    })
  ),
  on(promissoryNotesActions.ResetPromissoryNoteTemplate, (state, action) => ({
    ...state,
    promissoryNoteTemplates: [],
    pagination: {
      currentPage: 1,
      pageSize: 10,
      total_count: null,
      total_pages: null
    }
  })),
  on(
    promissoryNotesActions.SavePromissoryNoteTemplateSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteTemplateSelect: action.payload
    })
  ),
  on(
    promissoryNotesActions.GetPromissoryNoteTemplateShowSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteTemplateSelect: action.payload
    })
  ),
  on(
    promissoryNotesActions.GetPromissoryNoteTemplateShowReload,
    (state, action) => ({
      ...state,
      promissoryNoteTemplateSelect: null
    })
  ),
  on(
    promissoryNotesActions.CreatePromissoryNoteWithTemplateSuccess,
    (state, action) => ({
      ...state,
      promissoryNoteWithData: action.payload
    })
  ),
  on(promissoryNotesActions.SetTemplateId, (state, action) => ({
    ...state,
    templateId: action.payload
  })),
  on(promissoryNotesActions.ClearTemplateId, (state, action) => ({
    ...state,
    templateId: 0
  })),
  on(promissoryNotesActions.ClearConfigData, (state, action) => ({
    ...state,
    configData: null,
    promissoryNotes: null
  })),
  
  //* Pagaré V3
  on(promissoryNotesActions.GetPromissoryNoteCounters, (state, action) => {
    let fFields: IFilterField[];
    if (action.counterQueryParameters.filterFields) {
      if (action.counterQueryParameters.filterFields.some((value) => value.name === 'created_at')) {
        const datesFilter = action.counterQueryParameters.filterFields
          .filter((el) => el.name === 'created_at');
        fFields = [...state.counterQueryParameters?.filterFields, ...datesFilter];
      }
      if (action.counterQueryParameters.filterFields.some((value) => value.name === 'name')) {
        const nameFilter = action.counterQueryParameters.filterFields
          .filter((el) => el.name === 'name');
        fFields = [...state.counterQueryParameters?.filterFields, ...nameFilter];
      }
    }
    const newPromissoryNoteAccumulatedStatusKey = 
      action?.counterQueryParameters.type !== state.counterQueryParameters.type ?
      EAccumulatedStatesKeyPromissoryNote.IN_EFFECT :
      state.promissoryNoteAccumulatedStatusKey;
    
    const newcounterQueryParameters: IQueryParametersPromissoryNoteCounters = {
      type: action?.counterQueryParameters.type ? action?.counterQueryParameters.type : state.counterQueryParameters.type,
      filterFields: action?.counterQueryParameters?.filterFields ? fFields : state.counterQueryParameters.filterFields
    }
    return ({
      ...state,
      promissoryNoteAccumulatedStatusKey: newPromissoryNoteAccumulatedStatusKey,
      counterQueryParameters: newcounterQueryParameters,
      promissoryNoteType: action?.counterQueryParameters !== null && action?.counterQueryParameters !== undefined
        ? action.counterQueryParameters.type
        : state.counterQueryParameters.type
    })
  }),
  on(promissoryNotesActions.GetPromissoryNoteCountersSuccess, (state, action) => {
    let status = Object.entries(action.payload)
      .filter(([, value]) => value !== null)
      .reduce((obj, [, value]) => value, {});
    status = Object.values(status);
    const promissoryNoteAccumulatedStatusId = status.find(
      (status: IPromissoryNoteStatusCounterInfo) =>
        status.key === state.promissoryNoteAccumulatedStatusKey
    ).id;
    const fFields = state.counterQueryParameters.filterFields ?
      [
        ...[{
          name: 'status_id',
          type: ETypeFilterField.NUMERIC,
          term: String(promissoryNoteAccumulatedStatusId),
          operator: EOperatorFilterField.EQ
        }],
        ...state.counterQueryParameters.filterFields
      ] :
      [{
        name: 'status_id',
        type: ETypeFilterField.NUMERIC,
        term: String(promissoryNoteAccumulatedStatusId),
        operator: EOperatorFilterField.EQ
      }];
    return ({
      ...state,
      promissoryNoteStatusCounters: status,
      promissoryNoteStatus: promissoryNoteAccumulatedStatusId,
      queryParameters: {
        type: state.counterQueryParameters.type,
        filterFields: fFields
      }
    })
  }),
  on(promissoryNotesActions.SetPromissoryNoteStatus, (state, action) => {
    const newPromissoryNoteAccumulatedStatusKey = state.promissoryNoteStatusCounters.find(
      (status: IPromissoryNoteStatusCounterInfo) =>
        status.id === action.statusId
    ).key;
    const fFields = state.counterQueryParameters.filterFields ?
      [
        ...[{
          name: 'status_id',
          type: ETypeFilterField.NUMERIC,
          term: String(action.statusId),
          operator: EOperatorFilterField.EQ
        }],
        ...state.counterQueryParameters.filterFields
      ] :
      [{
        name: 'status_id',
        type: ETypeFilterField.NUMERIC,
        term: String(action.statusId),
        operator: EOperatorFilterField.EQ
      }];
    return ({
      ...state,
      promissoryNoteStatus: action.statusId,
      promissoryNoteAccumulatedStatusKey: newPromissoryNoteAccumulatedStatusKey,
      queryParameters: {
        type: state.counterQueryParameters.type,
        filterFields: fFields
      }
    })
  }),
  on(promissoryNotesActions.GetPromissoryNotes, (state, action) => {
    return ({
      ...state,
      queryParameters:
        action?.queryParams !== null && action?.queryParams !== undefined
          ? { ...state.queryParameters, ...action.queryParams }
          : state.queryParameters,
    })
  }),
  on(promissoryNotesActions.GetPromissoryNotesSuccess, (state, action) =>({
    ...state,
    promissoryNoteList: action.payload
  })),
  on(promissoryNotesActions.ClearPromissoryNoteFilters, (state, action) => {
    const updatedFilters = action.filterName ? 
      state.counterQueryParameters.filterFields
      .filter((el) => el.name !== action.filterName) :
      [];
    return ({
      ...state,
      counterQueryParameters: {
        type: state.counterQueryParameters.type,
        filterFields: updatedFilters
      }
    })
  })
);
export function promisoryNotesReducer(
  state: IPromissoryNotesState,
  action: Action
) {
  return promisoryNotesReducers(state, action);
}
