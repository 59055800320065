import { CatalogType, ICatalog, ICatalogStatuses, IFlowCatalog, ISignRequestValidationType } from '@literax/models/catalog.model';
import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IReminder } from '@literax/models/reminder.model';

export const getCatalog = createAction(
  '[Catalog] GetCatalog',
  props<{ payload: CatalogType }>()
);

export const getCatalogSuccess = createAction(
  '[Catalog] GetCatalogSuccess',
  props<{ payload: ICatalog }>()
);

export const getCatalogError = createAction(
  '[Catalog] GetCatalogError',
  props<{ payload: IAPIResponseError }>()
);

export const getFlowCatalog = createAction(
  '[Catalog] GetFlowCatalog',
  props<{ payload?: { model?: string } }>()
);


export const getFlowCatalogSuccess = createAction(
  '[Catalog] GetFlowCatalogSuccess',
  props<{ payload: IFlowCatalog[] }>()
);

export const getFlowCatalogError = createAction(
  '[Catalog] GetFlowCatalogError',
  props<{ payload: IAPIResponseError }>()
);

export const getCatalogStatuses = createAction(
  '[Catalog] getCatalogStatuses',
);

export const getCatalogStatusesSuccess = createAction(
  '[Catalog] getCatalogStatusesSuccess',
  props<{ payload: ICatalogStatuses[] }>()
);

export const getCatalogStatusesError = createAction(
  '[Catalog] GetFlowCatalogError',
  props<{ payload: IAPIResponseError }>()
);

export const reminders = createAction(
  '[Catalog] reminders',
);

export const remindersSuccess = createAction(
  '[Catalog] remindersSuccess',
  props<{ payload: IReminder[] }>()
);

export const remindersError = createAction(
  '[Catalog] remindersError',
  props<{ payload: IAPIResponseError }>()
);

export const getSignRequestValidationTypeCatalog = createAction(
  '[Catalog] getSignRequestValidationType',
);

export const getSignRequestValidationTypeCatalogSuccess = createAction(
  '[Catalog] getSignRequestValidationTypeSuccess',
  props<{ payload: ISignRequestValidationType[] }>()
);

export const getSignRequestValidationTypeCatalogError = createAction(
  '[Catalog] getSignRequestValidationTypeError',
  props<{ payload: IAPIResponseError }>()
);