<div class="workspace-top-info">
  <div class="breadcrumb-section">
    <ng-content select="[slot='breadcrumb']"></ng-content>
  </div>
  <div class="title">
    <ng-content select="[slot='title']"></ng-content>
  </div>
</div>
<div class="workspace">
  <div class="tab-header">
    <ng-content select="[slot='tabs']"></ng-content>
  </div>
  <div class="content">
    <div class="viewer">
      <ng-content select="[slot='document']"></ng-content>
    </div>
    <div class="sidebar">
      <ng-content select="[slot='sidebar']"></ng-content>
    </div>
  </div>
  <div class="footer">
    <ng-content select="[slot='footer']"></ng-content>
  </div>
</div>
