export class PermissionsConstants {
  public static PROFILE_TEMPLATE = 'profile_template';
  public static SHOW_USER_PROFILE = 'show_user_profile';
  public static CREATE_USER_PROFILE = 'create_user_profile';
  public static UPDATE_USER_PROFILE = 'update_user_profile';
  public static DELETE_USER_PROFILE = 'delete_user_profile';
  public static SHOW_CLIENT_PROFILE = 'show_client_profile';
  public static CREATE_CLIENT_PROFILE = 'create_client_profile';
  public static UPDATE_CLIENT_PROFILE = 'update_client_profile';
  public static DELETE_CLIENT_PROFILE = 'delete_client_profile';
  public static CREATE_CLIENT_USER = 'create_client_user';
  public static DELETE_CLIENT_USER = 'delete_client_user';
  public static UPDATE_CLIENT_USER = 'update_client_user';
  public static SHOW_CLIENT_USER = 'show_client_user';
  public static SHOW_REPORT = 'show_report';
  public static CREATE_DOCUMENT = 'create_document';
  public static UPDATE_DOCUMENT = 'update_document';
  public static DELETE_DOCUMENT = 'delete_document';
  public static DOWNLOAD_DOCUMENT = 'download_document';
  public static SHARE_DOCUMENT = 'share_document';
  public static SHOW_DOCUMENT = 'show_document';
  public static DOCUMENT_BULK_LOAD = 'document_bulk_load';
  public static SIGNATURE_TEMPLATE = 'signature_template';
  public static ELASTIC_SEARCH = 'elastic_search';
  public static CREATE_TASK = 'create_task';
  public static CREATE_SIGNATURE = 'create_signature';
  public static CREATE_REVIEW_PROCESS = 'create_review_process';
  public static UPDATE_REVIEW_PROCESS = 'update_review_process';
  public static SHOW_REVIEW_PROCESS = 'show_review_process';
  public static DELETE_REVIEW_PROCESS = 'delete_review_process';
  public static CREATE_PROMISSORY_NOTE = 'create_promissory_note';
  public static UPDATE_PROMISSORY_NOTE = 'update_promissory_note';
  public static DELETE_PROMISSORY_NOTE = 'delete_promissory_note';
  public static SHOW_PROMISSORY_NOTE = 'show_promissory_note';
  public static CREATE_FIELD = 'create_field';
  public static UPDATE_FIELD = 'update_field';
  public static DELETE_FIELD = 'delete_field';
  public static SHOW_FIELD = 'show_field';
  public static CREATE_TEMPLATE = 'create_template';
  public static DELETE_TEMPLATE = 'delete_template';
  public static UPDATE_TEMPLATE = 'update_template';
  public static SHOW_TEMPLATE = 'show_template';
  public static STORAGE = 'storage';
  public static FREE_SEARCH = 'free_search';
  public static CREATE_DEPARTMENT = 'create_department';
  public static DELETE_DEPARTMENT = 'delete_department';
  public static UPDATE_DEPARTMENT = 'update_department';
  public static SHOW_DEPARTMENT = 'show_department';
  public static SHOW_DIGITAL_LEGAL_COLLABORATION = 'show_digital_legal_collaboration';
  public static CREATE_COMPANY = 'create_company';
  public static UPDATE_COMPANY = 'update_company';
  public static DELETE_COMPANY = 'delete_company';
  public static SHOW_COMPANY = 'show_company';
  public static COLLABORATION_FLOW = 'collaboration_flow';
  public static SHOW_CONFIG_API = 'show_config_api';
  public static WEBSERVICE_CREATE_DOCUMENT = 'webservice_create_document';
  public static WEBSERVICE_DELETE_DOCUMENT = 'webservice_delete_document';
  public static WEBSERVICE_DOWNLOAD_DOCUMENT = 'webservice_download_document';
  public static WEBSERVICE_SHOW_DOCUMENT = 'webservice_show_document';
  public static WEBSERVICE_UPDATE_DOCUMENT = 'webservice_update_document';
  public static WEBSERVICE_DOCUMENT_BULK_LOAD = 'webservice_document_bulk_load';
  public static WEBSERVICE_CREATE_SIGNATURE = 'webservice_create_signature';
  public static BIOMETRICS_FLOW = 'biometrics_flow';
}
