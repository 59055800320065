import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { ActionCreator } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private modalSerivce: NzModalService,
    private translateService: TranslateService
  ) {}

  public handle(error, action: ActionCreator): Observable<ActionCreator> {
    if (error.httpStatus === 422) {
      const details = error.fields
        ? Object.entries(error?.fields).map(([key, value]) => ({
            [key.split('.')[1] || key]: value[0],
          }))
        : [];

      const htmlDetails = Object.values(details).map(
        (error) =>
          `<li>${Object.entries(error).reduce(
            (acc, [key, err]) => err,
            ''
          )}</li>`
      );

      const getDetails = () => {
        return `<ul>${htmlDetails.join('')}</ul>`;
      };

      const nzContent = `${error.message}</br />${getDetails()}`;
      this.modalSerivce.error({
        nzTitle: 'Error!',
        nzContent,
        nzCentered: true,
      });
    } else {
      this.modalSerivce.error({
        nzTitle: 'Error!',
        nzContent: environment.logStackTrace
        ? error.message
        : this.translateService.instant('DEFAULT_ERROR_MESSAGE'),
        nzCentered: true,
      });
    }

    return of(action);
  }
}
