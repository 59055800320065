<div
  class="wrapper"
  [ngStyle]="{ 'border-left': active ? '1px #1890ff solid' : 'white' }"
>
  <i class="fal fa-eye icon"></i>
  <div *ngIf="attachment?.kind === 'pdf'" class="content">
    <div class="name">{{ attachment?.name }}</div>
    <div class="attachment" *ngIf="attachment?.primary">
      {{ 'SIGNATURES.LIST.MAIN_DOCUMENT' | translate }}
    </div>
    <div class="attachment" *ngIf="!attachment?.primary">
      {{ 'SIGNATURES.LIST.ATTACHMENT' | translate }}
    </div>
  </div>

  <div *ngIf="attachment?.kind !== 'pdf'" class="content">
    <div class="name">{{ attachment?.name }}</div>
  </div>
</div>
