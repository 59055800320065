<div class="wrapper">
    <literax-auth-navbar [title]="'AUTH.TITLE.NAVBAR' | translate"></literax-auth-navbar>
    <div nz-row>
        <div nz-col nzSpan="12" nzOffset="6" class="main-container">
            <nz-card
                [nzTitle]="cardTitle"
            >
                <nz-result
                    nzStatus="403" 
                    nzTitle="{{ 'EXPIRED_LINK.SECOND_TITLE' | translate }}" 
                    nzSubTitle="{{ 'EXPIRED_LINK.SUBTITLE' | translate }}"
                >
                    <div nz-result-extra>
                        <button nz-button nzType="primary" (click)="requestNewLink()">{{ 'EXPIRED_LINK.BUTTON_NEW_LINK' | translate }}</button>
                    </div>
                </nz-result>
            </nz-card>
            <ng-template #cardTitle>
                <h4 style="text-align: center;">{{ 'EXPIRED_LINK.TITLE' | translate }}</h4>
            </ng-template>
        </div>
    </div>
</div>