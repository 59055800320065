import {
  DefaultProjectorFn,
  MemoizedSelector,
  createSelector,
} from '@ngrx/store';

import { ETransactionType } from '@literax/enums/document.enum';
import { WorkspaceState } from '../workspace.state';

export const DocumentSelectors = (
  storeFeature: MemoizedSelector<
    // eslint-disable-next-line @typescript-eslint/ban-types
    object,
    WorkspaceState,
    DefaultProjectorFn<WorkspaceState>
  >
) => ({
  workingDocument: createSelector(
    storeFeature,
    (state: WorkspaceState) => state?.document
  ),
  status: createSelector(
    storeFeature,
    (state: WorkspaceState) => state?.document?.status?.key
  ),
  transaction: createSelector(
    storeFeature,
    (state: WorkspaceState) =>
      ETransactionType[state?.document?.transactionType]
  ),
  signatureVerification: createSelector(
    storeFeature,
    (state: WorkspaceState) =>
      state?.document?.signingConfiguration?.signatureVerificationTypeId
  ),
  errorDocument: createSelector(
    storeFeature,
    (state: WorkspaceState) => state?.errorMessage
  )
});
