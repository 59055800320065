import { IAPIError } from '@literax/models/http/api/error.model';
import { IAppState } from '@literax/store/index';
import { ISignatureState } from '@literax/modules/documents/store/signature/signature.state';
import { createSelector } from '@ngrx/store';

const selectSignatures = (state: IAppState) => state.signatures;

export const selectSignatureResult = createSelector(
  selectSignatures,
  (state: ISignatureState) => state.signatureResult
);

export const selectShowSignatureResult = createSelector(
  selectSignatures,
  (state: ISignatureState) => state.showSignatureResult
);

export const selectSignatureErrors = createSelector(
  selectSignatures,
  (state: ISignatureState) => state.errors
);

export const selectSignatureErrorsForm = createSelector(
  selectSignatures,
  (state: ISignatureState) => {
    if (state.errors !== null && state.errors.detail !== null) {
      const uuidKey = Object.keys(state?.errors?.detail)[0];
      return { uuid: uuidKey, error: state.errors?.detail[uuidKey] };
    }
  }
);

export const selectSignatureErrorDetails = createSelector(
  selectSignatureErrors,
  (state: IAPIError) => state && state.detail
);

export const selectCertificateErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) => state && state.cert
);
export const selectErrorKeyReducer = createSelector(
  selectSignatureErrorDetails,
  (state: any) =>
    state &&
    Object.keys(state).reduce(
      (currentObject, key) => ({ ...currentObject, ...state[key] }),
      {}
    )
);
export const selectTokenErrors = createSelector(
  selectErrorKeyReducer,
  (state: any) => state?.expiry
);

export const selectImageError = createSelector(
  selectErrorKeyReducer,
  (state: any) => state && state?.id_image
);

export const selectNameError = createSelector(
  selectErrorKeyReducer,
  (state: any) => state && state?.name
);

export const selectEmailError = createSelector(
  selectErrorKeyReducer,
  (state: any) => state && state?.email
);
export const selectSignDataErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) => state && state.sign_data
);

export const selectKeyErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) => state && (state.key || state['signatures.expiry'])
);

export const signatureQuotes = createSelector(
  selectSignatures,
  (state: ISignatureState) => {
    const signatureQuotes = [];
    state.signatureQuotes.forEach((signatureQuote) => {
      signatureQuotes.push({
        value: signatureQuote.id,
        label: signatureQuote.name,
        form_builder_uid: signatureQuote.form_builder_uid
      });
    });
    return signatureQuotes;
  }
);
