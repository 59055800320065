import { Component, OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-expired',
  template: `<div class="container-fluid">
    <div class="wrapper">
      <literax-auth-navbar
        [standalone]="true"
        [title]="'AUTH.TITLE.NAVBAR' | translate"
      ></literax-auth-navbar>
      <div nz-row>
        <div nz-col nzSpan="12" nzOffset="6" class="main-container">
          <nz-card [nzTitle]="cardTitle">
            <nz-result
              nzStatus="403"
              nzTitle="{{ 'EXPIRED.TITLE' | translate }}"
              nzSubTitle="{{ 'EXPIRED.SUBTITLE' | translate }}"
            >
            </nz-result>
          </nz-card>
          <ng-template #cardTitle>
            <h4 style="text-align: center;">
              {{ 'EXPIRED.TOP_TITLE' | translate }}
            </h4>
          </ng-template>
        </div>
      </div>
    </div>
  </div> `,
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnDestroy {
  link = environment.domain + '/auth/registration';

  constructor() {}

  ngOnDestroy(): void {}
}
