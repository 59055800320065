import * as termsActions from '@literax/store/terms/terms.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ITermsResponse } from '@literax/models/terms.model';
import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { TermsService } from '@literax/services/terms/terms.service';
import { of } from 'rxjs';

@Injectable()
export class TermsEffects {
  getTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(termsActions.GetTerms),
      switchMap((action) =>
        this.termsService.getTerms().pipe(
          map((response: ServiceResponse) =>
            termsActions.GetTermsSuccess({
              payload: response.terms_of_service as ITermsResponse
            })
          ),
          catchError((error) =>
            of(termsActions.GetTermsError({ payload: error }))
          )
        )
      )
    )
  );

  acceptTerms = createEffect(() =>
  this.actions$.pipe(
    ofType(termsActions.AcceptTerms),
    switchMap((action) =>
      this.termsService.acceptTerms().pipe(
        map((response: ServiceResponse) =>
          termsActions.AcceptTermsSuccess()
        ),
        catchError((error) =>
          of(termsActions.AcceptTermsError({ payload: error }))
        )
      )
    )
  )
);

  constructor(
    private termsService: TermsService,
    private actions$: Actions,
  ) {}
}
