/**
 * ! DEPERCATED
 * *componente sin uso
 */

// import * as documentActions from '@literax/modules/documents/store/document/document.actions';
// import * as signatureActions from '@literax/modules/documents/store/signature/signature.actions';
// import * as usersActions from '@literax/components/configurations/users/states/users.actions';

// import { Actions, ofType } from '@ngrx/effects';
// import { ActivatedRoute, Router } from '@angular/router';
// import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  Component
} from '@angular/core';
// import {
//   ClearStack,
//   SetNavNodes
// } from '@literax/store/title-navbar/title-navbar.actions';
// import {
//   DelegateProcessTo,
//   EClientActions
// } from '@literax/modules/configurations/store/client/client.actions';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { EDocTransactionType, EDocumentRequest, EDocumentStatus, EWorkspaceDocumentTabs } from '@literax/enums/document.enum';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import {
//   GetHistoryEmail,
//   InitializeSignRequestFormStates,
//   SendSignRequestNotification
// } from '@literax/modules/documents/store/sign-request/sign-request.actions';
// import {
//   GetUsersReceiversActive,
//   selectUserPermissions
// } from '@literax/components/configurations/users/states/users.selector';
// import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
// import { Store, select } from '@ngrx/store';
// import {
//   attachments,
//   selectAllDocumentObserverRequests,
//   selectAllSignersRequests,
//   selectAttachment,
//   selectCurrentAttachment,
//   selectDocument,
//   selectGetDocumentAttachmentError,
//   selectLastAttachmentUploaded,
//   selectPendingMessages,
//   typePromissoryAttachment
// } from '@literax/modules/documents/store/document/document.selectors';
// import {
//   checkAttachment,
//   checkAttachmentSuccess
// } from '@literax/modules/documents/store/attachment/attachment.actions';
// import {
//   delay,
//   distinctUntilKeyChanged,
//   filter,
//   finalize,
//   first,
//   map,
//   take
// } from 'rxjs/operators';

// import { AcceptModalComponent } from '@literax/modules/shared/accept-modal/accept-modal.component';
// import { AddModalDateComponent } from './generate/add-modal-date/add-modal-date.component';
// import { AssignmentDialogComponent } from '@literax/modules/shared/assignment-dialog/assignment-dialog.component';
// import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
// import { BreakpointObserver } from '@angular/cdk/layout';
// import { ButtonAction } from '@literax/utils/buttons-bar-configs';
// import { CancelProcessDocumentComponent } from '@literax/components/platform/cancel-process-document/cancel-process-document.component';
// import { ConfirmDialogApprovalComponent } from '@literax/modules/shared/confirm-dialog-approval/confirm-dialog-approval.component';
// import { ConfirmDialogComponent } from '@literax/modules/shared/confirm-dialog/confirm-dialog.component';
// import { ConfirmDialogDelegateComponent } from '@literax/modules/shared/confirm-dialog-delegate/confirm-dialog-delegate.component';
// import { DelegateProcessDialogComponent } from '@literax/modules/shared/delegate-process-dialog/delegate-process-dialog.component';
// import { DeleteRecordDialogComponent } from '@literax/components/platform/delete-record-dialog/delete-record-dialog.component';
// import { DocumentSignature } from '@literax/models/document-signature';
// import { EAttachmentKind } from '@literax/enums/attachment-kind.enum';
// import { EOfficialIdentification } from '@literax/enums/official-identification.enum';
// import { ETemplateKind } from '@literax/enums/template.enum';
// import { HistoryComponent } from './history/history.component';
// import { IActions } from '@literax/components/configurations/users/models/users.interface';
// import { IAppState } from '@literax/store';
// import { IAttachment } from '@literax/models/http/attachment.model';
// import { IDocument } from '@literax/models/http/document/document.model';
// import { IProfile } from '@literax/components/configurations/profiles/models/profiles';
// import { ISignRequest } from '@literax/modules/documents/store/document/document.state';
// import { ISignatureResponse } from '@literax/models/http/api/signature.model';
// import { ISignerCoordinatesUpdater } from '@literax/models/http/api/client/pdf.model';
// import { IUpdateDocument } from '../../../models/http/document/document.model';
// import { IUser } from '@literax/models/user.model';
// import { IdentityValidationModalComponent } from '@literax/modules/shared/identity-validation-modal/identity-validation-modal.component';
// import { MatIconRegistry } from '@angular/material/icon';
// import { SignModalComponent } from '../sign-modal/sign-modal.component';
// import { TranslateService } from '@ngx-translate/core';
// import { environment } from '@environments/environment';
// import { removeDuplicates } from '@literax/utils/app.utils';
// import { selectCurrentLang } from '@literax/modules/configurations/store/config/config.selectors';
// import { selectCurrentUser } from '@literax/modules/auth/store/auth.selectors';
// import { selectLastEventEmail } from '@literax/modules/documents/store/sign-request/sign-request.selectors';
// import { selectSignatureResult } from '@literax/modules/documents/store/signature/signature.selector';
// import { signaturePositionActions } from '@literax/modules/documents/store/signature-position/singature-position.actions';
// import { statusTemplate } from '@literax/models/templates';
// 

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent {
//   @ViewChild('xml') xmlCodeElement: ElementRef<HTMLElement>;

//   readonly icons = ['info', 'signers', 'comment', 'message', 'pencil'];
//   document$ = this.store.pipe(untilDestroyed(this), select(selectDocument));
//   currentAttachment$ = this.store.pipe(
//     untilDestroyed(this),
//     select(selectCurrentAttachment)
//   );
//   selectAttachmen$ = this.store.pipe(
//     untilDestroyed(this),
//     select(selectAttachment)
//   );
//   typePromissoryAttachmen$ = this.store.pipe(
//     untilDestroyed(this),
//     select(typePromissoryAttachment)
//   );
//   errorAttachment$ = this.store.pipe(
//     untilDestroyed(this),
//     select(selectGetDocumentAttachmentError)
//   );
//   attachmens$ = this.store.pipe(untilDestroyed(this), select(attachments));
//   user$ = this.store.pipe(untilDestroyed(this), select(selectCurrentUser));
//   signRequests$ = this.store.pipe(
//     untilDestroyed(this),
//     select(selectAllSignersRequests)
//   );

//   lastUploadedAttachment$ = this.store.pipe(
//     untilDestroyed(this),
//     select(selectLastAttachmentUploaded)
//   );

//   observers$ = this.store.pipe(
//     untilDestroyed(this),
//     select(selectAllDocumentObserverRequests)
//   );

//   form: FormGroup;
//   hidden = false;
//   visible = [true, false];
//   visibleInfo: boolean[] = [];
//   signatures: { [key: string]: ISignatureResponse } = {};
//   contentVisible = true;

//   documentId: number;
//   documentBase64: string;
//   attachmentNames: string;
//   documentData: SafeResourceUrl;
//   selectedDocument: IDocument;
//   selectedAttachment: number;
//   isCommonDocument: boolean;
//   userInfo: IProfile;
//   hasObservers$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
//   isReview$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
//   isApprove$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
//   isSignature$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
//   isApplicant$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
//   isCheckReview$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
//     false
//   );
//   isCheckApprove$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
//     false
//   );

//   visualRepresentation: SafeResourceUrl;
//   showVisualRepresentation = false;
//   isXMLTemplate = false;
//   options = ['Attachments', 'Sign', 'Comment', 'Info', 'Edit'];
//   images = {
//     Info: 'infoSvg',
//     Sign: 'signersSvg',
//     Comment: 'commentSvg',
//     Attachments: 'messageSvg',
//     Edit: 'pencilSvg'
//   };

//   recordId: string;
//   templateId: string;
//   b2cToken: string;

//   lang$ = this.store.pipe(untilDestroyed(this), select(selectCurrentLang));
//   isTemplate: boolean;
//   isTicket: boolean;
//   isCollectData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
//     false
//   );
//   validFormTemplate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
//     false
//   );
//   visibleEdit = 0;
//   userSignerHaveCollection: boolean;
//   @ViewChild('chatContainer', { static: false }) chatContainer: ElementRef;
//   chatComponent: HTMLElement;
//   canInitializeChat = false;
//   isGeneratedRequest: string;
//   recordFilled: any = false;
//   onlyofficeVisible: boolean;

//   //#region Permisos de usuario
//   showDocument: boolean;
//   //#endregion

//   //#region  Revision
//   _attachment: IAttachment;
//   _document: IDocument;
//   _user: IProfile;
//   isDocxAttachment$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
//     false
//   );
//   _reload = false;
//   _currentAction: string;
//   typeDocVisible$: BehaviorSubject<string> = new BehaviorSubject<string>('');
//   viewerAction$: BehaviorSubject<string> = new BehaviorSubject<string>('view');
//   forceEnabledSigners$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
//     false
//   );
//   //#endregion

//   isPormisorryNote = false;

//   signatureQuotesValid: Record<string, unknown> = {};

//   notificationsValue$: BehaviorSubject<string> = new BehaviorSubject<string>(
//     ''
//   );

//   signatureQuotes$: BehaviorSubject<any[]> = new BehaviorSubject([]);
//   userWithSignatureQuote$: BehaviorSubject<boolean> =
//     new BehaviorSubject<boolean>(false);

//   showTemplateMetadata = false;
//   showSignatureQuote = false;

//   isFaceMatch = false;
//   documentRequest: EDocumentRequest;
//   isLegalRepresentativeReject = false;

//   @ViewChild('signModalFooter') signModalFooter: TemplateRef<any>;
//   signModalRef: NzModalRef;
//   signProcessInitiated = false;
//   signProcessNotFullfilled$: Observable<boolean>;
//   modalSteps: number;
//   currentModalStep: number;
//   //#region
//   tabs = [];
//   currentTab = EWorkspaceDocumentTabs.ATTACHMENTS;
//   currentTabIndex: number;
//   tabTypes = EWorkspaceDocumentTabs;
//   //#endregion

//   isActivo = false;
//   descriptionMessageModal = '';
//   titleMessagemodal = '';
//   typePromissoryNote: boolean;
//   user: IUser;
//   totalSigner = 0;

//   isMobileDevice: boolean;
//   isTabletDevice: boolean;
//   isDesktopDevice: boolean;

//   constructor(
//     private fb: FormBuilder,
//     private route: ActivatedRoute,
//     private store: Store<IAppState>,
//     private router: Router,
//     private modalService: NzModalService,
//     iconRegistry: MatIconRegistry,
//     private translate: TranslateService,
//     private detector: ChangeDetectorRef,
//     private actions$: Actions,
//     private b2cAuthService: B2CAuthService,
//     private sanitizer: DomSanitizer,
//     public breakpointObserver: BreakpointObserver
//   ) {
//     this.isMobileDevice =
//       this.breakpointObserver.isMatched('(max-width: 767px)');
//     this.isTabletDevice =
//       this.breakpointObserver.isMatched('(min-width: 768px)') &&
//       this.breakpointObserver.isMatched('(max-width: 1023px)');
//     this.isDesktopDevice = this.breakpointObserver.isMatched(
//       '(min-width: 1024px)'
//     );

//     this.user$.subscribe((user: IProfile) => (this.userInfo = user));

//     //#region documentos de tipo docx
//     this.route.data.subscribe(({ documetn }) => {
//       this.isReview$.next(
//         documetn.transaction_type === EDocTransactionType.REVIEW
//       );
//       this.isApprove$.next(
//         documetn.transaction_type === EDocTransactionType.APPROVAL
//       );
//       this.isApplicant$.next(
//         documetn.transaction_type === EDocTransactionType.REQUEST
//       );
//       this._attachment = documetn?.attachments.find((a) => a.primary === true);
//       this._document = documetn;
//       this._user = this.userInfo;
//       this.userSignerHaveCollection =
//         documetn.status.key === EDocumentStatus.DATA_COLLECTION;
//       this.isTemplate = this._attachment.form_builder_record_uid !== null;
//       this.isTicket = this._attachment.kind === EAttachmentKind.TICKET;
//       // si el attachment del documento es de tipo docx cargamos el visor only office
//       this.changeSwitchViewer(this._attachment);
//       this.getProcess();
//     });
//     //#endregion

//     this.store
//       .pipe(untilDestroyed(this), select(selectUserPermissions))
//       .subscribe((permissions: IActions) => {
//         this.showDocument = permissions?.show_document;
//       });
//     this.searchAttachment(0);
//     this.icons.forEach((icon) => {
//       iconRegistry.addSvgIcon(
//         `${icon}Svg`,
//         sanitizer.bypassSecurityTrustResourceUrl(
//           `../../assets/icon/${icon}.svg`
//         )
//       );
//     });

//     this.form = this.fb.group({
//       email: ['', Validators.email]
//     });

//     this.translate.onLangChange.subscribe(() => {
//       this.getProcess();
//       this.store.dispatch(documentActions.UpdateSelectedDocument());
//     });

//     this.lastUploadedAttachment$
//       .pipe(
//         untilDestroyed(this),
//         filter((attachment) => !!attachment),
//         delay(500)
//       )
//       .subscribe((attachment) => {
//         this.selectAttachment(attachment?.id);
//       });

//     this.signRequests$.subscribe((signers) => {
//       this.totalSigner = signers?.length;
//       signers?.some((signer) => {
//         this.isLegalRepresentativeReject =
//           signer.status?.key === EDocumentStatus.REJECTED;
//       });
//     });

//     this.document$
//       .pipe(filter((document) => document !== null))
//       .subscribe((document: IDocument) => {
//         this.selectedDocument = document;
//         this.hasObservers$.next(this.selectedDocument?.observers?.length > 0);

//         this.user$
//           .pipe(
//             filter((user) => user != null),
//             take(1)
//           )
//           .subscribe((currentUser) => {
//             if (this.selectedDocument?.user_signer?.length > 0) {
//               this.selectedDocument.user_signer.forEach((signer) => {
//                 if (
//                   this.selectedDocument.status?.key ===
//                     EDocumentStatus.IN_SIGN &&
//                   signer.sign_request_signature_quotes.length > 0 &&
//                   signer.status.key !== 'signed' &&
//                   signer.email === currentUser.email
//                 ) {
//                   this.signatureQuotes$.next(
//                     removeDuplicates(
//                       [
//                         ...this.signatureQuotes$.getValue(),
//                         ...signer.sign_request_signature_quotes
//                       ],
//                       'template_uid'
//                     )
//                   );
//                   this.validFormTemplate$.next(true);
//                 }
//               });
//               this.documentRequest = EDocumentRequest[this.selectedDocument.user_signer[0]?.id_document_request.toUpperCase()];
//                 // this.selectedDocument.user_signer[0]?.id_document_request;
//             }
//             this.isFaceMatch = this.selectedDocument.facematch;

//             if (this.selectedDocument.status?.key === 'signed') {
//               this.validateFormTemplate();
//             }

//             if (
//               this.selectedDocument?.status.key ===
//               EDocumentStatus.DATA_COLLECTION
//             ) {
//               this.validFormTemplate$.next(true);
//             }
//             if (this.isTemplate || this.isTicket) {
//               this.validateFormTemplate();
//             }
//           });

//         this.isCollectData$.next(
//           document.status.key === EDocumentStatus.DATA_COLLECTION
//         );

//         this.documentId = document.id;
//         this.isCommonDocument =
//           ['pdf', 'xml', 'md', 'template', 'ticket', 'docx', 'doc'].indexOf(
//             document.attachments[0]?.kind
//           ) >= 0;

//         this.typePromissoryNote = !this.isCommonDocument;
//         this.isReview$.next(
//           document.transaction_type === EDocTransactionType.REVIEW
//         );
//         this.isApprove$.next(
//           document.transaction_type === EDocTransactionType.APPROVAL
//         );
//         this.isApplicant$.next(
//           document.transaction_type === EDocTransactionType.REQUEST
//         );
//         this.isCheckReview$.next(
//           document.user_signer[0]?.signatory_role?.name === 'reviewer' &&
//             document.user_signer[0]?.status?.key === 'request_sent'
//         );
//         this.isCheckApprove$.next(
//           document.user_signer[0]?.signatory_role?.name === 'approver' &&
//             document.user_signer[0]?.status?.key === 'request_sent'
//         );
//         this.isSignature$.next(
//           document.transaction_type === EDocTransactionType.SIGNATURE
//         );
//         const attachment = document.attachments?.find(
//           (element) => element.id === this.selectedAttachment
//         );

//         this.setChatVisible(document);

//         if (
//           attachment?.kind === EAttachmentKind.TEMPLATE ||
//           attachment?.kind === EAttachmentKind.TICKET ||
//           this.isTemplate
//         ) {
//           this.b2cAuthService
//             .getToken()
//             .pipe(untilDestroyed(this))
//             .subscribe((token) => {
//               this.b2cToken = token;
//               this.templateId = attachment?.form_builder_template_id;
//               this.recordId = attachment?.form_builder_record_uid;
//             });
//         }
//         this.changeSwitchViewer(attachment);

//         if (
//           (document.status.key !== EDocumentStatus.DRAFT &&
//             document?.sign_requests.some(
//               (signer) =>
//                 signer.status.key === 'rejected' ||
//                 (signer.delegate && signer.delegate.status.key === 'rejected')
//             )) ||
//           this.isLegalRepresentativeReject
//         ) {
//           this.store.dispatch(documentActions.GetCancellationReasons());
//         }

//         const principalDoc = document.attachments.find((doc) => doc.primary);
//         if (
//           document.transaction_type === EDocTransactionType.SIGNATURE &&
//           document.status.key === EDocumentStatus.DRAFT &&
//           principalDoc.kind === EAttachmentKind.DOCX &&
//           principalDoc.file_docx_url
//         ) {
//           this.forceEnabledSigners$.next(true);
//         }
//       });
//   }
//   // end class constructor

//   public get officialIdentification(): typeof EOfficialIdentification {
//     return EOfficialIdentification;
//   }

//   ngOnInit() {
//     if (!document.querySelector('#onlyOfficeScript')) {
//       const scriptOnlyOffice = document.createElement('script');
//       scriptOnlyOffice.type = 'text/javascript';
//       scriptOnlyOffice.src = `${environment.onlyOfficeUrl}`;
//       scriptOnlyOffice.setAttribute('defer', '');
//       scriptOnlyOffice.setAttribute('id', 'onlyOfficeScript');
//       scriptOnlyOffice.setAttribute('lang', this.translate.currentLang);
//       const head = document.getElementsByTagName('head')[0];
//       head.appendChild(scriptOnlyOffice);
//       this._reload = false;
//     }

//     // this.store
//     //   .pipe(untilDestroyed(this), select(selectSignatureResult))
//     //   .subscribe((result: ISignatureResponse) => {
//     //     if (result) {
//     //       this.store.dispatch(documentActions.UpdateSelectedDocument());
//     //     }
//     //   });

//     this.document$
//       .pipe(
//         untilDestroyed(this),
//         filter((document) => !!document),
//         distinctUntilKeyChanged('status_name')
//       )
//       .subscribe((item) => {
//         this._document = item;
//         this._attachment = item?.attachments?.filter(
//           (a) => a.primary === true
//         )[0];
//         this._user = this.userInfo;
//         this.setChatVisible(item);
//         if (item) {
//           this.visibleInfo = [true, false, false, false, false];
//         } else {
//           this.visibleInfo = [false, false, true, false, false];
//         }

//         const isAuthorCollection: boolean =
//           (item?.transaction_type === EDocTransactionType.SIGNATURE ||
//             item?.transaction_type === EDocTransactionType.REQUEST) &&
//           (item?.status?.key === EDocumentStatus.DRAFT ||
//             item?.status?.key === EDocumentStatus.DATA_COLLECTION) &&
//           item?.user?.id === this._user?.id;

//         const isUserSignerCollection: boolean =
//           item?.transaction_type === EDocTransactionType.SIGNATURE &&
//           item?.status?.key === EDocumentStatus.DATA_COLLECTION &&
//           item?.user_signer[0]?.email !== this._user?.email;

//         const isUserSignerCollectionQuote: boolean =
//           (item?.transaction_type === EDocTransactionType.SIGNATURE ||
//             item?.transaction_type === EDocTransactionType.FORM) &&
//           item?.status?.key === EDocumentStatus.IN_SIGN &&
//           item?.user_signer.length > 0 &&
//           item?.user_signer[0].email === this._user?.email &&
//           item?.user_signer[0]?.sign_request_signature_quotes.length > 0;

//         const isUserSignerLegalRepresentative: boolean =
//           item?.transaction_type === EDocTransactionType.SIGNATURE &&
//           item?.status?.key === EDocumentStatus.DATA_COLLECTION &&
//           item?.user_signer[0]?.email === this._user?.email;

//         if (
//           isAuthorCollection ||
//           isUserSignerCollection ||
//           isUserSignerLegalRepresentative
//         ) {
//           this.showTemplateMetadata = true;
//         } else {
//           this.showTemplateMetadata = false;
//         }

//         if (isUserSignerCollectionQuote) {
//           this.showSignatureQuote = true;
//           this.showTemplateMetadata = true;
//         } else {
//           this.showSignatureQuote = false;
//         }

//         this.getTraslateTabs(this._document);
//         this.currentTabIndex = this.tabs.findIndex(
//           (t) => t.key === EWorkspaceDocumentTabs.ATTACHMENTS
//         );
//         this.currentTab = EWorkspaceDocumentTabs.ATTACHMENTS;
//       });

//     this.store
//       .pipe(untilDestroyed(this), select(selectPendingMessages))
//       .subscribe((pendingMessages) => {
//         this.notificationsValue$.next(pendingMessages);
//       });
//   }

//   setChatVisible(document: IDocument) {
//     this.canInitializeChat = !!document?.chat;
//     if (this.canInitializeChat) {
//       this.getPendingMessages(this.selectedDocument);
//     }
//   }

//   // valida que todos los metadatos requeridos de perfiles de hayan llenado
//   validateSignatureQuote(event: any): void {
//     if (event && event.record) {
//       this.signatureQuotesValid[event.record] = event.is_valid;

//       this.recordFilled = Object.values(this.signatureQuotesValid).every(
//         (isValid) => isValid === true
//       );
//     }
//   }

//   ngOnDestroy(): void {
//     this.store.dispatch(ClearStack());
//     const chatScript = document.querySelector('#chatScript');
//     if (chatScript) {
//       chatScript.parentNode.removeChild(chatScript);
//     }
//     if (!this.isPormisorryNote) {
//       this.store.dispatch(documentActions.CleanSelectedDocumentState());
//       this.store.dispatch(documentActions.ClearLastUploadedAttachment());
//     }
//   }

//   executeAction(button: ButtonAction): void {
//     const signRequestActions = {
//       to_approved: (event) => this.sendToApproved(event),
//       to_reviewed: (event) => this.concludeReview(event),
//       reviewed_to_approval: (event) => this.reviewToApproval(event),
//       signatures: (event) => this.reviewSignatures(),
//       download_doc: () => this.downloadFile(),
//       delete_doc: (event) => this.deleteFile(event),
//       set_delivery_date: (event) => this.saveOpenDialogGenerateModal(event),
//       assign: (event) => this.assignmentDialog(),
//       sign: (event) => this.openSignature(event),
//       approved_to_signature: (event) => this.approveToSignature(event),
//       reviewed_to_signature: (event) => this.reviewedToSignature(event),
//       edit_promissory_note: () => this.editPromissoryNote(),
//       save: () => this.saveAttachment(),
//       cancel_review: (event) => this.cancelProcess(event),
//       cancel_approval: (event) => this.cancelProcess(event),
//       cancel_collection: (event) => this.cancelProcess(event),
//       cancel_signature: (event) => this.cancelProcess(event),
//       cancel_request_sent: (event) => this.cancelProcess(event),
//       delegate_approval: (event) => this.delegateApproval(event),
//       delegate_reviewed: (event) => this.delegateReviewed(event)
//     };

//     if (!Array.isArray(button.action)) {
//       this._currentAction = button.action;
//       signRequestActions[button.action]
//         ? signRequestActions[button.action](button.action)
//         : this.markAs(button.action);
//     }
//   }

//   saveAttachment(): void {
//     this.forceEnabledSigners$.next(true);
//     this.viewerAction$.next('save');
//     this._reload = true;
//   }

//   isRequiredComplete(valid: boolean) {
//     this.recordFilled = valid;
//   }

//   activateInfoTab(tab: any): void {
//     this.currentTabIndex = this.tabs.findIndex((t) => t.key === tab.key);
//     this.currentTab = tab.key;
//     if (tab.key === EWorkspaceDocumentTabs.CHAT) {
//       this.notificationsValue$.next(null);
//       this.createChatComponent();
//     } else {
//       if (this.chatContainer) {
//         this.chatContainer.nativeElement.innerHTML = '';
//       }
//       if (this.chatComponent) {
//         this.chatComponent.remove();
//       }
//     }
//   }

//   injectChatScript() {
//     if (!document.querySelector('#chatScript')) {
//       const chat = document.createElement('script');
//       chat.type = 'text/javascript';
//       chat.src = `${environment.chatUrl}`;
//       chat.setAttribute('defer', '');
//       chat.setAttribute('id', 'chatScript');
//       document.querySelector('head').appendChild(chat);
//     }
//   }

//   createChatComponent() {
//     this.injectChatScript();
//     const doc$ = this.document$.pipe(untilDestroyed(this), take(1));
//     const token$ = this.b2cAuthService.getToken().pipe(untilDestroyed(this));
//     combineLatest([doc$, token$]).subscribe(([document, token]) => {
//       this.chatComponent = window.document.createElement('lit-chat');
//       this.chatComponent.setAttribute('token', encodeURIComponent(token));
//       this.chatComponent.setAttribute('is-azure-auth', 'true');
//       this.chatComponent.setAttribute(
//         'chatuuid',
//         encodeURIComponent(document?.chat)
//       );
//       this.chatComponent.setAttribute('sender', document?.token_chat?.username);
//       this.chatComponent.setAttribute(
//         'app-id',
//         document?.token_chat?.session?.app
//       );
//       this.chatComponent.setAttribute('channel', 'LiteraxChannel');
//       this.chatContainer.nativeElement.innerHTML = '';
//       this.chatContainer.nativeElement.appendChild(this.chatComponent);

//       this.chatComponent.addEventListener('requestNewToken', () => {
//         this.b2cAuthService.getToken(true).subscribe((token) => {
//           this.chatComponent.setAttribute('token', token);
//         });
//       });
//     });
//   }

//   updatePosition(signerCoordinates: ISignerCoordinatesUpdater) {
//     this.store.dispatch(
//       signaturePositionActions.UpdateSignaturePosition({
//         payload: signerCoordinates
//       })
//     );
//   }

//   hideOtherInfo(): void {
//     this.isActivo = false;
//     for (let i = 0; i < this.visibleInfo.length; i++) {
//       this.visibleInfo[i] = false;
//     }
//   }

//   toggleTabContent() {
//     this.contentVisible = !this.contentVisible;
//   }

//   onAddSignerClicked() {
//     this.document$.pipe(take(1)).subscribe((document) => {
//       this.store.dispatch(
//         InitializeSignRequestFormStates({ payload: document.sign_requests })
//       );
//     });
//     this.router.navigate([
//       `platform/documents/${this.documentId}/configuration`
//     ]);
//   }

//   // TODO: mover navegacion a directiva routerLink
//   onAddReviewerClicked() {
//     this.router.navigate([`platform/documents/${this.documentId}/reviewers`]);
//   }

//   // TODO: mover navegacion a directiva routerLink
//   onAddApproverClicked() {
//     this.router.navigate([`platform/documents/${this.documentId}/approvers`]);
//   }

//   onSignerAction(signer: ISignRequest, documentId: number) {
//     const sendEmailParams = [{ id: signer.id, send_notification_email: true }];
//     this.store.dispatch(
//       SendSignRequestNotification({
//         payload: { documentId, payload: sendEmailParams }
//       })
//     );
//   }

//   historyDocumentDialog(sign_request: ISignRequest, id: number) {
//     const HistoryParams = {
//       id: sign_request.id,
//       name: sign_request.name,
//       rfc: sign_request.rfc,
//       signature_type_id: sign_request.signature_type_id
//     };
//     this.store.dispatch(
//       GetHistoryEmail({ payload: { id, payload: HistoryParams } })
//     );

//     setTimeout(() => {
//       this.store
//         .pipe(
//           untilDestroyed(this),
//           select(selectLastEventEmail),
//           filter((r) => !!r),
//           first()
//         )
//         .subscribe((response) => {
//           // this.translate
//           //   .get('DOCUMENTS.SIGNERS.HISTORY.DIALOG_TITTLE')
//           //   .subscribe((nzTitle) => {
//           //     const modalHistory = this.modalService.create({
//           //       nzMask: false,
//           //       nzTitle,
//           //       nzContent: HistoryComponent,
//           //       nzWidth: 450,
//           //       nzComponentParams: {
//           //         emailEvent: response.last_email_event,
//           //         signer: sign_request
//           //       },
//           //       nzOnCancel: () => {
//           //         this.modalService.closeAll();
//           //       }
//           //     });
//           //     modalHistory.componentInstance.resend.subscribe((resend) => {
//           //       if (resend) {
//           //         this.onSignerAction(sign_request, id);
//           //       }
//           //     });
//           //   });
//         });
//     }, 1000);
//   }

//   deleteDocumentDialog(): void {
//     const modalRef = this.modalService.create({
//       nzMask: false,
//       nzWidth: '500px',
//       nzCentered: true,
//       nzOnCancel: () => this.modalService.closeAll(),
//       nzContent: DeleteRecordDialogComponent,
//       nzComponentParams: {
//         message: 'DOCUMENTS.CONFIRM_DELETE_MSG',
//         cancelButton: 'TOGGLE_SWITCH.NO',
//         acceptButton: 'TOGGLE_SWITCH.YES'
//       }
//     });

//     modalRef.componentInstance.acceptAction.subscribe((result) => {
//       if (result) {
//         this.store.dispatch(
//           documentActions.DeleteDocument({
//             payload: { documentId: this.documentId }
//           })
//         );
//       }
//     });
//   }

//   downloadFile(): void {
//     this.store.dispatch(
//       documentActions.DownloadDocument({ payload: { id: this.documentId } })
//     );
//   }

//   selectAttachment(id: number) {
//     this.selectedAttachment = id;
//     this.searchAttachment(this.selectedAttachment);
//   }

//   validateFormTemplate() {
//     this.user$.pipe(filter((user) => user != null)).subscribe((currentUser) => {
//       if (
//         this.selectedDocument.status.key === EDocumentStatus.DRAFT &&
//         this.selectedDocument.user.id === currentUser.id
//       ) {
//         this.validFormTemplate$.next(true);
//       } else if (this.isValidatedSignatory(currentUser)) {
//         this.validFormTemplate$.next(true);
//       } else {
//         this.validFormTemplate$.next(false);
//       }
//     });
//   }

//   isValidatedSignatory(user: IProfile) {
//     let signatory = null;
//     this.signRequests$.subscribe((signers) => {
//       signatory = signers?.find((signer) => {
//         return signer.rfc === user.rfc || signer.email === user.email;
//       });
//     });
//     return (
//       (signatory &&
//         this.selectedDocument.status.key === statusTemplate.collection) ||
//       (signatory &&
//         signatory.sign_request_signature_quotes.length > 0 &&
//         signatory.status?.key !== 'signed' &&
//         this.selectedDocument.status.key === statusTemplate.inProcess)
//     );
//   }

//   deleteFile(_: Event) {
//     const modalRef = this.modalService.create({
//       nzMask: false,
//       nzWidth: '500px',
//       nzCentered: true,
//       nzOnCancel: () => this.modalService.closeAll(),
//       nzContent: DeleteRecordDialogComponent,
//       nzComponentParams: {
//         message: 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.DELETE',
//         cancelButton: 'WORKSPACE.FOOTER.CONFIRM_BUTTONS.DELETE_NO',
//         acceptButton: 'WORKSPACE.FOOTER.CONFIRM_BUTTONS.DELETE_YES'
//       }
//     });

//     modalRef.componentInstance.acceptAction.subscribe((result) => {
//       if (result) {
//         this.store.dispatch(
//           documentActions.DeleteDocument({
//             payload: { documentId: this.documentId }
//           })
//         );
//       }
//     });
//   }

//   onCustomSignModalFooterClicked(event: any) {
//     this.signModalRef.componentInstance.signBehaviorButton.next('');
//   }

//   emitNextToStepper() {
//     this.signModalRef.componentInstance.nextBehaviorButton.next('');
//     this.modalSteps = this.signModalRef.componentInstance.steps.length;
//     this.currentModalStep = this.signModalRef.componentInstance.step + 1;
//   }

//   startSignInProcess() {
//     this.signProcessInitiated = true;
//     this.signModalRef.componentInstance.signProcessInitiated = true;
//   }

//   electronicSignature(document: IDocument) {
//     if (document.user_signer.length > 0) {
//       let signDialog: NzModalRef<IdentityValidationModalComponent>;

//       let signatureBody: DocumentSignature;
//       signatureBody = {
//         ...signatureBody,
//         signature: {
//           name: document.user_signer[0].name,
//           email: document.user_signer[0].email,
//           two_factor_token: '',
//           signature_image: '',
//           sign_requests: document.user_signer
//         }
//       };
//       this.translate.get('FORM.SIGNATURE.TITLE').subscribe((nzTitle) => {
//         signDialog = this.signModalRef = this.modalService.create({
//           nzTitle,
//           nzContent: IdentityValidationModalComponent,
//           nzKeyboard: false,
//           nzMaskClosable: false,
//           nzWidth: '480px',
//           nzStyle: {
//             position: 'absolute',
//             right: '30px',
//             top: '30px'
//           },
//           nzFooter: this.signModalFooter
//         });
//       });

//       if (this.documentRequest !== EDocumentRequest.NO_REQUESTED) {
//         signDialog.componentInstance.faceMatch = this.isFaceMatch;
//         signDialog.componentInstance.documentRequest = this.documentRequest;
//       }

//       if (document.signing_method.includes('plus')) {
//         signDialog.componentInstance.plusSignature = true;
//       }
//       // signDialog.componentInstance.data = signatureBody;
//       signDialog.componentInstance.nameDocument = document.name;
//       signDialog.componentInstance.documentId = document.id;

//       signDialog.afterClose.subscribe(() => {
//         this.getModalMessage();
//         this.validFormTemplate$.next(false);
//         this.visibleInfo = [true, false, false, false, false];
//         this.signProcessInitiated = false;
//         signDialog.destroy();
//       });

//       this.signProcessNotFullfilled$ =
//         this.signModalRef.componentInstance.canSign;
//       this.modalSteps = this.signModalRef.componentInstance.steps.length;
//       this.currentModalStep = this.signModalRef.componentInstance.step + 1;
//     }

//     this.actions$
//       .pipe(ofType(signatureActions.registerSignatureSuccess), take(1))
//       .subscribe(() => {
//         this.signModalRef.destroy();
//       });
//   }

//   openSignature(_: Event) {
//     this.document$
//       .pipe(
//         filter((document) => document != null),
//         take(1)
//       )
//       .subscribe((document) => {
//         if (document.signing_method === 'digital_signature') {
//           this.digitalSignature(document);
//         } else {
//           this.electronicSignature(document);
//         }
//       });
//   }

//   digitalSignature(document: IDocument) {
//     this.user$.pipe(take(1)).subscribe((user: IProfile) => {
//       this.translate.get('DOCUMENT-PREVIEW.SIGN.TITLE').subscribe((nzTitle) => {
//         const modalSign = this.modalService.create({
//           nzMask: false,
//           nzTitle,
//           nzContent: SignModalComponent,
//           nzWidth: 524,
//           nzComponentParams: {
//             data: {
//               showCancel: true,
//               document,
//               currentCertName: user.cert
//             }
//           },
//           nzOnCancel: () => {
//             this.modalService.closeAll();
//           }
//         });
//         modalSign.componentInstance.signed.subscribe((isSigned) => {
//           if (isSigned) {
//             this.getModalMessage();
//             this.store.dispatch(documentActions.UpdateSelectedDocument());
//           }
//         });
//       });
//     });
//   }

//   markAs(action: string): void {
//     if (action.split('_')[0] === 'rejected' || action === 'reject') {
//       const modalCancelProcess = this.modalService.create({
//         nzMask: false,
//         nzContent: CancelProcessDocumentComponent,
//         nzWidth: 656,
//         nzComponentParams: {
//           data: {
//             cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
//             acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
//             message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`
//           }
//         },
//         nzOnCancel: () => {
//           this.modalService.closeAll();
//         }
//       });
//       modalCancelProcess.componentInstance.comments.subscribe((comments) => {
//         this.store.dispatch(
//           signatureActions.RejectSignature({ payload: {
//             documentId: this._document.id,
//             signRequestId: this._document.user_signer[0].id,
//             rejectData: {
//               description: comments
//             }
//           }})
//         );
//       });
//     } else {
//       if (action === 'to_generated') {
//         this.isGeneratedRequest = 'view';
//         this._reload = true;
//         this._currentAction = action;
//       } else {
//         const modalRef = this.modalService.create({
//           nzMask: false,
//           nzWidth: '500px',
//           nzCentered: true,
//           nzOnCancel: () => this.modalService.closeAll(),
//           nzContent: DeleteRecordDialogComponent,
//           nzComponentParams: {
//             message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
//             cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
//             acceptButton: 'DOCUMENTS.BUTTONS.DELETE'
//           }
//         });

//         modalRef.componentInstance.acceptAction.subscribe((result) => {
//           if (result) {
//             if (
//               this._attachment?.kind === EAttachmentKind.DOCX &&
//               this._document?.transaction_type === EDocTransactionType.REVIEW
//             ) {
//               this.store.dispatch(
//                 documentActions.DocumentMarkAs({ payload: action })
//               );
//             } else {
//               this.store.dispatch(
//                 documentActions.DocumentMarkAs({ payload: action })
//               );

//               this.actions$
//                 .pipe(
//                   ofType(documentActions.DocumentMarkAsSuccess),
//                   untilDestroyed(this)
//                 )
//                 .subscribe((data) => {
//                   if (
//                     this._document.status.key === EDocumentStatus.IN_SIGN
//                   ) {
//                     this.getModalMessage(action);
//                   }
//                 });
//             }
//           }
//         });
//       }
//     }
//   }

//   afterOnlyofficeReload() {
//     const not_markable_actions: string[] = [
//       'set_delivery_date',
//       'save',
//       'download_doc',
//       'cancel_review',
//       'cancel_approval',
//       'cancel_signature',
//       'cancel_collection',
//       'cancel_request_sent',
//       'cancel_in_sign'
//     ];

//     if (
//       this._currentAction !== undefined &&
//       !not_markable_actions.some((action) => this._currentAction === action)
//     ) {
//       if (this._currentAction === 'to_reviewed') {
//         this.store.dispatch(
//           documentActions.DocumentMarkAsReviewed({
//             payload: {
//               action: this._currentAction,
//               sign_request: this.selectedDocument.user_signer[0].id
//             }
//           })
//         );
//       } else {
//         this.store.dispatch(
//           documentActions.DocumentMarkAs({ payload: this._currentAction })
//         );
//       }

//       this.actions$
//         .pipe(
//           ofType(documentActions.DocumentMarkAsSuccess),
//           untilDestroyed(this)
//         )
//         .subscribe((data) => {
//           this.store.dispatch(
//             documentActions.GetDocument({ payload: this.documentId })
//           );
//         });
//     }
//     // !Vierificar si el cambio de variable deb ser aqui
//     this.store.dispatch(
//       documentActions.GetDocument({ payload: this.documentId })
//     );
//     this.selectAttachment(this.selectedAttachment);
//     this._reload = false;

//     if (this._document?.transaction_type !== 'review') {
//       this.viewerAction$.next('view');
//       this.typeDocVisible$.next('pdf');
//     } else {
//       this.viewerAction$.next('edit');
//       this.typeDocVisible$.next('docx');
//     }
//   }

//   concludeReview(action: string) {
//     this._currentAction = action;
//     this._reload = true;
//     this.markAs(action);
//   }

//   cancelProcess(action: string) {
//     let message: string;
//     switch (action) {
//       case 'cancel_request_sent':
//         message = 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.CANCEL_REQUEST_SENT';
//         break;
//       case 'cancel_approval':
//         message = 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.CANCEL_APPROVAL';
//         break;
//       default:
//         message = 'WORKSPACE.FOOTER.CONFIRM_MESSAGE.CANCEL_SIGNATURE';
//         break;
//     }

//     const modalRef = this.modalService.create({
//       nzMask: false,
//       nzWidth: '500px',
//       nzCentered: true,
//       nzOnCancel: () => this.modalService.closeAll(),
//       nzContent: DeleteRecordDialogComponent,
//       nzComponentParams: {
//         message: message,
//         cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
//         acceptButton: 'DOCUMENTS.BUTTONS.DELETE'
//       }
//     });

//     modalRef.componentInstance.acceptAction.subscribe((result) => {
//       if (result) {
//         this.store.dispatch(
//           documentActions.DocumentMarkAsList({
//             payload: { id: this.documentId, action: action }
//           })
//         );
//       }
//     });
//   }

//   reviewToApproval(action: string) {
//     const modalConfirm = this.modalService.create({
//       nzMask: false,
//       nzContent: ConfirmDialogComponent,
//       nzComponentParams: {
//         data: {
//           cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
//           acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
//           message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
//           title: `WORKSPACE.FOOTER.CONFIRM_TITLE.${action.toUpperCase()}`,
//           acceptCallback: (): void => {
//             this.router.navigate([
//               'platform',
//               'documents',
//               this.documentId,
//               'reviewers-to-approvers'
//             ]);
//           },
//           cancelCallback: (): void => {
//             this.store.dispatch(
//               documentActions.DocumentMarkAs({ payload: action })
//             );
//           }
//         }
//       },
//       nzOnCancel: () => {
//         this.modalService.closeAll();
//       }
//     });
//   }

//   sendToApproved(action: string) {
//     this.store.dispatch(
//       documentActions.DocumentMarkAsApproved({
//         payload: {
//           action,
//           sign_request: this.selectedDocument.user_signer[0].id
//         }
//       })
//     );
//   }

//   approveDocReview(action: string) {
//     const modalConfirm = this.modalService.create({
//       nzMask: false,
//       nzContent: ConfirmDialogComponent,
//       nzComponentParams: {
//         data: {
//           cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
//           acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
//           message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
//           title: `WORKSPACE.FOOTER.CONFIRM_TITLE.${action.toUpperCase()}`,
//           acceptCallback: (): void => {
//             this.router.navigate([
//               'platform',
//               'documents',
//               this.documentId,
//               'reviewers-to-signers'
//             ]);
//           },
//           cancelCallback: (): void => {
//             if (this._attachment.kind === EAttachmentKind.DOCX) {
//               this._reload = true;
//               this._currentAction = action;
//             } else {
//               this.store.dispatch(
//                 documentActions.DocumentMarkAs({ payload: action })
//               );
//             }
//           }
//         }
//       },
//       nzOnCancel: () => {
//         this.modalService.closeAll();
//       }
//     });
//   }

//   approveToSignature(action: string) {
//     this.translate
//       .get('WORKSPACE.FOOTER.CONFIRM_APPROVAL.TITLE_MODAL')
//       .subscribe((nzTitle) => {
//         const modalConfirmApproval = this.modalService.create({
//           nzMask: false,
//           nzWidth: 500,
//           nzTitle,
//           nzContent: ConfirmDialogApprovalComponent
//         });
//         modalConfirmApproval.componentInstance.optionalConf.subscribe(
//           (configurations) => {
//             const documentSelect: IUpdateDocument = {
//               transaction_type: this.selectedDocument.transaction_type,
//               signing_method: this.selectedDocument.signing_method,
//               approval_timestamp_sheet: configurations.constancy
//             };
//             this.store.dispatch(
//               documentActions.UpdateDocument({
//                 payload: {
//                   documentId: this.documentId,
//                   document: documentSelect
//                 }
//               })
//             );
//             if (configurations.participants) {
//               this.router.navigate([
//                 'platform',
//                 'documents',
//                 this.documentId,
//                 'approvers-to-signers'
//               ]);
//             } else {
//               if (this._attachment.kind === EAttachmentKind.DOCX) {
//                 this._reload = true;
//                 this._currentAction = action;
//               } else {
//                 this.store.dispatch(
//                   documentActions.DocumentMarkAs({ payload: action })
//                 );
//               }
//             }
//           }
//         );
//       });
//   }

//   reviewedToSignature(action: string) {
//     const modalConfirm = this.modalService.create({
//       nzMask: false,
//       nzContent: ConfirmDialogComponent,
//       nzComponentParams: {
//         data: {
//           cancelButton: 'DOCUMENTS.BUTTONS.CANCEL',
//           acceptButton: 'DOCUMENTS.BUTTONS.DELETE',
//           message: `WORKSPACE.FOOTER.CONFIRM_MESSAGE.${action.toUpperCase()}`,
//           title: `WORKSPACE.FOOTER.CONFIRM_TITLE.${action.toUpperCase()}`,
//           acceptCallback: (): void => {
//             this.router.navigate([
//               'platform',
//               'documents',
//               this.documentId,
//               'reviewers-to-signers'
//             ]);
//           },
//           cancelCallback: (): void => {
//             if (this._attachment.kind === EAttachmentKind.DOCX) {
//               this._reload = true;
//               this._currentAction = action;
//             } else {
//               this.store.dispatch(
//                 documentActions.DocumentMarkAs({ payload: action })
//               );
//             }
//           }
//         }
//       },
//       nzOnCancel: () => {
//         this.modalService.closeAll();
//       }
//     });
//   }

//   delegateApproval(action: string) {
//     this.translate
//       .get('WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.APPROVAL.TITLE')
//       .subscribe((nzTitle) => {
//         const modalDelegate = this.modalService.create({
//           nzMask: false,
//           nzTitle,
//           nzContent: DelegateProcessDialogComponent,
//           nzComponentParams: {
//             data: {
//               cancelButton: 'WORKSPACE.FOOTER.CANCEL',
//               acceptButton: 'WORKSPACE.FOOTER.DELEGATE',
//               message:
//                 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.APPROVAL.DESCRIPTION',
//               userSignerId: this._user.id,
//               documentId: this.documentId,
//               acceptCallback: (delegate: any): void => {
//                 this.store.dispatch(
//                   DelegateProcessTo({
//                     payload: {
//                       to: delegate.id
//                     }
//                   })
//                 );
//               },
//               cancelCallback: (): void => {}
//             }
//           },
//           nzOnCancel: () => {
//             this.modalService.closeAll();
//           }
//         });
//       });

//     const confirmDialog = this.actions$
//       .pipe(
//         ofType(EClientActions.DelegateProcessToSuccess),
//         take(1),
//         finalize(() => confirmDialog.unsubscribe())
//       )
//       .subscribe((delegate: any) => {
//         this.messageConfirmationDelegate(action, delegate.payload);
//       });
//   }

//   delegateReviewed(action: string) {
//     this.translate
//       .get('WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.REVIEW.TITLE')
//       .subscribe((nzTitle) => {
//         const modalDelegate = this.modalService.create({
//           nzMask: false,
//           nzTitle,
//           nzContent: DelegateProcessDialogComponent,
//           nzComponentParams: {
//             data: {
//               cancelButton: 'WORKSPACE.FOOTER.CANCEL',
//               acceptButton: 'WORKSPACE.FOOTER.DELEGATE',
//               message:
//                 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.REVIEW.DESCRIPTION',
//               acceptCallback: (delegate: any): void => {
//                 this.store.dispatch(
//                   DelegateProcessTo({
//                     payload: {
//                       to: delegate.id
//                     }
//                   })
//                 );
//               },
//               cancelCallback: (): void => {}
//             }
//           },
//           nzOnCancel: () => {
//             this.modalService.closeAll();
//           }
//         });
//       });

//     const confirmDialog = this.actions$
//       .pipe(
//         ofType(EClientActions.DelegateProcessToSuccess),
//         take(1),
//         finalize(() => confirmDialog.unsubscribe())
//       )
//       .subscribe((delegate: any) => {
//         this.messageConfirmationDelegate(action, delegate.payload);
//       });
//   }

//   messageConfirmationDelegate(typeProccess: string, delegate: ISignRequest) {
//     const titleMessage =
//       typeProccess === 'delegate_reviewed'
//         ? 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.REVIEW.TITLE'
//         : 'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.APPROVAL.TITLE';
//     const modalConfirmDelegate = this.modalService.create({
//       nzMask: false,
//       nzWidth: 672,
//       nzContent: ConfirmDialogDelegateComponent,
//       nzFooter: null,
//       nzComponentParams: {
//         data: {
//           cancelButton: 'WORKSPACE.FOOTER.CANCEL',
//           acceptButton: 'WORKSPACE.FOOTER.AWARE',
//           message:
//             'WIZARD.DOCUMENTS.FORMS.DIALOG_DELEGATE.DESCRIPTION_CONFIRMATION',
//           title: titleMessage,
//           user: delegate?.name,
//           acceptCallback: (): void => {},
//           cancelCallback: (): void => {}
//         }
//       }
//     });
//   }

//   editPromissoryNote() {
//     this.isPormisorryNote = true;
//     this.router.navigate([
//       'platform',
//       'promissorynotes',
//       'edit',
//       this.documentId
//     ]);
//   }

//   saveOpenDialogGenerateModal(action: any) {
//     this.translate
//       .get('WORKSPACE.GENERATE.MODAL.TITLE')
//       .subscribe((nzTitle) => {
//         const modalDate = this.modalService.create({
//           nzMask: false,
//           nzWidth: 300,
//           nzTitle,
//           nzContent: AddModalDateComponent,
//           nzComponentParams: {
//             documentId: this.documentId,
//             attachment: this._attachment
//           }
//         });
//         modalDate.componentInstance.reload.subscribe((reload) => {
//           if (reload) {
//             this._reload = true;
//             this._currentAction = action;
//             this.getProcess();
//           }
//         });
//       });
//   }

//   reviewSignatures() {
//     this.router.navigate([
//       'platform',
//       'documents',
//       this.documentId,
//       'signatures'
//     ]);
//   }

//   getAttachmentIfEmpty(id: number) {
//     this.store.dispatch(
//       documentActions.GetDocumentAttachment({
//         payload: {
//           documentId: this.documentId,
//           attachmenId: id
//         }
//       })
//     );

//     this.attachmens$.pipe(take(1)).subscribe((elements: IAttachment[]) => {
//       const attachment: IAttachment = elements.find(
//         (element) => element.id === id
//       );
//       this._attachment = attachment;

//       if (!attachment?.text) {
//         this.store.dispatch(
//           documentActions.GetDocumentAttachment({
//             payload: { documentId: this.documentId, attachmenId: id }
//           })
//         );
//       } else {
//         this.store.dispatch(
//           documentActions.ChangeAttachmentSelect({ payload: attachment })
//         );

//         this.changeSwitchViewer(attachment);
//       }
//     });
//   }

//   searchPrimaryAttachment() {
//     this.document$
//       .pipe(
//         filter((document) => document != null),
//         filter((document) => document?.attachments?.length > 0),
//         take(1)
//       )
//       .subscribe((document: IDocument) => {
//         const attachment = document.attachments.find(
//           (element) => element.primary
//         );
//         this.isTemplate = attachment.form_builder_record_uid !== null;
//         this.isTicket = attachment.kind === EAttachmentKind.TICKET;
//         this.userSignerHaveCollection =
//           document.status.key === EDocumentStatus.DATA_COLLECTION;
//         this.selectedDocument = document;
//         this.selectedAttachment = attachment?.id;
//         if (document.transaction_type === EDocTransactionType.REVIEW) {
//           setTimeout(() => {
//             this.store.dispatch(
//               documentActions.GetDocumentAttachment({
//                 payload: {
//                   documentId: document?.id,
//                   attachmenId: attachment?.id
//                 }
//               })
//             );
//           }, 10000);
//         } else {
//           this.store.dispatch(
//             documentActions.GetDocumentAttachment({
//               payload: {
//                 documentId: document?.id,
//                 attachmenId: attachment?.id
//               }
//             })
//           );
//         }
//         this.changeSwitchViewer(attachment);
//       });
//   }

//   searchAttachment(id: number) {
//     if (id > 0) {
//       this.getAttachmentIfEmpty(id);
//     } else {
//       this.searchPrimaryAttachment();
//     }
//   }

//   getProcess() {
//     this.route.paramMap
//       .pipe(
//         untilDestroyed(this),
//         map(() => window.history.state)
//       )
//       .subscribe((params) => {
//         if (params?.process) {
//           this.store.dispatch(ClearStack());
//           const currentProcess = params.process;
//           const processType =
//             currentProcess === 'internal_process'
//               ? 'sent'
//               : currentProcess === 'title'
//               ? 'title'
//               : 'received';
//           const title = 'HOME.' + processType.toUpperCase();
//           this.store.dispatch(
//             SetNavNodes({
//               payload: {
//                 firstLevel: {
//                   title: title,
//                   route:
//                     currentProcess === 'title'
//                       ? `/platform/home`
//                       : `/platform/process/${currentProcess}/typeprocess/${processType}`
//                 }
//               }
//             })
//           );
//         } else {
//           this.store.dispatch(
//             SetNavNodes({
//               payload: {
//                 firstLevel: {
//                   title: 'HOME.TITLE',
//                   route: `/platform/home`
//                 }
//               }
//             })
//           );
//         }
//       });
//     this.document$
//       .pipe(filter((document) => !!document))
//       .subscribe((document: IDocument) => {
//         this.store.dispatch(
//           SetNavNodes({
//             payload: {
//               secondLevel: {
//                 route: '',
//                 title: `DOCUMENT-CONFIGURATION.PROCESS.${document?.status?.key?.toUpperCase()}`,
//                 color: '#616161'
//               },
//               thirdLevel: {
//                 route: '',
//                 title: document?.name,
//                 color: '#eeeeee'
//               }
//             }
//           })
//         );
//       });
//   }

//   editpromissorynote() {
//     this.router.navigate(['/platform/promissorynotes/edit/', this.documentId]);
//   }

//   onRequestNewToken() {
//     this.b2cAuthService
//       .getToken()
//       .pipe(untilDestroyed(this))
//       .subscribe((token) => {
//         this.b2cToken = token;
//       });
//   }

//   assignmentDialog() {
//     this.store.dispatch(usersActions.getAllUsers());
//     const listUsers$ = this.store.pipe(select(GetUsersReceiversActive));
//     this.translate
//       .get('DOCUMENT-CONFIGURATION.REASSIGMENT.ASSING')
//       .subscribe((nzTitle) => {
//         const modalAssigment = this.modalService.create({
//           nzMask: false,
//           nzWidth: 320,
//           nzTitle,
//           nzContent: AssignmentDialogComponent,
//           nzComponentParams: {
//             data: {
//               users: listUsers$,
//               acceptCallback: (request: any) => {
//                 this.sentAssignment(request);
//               },
//               cancelCallback: (): void => {}
//             }
//           }
//         });
//       });
//   }

//   sentAssignment(request: number) {
//     this.store.dispatch(
//       documentActions.DocumentCreateAssigment({
//         payload: {
//           documentId: this.selectedDocument.id,
//           sign_request: { client_user_id: request }
//         }
//       })
//     );
//   }

//   openEditPromissoryNote(): void {
//     this.router.navigate([
//       'platform',
//       'documents',
//       this.documentId,
//       'promissory-note-configuration'
//     ]);
//   }

//   changeSwitchViewer(attachment: IAttachment, fileType?: string) {
//     if (
//       (attachment?.file_docx_url === null &&
//         attachment?.kind === EAttachmentKind.DOCX) ||
//       this._document?.transaction_type === EDocTransactionType.REVIEW
//     ) {
//       this.viewerAction$.next('edit');
//       this.isDocxAttachment$.next(true);
//       this.typeDocVisible$.next('docx');
//     } else {
//       this.viewerAction$.next('view');
//       this.typeDocVisible$.next('pdf');
//       this.isDocxAttachment$.next(false);
//     }
//   }

//   editAttachment($event: IAttachment) {
//     this.isDocxAttachment$.next(true);
//     this.viewerAction$.next('edit');
//     this.typeDocVisible$.next('docx');
//   }

//   checkAttachment() {
//     this.store.dispatch(
//       checkAttachment({
//         payload: {
//           documentId: this.documentId,
//           attachmentId: this.selectedAttachment,
//           signRequestId: this.selectedDocument.user_signer[0].id
//         }
//       })
//     );

//     this.actions$
//       .pipe(ofType(checkAttachmentSuccess), untilDestroyed(this))
//       .subscribe(() => {
//         this.store.dispatch(documentActions.UpdateSelectedDocument());
//       });
//   }

//   getPendingMessages(document: IDocument) {
//     const token$ = this.b2cAuthService.getToken();
//     token$.subscribe((token) => {
//       const app_token = document?.token_chat?.session?.app
//         ? document?.token_chat?.session?.app
//         : document?.token_chat?.session?.token;
//       this.store.dispatch(
//         documentActions.GetPendingMessages({
//           payload: {
//             chat: document.chat,
//             app: app_token,
//             token: encodeURIComponent(token)
//           }
//         })
//       );
//     });
//   }

//   getTraslateTabs(document: IDocument) {
//     if (document.status.key === EDocumentStatus.DRAFT && this.isDesktopDevice) {
//       this.currentTab = EWorkspaceDocumentTabs.ATTACHMENTS;
//       this.currentTabIndex = this.tabs.findIndex(
//         (tab) => tab.key === EWorkspaceDocumentTabs.ATTACHMENTS
//       );
//     }

//     const listTabs = [];

//     const attachment = document.attachments?.find(
//       (element) => element.id === this.selectedAttachment
//     );

//     const isLegend: boolean =
//       document.user_signer[0]?.sign_request_signature_quotes.length > 0;

//     const needsMetadataTab = Object.values<string>(ETemplateKind).includes(
//       attachment.kind
//     );

//     if (
//       document.status?.key === EDocumentStatus.DRAFT ||
//       document.status?.key === EDocumentStatus.IN_SIGN ||
//       document.status?.key === EDocumentStatus.SIGNED ||
//       document.status?.key === EDocumentStatus.DATA_COLLECTION ||
//       document.status?.key === EDocumentStatus.REJECTED ||
//       document.status?.key === EDocTransactionType.APPROVAL ||
//       document.status?.key === EDocTransactionType.REVIEW ||
//       document.status?.key === EDocTransactionType.REVIEWED ||
//       document.status?.key === EDocTransactionType.SENT ||
//       (document.status?.key === EDocumentStatus.GENERATED &&
//         document.transaction_type === EDocTransactionType.REQUEST)
//     ) {
//       this.translate
//         .get('WORKSPACE.UI.TABICONS.ATTACHMENTS')
//         .subscribe((str) => {
//           listTabs.push({
//             name: str,
//             icon: 'fal fa-paperclip',
//             key: EWorkspaceDocumentTabs.ATTACHMENTS
//           });
//         });

//       this.translate.get('WORKSPACE.UI.TABICONS.SIGN').subscribe((str) => {
//         listTabs.push({
//           name: str,
//           icon: 'fal fa-users',
//           key: EWorkspaceDocumentTabs.PARTICIPANTS
//         });
//       });
//     }

//     if (
//       document.status?.key === 'in_sign' ||
//       document.status?.key === EDocTransactionType.REVIEW ||
//       document.status?.key === EDocTransactionType.APPROVAL
//     ) {
//       this.translate.get('WORKSPACE.UI.TABICONS.COMMENT').subscribe((str) => {
//         listTabs.push({
//           name: str,
//           icon: 'fal fa-comments',
//           key: EWorkspaceDocumentTabs.CHAT
//         });
//       });
//     }

//     this.translate.get('WORKSPACE.UI.TABICONS.INFO').subscribe((str) => {
//       listTabs.push({
//         name: str,
//         icon: 'fal fa-info-circle',
//         key: EWorkspaceDocumentTabs.INFO
//       });
//     });
//     if (
//       (document.status?.key === 'draft' ||
//         document.status?.key === 'data_collection' ||
//         (document.status?.key === 'in_sign' &&
//           document.user_signer.length &&
//           document.transaction_type !== EDocTransactionType.REQUEST)) &&
//       document.transaction_type !== EDocTransactionType.APPROVAL
//     ) {
//       if (needsMetadataTab) {
//         this.translate
//           .get(
//             isLegend
//               ? 'WORKSPACE.UI.TABICONS.LEGENDS'
//               : 'WORKSPACE.UI.TABICONS.EDIT'
//           )
//           .subscribe((str) => {
//             listTabs.push({
//               name: str,
//               icon: 'fal fa-pencil-alt',
//               key: EWorkspaceDocumentTabs.METADATA
//             });
//           });
//       }
//     }

//     this.tabs = listTabs;

//     if (this.isMobileDevice || this.isTabletDevice) {
//       this.translate.get('WORKSPACE.UI.TABICONS.DOCUMENT').subscribe((str) => {
//         this.tabs.unshift({
//           name: str,
//           icon: 'fal fa-file',
//           key: EWorkspaceDocumentTabs.DOCUMENT
//         });
//       });

//       if (document.status.key === EDocumentStatus.DRAFT) {
//         this.currentTab = EWorkspaceDocumentTabs.DOCUMENT;
//         this.currentTabIndex = this.tabs.findIndex(
//           (tab) => tab.key === EWorkspaceDocumentTabs.DOCUMENT
//         );
//       }
//     }
//   }

//   hideModalInfo() {
//     this.isActivo = false;
//   }

//   getModalMessage(action: string = '') {
//     if (action === '') {
//       this.translate
//         .get('MESSAGE_MODAL_PROCESS.SIGNER_MESSAGE.TITLE')
//         .subscribe((str) => {
//           this.titleMessagemodal = str;
//         });

//       this.descriptionMessageModal = this._document.name;
//     } else {
//       this.translate.get('MESSAGE_MODAL_PROCESS.TITLE').subscribe((str) => {
//         this.titleMessagemodal = str;
//       });

//       this.translate
//         .get('MESSAGE_MODAL_PROCESS.DESCRIPTION_PROSSESS_SIGNER')
//         .subscribe((str) => {
//           this.descriptionMessageModal = str;
//         });
//     }
//     this.modalService.create({
//       nzMask: false,
//       nzWidth: 700,
//       nzContent: AcceptModalComponent,
//       nzComponentParams: {
//         titleMessagemodal: this.titleMessagemodal,
//         descriptionMessageModal: this.descriptionMessageModal
//       },
//       nzClosable: false,
//       nzFooter: null
//     });
//   }
}
