import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IRol } from '@literax/models/rol.model';

export interface IRolState {
  roles: IRol[];
  rolesError: IAPIResponseError;
}

export const initialRolState: IRolState = {
  roles: [],
  rolesError: null
};
