import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IApprover, IApproverRequest } from '@literax/models/approver.model';

export enum EApproveRequestActions {
  CreateApproveRequest = '[ApproveRequest] Create Approve request',
  CreateApproveRequestSucces = '[ApproveRequest] Create Approve Request Success',
  CreateApproveRequestError = '[ApproveRequest] Create Approve Reqest Error',

  UpdateApproverequest = '[Approve Request] Update Approve Request',
  UpdateApproverequestSuccess = '[Approve Request] Update Approve Request Success',
  UpdateApproverequestError = '[Approve Request] Update Approve Request Error',

  DeleteApproverequest = '[Approve Request] Delete Approve Request',
  DeleteApproverequestSuccess = '[Approve Request] Delete Approve Request',
  DeleteApproverequestError = '[Approve Request] Delete Approve Request',

  ApproveApproveRequest = '[ApproveRequest] Approve Approve request',
  ApproveApproveRequestSucces = '[ApproveRequest] Approve Approve request success',
  ApproveApproveRequestError = '[ApproveRequest] Approve Approve request error',

  SendApproveersToSigners = '[ApproveRequest] Send Approveers to signers',
  SendApproveersToSignersSuccess = '[ApproveRequest] Send Approveers to signers success',
  SendApproveersToSignersError = '[ApproveRequest] Send Approveers to signers error'
}

/**
 * Action para la creacion de una solicitud de revision
 */
export const CreateApproveRequest = createAction(
  EApproveRequestActions.CreateApproveRequest,
  props<{ payload: { documentId: number; approveRequest: IApproverRequest } }>()
);

/**
 * Action para los errors de creacion de solicitud de revision
 */
export const CreateApproveRequestSucces = createAction(
  EApproveRequestActions.CreateApproveRequestSucces,
  props<{ payload: IApprover }>()
);

/**
 * Action para los errors de creacion de solicitud de revision
 */
export const CreateApproveRequestError = createAction(
  EApproveRequestActions.CreateApproveRequestError,
  props<{ payload: IAPIResponseError }>()
);

export const UpdateApproverequestError = createAction(
  EApproveRequestActions.UpdateApproverequestError,
  props<{ payload: IAPIResponseError }>()
);

/**
 * Action para la actualizacion de una solicitud de revision
 */
export const UpdateApproveRequest = createAction(
  EApproveRequestActions.UpdateApproverequest,
  props<{ payload: { documentId: number; approveRequest: IApproverRequest } }>()
);

export const UpdateApproveRequestSuccess = createAction(
  EApproveRequestActions.UpdateApproverequestSuccess,
  props<{ payload: boolean }>()
);

export const DeleteApproverequest = createAction(
  EApproveRequestActions.DeleteApproverequest,
  props<{ payload: { documentId: number; approveRequest: IApproverRequest } }>()
);

export const DeleteApproverequestSucces = createAction(
  EApproveRequestActions.DeleteApproverequestSuccess
);

export const DeleteApproverequestError = createAction(
  EApproveRequestActions.DeleteApproverequestError
);

export const ApproveApproveRequest = createAction(
  EApproveRequestActions.ApproveApproveRequest
);
