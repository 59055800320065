import * as profilesActions from '../states/profiles.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { IProfile } from '../models/profiles';
import {
  IAPIResponseError,
  IAPIResponseErrors
} from '@literax/models/http/api/error.model';
import { IEmail } from '@literax/components/configurations/profiles/models/emails';

export interface IProfilesState {
  profile: IProfile;
  errorGetAllProfile: IAPIResponseError;
  userEmails: IEmail[];
  registerEmailErrors: IAPIResponseErrors;
}
export const initiaProfileslState: IProfilesState = {
  profile: null,
  errorGetAllProfile: {
    error: {
      title: '',
      status: null,
      detail: {},
      code: null
    },
    success: null
  },
  userEmails: [],
  registerEmailErrors: {}
};

const reducers = createReducer(
  initiaProfileslState,
  on(profilesActions.updateProfilesbyId, (state, action) => ({
    ...state,
    userIdEdit: action.profile?.id
  })),
  on(profilesActions.updateProfilesbyIdSucess, (state, action) => ({
    ...state
  })),
  on(profilesActions.getAllProfilesSucces, (state, action) => ({
    ...state,
    profile: action.profile,
  })),
  on(profilesActions.errorConf, (state, action) => ({
    ...state,
    errorGetAllProfile: action.error
  })),
  on(profilesActions.getEmailsSuccess, (state, action) => ({
    ...state,
    userEmails: action.payload
  })),
  on(profilesActions.registerEmailError, (state, action) => ({
    ...state,
    registerEmailErrors: action.payload.error
  }))
);

export function profileReducer(state: IProfilesState, action: Action) {
  return reducers(state, action);
}
