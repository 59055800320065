<div *ngIf="!isDocumentUpload" class="input-container" fileDnD>
  <label *ngIf="!minimal" class="main-label">{{ labelTag }}</label>
  
  <label  class="file-input">
    <!-- <span class="gdx-mat-button">{{ currentButtonLabel() }}</span> -->

    <div>
      <img class="image-upload" src="../../../../assets/icon/inbox.svg">
    </div>
    <div class="text-description">
      <span>{{
        'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE_DESCRIPTION' | translate
      }} </span>
    </div>
    <div class="text-info-file">
      {{ 'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE_FORMATS' | translate }}
      <span *ngFor="let fType of fileTypes; let i = index">
        {{ fType }} {{i === fileTypes.length -1 ? '' : ',&nbsp;' }}</span>
    </div>
    


    <div class="internal-label">
      <input *ngIf="captureCamera" #input type="file" class="input" [accept]="acceptString()" capture />
      <input *ngIf="!captureCamera" #input type="file" class="input" [accept]="acceptString()" />
      
    </div>
  </label>
</div>

<div class="div-file-upload" *ngIf="isDocumentUpload">
  <div nz-row>
    <div nz-col nzMd="20" nzXs="24" nzSm="24" nzLg="20" nzXl="20">
      <div class="position-file-upload name-type-document">
        <i *ngIf="typeDocument === 'pdf'" class="fal fa-file-pdf icon blue"></i>
        <i *ngIf="typeDocument === 'docx'" class="far fa-file-word icon blue"></i>
        {{ fileName || currentFileName }}
      </div>
    </div>
    <div nz-col nzMd="4" nzXs="24" nzSm="24" nzLg="4" nzXl="4">
      <div (click)="updateDocumentUpload()" 
        class="position-file-upload "
      >
        <i class="fal fa-trash-alt icon-trash"></i>
      </div>
    </div>
  </div>
</div>
