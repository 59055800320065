import { AppUtils } from '@literax/utils/app.utils'
import { pki, asn1, util, md } from 'node-forge';

export function openCertificate(certFileBase64: string): any {
  return pki.certificateFromPem(derToPem('CERTIFICATE', certFileBase64))
}

export function derToPem(tag: string, derB64: string): string {
  const base64File = derB64.split(',');
  const pemChunks = base64File.length >= 1 ? base64File[1] : base64File[0];
  return pemBox(tag, AppUtils.scan(pemChunks, /.{1,64}/g));
}

export function findRegexIntoCertAttributes(certPEM: any, regex: any): string {
  const node = certPEM.subject.attributes.find((node) => node.value.match(regex))
  if (node) {
    return node.value
  }

  return '';
}

export function pemBox(tag: string, lines: string[]): string {
  return `-----BEGIN ${tag}-----\n${lines.join("\n")}\n-----END ${tag}-----`;
}

export function getPrivateKeyFromFile(keyFile: string, password: string) {
  if (!keyFile || !password || password === '' || keyFile === '') {
    return false;
  }

  const pkeyDer = util.decode64(keyFile);
  const pkeyAsn1 = asn1.fromDer(pkeyDer);

  try {
    const privateKeyInfo = pki.decryptPrivateKeyInfo(pkeyAsn1, password);
    if (!privateKeyInfo) {
      return false;
    }
    return pki.privateKeyFromAsn1(privateKeyInfo);
  } catch (error) {
    return false;
  }
}

export function sha256(data: string) {
  const messageDigest = md.sha256.create();
  return messageDigest.update(data, 'utf8');
}

export function openPrivateKey(fileBase64: string, password: string): any {
  if (!fileBase64) {
    throw 'invalid file';
  }

  const chunks = fileBase64.split(',');
  const keyFile = chunks.length >= 2 ? chunks[1] : chunks[0];
  const privateKey = getPrivateKeyFromFile(keyFile, password);

  if (!privateKey) {
    throw 'invalid password';
  }

  return privateKey;
}


export function sign(dataToSign: string, keyFile: string, password: string, convertDataToBase64: boolean = false): string | null {
  if (!dataToSign || !keyFile) {
    return null;
  }

  if (convertDataToBase64) {
    dataToSign = util.encode64(dataToSign);
  }

  const privateKey = openPrivateKey(keyFile, password);
  return util.encode64(privateKey.sign(sha256(dataToSign)));
}


