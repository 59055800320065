import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[onDragEnd]'
})
export class OnDragEndDirective {
  @Output() onDragEnd = new EventEmitter();

  @HostListener('dragend', ['$event']) public onDragEndDirective(event: any) {
    event.stopPropagation();
    this.onDragEnd.emit(event);
  }
}
