import {
  FieldsOnlyOffice,
  FieldsOnlyOfficeV3,
  IProfile,
  IQueryParameters,
  ITemplate,
  ITemplateById,
  ITemplateKinds,
  ITemplateProfile,
  ITemplates,
  initialITemplateKinds,
} from '@literax/models/templates';
import {
  FilterParams,
  TemplateCollector,
  TemplateFieldData,
  TemplateSigner,
} from '@literax/models/templates-reports.model';
import { IPagination, IPaginationV3 } from '@literax/models/pagination.model';

import { ColumnSort } from '@literax/models/table.model';
import { ETemplateProcessKind } from '@literax/enums/template.enum';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ITemplatesFields } from '@literax/models/templates-fields.model';
import { Process } from '@literax/models/process.model';

export interface ITemplatesState {
  updated: ITemplates;//v2
  templates: ITemplates[];//v2
  latest: ITemplates[];//v2
  templateFields: ITemplatesFields[];//v2
  fieldsOnlyOffice: FieldsOnlyOffice[];//v2
  templateUpdate: ITemplates;//v2
  error: IAPIResponseError;//v2
  currentPage: number;//v2
  pagination: IPagination;//v2
  templateFieldsCurrentPage: number;//v2
  flows: Process;//v2
  selectedFlow: string;//v2
  sortColumn: string;//v2
  currentDepartment: number;//v2
  kind: string;//v2
  templateKinds: ITemplateKinds;//v2
  metadataTemplateProfiles: ITemplateProfile[];
  metadataTemplateProfilesCurrentPage: number;
  profiles: IProfile[];
  templateFieldData: TemplateFieldData;
  templateFieldDataCurrentPage: number;
  templateFieldDataCurrentSort: ColumnSort[];
  templateFieldDataActiveFilter: FilterParams;
  collectors: TemplateCollector[];
  signers: TemplateSigner[];
  queryParameters: IQueryParameters;
  templatesV3: ITemplate[];
  paginationV3: IPaginationV3;
  templateById: ITemplateById;
  templateUpdateV3:ITemplateById;
  fieldsOnlyOfficeV3: FieldsOnlyOfficeV3[];
  kindV3: ETemplateProcessKind | string;
  forceDepartments: boolean;
}

export const initialTemplatesState: ITemplatesState = {
  updated: {},
  templates: [],
  latest: [],
  templateFields: [],
  fieldsOnlyOffice: [],
  templateUpdate: null,
  error: null,
  currentPage: 1,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    total_count: null,
    total_pages: null,
  },
  templateFieldsCurrentPage: 1,
  selectedFlow: 'template_draft',
  flows: null,
  sortColumn: 'created_at:desc',
  currentDepartment: null,
  kind: '',
  templateKinds: initialITemplateKinds,
  metadataTemplateProfiles: [],
  metadataTemplateProfilesCurrentPage: 1,
  profiles: [],
  templateFieldData: null,
  templateFieldDataCurrentPage: 1,
  templateFieldDataCurrentSort: [],
  templateFieldDataActiveFilter: null,
  collectors: [],
  signers: [],
  queryParameters: {
    pageNumber: 1,
    pageSize: 10,
    sortField: null,
    filterFields: [],
  },
  templatesV3: [],
  paginationV3: null,
  templateById: null,
  templateUpdateV3: null,
  fieldsOnlyOfficeV3: null,
  kindV3: null,
  forceDepartments: false
};
