import { Action, createReducer, on } from '@ngrx/store';
import {
  initialPromissoryNoteReportState,
  IPromissoryNoteReportState,
} from '@literax/store/report/report-promissory-note.state';
import * as reportPromissoryNoteAction from '@literax/store/report/report-promissory-note.actions';
import { reduceBeneficieariesData } from './promissory-note-utils';

const reducer = createReducer(
  initialPromissoryNoteReportState,
  on(reportPromissoryNoteAction.GetInfoReportSuccess, (state, action) => ({
    ...state,
    promissory_note_report: action.payload,
    beneficiaries: reduceBeneficieariesData(action.payload.beneficiaries),
  })),
  on(reportPromissoryNoteAction.GetInfoReportReset, (state, action) => ({
    ...state,
    promissory_note_report: null,
  })),
  on(reportPromissoryNoteAction.GetReportDetailSuccess, (state, action) => ({
    ...state,
    promissory_note_report_detail: action.payload,
  })),
  on(reportPromissoryNoteAction.GetReportDetailReset, (state, action) => ({
    ...state,
    promissory_note_report_detail: null,
  })),
  on(
    reportPromissoryNoteAction.GetFilterBeneficiariesSuccess,
    (state, action) => ({
      ...state,
      promissory_note_report_beneficiaries_filter: action.payload,
    })
  ),
  on(
    reportPromissoryNoteAction.GetFilterBeneficiariesReset,
    (state, action) => ({
      ...state,
      promissory_note_report_beneficiaries_filter: null,
    })
  ),
  on(
    reportPromissoryNoteAction.GetFiltersuscriptorSuccess,
    (state, action) => ({
      ...state,
      promissory_note_report_suscriptor_filter: action.payload,
    })
  ),
  on(reportPromissoryNoteAction.GetFiltersuscriptorReset, (state, action) => ({
    ...state,
    promissory_note_report_suscriptor_filter: null,
  }))
);

export function reportPromissoryNoteReducers(
  state: IPromissoryNoteReportState,
  action: Action
) {
  return reducer(state, action);
}
