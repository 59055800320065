import * as termsFreeActions from '@literax/store/free-terms/free-terms.actions';

import { Action, createReducer, on } from "@ngrx/store";
import { IFreeTermsState, initialFreeTermsState } from './free-terms.state';

const freeTermReducer = createReducer(
  initialFreeTermsState,
  on(termsFreeActions.GetTermsFreeSuccess, (state, action) => ({
    ...state, terms_of_service: action.payload
  })),
);

export function freeTermsReducers(
  state: IFreeTermsState,
  action: Action
) {
  return freeTermReducer(state, action);
}
