import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'translateMomentDate'
})
export class TranslateMomentDatePipe implements PipeTransform {
  private isValidFormat = (format: string) =>
    moment(moment().format(format)).isValid();

  constructor(private translateService: TranslateService) {
    moment.locale(this.translateService.currentLang);
  }

  transform(value: Date, inputFormat?: string): string {
    const format =
      inputFormat && this.isValidFormat(inputFormat) ? inputFormat : 'LLLL';

    const formattedDate =
      value && moment(value).isValid() ? moment(value).format(format) : '';

    return formattedDate;
  }
}
