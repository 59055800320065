import { Action, createReducer, on } from '@ngrx/store';
import { AuthState, initialAuthState } from './auth.state';
import {
  ClearProfileErrors,
  CurrentUserUpdateCertificateError,
  CurrentUserUpdateCertificateSuccess,
  CurrentUserUpdateEmailError,
  CurrentUserUpdateEmailSuccess,
  GetLoginInfoSuccess,
  LogoutSuccess,
  SetCurrentClient,
  SetCurrentClientToken,
  UpdateProfileInfo,
} from './auth.actions';

import { IProfile } from '@literax/components/configurations/profiles/models/profiles';

const extractUserInfo = (info: IProfile): any => {
  const { cert_name, image_url } = info;
  const { first_name, second_name, surname, mother_surname } =
    info.personal_info;
  return {
    cert_name,
    image_url,
    first_name,
    second_name,
    surname,
    mother_surname,
  };
};

const reducers = createReducer(
  initialAuthState,
  on(LogoutSuccess, (state, action) => ({
    ...state,
    currentUser: null,
    loginError: null,
  })),
  on(GetLoginInfoSuccess, (state, action) => ({
    ...state,
    currentUser: action.payload
  })),
  on(SetCurrentClient, (state, action) => ({
    ...state,
    currentUser: action.payload as IProfile,
  })),
  on(UpdateProfileInfo, (state, action) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      ...extractUserInfo(action.payload),
    },
  })),
  on(CurrentUserUpdateCertificateSuccess, (state, action) => ({
    ...state,
    currentUser: action.payload,
    updateProfileError: null,
  })),
  on(CurrentUserUpdateEmailSuccess, (state, action) => ({
    ...state,
    currentUser: action.payload,
    updateProfileError: null,
  })),
  on(CurrentUserUpdateCertificateError, (state, action) => ({
    ...state,
    updateProfileError: action.payload,
  })),
  on(CurrentUserUpdateEmailError, (state, action) => ({
    ...state,
    updateProfileError: action.payload,
  })),
  on(ClearProfileErrors, (state, action) => ({
    ...state,
    updateProfileError: null,
  })),
  on(SetCurrentClientToken, (state, action) => ({
    ...state,
    accessToken: action.payload.token,
  }))
);

export function authReducers(state: AuthState, action: Action) {
  return reducers(state, action);
}
