import * as attachmentActions from './attachment.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  catchError,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { AttachmentsService } from '@literax/services/platform/attachments.service';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IAppState } from '../../../../store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { ICreateAttachmentResponse } from '@literax/models/http/Attachment/create-attachment.model';
import { Injectable } from '@angular/core';
import { LoadingService } from '@literax/services/loading/loading.service';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { TranslateService } from '@ngx-translate/core';
import { UpdateSelectedDocument } from '../document/document.actions';
import { selectAttachment } from './attachment.selectors';
import { selectCurrentLang } from '@literax/modules/configurations/store/config/config.selectors';
import { LiteraxNotificationServiceService } from '@literax/services/literax-notification-service.service';

@Injectable()
export class AttachmentEffects {
  constructor(
    private attachmentService: AttachmentsService,
    private actions$: Actions,
    private store$: Store<IAppState>,
    private toastr: LiteraxNotificationServiceService,
    private loading: LoadingService,
    private translate: TranslateService,
    private toast: I18nToastrService
  ) {}

  createAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.createAttachment),
      switchMap((action) =>
        this.attachmentService
          .createAttachment(action.documentId, action.data)
          .pipe(
            map((response: ICreateAttachmentResponse) =>
              attachmentActions.createAttachmentSuccess({ payload: response })
            ),
            catchError((err: HttpErrorResponse) => {
              this.toastr.error(err.error.error.detail['name'][0]);
              return of(attachmentActions.createAttachmentError(err.error));
            })
          )
      )
    )
  );

  createAttachmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(attachmentActions.createAttachmentSuccess),
        tap(() => this.store$.dispatch(UpdateSelectedDocument()))
      ),
    { dispatch: false }
  );

  updateAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.updateAttachment),
      switchMap((action) =>
        this.attachmentService
          .updateAttachment(action.documentId, action.attachmentId, action.data)
          .pipe(
            map((response: ICreateAttachmentResponse) =>
              attachmentActions.updateAttachmentSuccess({ payload: response })
            ),
            catchError((err: HttpErrorResponse) =>
              of(attachmentActions.updateAttachmentError(err.error))
            )
          )
      )
    )
  );

  updateAttachmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.updateAttachmentSuccess),
      map(() => UpdateSelectedDocument())
    )
  );

  deleteAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.deleteAttachment),
      switchMap((action) =>
        this.attachmentService
          .deleteAttachment(action.documentId, action.attachmentId)
          .pipe(
            map((response: ServiceResponse) =>
              attachmentActions.deleteAttachmentSuccess()
            ),
            catchError((err: HttpErrorResponse) =>
              of(attachmentActions.deleteAttachmentError(err.error.error))
            )
          )
      )
    )
  );

  deleteAttachmentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(attachmentActions.deleteAttachmentSuccess),
        tap(() => this.store$.dispatch(UpdateSelectedDocument()))
      ),
    { dispatch: false }
  );

  checkAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.checkAttachment),
      tap(() => {
        this.loading.show();
      }),
      switchMap((action) =>
        this.attachmentService
          .checkAttachment(
            action.payload.documentId,
            action.payload.attachmentId,
            action.payload.signRequestId
          )
          .pipe(
            map((response: ServiceResponse) =>
              attachmentActions.checkAttachmentSuccess({
                payload: response.attachment
              })
            ),
            catchError((err: HttpErrorResponse) => {
              this.loading.hide();
              this.getLanguage();
              forkJoin([
                this.translate.get(
                  'DOCUMENT-PREVIEW.ATTACHMENTS.CHECK_ATTACHMENTS_ERROR'
                ),
                this.translate.get('DOCUMENT-PREVIEW.DOCUMENTS-TAB.ATTACHMENTS')
              ]).subscribe(([message, title]) => {
                this.toast.error(message, title);
              });
              return of(
                attachmentActions.checkAttachmentError({
                  payload: err.error as IAPIResponseError
                })
              );
            }),
            finalize(() => this.loading.hide())
          )
      )
    )
  );

  getLanguage = () =>
    this.store$
      .pipe(select(selectCurrentLang))
      .subscribe((lang) => this.translate.use(lang));
}
