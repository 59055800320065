import {
  IFolder,
  IPromissoryNoteCountersResponse,
  IPromissoryNoteList,
  IPromissoryNotes,
  ISavePromissoryNoteTemplate,
  PromissoryNotePayment
} from '@literax/models/promissory-notes.model';
import {
  IQueryParametersPromissoryNote,
  IQueryParametersPromissoryNoteCounters
} from '@literax/interfaces/query-parameters.interface';
import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ICreateAttachment } from '@literax/models/http/Attachment/create-attachment.model';
import { ICreateDocumentRequest } from '@literax/models/document.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IDocumentDownload } from '../../../models/http/document/document.model';
import { IFilterParams } from './promissory-notes.state';
import { IPagination } from '@literax/models/pagination.model';
import { IPromissoryNoteTemplate } from '../../../models/promissory-notes.model';

export enum EPromissoryNotesActions {
  GetDataConfig = '[Promissory Notes] GetDataConfig',
  GetDataConfigSuccess = '[Promissory Notes] GetDataConfigSuccess',
  GetDataConfigError = '[Promissory Notes] GetDataConfigError',
  GetDataConfigEndoserment = '[Promissory Notes] GetDataConfigEndoserment',
  GetDataConfigEndosermentSuccess = '[Promissory Notes] GetDataConfigEndosermentSuccess',
  GetDataConfigEndosermentError = '[Promissory Notes] GetDataConfigEndosermentError',
  GetDataConfigAction = '[Promissory Notes] GetDataConfigAction',
  SavePromissoryNotes = '[Promissory Notes] SavePromissoryNotes',
  SavePromissoryNotesSuccess = '[Promissory Notes] SavePromissoryNotesSuccess',
  SavePromissoryNotesError = '[Promissory Notes] SavePromissoryNotesError',
  updatePromissoryNotes = '[Promissory Notes] UpdatePromissoryNotes',
  updatePromissoryNotesSuccess = '[Promissory Notes] UpdatePromissoryNotesSuccess',
  updatePromissoryNotesError = '[Promissory Notes] updatePromissoryNotesError',
  GetPromissoryNote = '[Promissory Note] GetPromissoryNote',
  GetPromissoryNoteSuccess = '[Promissory Note] GetPromissoryNoteSuccess',
  GetPromissoryNoteError = '[Promissory Note] GetPromissoryNoteError',
  ResetPage = '[Promissory Notes] ResetPage',
  GetAllPromissoryNotesError = '[Promissory Notes] getAllPromissoryNotesError',
  GetPromissoryView = '[Promissory Notes] getPromissoryView',

  SavePromissoryNotePayment = '[PromissoryNotes] SavePromissoryNotePayment',
  SavePromissoryNotePaymentSuccess = '[PromissoryNotes] SavePromissoryNotePaymentSuccess ',
  SavePromissoryNotePaymentError = '[PromissoryNotes] SavePromissoryNotePaymentError ',
  GetCurrency = '[Promissory Notes] GetCurrensy',
  GetCurrencySuccess = '[Promissory Notes] GetCurrencySuccess',
  GetCurrencyError = '[Promissory Notes] GetCurrencyError',
  GetPayments = '[Promissory Notes] GetPayments',
  GetPaymentsSuccess = '[Promissory Notes] GetPaymentsSuccess',
  GetPaymentsError = '[Promissory Notes] GetPaymentsError',
  ShowPayment = '[ Promissory Notes] ShowPayment',
  ShowPaymentSuccess = '[ Promissory Notes] ShowPaymentSuccess',
  ShowPaymentError = '[ Promissory Notes] ShowPaymentError',
  ClearSelectPayment = '[Promissory Notes] ClearSelectPayment',
  SendReminder = '[Promissory Notes] sendReminder',
  SendReminderSuccess = '[Promissory Notes] sendReminderSuccess',
  SendReminderError = '[Promissory Notes] SendReminderError',
  DownloadLifeCycleFolder = '[Promissory Notes] DownloadLifeCycleFolder',
  SaveBlobFromUrl = '[Promissory Notes] SaveBlobLifeCycleFolder',
  DownloadDocument = '[Promissory Notes] DownloadDocument',
  SaveBlobDocument = '[Document] SaveBlobDocument',
  GetPromissoryNoteTemplates = '[Promissory Notes] GetPromissoryNoteTemplates',
  GetPromissoryNoteTemplatesSuccess = '[Promissory Notes] GetPromissoryNoteTemplatesSuccess',
  GetPromissoryNoteTemplatesError = '[Promissory Notes] GetPromissoryNoteTemplatesError',
  DeletePromissoryNoteTemplates = '[Promissory Notes] DeletePromissoryNoteTemplates',
  DeletePromissoryNoteTemplatesSuccess = '[Promissory Notes] DeletePromissoryNoteTemplatesSuccess',
  DeletePromissoryNoteTemplatesError = '[Promissory Notes] DeletePromissoryNoteTemplatesError',

  CreatePromissoryNoteWithTemplate = '[Promissory Notes] create promissory note with template',
  CreatePromissoryNoteWithTemplateSuccess = '[Promissory Notes] create promissory note with template success',
  CreatePromissoryNoteWithTemplateError = '[Promissory Notes] create promissory note with template error',

  ResetPromissoryNoteTemplate = '[Promissory Notes] RestPromissoryNoteTemplate',
  SavePromissoryNoteTemplate = '[Promissory Notes] SavePromissoryNoteTemplate',
  SavePromissoryNoteTemplateSuccess = '[Promissory Notes] SavePromissoryNoteTemplateSuccess',
  SavePromissoryNoteTemplateError = '[Promissory Notes] SavePromissoryNoteTemplateError',
  GetPromissoryNoteTemplateShow = '[Promissory Notes] GetPromissoryNoteTemplateShow',
  GetPromissoryNoteTemplateShowSuccess = '[Promissory Notes] GetPromissoryNoteTemplateShowSuccess',
  GetPromissoryNoteTemplateShowError = '[Promissory Notes] GetPromissoryNoteTemplateShowError',
  GetPromissoryNoteTemplateShowReload = '[Promissory Notes] GetPromissoryNoteTemplateShowReload',
  EditPromissoryNoteTemplate = '[Promissory Notes] EditPromissoryNoteTemplate',
  EditPromissoryNoteTemplateSuccess = '[Promissory Notes] EditPromissoryNoteTemplateSuccess',
  EditPromissoryNoteTemplateError = '[Promissory Notes] EditPromissoryNoteTemplateError',

  SetTemplateId = '[Promissory Notes] set template id',
  ClearTemplateId = '[Promissory Notes] clear template id',

  ClearConfigData = '[Promissory ClearConfigData] clear configdata',

  //* Pagaré V3
  GetPromissoryNoteCounters = '[Promissory Note] Get Promissory Note Counters',
  GetPromissoryNoteCountersSuccess = '[Promissory Note] Get Promissory Note Counters Success',
  GetPromissoryNoteCountersError = '[Promissory Note] Get Promissory Note Counters Error',

  SetPromissoryNoteStatus = '[Promissory Note] Set Promissory Note Status',

  GetPromissoryNotes = '[Promissory Note] Get Promissory Notes',
  GetPromissoryNotesSuccess = '[Promissory Note] Get Promissory Notes Success',
  GetPromissoryNotesError = '[Promissory Note] Get Promissory Notes Error',

  ClearPromissoryNoteFilters = '[Promissory Note] Clear Promissory Note Filters',

  ExportPromissoryNoteList = '[Promissory Note] Export Promissory Note List'
}
export const SaveBlobDocument = createAction(
  EPromissoryNotesActions.SaveBlobDocument,
  props<{ payload: IDocumentDownload }>()
);

export const DownloadDocument = createAction(
  EPromissoryNotesActions.DownloadDocument,
  props<{ payload: string; flow: string }>()
);

export interface IDownloadLifeCycleFolderParams {
  id: number;
}

export interface IDownloadLifeCycleFolderPayload {
  payload: IDownloadLifeCycleFolderParams;
}

export const GetDataConfig = createAction(
  EPromissoryNotesActions.GetDataConfig,
  props<{ payload: string }>()
);

export const GetDataConfigSuccess = createAction(
  EPromissoryNotesActions.GetDataConfigSuccess,
  props<{ payload: IPromissoryNotes }>()
);

export const GetDataConfigError = createAction(
  EPromissoryNotesActions.GetDataConfigError,
  props<{ payload: IAPIResponseError }>()
);

export const GetDataConfigEndoserment = createAction(
  EPromissoryNotesActions.GetDataConfigEndoserment,
  props<{ payload: { promissoryNoteId: number; type: string } }>()
);

export const GetDataConfigEndosermentSuccess = createAction(
  EPromissoryNotesActions.GetDataConfigEndosermentSuccess,
  props<{ payload: IPromissoryNotes }>()
);

export const GetDataConfigEndosermentError = createAction(
  EPromissoryNotesActions.GetDataConfigEndosermentError,
  props<{ payload: IAPIResponseError }>()
);

export const SavePromissoryNotes = createAction(
  EPromissoryNotesActions.SavePromissoryNotes,
  props<{ payload: ICreateDocumentRequest }>()
);

export const SavePromissoryNotesSuccess = createAction(
  EPromissoryNotesActions.GetDataConfigSuccess,
  props<{ payload: IDocument }>()
);

export const SavePromissoryNotesError = createAction(
  EPromissoryNotesActions.SavePromissoryNotesError,
  props<{ payload: IAPIResponseError }>()
);

export const updatePromissoryNotes = createAction(
  EPromissoryNotesActions.updatePromissoryNotes,
  props<{
    payload: {
      promissory: ICreateAttachment;
      documentId: number | string;
      attachmentId: number | string;
    };
  }>()
);

export const updatePromissoryNotesSuccess = createAction(
  EPromissoryNotesActions.updatePromissoryNotesSuccess
);

export const updatePromissoryNotesError = createAction(
  EPromissoryNotesActions.updatePromissoryNotesError,
  props<{ payload: IAPIResponseError }>()
);

export const GetPromissoryNote = createAction(
  EPromissoryNotesActions.GetPromissoryNote,
  props<{ payload: { id: number; role: string, documentId: number, routePath: string } }>()
);

export const GetPromissoryNoteSuccess = createAction(
  EPromissoryNotesActions.GetPromissoryNoteSuccess,
  props<{ payload: IFolder }>()
);

export const GetPromissoryNoteError = createAction(
  EPromissoryNotesActions.GetPromissoryNoteError,
  props<{ payload: IAPIResponseError }>()
);

export const ResetPage = createAction(EPromissoryNotesActions.ResetPage);

export const DownloadLifeCycleFolder = createAction(
  EPromissoryNotesActions.DownloadLifeCycleFolder,
  props<IDownloadLifeCycleFolderPayload>()
);

export const SaveBlobFromUrl = createAction(
  EPromissoryNotesActions.SaveBlobFromUrl,
  props<{ payload: { url: string } }>()
);

export const GetAllPromissoryNotesError = createAction(
  EPromissoryNotesActions.GetAllPromissoryNotesError,
  props<{ payload: IAPIResponseError }>()
);

export const getPromissoryView = createAction(
  EPromissoryNotesActions.GetPromissoryView,
  props<{ payload: IFolder }>()
);

export const GetDataConfigAction = createAction(
  EPromissoryNotesActions.GetDataConfigAction,
  props<{
    payload: { promissoryNoteId: number; action: string; params?: any };
  }>()
);

export const savePromissoryNotePayment = createAction(
  EPromissoryNotesActions.SavePromissoryNotePayment,
  props<{
    payload: { promissoryNotePayment: PromissoryNotePayment; folderId: number };
  }>()
);

export const SavePromissoryNotePaymentSuccess = createAction(
  EPromissoryNotesActions.SavePromissoryNotePaymentSuccess,
  props<{ payload: PromissoryNotePayment }>()
);

export const GetCurrency = createAction(EPromissoryNotesActions.GetCurrency);

export const GetCurrencySuccess = createAction(
  EPromissoryNotesActions.GetCurrencySuccess,
  props<{ payload: [] }>()
);

export const GetCurrencyError = createAction(
  EPromissoryNotesActions.GetCurrencyError,
  props<{ payload: IAPIResponseError }>()
);

export const GetPayments = createAction(
  EPromissoryNotesActions.GetPayments,
  props<{ payload: { folderId: number } }>()
);

export const GetPaymentsSuccess = createAction(
  EPromissoryNotesActions.GetPaymentsSuccess,
  props<{ payload: PromissoryNotePayment[] }>()
);

export const GetPaymentsError = createAction(
  EPromissoryNotesActions.GetPaymentsError,
  props<{ payload: IAPIResponseError }>()
);

export const ShowPayment = createAction(
  EPromissoryNotesActions.ShowPayment,
  props<{ payload: { folderId: number; paymentId: number } }>()
);

export const ShowPaymentSuccess = createAction(
  EPromissoryNotesActions.ShowPaymentSuccess,
  props<{ payload: PromissoryNotePayment }>()
);

export const ShowPaymentError = createAction(
  EPromissoryNotesActions.ShowPaymentError,
  props<{ payload: IAPIResponseError }>()
);

export const ClearSelectPayment = createAction(
  EPromissoryNotesActions.ClearSelectPayment
);

export const SendReminder = createAction(
  EPromissoryNotesActions.SendReminder,
  props<{ payload: { documentId: number } }>()
);

export const SendReminderSuccess = createAction(
  EPromissoryNotesActions.SendReminderSuccess
);

export const SendReminderError = createAction(
  EPromissoryNotesActions.SendReminderError,
  props<{ payload: IAPIResponseError }>()
);

export const GetPromissoryNoteTemplates = createAction(
  EPromissoryNotesActions.GetPromissoryNoteTemplates,
  props<{ pagination?: IPagination; filters?: IFilterParams }>()
);

export const GetPromissoryNoteTemplatesSuccess = createAction(
  EPromissoryNotesActions.GetPromissoryNoteTemplatesSuccess,
  props<{
    payload: {
      promissoryNoteTemplates: IPromissoryNoteTemplate[];
      pagination: IPagination;
    };
  }>()
);

export const GetPromissoryNoteTemplatesError = createAction(
  EPromissoryNotesActions.GetPromissoryNoteTemplatesError,
  props<{ payload: IAPIResponseError }>()
);

export const DeletePromissoryNoteTemplates = createAction(
  EPromissoryNotesActions.DeletePromissoryNoteTemplates,
  props<{ payload: { promissoryNoteTemplateId: number } }>()
);

export const DeletePromissoryNoteTemplatesSuccess = createAction(
  EPromissoryNotesActions.DeletePromissoryNoteTemplatesSuccess
);

export const DeletePromissoryNoteTemplatesError = createAction(
  EPromissoryNotesActions.DeletePromissoryNoteTemplatesError,
  props<{ payload: IAPIResponseError }>()
);

export const ResetPromissoryNoteTemplate = createAction(
  EPromissoryNotesActions.ResetPromissoryNoteTemplate
);

export const CreatePromissoryNoteWithTemplate = createAction(
  EPromissoryNotesActions.CreatePromissoryNoteWithTemplate,
  props<{ payload: { templateId: number; multilanguage: boolean } }>()
);

export const CreatePromissoryNoteWithTemplateSuccess = createAction(
  EPromissoryNotesActions.CreatePromissoryNoteWithTemplateSuccess,
  props<{ payload: IPromissoryNotes }>()
);

export const CreatePromissoryNoteWithTemplateError = createAction(
  EPromissoryNotesActions.CreatePromissoryNoteWithTemplateError
);

export const SavePromissoryNoteTemplate = createAction(
  EPromissoryNotesActions.SavePromissoryNoteTemplate,
  props<{ payload: ISavePromissoryNoteTemplate }>()
);

export const SavePromissoryNoteTemplateSuccess = createAction(
  EPromissoryNotesActions.SavePromissoryNoteTemplateSuccess,
  props<{ payload: IPromissoryNoteTemplate }>()
);

export const SavePromissoryNoteTemplateError = createAction(
  EPromissoryNotesActions.SavePromissoryNoteTemplateError,
  props<{ payload: IAPIResponseError }>()
);

export const GetPromissoryNoteTemplateShow = createAction(
  EPromissoryNotesActions.GetPromissoryNoteTemplateShow,
  props<{ payload: { promissoryNoteTemplateId: string } }>()
);

export const GetPromissoryNoteTemplateShowSuccess = createAction(
  EPromissoryNotesActions.GetPromissoryNoteTemplateShowSuccess,
  props<{ payload: IPromissoryNoteTemplate }>()
);

export const GetPromissoryNoteTemplateShowError = createAction(
  EPromissoryNotesActions.GetPromissoryNoteTemplateShowError,
  props<{ payload: IAPIResponseError }>()
);

export const GetPromissoryNoteTemplateShowReload = createAction(
  EPromissoryNotesActions.GetPromissoryNoteTemplateShowReload
);

export const EditPromissoryNoteTemplate = createAction(
  EPromissoryNotesActions.EditPromissoryNoteTemplate,
  props<{
    payload: {
      promissoryNoteTemplateId: number;
      promissoryNoteTemplate: ISavePromissoryNoteTemplate;
    };
  }>()
);

export const EditPromissoryNoteTemplateSuccess = createAction(
  EPromissoryNotesActions.EditPromissoryNoteTemplateSuccess
);

export const EditPromissoryNoteTemplateError = createAction(
  EPromissoryNotesActions.SavePromissoryNoteTemplateError,
  props<{ payload: IAPIResponseError }>()
);

export const SetTemplateId = createAction(
  EPromissoryNotesActions.SetTemplateId,
  props<{ payload: number }>()
);

export const ClearTemplateId = createAction(
  EPromissoryNotesActions.ClearTemplateId,
  props<{ payload: number }>()
);

export const ClearConfigData = createAction(
  EPromissoryNotesActions.ClearConfigData
);

//* Pagaré V3
export const GetPromissoryNoteCounters = createAction(
  EPromissoryNotesActions.GetPromissoryNoteCounters,
  props<{ counterQueryParameters?: IQueryParametersPromissoryNoteCounters }>()
);

export const GetPromissoryNoteCountersSuccess = createAction(
  EPromissoryNotesActions.GetPromissoryNoteCountersSuccess,
  props<{ payload: IPromissoryNoteCountersResponse }>()
);

export const GetPromissoryNoteCountersError = createAction(
  EPromissoryNotesActions.GetPromissoryNoteCountersError,
  props<{ payload: any }>()
);

export const SetPromissoryNoteStatus = createAction(
  EPromissoryNotesActions.SetPromissoryNoteStatus,
  props<{ statusId: number }>()
);

export const GetPromissoryNotes = createAction(
  EPromissoryNotesActions.GetPromissoryNotes,
  props<{ queryParams?: IQueryParametersPromissoryNote }>()
);

export const GetPromissoryNotesSuccess = createAction(
  EPromissoryNotesActions.GetPromissoryNotesSuccess,
  props<{ payload: IPromissoryNoteList }>()
);

export const GetPromissoryNotesError = createAction(
  EPromissoryNotesActions.GetPromissoryNotesError,
  props<{ payload: any }>()
);

export const ClearPromissoryNoteFilters = createAction(
  EPromissoryNotesActions.ClearPromissoryNoteFilters,
  props<{ filterName?: string }>()
);

export const ExportPromissoryNoteList = createAction(
  EPromissoryNotesActions.ExportPromissoryNoteList
);
