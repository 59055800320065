import { ICatalogStatuses, IFlowCatalog, ISignRequestValidationType } from '@literax/models/catalog.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IReminder } from '@literax/models/reminder.model';

export interface ICatalogState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: object;
  catalogError: IAPIResponseError;
  flowCatalog: IFlowCatalog[];
  catalogStatuses:ICatalogStatuses[];
  reminders: IReminder[];
  signRequestValidationTypes: ISignRequestValidationType[];
}

export const initialCatalogState: ICatalogState = {
  values: {},
  catalogError: null,
  flowCatalog: [],
  catalogStatuses: [],
  reminders: [],
  signRequestValidationTypes: []
};
