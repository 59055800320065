import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-e404',
  templateUrl: './e404.component.html',
  styleUrls: ['./e404.component.scss'],
})
export class E404Component implements OnInit {  
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onBack() {
    this.router.navigate(['/validator/']);
  }
  onLogin() {
    this.router.navigate(['/auth/registration']);
  }

  onRegister() {
    this.router.navigate(['auth/registration']);
  }
}
