import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() name: string;
  @Input() urlImage: string;
  @Output() save: EventEmitter<string> = new EventEmitter<string>();
  @Input() control: FormControl;

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (fileLoadedEvent: any) => {
        this.urlImage = fileLoadedEvent.target.result.toString();
        this.save.emit(this.urlImage);
      };
    }
  }

  // ****************************************
  // NG LyfeCicle
  // ****************************************

  ngOnInit(): void {}

  constructor() {}
}
