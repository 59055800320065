import { ColumnSort, TableHeaders } from '@literax/models/table.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() headers: TableHeaders[] = [];
  @Input() rowsData: any[] = [];

  @Output() sortChange: EventEmitter<ColumnSort[]> = new EventEmitter<ColumnSort[]>();

  sortOrder = null;

  constructor() {}

  ngOnInit(): void {}

  /**
   * sortOrderChange
   * * Función para emitir el ordenamiento por columna
   * @param order Orden de la columna ('ascend' || 'descend' || null)
   * @param columnValue Columna a ordenar
  */
   sortOrderChange(order: string, columnValue: string) {
    let columnOrder;
    switch (order) {
      case 'ascend':
        columnOrder = ':asc';
        break;

      case 'descend':
        columnOrder = ':desc';
        break;
    
      default:
        columnOrder = '';
        break;
    }

    const sorts = {
      columnName: columnValue,
      direction: columnOrder,
      active: true,
    };

    this.sortChange.emit([sorts]);
  }
}
