<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="row error-404">
          <div class="col-12 text-center">
            <h1>{{ 'HTTP.ERRORS.NOT_FOUND.OOPS' | translate }}</h1>
            <h3>{{ 'HTTP.ERRORS.NOT_FOUND.MESSAGE' | translate }}</h3>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
