import * as registrationActions from './registration.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  IRegistrationState,
  initialRegistrationState
} from './registration.state';

const registrationReducer = createReducer(
  initialRegistrationState,
  on(
    registrationActions.validateClientSuccess,
    registrationActions.validateClientError,  
    (state, action) => ({ ...state, validateClient: action.validCustomer })
  ),
  on(registrationActions.getPlansSuccess,
    (state, action) => { 
      const orderedPlans = [...action.plans].sort((a, b) => a.price - b.price);
      return { ...state, plans: orderedPlans }
    }
  ),
  on(registrationActions.getPlanReferenceSuccess, (state, action) => ({ ...state, planReference: action.refResponse })),
  on(registrationActions.createClientSuccess, (state, action) => ({ ...state, clientCreation: true })),
  on(registrationActions.createClientError, (state, action) => ({ ...state, clientCreation: false })),
  on(registrationActions.cleanRegistrationState, (state, action) => ({ 
    ...state,
    validateClient: null,
    plans: null,
    planReference: null,
    clientCreation: null
  }))
);

export function registrationReducers(state: IRegistrationState | undefined, action: Action) {
  return registrationReducer(state, action);
}
