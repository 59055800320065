import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppState } from '@literax/store';
import { selectShowSignatureResult } from '../../store/validator.selector';
import { select, Store } from '@ngrx/store';


import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-validator-signers',
  templateUrl: './validator-signers.component.html',
  styleUrls: ['./validator-signers.component.scss'],
})
export class ValidatorSignersComponent implements OnInit, OnDestroy {
  signature$ = this.store.pipe(
    untilDestroyed(this),
    select(selectShowSignatureResult)
  );
  signers: any[] = [];
  constructor(private store: Store<IAppState>) {}

  ngOnInit() {
    this.generateSigners();
  }
  ngOnDestroy() {}

  generateSigners() {
    this.signature$.subscribe((response) => {
      this.signers = response.signatures;
    });
  }
}
