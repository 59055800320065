import * as reportActions from './report.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ReportService } from '@literax/services/report/report.service';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IReportFilter } from '@literax/models/reports.model';

@Injectable()
export class ReportEffects {
  constructor(
    private store: Store<IAppState>,
    private actions$: Actions,
    private reportServices: ReportService,
    private toastr: I18nToastrService
  ) {}

  getReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.GetReport),
      withLatestFrom(this.store.select((state) => state.report.month_filter)),
      withLatestFrom(this.store.select((state) => state.report.year_filter)),
      withLatestFrom(this.store.select((state) => state.report.user_department_filter)),
      withLatestFrom(this.store.select((state) => state.report.departments_filter)),
      withLatestFrom(this.store.select((state) => state.report.companies_filter)),
      withLatestFrom(this.store.select((state) => state.report.signers1_filter)),
      withLatestFrom(this.store.select((state) => state.report.signers2_filter)),
      switchMap(([[[[[[[action, month], year], userDepartment], departments], companies], signers1], signers2 ]) =>
        this.reportServices.getReport({
          month,
          year,
          departments,
          signers1,
          signers2,
          companies,
          user_department: userDepartment
        }).pipe(
          map((response: ServiceResponse) =>
            reportActions.GetReportSuccess({
              payload: response.statistic.data_results
            })
          ),
          catchError((error) =>
            of(reportActions.GetReportError({ payload: error }))
          )
        )
      )
    )
  );

  gerSigners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.GetSignersReport),
      switchMap((action) =>
        this.reportServices.getSigners(action.payload).pipe(
          map((response: ServiceResponse) =>
            reportActions.GetSignersSuccess({
              payload: response.autocomplete.results
            })
          ),
          catchError((error) =>
            of(reportActions.GetSignersError({ payload: error }))
          )
        )
      )
    )
  );

  downloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.ExportDocument),
      withLatestFrom(this.store.select((state) => state.report.month_filter)),
      withLatestFrom(this.store.select((state) => state.report.year_filter)),
      withLatestFrom(this.store.select((state) => state.report.user_department_filter)),
      withLatestFrom(this.store.select((state) => state.report.departments_filter)),
      withLatestFrom(this.store.select((state) => state.report.companies_filter)),
      withLatestFrom(this.store.select((state) => state.report.signers1_filter)),
      withLatestFrom(this.store.select((state) => state.report.signers2_filter)),
      switchMap(([[[[[[[action, month], year], userDepartment], departments], companies], signers1], signers2]) => {
        const filter: IReportFilter = {
          month,
          year,
          user_department: userDepartment,
          departments,
          companies,
          signers1,
          signers2
        };
        return this.reportServices.exportReport(action.payload, filter).pipe(
          tap((response: Blob) =>
            this.reportServices.saveDownloadFileAs(response, `${action.payload}.xlsx`)
          ),
          catchError((err: HttpErrorResponse) => {
            return of(reportActions.ExportDocumentError());
          })
        );
      })
    )
  );
}
