import { IApiDetailedError, IUpdateData } from '@literax/models/common';
import {
  ICreateSignRequest,
  IEditParticipants,
  IEmailHistory,
  ISignRequestQuote,
} from '@literax/models/participant.model';
import { createAction, props } from '@ngrx/store';

import { IDocumentNotifications, IDocumentResponse } from '@literax/models/document.model';
import { ParticipantUpdatePaths } from '@literax/enums/participant.enum';

export enum EParticipantActionTypes {
  add = '@workspace/add',
  addSuccess = '@workspace/addSuccess',
  addError = '@workspace/addError',

  update = '@workspace/update',
  updateSuccess = '@workspace/updateSuccess',
  updateError = '@workspace/updateError',

  remove = '@workspace/remove',
  removeSuccess = '@workspace/removeSuccess',
  removeError = '@workspace/removeError',

  addMultiples = '@workspace/addMultiples',
  addMultiplesSuccess = '@workspace/addMultiplesSuccess',
  addMultiplesError = '@workspace/addMultiplesError',

  updateMultiple = '@workspace/updateMultiple',
  updateMultipleSuccess = '@workspace/updateMultipleSuccess',
  updateMultipleError = '@workspace/updateMultipleError',

  removeMultiple = '@workspace/removeMultiple',
  removeMultipleSuccess = '@workspace/removeMultipleSuccess',
  removeMultipleError = '@workspace/removeMultipleError',

  getSignerEmailHistory = '@workspace/getSignerEmailHistory',
  getSignerEmailHistorySuccess = '@workspace/getSignerEmailHistorySuccess',
  getSignerEmailHistoryError = '@workspace/getSignerEmailHistoryError',

  sendSignerEmail = '@workspace/sendSignerEmail',
  sendSignerEmailSuccess = '@workspace/sendSignerEmailSuccess',
  sendSignerEmailError = '@workspace/sendSignerEmailError',

  getSignatureQuotes = '@workspace/getSignatureQuotes',
  getSignatureQuotesSuccess = '@workspace/getSignatureQuotesSuccess',
  getSignatureQuotesError = '@workspace/getSignatureQuotesError',

  sendEmailNotification = '@workspace/sendEmailNotification',
  sendEmailNotificationSuccess = '@workspace/sendEmailNotificationSuccess',
  sendEmailNotificationError = '@workspace/sendEmailNotificationError',
}

export const ParticipantActions = {
  add: createAction(
    EParticipantActionTypes.add,
    props<{ payload: { participant: Partial<ICreateSignRequest> } }>()
  ),
  addSuccess: createAction(
    EParticipantActionTypes.addSuccess,
    props<{ payload: { document?: IDocumentResponse } }>()
  ),
  addError: createAction(
    EParticipantActionTypes.addError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  update: createAction(
    EParticipantActionTypes.update,
    props<{
      payload: {
        id: number;
        updateData: Array<IUpdateData<ParticipantUpdatePaths>>;
      };
    }>()
  ),
  updateSuccess: createAction(
    EParticipantActionTypes.updateSuccess,
    props<{ payload: { document: IDocumentResponse } }>()
  ),
  updateError: createAction(
    EParticipantActionTypes.updateError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  remove: createAction(
    EParticipantActionTypes.remove,
    props<{ payload: number }>()
  ),
  removeSuccess: createAction(
    EParticipantActionTypes.removeSuccess,
    props<{ payload: { document: IDocumentResponse } }>()
  ),
  removeError: createAction(
    EParticipantActionTypes.removeError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  removeMultiple: createAction(
    EParticipantActionTypes.removeMultiple,
    props<{ payload: number[] }>()
  ),
  removeMultipleSuccess: createAction(
    EParticipantActionTypes.removeMultipleSuccess,
    props<{ payload: { document: IDocumentResponse } }>()
  ),
  removeMultipleError: createAction(
    EParticipantActionTypes.removeMultipleError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  addMultiple: createAction(
    EParticipantActionTypes.addMultiples,
    props<{ payload: { participants: Partial<ICreateSignRequest>[] } }>()
  ),
  addMultipleSuccess: createAction(
    EParticipantActionTypes.addMultiplesSuccess,
    props<{ payload: { document: IDocumentResponse } }>()
  ),
  addMultipleError: createAction(
    EParticipantActionTypes.addMultiplesError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  updateMultiple: createAction(
    EParticipantActionTypes.updateMultiple,
    props<{
      payload: IEditParticipants[];
    }>()
  ),
  updateMultipleSuccess: createAction(
    EParticipantActionTypes.updateMultipleSuccess,
    props<{ payload: { document: IDocumentResponse } }>()
  ),
  updateMultipleError: createAction(
    EParticipantActionTypes.updateMultipleError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  getSignerEmailHistory: createAction(
    EParticipantActionTypes.getSignerEmailHistory,
    props<{ payload: { signerId: number } }>()
  ),
  getSignerEmailHistorySuccess: createAction(
    EParticipantActionTypes.getSignerEmailHistorySuccess,
    props<{ payload: { signerId: number; history: IEmailHistory[] } }>()
  ),
  getSignerEmailHistoryError: createAction(
    EParticipantActionTypes.getSignerEmailHistoryError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  sendSignerEmail: createAction(
    EParticipantActionTypes.sendSignerEmail,
    props<{ payload: { signerId: number; name: string } }>()
  ),
  sendSignerEmailSuccess: createAction(
    EParticipantActionTypes.sendSignerEmailSuccess,
    props<{ payload: string }>()
  ),
  sendSignerEmailError: createAction(
    EParticipantActionTypes.sendSignerEmailError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  getSignatureQuotes: createAction(
    EParticipantActionTypes.getSignatureQuotes,
    props<{ payload: { searchTerm: string; type: 'email' | 'taxId' } }>()
  ),
  getSignatureQuotesSuccess: createAction(
    EParticipantActionTypes.getSignatureQuotesSuccess,
    props<{ payload: ISignRequestQuote[] }>()
  ),
  getSignatureQuotesError: createAction(
    EParticipantActionTypes.getSignatureQuotesError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),

  sendEmailNotification: createAction(
    EParticipantActionTypes.sendEmailNotification,
    props<{ payload: { notificationPayload: IDocumentNotifications } }>()
  ),
  sendEmailNotificationSuccess: createAction(
    EParticipantActionTypes.sendEmailNotificationSuccess,
    props<{ payload: string }>()
  ),
  sendEmailNotificationError: createAction(
    EParticipantActionTypes.sendEmailNotificationError,
    props<{ payload: { error: IApiDetailedError } }>()
  ),
};
