import { createAction, props } from '@ngrx/store';

export const ClearParams = createAction('[BackButton] ClearParams');

export const GoPreviousUrl = createAction(
  '[BackButton] GoPreviousUrl',
  props<{ hasAction: boolean; params?: any }>()
);

export const GoPreviousUrlSuccess = createAction(
  '[BackButton] GoPreviousUrlSuccess'
);
