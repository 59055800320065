import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-confirm-dialog-approval',
  templateUrl: './confirm-dialog-approval.component.html',
  styleUrls: ['./confirm-dialog-approval.component.scss'],
})
export class ConfirmDialogApprovalComponent implements OnInit {
  @Output() optionalConf = new EventEmitter();

  constancy = new FormGroup({
    enabled: new FormControl(false),
  });

  participants = new FormGroup({
    enabled: new FormControl(false),
  });

  constructor(private modal: NzModalService) {}

  ngOnInit(): void {}

  cancelAction(event: Event) {
    this.modal.closeAll();
  }

  nextAction(event: Event) {
    this.optionalConf.emit(
      {
        constancy: this.constancy.value.enabled,
        participants: this.participants.value.enabled
      }
    );
    this.modal.closeAll();
  }
}
