import {
  EDocumentStatus,
  EDocumentUpdatePaths,
  EWorkspaceDocumentTabs,
} from '@literax/enums/document.enum';
import {
  IAccessTokens,
  IAccessTokensRequest,
  IInfo,
} from '../../../../../components/configurations/users/models/users.interface';
import { IApiDetailedError, IUpdateData } from '@literax/models/common';
import {
  IDocumentResponse,
  IDownloadDoc,
  IVerificationType,
} from '@literax/models/document.model';
import { createAction, props } from '@ngrx/store';
import { IReminder } from '@literax/models/reminder.model';

export enum EDocumentActionTypes {
  getDocument = '@workspace/getDocument',
  getDocumentSuccess = '@workspace/getDocumentSuccess',
  getDocumentError = '@workspace/getDocumentError',

  markAsInProcess = '@workspace/markAsInProcess',
  markAsInProcessSuccess = '@workspace/markAsInProcessSuccess',
  markAsInProcessError = '@workspace/markAsInProcessError',

  updateDocument = '@workspace/updateDocument',
  updateDocumentSuccess = '@workspace/updateDocumentSuccess',
  updateDocumentError = '@workspace/updateDocumentError',

  download = '@workspace/download',
  downloadSuccess = '@workspace/downloadSuccess',
  downloadError = '@workspace/downloadError',

  getUserInfo = '@workspace/getUserInfo',
  getUserInfoSuccess = '@workspace/getUserInfoSuccess',
  getUserInfoError = '@workspace/getUserInfoError',

  getAcessTokens = '@workspace/getAcessTokens',
  getAcessTokensSuccess = '@workspace/getAcessTokensSuccess',
  getAcessTokensError = '@workspace/getAcessTokensError',

  getVerificationTypes = '@workspace/getVerificationTypes',
  getVerificationTypesSuccess = '@workspace/getVerificationTypesSuccess',
  getVerificationTypesError = '@workspace/getVerificationTypesError',
}

export const DocumentActions = {
  getDocument: createAction(
    EDocumentActionTypes.getDocument,
    props<{
      payload: {
        id: number;
        getAttachment?: boolean;
        token?: string;
        activateTab?: EWorkspaceDocumentTabs;
      };
    }>()
  ),
  getDocumentSuccess: createAction(
    EDocumentActionTypes.getDocumentSuccess,
    props<{
      payload: {
        document: IDocumentResponse;
        getAttachment?: boolean;
        token?: string;
      };
    }>()
  ),
  getDocumentError: createAction(
    EDocumentActionTypes.getDocumentError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),

  updateDocument: createAction(
    EDocumentActionTypes.updateDocument,
    props<{
      payload: { data: Array<IUpdateData<EDocumentUpdatePaths>> };
    }>()
  ),

  updateDocumentSuccess: createAction(
    EDocumentActionTypes.updateDocumentSuccess,
    props<{ payload: { document: Partial<IDocumentResponse> } }>()
  ),

  updateDocumentError: createAction(
    EDocumentActionTypes.updateDocumentError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),
  download: createAction(EDocumentActionTypes.download),
  downloadSuccess: createAction(
    EDocumentActionTypes.downloadSuccess,
    props<{ payload: IDownloadDoc }>()
  ),
  downloadError: createAction(
    EDocumentActionTypes.downloadError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),

  getUserInfo: createAction(
    EDocumentActionTypes.getUserInfo,
    props<{ payload: { email: string; token: string } }>()
  ),

  getUserInfoSuccess: createAction(
    EDocumentActionTypes.getUserInfoSuccess,
    props<{ payload: { info: IInfo } }>()
  ),

  getUserInfoError: createAction(
    EDocumentActionTypes.getUserInfoError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),

  getAccessTokens: createAction(
    EDocumentActionTypes.getAcessTokens,
    props<{
      payload: { body: IAccessTokensRequest; token: string };
    }>()
  ),

  getAccessTokensSuccess: createAction(
    EDocumentActionTypes.getAcessTokensSuccess,
    props<{ payload: { tokens: IAccessTokens } }>()
  ),

  getAcessTokensError: createAction(
    EDocumentActionTypes.getAcessTokensError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),
  getVerificationTypes: createAction(EDocumentActionTypes.getVerificationTypes),
  getVerificationTypesSuccess: createAction(
    EDocumentActionTypes.getVerificationTypesSuccess,
    props<{ payload: IVerificationType[] }>()
  ),
  getVerificationTypesError: createAction(
    EDocumentActionTypes.getVerificationTypesError,

    props<{ payload: { error: IApiDetailedError | string } }>()
  ),
};
