import { Component, OnInit } from '@angular/core';
import {
  EDocumentStatus,
  EWorkspaceDocumentTabs,
} from '@literax/enums/document.enum';

export interface IWorkspaceTab {
  title: string;
  icon: string;
  id: EWorkspaceDocumentTabs;
  status?: Array<string>;
  visible: boolean;
}

export const WorkspaceTabs: any[] = [
  {
    title: 'WORKSPACE.UI.TABICONS.DOCUMENT',
    icon: 'fal fa-file',
    id: EWorkspaceDocumentTabs.DOCUMENT,
    status: [
      EDocumentStatus.DRAFT,
      EDocumentStatus.DATA_COLLECTION,
      EDocumentStatus.IN_SIGN,
      EDocumentStatus.APPROVAL,
      EDocumentStatus.SIGNED,
      EDocumentStatus.REVIEW,
      EDocumentStatus.REVIEWED,
      EDocumentStatus.IN_EFFECT,
      EDocumentStatus.APPROVED,
      EDocumentStatus.EXPIRED,
      EDocumentStatus.IN_PROCESS,
    ],
    visible: true,
  },
  {
    title: 'WORKSPACE.UI.TABICONS.ATTACHMENTS',
    icon: 'fal fa-paperclip',
    id: EWorkspaceDocumentTabs.ATTACHMENTS,
    status: [
      EDocumentStatus.DRAFT,
      EDocumentStatus.DATA_COLLECTION,
      EDocumentStatus.IN_SIGN,
      EDocumentStatus.APPROVAL,
      EDocumentStatus.SIGNED,
      EDocumentStatus.REVIEW,
      EDocumentStatus.REVIEWED,
      EDocumentStatus.REJECTED,
      EDocumentStatus.IN_EFFECT,
      EDocumentStatus.APPROVED,
      EDocumentStatus.SENT,
      EDocumentStatus.EXPIRED,
      EDocumentStatus.IN_PROCESS,
    ],
    visible: true,
  },
  {
    title: 'WORKSPACE.UI.TABICONS.SIGN',
    icon: 'fal fa-users',
    id: EWorkspaceDocumentTabs.PARTICIPANTS,
    status: [
      EDocumentStatus.DRAFT,
      EDocumentStatus.DATA_COLLECTION,
      EDocumentStatus.IN_SIGN,
      EDocumentStatus.APPROVAL,
      EDocumentStatus.SIGNED,
      EDocumentStatus.REVIEW,
      EDocumentStatus.REVIEWED,
      EDocumentStatus.REJECTED,
      EDocumentStatus.IN_EFFECT,
      EDocumentStatus.APPROVED,
      EDocumentStatus.SENT,
      EDocumentStatus.EXPIRED,
      EDocumentStatus.IN_PROCESS,
    ],
    visible: true,
  },
  {
    title: 'WORKSPACE.UI.TABICONS.COMMENT',//chat
    icon: 'fal fa-comments',
    id: EWorkspaceDocumentTabs.CHAT,
    status: [
      EDocumentStatus.DATA_COLLECTION,
      EDocumentStatus.IN_SIGN,
      EDocumentStatus.APPROVAL,
      EDocumentStatus.REVIEW,
      EDocumentStatus.SENT,
      EDocumentStatus.IN_PROCESS,
    ],
    visible: false,
  },
  {
    title: 'WORKSPACE.UI.TABICONS.INFO',
    icon: 'fal fa-info-circle',
    id: EWorkspaceDocumentTabs.INFO,
    status: [
      EDocumentStatus.DRAFT,
      EDocumentStatus.DATA_COLLECTION,
      EDocumentStatus.IN_SIGN,
      EDocumentStatus.APPROVAL,
      EDocumentStatus.SIGNED,
      EDocumentStatus.REVIEW,
      EDocumentStatus.REVIEWED,
      EDocumentStatus.REJECTED,
      EDocumentStatus.IN_EFFECT,
      EDocumentStatus.APPROVED,
      EDocumentStatus.SENT,
      EDocumentStatus.EXPIRED,
      EDocumentStatus.IN_PROCESS,
    ],
    visible: true,
  },
  {
    title: 'WORKSPACE.UI.TABICONS.EDIT',
    icon: 'fal fa-pencil-alt',
    id: EWorkspaceDocumentTabs.METADATA,
    status: [
      EDocumentStatus.DRAFT,
      EDocumentStatus.DATA_COLLECTION,
      EDocumentStatus.IN_SIGN,
      EDocumentStatus.IN_EFFECT,
    ],
    visible: true,
  },
];

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-workspace-layout',
  templateUrl: './workspace-layout.component.html',
  styleUrls: ['./workspace-layout.component.scss'],
})
export class WorkspaceLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
