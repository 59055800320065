import { BaseService } from '@literax/services/base-config/base.service';
import { IFilterPromissoryNoteDetail } from '@literax/models/report-promissory-note.model';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReportPromissoryNoteService {

  constructor(private base: BaseService) { }

  //TODO: obtencion de infomacion de pantalla dashboard de pagare
  getInfoReport(filter: IFilterPromissoryNoteDetail = null, isFilter: boolean = false) {

    let url = '';
    let isFirtsQuery = false;

    if(isFilter){
      url = '/promissory_notes/report?';
      if(filter.beneficiary !== ''){
        isFirtsQuery = true;
        url = `${url}beneficiary=${filter.beneficiary}`; 
      }

      if(filter.suscriptor !== ''){

        if(isFirtsQuery){
          url = `${url}&suscriptor=${filter.suscriptor}`;
        }else{
          url = `${url}suscriptor=${filter.suscriptor}`;
          isFirtsQuery = true;
        }
      }

      if(filter.expiry){
        if(isFirtsQuery){
          url = `${url}&start_date=${filter.expiry}`;
        }
        else{
          url = `${url}start_date=${filter.expiry}`;
          isFirtsQuery = true;
        }
      }


    }
    else{
      url = '/promissory_notes/report';
    }
    return this.base.get(url);
  }

  //TODO: obtener detalle de reporte
  getDetailReport(filter: IFilterPromissoryNoteDetail = null, exportFile: string = '') {
    let url = `/promissory_notes/details`;
    let isFirtsQuery = false;
    if(exportFile !== ''){
      url = `/promissory_notes/details.xlsx`;
    }

    if(filter) {
      url = `/promissory_notes/details?`;
      if(filter.beneficiary !== ''){
        isFirtsQuery =true;  
       url = `${url}beneficiary=${filter.beneficiary}`; 
      }
      if(filter.suscriptor !== ''){
        if(isFirtsQuery){
          url = `${url}&suscriptor=${filter.suscriptor}`;
        }
        else{
          url = `${url}suscriptor=${filter.suscriptor}`;
          isFirtsQuery =true;  
        } 
        
        
      }
      if(filter.expiry !== ''){
        
        if(isFirtsQuery){
          url = `${url}&start_date=${filter.expiry}`;
        }else{
          url = `${url}start_date=${filter.expiry}`;
          isFirtsQuery = true;
        }
      }
      if(filter.expiryEnd !== ''){
        if(isFirtsQuery){
          url = `${url}&end_date=${filter.expiryEnd}`;
        }
        else{
          url = `${url}end_date=${filter.expiryEnd}`;
        }
        
        
      }
    }
    
    return this.base.get(url);
  }

  getDetailReportDownload(filter: IFilterPromissoryNoteDetail = null, exportFile: string = '') {

    let url = `/promissory_notes/details`;
    let isFirtsQuery = false;
    if(exportFile !== ''){
      url = `/promissory_notes/details.xlsx?`;
    }
    
    if(filter) {
      
      if(filter.beneficiary !== ''){
        isFirtsQuery =true;  
       url = `${url}beneficiary=${filter.beneficiary}`; 
      }
      if(filter.suscriptor !== ''){
        if(isFirtsQuery){
          url = `${url}&suscriptor=${filter.suscriptor}`;
        }
        else{
          url = `${url}suscriptor=${filter.suscriptor}`;
          isFirtsQuery =true;  
        } 
        
        
      }
      if(filter.expiry !== ''){
        
        if(isFirtsQuery){
          url = `${url}&start_date=${filter.expiry}`;
        }else{
          url = `${url}start_date=${filter.expiry}`;
          isFirtsQuery = true;
        }
      }
      if(filter.expiryEnd !== ''){
        if(isFirtsQuery){
          url = `${url}&end_date=${filter.expiryEnd}`;
        }
        else{
          url = `${url}end_date=${filter.expiryEnd}`;
        }
        
        
      }
    }
    
    return this.base.getBlob(url);
  }

  //TODO: Beneficiaries
  getBeneficiaries(){
    const url = '/promissory_notes/beneficiaries';
    return this.base.get(url);
  }

  //TODO: Suscritors
  getSuscritor(){
    const url = '/promissory_notes/suscriptors';
    return this.base.get(url);
  }

  saveDownloadFileAs(response: Blob, filename: string) {
    saveAs(response, filename);
  }
}
