import {
  Component,
  Input,
  OnInit
} from '@angular/core';

import { NzModalService } from 'ng-zorro-antd/modal';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-sign-modal',
  templateUrl: './sign-modal.component.html',
  styleUrls: ['./sign-modal.component.scss'],
})
export class SignModalComponent implements OnInit {
  @Input() data: any;

  ngOnInit(): void { }

  closeModal() {
    this.modal.closeAll();
  }

  signedFile() {
    this.closeModal();
  }

  constructor(
    private modal: NzModalService
  ) {}
}
