const isUndefined = (value): boolean => value === undefined;
const isNull = (value): boolean => value === null;
const isBoolean = (value): boolean => typeof value === 'boolean';
const isObject = (value): boolean => value === Object(value);
const isArray = (value): boolean => Array.isArray(value);
const isDate = (value): boolean => value instanceof Date;
const isBlob = (value): boolean =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';
const isFile = (value): boolean =>
  value &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' ||
    typeof value.lastModified === 'number');

const analyzeObjectValueTypes = (object) => {
  const objectTypes = Object.values(object).map((value) => {
    if (value instanceof File) {
      return 'file';
    } else if (isObject(value)) {
      return analyzeObjectValueTypes(value);
    } else {
      return typeof value;
    }
  });
  return objectTypes;
};

const checkIfObjectContainsFile = (objectToFindFile: object): boolean => {
  return Object.values(objectToFindFile).some((value) => value instanceof File);
};

export type SerializeConfig = {
  indices: boolean;
  nullsAsUndefineds: boolean;
  booleansAsIntegers: boolean;
  allowEmptyArrays: boolean;
  returnAsFormData: boolean;
};

/**
 *
 * @param obj object
 * @param cfg object
 * @param fd FormData
 * @param pre prefijo
 * @returns FormData
 */
export const serialize = (
  obj: any,
  config?: Partial<SerializeConfig>,
  fd?: FormData,
  pre?
): FormData | object => {
  config = config || {};

  config.indices = isUndefined(config.indices) ? false : config.indices;

  config.nullsAsUndefineds = isUndefined(config.nullsAsUndefineds)
    ? false
    : config.nullsAsUndefineds;

  config.booleansAsIntegers = isUndefined(config.booleansAsIntegers)
    ? false
    : config.booleansAsIntegers;

  config.allowEmptyArrays = isUndefined(config.allowEmptyArrays)
    ? false
    : config.allowEmptyArrays;

  config.returnAsFormData = isUndefined(config.returnAsFormData)
    ? false
    : config.returnAsFormData;

  fd = fd || new FormData();

  if (isUndefined(obj)) {
    return fd;
  } else if (isNull(obj)) {
    if (!config.nullsAsUndefineds) {
      fd.append(pre, '');
    }
  } else if (isBoolean(obj)) {
    if (config.booleansAsIntegers) {
      fd.append(pre, obj ? '1' : '0');
    } else {
      fd.append(pre, obj ? 'true' : 'false');
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        const key = pre + '[' + (config.indices ? index : '') + ']';

        serialize(value, config, fd, key);
      });
    } else if (config.allowEmptyArrays) {
      fd.append(pre + '[]', '');
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = pre ? pre + '[' + prop + ']' : prop;

      serialize(value, config, fd, key);
    });
  } else {
    fd.append(pre, obj);
  }

  return fd;
};


/**
 *
 * @param obj object
 * @param cfg object
 * @param fd FormData
 * @param pre prefijo
 * @returns FormData
 */
 export const serializeV3 = (
  obj: any,
  config?: Partial<SerializeConfig>,
  fd?: FormData,
  pre?
): FormData | object => {
  config = config || {};

  config.indices = isUndefined(config.indices) ? false : config.indices;

  config.nullsAsUndefineds = isUndefined(config.nullsAsUndefineds)
    ? false
    : config.nullsAsUndefineds;

  config.booleansAsIntegers = isUndefined(config.booleansAsIntegers)
    ? false
    : config.booleansAsIntegers;

  config.allowEmptyArrays = isUndefined(config.allowEmptyArrays)
    ? false
    : config.allowEmptyArrays;

  config.returnAsFormData = isUndefined(config.returnAsFormData)
    ? false
    : config.returnAsFormData;

  fd = fd || new FormData();

  if (isUndefined(obj)) {
    return fd;
  } else if (isNull(obj)) {
    if (!config.nullsAsUndefineds) {
      fd.append(pre, '');
    }
  } else if (isBoolean(obj)) {
    if (config.booleansAsIntegers) {
      fd.append(pre, obj ? '1' : '0');
    } else {
      fd.append(pre, obj ? 'true' : 'false');
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        const key = pre + '[' + (config.indices ? index : '') + ']';

        serialize(value, config, fd, key);
      });
    } else if (config.allowEmptyArrays) {
      fd.append(pre + '[]', '');
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('.') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = pre ? pre + '.' + prop + '' : prop;

      serializeV3(value, config, fd, key);
    });
  } else {
    fd.append(pre, obj);
  }

  return fd;
};