<literax-form-field 
  [label]="'FORM.FILE_INPUT.RFC' | translate"
  [upperCase]="true"
  [placeholder]="'FORM.FILE_INPUT.RFC' | translate"
  [apiError]="backErrors?.rfc"
  [formControl]="form?.get('rfc')"
  disabled
>
</literax-form-field>

<literax-file-input
  #cert
  [formControl]="form.get('certificate')"
  [fileTypes]="permittedCertificateFiles"
  [labelTag]="'PROFILE.FORM.CERT' | translate"
  [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
  [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
  [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
  [returnBase64]="true"
>
</literax-file-input>
<literax-api-error
  [control]="form.controls.certificate"
  [errors]="backErrors?.cert"
>
</literax-api-error>

<literax-file-input
  [formControl]="form.get('key')"
  [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
  [fileTypes]="permittedKeyFiles"
  [labelTag]="'FORM.FILE_INPUT.LABEL_KEY' | translate"
  [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
  [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
  [returnBase64]="true"
>
</literax-file-input>
<literax-api-error [control]="form?.controls?.certificate">
</literax-api-error>

<literax-form-field
  type="password"
  [label]="'FORM.FILE_INPUT.PASSWORD' | translate"
  [placeholder]="'FORM.FILE_INPUT.PASSWORD' | translate"
  [apiError]="backErrors?.pass"
  [formControl]="form?.get('password')"
>
</literax-form-field>

<div *nzModalFooter>
  <button nz-button nzType="default" (click)="cancel()">
    <i class="fal fa-times-circle"></i>
    {{ 'FORM.FILE_INPUT.CANCEL' | translate }}
  </button>
  <button nz-button nzType="primary" [disabled]="!form.valid" (click)="validateRFC()">
    <i class="fal fa-check"></i>
    {{ 'FORM.FILE_INPUT.VALIDATE' | translate }}
  </button>
</div>
