<ng-container *ngIf="menuItem.display">
  <div
    class="row row-menu"
    [class.row-menu-btn]="menuItem?.button"
    [ngbTooltip]="menuItem?.header | translate"
    [placement]="'bottom-left'"
    [container]="'body'"
    [tooltipClass]="'literax-tooltip'"
    [disableTooltip]="!menuClosed"
  >
    <div
      matRipple
      *ngIf="menuItem.path && !menuItem?.button"
      class="col-12 menu-item"
      [class.btn]="menuItem?.button"
      routerLinkActive="active"
      [routerLink]="menuItem.path"
      (click)="closeMenuEmitter.emit(false)"
    >
      <i class="{{ menuItem?.icon }} fa-icon-size mr-3"></i>
      <ng-container *ngIf="!menuClosed">
        {{ menuItem?.header | translate }}
      </ng-container>
    </div>
  </div>

  <!-- OpenMenu with  Child Menu Start -->
  <ng-container *ngIf="!menuItem.path && !menuItem?.button && !menuClosed">
    <div
      class="row row-menu"
      [ngbTooltip]="menuItem?.header | translate"
      [placement]="'bottom-left'"
      [container]="'body'"
      [tooltipClass]="'literax-tooltip'"
      [disableTooltip]="!menuClosed"
    >
      <div class="col-12 menu-item" (click)="toggleMenu()">
        <i class="{{ menuItem?.icon }} fa-icon-size mr-3"></i>
        {{ menuItem?.header | translate }}
        <i
          *ngIf="!menuItem?.button"
          class="fas fa-chevron-right float-right arrow"
          [class.open]="showMenu"
        ></i>
      </div>
    </div>

    <div
      class="collapsable row"
      [style.maxHeight]="showMenu ? menuItem.children.length * 64 + 'px' : '0'"
    >
      <ng-container *ngFor="let childMenu of menuItem.children">
        <div
          *ngIf="childMenu.display"
          routerLinkActive="active"
          [routerLink]="childMenu.path"
          class="col-12 menu-item child-menu-item"
          (click)="closeMenuEmitter.emit(false)"
        >
          <i class="{{ childMenu?.icon }} fa-icon-size mr-3"></i>
          {{ childMenu.label | translate }}
        </div>
      </ng-container>
    </div>
  </ng-container>
  <!-- OpenMenu with Child Menu Finish -->

  <!-- ClosedMenu with Child Menu Start -->
  <ng-container *ngIf="!menuItem.path && !menuItem?.button && menuClosed">
    <div
      class="row row-menu"
      [ngbTooltip]="menuItem?.header | translate"
      [placement]="'bottom-left'"
      [container]="'body'"
      [tooltipClass]="'literax-tooltip'"
      [matMenuTriggerFor]="buttonMenu"
    >
      <div class="col-12 menu-item">
        <i class="{{ menuItem?.icon }} fa-icon-size mr-3"></i>
      </div>
    </div>

    <mat-menu
      #buttonMenu="matMenu"
      overlapTrigger
      class="mat-menu-child-container closed"
    >
      <ng-container *ngFor="let childMenu of menuItem.children">
        <div
          *ngIf="childMenu.display"
          routerLinkActive="active"
          [routerLink]="childMenu.path"
          class="col-12 mat-menu-child-item"
        >
          <i class="{{ childMenu?.icon }} fa-icon-size mr-3"></i>
          {{ childMenu.label | translate }}
        </div>
      </ng-container>
    </mat-menu>
  </ng-container>
  <!-- ClosedMenu with Child Menu Finish -->

  <ng-container *ngIf="menuItem?.button">
    <div
      class="col-12 menu-item"
      [class.btn]="menuItem?.button"
      [class.btn-closed]="menuClosed"
      [matMenuTriggerFor]="buttonMenu"
    >
      <i class="{{ menuItem?.icon }} fa-icon-size mr-3"></i>

      <ng-container *ngIf="!menuClosed">
        {{ menuItem?.header | translate }}
        <i
          *ngIf="!menuItem?.button"
          class="fas fa-chevron-right float-right arrow"
          [class.open]="showMenu"
        ></i>
      </ng-container>
    </div>

    <mat-menu
      #buttonMenu="matMenu"
      overlapTrigger
      class="mat-menu-child-container btn-items {{
        menuClosed ? 'closed' : ''
      }} "
    >
      <ng-container *ngFor="let childMenu of menuItem.children">
        <div
          *ngIf="childMenu.display"
          routerLinkActive="active"
          [routerLink]="childMenu.path"
          class="col-12 mat-menu-child-item"
        >
          {{ childMenu.label | translate }}
          <i class="{{ childMenu?.icon }} fa-icon-size mr-3"></i>
        </div>
        <div class="separator"></div>
      </ng-container>
    </mat-menu>
  </ng-container>
</ng-container>
