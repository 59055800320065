import { IPlan, IReferenceResponse } from '@literax/models/plan.model';

export interface IRegistrationState {
  validateClient: boolean;
  plans: IPlan[];
  planReference: IReferenceResponse;
  clientCreation: boolean;
}

export const initialRegistrationState: IRegistrationState = {
  validateClient: null,
  plans: [],
  planReference: null,
  clientCreation: null
};