import { IPagination } from '../../../../../models/pagination.model';
import {
  IOperation,
  IUserIntegrator,
  IUserIntegratorResponse,
  IWebhookEvent,
  IWebhookEventQueryParams,
  IWebServiceConfigResponse
} from '../models/api.model';

export interface IApiConfigurationsInterface {
  users: IUserIntegrator[];
  webservice_configs: IWebServiceConfigResponse[];
  operations: IOperation[];
  events: IWebhookEvent[];
  selected_webservice_config: IWebServiceConfigResponse;
  selected_operation: IOperation;
  selected_user: IUserIntegratorResponse;
  web_service_sort_params: string;
  operation_sort_params: string;
  user_sort_params: string;
  events_sort_params: Partial<IWebhookEventQueryParams>;
  create_user_errors: { [key: string]: any };
  create_operation_errors: { [key: string]: any };
  create_webservice_errors: { [key: string]: any };
  update_user_errors: { [key: string]: any };
  update_operation_errors: { [key: string]: any };
  update_webservice_errors: { [key: string]: any };
  create_user: boolean;
  create_operation: boolean;
  create_webservice: boolean;
  update_user: boolean;
  update_operation: boolean;
  update_webservice: boolean;
  user_curret_page: number;
  operations_curret_page: number;
  webservice_curret_page: number;
  pagination: IPagination
}

export const initialApiConfigurationsState: IApiConfigurationsInterface = {
  users: [],
  webservice_configs: [],
  operations: [],
  events: [],
  web_service_sort_params: 'id:desc',
  operation_sort_params: 'id:desc',
  user_sort_params: 'id:desc',
  events_sort_params: {
    sort: 'id_event:desc',
    page: 1
  },
  selected_webservice_config: {},
  selected_operation: {},
  selected_user: {},
  create_user_errors: {},
  create_operation_errors: {},
  create_webservice_errors: {},
  update_user_errors: {},
  update_operation_errors: {},
  update_webservice_errors: {},
  create_user: false,
  create_operation: false,
  create_webservice: false,
  update_user: false,
  update_operation: false,
  update_webservice: false,
  user_curret_page: 1,
  operations_curret_page: 1,
  webservice_curret_page: 1,
  pagination: null
};
