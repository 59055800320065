<div class="headers">
  <div *ngFor="let item of columns" class="title" [ngStyle]="item?.style">
    {{ item.label | translate }}
    <div *ngIf="item.sortColumn" class="sortIcon pl-2">
      <div class="sortUp" (click)="sortColumn.emit(item?.value + ':asc')">
        <i class="fal fa-sort-up"></i>
      </div>
      <div class="sortDown" (click)="sortColumn.emit(item.value + ':desc')">
        <i class="far fa-sort-down"></i>
      </div>
    </div>
  </div>
</div>
<literax-virtual-scroll
  [list]="list"
  [height]="'50vh'"
  [itemRowSize]="46"
  [rowTem]="rowTemplate"
  (nextBatchEmitter)="nexBatch.emit($event)"
>
</literax-virtual-scroll>
