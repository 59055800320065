import {
  DocumentsResponse,
  IDocFilterField,
  IDocumentResponse,
  IDocumentStatusAcumulation,
  IDocumentViewSignaturesResponse,
  IQueryParameters,
} from '@literax/models/document.model';
import {
  IAPIError,
  IAPIResponseError,
} from '@literax/models/http/api/error.model';
import {
  ICancelatioReason,
  IDocument,
} from '@literax/models/http/document/document.model';

import { DocumentListType } from '../../const/flows.conts';
import { ETypeDocConsult } from '@literax/enums/document.enum';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IFlowCatalog } from '@literax/models/catalog.model';
import { ILastEmailEvent } from '@literax/models/http/api/sign-request/last-email-event';
import { IPaginationV3 } from '@literax/models/pagination.model';
import { ISearch } from '../../../../models/http/document/document.model';
import { ISignerCoordinatesUpdater } from '@literax/models/http/api/client/pdf.model';
import { ISort } from '@literax/interfaces/sort-descriptor.interface';
import { IStatus } from '../../../../store/status/status.state';
import { ITabFlow } from '../../../../models/flow.model';

export interface IUser {
  id: number;
  name: string;
}

export interface IFilterParams {
  name?: string;
  status_id?: number[];
  user_name?: string;
  signer?: string;
  created_at?: string;
  flow?: string;
  process?: string;
  currentPage?: number;
  pageSize?: number;
  transaction_type?: string;
  status_key?: string;
}

export interface ISignatureRole {
  id: number;
  name: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface ISignaturePosition {
  x: number;
  y: number;
  page: number;
  attachment_id: number;
}

export interface ISignatureRole {
  id: number;
  name: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface ISignRequest {
  id?: number;
  date_signed?: Date;
  is_author?: boolean;
  signatory_role?: ISignatureRole;
  expiry?: Date | string;
  signed_date?: Date;
  email?: string;
  name?: string;
  rfc?: string;
  role?: string;
  signed?: boolean;
  approved?: boolean;
  reviewed?: boolean;
  signature_type_id?: number;
  company_rfc?: string;
  company_name?: string;
  color?: string;
  status?: IStatus;
  signature_positions?: ISignaturePosition[];
  document_id?: number;
  created_at?: Date;
  updated_at?: Date;
  id_document_request?: string;
  user_terms_accepted?: boolean;
  oficial_identification?: boolean;
  signature_type_name?: string;
  auth?: string;
  order?: number;
  can_send_notification?: boolean;
  legal_representatives?: Partial<ISignRequest>[];
  last_email_event?: ILastEmailEvent;
  sign_request_signature_quotes?: ISignRequestQuote[];
  approval_required?: boolean;
  holder_status?: string;
  delegate?: ISignRequest;
  is_delegate?: boolean;
  population_registry_code?: string;
}

export type ISignRequestQuote = {
  template_uid?: string;
  record_uid?: string;
  signature_quote_text?: string;
};

export const nullIDocument: IDocument = {
  id: 0,
  created_at: new Date(),
  name: '',
  sign_requests: [],
  status: { id: 0, name: '', color: '', key: null },
  status_name: '',
  updated_at: new Date(),
  expiry: new Date(),
  user: null,
  signatures: [],
  observers: [],
  attachments: [
    {
      id: 0,
      name: '',
      file_path: null,
      created_at: new Date(),
      updated_at: new Date(),
      kind: 'pdf',
      primary: null,
      multilanguage: null,
    },
  ],
  process_name: '',
  rule: '',
  signing_method: null,
};

export interface IDocumentState {
  documents: DocumentsResponse;
  queryParameters: IQueryParameters;
  selectedDocument: Partial<IDocument>;
  signerCoordinates: ISignerCoordinatesUpdater;
  selectAttachment: IAttachment;
  attachments: IAttachment[];
  lastDocument: IDocument; // ! Deprecated: change for lastDocumentV3
  lastDocumentV3: IDocumentResponse; // TODO: Remove suffix V3
  uploadErrors: IAPIResponseError;
  activeFilter: IFilterParams; // ! Deprecated: change for queryParameters
  deleteDocumentError: IAPIError;
  getDocumentError: IAPIResponseError;
  getAttachmentError: IAPIResponseError;
  sortColumn: string;
  tabsFlows: ITabFlow[];
  navbarDocument: ISearch[];
  onlyoffice_key: string;
  hidden: boolean;
  currentSort: ISort[];
  commentRejected: ICancelatioReason[];
  lastUploadedAttachment: IAttachment;
  pending_seend: number;
  pagination: IPaginationV3; // TODO: Remove suffix V3
  facialRecognitionRequired: boolean;
  identificationRequired: string;
  approvers: ISignRequest[];
  reviewers: ISignRequest[];
  selectedDocumentViewSignature: IDocumentViewSignaturesResponse;
  // documents list redesign state
  acumulatedStatuses: IDocumentStatusAcumulation[];
  filterParams: IDocFilterField[];
  flowId: number;
  flow: IFlowCatalog;
  type: string;
  acumulatedStatusesTab: IDocumentStatusAcumulation[];
}

export const initialDocumentState: IDocumentState = {
  documents: null,
  queryParameters: {
    pageNumber: 1,
    pageSize: 10,
    type: ETypeDocConsult.SENT,
    show: 1,
    sortField: null,
    filterFields: [],
  },
  selectedDocument: null,
  selectAttachment: null,
  attachments: [],
  lastDocument: null,
  lastDocumentV3: null,
  uploadErrors: null,
  activeFilter: null,
  deleteDocumentError: null,
  getDocumentError: null,
  getAttachmentError: null,
  signerCoordinates: null,
  sortColumn: 'status.order:asc',
  tabsFlows: null,
  navbarDocument: null,
  onlyoffice_key: null,
  hidden: false,
  currentSort: [{ field: 'status.order', dir: 'asc' }],
  commentRejected: [],
  lastUploadedAttachment: null,
  pending_seend: 0,
  pagination: null,
  facialRecognitionRequired: false,
  identificationRequired: '',
  approvers: [],
  reviewers: [],
  selectedDocumentViewSignature: null,
  acumulatedStatuses: undefined,
  filterParams: [],
  flowId: 2,
  type: DocumentListType.SENT,
  flow: undefined,
  acumulatedStatusesTab: null
};

export interface IReviewerToSignerList {
  resource_uuid?: string;
  approval_required?: boolean;
  document_id: number;
  signature_type_id: number;
  signature_type_name?: string;
  company_name: string;
  company_rfc: string;
  name: string;
  rfc: string;
  email: string;
  expiry: string | Date;
  is_approver: boolean;
}
