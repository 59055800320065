import { WizardConfig } from '@literax/models/wizard.model';

export interface ConfigState {
  lang: string;
  documentWizard: WizardConfig;
}

export const initialConfigDocumentWizard: WizardConfig = {
  height: 'auto',
  width: '1024px'
};

export const initialConfigState: ConfigState = {
  lang: 'es',
  documentWizard: initialConfigDocumentWizard
};
