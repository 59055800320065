import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { ICompanyAdd } from '@literax/models/company.model';
import { BaseService } from '@literax/services/base-config/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private base: BaseService) {}

  getCompanies(): Observable<ServiceResponse> {
    return this.base.get(`/companies`);
  }

  createCompany(company: ICompanyAdd): Observable<ServiceResponse> {
    return this.base.post(`/companies`, { company });
  }

  deleteCompany(companyId: number): Observable<ServiceResponse> {
    return this.base.delete(`/companies/${companyId}`);
  }
}
