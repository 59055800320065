import { Component, Input } from '@angular/core';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { IDepartmentReceivers } from '../../../components/configurations/users/models/users.interface';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.scss'],
})
export class AssignmentDialogComponent {
  @Input() data: ConfirmDialogData;
  receiver: { userId: number; userName: string };
  constructor(public modal: NzModalService) {}
}

export interface ConfirmDialogData {
  delegate: (request: { userId: number; userName: string }) => void;
  departmentReceivers$: Observable<IDepartmentReceivers[]>;
}
@UntilDestroy()
@Component({
  template: `
    <div class="text-center modal-success">
      <span nz-icon [nzType]="'check-circle'"></span>
      <h3>
        {{
          'DOCUMENT-CONFIGURATION.REASSIGMENT.TITLE_SUCCESSFULLY' | translate
        }}
      </h3>
      <p>{{ userName }}</p>
      <button nz-button nzType="primary" (click)="modal.closeAll()">
        {{ 'WORKSPACE.FOOTER.AWARE' | translate }}
      </button>
    </div>
  `,
  styleUrls: ['./assignment-dialog.component.scss'],
})
export class AssignmentModalSuccessComponent {
  @Input() userName: string;
  constructor(public modal: NzModalService) {}
}
