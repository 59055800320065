<nz-upload
  [nzShowUploadList]="false"
  [nzMultiple]="false"
  (nzChange)="onFileChange($event)"
  [nzDisabled]="disabled"
  [nzBeforeUpload]="beforeUpload"
  [nzAccept]="accept"
>
  <button nz-button type="button" *ngIf="!file">
    <span nz-icon nzType="cloud-upload" nzTheme="outline"></span>
    {{ placeholder | translate }}
  </button>
  <div class="file-block" *ngIf="file">
    <p
      *ngIf="file"
      [title]="returnAsBase64 ? file.file?.name : file?.name"
      class="primary-color"
    >
      <i class="fal fa-paperclip"></i>
      {{ returnAsBase64 ? file.file?.name : file?.name }}
    </p>
    <button nz-button nzType="text" (click)="removeFile()">
      <i class="far fa-trash"></i>
    </button>
  </div>
</nz-upload>
