export enum ETemplateStatus {
  PUBLISHED = 'published',
  DATA_COLLECTION = 'data_collection',
  DRAFT = 'draft',
  TO_WORK_IN_PROCESS = 'to_work_in_process',
  UNPUBLISHED = 'unpublished',
  WORK_IN_PROGRESS = 'work_in_progress',
  IN_PROCESS = 'in_process'
}

export enum ETemplateKind {
  DOCUMENT = 'document',
  TICKET = 'ticket',
  FORM = 'form',
  PROFILE = 'profile',
  SIGNATURE_QUOTE = 'signature_quote',
  TEMPLATE = 'template'
}

export enum ETemplateFlow {
  TEMPLATE_DRAFT = 'template_draft',
  TEMPLATE_PUBLISHED = 'template_published',
  TEMPLATE_UNPUBLISHED = 'template_unpublished',
  TEMPLATE_ALL = 'template_all'
}

export enum ETemplateProcessKind {
  DOCUMENT = 0,
  TICKET = 1,
  FORM = 2,
  SIGNATURE_QUOTE = 3,
  PROFILE = 4
}

export enum ETemplateUpdatePaths {
  NAME = '/name',
  DEPARTMENT_IDS = '/departmentIds'
}