import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ISignRequest } from '@literax/modules/documents/store/document/document.state';
import { ISignature } from '@literax/models/http/api/signature.model';
import { Action } from 'rxjs/internal/scheduler/Action';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-signer-info',
  templateUrl: './signer-info.component.html',
  styleUrls: ['./signer-info.component.scss']
})
export class SignerInfoComponent {
  @Input() free: boolean;
  @Input() signer: ISignRequest;
  @Input() signature: ISignature;
  @Input() labelButton: string;
  @Output() actionButton = new EventEmitter();
  showInfo = false;

  actionClicked(event: Event) {
    this.actionButton.emit({ type: 'signerInfoAction', signer: this.signer });
  }

  showSignature(event: Event) {
    this.showInfo = !this.showInfo;
  }

  constructor() {}
}
