import { Component, Input, OnInit } from '@angular/core';
import { IAppState } from '@literax/store';
import { Store } from '@ngrx/store';
import * as backButtonActions from '@literax/store/back-button/back-button.actions';

@Component({
  selector: 'literax-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() hasAction = false;
  @Input() params?: any;

  constructor(private store: Store<IAppState>) {}

  onBack() {
    this.store.dispatch(
      backButtonActions.GoPreviousUrl({
        hasAction: this.hasAction,
        params: this.params,
      })
    );
  }
}
