import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { EViewerType } from '@literax/enums/attachment.enum';
import { IAppState } from '@literax/store';
import { MsalService } from '@azure/msal-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceButtonBar } from '@literax/modules/documents/workspace/components/buttons-bar-layout/workspace-button-bar';

@UntilDestroy()
@Component({
  selector: 'literax-signature-buttons',
  templateUrl: './signature-buttons.component.html',
  styles: [' :host { width: 100%}'],
})
export class SignatureButtonsComponent
  extends WorkspaceButtonBar
  implements OnInit, OnDestroy
{

  viewerModes = EViewerType;

  constructor(
    store: Store<IAppState>,
    router: Router,
    modalService: NzModalService,
    translateService: TranslateService,
    b2cAuthService: B2CAuthService,
    msalService: MsalService
  ) {
    super({ store, router, modalService, translateService, b2cAuthService, msalService });
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  ngOnInit(): void {}
}
