import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private resizeObserver: ResizeObserver;
  private resize$: BehaviorSubject<number>;

  constructor() {
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.resize$.next(entry.contentRect.width);
      });
    });

    this.resize$ = new BehaviorSubject<number>(window.innerWidth);

    this.resizeObserver.observe(document.documentElement);
  }

  public getSize(): Observable<number> {
    return this.resize$.asObservable();
  }
}
