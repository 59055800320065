<literax-buttons-bar-layout>
  <nz-space slot="left">
    <ng-container *ngIf="!isConfigScreen">
      <!-- ! boton para eliminar documento (autor) -->
      <ng-container *ngIf="status === statusTypes.DRAFT"
        ><button
          nz-button
          *nzSpaceItem
          nzType="primary"
          nzDanger
          (click)="action(actions.application.DELETE)"
        >
          <span nz-icon>
            <i class="fal fa-trash-alt"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.DELETE' | translate }}
        </button></ng-container
      >
      <!-- ! boton para cancelar proceso (autor) -->
      <ng-container *ngIf="status === statusTypes.REVIEW && isAuthor"
        ><button
          nz-button
          *nzSpaceItem
          nzType="primary"
          nzDanger
          (click)="action(actions.application.CANCEL)"
        >
          <span nz-icon>
            <i class="fal fa-times-circle"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.CANCEL' | translate }}
        </button></ng-container
      >
    </ng-container>
    <!-- ! boton para regresar a workspace (autor, pantalla de configuracion) -->
    <ng-container *ngIf="isConfigScreen"
      ><button
        nz-button
        *nzSpaceItem
        nzType="primary"
        nzDanger
        (click)="action(actions.application.BACK_TO_WORKSPACE)"
      >
        <span nz-icon>
          <i class="fal fa-arrow-to-left"></i>
        </span>
        {{ 'WORKSPACE.FOOTER.BACK' | translate }}
      </button></ng-container
    >
  </nz-space>

  <nz-space slot="right">
    <ng-container *ngIf="!isConfigScreen">
      <!-- ! boton descarga de documento(s) -->
      <ng-container 
        *ngIf="!isHidden"
      >
        <button
          nz-button
          *nzSpaceItem
          nzType="default"
          (click)="action(actions.application.DOWNLOAD)"
        >
          <span nz-icon>
            <i class="far fa-cloud-download"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.DOWNLOAD' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="status === statusTypes.REVIEW && isParticipant">
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          (click)="action(actions.review.TO_REVIEWED)"
        >
          <span nz-icon>
            <i class="far fa-check-double"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.FINISH_REVIEW' | translate }}
        </button>
      </ng-container>

      <!-- ! boton para "guardar" anexo docx  -->
      <ng-container *ngIf="(status === statusTypes.REVIEW || status === statusTypes.REVIEWED) && isAuthor && isSaveReview">
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          (click)="action(actions.review.REVIEW_RELOAD)"
        >
          <span nz-icon>
            <i class="fal fa-save"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SAVE' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="status === statusTypes.REVIEWED && isAuthor && !isSaveReview">
        <button
          nz-button
          *nzSpaceItem
          nz-dropdown
          nzType="primary"
          nzTrigger="click"
          [nzDropdownMenu]="sendTo"
        >
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND_TO' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="status === statusTypes.DRAFT">
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          [disabled]="!haveParticipants"
          (click)="action(actions.review.SEND_REVIEW)"
        >
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </nz-space>

  <nz-dropdown-menu #sendTo="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <button
          nz-button
          nzType="primary"
          nzBlock
          (click)="action(actions.request.TO_APPROVAL)"
        >
          {{ 'WORKSPACE.FOOTER.ACTIONS.TO_APPROVAL' | translate }}
        </button>
      </li>
      <li nz-menu-item>
        <button
          nz-button
          nzType="primary"
          nzBlock
          (click)="action(actions.request.TO_SIGNATURE)"
        >
          {{ 'WORKSPACE.FOOTER.ACTIONS.TO_SIGNATURE' | translate }}
        </button>
      </li>
    </ul>
  </nz-dropdown-menu>
</literax-buttons-bar-layout>
