import * as documentActions from '../../../modules/documents/store/document/document.actions';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  selectUserEmail,
  selectUserName,
} from '@literax/modules/auth/store/auth.selectors';

import { BaseService } from '@literax/services/base-config/base.service';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { ISignerDocument } from '@literax/models/http/api/signature.model';
import { Logout } from '@literax/modules/auth/store/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { selectCurrentLang } from '@literax/modules/configurations/store/config/config.selectors';
import { selectDocumentViewSignature } from '@literax/modules/documents/store/document/document.selectors';
import { setLang } from '@literax/modules/configurations/store/config/config.actions';
import { tap } from 'rxjs/operators';

import { IAttachmentViewSignature, IDocumentViewSignaturesResponse, IViewSignature, IViewSignRequest } from '@literax/models/document.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';

@UntilDestroy()
@Component({
  selector: 'literax-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.scss'],
})
export class SignaturesComponent implements OnInit, OnDestroy {
  document$ = this.store.pipe(untilDestroyed(this), select(WorkspaceSelectors.selectDocument));

  viewDocumentSignature$ = this.store.pipe(untilDestroyed(this),select(selectDocumentViewSignature)); //v3

  email$ = this.store.pipe(select(selectUserEmail));
  lang$ = this.store.pipe(
    select(selectCurrentLang),
    tap((lang: string) => this.updateCurrentLang(lang))
  );
  name$ = this.store.pipe(select(selectUserName));

  activeTab = 0;
  attachments: IAttachment[];
  logoPath =
    'https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax/images/logo_white.svg';
  paramsId = 0;
  selectedAttachment: IAttachment;
  
  document: IDocument;
  signers: ISignerDocument[] = [];

  //v3
  viewDocumentSignature: IDocumentViewSignaturesResponse;
  viewAttachments: IAttachmentViewSignature[];
  selectViewAttachment: IAttachmentViewSignature;
  isActive = false;
  viewSigners: IViewSignRequest[] = [];
  isViewDigitalSignature = false;

  //isActivo = false;

  constructor(
    private baseService: BaseService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAppState>,
    private translate: TranslateService
  ) {


    this.viewDocumentSignature$.subscribe((document: IDocumentViewSignaturesResponse) =>{
      if(document){
        this.store.dispatch(
          WorkspaceActions.getDocument({
            payload: { id: document.documentId },
          })
        );

        this.viewDocumentSignature = document;
        this.viewAttachments = document.attachments;
        this.selectViewAttachment = document.attachments[0];
        this.generateSigners();
      }
    })
  }

  ngOnInit() {
    this.store.dispatch(documentActions.CleanSelectedDocumentState());
    this.store.dispatch(documentActions.CleanSelectDocumentViewSignatureState());
    this.route.params.subscribe((params) => {
      this.store.dispatch(documentActions.GetViewDocumentSignatures({ payload: params.id }));//v3
      this.paramsId = params.id;
    });
  }

  ngOnDestroy(): void {}

  onTabClicked(index: number, attachment: IAttachmentViewSignature) {
    this.activeTab = index;
    this.selectViewAttachment = attachment;
    this.isActive = false;

    this.generateSigners();
  }

  navigateBack() {
    this.router.navigate([
      `/platform/documents/${this.document.transaction_type}/${this.paramsId}`,
    ]);
  }

  updateCurrentLang(lang: string) {
    if (lang) {
      this.translate.use(lang);
    }
  }

  setLang(e: { event: string; lang: string }) {
    this.store.dispatch(setLang({ payload: e.lang }));
  }

  logout() {
    this.store.dispatch(Logout());
  }

  
  generateSigners() {
    //v3
    const viewSigner: IViewSignRequest[] = [];
    this.selectViewAttachment.signRequests.forEach((signRequest) =>{
       
      let newViewSigner: IViewSignRequest = signRequest;
      viewSigner.push(newViewSigner);
      this.isViewDigitalSignature = signRequest.signatures[0].signatureImageBase64 == null;
    });

    this.viewSigners = viewSigner;

  }

  viewDocuments() {
    this.isActive = true;//v3
  }
}
