import {
  ESignatureVerificationTypeId,
  ESigningMethod,
  EWorkspaceDocumentTabs,
} from '@literax/enums/document.enum';
import {
  IAttachmentRequestParams,
  IViewingAttachment,
} from '@literax/models/attachment.model';
import {
  ILegalPerson,
  INaturalPerson,
  IObserver,
  ISignRequestQuote,
} from '@literax/models/participant.model';

import {
  IDocumentResponse,
  IVerificationType,
} from '@literax/models/document.model';
import {
  ILookFeelConfig,
  IAccessTokens,
} from '../../../../components/configurations/users/models/users.interface';
import { IReminder } from '@literax/models/reminder.model';

export interface IDocumentConfiguration {
  ordering: boolean;
  flowName: string;
  signingMethod: ESigningMethod;
  signatureVerificationTypeId: ESignatureVerificationTypeId;
  showBiometricsCheck: boolean;
  legalPersons: ILegalPerson[];
  naturalPersons: INaturalPerson[];
  participants: Array<INaturalPerson | ILegalPerson>;
  observers: IObserver[];
  participantsCount: number;
  observersCount: number;
  participantSignatureQuotes: ISignRequestQuote[];
}

export enum EWorkingMode {
  AUTH = 0,
  GUEST,
  INTEGRATOR,
}

export enum IDocxAttachmentState {
  IDLE = 0,
  GENERATING,
  GENERATED,
}

export interface WorkspaceState {
  document: IDocumentResponse | null;
  attachment: IViewingAttachment | null;
  mainAttachment: IViewingAttachment | null;
  viewerOptions: IAttachmentRequestParams | null;
  activeTab: EWorkspaceDocumentTabs;
  formBuilderRequiredFieldsFilled: boolean;
  isPromissoryNoteFlow: boolean;
  actionToDispatch: string | null;
  configuration: IDocumentConfiguration;
  errorMessage: string | null | any;
  guestEmail: string | null;
  guestToken: string | null;
  lookFeel: ILookFeelConfig;
  workingModeType: EWorkingMode;
  tokens: IAccessTokens;
  docxAttachmentState: IDocxAttachmentState;
  signatureVerificationTypes: IVerificationType[];
}

export const initialWorkspaceState: WorkspaceState = {
  document: null,
  attachment: null,
  mainAttachment: null,
  viewerOptions: null,
  activeTab: EWorkspaceDocumentTabs.ATTACHMENTS,
  formBuilderRequiredFieldsFilled: false,
  isPromissoryNoteFlow: false,
  actionToDispatch: null,
  configuration: {
    ordering: false,
    flowName: null,
    signingMethod: ESigningMethod.DIGITAL_SIGNATURE,
    signatureVerificationTypeId: ESignatureVerificationTypeId.EMAIL,
    showBiometricsCheck: false,
    legalPersons: [],
    naturalPersons: [],
    participants: [],
    observers: [],
    participantsCount: 0,
    observersCount: 0,
    participantSignatureQuotes: []
  },
  errorMessage: null,
  guestEmail: null,
  guestToken: null,
  lookFeel: null,
  workingModeType: EWorkingMode.AUTH,
  tokens: null,
  docxAttachmentState: IDocxAttachmentState.IDLE,
  signatureVerificationTypes: []
};
