import { Component, Input, OnInit } from '@angular/core';

import { NzModalService } from 'ng-zorro-antd/modal';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-request-new-link-dialog',
  templateUrl: './request-new-link-dialog.component.html',
  styleUrls: ['./request-new-link-dialog.component.scss']
})
export class RequestNewLinkDialogComponent implements OnInit {

  @Input() isFailed = true;

  constructor(
    private modalService: NzModalService
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalService.closeAll();
  }

}
