<div class="document-info">
    <div class="info-row">
        <div class="label">
            {{ 'XML_SCREEN.TABS.INFO.FILE_NAME' | translate }}
        </div>
        <div class="value">{{ document?.name }}</div>
    </div>
    <div class="info-row">
        <div class="label">
            {{ 'XML_SCREEN.TABS.INFO.CREATED_AT' | translate }}
        </div>
        <div class="value">
            {{ document?.createdAt * 1000 | date: 'dd / MMM / YYYY' }}
        </div>
    </div>
    <div class="info-row">
        <div class="label">
            {{ 'XML_SCREEN.TABS.INFO.AUTHOR' | translate }}
        </div>
        <div class="value">{{ document?.author.name }}</div>
    </div>
    <div class="info-row" *ngIf="
        document?.status?.key !== documentStatus.DRAFT &&
        document?.status?.key !== documentStatus.SIGNED &&
        document?.status?.key !== documentStatus.REVIEWED &&
        document?.status?.key !== documentStatus.REJECTED
      ">
        <div class="label">
            {{ 'XML_SCREEN.TABS.INFO.DEADLINE' | translate }}
        </div>
        <div class="value">
            {{
            document?.expiryAt !== null
            ? (document?.expiryAt * 1000 | date: 'dd / MMM / YYYY ')
            : '-'
            }}
        </div>
    </div>
    <div class="info-row">
        <div class="label">
            {{ 'XML_SCREEN.TABS.INFO.MAIN_ATTACHMENT' | translate }}
        </div>
        <div class="value">{{ mainAttachment?.name }}</div>
    </div>
    <div class="info-row">
        <div class="label">
            {{ 'XML_SCREEN.TABS.INFO.ATTACHMENTS' | translate }}
        </div>
        <div class="value" *ngFor="let attachment of attachments">
            {{ attachment.name }}
        </div>
        <div class="value" *ngIf="attachments.length === 0">
            {{ 'XML_SCREEN.TABS.INFO.ATTACHMENTS_EMTY' | translate }}
        </div>
    </div>
</div>