import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ITermsResponse } from '@literax/models/terms.model';

export const GetTermsFree = createAction(
  '[Terms] GetTermsFree',
  props<{ token: string }>()
);

export const GetTermsFreeSuccess = createAction(
  '[Terms] GetTermsFreeSuccess',
  props<{ payload: ITermsResponse }>()
);

export const GetTermsFreeError = createAction(
  '[Terms] GetTermsFreeError',
  props<{ payload: IAPIResponseError }>()
);

export const AcceptTermsFree = createAction(
  '[Terms] AcceptTermsFree',
  props<{ signRequestId: number, documentId: number, token: string }>()
);

export const AcceptTermsFreeSuccess = createAction(
  '[Terms] AcceptTermsFreeSuccess'
);

export const AcceptTermsFreeError = createAction(
  '[Terms] AcceptTermsFreeError',
  props<{ payload: IAPIResponseError }>()
);
