import { Action, createReducer, on } from '@ngrx/store';
import {
  CreateApproveRequestError,
  CreateApproveRequestSucces
} from './approve-request.actions';
import {
  IApproverRequestWorkspaceState,
  initialApproverRequestWorkspaceState
} from './approve-request.state';

const approveRequestReducer = createReducer(
  initialApproverRequestWorkspaceState,

  /**
   * Reducer para mostrar los errores de creacion de revisores
   */
  on(CreateApproveRequestError, (state, action) => ({
    ...state,
    newApproverCreationErrors: action.payload
  })),

  /**
   * Reducer para la creacion exitosa de un revisor
   */
  on(CreateApproveRequestSucces, (state, action) => ({
    ...state,
    newApproverCreationSuccess: action.payload
  }))
);

export function approversReducer(
  state: IApproverRequestWorkspaceState,
  action: Action
) {
  return approveRequestReducer(state, action);
}
