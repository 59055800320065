<div class="loader" *ngIf="viewerType === viewerTypes.LOADING">
  <ng-template #indicatorTemplate><span nz-icon> <i class="fad fa-spinner-third fa-spin"></i> </span> <span>Procesando
      archivo...</span></ng-template>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
</div>

<literax-if-pdf *ngIf="viewerType === viewerTypes.PDF"></literax-if-pdf>
<literax-onlyoffice-editor *ngIf="viewerType === viewerTypes.DOCX"></literax-onlyoffice-editor>
<div class="markdown-document">
  <div>
    <markdown [data]="markdownData" *ngIf="viewerType === viewerTypes.MD"></markdown>
  </div>
</div>
<div class="attachments-navigate" *ngIf="isMobile$ | async">
  <button nz-button nzType="primary" nzShape="circle" (click)="navigateToAttachments()">
    <span nz-icon>
      <i class="fal fa-paperclip"></i>
    </span>
  </button>
</div>