import * as moment from 'moment';
import * as validatorActions from '../../store//validator.actions';

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { BreakpointObserver } from '@angular/cdk/layout';
import { IAppState } from '@literax/store';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { selectShowSignatureResult } from '../../store/validator.selector';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-uuid-validation-document',
  templateUrl: './uuid-validation-document.component.html',
  styleUrls: ['./uuid-validation-document.component.scss'],
})
export class UuidValidationDocumentComponent implements OnInit {
  signature$ = this.store.pipe(
    untilDestroyed(this),
    select(selectShowSignatureResult)
  );
  apiRoot = environment.literaxUrl;
  today = new Date();
  createdAt: string;

  isMobileDevice: boolean;
  isTabletDevice: boolean;
  isDesktopDevice: boolean;

  constructor(
    private store: Store<IAppState>,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    public breakpointObserver: BreakpointObserver
  ) {
    moment.locale(this.translate.currentLang);
  }

  ngOnInit() {
    this.configResponsiveDesign();
    let uuid = this.route.snapshot.paramMap.get('uuid');
    if (uuid.includes('__')) {
      uuid = uuid.split('__')[1];
      this.store.dispatch(
        validatorActions.ShowSignature({
          payload: { hashId: uuid, entityType: 1 },
        })
      );
    } else {
      this.store.dispatch(
        validatorActions.ShowSignature({
          payload: { hashId: uuid, entityType: 0 },
        })
      );
    }
  }

  onLogin() {
    this.router.navigate(['/auth/registration']);
  }

  onRegister() {
    this.router.navigate(['/auth/registration']);
  }

  onVerification(uuid: string) {
    const win = window.open(`/validator/uuid/__${uuid}`, '_blank');
    win.focus();
  }

  configResponsiveDesign() {
    if (this.breakpointObserver.isMatched('(max-width: 767px)')) {
      this.isMobileDevice = true;
    } else if (this.breakpointObserver.isMatched('(min-width: 768px)')) {
      if (this.breakpointObserver.isMatched('(max-width: 1023px)')) {
        this.isTabletDevice = true;
      } else if (this.breakpointObserver.isMatched('(min-width: 1024px)')) {
        this.isDesktopDevice = true;
      }
    }
  }
}
