import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IProfilesState } from './profiles.reducer';
export const getProfileState =
  createFeatureSelector<IProfilesState>('profiles');
export const getSelectAll = createSelector(
  getProfileState,
  (state: IProfilesState) => state.profile
);

export const getImage = createSelector(
  getProfileState,
  (state: IProfilesState) => state.profile.image_url
);

export const getError = createSelector(
  getProfileState,
  (state: IProfilesState) => state?.errorGetAllProfile?.error?.detail
);

export const selectCertError = createSelector(
  getError,
  (state: any) => state?.cert
);

export const selectImageError = createSelector(
  getError,
  (state: any) => state && state.image
);

export const selectNameError = createSelector(
  getError,
  (state: any) => state && state.name
);

export const selectLastNameError = createSelector(
  getError,
  (state: any) => state && state.last_name
);

export const selectPasswordError = createSelector(
  getError,
  (state: any) => state && (state.current_password || state.password)
);

export const selectUserEmails = createSelector(
  getProfileState,
  (state) => state.userEmails
);

export const selectRegisterEmailErrors = createSelector(
  getProfileState,
  (state) => state.registerEmailErrors.error?.detail
);
