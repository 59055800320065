import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { CoreActions } from './store/core/core.actions';
import { EDeviceType } from './enums/application.enum';
import { IAppState } from './store';
import { ScreenSizeService } from './services/screen-size.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';
@UntilDestroy()
@Component({
  selector: 'literax-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  screenObserver: Subscription;
  constructor(
    private store: Store<IAppState>,
    private screenSize: ScreenSizeService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnDestroy(): void {
    this.screenObserver.unsubscribe();
  }

  ngOnInit(): void {
    this.screenObserver = this.screenSize.getSize().subscribe((size) => {
      if (size < 768) {
        this.store.dispatch(CoreActions.setIsDesktop({ payload: false }));
        this.store.dispatch(
          CoreActions.setDeviceType({ payload: EDeviceType.MOBILE })
        );
      }

      if (size < 992 || size > 768) {
        this.store.dispatch(CoreActions.setIsDesktop({ payload: false }));
        this.store.dispatch(
          CoreActions.setDeviceType({ payload: EDeviceType.TABLET })
        );
      }
      if (size > 992) {
        this.store.dispatch(CoreActions.setIsDesktop({ payload: true }));
        this.store.dispatch(
          CoreActions.setDeviceType({ payload: EDeviceType.DESKTOP })
        );
      }
    });
  }

  ngAfterViewInit() {
    this.createScript();
    this.setValueAnalytics();
    if (environment.enableHotjarTracking) {
      ((h: any, o, t, j, a, r) => { 
        h.hj = h.hj || function(){
          // eslint-disable-next-line prefer-rest-params
          (h.hj.q = h.hj.q || []).push(arguments)
        }; 
        h._hjSettings = { hjid: environment.hotjarSiteId, hjsv: environment.hotjarVersion }; 
        a = o.getElementsByTagName('head')[0]; 
        r = o.createElement('script');
        r.async = 1; 
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; 
        a.appendChild(r); 
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv='); 
    }
  }

  createScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = environment.urlAnalytics + environment.analyticsId;
    document.querySelector('head').appendChild(script);
  }

  setValueAnalytics() {
    const script = document.createElement('script');
    script.innerHTML = ` window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config','${environment.analyticsId}');`;
    document.querySelector('head').appendChild(script);
  }
}
