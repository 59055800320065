<div class="participants-list">
    <ng-container>
        <nz-collapse [nzAccordion]="participants.observers?.length" [nzBordered]="false" nzGhost *ngIf=" participants">
            <nz-collapse-panel [nzHeader]="null" [nzActive]="true" [nzShowArrow]="false">
                <div *ngFor="let participant of participants.naturalPersons" class="participant">
                    <ng-container *ngTemplateOutlet="naturalPerson; context: { participant }"></ng-container>
                </div>
                <div *ngFor="let participant of participants.legalPersons" class="participant">
                    <ng-container *ngTemplateOutlet="legalPerson; context: { participant }"></ng-container>
                </div>
            </nz-collapse-panel>

            <nz-collapse-panel *ngIf="(participants$ | async)?.observers?.length" [nzHeader]="observersTitle"
                [nzActive]="true" [nzShowArrow]="false">
                <ng-template #observersTitle>
                    <div class="attachments-tab-title">
                        <h6>{{ 'XML_SCREEN.TABS.COLLABORATORS.TYPES.OBSERVERS' | translate }}</h6>
                    </div>
                </ng-template>
                <!-- ? Begin: Observers loop -->
                <div *ngFor="let participant of participants.observers" class="participant">
                    <div class="participant-name">
                        {{ participant.name }}
                        <span class="participant-label" *ngIf="participant.isAuthor">{{
                            'XML_SCREEN.TABS.COLLABORATORS.AUTHOR' | translate
                            }}</span>
                    </div>
                    <div class="participant-info">
                        <div class="participant-info-row">
                            <div class="label">
                                {{ 'XML_SCREEN.TABS.COLLABORATORS.EMAIL' | translate }}
                            </div>
                            <div class="value">{{ participant.email }}</div>
                        </div>
                        <div class="participant-info-row">
                            <div class="label">
                                {{ 'XML_SCREEN.TABS.COLLABORATORS.EMAIL' | translate }}
                            </div>
                            <div class="value">{{ participant.companyName }}</div>
                        </div>
                    </div>
                </div>
                <!-- ? End: Observers loop -->
            </nz-collapse-panel>
        </nz-collapse>
    </ng-container>
</div>

<ng-template #legalPerson let-participant="participant">
    <ng-container *ngTemplateOutlet="
        participantName;
        context: { participant, name: participant.companyName }
      "></ng-container>
    <ng-container *ngTemplateOutlet="
        participantInfo;
        context: {
          email: participant.email,
          expiry: participant.expiryAt,
          taxId: participant.companyTaxId,
          idRequested: participant.idDocumentRequest
        }
      ">
    </ng-container>
    <div class="participant-representatives">
        <div class="representatives-label">
            {{ 'XML_SCREEN.TABS.COLLABORATORS.BEHALF_OF' | translate }}
            <nz-badge nzSize="small" nzStandalone [nzCount]="participant?.legalRepresentatives?.length" [nzStyle]="{
            backgroundColor: '#E6F7FF',
            color: '#1890FF',
            boxShadow: '0 0 0 1px #E6F7FF'
          }"></nz-badge>
        </div>
        <div class="legal-representatives">
            <div *ngFor="let representative of participant.legalRepresentatives" class="participant">
                <ng-container *ngTemplateOutlet="
              naturalPerson;
              context: { participant: representative, isLr: true }
            ">
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #naturalPerson let-participant="participant" let-isLr="isLr">
    <ng-container *ngTemplateOutlet="
        participantName;
        context: { participant, name: participant.name, isLr }
      "></ng-container>
    <ng-container *ngTemplateOutlet="participantHistory; context: { participant }"></ng-container>
    <ng-container *ngTemplateOutlet="
        participantInfo;
        context: {
          email: participant.email,
          expiry: participant.expiryAt,
          proccesedDate: participant.proccesedDate,
          taxId: participant.taxId,
          idRequested: participant.idDocumentRequest
        }
      ">
    </ng-container>
</ng-template>
<ng-template #participantName let-name="name" let-participant="participant" let-isLr="isLr"
    let-isApplicant="isApplicant" let-isRequest="isRequest" let-mandatoryApproval="mandatoryApproval">
    <div class="participant-name">
        {{ name }}
        <div class="participant-label">
            <span *ngIf="participant.isAuthor">{{
                'XML_SCREEN.TABS.COLLABORATORS.AUTHOR' | translate
                }}</span>
            <span *ngIf="isLr">{{ 'XML_SCREEN.TABS.COLLABORATORS.TYPES.LR' | translate }}</span>
        </div>
    </div>
</ng-template>
<ng-template #participantInfo let-email="email" let-expiry="expiry" let-proccesedDate="proccesedDate" let-taxId="taxId"
    let-idRequested="idRequested">
    <div class="participant-info">
        <div class="participant-info-row">
            <div class="label">{{ 'XML_SCREEN.TABS.COLLABORATORS.EMAIL' | translate }}</div>
            <div class="value">{{ email }}</div>
        </div>
        <div class="participant-info-row" *ngIf="expiry">
            <div class="label">{{ 'XML_SCREEN.TABS.COLLABORATORS.EXPIRY' | translate }}</div>
            <div class="value">
                {{ expiry * 1000 | date: 'dd / MMM / YYYY' }}
            </div>
        </div>
        <div class="participant-info-row" *ngIf="proccesedDate">
            <div class="label">{{ 'XML_SCREEN.TABS.COLLABORATORS.SIGN_DATE' | translate }}</div>
            <div class="value">
                {{ proccesedDate * 1000 | date: 'dd / MMM / YYYY' }}
            </div>
        </div>
        <div class="participant-info-row" *ngIf="signingMethod === signingMethods.DIGITAL_SIGNATURE">
            <div class="label">{{ 'XML_SCREEN.TABS.COLLABORATORS.TAX_ID' | translate }}</div>
            <div class="value">{{ taxId }}</div>
        </div>
        <div class="participant-info-row">
            <div class="label">
                {{ 'XML_SCREEN.TABS.COLLABORATORS.ID' | translate }}
            </div>
            <div class="value">
                {{ idRequest ? 'XML_SCREEN.TABS.COLLABORATORS.ID_REQUESTED.YES' :
                ('XML_SCREEN.TABS.COLLABORATORS.ID_REQUESTED.NO' | translate) }}
            </div>
        </div>
    </div>
</ng-template>