import * as termsActions from '@literax/store/terms/terms.actions';

import { Action, createReducer, on } from "@ngrx/store";
import { ITermsState, initialTermsState } from "./terms.state";

const termReducer = createReducer(
  initialTermsState,
  on(termsActions.GetTermsSuccess, (state, action) => ({ 
    ...state, terms_of_service: action.payload
  })),
);

export function termsReducer(
  state: ITermsState,
  action: Action
) {
  return termReducer(state, action);
}
