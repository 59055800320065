import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { ETabsUsers, IReportFilter } from '@literax/models/reports.model';
import { BaseService } from '@literax/services/base-config/base.service';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private base: BaseService) {}

  urlFilters = '';

  getReport(filter: IReportFilter): Observable<ServiceResponse> {
    const url = `/statistics?${this.generateUrlByFilters(filter)}`;
    return this.base.get(url);
  }

  getSigners(signer: string): Observable<ServiceResponse> {
    let params = new HttpParams();
    params = params.append('query', signer);
    return this.base.get(`/clients/sign_requests?`, params);
  }

  exportReport(document: string, filter: IReportFilter): Observable<Blob> {
    let url = `/statistics.xlsx?${this.generateUrlByFilters(filter)}`;
    if (document === ETabsUsers.teams) {
      url += '&type_xlsx_data=deparments_consumed';
    }
    if (document === ETabsUsers.members) {
      url += '&type_xlsx_data=users_consumed';
    }
    if (document === ETabsUsers.companies) {
      url += '&type_xlsx_data=companies_consumed';
    }
    return this.base.getBlob(url);
  }

  saveDownloadFileAs(response: Blob, filename: string): void {
    saveAs(response, filename);
  }

  generateUrlByFilters(filter: IReportFilter): string {
    let url = '';

    url += `period_month=${filter.month}`;

    url += `&period_year=${filter.year}`;

    url += '&permitted_flows[]=signature';

    url += `${
      filter.user_department !== 0
        ? `&user_department=${filter.user_department}`
        : ''
    }`;

    filter.departments?.forEach((department) => {
      url += `&departments[]=${department}`;
    });

    filter.companies?.forEach((company) => {
      url += `&companies[]=${company}`;
    });

    filter.signers1?.forEach((signer) => {
      url += `&sign_requests1[]=${signer}`;
    });

    filter.signers2?.map((signer) => {
      url += `&sign_requests2[]=${signer}`;
    });

    return url;
  }
}
