import * as statusActions from './status.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { IStatus, IStatusState, initialStatusState } from './status.state';

const statusReducer = createReducer(
  initialStatusState,
  on(statusActions.getStatusesSuccess, (state, action) => ({ ...state, statuses: action.payload as IStatus[] }))
);

export function statusReducers(state: IStatusState | undefined, action: Action) {
  return statusReducer(state, action);
}
