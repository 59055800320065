import { createSelector } from '@ngrx/store';
import { IAppState } from '../../../../store';
import { IAttachmentState } from './attachment.state';

const selectAttachments = (state: IAppState) => state.attachments;
const selectErrors = (state: IAttachmentState, key: string): any => {
  return state[key] && state[key].error && state[key].error.detail;
};

export const selectAttachmentList = createSelector(
  selectAttachments,
  (state: IAttachmentState) => state.attachments
);
export const selectuploadErrors = createSelector(
  selectAttachments,
  (state: IAttachmentState) => state
);

export const selectAttachment = createSelector(
  selectAttachments,
  (state: IAttachmentState) => state && state.selectedAttachment
);

export const selectCreateAttachmentNameError = createSelector(
  selectAttachments,
  (state: IAttachmentState) => {
    const errors = selectErrors(state, 'uploadErrors');
    return errors && errors.name;
  }
);

export const selectCreateAttachmentFileError = createSelector(
  selectAttachments,
  (state: IAttachmentState) => {
    const errors = selectErrors(state, 'uploadErrors');
    return errors && errors.file;
  }
);

export const selectCreateAttachmentUploadSuccess = createSelector(
  selectAttachments,
  (state: IAttachmentState) => state.uploadErrors && state.uploadErrors.success
);

export const selectLastAttachmentUploaded = createSelector(
  selectAttachments,
  (state: IAttachmentState) => state?.lastAttachment
);

export const selectGetAttachmentError = createSelector(
  selectAttachments,
  (state: IAttachmentState) => {
    return state.getAttachmentError;
  }
);
