import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

/**
 * Component to be extended by any virtual scroll component in the application
 */
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
})
export class VirtualScrollComponent<T = any> implements OnInit {
  @ContentChild(TemplateRef) rowTemplate: TemplateRef<any>;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;
  @Input() height: string;
  @Input() rowTem: TemplateRef<any>;
  @Input() itemRowSize: number;
  @Input() ignoreNextBatch: boolean;
  @Input() list: T[] = [];
  @Output() nextBatchEmitter = new EventEmitter<void>();
  previousOffset = 0;

  constructor() {}
  ngOnInit(): void {}

  nextBatch(event) {
    if (this.ignoreNextBatch) {
      return;
    }
    const { end } = this.viewport.getRenderedRange();
    const total = this.viewport.getDataLength();
    const offset = this.viewport.measureScrollOffset();

    if (end === total && end > 0 && total > 0 && this.previousOffset < offset) {
      this.nextBatchEmitter.emit();
    }
    this.previousOffset = offset;
  }
}
