import { createReducer, on } from '@ngrx/store';
import { initialCompanyState } from '@literax/modules/configurations/store/company/company.state';
import * as companyActions from './company.actions';

const reducers = createReducer(
  initialCompanyState,
  on(companyActions.GetCompaniesSuccess, (state, action) => ({
    ...state,
    companies: action.payload
  }))
);

export function companyReducers(state, action) {
  return reducers(state, action);
}
