<literax-buttons-bar-layout>
  <nz-space slot="left">
    <ng-container *ngIf="!isConfigScreen">
      <!-- ! boton para eliminar documento (autor) -->
      <ng-container *ngIf="status === statusTypes.DRAFT"><button nz-button *nzSpaceItem nzType="primary" nzDanger
          (click)="action(actions.application.DELETE)">
          <span nz-icon>
            <i class="fal fa-trash-alt"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.DELETE' | translate }}
        </button></ng-container>
      <!-- ! boton para cancelar proceso (autor) -->
      <ng-container *ngIf="
          (status === statusTypes.IN_SIGN ||
            status === statusTypes.DATA_COLLECTION) &&
          isAuthor
        "><button nz-button *nzSpaceItem nzType="primary" nzDanger (click)="action(actions.application.CANCEL)">
          <span nz-icon>
            <i class="fal fa-times-circle"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.CANCEL' | translate }}
        </button></ng-container>
      <!-- ! boton para rechazar la firma (participante) -->
      <ng-container *ngIf="status === statusTypes.IN_SIGN && isParticipant"><button nz-button *nzSpaceItem
          nzType="primary" nzDanger (click)="action(actions.application.REJECT)">
          Rechazar
        </button></ng-container>
    </ng-container>
    <!-- ! boton para regresar a workspace (autor, pantalla de configuracion) -->
    <ng-container *ngIf="isConfigScreen"><button nz-button *nzSpaceItem nzType="primary" nzDanger
        (click)="action(actions.application.BACK_TO_WORKSPACE)">
        <span nz-icon>
          <i class="fal fa-arrow-to-left"></i>
        </span>
        {{ 'WORKSPACE.FOOTER.BACK' | translate }}
      </button></ng-container>
  </nz-space>

  <nz-space slot="right">
    <ng-container *ngIf="!isConfigScreen">
      <!-- ! boton de marcar como (autor) -->
      <ng-container *ngIf="isAuthor && (status === statusTypes.REJECTED || status === statusTypes.EXPIRED)">
        <button nz-button *nzSpaceItem nz-dropdown nzType="default" nzTrigger="click" [nzDropdownMenu]="returnToDraft">
          <span nz-icon>
            <i class="fal fa-share-alt"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND_TO' | translate }}
        </button>
      </ng-container>

      <!-- ! boton descarga de documento(s) -->
      <ng-container *ngIf="isAuthor">
        <button nz-button *nzSpaceItem nzType="default" (click)="action(actions.application.DOWNLOAD)">
          <span nz-icon>
            <i class="far fa-cloud-download"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.DOWNLOAD' | translate }}
        </button>
      </ng-container>
      <!-- ! boton enviar doc. firmado -->
      <ng-container *ngIf="
         status === statusTypes.SIGNED
        ">
        <button nz-button *nzSpaceItem nzType="default" (click)="sendSignedDocument()">
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND_SIGNED_DOCUMENT' | translate }}
        </button>
      </ng-container>
      <!-- ! boton ver firmas -->
      <ng-container *ngIf="
          (status === statusTypes.IN_SIGN || status === statusTypes.SIGNED) &&
          isAuthor
        ">
        <button nz-button *nzSpaceItem nzType="primary" (click)="action(actions.application.VIEW_SIGNATURES)">
          <span nz-icon>
            <i class="fal fa-pen-fancy"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SIGNATURES' | translate }}
        </button>
      </ng-container>
      <!-- ! boton envio a firma o recoleccion -->
      <ng-container *ngIf="isAuthor && flowWithTemplate && status === statusTypes.DRAFT">
        <!-- ! Envio a firma o recoleccion -->
        <button nz-button *nzSpaceItem nz-dropdown nzType="primary" nzTrigger="click"
          [nzDropdownMenu]="singOrDataCollection" [disabled]="!haveParticipants">
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND' | translate }}
        </button>
      </ng-container>

      <!-- ! boton para firma (participante) -->
      <ng-container *ngIf="status === statusTypes.IN_SIGN && isParticipant">
        <button nz-button *nzSpaceItem nzType="primary" (click)="action(actions.signature.SIGN)"
          [disabled]="attachmentReaders || (hasQuote && formBuilderInvalid)">
          <span nz-icon>
            <i class="fal fa-pen-fancy"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SIGN' | translate }}
        </button>
      </ng-container>
      <!-- ! boton para "guardar" anexo docx  -->
      <ng-container *ngIf="
          isViewingDocxAttachment &&
          hasPdfVersion === false &&
          status !== statusTypes.IN_SIGN &&
          status !== statusTypes.EXPIRED
        ">
        <button nz-button *nzSpaceItem nzType="primary" (click)="action(actions.application.SAVE)">
          <span nz-icon>
            <i class="fal fa-save"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SAVE' | translate }}
        </button>
      </ng-container>
      <!-- ! boton para "guardar" documento de plantilla para recolección  -->
      <ng-container *ngIf="status === statusTypes.DATA_COLLECTION && isParticipant">
        <button nz-button *nzSpaceItem nzType="primary" [disabled]="formBuilderInvalid"
          (click)="action(actions.signature.SEND_SIGN)">
          <span nz-icon>
            <i class="fal fa-save"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SAVE' | translate }}
        </button>
      </ng-container>
      <!-- ! boton envio a firma (autor) -->
      <ng-container
        *ngIf="status === statusTypes.DRAFT && flowWithTemplate === false && attachment?.viewerType === viewerModes.PDF">
        <button nz-button *nzSpaceItem nzType="primary" [disabled]="!haveParticipants"
          (click)="action(actions.signature.SEND_SIGN)">
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND_TO_SIGN' | translate }}
        </button>
      </ng-container>
      <!-- ! boton para enviar pagare a edicion -->
      <ng-container *ngIf="
          isPromissoryNoteFlow &&
          status !== statusTypes.IN_EFFECT &&
          status !== statusTypes.IN_SIGN
        ">
        <button nz-button *nzSpaceItem nzType="primary" (click)="action(actions.application.PROMISSORY_NOTE_EDIT)">
          <span nz-icon>
            <i class="fas fa-pen-fancy"></i>
          </span>
          {{ 'PROMISSORY_NOTES.EDIT' | translate }}
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isConfigScreen">
      <!-- ! boton envio a firma (autor) -->
      <ng-container *ngIf="
          signingMethod === signingMethodTypes.DIGITAL_SIGNATURE &&
          !flowWithTemplate &&
          status === statusTypes.DRAFT
        ">
        <button nz-button *nzSpaceItem nzType="primary" [disabled]="!haveParticipants"
          (click)="action(actions.signature.SEND_SIGN)">
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND_TO_SIGN' | translate }}
        </button>
      </ng-container>

      <!-- ! boton envio a firma o recoleccion -->
      <ng-container *ngIf="
          isAuthor &&
          flowWithTemplate &&
          status === statusTypes.DRAFT &&
          signingMethod === signingMethodTypes.DIGITAL_SIGNATURE
        ">
        <!-- ! Envio a firma o recoleccion -->
        <button nz-button *nzSpaceItem nz-dropdown nzType="primary" nzTrigger="click"
          [nzDropdownMenu]="singOrDataCollection" [disabled]="!haveParticipants">
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </nz-space>
  <nz-dropdown-menu #singOrDataCollection="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <button nz-button nzType="primary" nzBlock (click)="action('send_collection')">
          {{ 'WORKSPACE.FOOTER.SEND_TO_COLLECT' | translate }}
        </button>
      </li>
      <li nz-menu-item>
        <button [disabled]="formBuilderInvalid" nz-button nzBlock nzType="primary"
          (click)="action(actions.signature.SEND_SIGN)">
          {{ 'WORKSPACE.FOOTER.SEND_TO_SIGN' | translate }}
        </button>
      </li>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #returnToDraft="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <button nz-button nzType="primary" nzBlock (click)="action(actions.application.TO_DRAFT)">
          {{ 'WORKSPACE.FOOTER.ACTIONS.TO_DRAFT' | translate }}
        </button>
      </li>
    </ul>
  </nz-dropdown-menu>
</literax-buttons-bar-layout>