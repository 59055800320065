import {
  Directive,
  OnInit,
  InjectionToken,
  Inject,
  Optional,
  Host,
  ComponentRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  OnDestroy
} from '@angular/core';
import { NgControl } from '@angular/forms';

import { merge } from 'rxjs';
import { FormSubmitDirective } from './form-submit.directive';
import { EMPTY, Observable } from 'rxjs';
import { ControlErrorComponent } from '../../control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export const defaultErrors = {
  matDatepickerMin: (error) => 'FORM.ERRORS.MAT_DATEPICKER_MIN',
  matDatepickerParse: (error) => 'FORM.ERRORS.MAT_DATEPICKER_PARSE',
  nameFormat: ({}) => 'FORM.ERRORS.NAME_FORMAT',
  requiredFileType: (error: boolean) => 'FORM.ERRORS.REQUIRED_FILE_TYPE',
  maxFileSize: (error: boolean) => 'FORM.ERRORS.MAX_FILE_SIZE',
  expiryGTNow: (error: boolean) => 'FORM.ERRORS.EXPIRY'
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
@UntilDestroy()
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  submit$: Observable<Event>;
  ngSubmit$: Observable<Event>;
  ref: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;

  constructor(
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private control: NgControl,
    private translate: TranslateService,
    @Optional() controlErrorContainer: ControlErrorContainerDirective,
    @Optional() @Host() private form: FormSubmitDirective,
    @Inject(FORM_ERRORS) private errors
  ) {
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  touchControls(controls) {
    if (controls) {
      const keys = Object.keys(controls);
      const controlsKey = 'controls';
      for (const key of keys) {
        controls[key].markAsTouched();
        if (
          controls[key][controlsKey] &&
          Array.isArray(controls[key][controlsKey])
        ) {
          this.touchControls(controls[key][controlsKey]);
        } else if (controls[key][controlsKey]) {
          this.touchControls(controls[key][controlsKey]);
        }
      }
    }
  }

  ngOnInit(): void {
    this.submit$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.form.formGroupDirective) {
        const controls = this.form.formGroupDirective.form.controls;
        this.touchControls(controls);
        this.form.formGroupDirective.form.markAsTouched();
      }
    });

    merge(this.submit$, this.control.valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const controlErrors = this.control.errors;
        if (controlErrors) {
          const firstKey = Object.keys(controlErrors)[0];
          const getError = this.errors[firstKey] || ((error: boolean) => '');
          const text = getError(controlErrors[firstKey]);
          this.setError(text, controlErrors);
        } else if (this.ref) {
          this.setError(null);
        }
      });
  }

  ngOnDestroy(): void {}

  setError(text: string, controlErrors?: any) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(
        ControlErrorComponent
      );
      this.ref = this.container.createComponent(factory);
    }
    if (text) {
      this.ref.instance.text = text;
      this.ref.instance.params = controlErrors;
      // this.translate.get(text, controlErrors).subscribe(i18nText => this.ref.instance.text = i18nText);
    } else {
      this.ref.instance.text = null;
    }
  }
}
