import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISignatureResponse } from '@literax/models/http/api/signature.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { IUser } from '@literax/models/user.model';
import { ISignRequest } from '@literax/modules/documents/store/document/document.state';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-approvers-list-container',
  templateUrl: './approvers-list-container.component.html',
  styleUrls: ['./approvers-list-container.component.scss']
})
export class ApproversListContainerComponent implements OnInit {
  @Input() document: IDocument;
  @Input() ApproverRequest: ISignRequest[];
  @Input() user: IUser;

  @Output() addApprover: EventEmitter<void> = new EventEmitter<void>();
  @Output() actionButton = new EventEmitter();
  @Output() historyButton: EventEmitter<any> = new EventEmitter<any>();

  contentVisible = true;
  signatures: { [key: string]: ISignatureResponse } = {};

  isUserLoginObserver = false;

  constructor() {}

  ngOnInit(): void {}

  toggleTabContent() {
    this.contentVisible = !this.contentVisible;
  }
}
