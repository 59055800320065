import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { UsersServices } from '@literax/components/configurations/users/services/users.services';
import { pluck, tap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfirmEmailGuard implements CanActivate {
  tokenActivate: string;
  constructor(
    private router: Router,
    private clientUsersServices: UsersServices,
    private store: Store<IAppState>
  ) {}
  canActivate(next: ActivatedRouteSnapshot) {
    return this.isRouteAllowed(next);
  }

  isRouteAllowed(route: ActivatedRouteSnapshot) {
    this.tokenActivate = route.paramMap.get('token');
    const locale = route.queryParams.locale;
    return this.redirectAndCancelNavigation(this.tokenActivate,locale);
  }

  redirectAndCancelNavigation(hasAccess,locale) {
    if (hasAccess) {
      this.router.navigate(['/register_client/' + hasAccess],{
        queryParams:{ locale:locale || 'es'}
      });
      return true;
    }
    return false;
  }
}
