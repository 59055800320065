import { createReducer, Action, on } from '@ngrx/store';
import { initialRolState, IRolState } from './rol.state';
import * as rolsAcctions from '@literax/store/rol/rol.actions';

const reducers = createReducer(
  initialRolState,
  on(rolsAcctions.GetRolesSuccess, (state, action) => ({
    ...state,
    roles: action.payload
  })),
  on(rolsAcctions.GetRolesError, (state, action) => ({
    ...state,
    rolesError: action.payload
  }))
);

export function rolsReducers(state: IRolState, action: Action) {
  return reducers(state, action);
}
