import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  IDefaultUser,
  IUser,
  IUserConfirmation,
} from '../models/users.interface';

import { AppUtils } from '@literax/utils/app.utils';
import { BaseService } from '@literax/services/base-config/base.service';
import { IFilterParams } from '@literax/interfaces/filter-param.interface';
import { IPagination } from '@literax/models/pagination.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersServices {
  uri = environment.apiEndpoint + '/' + environment.apiVersion;

  constructor(private base: BaseService, private http: HttpClient) {}

  getDashboard(): Observable<ServiceResponse> {
    return this.base.get(`/dashboard/users`);
  }

  getAll(
    pagination: IPagination,
    sortColumn: string = '',
    filterParams?: IFilterParams
  ): Observable<ServiceResponse> {
    return this.base.get(
      `/client_users?sort=${sortColumn}&page=${pagination.currentPage}&per_page=${pagination.pageSize}${AppUtils.parseFilterParams(
        filterParams
      )}`
    );
  }
  save(client_user: IUser): Observable<ServiceResponse> {
    return this.base.post(`/client_users`, {
      client_user,
    });
  }
  update(client_user: IUser): Observable<ServiceResponse> {
    return this.base.patch(`/client_users/` + client_user.id, {
      client_user,
    });
  }
  delete(userId: number) {
    return this.base.delete(`/client_users/` + userId);
  }

  userConfirm(user: IUserConfirmation, token: string) {
    const header = new HttpHeaders({
      'content-type': 'application/json',
      locale: JSON.parse(localStorage.getItem('config')).lang,
      usertoken: token,
    });
    return this.http.post(this.uri + `/users`, { user }, { headers: header });
  }

  userLoginConfirm(
    token: string,
    b2cToken: string
  ): Observable<ServiceResponse> {
    const body = { join_with_current_user: true };
    return this.http.post<ServiceResponse>(this.uri + `/users`, body, {
      headers: { usertoken: token, Authorization: `bearer ${b2cToken}` },
    });
  }

  info = (email: string): Observable<ServiceResponse> =>
    this.base.get(`/users/${email}/info`, new HttpParams(), true);

  getUserProfiles(): Observable<ServiceResponse> {
    return this.base.get(`/profiles`);
  }

  getReceivers = (excludeOneself: boolean = false): Observable<ServiceResponse> =>
    this.base.get(`/departments/receivers?excludeOneself=${excludeOneself}`, new HttpParams(), true);

  setDefaultUser(defaultUser: IDefaultUser): Observable<ServiceResponse> {
    return this.base.put(`/client_users/set_default/` + defaultUser.uuid, {
      client_user: { is_default: defaultUser.is_default },
    });
  }

  disableTaxMessage(): Observable<ServiceResponse> {
    return this.base.put(`/users`, {
      user: {
        disable_tax_validation: true,
      },
    });
  }
}
