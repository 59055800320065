import {
  IDocument,
  ILegalRepresentative
} from '@literax/models/http/document/document.model';
import { IDocumentState, ISignRequest } from './document.state';

import { IAppState } from '../../../../store';
import { WorkspaceState } from '../workspace/workspace.state';
import { createSelector } from '@ngrx/store';
import { selectWorkspace } from '../workspace/workspace.selectors';

function singRequestLegalRepresentative(
  legalRepresentative: ILegalRepresentative[]
): ISignRequest[] {
  const plainLegalRepresentatives = [];
  if (legalRepresentative) {
    legalRepresentative.forEach((legal) => {
      legal.legal_representatives.forEach((legalPerson) => {
        plainLegalRepresentatives.push(legalPerson);
      });
    });
  }
  return plainLegalRepresentatives;
}
const selectDocuments = (state: IAppState) => state.documents;

const selectErrors = (state: IDocumentState, key: string): any => {
  return state[key] && state[key].error && state[key].error.detail;
};

export const selectDocumentList = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.documents
);

export const selectPaginationData = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.pagination
);

export const selectDocument = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.selectedDocument
);

export const selectFacialBiometricsRequired = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.facialRecognitionRequired
);

export const selectIdentificationRequired = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.identificationRequired
);

export const signatureMethodOfDocument = createSelector(
  selectDocuments,
  (state: IDocumentState) => state?.selectedDocument?.signing_method
);

export const selectDocumentHierarchy = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.selectedDocument?.hierarchy
);

export const selectAllDocumentSignRequests = createSelector(
  selectDocument,
  (state: IDocument) => {
    if (state?.user_signer === null) {
      return ((state && state.sign_requests) || []).sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
    } else {
      const plainLegalRepresentatives = singRequestLegalRepresentative(
        state?.legal_representative
      );
      return (
        state &&
        [...state.sign_requests, ...plainLegalRepresentatives].sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        })
      );
    }
  }
);

export const selectAllSignersRequests = createSelector(
  selectDocument,
  (state: IDocument) => {
    const plainLegalRepresentatives = singRequestLegalRepresentative(
      state?.legal_representative
    );
    return (
      state &&
      [...state.sign_requests, ...plainLegalRepresentatives].sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      })
    );
  }
);

export const selectSinRequestDocumentConfiguration = createSelector(
  selectDocument,
  (state: IDocument) => {
    if (state?.legal_representative != null) {
      const arrayLegalPerson = state?.legal_representative;
      const newArray = arrayLegalPerson.map((legalPerson) => {
        let newLegalPerson = { ...legalPerson?.legal_representatives[0] };
        if (legalPerson.company_name !== null) {
          newLegalPerson = {
            ...legalPerson?.legal_representatives[0],
            name: legalPerson.legal_representatives[0].company_name,
            rfc: legalPerson.legal_representatives[0].company_rfc,
            legal_representatives: legalPerson.legal_representatives,
            email: legalPerson.legal_representatives[0].email,
            signature_type_id: 2
          };
        }
        return newLegalPerson;
      });
      return [
        ...state.sign_requests.filter(
          (elemento) =>
            elemento.company_name === null &&
            elemento.signatory_role.name !== 'reviewer'
        ),
        ...newArray
      ].sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        if (a.order < b.order) {
          return -1;
        }
        return 0;
      });
    } else {
      return (
        state &&
        [
          ...state.sign_requests.filter(
            (signer) => signer.signatory_role.name !== 'reviewer'
          )
        ].sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return 0;
        })
      );
    }
  }
);

export const selectAllDocumentObserverRequests = createSelector(
  selectDocument,
  (state: IDocument) => state?.observers
);

export const selectCreateDocumentNameError = createSelector(
  selectDocuments,
  (state: IDocumentState) => {
    const errors = selectErrors(state, 'uploadErrors');
    return errors && errors.name;
  }
);

export const selectCreateDocumentFileError = createSelector(
  selectDocuments,
  (state: IDocumentState) => {
    const errors = selectErrors(state, 'uploadErrors');
    return errors && errors.file;
  }
);

export const selectCreateDocumentUploadSuccess = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.uploadErrors && state.uploadErrors.success
);

export const selectLastDocumentUploaded = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.lastDocument
);

export const selectCurrentPage = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.pagination.currentPage
);

export const selectGetDocumentError = createSelector(
  selectDocuments,
  (state: IDocumentState) => {
    return state.getDocumentError;
  }
);
export const selectGetDocumentAttachmentError = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.getAttachmentError
);

export const selectAttachment = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.selectAttachment?.text.split(',')[1]
);

export const selectCurrentAttachment = createSelector(
  selectDocuments,
  (state: IDocumentState) => state?.selectAttachment
);

export const selectAttachmentData = createSelector(
  selectDocuments,
  (state: IDocumentState) => state && state.selectAttachment
);

export const typePromissoryAttachment = createSelector(
  selectDocuments,
  (state: IDocumentState) =>
    state && state.selectAttachment?.kind === 'promissory_note' ? true : false
);

export const attachments = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.attachments
);

export const selectTabsFlows = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.tabsFlows
);

export const searchDocument = createSelector(
  selectDocuments,
  (state: IDocumentState) => {
    if (state.navbarDocument !== null) {
      return [...(state.navbarDocument || [])];
    }
  }
);

export const searchDocumentSuggestions = createSelector(
  selectDocuments,
  (state: IDocumentState) => {
    if (state.navbarDocument !== null) {
      return state.navbarDocument || [];
    }
  }
);

export const searchDocumentFirstSuggestion = createSelector(
  searchDocumentSuggestions,
  (state: any[]) => {
    if (state?.length > 0) {
      return state[0];
    } else {
      return null;
    }
  }
);

export const selectOnlyofficeKey = createSelector(
  selectDocument,
  (state: IDocumentState) => state.onlyoffice_key
);

export const selectReviewers = createSelector(
  selectWorkspace,
  (state: WorkspaceState) => state.document.signRequests.naturalPersons
);

export const selectApprovers = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.approvers
);

export const selectLastAttachmentUploaded = createSelector(
  selectDocuments,
  (state) => state?.lastUploadedAttachment
);

export const selectMinApprovers = createSelector(
  selectDocument,
  (state: IDocument) => state?.minimum_approvals
);

export const selectCommentRejected = createSelector(
  selectDocuments,
  (state) => state?.commentRejected
);

export const selectPendingMessages = createSelector(
  selectDocuments,
  (state: IDocumentState) =>
    state.pending_seend > 99
      ? '99+'
      : state.pending_seend === 0
      ? null
      : state.pending_seend.toString()
);

export const selectDocumentViewSignature = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.selectedDocumentViewSignature
);

export const selectAcountStatuses = createSelector(
  selectDocuments,
  (state: IDocumentState) => state.acumulatedStatusesTab
);
