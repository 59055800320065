import * as termsFreeActions from '@literax/store/free-terms/free-terms.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ITermsResponse } from '@literax/models/terms.model';
import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { TermsSignService } from '@literax/services/sign/terms-sign.service';
import { of } from 'rxjs';

@Injectable()
export class FreeTermsEffects {
  getTermsFree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(termsFreeActions.GetTermsFree),
      switchMap((action) =>
        this.termsService.getTerms(action.token).pipe(
          map((response: ServiceResponse) =>
            termsFreeActions.GetTermsFreeSuccess({
              payload: response.terms_of_service as ITermsResponse,
            })
          ),
          catchError((error) =>
            of(termsFreeActions.GetTermsFreeError({ payload: error }))
          )
        )
      )
    )
  );

  acceptTermsFree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(termsFreeActions.AcceptTermsFree),
      switchMap((action) =>
        this.termsService
          .acceptTerms(action.signRequestId, action.documentId, action.token)
          .pipe(
            map((response: ServiceResponse) =>
              termsFreeActions.AcceptTermsFreeSuccess()
            ),
            catchError((error) =>
              of(termsFreeActions.AcceptTermsFreeError({ payload: error }))
            )
          )
      )
    )
  );

  constructor(
    private termsService: TermsSignService,
    private actions$: Actions
  ) {}
}
