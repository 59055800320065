import { Action, createReducer, on } from '@ngrx/store';
import { CoreActions } from './core.actions';
import { ICoreState, initialAplicationState } from './core.state';

const CoreReducer = createReducer(
  initialAplicationState,
  on(CoreActions.setIsDesktop, (state, action) => ({
    ...state,
    isDesktop: action.payload,
  })),
  on(CoreActions.setDeviceType, (state, action) => ({
    ...state,
    deviceType: action.payload,
  }))
);

export function coreReducers(state: ICoreState, action: Action) {
  return CoreReducer(state, action);
}
