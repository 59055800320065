<literax-auth-navbar
  [title]="'AUTH.TITLE.NAVBAR' | translate"
></literax-auth-navbar>
<div class="content">
  <div class="mat-elevation-z6 mat-modal">
    <span class="title">
      {{ 'E404.TITLE' | translate }}
    </span>
    <br />
    <span class="subtitle">
      {{ 'E404.SUBTITLE' | translate }}
    </span>
    <br />
    <button class="primary" (click)="goToLiterax()">
      {{ 'E404.BUTTON' | translate }}
    </button>
  </div>
  <div class="footer-content">
    <div class="links">
      <span class="privacy" (click)="goToPrivacy()">
        {{ 'E404.NOTICE_OF_PRIVACY' | translate }}
      </span>
      <span class="pipe">|</span>
      <span class="terms" (click)="goToTerms()">
        {{ 'E404.TERMS_AND_CONDITIONS' | translate }}
      </span>
    </div>
    <div class="legend">
      <span>
        {{ 'E404.LEGEND_1' | translate }}
      </span>
      <br />
      <span>
        {{ 'E404.LEGEND_2' | translate }}
      </span>
    </div>
  </div>
</div>
