import {
  ISignatureResponse,
} from '@literax/models/http/api/signature.model';
import { XmlValidationResponse } from '../validator.interfaces';
import { ISignatureValidatorResponse } from '@literax/models/signature.model';

export interface IValidatorState {
  signatureResult: ISignatureResponse;
  showSignatureResult: ISignatureValidatorResponse;
  XMLValidatedDocument: XmlValidationResponse;
  errors: any;
}

export const initialValidatorState: IValidatorState = {
  signatureResult: null,
  showSignatureResult: null,
  XMLValidatedDocument: null,
  errors: null,
};
