<div class="wrapper">
  <div id="main-document" class="section">
    <div class="subtitle">
      {{ 'DOCUMENT-PREVIEW.DOCUMENTS-TAB.MAIN_DOCUMENT' | translate }}
    </div>
    <literax-attachment
      [attachment]="mainDocument"
      [delete]="false"
      [actions]="edit"
      [disabledActions]="disabledActions"
      [main]="true"
      [showCheckAttachment]="showCheckAttachment"
      [workarea]="workarea"
      (attachmentId)="attachmentDetail($event)"
      (checkId)="checkAttachment($event)"
      (replaceAttachment)="replaceAttachment.emit($event)"
      (editAttachment)="editAttachment.emit($event)"
    ></literax-attachment>
  </div>
  <div id="attachments" class="section">
    <div class="subtitle">
      {{ 'DOCUMENT-PREVIEW.DOCUMENTS-TAB.ATTACHMENTS' | translate }}
    </div>
    <div *ngIf="attachments?.length > 0; else emptyAttachments">
      <div *ngIf="!typePromissoryNote; else promissoryNote">
        <literax-attachment
          *ngFor="let attachment of attachments; let i = index"
          [attachment]="attachment"
          [actions]="edit"
          [showCheckAttachment]="showCheckAttachment"
          [workarea]="workarea"
          (attachmentId)="attachmentDetail($event)"
          (checkId)="checkAttachment($event)"
          (replaceAttachment)="replaceAttachment.emit($event)"
          (editAttachment)="editAttachment.emit($event)"
        >
        </literax-attachment>
      </div>
      <ng-template #promissoryNote>
        <literax-attachment
          *ngFor="let attachment of attachments; let i = index"
          [documentSecondary]="documentsSecondary"
          [attachment]="attachment"
          [actions]="edit"
          [typePromissory]="true"
          (attachmentId)="attachmentDetail($event)"
          (sign)="goToSign($event)"
        >
        </literax-attachment>
      </ng-template>
    </div>

    <ng-template #emptyAttachments>
      <div class="empty-attachments">
        <img
          src="../../../../assets/icon/inbox-above.svg"
          class="image-upload-above"
        />
        <img
          src="../../../../assets/icon/inbox-shade.svg"
          class="image-upload-shade"
        />
        <img
          src="../../../../assets/icon/inbox-below.svg"
          class="image-upload-below"
        />

        <p class="empty-message">
          {{ 'DOCUMENT-PREVIEW.DOCUMENTS-TAB.EMPTY_ATTACHMENTS' | translate }}
        </p>
      </div>
    </ng-template>
  </div>
</div>
