import {
  ICreateSignatureRequest,
  IRejectSignatureRequest,
  IRejectSignatureResponse,
  ISignatureResponse,
} from '@literax/models/signature.model';
import {
  IShowSignatureResponse,
  SingRequestForm,
} from '@literax/models/http/api/signature.model';
import { createAction, props } from '@ngrx/store';

import { DocumentSignature } from '@literax/models/document-signature';
import { HttpErrorResponse } from '@angular/common/http';
import { ITemplates } from '@literax/models/templates';

export enum ESignatureActions {
  CreateSignature = '[Signature] Create Signature',
  CreateSignatureSuccess = '[Signature] Create Signature Success',
  CreateSignatureError = '[Signature] Create Signature Error',
  CreateSimpleSignature = '[Signature] Create Simple Signature',
  CreateSimpleSignatureSuccess = '[Signature] Create Simple Signature Success',
  CreateSimpleSignatureError = '[Signature] Create Simple Signature Error',
  ClearSignatureResult = '[Signature] Clear Signature Result',
  ShowSignature = '[Signature] Show Signature',
  ShowSignatureSuccess = '[Signature] Show Signature Success',
  ShowSignatureError = '[Signature] Show Signature Error',
  twoFactorToken = '[Signature] thoFactorToken',
  twoFactorTokenSuccess = '[Signature] twoFactorTokenSuccess',
  twoFactorTokenError = '[Signature] twoFactorTokenError',
  createSimpleSignature = '[Signature] Create Simple Signature',
  createSimpleSignatureSuccess = '[Signature] Create Simple Signature Success',
  createSimpleSignatureError = '[Signature] Create Simple Signature Error',

  GetSignatureQuote = '[Signature] Get signature quote by participant',
  GetSignatureQuoteSuccess = '[Signature] Get signature quote by participant success',
  GetSignatureQuoteError = '[Signature] Get signature quote by participant',
  ClearSignatureQuotes = '[Signature] Clear signature quote by participant',
}
export const CreateSimpleSignature = createAction(
  ESignatureActions.CreateSimpleSignature,
  props<{ payload: DocumentSignature }>()
);

export const CreateSignature = createAction(
  ESignatureActions.CreateSignature,
  props<{ payload: { documentId: number; params: SingRequestForm } }>()
);

export const CreateSignatureSuccess = createAction(
  ESignatureActions.CreateSignatureSuccess,
  props<{ payload: ISignatureResponse }>()
);
export const CreateSignatureError = createAction(
  ESignatureActions.CreateSignatureError,
  props<{ payload: HttpErrorResponse }>()
);
export const ClearSignatureResult = createAction(
  ESignatureActions.ClearSignatureResult
);
export const ShowSignature = createAction(
  ESignatureActions.ShowSignature,
  props<{ payload: string }>()
);
export const ShowSignatureSuccess = createAction(
  ESignatureActions.ShowSignatureSuccess,
  props<{ payload: IShowSignatureResponse }>()
);
export const ShowSignatureError = createAction(
  ESignatureActions.ShowSignatureError,
  props<{ payload: HttpErrorResponse }>()
);

export const twoFactorToken = createAction(
  ESignatureActions.twoFactorToken,
  props<{ documentId: number; signRequestId: number; token?: string }>()
);

export const twoFactorTokenSuccess = createAction(
  ESignatureActions.twoFactorTokenSuccess
);

export const twoFactorTokenError = createAction(
  ESignatureActions.twoFactorTokenError,
  props<{ payload: { message: string } }>()
);

// ! Deprecated: change action for registerSignature
export const createSimpleSignature = createAction(
  ESignatureActions.createSimpleSignature,
  props<{ payload: { signature: DocumentSignature; document_id: number } }>()
);

// ! Deprecated: change action for registerSignatureSuccess
export const createSimpleSignatureSuccess = createAction(
  ESignatureActions.createSimpleSignatureSuccess,
  props<{ payload: DocumentSignature }>()
);

// ! Deprecated: change action for registerSignatureError
export const createSimpleSignatureError = createAction(
  ESignatureActions.createSimpleSignatureError,
  props<{ payload: HttpErrorResponse }>()
);

export const registerSignature = createAction(
  '[Signature] Register Signature',
  props<{
    payload: {
      documentId: number;
      signRequestId: number;
      signatureData: ICreateSignatureRequest;
      token?: string;
    };
  }>()
);

export const registerSignatureSuccess = createAction(
  '[Signature] Register Signature Success',
  props<{ payload: ISignatureResponse }>()
);

export const registerSignatureError = createAction(
  '[Signature] Register Signature Error',
  props<{ payload: HttpErrorResponse }>()
);

export const RejectSignature = createAction(
  '[Signature] Reject Signature',
  props<{
    payload: {
      documentId: number;
      signRequestId: number;
      rejectData?: IRejectSignatureRequest;
      token?: string;
    };
  }>()
);

export const RejectSignatureSuccess = createAction(
  '[Signature] Reject Signature Success',
  props<{ payload: IRejectSignatureResponse }>()
);

export const RejectSignatureError = createAction(
  '[Signature] Reject Signature Error',
  props<{ payload: HttpErrorResponse }>()
);

export const GetSignatureQuote = createAction(
  ESignatureActions.GetSignatureQuote,
  props<{ payload: string }>()
);

export const GetSignatureQuoteSuccess = createAction(
  ESignatureActions.GetSignatureQuoteSuccess,
  props<{ payload: ITemplates[] }>()
);

export const GetSignatureQuoteError = createAction(
  ESignatureActions.GetSignatureQuoteError
);

export const ClearSignatureQuotes = createAction(
  ESignatureActions.ClearSignatureQuotes
);
