import { Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-view-image-modal',
  templateUrl: './view-image-modal.component.html',
  styleUrls: ['./view-image-modal.component.scss'],
})
export class ViewImageModalComponent implements OnInit {
  title: string;
  imagePaths: string[];
  videoPath: string;

  constructor() {}

  ngOnInit(): void {}
}
