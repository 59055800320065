import { IAPIError, IAPIResponseError } from '@literax/models/http/api/error.model';
import {
  ICreateAttachment,
  ICreateAttachmentResponse
} from '@literax/models/http/Attachment/create-attachment.model';
import { createAction, props } from '@ngrx/store';

import { IAttachment } from '@literax/models/http/attachment.model';

export const createAttachment = createAction(
  '[Attachment] CreateAttachment',
  props<{ documentId: number | string; data: ICreateAttachment; }>()
);

export const createAttachmentSuccess = createAction(
  '[Attachment] CreateAttachmentSuccess',
  props<{ payload: ICreateAttachmentResponse }>()
);

export const createAttachmentError = createAction(
  '[Attachment] CreateAttachmentError',
  props<{ payload: IAPIResponseError }>()
);

export const updateAttachment = createAction(
  '[Attachment] UpdateAttachment',
  props<{ 
    documentId: number | string;
    attachmentId: number | string; 
    data: ICreateAttachment; 
  }>()
);

export const updateAttachmentSuccess = createAction(
  '[Attachment] UpdateAttachmentSuccess',
  props<{ payload: ICreateAttachmentResponse }>()
);

export const updateAttachmentError = createAction(
  '[Attachment] UpdateAttachmentError',
  props<{ payload: IAPIResponseError }>()
);

export const deleteAttachment = createAction(
  '[Attachment] DeleteAttachment',
  props<{ documentId: number | string; attachmentId: number | string; }>()
);

export const deleteAttachmentSuccess = createAction(
  '[Attachment] DeleteAttachmentSuccess'
);

export const deleteAttachmentError = createAction(
  '[Attachment] DeleteAttachmentError',
  props<{ payload: IAPIError }>()
);

export const updateLocalAttachment = createAction(
  '[Attachment] UpdateLocalAttachment',
  props<{ payload: IAttachment }>()
);

export const checkAttachment = createAction(
  '[Attachment] CheckAttachment',
  props<{ payload: {
    documentId: number;
    attachmentId: number; 
    signRequestId: number; 
  }}>()
);

export const checkAttachmentSuccess = createAction(
  '[Attachment] CheckAttachmentSuccess',
  props<{payload: IAttachment}>()
);

export const checkAttachmentError = createAction(
  '[Attachment] CheckAttachmentError',
  props<{ payload: IAPIResponseError }>()
);