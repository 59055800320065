import * as documentActions from '@literax/modules/documents/store/document/document.actions';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IClient } from '../../../models/http/api/client/client.model';
import { Store, select } from '@ngrx/store';
import { debounceTime, filter } from 'rxjs/operators';
import {
  searchDocument,
  searchDocumentFirstSuggestion,
} from '@literax/modules/documents/store/document/document.selectors';

import { DeviceDetectorService } from 'ngx-device-detector';
import { FormControl } from '@angular/forms';
import { MenuItem } from '@literax/models/menu-item.model';
import { Router } from '@angular/router';
import { SetCurrentClient } from '@literax/modules/configurations/store/client/client.actions';

import { ILookFeelConfig } from '../../../components/configurations/users/models/users.interface';
import { ISearch } from '../../../models/http/document/document.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() logoUrl: string;
  @Input() isCollapsed = true;
  isMovileDevice: boolean;
  @Input() lookFeel: ILookFeelConfig;
  @Input() lang: string;
  @Input() currentClient: IClient;
  @Input() clients: IClient[];
  @Input() menuItems: {
    top: MenuItem[];
    bottom: MenuItem[];
  };
  @Input() set name(value) {
    this._name = value;
  }
  @Input() set email(value) {
    this._email = value;
  }
  @Input() set profileImage(value) {
    this._profileImage = value;
  }
  @Output() Logout = new EventEmitter();
  @Output() SetLang = new EventEmitter();
  @Output() changeClient = new EventEmitter();

  myControl = new FormControl();
  options = [];
  filteredOptions = this.store.pipe(
    select(searchDocument),
    filter((data) => data != null)
  );
  suggestion = this.store.pipe(select(searchDocumentFirstSuggestion));
  _name: string;
  _email: string;
  _profileImage: string;

  constructor(
    private router: Router,
    private store: Store,
    private deviceDetector: DeviceDetectorService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.myControl.valueChanges
      .pipe(
        filter((value) => value.length >= 4),
        debounceTime(300),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.filter(data);
      });
    this.isMovileDevice =
      this.deviceDetector.isMobile() || this.deviceDetector.isTablet();
  }

  ngOnDestroy(): void {}

  ngOnChanges(values) {
    this.cd.detectChanges();
  }

  clearSearch() {
    this.myControl.setValue('');
  }

  setLang(lang: string) {
    this.SetLang.emit({ event: 'set lang', lang });
  }

  logout() {
    this.Logout.emit({ event: 'Logout clicked event' });
  }

  change(event, client): void {
    if (event.type === 'click')
      this.store.dispatch(
        SetCurrentClient({
          payload: this.clients.find((c) => c.uuid === client.uuid),
        })
      );
  }

  changeCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  sendToDocument = (document: ISearch) =>
    this.router.navigate([
      `/platform/documents/${document.transactionTypeName}/${document.id}`,
    ]);

  filter(val: string) {
    if (val !== '') {
      this.store.dispatch(documentActions.GetNavbarDocument({ payload: val }));
    }
  }
}
