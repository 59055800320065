import {
  IField,
  IGeneralMetadata,
  IQueryParameters,
} from '@literax/models/templates-fields.model';
import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IPaginationV3 } from '@literax/models/pagination.model';

export enum ETemplatesActions {
  //V3
  Get = '[Templates Fields] Get',
  GetSuccess = '[Templates Fields] GetSuccess',
  GetError = '[Templates Fields] GetError',

  GetField = '[Templates Fields] GetField',
  GetFieldSuccess = '[Templates Fields] GetFieldSuccess',
  GetFieldError = '[Templates Fields] GetFieldError',

  PostField = '[Templates Fields] PostField',
  PostFieldSuccess = '[Templates Fields] PostFieldSuccess',
  PostFieldError = '[Templates Fields] PostFieldError',

  PutField = '[Templates Fields] PutField',
  PutFieldSuccess = '[Templates Fields] PutFieldSuccess',
  PutFieldError = '[Templates Fields] PutFieldError',

  DeleteField = '[Templates Fields] DeleteField',
  DeleteFieldSuccess = '[Templates Fields] DeleteFieldSuccess',
  DeleteFieldError = '[Templates Fields] DeleteFieldError',

  PostProfileField = '[Templates Fields] PostProfileField',
  PostProfileFieldSuccess = '[Templates Fields] PostProfileFieldSuccess',
  PostProfileFieldError = '[Templates Fields] PostProfileFieldError',

  DeleteProfileField = '[Templates Fields] DeleteProfileField',
  DeleteProfileFieldSuccess = '[Templates Fields] DeleteProfileFieldSuccess',
  DeleteProfileFieldError = '[Templates Fields] DeleteProfileFieldError',
}

//V3
export const Get = createAction(
  ETemplatesActions.Get,
  props<{ payload?: IQueryParameters }>()
);

export const GetSuccess = createAction(
  ETemplatesActions.GetSuccess,
  props<{ payload: { fields: IField[]; pagination: IPaginationV3 } }>()
);

export const GetError = createAction(
  ETemplatesActions.GetError,
  props<{ payload: IAPIResponseError }>()
);

export const GetField = createAction(
  ETemplatesActions.GetField,
  props<{ fieldId: number }>()
);

export const GetFieldSuccess = createAction(
  ETemplatesActions.GetFieldSuccess,
  props<{ payload: { field: IGeneralMetadata } }>()
);

export const GetFieldError = createAction(
  ETemplatesActions.GetFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const PostField = createAction(
  ETemplatesActions.PostField,
  props<{ field: IGeneralMetadata }>()
);

export const PostFieldSuccess = createAction(
  ETemplatesActions.PostFieldSuccess
);

export const PostFieldError = createAction(
  ETemplatesActions.PostFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const PutField = createAction(
  ETemplatesActions.PutField,
  props<{ field: IGeneralMetadata }>()
);

export const PutFieldSuccess = createAction(ETemplatesActions.PutFieldSuccess);

export const PutFieldError = createAction(
  ETemplatesActions.PutFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const DeleteField = createAction(
  ETemplatesActions.DeleteField,
  props<{ fieldId: number }>()
);

export const DeleteFieldSuccess = createAction(
  ETemplatesActions.DeleteFieldSuccess
);

export const DeleteFieldError = createAction(
  ETemplatesActions.DeleteFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const PostProfileField = createAction(
  ETemplatesActions.PostProfileField,
  props<{ field: IGeneralMetadata; profileId: number }>()
);

export const PostProfileFieldSuccess = createAction(
  ETemplatesActions.PostProfileFieldSuccess
);

export const PostProfileFieldError = createAction(
  ETemplatesActions.PostProfileFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const DeleteProfileField = createAction(
  ETemplatesActions.DeleteProfileField,
  props<{ fieldId: number; profileId: number }>()
);

export const DeleteProfileFieldSuccess = createAction(
  ETemplatesActions.DeleteFieldSuccess
);

export const DeleteProfileFieldError = createAction(
  ETemplatesActions.DeleteFieldError,
  props<{ payload: IAPIResponseError }>()
);
