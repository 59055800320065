import { Directive, HostListener } from '@angular/core';

import { LiteraxStepperComponent } from '../literax-stepper.component';

@Directive({
  selector: '[literaxStepperPrevious]'
})
export class LiteraxStepperPreviousDirective {

  constructor(
    private literaxStepper: LiteraxStepperComponent
  ) { }

  @HostListener('click')
  private onClick() {
    this.literaxStepper.previousStep()
  }
}
