<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="row error-404">
          <div class="col-12 text-center">
            <h3>{{ 'AUTH.DIALOG.ERROR_PASSWORD_RECOVERY' | translate }}</h3>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
