<div class="headers-container" [class]="isPagare ? 'pagare' : ''">
  <div class="tab-container">
    <literax-process-tab
      *ngFor="let tab of tabs; let i = index"
      [count]="tab.count"
      [name]="tab.name"
      [key]="tab.flow_key"
      [color]="tab.color"
      [active]="activeTab == i ? true : false"
      [isPagare]="isPagare"
      (click)="onTabClicked(i, tab)"
      [permissions]="permissions"
    >
    </literax-process-tab>
  </div>
</div>

<div
  class="main-container"
  [class]="isPromissoryNoteTemplate ? 'container-top' : ''"
>
  <ng-content></ng-content>
</div>
