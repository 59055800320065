import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * Base implementation of service calls. Access token management
 * is done here.
 */
export class BaseService {
  private currentLocale = 'es';

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {}

  public set locale(locale: string) {
    this.currentLocale = locale;
  }

  /**
   * Base get call
   * @param url - Url to call
   * @params params
   * @returns An Observable of the response from the server
   */
  get(url: string, params?: HttpParams, migr: boolean = false) {
    return this.httpClient.get<any>(
      `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
        migr ? environment.apiVersionMigr : environment.apiVersion
      }${url}`,
      {
        params,
      }
    ).pipe(catchError(this.handleError));
  }

  /**
   * Base get call
   * @param url - Url to call
   * @params params
   * @returns An Observable of the response from the server
   */
  getObserveResponse(url: string) {
    return this.httpClient.get<any>(
      `${environment.apiEndpointMigr}/${environment.apiVersionMigr}${url}`,
      {
        observe: 'response',
      }
    );
  }

  getBlob(url: string, migr: boolean = false): Observable<Blob> {
    return this.httpClient.get(
      `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
        migr ? environment.apiVersionMigr : environment.apiVersion
      }${url}`,
      {
        responseType: 'blob',
      }
    );
  }

  getExternalBlob(url: string): Observable<Blob> {
    return this.httpClient.get(url, {
      responseType: 'blob',
    });
  }

  /**
   * Base post call
   * @param url - Url to call
   * @param body - Object to be sent as the post body
   * @returns An Observable of the response from the server
   */
  post<T>(url: string, body: any, options?: any, migr: boolean = false) {
    return this.httpClient.post<T>(
      `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
        migr ? environment.apiVersionMigr : environment.apiVersion
      }${url}`,
      body,
      options
    );
  }

  /**
   * Base put call
   * @param url - Url to call
   * @param body - Object to be sent as the put body
   * @returns An Observable of the response from the server
   */
  put<T>(url: string, body: any, options?: any, migr: boolean = false) {
    return this.httpClient.put<T>(
      `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
        migr ? environment.apiVersionMigr : environment.apiVersion
      }${url}`,
      body,
      options
    ).pipe(catchError(this.handleError));
  }

  /**
   * Base patch call
   * @param url - Url to call
   * @param body - Object to be sent as the put body
   * @returns An Observable of the response from the server
   */
  patch<T>(url: string, body: any, options?: any, migr: boolean = false) {
    return this.httpClient.patch<T>(
      `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
        migr ? environment.apiVersionMigr : environment.apiVersion
      }${url}`,
      body,
      options
    ).pipe(catchError(this.handleError));
  }
  /**
   * Base delete call
   * @param url - Url to call
   * @param body - Object to be sent inside the delete http options
   * @returns An Observable of the response from the server
   */
  delete(url: string, params?: any, migr: boolean = false) {
    return this.httpClient.delete<any>(
      `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
        migr ? environment.apiVersionMigr : environment.apiVersion
      }${url}`,
      {
        params,
      }
    );
  }

    /**
   * If the error is a client-side error, return the error. If the error is a server-side error, return
   * the error
   * @param {HttpErrorResponse} error - The error object that was thrown.
   * @returns The error message.
   */
    private handleError(error: HttpErrorResponse) {
      let e: {
        message?: string;
        level?: string;
        fields?: string[];
        httpStatus?: any;
      } = {};
      if ([400, 404, 422, 500].includes(error.status)) {
        e = Object.entries(error.error)
          .map(([k, m]) => [k.toLocaleLowerCase(), m])
          .reduce((a, c: string[]) => ({ ...a, [c[0]]: c[1] }), {});
      } else {
        e.message = error.error;
      }
      e.httpStatus = error.status;
      return throwError(e);
    }

}
