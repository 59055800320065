import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { AuthService } from '@literax/modules/auth/services/auth.service';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-error-password',
  templateUrl: './error-password.component.html',
  styleUrls: ['./error-password.component.scss']
})
export class ErrorResetPasswordComponent implements OnInit {
  //readonly redirectPath = '/password/reset/error';

  ngOnInit() {}

  onBackClicked() {
    this.router.navigate(['auth', 'login']);
  }
  constructor(private router: Router) {}
}
