<div class="approver-request">
  <div class="wrapper">
    <div class="check-container">
      <div
        class="rectangle"
        [ngClass]="
          approver?.status?.key === 'approved'
            ? 'rectangle-success'
            : approver?.status?.key === 'rejected'
            ? 'rectangle-reject'
            : ''
        "
        (click)="openCommentDetails()"
      >
        <i
          class="fal"
          [ngClass]="
            approver?.status?.key === 'approved'
              ? 'color-reviewed fa-check-double'
              : approver?.status?.key === 'rejected'
              ? 'color-reject fa-times'
              : 'color-unchecked fa-check-double'
          "
        ></i>
      </div>
    </div>
    <div class="approvers">
      <div class="approver-info">
        <p class="approver name">
          {{ approver?.name }}
          <span *ngIf="approver?.is_author" class="author-label">
            - {{ 'DOCUMENTS.SIGNERS.AUTHOR' | translate }}
          </span>
          <span *ngIf="approver?.approval_required" class="pl-2">
            <i class="fal fa-star"></i>
          </span>
        </p>

        <div class="actions-container">
          <literax-popover
            *ngIf="
              userDocument?.id === user?.id &&
              approver?.status?.key !== 'approved' &&
              !approver?.is_author &&
              actionButtonEnable &&
              status !== 'draft' &&
              status !== 'rejected'
            "
            [last_event_email]="approver?.last_email_event"
            [signer]="approver"
            [statusDocument]="document.status.key"
            (historyButton)="historyButton.emit($event)"
            (actionButton)="actionButton.emit($event)"
          ></literax-popover>
        </div>
      </div>
      <div class="approver-extra-info">
        <div class="info-cell">
          <span class="title">
            {{ 'DOCUMENTS.SIGNERS.EMAIL' | translate }}:
          </span>
          <span class="extra-info">{{ approver?.email }}</span>
        </div>
        <div class="info-cell">
          <span class="title">
            {{ 'DOCUMENTS.SIGNERS.EXPIRY' | translate }}:
          </span>
          <span class="extra-info">
            {{ approver?.expiry | date: 'dd/MM/yyyy' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="delegated-participant" *ngIf="approver?.delegate">
    <div class="delegated-title">
      {{
        'DOCUMENTS.SIGNERS.DELEGATED_TO_TITLE'
          | translate: { process: 'WORKSPACE.FOOTER.APPROVAL' | translate }
      }}
    </div>
    <div class="wrapper">
      <div class="check-container">
        <div
          class="rectangle"
          [ngClass]="
            approver?.delegate?.status?.key === 'approved'
              ? 'rectangle-success'
              : approver?.delegate?.status?.key === 'rejected'
              ? 'rectangle-reject'
              : ''
          "
          (click)="openCommentDetails()"
        >
          <i
            class="fal"
            [ngClass]="
              approver?.delegate?.status?.key === 'approved'
                ? 'color-reviewed fa-check-double'
                : approver?.delegate?.status?.key === 'rejected'
                ? 'color-reject fa-times'
                : 'color-unchecked fa-check-double'
            "
          ></i>
        </div>
      </div>
      <div class="approvers">
        <div class="approver-info">
          <p class="approver name">
            {{ approver?.delegate?.name }}
            <span class="author-label">
              - {{ 'DOCUMENTS.SIGNERS.DELEGATE' | translate }}
            </span>
            <span *ngIf="approver?.delegate?.approval_required" class="pl-2">
              <i class="fal fa-star"></i>
            </span>
          </p>
        </div>
        <div class="approver-extra-info">
          <div class="info-cell">
            <span class="title">
              {{ 'DOCUMENTS.SIGNERS.EMAIL' | translate }}:
            </span>
            <span class="extra-info">{{ approver?.delegate?.email }}</span>
          </div>
          <div class="info-cell">
            <span class="title">
              {{ 'DOCUMENTS.SIGNERS.EXPIRY' | translate }}:
            </span>
            <span class="extra-info">
              {{ approver?.delegate?.expiry | date: 'dd/MM/yyyy' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
