import * as _ from 'lodash';

import {
  EDocColumnSorting,
  EDocumentUpdatePaths,
  ETypeDocConsult,
} from '@literax/enums/document.enum';
import {
  IAccessTokens,
  IAccessTokensRequest,
  IInfo,
} from '../../components/configurations/users/models/users.interface';
import {
  IAttachment,
  IAttachmentRequestParams,
} from '@literax/models/attachment.model';
import {
  ICreateDocumentRequest,
  IDocColumnSorting,
  IDocFilterField,
  IDocumentNotifications,
  IDocumentResponse,
  IDownloadDoc,
  IProcessRequestBody,
} from '@literax/models/document.model';
import {
  ICreateSignRequest,
  IEmailHistory,
  IUpdateSignaturePosition,
} from '@literax/models/participant.model';

import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { IUpdateData } from '@literax/models/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParticipantUpdatePaths } from '@literax/enums/participant.enum';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { environment } from '@environments/environment';
import { serialize } from '@literax/utils/json-to-form-data';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends BaseService<any> {
  constructor(private http: HttpClient) {
    super(http);
  }

  /**
   * This function returns an observable of type IDocumentResponse
   * @param {number} documentId - The id of the document you want to retrieve.
   * @param {string} [token] - The token to use for the request. If not provided, the default token
   * will be used.
   * @returns An observable of type IDocumentResponse
   */
  public getDocumentById(
    documentId: number,
    token?: string
  ): Observable<IDocumentResponse> {
    return this.getById(`/documents/${documentId}`, true, token);
  }

  public getUserInfo = (email: string, token?: string): Observable<IInfo> =>
    this.getById(`/users/${email}/info`, true, token);

  public getAccessTokens = (
    body: IAccessTokensRequest,
    token: string
  ): Observable<IAccessTokens> =>
    this.post(`/microservices/access_token`, body, true, {
      headers: this.createHeader(token),
    });

  public updateDocument(
    documentId: number,
    newData: Array<IUpdateData<EDocumentUpdatePaths>>
  ): Observable<IDocumentResponse> {
    return this.patch(`/documents/${documentId}`, newData, true);
  }

  public downloadDocument(
    id: number
  ): Observable<ServiceResponse<IDownloadDoc>> {
    return this.get(`/documents/${id}/file_disposition`);
  }

  /**
   * It takes a document object, serializes it, and sends it to the server as a form data object
   * @param {ICreateDocumentRequest} document - ICreateDocumentRequest - The document object that will
   * be sent to the server.
   * @returns Observable<IDocumentResponse>
   */
  public createDocument(
    document: ICreateDocumentRequest
  ): Observable<IDocumentResponse> {
    return this.post(
      `/documents`,
      serialize(document, { returnAsFormData: true }),
      true
    );
  }

  /**
   * It takes a document ID and an action, and returns an observable of a partial document response
   * @param {number} documentId - The id of the document you want to mark as
   * @param {EDocumentStatus} action - EDocumentStatus - this is an enum
   * @returns An observable of a partial IDocumentResponse
   */
  public process(
    documentId: number,
    body: IProcessRequestBody
  ): Observable<IDocumentResponse> {
    const path = `/documents/${documentId}/process`;
    if (body.token) {
      return this.put(path, _.omit(body, 'token'), true, {
        headers: this.createHeader(body.token),
      });
    } else {
      return this.put(path, _.omit(body, 'token'), true);
    }
  }

  public getAttachmentById(
    documentId: number,
    attachmentId: number,
    options?: IAttachmentRequestParams,
    token?: string
  ): Observable<IAttachment> {
    let attachmentRequestOptions = '';

    if (options && options.requestOnlyOfficeConfig) {
      attachmentRequestOptions = '?kind=1';
    }

    if (options && options.requestPdfVersion) {
      attachmentRequestOptions = '?kind=0';
    }

    return this.getById(
      `/documents/${documentId}/attachments/${attachmentId}${attachmentRequestOptions}`,
      true,
      token
    );
  }

  public replaceAttachment(
    documentId: number,
    attachmentId: number,
    file
  ): Observable<IDocumentResponse> {
    return this.put(
      `/documents/${documentId}/attachments/${attachmentId}`,
      serialize(file),
      true
    );
  }

  public redactAttachment(
    documentId: number,
    file
  ): Observable<IDocumentResponse> {
    return this.post(
      `/documents/${documentId}/attachments`,
      serialize(file),
      true
    );
  }

  public deleteAttachment(
    documentId: number,
    attachmentId: number
  ): Observable<IDocumentResponse> {
    return this.delete(
      `/documents/${documentId}/attachments/${attachmentId}`,
      null,
      true
    );
  }

  public getSignatureQuotes(
    documentId: number,
    searchTerm: string,
    type: 'email' | 'taxId'
  ): Observable<any> {
    return this.get(
      `/documents/${documentId}/sign_request_quotes?${type}=${searchTerm}`
    );
  }

  public appendParticipant(
    documentId: number,
    participant: Partial<ICreateSignRequest>
  ): Observable<IDocumentResponse> {
    return this.post(
      `/documents/${documentId}/signrequests`,
      participant,
      true
    );
  }

  public getParticipantEmailHistory(
    documentId: number,
    signRequestId: number
  ): Observable<IEmailHistory[]> {
    return this.get(
      `/documents/${documentId}/signrequests/${signRequestId}/emailhistory`
    );
  }

  public sendParticipantEmail(
    documentId: number,
    signRequestId: number
  ): Observable<string> {
    return this.post(
      `/documents/${documentId}/signrequests/${signRequestId}/sendemail`,
      {},
      true
    );
  }

  public sendEmailNotification(
    documentId: number,
    payload: IDocumentNotifications
  ): Observable<string> {
    return this.post(
      `/documents/${documentId}/notifications`,
      payload,
      true
    );
  }

  public updateParticipant(
    documentId: number,
    signRequestId: number,
    updateData: Array<IUpdateData<ParticipantUpdatePaths>>
  ): Observable<IDocumentResponse> {
    return this.patch(
      `/documents/${documentId}/signrequests/${signRequestId}`,
      updateData,
      true
    );
  }

  public updateMultipleParticipant(
    documentId: number,
    updateData: Array<{
      id: number;
      patch: Array<IUpdateData<ParticipantUpdatePaths>>;
    }>
  ): Observable<IDocumentResponse> {
    return this.patch(
      `/documents/${documentId}/multiplesignrequests`,
      updateData,
      true
    );
  }

  public removeParticipant(
    documentId: number,
    participantId: number
  ): Observable<IDocumentResponse> {
    return this.delete(
      `/documents/${documentId}/signrequests/${participantId}`,
      null,
      true
    );
  }

  public removeMultipleParticipant(
    documentId: number,
    participants: number[]
  ): Observable<IDocumentResponse> {
    return this.delete(
      `/documents/${documentId}/signrequests`,
      participants,
      true
    );
  }

  public updateParticipantSignaturePosition(
    documentId: number,
    attachmentId: number,
    newPositions: IUpdateSignaturePosition
  ): Observable<IDocumentResponse> {
    return this.put(
      `/documents/${documentId}/attachments/${attachmentId}/signature_positions`,
      newPositions,
      true
    );
  }

  public addMultiple(
    documentId: number,
    participants: Partial<ICreateSignRequest>[]
  ): Observable<any> {
    return this.post(
      `/documents/${documentId}/multiplesignrequests`,
      participants,
      true
    );
  }

  public getDocuments(
    pageNumber: number,
    pageSize: number,
    type: ETypeDocConsult,
    show: number,
    sortField: IDocColumnSorting,
    filterFields: IDocFilterField[]
  ): Observable<any> {
    let queryParams = `?pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}&show=${show}`;

    if (sortField) {
      queryParams += this.generateSortField(sortField, type);
    }

    if (filterFields) {
      queryParams += this.generateFilterFields(filterFields);
    }

    const url = `/documents${queryParams}`;
    return this.http.get<any>(
      `${environment.apiEndpointMigr}/${environment.apiVersionMigr}${url}`,
      {
        observe: 'response',
      }
    );
  }

  public exportListDocuments(
    pageNumber: number,
    pageSize: number,
    type: ETypeDocConsult,
    show: number,
    sortField: IDocColumnSorting,
    filterFields: IDocFilterField[]
  ): Observable<any> {
    let queryParams = `?format=xlsx&pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}&show=${show}`;

    if (sortField) {
      queryParams += this.generateSortField(sortField, type);
    }

    if (filterFields) {
      queryParams += this.generateFilterFields(filterFields);
    }

    const url = `/documents/export${queryParams}`;
    return this.http.get(
      `${environment.apiEndpointMigr}/${environment.apiVersionMigr}${url}`,
      {
        responseType: 'blob',
      }
    );
  }

  generateSortField(
    sortField: IDocColumnSorting,
    type: ETypeDocConsult
  ): string {
    let generatedSort: string;
    switch (type) {
      case ETypeDocConsult.PROJECTS:
        if (
          sortField.name === EDocColumnSorting.NAME ||
          EDocColumnSorting.CREATED_AT
        ) {
          generatedSort = `&sortField[name]=${sortField.name}&sortField[type]=${sortField.type}`;
        }
        break;
      case ETypeDocConsult.RECEIVED:
        if (
          sortField.name === EDocColumnSorting.NAME ||
          EDocColumnSorting.CREATED_AT ||
          EDocColumnSorting.STATUS_ID ||
          EDocColumnSorting.EXPIRY_AT
        ) {
          generatedSort = `&sortField[name]=${sortField.name}&sortField[type]=${sortField.type}`;
        }
        break;

      case ETypeDocConsult.SENT:
        if (
          sortField.name === EDocColumnSorting.NAME ||
          EDocColumnSorting.CREATED_AT ||
          EDocColumnSorting.STATUS_ID ||
          EDocColumnSorting.EXPIRY_AT
        ) {
          generatedSort = `&sortField[name]=${sortField.name}&sortField[type]=${sortField.type}`;
        }
        break;

      case ETypeDocConsult.SHARED:
        if (
          sortField.name === EDocColumnSorting.NAME ||
          EDocColumnSorting.CREATED_AT ||
          EDocColumnSorting.EXPIRY_AT
        ) {
          generatedSort = `&sortField[name]=${sortField.name}&sortField[type]=${sortField.type}`;
        }
        break;
    }

    return generatedSort;
  }

  generateFilterFields(filterFields: IDocFilterField[]): string {
    const generatedFilter: string[] = [];
    for (const key in filterFields) {
      if (filterFields.hasOwnProperty(key)) {
        const element = filterFields[key];
        generatedFilter.push(
          `&filterFields[${key}][name]=${encodeURIComponent(element.name)}`
        );
        generatedFilter.push(
          `filterFields[${key}][type]=${encodeURIComponent(element.type)}`
        );
        generatedFilter.push(
          `filterFields[${key}][term]=${encodeURIComponent(element.term)}`
        );
        generatedFilter.push(
          `filterFields[${key}][operator]=${encodeURIComponent(
            element.operator
          )}`
        );
      }
    }
    return generatedFilter.join('&');
  }
}
