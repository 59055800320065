import { BaseService } from '../../../services/base-config/base.service';
import { Client } from '@literax/models/http/onboarding/client.model';
import { IProfileUser } from '@literax/models/user.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '@literax/interfaces/service-response';

@Injectable({
  providedIn: 'root'
})
/**
 * Handles all authorization and registration calls.
 */
export class AuthService {
  constructor(
    private base: BaseService //private tokenService: AngularTokenService
  ) {}

  /**
   * Update profile data of a user with the data from a {@link AuthService#UpdateProfile}.
   * @param userData A {@link AuthService#UpdateProfile} instance with the complete d.netata to send
   * @returns An observable of the response from the server.
   */
  updateProfile(userData: IProfileUser): Observable<ServiceResponse> {
    return this.base.put(`/users`, { user: userData });
  }

  passwordRecovery(
    email: string,
    redirectUrl: string
  ): Observable<ServiceResponse> {
    return this.base.post('/auth/password', {
      email,
      redirect_url: redirectUrl
    });
  }

  passwordReset(password: string, passwordConfirmation: string) {
    // this.tokenService.currentAuthData
    return this.base.put('/auth/password', {
      password,
      password_confirmation: passwordConfirmation
    });
  }
}
