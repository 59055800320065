import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials'
})
export class NameInitials implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value
        .split(' ')
        .slice(0, 2)
        .map((n) => n[0])
        .join('');
    }
    return '';
  }
}
