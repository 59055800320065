<div
	class='show-comment'
>
	<div
		class='title d-flex justify-content-between'
	>
		<h3>
			{{'WORKSPACE.REJECTED.SHOW_MODAL.TITLE' | translate}}
		</h3>
		<button
			type='button'
			(click)='dialogRef.close()'
		>
			<i class='fal fa-times'></i>
		</button>
	</div>

	<div
		class='content-name'
	>
		<span
			class='letters'
		>
			{{getFirstLetters()}}
		</span>
		<span
			class='name'
		>
			{{data.user}}
		</span>
	</div>

	<div
		class='content-comment'
	>
		<p>
			{{data.comment[data.comment.length - 1]?.description}}
		</p>
	</div>

	<div
		class='col-12 px-0'
	>
		<div
			class='row buttons mx-0'
		>
			<button
				class="d-block interfirma-button primary"
				(click)='dialogRef.close()'
			>
				{{'WORKSPACE.REJECTED.SHOW_MODAL.CLOSE' | translate}}
			</button>
		</div>
	</div>

</div>
