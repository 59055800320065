import { ClientsResponse, ICreateClient, ICreateClientResponse, IValidateClient } from '@literax/models/client.model';

import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseService<any> {

  constructor(http: HttpClient) {
    super(http);
  }

  public validateClient(clientData: IValidateClient): Observable<any> {
    const path = `/clients/validate`;
    return this.post(path, clientData, true);
  }

  public getClients(): Observable<ClientsResponse> {
    return this.get(`/clients`);
  }

  public createClient(clientData: ICreateClient): Observable<ICreateClientResponse> {
    return this.post(
      `/clients`,
      clientData,
      true
    );
  }
}
