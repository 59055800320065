import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { MenuItem } from '@literax/models/menu-item.model';
import { MatMenuTrigger } from '@angular/material/menu';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent implements OnChanges {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() menuItem: MenuItem;
  @Input() menuClosed: boolean;
  @Output() openMenuEmitter: EventEmitter<void> = new EventEmitter();

  @Output()
  closeMenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  showMenu: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuClosed && this.menuClosed) {
      this.showMenu = false;
    }
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
    this.openMenuEmitter.emit();
  }

  showMatMenu(): void {
    this.trigger.openMenu();
  }
}
