<ng-container *ngFor="let approver of ApproverRequest">
  <literax-approver
    [document]="document"
    [status]="document.status.key"
    [approver]="approver"
    [user]="user"
    [userDocument]="document.user"
    [actionButtonEnable]="approver.email !== user.email"
    (historyButton)="historyButton.emit($event)"
    (actionButton)="actionButton.emit($event)"
  >
  </literax-approver>
</ng-container>
