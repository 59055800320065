<div
  class="search-container"
  [ngClass]="divActive ? 'active-div' : ''"
  [class.disabled]="readOnly"
>
  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
    <input
      #searchBar
      nz-input 
      nzSize="default" 
      placeholder="{{'LIT_EXPLORER.UI.FILTERS.SEARCH_PLACEHOLDER' | translate}}"
    />
  </nz-input-group>
  <ng-template #suffixIconButton>
    <button 
      nz-button 
      nzType="primary" 
      nzSearch
      (click)="buttonSearch()"
    >
      <i nz-icon nzType="search"></i>
    </button>
  </ng-template>

</div>
