import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IAttachment } from '@literax/models/http/attachment.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-document-tab',
  templateUrl: './document-tab.component.html',
  styleUrls: ['./document-tab.component.scss'],
})
export class DocumentTabComponent implements OnInit {
  @Input('name') documentName: string;
  @Input('id') documentId: string;
  @Input('attachments') documentAttachments: IAttachment[];
  @Input('status') documentStatus: string;
  @Input('nodes') navNodes: Array<{ text: string; link: string }> = [];
  @Input() color: string;
  @Output() back = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.back.emit();
  }
}
