import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import * as UsersActions from '@literax/components/configurations/users/states/users.actions';
import {
  getErrorLogin,
  selectLoginEmailError,
  selectLoginPasswordError
} from '@literax/components/configurations/users/states/users.selector';
import { IAppState } from '@literax/store';
import { selectErrorTitle } from '@literax/modules/auth/store/auth.selectors';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-login-clientusers',
  templateUrl: './login-clientusers.component.html',
  styleUrls: ['./login-clientusers.component.scss']
})
export class LoginClientusersComponent implements OnInit {
  error$ = this.store.pipe(select(getErrorLogin));
  errorEmail$ = this.store.pipe(select(selectLoginEmailError));
  errorPassword$ = this.store.pipe(select(selectLoginPasswordError));
  errorTitle$ = this.store.pipe(select(selectErrorTitle));
  submitted = false;
  login = environment.literaxUrl;
  url: string = environment.literaxUrl;

  constructor(
    private route: ActivatedRoute,
    private store: Store<IAppState>,
    public authService: B2CAuthService,
    private msalService: MsalService,
    private b2cAuthService: B2CAuthService
  ) {}

  ngOnInit() {
    const tokenSilentRequest = {
      scopes: environment.b2cConfig.scopes,
      account: this.b2cAuthService.getUserInfo()
    };
    this.msalService
      .acquireTokenSilent(tokenSilentRequest)
      .subscribe((authResult) => {
        const token: string = this.route.snapshot.params.token;
        const b2cToken: string = authResult.accessToken;
        this.store.dispatch(
          UsersActions.loginClientUsersConfirmations({ b2cToken, token })
        );
      });
  }
}
