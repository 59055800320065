<form [formGroup]="attachmentForm">
  <div *ngIf="action === EButtonActions.UPLOAD" class="gdx-form-group">
    <div id="row-form" class="gdx-form-group">
      <literax-file-input
        #fileInput
        [formControl]="attachmentForm.get('file')"
        [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
        [fileTypes]="permittedFiles$ | async"
        [labelTag]="'WIZARD.DOCUMENTS.FORMS.UPLOADER.FILE' | translate"
        buttonChangeTag="{{ 'FORM.FILE_INPUT.CHANGE_BUTTON' | translate }}"
        buttonSelectTag="{{ 'FORM.FILE_INPUT.SELECT_BUTTON' | translate }}"
        (fileType)="fileType = $event"
        (changeBase64)="changeFile($event)"
      >
      </literax-file-input>
      <literax-api-error [control]="attachmentForm.controls.file">
      </literax-api-error>
    </div>
  </div>

  <div class="gdx-form-group">
    <literax-form-field
      [label]="'WIZARD.DOCUMENTS.FORMS.UPLOADER.NAME' | translate"
      formControlName="name"
    >
    </literax-form-field>
  </div>
</form>
<div *nzModalFooter class="footer-custom">
  <button nz-button nzType="default" (click)="closeDialog()">
    <i class="fal fa-times-circle"></i>
    {{ 'WIZARD.DOCUMENTS.BUTTONS.CANCEL' | translate }}
  </button>
  <button
    nz-button
    nzType="primary"
    [disabled]="!attachmentForm.valid"
    (click)="saveAction()"
  >
    <i class="fal fa-save"></i>
    {{ 'WIZARD.DOCUMENTS.BUTTONS.SAVE' | translate }}
  </button>
</div>
