import { createAction, props } from '@ngrx/store';
import { IRol } from '../../models/rol.model';
import { IAPIResponseError } from '../../models/http/api/error.model';

export enum ERolActions {
  GetRol = '[Rol] Get roles',
  GetRolSuccess = '[Rol] Get roles success',
  GetRolError = '[Rol] Get roles error'
}

export const GetRoles = createAction(ERolActions.GetRol);

export const GetRolesSuccess = createAction(
  ERolActions.GetRolSuccess,
  props<{ payload: IRol[] }>()
);

export const GetRolesError = createAction(
  ERolActions.GetRolError,
  props<{ payload: IAPIResponseError }>()
);
