import { AfterViewInit, Component, ContentChildren, QueryList } from '@angular/core';

import { LiteraxStepComponent } from './literax-step/literax-step.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'literax-stepper',
  templateUrl: './literax-stepper.component.html',
  styleUrls: ['./literax-stepper.component.scss']
})
export class LiteraxStepperComponent implements AfterViewInit {
  @ContentChildren(LiteraxStepComponent) literaxSteps: QueryList<LiteraxStepComponent>;

  currentStep: number = 1;
  stepsNumber: number;
  hideStep: boolean = false;

  ngAfterViewInit(): void {
    this.stepsNumber = this.literaxSteps.length;
    setTimeout(() => this.literaxSteps.first.activeStep = true);
  }


  nextStep() {
    if (this.currentStep < this.stepsNumber) {
      this.currentStep = this.currentStep + 1;
      this.literaxSteps.forEach((stepComponent: LiteraxStepComponent, index: number) => {
        stepComponent.activeStep = (index + 1) === this.currentStep;
      });
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep = this.currentStep - 1;
      this.literaxSteps.forEach((stepComponent: LiteraxStepComponent, index: number) => {
        stepComponent.activeStep = (index + 1) === this.currentStep;
      });
    }
  }
}
