import { createAction, props } from '@ngrx/store';

import { TitleNavbarConfig } from '@literax/models/title-navbar-config.model';

export enum ETitleNavbarActions {
  SetNavNodes = '[TitleNavbar] SetNavNodes',
  AddToStack = '[TitleNavbar] AddToStack',
  ReplaceStack = '[TitleNavbar] ReplaceStack',
  ClearStack = '[TitleNavbar] ClearStack',
  RemoveFromStack = '[TitleNavbar RemoveFromStack]'
}

export const AddToStack = createAction(
  ETitleNavbarActions.AddToStack,
  props<{ payload: TitleNavbarConfig | TitleNavbarConfig[] }>()
);

export const SetNavNodes = createAction(
  ETitleNavbarActions.SetNavNodes,
  props<{
    payload: Partial<{
      firstLevel: TitleNavbarConfig;
      secondLevel: TitleNavbarConfig;
      thirdLevel: TitleNavbarConfig;
      fourthLevel: TitleNavbarConfig;
    }>;
  }>()
);

export const ReplaceStack = createAction(
  ETitleNavbarActions.ReplaceStack,
  props<{ payload: TitleNavbarConfig[] }>()
);

export const UpdateStack = createAction(
  ETitleNavbarActions.AddToStack,
  props<{ payload: TitleNavbarConfig }>()
);

export const ClearStack = createAction(ETitleNavbarActions.ClearStack);

export const RemoveFromStack = createAction(
  ETitleNavbarActions.RemoveFromStack,
  props<{ payload: number }>()
);
