import { Actions, ofType } from '@ngrx/effects';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetNewLink, GetNewLinkSuccess } from '@literax/store/free-document/free-document.actions';
import { Store, select } from '@ngrx/store';

import { IAppState } from '@literax/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequestNewLinkDialogComponent } from '../request-new-link-dialog/request-new-link-dialog.component';
import { deleteCookie } from '@literax/utils/cookie.utils';
import jwt_decode from "jwt-decode";
import { selectToken } from '@literax/store/free-document/free-document.selectors';


import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss']
})
export class ExpiredLinkComponent implements OnInit, OnDestroy {

  token: string;

  constructor(
    private modalService: NzModalService,
    private store: Store<IAppState>,
    private actions$: Actions,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void { 
    this.token = this.route.snapshot.paramMap.get('token');
  }

  ngOnDestroy(): void { }

  requestNewLink(): void {
    const TokenDecoded = jwt_decode(this.token);
    deleteCookie(`VISIT_DOCUMENT_${TokenDecoded["uuid"]}_${TokenDecoded["request"]}`);
    this.store.dispatch(
      GetNewLink({
        payload: { 
          documentId: TokenDecoded["uuid"],
          signRequestId: TokenDecoded["request"],
          token: this.token
        }
      })
    );
    this.actions$.pipe(ofType(GetNewLinkSuccess)).subscribe((data) => {
      this.modalService.create({
        nzMask: false,
        nzWidth: 700,
        nzContent: RequestNewLinkDialogComponent,
        nzComponentParams: {
          isFailed: false
        },
        nzClosable: false,
        nzFooter: null
      });
    });
  }
}
