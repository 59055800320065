import {
  AttachmentActions,
  EAttachmentActionTypes,
} from './actions/attachment.actions';
import {
  DocumentActions,
  EDocumentActionTypes,
} from './actions/document.actions';
import { EWorkspaceDocumentTabs } from '@literax/enums/document.enum';
import {
  EParticipantActionTypes,
  ParticipantActions,
} from './actions/participant.actions';
import { ESignActionTypes, SignActions } from './actions/sign.actions';
import { createAction, props } from '@ngrx/store';

import { IApiDetailedError } from '@literax/models/common';
import {
  IDocumentResponse,
  IProcessRequestBody,
} from '@literax/models/document.model';
import { EWorkingMode } from './workspace.state';

const WorkspaceAction = {
  ...EDocumentActionTypes,
  ...EAttachmentActionTypes,
  ...EParticipantActionTypes,
  ...ESignActionTypes,
  clearState: '@workspace/clearState',
  setCurrentWorkspaceTab: '@workspace/setCurrentWorkspaceTab',
  setFormBuilderRequiredFieldsStatus:
    '@workspace/setFormBuilderRequiredFieldsStatus',
  handleAction: '@workspace/handleAction',
  handleActionError: '@workspace/handleActionError',
  handleActionSuccess: '@workspace/handleActionSuccess',
  setWorkspaceMode: '@workspace/setWorkspaceMode',
  deleteDocumentSuccess: '@workspace/deleteDocumentSuccess',
  setGuestToken: '@workspace/setGuestToken',
  redirectTo: '@workspace/redirectTo'
};

export type WorkspaceActionTypes = typeof WorkspaceAction;

export const WorkspaceActions = {
  ...DocumentActions,
  ...AttachmentActions,
  ...ParticipantActions,
  ...SignActions,
  clearState: createAction(WorkspaceAction.clearState),
  setCurrentWorkspaceTab: createAction(
    WorkspaceAction.setCurrentWorkspaceTab,
    props<{ payload: { id: EWorkspaceDocumentTabs } }>()
  ),
  handleAction: createAction(
    WorkspaceAction.handleAction,
    props<{
      payload: IProcessRequestBody;
    }>()
  ),
  handleActionError: createAction(
    WorkspaceAction.handleActionError,
    props<{ payload: { error: IApiDetailedError | string } }>()
  ),
  handleActionSuccess: createAction(
    WorkspaceAction.handleActionSuccess,
    props<{
      payload?: { document: Partial<IDocumentResponse>; action?: any };
    }>()
  ),
  setFormBuilderRequiredFieldsStatus: createAction(
    WorkspaceAction.setFormBuilderRequiredFieldsStatus,
    props<{ payload: boolean }>()
  ),
  setWorkspaceMode: createAction(
    WorkspaceAction.setWorkspaceMode,
    props<{ payload: { mode: EWorkingMode } }>()
  ),
  setGuestToken: createAction(
    WorkspaceAction.setGuestToken,
    props<{ payload: string }>()
  ),
  deleteDocumentSuccess: createAction(WorkspaceAction.deleteDocumentSuccess),
  redirectTo: createAction(
    WorkspaceAction.redirectTo,
    props<{ payload: string }>()
  )
};
