import { Component, OnInit } from '@angular/core';

import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-registration-success-modal',
  templateUrl: './registration-success-modal.component.html',
  styleUrls: ['./registration-success-modal.component.scss']
})
export class RegistrationSuccessModalComponent implements OnInit {

  constructor(
    private router: Router,
    private modal: NzModalService
  ) { }

  ngOnInit(): void {
  }

  clickOk() {
    this.router.navigate(['/platform/home']);
    this.modal.closeAll();
  }
}
