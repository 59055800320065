import * as statusActions from './status.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { IStatus } from './status.state';
import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { StatusService } from '@literax/services/platform/status.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class StatusEffects {

  constructor(
    private statusService: StatusService,
    private actions$: Actions
  ) {}

  getStatuses$ = createEffect(() => 
    this.actions$.pipe(
      ofType(statusActions.getStatuses),
      switchMap((action) =>
        this.statusService.getStatuses(action.payload.type, action.payload.sort)
      ),
      switchMap((response: ServiceResponse) =>
        of(statusActions.getStatusesSuccess({ payload: response.statuses as IStatus[] }))
      )
    )
  );
}
