import {
  DefaultProjectorFn,
  MemoizedSelector,
  createSelector,
} from '@ngrx/store';

import { WorkspaceState } from '../workspace.state';

export const SignatureConfigurationSelectors = (
  storeFeature: MemoizedSelector<
    // eslint-disable-next-line @typescript-eslint/ban-types
    object,
    WorkspaceState,
    DefaultProjectorFn<WorkspaceState>
  >
) => ({
  isBiometricsFlow: createSelector(
    storeFeature,
    (state: WorkspaceState) => state?.document?.signingConfiguration?.facematch
  ),
  hasParticipantsOrdering: createSelector(
    storeFeature,
    (state: WorkspaceState) => state?.document?.signingConfiguration?.ordering
  ),
  signingMethod: createSelector(
    storeFeature,
    (state: WorkspaceState) =>
      state.document?.signingConfiguration?.signingMethod
  )
});
