import * as catalogActions from './catalog.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { ICatalogState, initialCatalogState } from './catalog.state';

const reducers = createReducer(
  initialCatalogState,
  on(catalogActions.getCatalog, (state, action) => {
    return {
      ...state,
      values: {},
    };
  }),
  on(catalogActions.getCatalogSuccess, (state, action) => {
    return {
      ...state,
      values: { ...action.payload },
    };
  }),
  on(catalogActions.getFlowCatalogSuccess, (state, action) => ({
    ...state,
    flowCatalog: action.payload,
  })),
  on(catalogActions.getCatalogStatusesSuccess, (state, action) => ({
    ...state,
    catalogStatuses: action.payload,
  })),
  on(catalogActions.remindersSuccess, (state, action) => ({
    ...state,
    reminders: action.payload,
  })),
  on(catalogActions.getSignRequestValidationTypeCatalogSuccess, (state, action) => ({
    ...state,
    signRequestValidationTypes: action.payload,
  }))
);

export function catalogReducers(state: ICatalogState, action: Action) {
  return reducers(state, action);
}
