import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState } from './auth.state';
import { IAPIResponseError } from '@literax/models/http/api/error.model';

const selectAuth = createFeatureSelector('auth');

export const selectCurrentUser = createSelector(
  selectAuth,
  (state: AuthState) => state?.currentUser
);

export const selectCurrentClientToken = createSelector(
  selectAuth,
  (state: AuthState) => state?.accessToken
);

export const selectLoginError = createSelector(
  selectAuth,
  (state: AuthState) => state?.loginError
);

export const selectErrorTitle = createSelector(
  selectAuth,
  (state: AuthState) => state.loginError && state.loginError.title
);

export const selectProfilePicture = createSelector(
  selectAuth,
  (state: AuthState) => {
    if (state.currentUser) {
      return state.currentUser.image_url;
    }
  }
);

export const selectUserName = createSelector(selectAuth, (state: AuthState) => {
  if (
    state.currentUser &&
    (state.currentUser.personal_info.first_name ||
      state.currentUser.personal_info.surname)
  ) {
    let secondName = '';
    let motherUsrName = '';

    const kind = state.currentUser['clients'][0]['kind'];
    const condition =
      state.currentUser.personal_info.first_name ||
      state.currentUser.personal_info.surname;
    const title = `${state.currentUser.personal_info.first_name || ''} ${
      secondName || ''
    } ${state.currentUser.personal_info.surname || ''} ${motherUsrName || ''}`;

    return condition ? title : state.currentUser.email;
  }
});

export const selectUserEmail = createSelector(
  selectAuth,
  (state: AuthState) => state.currentUser && state.currentUser.email
);

export const selectUpdateProfileErrors = createSelector(
  selectAuth,
  (state: AuthState) => state.updateProfileError
);

export const selectUpdateProfileErrorDetail = createSelector(
  selectUpdateProfileErrors,
  (state: IAPIResponseError) => state && state.error && state.error.detail
);

export const selectUpdateProfileCertError = createSelector(
  selectUpdateProfileErrorDetail,
  (state: any) => state && state.cert
);

export const getRFCByCurrentUser = createSelector(
  selectAuth,
  (state: AuthState) => state.currentUser?.rfc
);