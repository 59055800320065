import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-e404-validate-token',
  templateUrl: './e404-validate-token.component.html',
  styleUrls: ['./e404-validate-token.component.scss'],
})
export class E404ValidateTokenComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onLogin() {
    this.router.navigate(['/auth/registration']);
  }

  onRegister() {
    this.router.navigate(['auth/registration']);
  }

  goToLiterax() {
    window.location.href = environment.literax;
  }

  goToPrivacy() {
    window.location.href = environment.privacy;
  }

  goToTerms() {
    window.location.href = environment.terms;
  }
}
