import { Action, createReducer, on } from '@ngrx/store';
import {
  CreateReviewRequestError,
  CreateReviewRequestSucces,
  EReviewRequestActions
} from './review-request.actions';
import {
  initialReviewRequestWorkspaceState,
  IReviewRequestWorkspaceState
} from './review-request.state';

const reviewRequestReducer = createReducer(
  initialReviewRequestWorkspaceState,

  /**
   * Reducer para mostrar los errores de creacion de revisores
   */
  on(CreateReviewRequestError, (state, action) => ({
    ...state,
    newReviewerCreationErrors: action.payload
  })),

  /**
   * Reducer para la creacion exitosa de un revisor
   */
  on(CreateReviewRequestSucces, (state, action) => ({
    ...state,
    newReviewerCreationSuccess: action.payload
  }))
);

export function reviewersReducer(
  state: IReviewRequestWorkspaceState,
  action: Action
) {
  return reviewRequestReducer(state, action);
}
