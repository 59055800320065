import * as backButtonActions from '@literax/store/back-button/back-button.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '..';
import { of } from 'rxjs';
import { Location } from '@angular/common';

@Injectable()
export class BackButtonEffects {
  goPreviousUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(backButtonActions.GoPreviousUrl),
      switchMap((action) => {
        if (action.hasAction) {
          this.location.back();
        }

        return of(backButtonActions.GoPreviousUrlSuccess());
      })
    )
  );

  constructor(private actions$: Actions, private location: Location) {}
}
