import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-delete-record-dialog',
  templateUrl: './delete-record-dialog.component.html',
  styleUrls: ['./delete-record-dialog.component.scss'],
})
export class DeleteRecordDialogComponent implements OnInit, OnDestroy {
  
  @Input() message: string;
  @Input() cancelButton: string;
  @Input() acceptButton: string;

  @Output() acceptAction: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  
  cancelEvent() {
    this.modal.closeAll();
  }

  deleteEvent() {
    this.acceptAction.emit(true);
    this.cancelEvent();
  }

  constructor(
    private modal: NzModalService
    
  ) {}

  ngOnDestroy(){}

  ngOnInit(){}

}
