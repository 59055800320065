import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serialize } from '@literax/utils/json-to-form-data';
import { BaseService } from '@literax/services/base.service';
import { XmlValidationResponse } from './validator.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService extends BaseService<any> {
  constructor(private http: HttpClient) {
    super(http);
  }

  public uploadXmlFiles(files: File[]): Observable<XmlValidationResponse> {
    const data = new FormData();
    files.forEach((file) => data.append('files', file));
    return this.post(
      '/guests/signature_verification',
      data,
      true
    );
  }
}
