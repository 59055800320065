import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { environment } from '@environments/environment';
import { serialize } from '@literax/utils/json-to-form-data';

@Injectable({
  providedIn: 'root',
})
/**
 * Base implementation of service calls. Access token management
 * is done here.
 */
export class SignService {
  private currentLocale = 'es';

  constructor(private httpClient: HttpClient) {}

  public set locale(locale: string) {
    this.currentLocale = locale;
  }

  /**
   * Retrieve access_token, expiry, client, uid and token_type
   * from browsers local storage.
   * @returns The HttpHeaders to be sent.
   */
  createHeader(token?: string): HttpHeaders {
    const headers = {
      locale: this.currentLocale,
      Authorization: `Bearer ${token}`,
    };

    return new HttpHeaders(headers);
  }

  /**
   * Base get call
   * @param url - Url to call
   * @returns An Observable of the response from the server
   */
  get(url: string, token?: string) {
    return this.httpClient.get<ServiceResponse>(
      environment.guestApiEndpoint + url,
      {
        headers: this.createHeader(token),
      }
    );
  }

  /**
   * Base post call
   * @param url - Url to call
   * @param body - Object to be sent as the post body
   * @returns An Observable of the response from the server
   */
  post(url: string, body: any, token?: string) {
    return this.httpClient.post<ServiceResponse>(
      environment.guestApiEndpoint + url,
      body.cancellation_reason ? body : serialize(body), // TODO: Verificar el post savePromissoryNotePayment de documents-sign.service
      {
        headers: this.createHeader(token),
      }
    );
  }

  /**
   * Base put call
   * @param url - Url to call
   * @param body - Object to be sent as the put body
   * @returns An Observable of the response from the server
   */
  put(url: string, body: any, token?: string) {
    return this.httpClient.put<ServiceResponse>(
      environment.guestApiEndpoint + url,
      body,
      {
        headers: this.createHeader(token),
      }
    );
  }

  /**
   * Base delete call
   * @param url - Url to call
   * @param body - Object to be sent inside the delete http options
   * @returns An Observable of the response from the server
   */
  delete(url: string, body?: any, token?: string) {
    const httpOptions = {
      body,
      headers: this.createHeader(token),
    };
    return this.httpClient.delete<ServiceResponse>(
      environment.guestApiEndpoint + url,
      httpOptions
    );
  }
}
