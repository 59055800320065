<nz-switch
  [ngModel]="isChecked"
  [nzCheckedChildren]="checkedTemplate"
  [nzUnCheckedChildren]="unCheckedTemplate"
  [formControl]="form.get('enabled')"
  (change)="onChanged($event)"
  (blur)="onBlur($event)"
  [nzDisabled]="disabled"
></nz-switch>
<ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
<ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
