import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { IAppState } from '@literax/store';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { environment } from '@environments/environment';


/**
 * Not found component displayed when the user enters an invalid url request
 */
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.scss'],
})
export class SignedComponent implements OnDestroy {
  link = environment.domain + '/auth/registration';
  error$ = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.errorDocument)
  );

  errorMessage: any;
  errorCode: any;

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.error$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data !== null) {
        this.errorMessage = data.message;
        this.errorCode = data.code;
      } else {
        this.errorMessage = '';
        this.errorCode = null;
      }
    });
  }

  ngOnDestroy(): void {}
}
