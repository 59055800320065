import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { IAppState } from '@literax/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SignService } from '@literax/services/sign/sign.service';
import { TranslateService } from '@ngx-translate/core';
import { selectCurrentLang } from '@literax/modules/configurations/store/config/config.selectors';
import { setLang } from '@literax/modules/configurations/store/config/config.actions';
import { UntilDestroy } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-uuid-validation',
  templateUrl: './uuid-validation.component.html',
  styleUrls: ['./uuid-validation.component.scss'],
})
export class UuidValidationComponent {
  uuid: string;
  @ViewChild('uiid') input: ElementRef;
  lang$: Observable<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  language: any;
  lang: string;

  constructor(
    private router: Router,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private signService: SignService,
  ) {
    this.lang$ = this.store.pipe(
      select(selectCurrentLang),
      map((lang: string) => lang || 'es'),
      tap((lang: string) => this.updateCurrentLang(lang))
    );
  }

  

  onVerification() {
    this.uuid = this.input.nativeElement.value;
    this.router.navigate(['/validator/uuid/__' + this.uuid]);
  }

  onLogin() {
    this.router.navigate(['/auth/registration']);
  }

  onRegister() {
    this.router.navigate(['auth/registration']);
  }

  setLang(lang: string) {
    this.store.dispatch(setLang({ payload: lang }));
  }

  updateCurrentLang(lang: string) {
    if (lang) {
      this.translate.use(lang);
      this.signService.locale = lang;
      this.language = lang;
    }
  }
}
