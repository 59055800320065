export enum ESignatureType {
  ELECTRONIC = 'electronic_signature',
  ELECTRONIC_PLUS = 'electronic_signature_plus',
  DIGITAL = 'digital_signature',
}

export enum EWorkspaceDocumentTabs {
  ATTACHMENTS = 'attachments',
  PARTICIPANTS = 'participants',
  CHAT = 'chat',
  METADATA = 'metadata',
  INFO = 'document_info',
  DOCUMENT = 'document',
}

export enum EParticipantTypes {
  SIGNER = 'signer',
  APPROVER = 'approver',
  REVIEWER = 'reviewer',
  OBSERVER = 'observer',
}

export enum EFlowName {
  DRAFT = 'draft',
  SIGNATURE = 'signature',
  LEGACY_SIGNATURE = 'legacy_signature',
  PROMISSORY_NOTE_IN_EFFECT = 'promissory_note_in_effect',
  PROMISSORY_NOTE_EXTINCT = 'promissory_note_extinct',
  PROMISSORY_NOTE_EXPIRED = 'promissory_note_expired',
  DATA_COLLECTION = 'data_collection',
  REVIEW = 'review',
  APPROVAL = 'approval',
  REQUEST = 'request',
  FORM = 'form',
}

export enum ETransactionType {
  SIGNATURE = 0,
  REVIEW,
  APPROVAL,
  REQUEST,
  FORM,
}

export enum EProcess {
  WEB = 0,
  SIGNATURE_GATEWAY,
  ASYNC,
}

export enum ESignatureTypeId {
  NATURAL_PERSON = 1,
  LEGAL_PERSON,
}

export enum EDocumentRequest {
  NO_REQUESTED = 0,
  ID_DOCUMENT,
  INE,
}

export enum ESigningMethod {
  DIGITAL_SIGNATURE = 0,
  ELECTRONIC_SIGNATURE,
  ELECTRONIC_SIGNATURE_PLUS,
  ACEPTATION_SIGNATURE,
}

export enum EDocumentUpdatePaths {
  SIGNING_METHOD = '/signingMethod',
  HIERARCHY = '/hierarchy',
  FACEMATCH = '/facematch',
  MINIMUM_APPROVALS = '/minimumApprovals',
  VERIFICATION_TYPE = '/signatureVerificationTypeId',
  HIDDEN = '/hidden',
  HIDDE_COLLABORATORS = '/hideCollaborators',
}

export enum EDocumentCommonActions {
  REJECT = 'reject',
  CANCEL = 'cancel',
  DELETE = 'delete',
  VIEW_SIGNATURES = 'view_signatures',
  DOWNLOAD = 'download',
  BACK_TO_WORKSPACE = 'back_to_workspace',
  TO_DRAFT = 'to_draft',
  SAVE = 'save',
  PROMISSORY_NOTE_EDIT = 'promissory_note_edit',
}

export enum EDocumentSignatureActions {
  SIGN = 'sign',
  SEND_SIGN = 'send_sign',
  SEND_COLLECTION = 'send_collection',
  RETURN_DATA_COLLECTION = 'return_data_collection',
}

export enum EDocumentReviewActions {
  SEND_REVIEW = 'send_review',
  TO_REVIEWED = 'to_reviewed',
  REVIEWED_TO_APPROVAL = 'reviewed_to_approval',
  REVIEWED_TO_SIGNATURE = 'reviewed_to_signature',
  REVIEW_RELOAD = 'review_reload'
}

export enum EDocumentApprovalActions {
  SEND_APPROVAL = 'send_approval',
  TO_APPROVED = 'to_approved',
  APPROVED_TO_SIGNATURE = 'approved_to_signature',
  REJECTED_APPROVAL = 'rejected_approval',
  TO_SIGNATURE = 'to_signature',
}

export enum EDocumentRequestActions {
  TO_SEND = 'to_send',
  ACCEPT = 'mark_as_in_process',
  ASSIGN = 'assign',
  TO_GENERATED = 'to_generated',
  TO_REVIEW = 'to_review',
  TO_APPROVAL = 'to_approval',
  TO_SIGNATURE = 'to_signature',
}

export enum EDocumentStatus {
  DRAFT = 'draft',
  REVIEW = 'review',
  REVIEWED = 'reviewed',
  APPROVAL = 'approval',
  REJECTED_APPROVAL = 'rejected_approval',
  CANCEL_COLLECTION = 'cancel_collection',
  DATA_COLLECTION = 'data_collection',
  IN_SIGN = 'in_sign',
  GENERATED = 'generated',
  CANCEL_SIGNATURE = 'cancel_signature',
  CANCEL_REVIEW = 'cancel_review',
  CANCEL_APPROVAL = 'cancel_approval',
  CANCEL_REQUEST = 'cancel_request',
  CANCEL_REQUEST_SENT = 'cancel_request_sent',
  REJECTED = 'rejected',
  SIGNED = 'signed',
  APPROVED = 'approved',
  UPLOADED = 'uploaded',
  REVIEWING = 'reviewing',
  IN_EFFECT = 'in_effect',
  IN_PROCESS = 'in_process',
  SIGN = 'SIGN',
  EXTINC = 'extinc',
  SENT = 'sent',
  EXPIRED = 'expired',
}

export enum EDocTransactionType {
  REVIEW = 'review',
  SIGNATURE = 'signature',
  APPROVAL = 'approval',
  REQUEST = 'request',
  FORM = 'form',
  REVIEWED = 'reviewed',
  IN_PROCESS = 'mark_as_in_process',
  SENT = 'sent',
  SEND_SIGN = 'send_sign',
}

export enum ESignatureVerificationTypeId {
  EMAIL = 0,
  TOKEN,
  SMS
}

export enum ETypeDocConsult {
  PROJECTS = 'projects',
  SENT = 'sent',
  RECEIVED = 'received',
  SHARED = 'shared',
}

export enum EDocColumnSorting {
  NAME = 'name',
  CREATED_AT = 'created_at',
  EXPIRY_AT = 'expiry_at',
  STATUS_ID = 'status_id',
}

export enum EDocFilterFieldColumn {
  NAME = 'name',
  CREATED_AT = 'created_at',
  FLOW_ID = 'flow_id',
  STATUS_ID = 'status_id',
}

export enum EDocFilterFieldTypeData {
  TEXT = 'text',
  NUMERIC = 'numeric',
  BOOLEAN = 'boolean',
  TIMESTAMP = 'timestamp',
}

export enum EDocFilterFieldTypeOperator {
  EQ = 'eq',
  ILI = 'ili',
  LT = 'lt',
  LTE = 'lte',
  GT = 'gt',
  GTE = 'gte',
}

export enum EShow {
  ALL,
  VISIBLES,
  HIDDEN,
}
