<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="row message">
          <div class="col-12 text-center">
            <h2>{{ 'HTTP.ERRORS.REJECTED.TITLE' | translate }}</h2>
            <h4>{{ error$ | async }}</h4>
            <h4>
              {{ 'HTTP.ERRORS.REJECTED.INVITATION' | translate }}
            </h4>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>