<div class="document-signatures" *ngIf="document">
  <div class="headline">
    <h3>{{'SIGNATURES_SCREEN.TITLE' | translate}}</h3>
    <span>{{document?.name}}</span>
  </div>
  <div class="signatures">

    <div class="frame-headline">
      <div class="document-name">
        {{currentAttachment?.name}}
      </div>
      <div class="tabs">
        <nz-tabset [nzSelectedIndex]="currentTabIndex">
          <nz-tab [nzTitle]="tabTitle" *ngFor="let tab of tabs" (nzClick)="setCurrentTab(tab.key)">
            <ng-template #tabTitle>
              <div class="tab-title">
                <span nz-icon>
                  <i [class]="tab.icon"></i>
                </span>
                <span class="mobile-hide">{{ tab.label | translate }}</span>
              </div>
            </ng-template>
          </nz-tab>
        </nz-tabset>
      </div>
    </div>

    <div class="frame-content">
      <div class="participants">
        <ng-container *ngTemplateOutlet="sinaturesTpl"></ng-container>
      </div>
      <div class="sidebar" [class.active]="currentTab.key === tabTypes.ATTACHMENTS">
        <div class="header">
          {{'SIGNATURES_SCREEN.SIDEBAR'|translate}}
        </div>
        <div class="attachments">
          <div class="attachment-box" *ngFor="let attachment of attachments"
            [class.active]="attachment.id === currentAttachment?.id" (click)="setCurrentAttachment(attachment)">
            <div class="icon">
              <span nz-icon>
                <i class="fal fa-eye"></i>
              </span>
            </div>
            <div class="label">{{attachment.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<ng-template #signatureContent let-signData="signData" let-document="document">
  <div nz-row *ngIf="document.signingConfiguration.signingMethod !== signingMethods.DIGITAL_SIGNATURE">

    <div nz-col nzSpan="24">
      <div class="collaborator-position"
        *ngIf="signData.signatures.length > signData?.onBehalfOf.length && signData?.onBehalfOf.length > 0">
        {{
        'SIGNATURES_SCREEN.SIGNATURE.POSITION.BEHALF_OF'
        | translate
        }}
      </div>

      <div class="collaborator-position" *ngIf="signData?.onBehalfOf.length === signData.signatures.length">
        {{
        'SIGNATURES_SCREEN.SIGNATURE.POSITION.BEHALF_OF'
        | translate
        }}
      </div>
    </div>

    <div *ngIf="signData?.onBehalfOf.length > 0" class="mb-2" nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
      <nz-descriptions nzBordered nzLayout="vertical" nzSize="small">
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.COMPANY_NAME' | translate }}">
          <div *ngFor="let company of signData.onBehalfOf">{{company.companyName}}</div>
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="6" nzXl="18" class="mb-2">
      <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.TIMESTAMP' | translate }}">
          {{
          signData.signature.pscTimestamp * 1000
          | date: 'dd-MM-yyyy HH:mm a'
          }}
          / {{ signData.signature.pscTimestamp * 1000 | date:'dd-MM-yyyy hh:mm a':'+0000' }}
        </nz-descriptions-item>
      </nz-descriptions>

      <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.GEO' | translate }}">
          {{ signData.signature.clientIp }}
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6" class="mb-2">
      <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
        <nz-descriptions-item nzTitle=" {{ 'SIGNATURES_SCREEN.SIGNATURE.DIGITAL_TRACE' | translate }}">
          <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <div>
              <img class="img-ajust" [src]="signData.signature.signatureImageBase64" alt="digital trace" />
            </div>

            <br />
            <div class="img-icon-trace" *ngIf="document.signingConfiguration.signingMethod === signingMethods.ELECTRONIC_SIGNATURE_PLUS">
              <i class="far fa-shield-check">
                {{ 'VALIDATOR_UUID.PLUS' | translate }}
              </i>
            </div>
          </div>
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

  </div>


  <div nz-row *ngIf="document.signingConfiguration.signingMethod === signingMethods.DIGITAL_SIGNATURE">

    <div nz-col nzSpan="24">
      <nz-descriptions nzBordered nzLayout="vertical" nzSize="small">
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.RFC' | translate }}">
          {{ signData?.rfc }}
        </nz-descriptions-item>
      </nz-descriptions>
    </div>


    <div nz-col nzSpan="24">
      <div class="collaborator-position"
        *ngIf="signData.signatures.length > signData?.onBehalfOf.length && signData?.onBehalfOf.length > 0">
        {{
        'VALIDATOR_UUID.MATCARD.TITLE_PERSONAL_ELECTRONIC'
        | translate
        }}
      </div>

      <div class="collaborator-position" *ngIf="signData?.onBehalfOf.length === signData.signatures.length">
        {{
        'VALIDATOR_UUID.FORM.SIGNS.LEGAL'
        | translate
        }}
      </div>
    </div>

    <div *ngIf="signData?.onBehalfOf.length > 0" class="mb-2" nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
      <nz-descriptions nzBordered nzLayout="vertical" nzSize="small">
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.COMPANY' | translate }}">
          <div *ngFor="let company of signData.onBehalfOf">{{company.companyName}}</div>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.COMPANY' | translate }}">
          <div *ngFor="let company of signData.onBehalfOf">{{company.companyTaxId}}</div>
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

    <div nz-col nzSpan="24">
      <nz-descriptions nzBordered nzLayout="vertical" nzSize="small">
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.CERT' | translate }}">
          {{ signData.signature.digitalCertSerialNumber }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="{{ 'SIGNATURES_SCREEN.SIGNATURE.TIMESTAMP_ELECTRONIC' | translate }}">
          {{
          signData.signature.pscTimestamp * 1000
          | date: 'dd-MM-yyyy HH:mm a'
          }}
          / {{ signData.signature.pscTimestamp * 1000 | date:'dd-MM-yyyy hh:mm a':'+0000' }}
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

  </div>


  <div nz-col nzSpan="24">
    <hr />
    <div class="title-nom">NOM 151</div>

    <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
      <nz-descriptions-item nzTitle=" {{ 'SIGNATURES_SCREEN.SIGNATURE.OCSP' | translate }}">
        {{
        signData.signature.pscTimestamp * 1000
        | date: 'dd-MM-yyyy HH:mm a'
        }}
        / {{ signData.signature.pscTimestamp * 1000 | date:'dd-MM-yyyy hh:mm a':'+0000' }}
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle=" {{ 'SIGNATURES_SCREEN.SIGNATURE.PSC_POLICY' | translate }}">
        {{ signData.signature.pscPolicy }}
      </nz-descriptions-item>
    </nz-descriptions>
  </div>


  <div nz-col nzSpan="24">
    <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
      <nz-descriptions-item nzTitle=" {{ 'SIGNATURES_SCREEN.SIGNATURE.FINGER_PRINT' | translate }}">
        {{ signData.signature.fingerPrint }}
      </nz-descriptions-item>
    </nz-descriptions>
  </div>

  <div nz-col nzSpan="24">
    <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
      <nz-descriptions-item nzTitle=" {{ 'SIGNATURES_SCREEN.SIGNATURE.PSC_SIGNATURE' | translate }}">
        {{ signData.signature.pscSignature }}
      </nz-descriptions-item>
    </nz-descriptions>
  </div>

</ng-template>



<ng-template #sinaturesTpl>
  <nz-collapse nzAccordion>
    <nz-collapse-panel *ngFor="let signatures of currentAttachment?.signRequestSignatures" [nzHeader]="titleHeader"
      [nzActive]="false" [nzExtra]="extraSigners" [nzDisabled]="false">

      <ng-template #titleHeader>
        <ng-container *ngIf="document.signingConfiguration.signingMethod === signingMethods.DIGITAL_SIGNATURE">
          {{signatures.name}} | {{signatures.rfc}}
        </ng-container>
        <ng-container *ngIf="document.signingConfiguration.signingMethod !== signingMethods.DIGITAL_SIGNATURE">
          {{signatures.name}} | {{signatures.email}}
        </ng-container>
      </ng-template>

      <ng-container *ngTemplateOutlet="signatureContent;context:{signData:signatures, document}"></ng-container>


    </nz-collapse-panel>
  </nz-collapse>

  <ng-template #extraSigners>
    <span nz-icon>
      <i class="fal fa-pen-fancy"></i>
    </span>
  </ng-template>
</ng-template>