
    <div class="title">
        {{ 'VALIDATOR_UUID.TITLE' | translate }}
    </div>
    <nz-card nzTitle="{{ 'VALIDATOR_UUID.MATCARD.SIGN_TEXT' | translate }}" class="mat-elevation-z3">
        <div class="validation-content">
            <div class="validation-home">
                <div class="position-subtitle">
                    <h4 class="validation-folio">
                        {{ 'VALIDATOR_UUID.MATCARD.TITLE_BOX' | translate }}
                    </h4>
                </div>

                <div class="validation-input">
                    <input type="text" class="wdt-input" #uiid
                        placeholder="{{ 'VALIDATOR_UUID.MATCARD.TITLE_BOX' | translate }}" />
                </div>
                <div class="validation-button">
                    <button nz-button nzType="primary" class="finish-button" (click)="onVerification()">
                        {{ 'VALIDATOR_UUID.MATCARD.BUTTON_BOX' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </nz-card>

    <div class="footer-content">
        <div class="footer-verification">
            {{ 'VALIDATOR_UUID.FOOTER.VERIFICATION_SIGN' | translate }}
        </div>

        <div class="footer-verification">
            {{ 'VALIDATOR_UUID.FOOTER.SIGNER' | translate }}
        </div>

        <div class="footer-register">
            <a class="footer-register" (click)="onRegister()">{{
                'VALIDATOR_UUID.FOOTER.REGISTER' | translate
                }}</a>
        </div>
    </div>
