import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PermissionsConstants } from '../../auth/constans/permissions.constants';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-process-tab',
  templateUrl: './process-tab.component.html',
  styleUrls: ['./process-tab.component.scss'],
})
export class ProcessTabComponent implements OnInit {
  @Input('count') flowCount: number;
  @Input('name') flowName: string;
  @Input('key') flowKey: string;
  @Input() active: boolean;
  @Input() color: string;
  @Input() permissions: string[];
  @Input() isPagare: boolean = false;

  @Output() getFlow = new EventEmitter();

  get showDigitalLegalCollaboration() {
    return this.permissions?.some(
      (p) => p === PermissionsConstants.SHOW_DIGITAL_LEGAL_COLLABORATION
    );
  }
  constructor() {}

  ngOnInit() {}

  onClick(key: string) {
    this.getFlow.emit(key);
  }
}
