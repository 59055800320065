import { createAction, props } from '@ngrx/store';
import {
  ISignaturePositionUpdate,
  ISignerCoordinatesUpdater
} from '@literax/models/http/api/client/pdf.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';

export enum ESignaturePosition {
  UpdateSignaturePosition = '[SignaturePosition] Update SignaturePosition',
  UpdateSignaturePositionSuccess = '[SignaturePosition] Update SignaturePosition Success',
  UpdateSignaturePositionError = '[SignaturePosition] Update SignaturePosition Error'
}

const UpdateSignaturePosition = createAction(
  ESignaturePosition.UpdateSignaturePosition,
  props<{ payload: ISignerCoordinatesUpdater }>()
);

export const UpdateSignaturePositionSuccess = createAction(
  ESignaturePosition.UpdateSignaturePositionSuccess,
  props<{ payload: ISignaturePositionUpdate }>()
);

const UpdateSignaturePositionError = createAction(
  ESignaturePosition.UpdateSignaturePositionError,
  props<{ payload: IAPIResponseError }>()
);

export const signaturePositionActions = {
  UpdateSignaturePosition,
  UpdateSignaturePositionError,
  UpdateSignaturePositionSuccess
};
