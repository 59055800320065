<div class="ir-breadcrumbs">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      {{ documentStatus }}
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      {{ documentName }}
    </nz-breadcrumb-item>
  </nz-breadcrumb>

</div>
