<div nz-row>
  <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
    <div class="main-container">
      <nz-page-header
        class="site-page-header"
        [nzBackIcon]="backIconTemplate"
        [nzTitle]="'SIGNATURES.TITLE' | translate"
      >
        <ng-template #backIconTemplate>
          <literax-back-button></literax-back-button>
        </ng-template>
      </nz-page-header>

      <!-- v3 -->
      <section
        id="document-modal"
        *ngIf="
          viewDocumentSignature$ | async as IDocumentViewSignaturesResponse
        "
        class="mat-elevation-z3"
      >
        <div id="modal-content">
          <div id="document-main" class="preview-row">
            <div id="viewer">
              <!-- seccion tablet -->
              <section class="screen-resolution-show">
                <div nz-row>
                  <div nz-col nzMd="12" nzXs="12" nzSm="12" nzLg="12" nzXl="12">
                    <div id="title-DocumentName">
                      {{ selectViewAttachment.name }}
                    </div>
                  </div>
                  <div nz-col nzMd="12" nzXs="12" nzSm="12" nzLg="12" nzXl="12">
                    <div class="cursor" id="title" (click)="viewDocuments()">
                      <i class="fal fa-paperclip"></i>
                      {{ 'SIGNATURES.DOCUMENTS_LIST' | translate }}
                    </div>
                  </div>

                  <div class="screen-resolution-show" nz-row>
                    <div
                      nz-col
                      nzMd="24"
                      nzXs="24"
                      nzSm="24"
                      nzLg="24"
                      nzXl="24"
                    >
                      <div class="tab-option" *ngIf="isActive">
                        <nz-affix [nzOffsetTop]="120">
                          <div id="items">
                            <literax-attachment-signature
                              *ngFor="
                                let attachment of viewAttachments;
                                let i = index
                              "
                              [attachment]="attachment"
                              [active]="activeTab == i ? true : false"
                              (click)="onTabClicked(i, attachment)"
                            >
                            </literax-attachment-signature>
                          </div>
                        </nz-affix>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!--v3-->
              <nz-collapse>
                <nz-collapse-panel
                  *ngFor="let signature of viewSigners; let i = index"
                  [nzHeader]="signature?.name"
                  [nzActive]="i === 0"
                  [nzExtra]="extraSigners2"
                  [nzDisabled]="false"
                >
                  <literax-signature
                    [digital]="isViewDigitalSignature"
                    [viewSignature]="signature"
                    [document]="document$ | async"
                  >
                  </literax-signature>
                </nz-collapse-panel>
              </nz-collapse>
              <ng-template #extraSigners2>
                <i class="icon-signers fal fa-pen-fancy"></i>
              </ng-template>
            </div>

            <div class="screen-resolution-hidden" id="options-tabs">
              <div id="title">
                {{ 'SIGNATURES.DOCUMENTS_LIST' | translate }}
              </div>
              <div id="items">
                <literax-attachment-signature
                  *ngFor="let attachment of viewAttachments; let i = index"
                  [attachment]="attachment"
                  [active]="activeTab == i ? true : false"
                  (click)="onTabClicked(i, attachment)"
                >
                </literax-attachment-signature>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
