<div class="sign-request">
  <div class="wrapper">
    <div class="signers">
      <div class="signer-info">
        <p class="signer name">
          {{ signer?.company_name || signer?.name }}
          <span
            *ngIf="signer?.is_author && !typePromissoryNote"
            class="author-label"
          >
            | {{ 'DOCUMENTS.SIGNERS.AUTHOR' | translate }}

          </span>
          <i
            *ngIf="signer?.is_author && !typePromissoryNote"
            class="far fa-bell-on icon-author"
          >
          </i>
          <ng-container *ngIf="typePromissoryNote">
            <span class="author-label">
              |
              {{
                'DOCUMENTS.SIGNERS.ROLES.' + signer?.signatory_role?.name
                  | uppercase
                  | translate
              }}
            </span>
            <ng-container [ngSwitch]="signer?.signatory_role?.name">
              <i *ngSwitchCase="'subscriptor'" class="fal fa-money-check-edit-alt promissory-note-icon"></i>
              <i *ngSwitchCase="'guarantor'" class="fal fa-money-check-edit promissory-note-icon"></i>
              <i *ngSwitchCase="'root'" class="fal fa-money-bill promissory-note-icon"></i>
              <i *ngSwitchDefault class="fal fa-money-bill-wave-alt promissory-note-icon"></i>
            </ng-container>
          </ng-container>
        </p>
      </div>

      <nz-tabset *ngIf="!typePromissoryNote">
        <nz-tab 
          [nzTitle]="titleTemplate"
        >
          <ng-template #titleTemplate>
            <div class="check-container">
              <div 
                *ngIf="signer?.status?.key !== 'rejected'" 
                class="rectangle"
                [ngStyle]="{
                  'color': signer?.status?.key === 'draft' ? '#595959' : '#13C2C2' , 
                  'background': signer?.status?.key === 'signer' ? '#F9F0FF' : '#E6FFFB', 
                  'font-size' : '12px' 
                }"
              >
                
                  <i
                    [class]="signerIcons[signer?.signatory_role?.name]"
                    [class.fas]="signer?.signed"
                    [class.fal]="!signer?.signed"
                    [class.inactive_holder]="
                      signer?.holder_status === 'inactive' ||
                      signer?.holder_status === 'canceled'
                    "
                  ></i>
              </div>
              <div
                *ngIf="signer?.status?.key === 'rejected'"
                class="rectangle rejected"
                (click)="openCommentDetails()"
              >
                <i class="fas fa-times"></i>
              </div>
            </div>

          </ng-template>
        </nz-tab>
      </nz-tabset>


      <div class="signer-extra-info">
        
        <div *ngIf="signer?.email" class="info-cell">
          <span class="title">{{
            (signer?.signature_type_name === 'natural_person'
              ? 'DOCUMENTS.SIGNERS.EMAIL'
              : 'DOCUMENTS.SIGNERS.RL_EMAIL'
            ) | translate
          }}:</span>
          <span class="extra-info">{{ signer?.email }}</span>
        </div>

        <div
          *ngIf="
            !signer?.signed &&
            signer?.expiry &&
            !typePromissoryNote &&
            !hasObservers
          "
          class="info-cell"
        >
          <span class="title">{{
            'DOCUMENTS.SIGNERS.EXPIRY' | translate
          }}:</span>
          <span class="extra-info">{{
            signer?.expiry | date: 'dd/MM/yyyy'
          }}</span>
        </div>
        <div
          *ngIf="signer?.signed && signer?.signed_date && !typePromissoryNote"
          class="info-cell"
        >
          <span class="title">{{
            'DOCUMENTS.SIGNERS.SIGN_DATE' | translate
          }}:</span>
          <span class="extra-info">{{
            signer?.signed_date | date: 'dd/MM/yyyy'
          }}</span>
        </div>

        <div
          *ngIf="
            signer?.rfc &&
            (signer?.signature_type_name === 'natural_person' ||
              signer?.signature_type_name === 'legal_person')
          "
          class="info-cell"
        >
          <span class="title">{{
            (signer?.signature_type_name === 'natural_person'
              ? 'DOCUMENTS.SIGNERS.TAX_ID'
              : 'DOCUMENTS.SIGNERS.COMPANY_RFC'
            ) | translate
          }}:</span>
          <span class="extra-info">{{
            signer?.company_rfc || signer?.rfc
          }}</span>
        </div>

        <div *ngIf="signer?.company_name" class="info-cell">
          <span
            class="title"
            *ngIf="signer?.signatory_role?.name !== 'observer'"
          >{{ 'DOCUMENTS.SIGNERS.RL' | translate }}:</span>
          <span
            class="title"
            *ngIf="signer?.signatory_role?.name === 'observer'"
          >{{ 'DOCUMENTS.SIGNERS.OBSERVER' | translate }}:</span>
          <span class="extra-info">{{ signer?.name }}</span>
        </div>
        <div
          *ngIf="signer?.signature_type_name === 'legal_person' && signer?.rfc"
          class="info-cell"
        >
          <span class="title">{{
            'DOCUMENTS.SIGNERS.RL_RFC' | translate
          }}:</span>
          <span class="extra-info">{{ signer?.rfc }}</span>
        </div>
      </div>

    </div>
    <div class="actions-container">
      <literax-popover
        *ngIf="
          userDocument?.id === user?.id &&
          signer?.can_send_notification &&
          !signer?.signed &&
          !signer?.is_author &&
          actionButtonEnable &&
          status !== 'draft' &&
          status !== 'rejected' &&
          !userLoginObservable
        "
        [last_event_email]="signer?.last_email_event"
        [signer]="signer"
        [statusDocument]="status"
        (historyButton)="historyButton.emit($event)"
        (actionButton)="actionButton.emit($event)"
      ></literax-popover>
    </div>
  </div>
</div>
