export class ServicesConstants
{
    /// <summary> 
    /// Usuarios adicionales a los incluidos en el plan
    /// </summary>
    public static EXTRA_USERS = "4100002";
    /// <summary> 
    /// Documentos adicionales a los incluidos en el plan
    /// </summary>
    public static EXTRA_DOCUMENTS = "4100003";
    /// <summary> 
    /// Permite listar documentos
    /// </summary>
    public static LIST_DOCUMENTS = "4100004";
    /// <summary> 
    /// Permite el acceso al Dashboard
    /// </summary>
    public static USE_DASHBOARD = "4100005";
    /// <summary> 
    /// Permite la edición de documentos
    /// </summary>
    public static MANAGE_DOCUMENTS = "4100006";
    /// <summary> 
    /// Permite el uso de pagarés
    /// </summary>
    public static PROMISORY_NOTES = "4100024";
    /// <summary> 
    /// Permite consultar un documento
    /// </summary>
    public static VIEW_DOCUMENTS = "4100007";
    /// <summary> 
    /// Permite la firma de documentos
    /// </summary>
    public static SIGN_DOCUMENTS = "4100008";
    /// <summary> 
    /// Permite compartir documentos
    /// </summary>
    public static SHARE_DOCUMENTS = "4100009";
    /// <summary> 
    /// Permite la descarga de un documento
    /// </summary>
    public static DOWNLOAD_DOCUMENTS = "4100010";
    /// <summary> 
    /// Permite el acceso al módulo de configuración
    /// </summary>
    public static APP_CONFIGURATION = "4100011";
    /// <summary> 
    /// Permite la edición del perfil del cliente
    /// </summary>
    public static EDIT_PROFILE = "4100012";
    /// <summary> 
    /// Permite el uso del API
    /// </summary>
    public static USE_API = "4100013";
    /// <summary> 
    /// Limita la creación de documentos a 50 por mes
    /// </summary>
    public static LIMIT_50_DOCUMENTS = "4100014";
    /// <summary> 
    /// Limita la creación de documentos a 100 por mes
    /// </summary>
    public static LIMIT_100_DOCUMENTS = "4100015";
    /// <summary> 
    /// Limita la creación de documentos a 200 por mes
    /// </summary>
    public static LIMIT_200_DOCUMENTS = "4100016";
    /// <summary> 
    /// Limita la creación de documentos a 500 por mes
    /// </summary>
    public static LIMIT_500_DOCUMENTS = "4100017";
    /// <summary> 
    /// Limita la creación de documentos a 1,000 por mes
    /// </summary>
    public static LIMIT_1000_DOCUMENTS = "4100018";
    /// <summary> 
    /// Permite el uso de Elastic Search
    /// </summary>
    public static FREE_SEARCH = "4100022";
    /// <summary> 
    /// Permite el uso del Drive y Minio
    /// </summary>
    public static STORAGE = "4100023";
    /// <summary> 
    /// Permite el uso de plantillas
    /// </summary>
    public static TEMPLATES = "4100025";
    /// <summary> 
    /// Permite el uso de CLM (Genera, revisa, autoriza)
    /// </summary>
    public static DIGITAL_LEGAL_COLLABORATION = "4100026";
    /// <summary> 
    /// Permite el uso de usuarios colaboradores
    /// </summary>
    public static COLLABORATOR = "4100027";
    /// <summary> 
    /// Permite el registro multi compañías
    /// </summary>
    public static MULTI_COMPANIES = "4100028";
    /// <summary> 
    /// Permite crear perfiles personalizados a usuarios
    /// </summary>
    public static USER_PROFILES = "4100029";
    /// <summary> 
    /// Permite registrar firmas con biométricos
    /// </summary>
    public static BIOMETRICS_SIGN = "4100030";
    /// <summary> 
    /// Permite configurar el Look and Feel
    /// </summary>
    public static LOOKANDFEEL_CONFIG = "4100031";
    /// <summary>
    /// Permite obtener la configuracion de token rsa
    /// </summary>
    public static TOKEN_RSA = "4100032";
    /// <summary>
    /// Permite utilizar Servicios SMS
    /// </summary>
    public static SMS = "4100033";
    /// <summary>
    /// Permite obtener la configuracion cliente de correo
    /// </summary>
    public static EMAIL_CONFIG = "4100034";
    /// <summary> 
    /// Permite el uso de contratos
    /// </summary>
    public static CONTRACTS = "4100035";

}