import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[onDragStart]'
})
export class OnDragStartDirective {
  @Output() onDragStart = new EventEmitter();

  @HostListener('dragstart', ['$event']) public onDragStartDirective(
    event: any
  ) {
    event.stopPropagation();
    this.onDragStart.emit(event);
  }
}
