import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-lenguage',
  templateUrl: './lenguage.component.html',
  styleUrls: ['./lenguage.component.scss'],
})
export class LenguageComponent implements OnInit {
  @Input() lang: string;
  @Output() SetLang = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  setLang(lang: string) {
    this.SetLang.emit({ event: 'set lang', lang });
  }
}
