import { Component, Input, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() icon: string;
  @Input() disabled: string | boolean;
  @Input() class: string;
  constructor() {}
  ngOnInit(): void {}
}
