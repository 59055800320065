<literax-buttons-bar-layout>
  <nz-space slot="left">
    <ng-container *ngIf="!isConfigScreen">
      <!-- ! boton para eliminar documento (autor) -->
      <ng-container *ngIf="status === statusTypes.DRAFT"
        ><button
          nz-button
          *nzSpaceItem
          nzType="primary"
          nzDanger
          (click)="action(actions.application.DELETE)"
        >
          <span nz-icon>
            <i class="fal fa-trash-alt"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.DELETE' | translate }}
        </button></ng-container
      >
      <!-- ! boton para cancelar proceso (autor) -->
      <ng-container *ngIf="status === statusTypes.APPROVAL && isAuthor"
        ><button
          nz-button
          *nzSpaceItem
          nzType="primary"
          nzDanger
          (click)="action(actions.application.CANCEL)"
        >
          <span nz-icon>
            <i class="fal fa-times-circle"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.CANCEL' | translate }}
        </button></ng-container
      >
      <!-- ! boton para rechazar la firma (participante) -->
      <ng-container *ngIf="status === statusTypes.APPROVAL && isParticipant"
        ><button
          nz-button
          *nzSpaceItem
          nzType="primary"
          nzDanger
          (click)="action(actions.application.REJECT)"
        >
          {{ 'WORKSPACE.FOOTER.REJECTED_APPROVAL' | translate }}
        </button></ng-container
      >
    </ng-container>
    <!-- ! boton para regresar a workspace (autor, pantalla de configuracion) -->
    <ng-container *ngIf="isConfigScreen"
      ><button
        nz-button
        *nzSpaceItem
        nzType="primary"
        nzDanger
        (click)="action(actions.application.BACK_TO_WORKSPACE)"
      >
        <span nz-icon>
          <i class="fal fa-arrow-to-left"></i>
        </span>
        {{ 'WORKSPACE.FOOTER.BACK' | translate }}
      </button></ng-container
    >
  </nz-space>

  <nz-space slot="right">
    <ng-container *ngIf="!isConfigScreen">
      <!-- ! boton descarga de documento(s) -->
      <ng-container
        *ngIf="isAuthor"
      >
        <button
          nz-button
          *nzSpaceItem
          nzType="default"
          (click)="action(actions.application.DOWNLOAD)"
        >
          <span nz-icon>
            <i class="far fa-cloud-download"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.DOWNLOAD' | translate }}
        </button>
      </ng-container>
      <!-- ! boton para "guardar" anexo docx  -->
      <ng-container
        *ngIf="
          isViewingDocxAttachment &&
          hasPdfVersion === false &&
          status === statusTypes.DRAFT &&
          isAuthor
        "
      >
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          (click)="action(actions.application.SAVE)"
        >
          <span nz-icon>
            <i class="fal fa-save"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SAVE' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="status === statusTypes.APPROVAL && isParticipant">
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          (click)="action(actions.approval.TO_APPROVED)"
        >
          <span nz-icon>
            <i class="far fa-check-double"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.APPROVE' | translate }}
        </button>
      </ng-container>

      <ng-container
        *ngIf="
          status === statusTypes.DRAFT &&
          attachment?.viewerType === viewerModes.PDF
        "
      >
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          [disabled]="!haveParticipants"
          (click)="action(actions.approval.SEND_APPROVAL)"
        >
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND' | translate }}
        </button>
      </ng-container>

      <!-- ! boton envio a firma (autor) -->
      <ng-container
        *ngIf="
          status === statusTypes.APPROVED &&
          flowWithTemplate === false &&
          isAuthor
        "
      >
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          [disabled]="!haveParticipants"
          (click)="action(actions.approval.TO_SIGNATURE)"
        >
          <span nz-icon>
            <i class="far fa-paper-plane"></i>
          </span>
          {{ 'WORKSPACE.FOOTER.SEND_TO_SIGN' | translate }}
        </button>
      </ng-container>
    </ng-container>
    <!-- ! boton de marcar como (autor) -->
    <ng-container
      *ngIf="
        isAuthor &&
        (status === statusTypes.EXPIRED || status === statusTypes.REJECTED)
      "
    >
      <button
        nz-button
        *nzSpaceItem
        nz-dropdown
        nzType="default"
        nzTrigger="click"
        [nzDropdownMenu]="returnToDraft"
      >
        <span nz-icon>
          <i class="fal fa-share-alt"></i>
        </span>
        {{ 'WORKSPACE.FOOTER.SEND_TO' | translate }}
      </button>
    </ng-container>
  </nz-space>

  <nz-dropdown-menu #returnToDraft="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <button
          nz-button
          nzType="primary"
          nzBlock
          (click)="action(actions.application.TO_DRAFT)"
        >
          {{ 'WORKSPACE.FOOTER.ACTIONS.TO_DRAFT' | translate }}
        </button>
      </li>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #singOrDataCollection="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <button
          nz-button
          nzType="primary"
          nzBlock
          (click)="action('send_collection')"
        >
          {{ 'WORKSPACE.FOOTER.SEND_TO_COLLECT' | translate }}
        </button>
      </li>
      <li nz-menu-item>
        <button
          [disabled]="formBuilderInvalid"
          nz-button
          nzBlock
          nzType="primary"
          (click)="action('mark_as_in_process')"
        >
          {{ 'WORKSPACE.FOOTER.SEND_TO_SIGN' | translate }}
        </button>
      </li>
    </ul>
  </nz-dropdown-menu>
</literax-buttons-bar-layout>
