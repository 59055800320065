<cdk-virtual-scroll-viewport
  [itemSize]="itemRowSize"
  [maxBufferPx]="itemRowSize * 2"
  [minBufferPx]="itemRowSize"
  [style.height]="height"
  (scrolledIndexChange)="nextBatch($event)"
>
  <ng-template let-row let-index="index" cdkVirtualFor [cdkVirtualForOf]="list">
    <ng-container
      [ngTemplateOutlet]="rowTemplate || rowTem"
      [ngTemplateOutletContext]="{ $implicit: row, index: index }"
    >
    </ng-container>
  </ng-template>
</cdk-virtual-scroll-viewport>
