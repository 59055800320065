<form
  #generateForm
  [formGroup]="form"
>
  <div class="row">
    <div class="col-8">
      <label class="title-calendar">
        {{
          'WORKSPACE.GENERATE.MODAL.TITLE_CONTROL_DATE' | translate
        }}</label
      >
    </div>
    <div class="col-12">
      <nz-date-picker
        formControlName="delivery_date"
        nzFormat="dd-MM-yyyy"
        [nzDisabledDate]="disabledDate"
      ></nz-date-picker>
    </div>
  </div>

  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="cancel()">
      <i class="fal fa-times-circle"></i>&nbsp;{{ 'WORKSPACE.GENERATE.MODAL.BUTTON_CANCEL' | translate }}
    </button>
    <button nz-button nzType="primary" (click)="saveGenerateDate()">
      <i class="fal fa-save"></i>&nbsp;{{ 'WORKSPACE.GENERATE.MODAL.BUTTON_SAVE' | translate }}
    </button>
  </div>
</form>
