import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store, select } from '@ngrx/store';

import { EDocumentStatus } from '@literax/enums/document.enum';
import { IAppState } from '@literax/store';
import { IAttachment } from '@literax/models/http/attachment.model';
import { IDocument } from '@literax/models/http/document/document.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { attachments } from '@literax/modules/documents/store/document/document.selectors';
import { selectAttachments } from '@literax/store/free-document/free-document.selectors';


import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() document: IDocument;
  @Input() documentsSecondary: IDocument[];
  @Input() mainDoc: IDocument;
  @Input() disabledActions: boolean;
  @Input() typePromissoryNote: boolean;
  @Output() attachmentSelected = new EventEmitter();
  @Output() replaceAttachment = new EventEmitter();
  @Output() editAttachment = new EventEmitter();
  @Output() checkAttachmentId = new EventEmitter();

  attachments$ = this.store.pipe(untilDestroyed(this), select(attachments));
  freeDocumentAttachments$ = this.store.pipe(
    untilDestroyed(this),
    select(selectAttachments)
  );

  attachments: IAttachment[] = [];
  mainDocument: IAttachment;
  edit: boolean;

  @Input() workarea: 'free' | 'account' | 'promissory_note' = 'account';
  showCheckAttachment = false;

  constructor(private router: Router, private store: Store<IAppState>) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    const AttachmentKey = this.document?.status?.key;
    const isUserSigner = this.document?.user_signer?.length > 0;
    this.showCheckAttachment =
      isUserSigner &&
      (AttachmentKey === EDocumentStatus.APPROVAL ||
        AttachmentKey === EDocumentStatus.REVIEW);
  }

  ngOnChanges() {
    if (this.document) {
      if (this.workarea === 'free') {
        this.setupAttachments(this.freeDocumentAttachments$);
      } else {
        this.setupAttachments(this.attachments$);
      }
    } else if (
      (this.documentsSecondary || this.mainDoc) &&
      this.typePromissoryNote
    ) {
      this.findDocumentsAttachments();
    }
  }

  setupAttachments(observable: Observable<IAttachment[]>): void {
    observable.subscribe((attachments) => {
      const documentInfo = {
        id: this.document.id,
        status: this.document.status
      };
      this.edit = this.document?.status?.key === 'draft';
      this.attachments = attachments.map((el: IAttachment) => ({
        ...el,
        documentInfo
      }));
      this.mainDocument = this.attachments.find((element) => element.primary);
      if (this.mainDocument) {
        this.attachments = this.attachments.filter(
          (attachment) => attachment.id !== this.mainDocument.id
        );
        this.typePromissoryNote =
          this.mainDocument.kind === 'promissory_note' ||
          this.mainDocument.kind === 'template';
      }
    });
  }

  attachmentDetail(attachmentId: number) {
    this.attachmentSelected.emit(attachmentId);
  }

  checkAttachment(attachmentId: number) {
    this.checkAttachmentId.emit(attachmentId);
  }

  goToSign(documentId: number) {
    this.router.navigate([`/platform/documents/${documentId}`]);
  }

  findDocumentsAttachments() {
    if (this.mainDoc) {
      this.mainDocument = {
        ...this.mainDoc.attachments[0],
        documentInfo: { id: this.mainDoc.id, status: this.mainDoc.status }
      };
    }

    if (this.documentsSecondary) {
      this.documentsSecondary.forEach((document) => {
        const documentInfo = { id: document.id, status: document.status };
        this.attachments.push({ ...document.attachments[0], documentInfo });
      });
    }
  }
}
