import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IDocument,
  ICancelatioReason
} from '@literax/models/http/document/document.model';
import { IUser } from '@literax/models/user.model';
import { ISignRequest } from '@literax/modules/documents/store/document/document.state';
import { MatDialog } from '@angular/material/dialog';
import { ShowCommentRejectedComponent } from '@literax/components/platform/workspace/show-comment-rejected/show-comment-rejected.component';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@literax/store';

import { selectCommentRejected } from '@literax/modules/documents/store/document/document.selectors';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.scss']
})
export class ApplicantComponent implements OnInit, OnDestroy {
  @Input() applicant: ISignRequest;
  @Input() document: IDocument;
  @Input() user: IUser;
  @Input() userDocument: IUser;
  @Input() isOwner = false;

  comment$ = this.store.pipe(
    untilDestroyed(this),
    select(selectCommentRejected)
  );
  comment: ICancelatioReason[];

  constructor(private dialog: MatDialog, private store: Store<IAppState>) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.comment$
      .pipe(untilDestroyed(this))
      .subscribe((data) => (this.comment = data));
  }

  openCommentDetails(): void {
    const dialogRef = this.dialog.open(ShowCommentRejectedComponent, {
      width: '656px',
      height: 'auto',
      disableClose: true,
      data: {
        comment: this.comment,
        user: this.applicant.name
      }
    });
  }
}
