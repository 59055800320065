import { Injectable } from '@angular/core';
import { BaseService } from '../base-config/base.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  constructor(private base: BaseService) {}

  getStatuses(type: string, sort: string) {
    return this.base.get(`/statuses/${type}?sort=${sort}`);
  }
}
