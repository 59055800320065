import { IApprover } from '@literax/models/approver.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';

export interface IApproverRequestWorkspaceState {
  approvers: IApprover[];
  newApproverCreationErrors: Partial<IAPIResponseError>;
  newApproverCreationSuccess: Partial<IApprover>;
}

export const initialApproverRequestWorkspaceState: IApproverRequestWorkspaceState = {
  approvers: [],
  newApproverCreationErrors: null,
  newApproverCreationSuccess: null
};
