import { Client } from '@literax/models/http/onboarding/client.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IClient } from '@literax/models/http/api/client/client.model';
import { IUserActiveDelegateItem } from '@literax/models/user.model';
import { ILegalRepresentativeResponse } from '@literax/models/client.model';

export interface IClientState {
  clients?: IClient[];
  currentClient?: IClient;
  client: Client;
  clientError: IAPIResponseError;
  clientDelegate: { [k: string]: IUserActiveDelegateItem[] };
  legalRepresentatives: ILegalRepresentativeResponse[];
}

export const initialClientState: IClientState = {
  clients: [],
  currentClient: null,
  client: null,
  clientError: null,
  legalRepresentatives: [],
  clientDelegate: null,
};
