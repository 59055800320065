<div class="row-sumary">
  {{ 'DOCUMENT-CONFIGURATION.REASSIGMENT.ASSING_INFO' | translate }}
</div>
<div class="representatives">
  <nz-collapse nzAccordion>
    <nz-collapse-panel
      *ngFor="let d of data.departmentReceivers$ | async; let i = index"
      [nzHeader]="d.name"
      [nzActive]="i === 0"
    >
      <div *ngFor="let r of d?.receivers">
        <nz-radio-group [(ngModel)]="receiver">
          <label
            nz-radio
            [nzValue]="{
              userId: r.id,
              userName: r.receiverName + '' + r.receiverLastname
            }"
            >{{ r.receiverName }} {{ r.receiverLastname }}</label
          >
        </nz-radio-group>
      </div>
      <div *ngIf="d?.receivers?.length === 0">
        {{
          'WIZARD.DOCUMENTS.FORMS.DIALOG_LEGAL_REPRESENTATIVES.EMPTY'
            | translate
        }}
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>
<div class="row-buttons" *nzModalFooter>
  <div class="buttons">
    <button nz-button nzType="default" type="button" (click)="modal.closeAll()">
      {{ 'TEMPLATES_FIELD.MODAL.CANCEL' | translate }}
    </button>
    <button
      nz-button
      nzType="primary"
      [disabled]="!receiver"
      (click)="data.delegate(receiver)"
    >
      {{ 'WORKSPACE.FOOTER.DELEGATE' | translate }}
    </button>
  </div>
</div>
