<canvas
  id="canvas"
  class="signature drawer"
  (mousedown)="drawSignature()"
  (mousemove)="traceSignatureMouse($event)"
  (mouseup)="redibujarTrazado()"
  (touchstart)="drawSignature()"
  (touchmove)="traceSignatureTouch($event)"
  (touchend)="redibujarTrazado()"
></canvas>
<div class="clean-drawer">
  <span (click)="cleanSignature()" class="title-font" *ngIf="clean">
    {{ 'REPORTS.FILTER.CLEAR' | translate }}
  </span>
</div>
