import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private http: HttpClient) {}

  public getB2cToken() {
    const fd = new FormData();
    fd.append(
      'username',
      environment.paymentWidgetGetnetConfig.b2cCredentials.user
    );
    fd.append(
      'password',
      environment.paymentWidgetGetnetConfig.b2cCredentials.pwd
    );
    fd.append(
      'grant_type',
      environment.paymentWidgetGetnetConfig.b2cCredentials.grant_type
    );
    fd.append(
      'scope',
      environment.paymentWidgetGetnetConfig.b2cCredentials.scope
    );
    fd.append(
      'client_id',
      environment.paymentWidgetGetnetConfig.b2cCredentials.client_id
    );
    fd.append(
      'response_type',
      environment.paymentWidgetGetnetConfig.b2cCredentials.response_type
    );

    return this.http.post(
      environment.paymentWidgetGetnetConfig.b2cCredentials.url,
      fd
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getB2cTokenSynchronous(): Promise<any> {
    return this.getB2cToken().toPromise();
  }
}
