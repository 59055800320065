import { state } from '@angular/animations';
import { ApplicationReducer } from '@literax/interfaces/app.interfaces';
import { WorkspaceState } from '../workspace.state';

export const ParticipantReducers: ApplicationReducer<WorkspaceState> = {
  addMultipleSuccess: (state, action) => ({ ...state }),
  getSignerEmailHistorySuccess: (state, action) => {
    const legalPersons = [...state.configuration.legalPersons].map(
      (participant) => {
        return {
          ...participant,
          legalRepresentatives: participant.legalRepresentatives.map(
            (legalRepresentative) =>
              legalRepresentative.signRequestId === action.payload.signerId
                ? {
                    ...legalRepresentative,
                    lastEmailEvent: {
                      ...legalRepresentative.lastEmailEvent,
                      history: action.payload.history,
                    },
                  }
                : legalRepresentative
          ),
        };
      }
    );
    const naturalPersons = [...state.configuration.naturalPersons].map(
      (participant) =>
        participant.signRequestId === action.payload.signerId
          ? {
              ...participant,
              lastEmailEvent: {
                ...participant.lastEmailEvent,
                history: action.payload.history,
              },
            }
          : participant
    );

    return {
      ...state,
      configuration: {
        ...state.configuration,
        legalPersons,
        naturalPersons,
        participants: [...legalPersons, ...naturalPersons],
        participantsCount:
          [legalPersons, naturalPersons].reduce(
            (accumulator, current) => current?.length + accumulator,
            0
          ) ?? 0,
      },
    };
  },
  getSignatureQuotesSuccess: (state, action) => ({
    ...state,
    configuration: {
      ...state.configuration,
      participantSignatureQuotes: action.payload,
    },
  }),
};
