import {
  EAttachmentReadStatus,
  EKindAttachment,
  EViewerType,
} from '@literax/enums/attachment.enum';
import {
  EDocumentStatus,
  ETransactionType,
} from '@literax/enums/document.enum';
import {
  IAttachment,
  IViewingAttachment,
} from '@literax/models/attachment.model';

import { EWorkingMode } from '@literax/modules/documents/store/workspace/workspace.state';
import { IDocumentResponse } from '@literax/models/document.model';
import { checkIfDocxAttachmentHasPdf } from '@literax/modules/documents/workspace/components/buttons-bar-layout/workspace-button-bar';

export const formatAttachment = (
  attachment: IAttachment | any,
  workingMode: EWorkingMode,
  document: IDocumentResponse
) => {
  if (attachment === null || document === null) return;
  const {
    id,
    name,
    filePath: url,
    kind,
    primary,
    readStatus,
    filePlainText,
  } = attachment;

  let viewingAttachment: IViewingAttachment = {
    id,
    name,
    url,
    kind,
    primary,
    readStatus,
    filePlainText,
  };

  const setViewerType = () => {
    if (
      checkIfDocxAttachmentHasPdf(id) ||
      (workingMode === EWorkingMode.GUEST &&
        document.transactionType !== ETransactionType.REVIEW)
    ) {
      return EViewerType.PDF;
    }

    if (document?.status?.key === EDocumentStatus.IN_SIGN) {
      return EViewerType.PDF;
    }

    if (
      [EDocumentStatus.APPROVAL, EDocumentStatus.APPROVED].includes(
        document?.status?.key
      )
    ) {
      return EViewerType.PDF;
    }

    return EViewerType.DOCX;
  };

  const detectIfIsRequiredPDF = () => {
    if (
      workingMode === EWorkingMode.GUEST &&
      document.transactionType !== ETransactionType.REVIEW
    ) {
      return true;
    }

    if (document?.status?.key === EDocumentStatus.IN_SIGN) {
      return true;
    }

    if (
      document.transactionType === ETransactionType.APPROVAL &&
      document?.status?.key === EDocumentStatus.APPROVAL
    ) {
      return true;
    }

    return checkIfDocxAttachmentHasPdf(id);
  };

  const isDocx: Pick<
    IViewingAttachment,
    'textEditorConfig' | 'hasPdfVersion' | 'viewerType'
  > = {
    textEditorConfig: {
      subscriberId: attachment.textEditorConfig?.subscriberId || null,
      key: attachment.textEditorConfig?.key || null,
    },
    hasPdfVersion: detectIfIsRequiredPDF(),
    viewerType: setViewerType(),
  };

  const isPdf: Pick<
    IViewingAttachment,
    'readStatus' | 'viewerType' | 'hasXml'
  > = {
    viewerType: EViewerType.PDF,
    hasXml: attachment.filePlainText !== null,
  };

  if (viewingAttachment.kind === EKindAttachment.DOCX) {
    viewingAttachment = { ...viewingAttachment, ...isDocx };
  }

  if (viewingAttachment.kind === EKindAttachment.MD) {
    viewingAttachment = {
      ...viewingAttachment,
      viewerType: EViewerType.MD,
      readStatus: EAttachmentReadStatus.READED,
    };
  }

  if (
    [
      EKindAttachment.PDF,
      EKindAttachment.XML,
      EKindAttachment.TICKET,
      EKindAttachment.TEMPLATE,
      EKindAttachment.ASSIGNMENT_OF_RIGHTS,
      EKindAttachment.ENDORSEMENT,
      EKindAttachment.ENDORSEMENT_REVOCATION,
      EKindAttachment.RECEIPT,
      EKindAttachment.PROMISSORY_NOTE,
      EKindAttachment.PROMISSORY_NOTE_EXTINCTION,
      EKindAttachment.PROMISSORY_NOTE_PRESENTATION_FOR_PAYMENT,
      EKindAttachment.CONTRACT,
      EKindAttachment.CONTRACT_ATTACHMENT,
    ].includes(kind)
  ) {
    viewingAttachment = { ...viewingAttachment, ...isPdf };
  }

  return viewingAttachment;
};
