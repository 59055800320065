<div class="applicant-request">
  <div class="wrapper">
    <div class="applicants">
      <div class="applicant-info">
        <p class="applicant name">
          {{ isOwner ? user?.name + ' ' + user?.last_name : applicant?.name }}
          <nz-divider nzType="vertical"></nz-divider>
          <span class="author-label">
            {{
              (isOwner
                ? 'DOCUMENT-CONFIGURATION.APPLICANT.APPLICANT'
                : 'DOCUMENT-CONFIGURATION.APPLICANT.APPLICANTTO'
              ) | translate
            }}
          </span>
          <em
            *ngIf="isOwner || applicant?.status?.key !== 'rejected'"
            class="fal fa-cogs"
          >
          </em>
          <em
            (click)="openCommentDetails()"
            *ngIf="applicant?.status?.key === 'rejected'"
            class="fas fa-times"
          >
          </em>
        </p>
      </div>

      <div class="applicant-extra-info">
        <div class="info-cell">
          <span class="title"
            >{{ 'DOCUMENTS.SIGNERS.EMAIL' | translate }}:</span
          >
          <span class="extra-info">{{ applicant?.email }}</span>
        </div>
      </div>

      <div class="applicant-extra-info">
        <div class="info-cell">
          <span class="title"
            >{{
              (isOwner
                ? 'DOCUMENT-CONFIGURATION.APPLICANT.REQUEST_DATE'
                : 'DOCUMENT-CONFIGURATION.APPLICANT.EXPIRY'
              ) | translate
            }}:</span
          >
          <span class="extra-info">{{
            applicant?.expiry || document?.created_at | date: 'dd/MM/yyyy'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
