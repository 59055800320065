import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

import { AppUtils } from '@literax/utils/app.utils';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-signature-canvas',
  templateUrl: './signature-canvas.component.html',
  styleUrls: ['./signature-canvas.component.scss'],
})
export class SignatureCanvasComponent implements OnInit, AfterViewInit {
  canvas: any;
  context: any;
  cw: number;
  ch: number;
  clean = false;
  insulationFactor = 5;
  canvasBlank = document.createElement('canvas');
  trazados = [];
  puntos = [];
  dibujar = false;
  @Output() signatureDone: EventEmitter<Blob> = new EventEmitter<Blob>();
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.setCanvas();
    }, 100);
  }

  preventDefault(e) {
    e.preventDefault();
  }

  disableScroll() {
    document.body.addEventListener('touchmove', this.preventDefault, {
      passive: false,
    });
  }

  enableScroll() {
    document.body.removeEventListener('touchmove', this.preventDefault);
  }

  setCanvas() {
    this.canvas = document.getElementById('canvas');
    this.context = this.canvas.getContext('2d');
    this.context.lineJoin = 'round';
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const width = this.canvas.offsetWidth * ratio;
    const height = this.canvas.offsetHeight * ratio;
    this.cw = width;
    this.ch = height;
    this.canvas.width = width;
    this.canvas.height = height;
    this.context.scale(ratio, ratio);

  }
  drawSignature() {
    this.dibujar = true;
    this.puntos.length = 0;
    this.disableScroll();
    this.context.beginPath();
  }
  traceSignature(position) {
    this.puntos.push(position);
    this.context.lineTo(position.x, position.y);
    this.context.stroke();
  }

  traceSignatureMouse(event) {
    if (this.dibujar) {
      const position = this.oMousePos(this.canvas, event);
      this.traceSignature(position);
    }
  }
  traceSignatureTouch(event) {
    if (this.dibujar) {
      const position = this.onTouchPos(this.canvas, event);
      this.traceSignature(position);
    }
  }

  reducirArray(n, elArray) {
    const nuevoArray = [];
    for (let i = 0; i < elArray.length; i++) {
      if (i % n === 0) {
        nuevoArray[nuevoArray.length] = elArray[i];
      }
    }
    nuevoArray[nuevoArray.length - 1] = elArray[elArray.length - 1];
    this.trazados.push(nuevoArray);
  }
  calcularPuntoDeControl(ry, a, b) {
    const pc: any = {};
    pc.x = (ry[a].x + ry[b].x) / 2;
    pc.y = (ry[a].y + ry[b].y) / 2;
    return pc;
  }
  alisarTrazado(ry) {
    if (ry.length > 1) {
      const ultimoPunto = ry.length - 1;
      this.context.beginPath();
      this.context.moveTo(ry[0].x, ry[0].y);
      for (let i = 1; i < ry.length - 2; i++) {
        const pc = this.calcularPuntoDeControl(ry, i, i + 1);
        this.context.quadraticCurveTo(ry[i].x, ry[i].y, pc.x, pc.y);
      }
      this.context.quadraticCurveTo(
        ry[ultimoPunto - 1].x,
        ry[ultimoPunto - 1].y,
        ry[ultimoPunto].x,
        ry[ultimoPunto].y
      );
      this.context.stroke();
    }
  }
  redibujarTrazado() {
    this.dibujar = false;
    this.enableScroll();
    this.context.clearRect(0, 0, this.cw, this.ch);
    this.reducirArray(this.insulationFactor, this.puntos);
    this.trazados.forEach((elemento) => this.alisarTrazado(elemento));
    this.saveSignature();
  }
  cleanSignature() {
    this.dibujar = false;
    this.context.clearRect(0, 0, this.cw, this.ch);
    this.trazados.length = 0;
    this.puntos.length = 0;
    this.clean = !this.clean;
    this.signatureDone.emit(null);
  }

  saveSignature() {
    const image = this.canvas.toDataURL('image/png');
    if (this.canvas.toDataURL() !== this.canvasBlank.toDataURL()) {
      const file = this.dataURItoBlob(image);
      this.signatureDone.emit(file);
      this.clean = true;
    }
  }
  oMousePos(canvas, evt) {
    const ClientRect = canvas.getBoundingClientRect();
    return {
      x: Math.round(evt.clientX - ClientRect.left),
      y: Math.round(evt.clientY - ClientRect.top),
    };
  }
  onTouchPos(canvas, evt) {
    const ClientRect = canvas.getBoundingClientRect();
    return {
      x: Math.round(evt.touches[0].clientX - ClientRect.left),
      y: Math.round(evt.touches[0].clientY - ClientRect.top),
    };
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = decodeURI(dataURI.split(',')[1]);
    
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ia], `signature_image.${AppUtils.extensionFromMimeType(mimeString)}`, { type: mimeString });
  }
}
