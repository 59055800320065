import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-to-validate',
  templateUrl: './to-validate.component.html',
  styleUrls: ['./to-validate.component.scss']
})
export class ToValidateComponent implements OnInit {

  docName: string;
  link = environment.literaxUrl;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.docName = this.route.snapshot.paramMap.get('docName');
  }

  goToUrl() {
    window.location.href = this.link;
  }

}
