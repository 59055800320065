<ul>
  <li
    *ngFor="let tab of tabs"
    (click)="selectTab(tab)"
    [class.active]="tab.active"
  >
    <button>{{ tab.title }}</button>
  </li>
  <!-- dynamic tabs -->
  <li
    *ngFor="let tab of dynamicTabs"
    (click)="selectTab(tab)"
    [class.active]="tab.active"
  >
    <button>
      {{ tab.title }}
      <span class="tab-close" *ngIf="tab.isCloseable" (click)="closeTab(tab)"
        >x</span
      >
    </button>
  </li>
</ul>
<ng-content></ng-content>
<ng-template dynamic-tabs #container></ng-template>
