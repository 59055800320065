import { ETemplateProcessKind, ETemplateUpdatePaths } from '@literax/enums/template.enum';
import {
  FieldsOnlyOffice,
  IProfile,
  IQueryParameters,
  ITemplate,
  ITemplateById,
  ITemplateKinds,
  ITemplatePayload,
  ITemplateProfile,
  ITemplateRelationMetaDataPayload,
  ITemplateResponse,
  ITemplates,
} from '@literax/models/templates';
import {
  FilterParams,
  TemplateCollector,
  TemplateFieldData,
  TemplateSigner,
} from '@literax/models/templates-reports.model';
import { IPagination, IPaginationV3 } from '@literax/models/pagination.model';
import { createAction, props } from '@ngrx/store';

import { ColumnSort } from '@literax/models/table.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ICreateDocument } from '@literax/models/http/document/create-document.model';
import { ITemplatesFields } from '@literax/models/templates-fields.model';
import { IUpdateData } from '@literax/models/common';
import { Process } from '@literax/models/process.model';

export enum ETemplatesActions {
  /* == START: Acciones para la creacion de template == */
  CreateTemplate = '[Templates] Create Template',
  CreateTemplateSuccess = '[Templates] Create Template Success',
  CreateTemplateError = '[Templates] Create Template Error',
  /* == END: Acciones para la creacion de template == */

  /* == START: Acciones para la edicion de template == */
  EditTemplate = '[Templates] Edit Template',
  EditTemplateSuccess = '[Templates] Edit Template Success',
  EditTemplateError = '[Templates] Edit Template Error',
  /* == END: Acciones para la edicion de template == */

  /* == START: Acciones para la edicion de template == */
  GetTemplate = '[Templates] Get Template',
  GetTemplateSuccess = '[Templates] Get Template Success',
  GetTemplateError = '[Templates] Get Template Error',
  /* == END: Acciones para la edicion de template == */
  Search = '[Templates] Search template',
  Latest = '[Templates] get latest',
  LatestSuccess = '[Templates] get latest success',
  ResetPagination = '[Templates] reset templates current page',

  CreateDocument = '[Templates] Create Document From Template',

  IndexTemplate = '[Templates] Index Templates',
  IndexTemplateSucess = '[Templates] Index Templates Success',
  ErrorTemplate = '[Templates] Index Templates Error',
  GetFlows = '[Templates] show Flows templates',
  GetFlowsSuccess = '[Templates] show Flows templates Success',
  ChangeFlow = '[Templates] ChangeFlow',
  ExportExcel = '[Templates] Export Excel',
  SortColumn = '[Templates] Sort Column',
  GetTemplateFields = '[Templates] Get Template Fields',
  GetTemplateFieldsSuccess = '[Templates] Get Template Fields Success',
  SearchTemplateField = '[Templates] Search Template Fields',
  SearchTemplateFieldSuccess = '[Templates] Search Template Fields Success',
  ShowTemplate = '[Templates] Show Template',// v2 quitar
  ShowTemplateSuccess = '[Templates] Show Template Success',// v2 quitar
  RelationshipTemplateFields = '[Templates] Relationship Template Fields',
  RelationshipTemplateFieldsSuccess = '[Templates] Relationship Template Fields Success',
  ChangeStatusTemplate = '[Templates] Change status template',
  ChangeStatusTemplateSuccess = '[Templates] Change status template success',
  ChangeStatusTemplateError = '[Templates] Change status template error',
  IndexTemplateFields = '[Templates] Index Template Fields',
  IndexTemplateFieldsSuccess = '[Templates] Index Template Fields Success',
  UpdateTemplateField = '[Templates] update Template Field',
  UpdateTemplateFieldSuccess = '[Templates] update Template Field Success',

  ClearTemplateFields = '[Templates] clear templates for workspace',

  DeleteTemplate = '[Templates] Delete templates',
  DeleteTemplateSuccess = '[Templates] Delete templates success',
  DeleteTemplateError = '[Templates] Delete templates error',
  UpdateTemplateNameDepartments = '[Templates] update template name and departments',
  UpdateTemplateNameDepartmentsSuccess = '[Templates] update template name and departments success',
  UpdateTemplateNameDepartmentsError = '[Templates] update template name and departments error',

  ChangeDepartment = '[Templates] Change Departments',
  ChangeKind = '[Templates] Change kind',

  GetKinds = '[Templates] Get kinds',
  GetKindsSuccess = '[Templates] Get kinds success',
  GetKindsError = '[Templates] Get kinds error',

  DeleteTemplateField = '[Templates] Delete template field',
  DeleteTemplateFieldSuccess = '[Templates] Delete template field success',
  DeleteTemplateFieldError = '[Templates] Delete template field error',

  FindTemplateFields = '[Templates] Find Template fields',
  FindTemplateFieldsSuccess = '[Templates] Find Template fields success',
  FindTemplateFieldsError = '[Templates] Find Template fields error',

  CreateProfile = '[Templates] Create profile',
  CreateProfileSuccess = '[Templates] Create profile success',
  CreateProfileError = '[Templates] Create profile error',

  GetProfiles = '[Templates] Get profiles',
  GetProfilesSuccess = '[Templates] Get profiles success',
  GetProfilesError = '[Templates] Get profiles error',

  MetadataTemplateProfile = '[Templates]  Template metadata Profile',
  MetadataTemplateProfileSuccess = '[Templates]  Template metadata Profile success',
  MetadataTemplateProfileError = '[Templates]  Template metadata Profile error',

  ClearMetadataTemplateProfile = '[Templates] ClearMetadataTemplateProfile',

  DeleteMetadataProfile = '[Templates] delete metadata profile',
  DeleteMetadataProfileSuccess = '[Templates] delete metadata profile success',
  DeleteMetadataProfileError = '[Templates] delete metadata profile error',

  UpdateProfileNameDepartments = '[Templates] update profile name and departments',
  UpdateProfileNameDepartmentsSuccess = '[Templates] update profile name and departments success',
  UpdateProfileNameDepartmentsError = '[Templates] update profile name and departments error',

  GetTemplateStadistics = '[Templates] Get Template Stadistics',
  GetTemplateStadisticsSuccess = '[Templates] Get Template Stadistics Success',
  GetTemplateStadisticsError = '[Templates] Get Template Stadistics Error',

  GetCollectorsByTemplate = '[Templates] Get Collectors By Template',
  GetCollectorsByTemplateSuccess = '[Templates] Get Collectors By Template Success',
  GetCollectorsByTemplateError = '[Templates] Get Collectors By Template Error',

  GetSignersByTemplate = '[Templates] Get Signers By Template',
  GetSignersByTemplateSuccess = '[Templates] Get Signers By Template Success',
  GetSignersByTemplateError = '[Templates] Get Signers By Template Error',

  ChangeSort = '[Templates] Change Sort Column',

  DownloadTemplateStadistics = '[Templates] Download Template Stadistics',

  ApplyFilters = '[Templates] Apply Filters',

  CleanTemplateStadistics = '[Templates] Clean Template Stadistics',

  hasPromissoryTemplates = '[Templates] check if has promissory note templates',
  hasPromissoryTemplatesSuccess = '[Templates] check if has promissory note templates success',

  //V3
  Get = '[Templates] Get',
  GetSuccess = '[Templates] GetSuccess',
  GetError = '[Templates] GetError',
  ChangeStatus = '[Templates] ChangeStatus',
  ChangeTemplateKind = '[Templates] Change Template Kind',
  /* == START: Acciones para la creacion de template v3 == */
  CreateTemplateV3 = '[Templates] Create Template V3',
  CreateTemplateV3Success = '[Templates] Create Template Success V3',
  CreateTemplateV3Error = '[Templates] Create Template Error V3',
  /* == END: Acciones para la creacion de template V3 == */
  /* == START: Acciones para la obtener el template por id V3 == */
  GetTemplateById = '[Templates] Get Template for by Id V3',
  GetTemplateByIdSuccess = '[Templates] Get Template for by Id Success V3',
  GetTemplateByIdError = '[Templates] Get Template for by Id Errors V3',
  /* == END: Acciones para la obtener el template por id V3 == */
  /* == START: Acciones para la para agregar metadata al template creado == */
  CreateelationTemplateMetaData = '[Templates] CreateelationTemplateMetaData V3',
  CreateelationTemplateMetaDataSuccess = '[Templates] CreateelationTemplateMetaData Success V3',
  CreateelationTemplateMetaDataError = '[Templates] CreateelationTemplateMetaData Errors V3',

  ResetTemplatesData = '[Templates] Reset Templates Data'
  /* == END: Acciones para la obtener el template por id V3 == */

}

export const clearTemplateFields = createAction(
  ETemplatesActions.ClearTemplateFields
);

/**
 * Action para la creacion de un template
 */
export const createTemplate = createAction(
  ETemplatesActions.CreateTemplate,
  props<{ payload: ITemplate }>()
);

/**
 * Action ejecutada al recivir un mensaje satisfactorio desde el servicio
 */
export const CreateTemplateSuccess = createAction(
  ETemplatesActions.CreateTemplateSuccess,
  props<{ payload: ITemplateResponse }>()
);

/**
 * Action para mostrar los errores generados al crear un template
 */
export const CreateTemplateError = createAction(
  ETemplatesActions.CreateTemplateError,
  props<{ payload: IAPIResponseError }>()
);

/**
 * Action para la edicion de un template
 */
export const EditTemplate = createAction(
  ETemplatesActions.EditTemplate,
  props<{ payload: ITemplates }>()
);

export const CreateDocument = createAction(
  ETemplatesActions.CreateDocument,
  props<{ payload: ICreateDocument }>()
);

export const GetTemplate = createAction(
  ETemplatesActions.GetTemplate,
  props<{ templateId: number }>()
);

export const GetTemplateSuccess = createAction(
  ETemplatesActions.GetTemplateSuccess,
  props<{ payload: ITemplateResponse }>()
);

export const GetTemplateError = createAction(
  ETemplatesActions.GetTemplateError,
  props<{ payload: IAPIResponseError }>()
);

export const indexTemplate = createAction(
  ETemplatesActions.IndexTemplate,
  props<{ pagination?: IPagination }>()
);
export const resetPagination = createAction(ETemplatesActions.ResetPagination);

export const indexTemplateSuccess = createAction(
  ETemplatesActions.IndexTemplateSucess,
  props<{ payload: { templates: ITemplates[]; pagination: IPagination } }>()
);

export const errorTemplate = createAction(
  ETemplatesActions.ErrorTemplate,
  props<{ payload: IAPIResponseError }>()
);

export const getFlows = createAction(
  ETemplatesActions.GetFlows,
  props<{ payload: string }>()
);

export const getFlowsSuccess = createAction(
  ETemplatesActions.GetFlowsSuccess,
  props<{ payload: Process }>()
);

export const changeFlow = createAction(
  ETemplatesActions.ChangeFlow,
  props<{ templateFlow: string; pagination?: IPagination }>()
);

export const exportExcel = createAction(ETemplatesActions.ExportExcel);

export const search = createAction(
  ETemplatesActions.Search,
  props<{ payload: { param: string; term: string; order?: string } }>()
);

export const latest = createAction(ETemplatesActions.Latest);

export const latestSuccess = createAction(
  ETemplatesActions.LatestSuccess,
  props<{ payload: ITemplates[] }>()
);

export const sortColumn = createAction(
  ETemplatesActions.SortColumn,
  props<{ payload: string }>()
);

export const getTemplateFields = createAction(
  ETemplatesActions.GetTemplateFields,
  props<{ payload: { templateId: string; search: string } }>()
);

export const getTemplateFieldsSuccess = createAction(
  ETemplatesActions.GetTemplateFieldsSuccess,
  props<{ payload: ITemplatesFields[] }>()
);

export const searchTemplateFields = createAction(
  ETemplatesActions.SearchTemplateField,
  props<{ payload: { templateId: string; search: string } }>()
);

export const searchTemplateFieldsSuccess = createAction(
  ETemplatesActions.SearchTemplateFieldSuccess,
  props<{ payload: ITemplatesFields[] }>()
);

// v2 quitar
export const showTemplate = createAction(
  ETemplatesActions.ShowTemplate,
  props<{ payload: string }>()
);

// v2 quitar
export const showTemplateSuccess = createAction(
  ETemplatesActions.ShowTemplateSuccess,
  props<{ payload: ITemplates }>()
);

export const relationshipTemplateFields = createAction(
  ETemplatesActions.RelationshipTemplateFields,
  props<{ payload: { templateId: string; field: any } }>()
);

export const relationshipTemplateFieldsSuccess = createAction(
  ETemplatesActions.RelationshipTemplateFieldsSuccess,
  props<{ payload: any }>()
);

export const changeStatusTemplate = createAction(
  ETemplatesActions.ChangeStatusTemplate,
  props<{ payload: { templateId: string; status: string } }>()
);

export const changeStatusTemplateSuccess = createAction(
  ETemplatesActions.ChangeStatusTemplateSuccess,
  props<{ payload: ITemplates }>()
);

export const changeStatusTemplateError = createAction(
  ETemplatesActions.ChangeStatusTemplateError,
  props<{ payload: IAPIResponseError }>()
);

export const indexTemplateFields = createAction(
  ETemplatesActions.IndexTemplateFields
);

export const IndexTemplateFieldsSuccess = createAction(
  ETemplatesActions.IndexTemplateFieldsSuccess,
  props<{ payload: FieldsOnlyOffice[] }>()
);

export const updateTemplateField = createAction(
  ETemplatesActions.UpdateTemplateField,
  props<{ payload: { templateId: string; templateField: any } }>()
);

export const updateTemplateFieldSuccess = createAction(
  ETemplatesActions.UpdateTemplateFieldSuccess,
  props<{ payload: FieldsOnlyOffice }>()
);

export const DeleteTemplate = createAction(
  ETemplatesActions.DeleteTemplate,
  props<{ payload: number }>()
);

export const DeleteTemplateSuccess = createAction(
  ETemplatesActions.DeleteTemplateSuccess
);

export const DeleteTemplateError = createAction(
  ETemplatesActions.DeleteTemplateError
);
export const updateTemplateNameDepartments = createAction(
  ETemplatesActions.UpdateTemplateNameDepartments,
  props<{ payload: Array<IUpdateData<ETemplateUpdatePaths>> }>()
);

export const updateTemplateNameDepartmentsSuccess = createAction(
  ETemplatesActions.UpdateTemplateNameDepartmentsSuccess,
  props<{ payload: ITemplateById }>()
);

export const updateTemplateNameDepartmentsError = createAction(
  ETemplatesActions.UpdateTemplateNameDepartmentsError
);

export const changeDepartment = createAction(
  ETemplatesActions.ChangeDepartment,
  props<{ payload: number }>()
);

//! DEPRECATE: este action se usa con el V2, es reemplazado por el ChangeTemplateKind
export const changeKind = createAction(
  ETemplatesActions.ChangeKind,
  props<{ payload: string }>()
);

export const GetKinds = createAction(ETemplatesActions.GetKinds);

export const GetKindsSuccess = createAction(
  ETemplatesActions.GetKindsSuccess,
  props<{ payload: ITemplateKinds }>()
);

export const GetKindsError = createAction(
  ETemplatesActions.GetKindsError,
  props<{ payload: IAPIResponseError }>()
);

export const DeleteTemplateField = createAction(
  ETemplatesActions.DeleteTemplateField,
  props<{ payload: { templateId: string; templateField: any } }>()
);

export const DeleteTemplateFieldSuccess = createAction(
  ETemplatesActions.DeleteTemplateFieldSuccess
);

export const DeleteTemplateFieldError = createAction(
  ETemplatesActions.DeleteTemplateFieldError,
  props<{ payload: IAPIResponseError }>()
);

export const FindTemplateFields = createAction(
  ETemplatesActions.FindTemplateFields,
  props<{ payload: { templateId: string; page: number; search: string } }>()
);

export const FindTemplateFieldsSuccess = createAction(
  ETemplatesActions.FindTemplateFieldsSuccess,
  props<{ payload: FieldsOnlyOffice[] }>()
);

export const FindTemplateFieldsError = createAction(
  ETemplatesActions.FindTemplateFieldsError
);

export const MetadataTemplateProfile = createAction(
  ETemplatesActions.MetadataTemplateProfile,
  props<{ payload: { profileId: number; search?: string } }>()
);

export const MetadataTemplateProfileSuccess = createAction(
  ETemplatesActions.MetadataTemplateProfileSuccess,
  props<{ payload: ITemplateProfile[] }>()
);

export const MetadataTemplateProfileError = createAction(
  ETemplatesActions.MetadataTemplateProfileError,
  props<{ payload: IAPIResponseError }>()
);

export const CreateProfile = createAction(
  ETemplatesActions.CreateProfile,
  props<{ payload: IProfile }>()
);

export const CreateProfileSuccess = createAction(
  ETemplatesActions.CreateProfileSuccess,
  props<{ payload: IProfile }>()
);

export const CreateProfileError = createAction(
  ETemplatesActions.CreateProfileError
);

export const GetProfiles = createAction(ETemplatesActions.GetProfiles);

export const GetProfilesSuccess = createAction(
  ETemplatesActions.GetProfilesSuccess,
  props<{ payload: IProfile[] }>()
);

export const GetProfilesError = createAction(
  ETemplatesActions.GetProfilesError,
  props<{ payload: IAPIResponseError }>()
);

export const DeleteMetadataProfile = createAction(
  ETemplatesActions.DeleteMetadataProfile,
  props<{ payload: { profileId: number; metadataId: number } }>()
);

export const DeleteMetadataProfileSuccess = createAction(
  ETemplatesActions.DeleteMetadataProfileSuccess
);

export const DeleteMetadataProfileError = createAction(
  ETemplatesActions.DeleteMetadataProfileError
);

export const updateProfileNameDepartments = createAction(
  ETemplatesActions.UpdateProfileNameDepartments,
  props<{ payload: IProfile }>()
);

export const updateProfileNameDepartmentsSuccess = createAction(
  ETemplatesActions.UpdateProfileNameDepartmentsSuccess,
  props<{ payload: string }>()
);

export const updateProfileNameDepartmentsError = createAction(
  ETemplatesActions.UpdateProfileNameDepartmentsError
);

export const GetTemplateStadistics = createAction(
  ETemplatesActions.GetTemplateStadistics,
  props<{ templateId: number }>()
);

export const GetTemplateStadisticsSuccess = createAction(
  ETemplatesActions.GetTemplateStadisticsSuccess,
  props<{ payload: TemplateFieldData }>()
);

export const GetTemplateStadisticsError = createAction(
  ETemplatesActions.GetTemplateStadisticsError,
  props<{ payload: IAPIResponseError }>()
);

export const ClearMetadataTemplateProfile = createAction(
  ETemplatesActions.ClearMetadataTemplateProfile
);

export const GetCollectorsByTemplate = createAction(
  ETemplatesActions.GetCollectorsByTemplate,
  props<{ templateId: number }>()
);

export const GetCollectorsByTemplateSuccess = createAction(
  ETemplatesActions.GetCollectorsByTemplateSuccess,
  props<{ payload: TemplateCollector[] }>()
);

export const GetCollectorsByTemplateError = createAction(
  ETemplatesActions.GetCollectorsByTemplateError,
  props<{ payload: IAPIResponseError }>()
);

export const GetSignersByTemplate = createAction(
  ETemplatesActions.GetSignersByTemplate,
  props<{ templateId: number }>()
);

export const GetSignersByTemplateSuccess = createAction(
  ETemplatesActions.GetSignersByTemplateSuccess,
  props<{ payload: TemplateSigner[] }>()
);

export const GetSignersByTemplateError = createAction(
  ETemplatesActions.GetSignersByTemplateError,
  props<{ payload: IAPIResponseError }>()
);

export const ChangeCurrentSort = createAction(
  ETemplatesActions.ChangeSort,
  props<{ templateId: number; columnSort: ColumnSort[] }>()
);

export const DownloadTemplateStadistics = createAction(
  ETemplatesActions.DownloadTemplateStadistics,
  props<{ templateId: number }>()
);

export const ApplyFilter = createAction(
  ETemplatesActions.ApplyFilters,
  props<{ templateId: number; filterParams: FilterParams }>()
);

export const CleanTemplateStadistics = createAction(
  ETemplatesActions.CleanTemplateStadistics
);

//V3
export const Get = createAction(
  ETemplatesActions.Get,
  props<{ payload?: IQueryParameters }>()
);

export const GetSuccess = createAction(
  ETemplatesActions.GetSuccess,
  props<{ payload: { templatesV3: ITemplate[]; paginationV3: IPaginationV3 } }>()
);

export const GetError = createAction(
  ETemplatesActions.GetError,
  props<{ payload: IAPIResponseError }>()
);

export const ChangeStatus = createAction(
  ETemplatesActions.ChangeStatus,
  props<{ status?: string }>()
);

//* Este action reemplazaria al changeKind
export const ChangeTemplateKind = createAction(
  ETemplatesActions.ChangeTemplateKind,
  props<{ kind?: ETemplateProcessKind; forceDepartments?: boolean }>()
);

/* action para la creacio de plantillas    */
export const CreateTemplateV3 = createAction(
  ETemplatesActions.CreateTemplateV3,
  props<{payload: ITemplatePayload}>()
);
export const CreateTemplateV3Success = createAction(
  ETemplatesActions.CreateTemplateV3Success,
  props<{payload: ITemplateById}>()
);
export const CreateTemplateV3Error = createAction(
  ETemplatesActions.CreateTemplateV3Error,
  props<{payload: IAPIResponseError}>()
);

/*Accion Consulta de template por medio de un Id*/
export const GetTemplateById = createAction(
  ETemplatesActions.GetTemplateById,
  props<{ payload: number }>()
);
export const GetTemplateByIdSuccess = createAction(
  ETemplatesActions.GetTemplateByIdSuccess,
  props<{payload: ITemplateById}>()
);
export const GetTemplateByIdError = createAction(
  ETemplatesActions.GetTemplateByIdError,
  props<{payload: IAPIResponseError}>()
);

export const CreateelationTemplateMetaData = createAction(
  ETemplatesActions.CreateelationTemplateMetaData,
  props<{payload: {templateId: number, payloadData:ITemplateRelationMetaDataPayload}}>()
);
export const CreateelationTemplateMetaDataSuccess = createAction(
  ETemplatesActions.CreateelationTemplateMetaDataSuccess,
  props<{payload: ITemplateById}>()
);
export const CreateelationTemplateMetaDataError = createAction(
  ETemplatesActions.CreateelationTemplateMetaDataError,
  props<{payload: IAPIResponseError}>()
);

export const ResetTemplatesData = createAction(
  ETemplatesActions.ResetTemplatesData
);

//end V3
