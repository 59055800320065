import * as _ from 'lodash';

import {
  EAttachmentReadStatus,
  EKindAttachment,
} from '@literax/enums/attachment.enum';
import {
  ESigningMethod,
  ETransactionType
} from '@literax/enums/document.enum';

import { ApplicationReducer } from '@literax/interfaces/app.interfaces';
import { EParticipantKind } from '@literax/enums/participant.enum';
import { IViewingAttachment } from './../../../../../models/attachment.model';
import { WorkspaceState } from '../workspace.state';
import { formatAttachment } from '@literax/utils/attachment.utils';
import jwt_decode from 'jwt-decode';

export const DocumentReducers: ApplicationReducer<WorkspaceState> = {
  getDocumentSuccess: (state, action) => {
    let guestEmail = null;
    if (action.payload.token) {
      guestEmail = jwt_decode(action.payload.token)['email'];
    }
    const mainAttachment: IViewingAttachment = formatAttachment(
      {
        ...action.payload.document.attachments.find(
          (attachment) => attachment.primary === true
        ),
        readStatus: EAttachmentReadStatus.PENDING,
      },
      state?.workingModeType,
      action.payload.document
    );

    const attachments = [...action.payload.document.attachments].map(
      (attachment) =>
        attachment.id
          ? formatAttachment(
              {
                ...attachment,
                readStatus: EAttachmentReadStatus.NONE,
              },
              state?.workingModeType,
              action.payload.document
            )
          : formatAttachment(
              attachment,
              state.workingModeType,
              action.payload.document
            )
    );
    // detectar y asignar si el documento pertenece a algun flujo con pagare
    const isPromissoryNoteFlow = [
      EKindAttachment.ASSIGNMENT_OF_RIGHTS,
      EKindAttachment.ENDORSEMENT,
      EKindAttachment.ENDORSEMENT_REVOCATION,
      EKindAttachment.PROMISSORY_NOTE,
      EKindAttachment.PROMISSORY_NOTE_EXTINCTION,
      EKindAttachment.PROMISSORY_NOTE_PRESENTATION_FOR_PAYMENT,
      EKindAttachment.RECEIPT,
    ].includes(mainAttachment?.kind);

    const legalPersons =
      action.payload.document.signRequests?.legalPersons?.reduce(
        (previous, current, index) => {
          const legalRepresentative = _.omit(
            {
              ...current,
              kind: EParticipantKind.NATURAL_PERSON,
            },
            ['companyName', 'companyTaxId', 'orderingTurn', 'idDocumentRequest', 'signRequestValidationTypeId']
          );
          const legalPerson = {
            signRequestId: new Date().getTime() + index,
            companyName: current.companyName,
            companyTaxId: current.companyTaxId,
            expiryAt: current.expiryAt,
            orderingTurn: current.orderingTurn,
            idDocumentRequest: current.idDocumentRequest,
            signRequestValidationTypeId: current.signRequestValidationTypeId,
            kind: EParticipantKind.LEGAL_PERSON,
            legalRepresentatives: [legalRepresentative],
          };

          const exists = previous?.find((participant) =>
            state?.document?.signingConfiguration?.signingMethod !==
            ESigningMethod.DIGITAL_SIGNATURE
              ? participant.companyName === current.companyName
              : participant.companyName === current.companyName &&
                participant.companyTaxId === current.companyTaxId
          );

          exists !== undefined
            ? exists.legalRepresentatives.push(legalRepresentative)
            : previous.push(legalPerson);

          return previous;
        },
        []
      ) || [];
    const naturalPersons =
      action.payload.document.signRequests?.naturalPersons.map(
        (naturalPerson) => ({
          ...naturalPerson,
          kind: EParticipantKind.NATURAL_PERSON,
        })
      ) || [];
    const observers =
      action.payload.document.signRequests?.observers.map((observer) => ({
        ...observer,
        kind: EParticipantKind.OBSERVER,
      })) || [];

    const ordering = (): boolean => {
      const { document } = action.payload;
      switch (action.payload.document.transactionType) {
        case ETransactionType.APPROVAL:
          return document.approvalConfiguration.ordering;
        case ETransactionType.SIGNATURE:
          return document.signingConfiguration.ordering;
        default:
          return false;
      }
    };

    return {
      ...state,
      document: {
        ...action.payload.document,
        attachments,
      },
      mainAttachment,
      attachment: mainAttachment,
      isPromissoryNoteFlow,
      configuration: {
        ...state.configuration,
        ordering: ordering(),
        signingMethod:
          action.payload?.document?.signingConfiguration?.signingMethod,
        flowName: action.payload.document?.flowName,
        legalPersons,
        naturalPersons,
        participants: [...legalPersons, ...naturalPersons],
        observers,
        participantsCount:
          [legalPersons, naturalPersons].reduce(
            (accumulator, current) => current?.length + accumulator,
            0
          ) ?? 0,
        observersCount: observers.length,
        signatureVerificationTypeId:
          action.payload?.document?.signingConfiguration
            ?.signatureVerificationTypeId,
        participantSignatureQuotes: [],
      },
      guestEmail,
    };
  },

  moveParticipantCanvasSuccess: (state, action) => {
    const legalPersons =
      action.payload.document.signRequests?.legalPersons?.reduce(
        (previous, current, index) => {
          const legalRepresentative = _.omit(
            {
              ...current,
              kind: EParticipantKind.NATURAL_PERSON,
            },
            ['companyName', 'companyTaxId', 'orderingTurn', 'idDocumentRequest']
          );
          const legalPerson = {
            signRequestId: new Date().getTime() + index,
            companyName: current.companyName,
            companyTaxId: current.companyTaxId,
            expiryAt: current.expiryAt,
            orderingTurn: current.orderingTurn,
            idDocumentRequest: current.idDocumentRequest,
            kind: EParticipantKind.LEGAL_PERSON,
            legalRepresentatives: [legalRepresentative],
          };

          const exists = previous?.find((participant) =>
            state?.document?.signingConfiguration?.signingMethod !==
            ESigningMethod.DIGITAL_SIGNATURE
              ? participant.companyName === current.companyName
              : participant.companyName === current.companyName &&
                participant.companyTaxId === current.companyTaxId
          );

          exists !== undefined
            ? exists.legalRepresentatives.push(legalRepresentative)
            : previous.push(legalPerson);

          return previous;
        },
        []
      ) || [];
    const naturalPersons =
      action.payload.document.signRequests?.naturalPersons.map(
        (naturalPerson) => ({
          ...naturalPerson,
          kind: EParticipantKind.NATURAL_PERSON,
        })
      ) || [];
    const observers =
      action.payload.document.signRequests?.observers.map((observer) => ({
        ...observer,
        kind: EParticipantKind.OBSERVER,
      })) || [];

    return {
      ...state,
      configuration: {
        ...state.configuration,
        legalPersons,
        naturalPersons,
        participants: [...legalPersons, ...naturalPersons],
        observers,
        participantsCount:
          [legalPersons, naturalPersons].reduce(
            (accumulator, current) => current?.length + accumulator,
            0
          ) ?? 0,
        observersCount: observers.length,
      },
    };
  },

  updateDocumentSuccess: (state, action) => {
    const legalPersons =
      action.payload.document.signRequests?.legalPersons?.reduce(
        (previous, current, index) => {
          const legalRepresentative = _.omit(
            {
              ...current,
              kind: EParticipantKind.NATURAL_PERSON,
            },
            ['companyName', 'companyTaxId', 'orderingTurn', 'idDocumentRequest']
          );
          const legalPerson = {
            signRequestId: new Date().getTime() + index,
            companyName: current.companyName,
            companyTaxId: current.companyTaxId,
            expiryAt: current.expiryAt,
            orderingTurn: current.orderingTurn,
            idDocumentRequest: current.idDocumentRequest,
            kind: EParticipantKind.LEGAL_PERSON,
            legalRepresentatives: [legalRepresentative],
          };

          const exists = previous?.find((participant) =>
            state?.document?.signingConfiguration?.signingMethod !==
            ESigningMethod.DIGITAL_SIGNATURE
              ? participant.companyName === current.companyName
              : participant.companyName === current.companyName &&
                participant.companyTaxId === current.companyTaxId
          );

          exists !== undefined
            ? exists.legalRepresentatives.push(legalRepresentative)
            : previous.push(legalPerson);

          return previous;
        },
        []
      ) || [];
    const naturalPersons =
      action.payload.document.signRequests?.naturalPersons.map(
        (naturalPerson) => ({
          ...naturalPerson,
          kind: EParticipantKind.NATURAL_PERSON,
        })
      ) || [];

    return {
      ...state,
      document: {
        ...state.document,
        ...action.payload?.document,
      },
      configuration: {
        ...state.configuration,
        legalPersons,
        naturalPersons,
        participants: [...legalPersons, ...naturalPersons],
        participantsCount:
          [legalPersons, naturalPersons].reduce(
            (accumulator, current) => current?.length + accumulator,
            0
          ) ?? 0,
        signingMethod:
          action.payload?.document?.signingConfiguration?.signingMethod,
        signatureVerificationTypeId:
          action.payload?.document?.signingConfiguration
            ?.signatureVerificationTypeId,
      },
    };
  },

  getUserInfoSuccess: (state, action) => {
    return {
      ...state,
      lookFeel: action.payload?.info?.lookFeelConfig,
    };
  },
  getAccessTokensSuccess: (state, action) => {
    return {
      ...state,
      tokens: action.payload?.tokens,
    };
  },
  getDocumentError: (state, action) => {
    return {
      ...state,
      errorMessage: action.payload.error.message,
    };
  },
  getVerificationTypesSuccess: (state, action) => ({
    ...state,
    signatureVerificationTypes: action.payload,
  }),
};
