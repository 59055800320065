import {
  cacheDocxAttachmentState,
  checkIfDocxAttachmentHasPdf,
} from '@literax/modules/documents/workspace/components/buttons-bar-layout/workspace-button-bar';

import { ApplicationReducer } from '@literax/interfaces/app.interfaces';
import { EViewerType } from '@literax/enums/attachment.enum';
import { IViewingAttachment } from '@literax/models/attachment.model';
import { WorkspaceState } from '../workspace.state';
import { formatAttachment } from '@literax/utils/attachment.utils';

export const AttachmentReducers: ApplicationReducer<WorkspaceState> = {
  viewAttachment: (state, action) => ({
    ...state,
    attachment: {
      ...state.attachment,
      base64: null,
    },
  }),
  viewAttachmentSuccess: (state, action) => {
    let mainAttachment = { ...state.mainAttachment };
    if (mainAttachment.id === action.payload.attachment.attachmentId) {
      mainAttachment = formatAttachment(
        {
          ...mainAttachment,
          ...action.payload.attachment,
        },
        state?.workingModeType,
        state?.document
      );
    }

    const attachment: IViewingAttachment = formatAttachment(
      {
        ...state.document?.attachments.find(
          (attachment) =>
            attachment.id === action.payload.attachment.attachmentId
        ),
        ...action.payload.attachment,
      },
      state.workingModeType,
      state?.document
    );

    const attachments = [...state.document?.attachments].map((attachment) => {
      return attachment.id === action.payload.attachment.attachmentId
        ? formatAttachment(
          {
            ...attachment,
            ...action.payload.attachment,
          },
          state.workingModeType,
          state?.document
        )
        : attachment;
    });

    return {
      ...state,
      mainAttachment,
      attachment,
      document: {
        ...state.document,
        attachments,
      },
      activeTab: action.payload.showTab ?? state.activeTab,
    };
  },
  setBase64: (state, action) => {
    const mainAttachment =
      action.payload.id === state.mainAttachment.id
        ? { ...state.mainAttachment, base64: action.payload.base64 }
        : state.mainAttachment;
    const attachment =
      action.payload.id === state.attachment.id
        ? { ...state.attachment, base64: action.payload.base64 }
        : state.attachment;
    const attachments = [...state.document.attachments].map((attachment) =>
      attachment.id === action.payload.id
        ? { ...attachment, base64: action.payload.base64 }
        : attachment
    );

    return {
      ...state,
      mainAttachment,
      attachment,
      document: {
        ...state.document,
        attachments,
      },
    };
  },
  replaceAttachmentSuccess: (state, action) => {
    const mainAttachment =
      action.payload.attachment.id === state.mainAttachment.id
        ? { ...state.mainAttachment, ...action.payload.attachment }
        : state.mainAttachment;
    const attachment =
      action.payload.attachment.id === state.attachment.id
        ? { ...state.attachment, ...action.payload.attachment }
        : state.attachment;
    const attachments = [...state.document.attachments].map((attachment) =>
      attachment.id === action.payload.attachment.id
        ? { ...attachment, ...action.payload.attachment }
        : attachment
    );
    return {
      ...state,
      mainAttachment,
      attachment,
      document: {
        ...state.document,
        attachments,
      },
    };
  },
  redactAttachmentSuccess: (state, action) => {
    const attachments = [...state.document.attachments];
    attachments.findIndex(
      (attachment) => attachment.id === action.payload.attachment.id
    ) !== -1
      ? attachments.map((attachment) =>
        attachment.id === action.payload.attachment.id
          ? { ...attachment, ...action.payload.attachment }
          : attachment
      )
      : attachments.push(action.payload.attachment);
    return {
      ...state,
      document: {
        ...state.document,
        attachments,
      },
    };
  },
  deleteAttachmentSuccess: (state, action) => {
    let attachment = state.attachment;
    let attachments = [...state.document.attachments];
    attachments = attachments.filter(
      (attachment) => attachment.id !== action.idAttachment
    );
    if (state.attachment.id === action.idAttachment) {
      attachment = attachments.find((attachment) => attachment.primary);
    }
    return {
      ...state,
      attachment,
      document: {
        ...state.document,
        attachments,
      },
    };
  },
  sendReadStatus: (state, action) => {
    const attachment = { ...state.attachment, readStatus: action.readStatus };

    const mainAttachment =
      state.attachment?.id === state.mainAttachment.id
        ? { ...state.mainAttachment, readStatus: action.readStatus }
        : state.mainAttachment;

    const attachments = [...state.document.attachments].map((attachment) =>
      attachment.id === state.attachment?.id
        ? { ...attachment, readStatus: action.readStatus }
        : attachment
    );

    return {
      ...state,
      mainAttachment,
      attachment,
      document: {
        ...state.document,
        attachments,
      },
    };
  },
  setViewerType: (state, action) => {
    const attachment = { ...state.attachment };
    return {
      ...state,
      attachment,
    };
  },
  transformDocxToPDF: (state, action) => {
    return {
      ...state,
      attachment: {
        ...state.attachment,
        viewerType: EViewerType.LOADING,
      },
    };
  },
  transformDocxToPDFSuccess: (state, action) => {
    cacheDocxAttachmentState(state?.attachment?.id, true);
    return {
      ...state,
      mainAttachment: {
        ...state.mainAttachment,
        hasPdfVersion: checkIfDocxAttachmentHasPdf(state?.mainAttachment?.id),
      },
      attachment: {
        ...state.attachment,
        viewerType: EViewerType.PDF,
        hasPdfVersion: checkIfDocxAttachmentHasPdf(state?.attachment?.id),
      },
    };
  },
};
