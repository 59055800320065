import { createAction, props } from "@ngrx/store";
import { IPromissory_Note_Report, IPromissory_Note_Report_Detail, IPromissoryNote_Report_Beneficiaries_Filter, IFilterPromissoryNoteDetail } from '@literax/models/report-promissory-note.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IPromissoryNote_Report_suscriptor_Filter } from '../../models/report-promissory-note.model';



export enum EReportPromissoryNoteActions{


    //TODO: GET ALL INFO REPORT SERVICES 
    GetInfoReport='[ReportPromissoryNote] GetInfoReport',
    GetInfoReportSuccess='[ReportPromissoryNote] GetInfoReportSuccess',
    GetInfoReportError='[ReportPromissoryNote] GetInfoReportError',
    GetInfoReportReset='[ReportPromissoryNote] GetInfoReportReset',
    //TODO: GET ALL INFO REPORT DETAILS
    GetReportDetail= '[ReportPromissoryNote] GetReportDetail',
    GetReportDetailSuccess= '[ReportPromissoryNote] GetReportDetailSuccess',
    GetReportDetailError= '[ReportPromissoryNote] GetReportDetailError',
    GetReportDetailReset='[ReportPromissoryNote] GetReportDetailReset',
    //TODO: FIlter info beneficiaries
    GetFilterBeneficiaries= '[ReportPromissoryNote] GetFilterBeneficiaries',
    GetFilterBeneficiariesSuccess= '[ReportPromissoryNote] GetFilterBeneficiariesSuccess',
    GetFilterBeneficiariesError= '[ReportPromissoryNote] GetFilterBeneficiariesError',
    GetFilterBeneficiariesReset='[ReportPromissoryNote] GetFilterBeneficiariesReset',
    //TODO: FIlter info suscriptors
    GetFiltersuscriptor= '[ReportPromissoryNote] GetFiltersuscriptor',
    GetFiltersuscriptorSuccess= '[ReportPromissoryNote] GetFiltersuscriptorSuccess',
    GetFiltersuscriptorError= '[ReportPromissoryNote] GetFiltersuscriptorError',
    GetFiltersuscriptorReset='[ReportPromissoryNote] GetFiltersuscriptorReset',
    //TODO: export and donwload excel
    DownloadReportDetailsPromissoryNote = '[Templates] Download Template Stadistics',
}


//TODO: GET ALL INFO REPORT SERVICES 
export const GetInfoReport = createAction(
    EReportPromissoryNoteActions.GetInfoReport,
    props<{ payload:{ filter: IFilterPromissoryNoteDetail, isFilter:boolean  } }>()
);

export const GetInfoReportSuccess = createAction(
    EReportPromissoryNoteActions.GetInfoReportSuccess,
    props<{ payload: IPromissory_Note_Report }>()
);

export const GetInfoReportError = createAction(
    EReportPromissoryNoteActions.GetInfoReportError,
    props< {payload: IAPIResponseError} >()
);

export const GetInfoReportReset = createAction(
    EReportPromissoryNoteActions.GetInfoReportReset
);

//TODO: GET ALL INFO REPORT DETAILS SERVICES 
export const GetReportDetail = createAction(
    EReportPromissoryNoteActions.GetReportDetail,
    props<{payload: 
        {
            filter:IFilterPromissoryNoteDetail,
            exportFile: string
        }
    }>()

);

export const GetReportDetailSuccess = createAction(
    EReportPromissoryNoteActions.GetReportDetailSuccess,
    props< {payload: IPromissory_Note_Report_Detail}>()
);

export const GetReportDetailError = createAction(
    EReportPromissoryNoteActions.GetReportDetailError,
    props< { payload: IAPIResponseError } >()
);

export const GetReportDetailReset = createAction(
    EReportPromissoryNoteActions.GetReportDetailReset
);

//TODO: Get info filter beneficiaries
export const GetFilterBeneficiaries = createAction(
    EReportPromissoryNoteActions.GetFilterBeneficiaries
);

export const GetFilterBeneficiariesSuccess = createAction(
    EReportPromissoryNoteActions.GetFilterBeneficiariesSuccess,
    props< {payload: IPromissoryNote_Report_Beneficiaries_Filter}>()
);

export const GetFilterBeneficiariesError = createAction(
    EReportPromissoryNoteActions.GetFilterBeneficiariesError,
    props< {payload: IAPIResponseError }>()
);

export const GetFilterBeneficiariesReset = createAction(
    EReportPromissoryNoteActions.GetFilterBeneficiariesReset
);

//TODO: Get info filter suscriptors
export const GetFiltersuscriptor = createAction(
    EReportPromissoryNoteActions.GetFiltersuscriptor
);

export const GetFiltersuscriptorSuccess = createAction(
    EReportPromissoryNoteActions.GetFiltersuscriptorSuccess,
    props< {payload: IPromissoryNote_Report_suscriptor_Filter}>()
);

export const GetFiltersuscriptorError = createAction(
    EReportPromissoryNoteActions.GetFiltersuscriptorError,
    props< {payload: IAPIResponseError }>()
);

export const GetFiltersuscriptorReset = createAction(
    EReportPromissoryNoteActions.GetFiltersuscriptorReset
);

//TODO: download and export file excel
export const DownloadReportDetailsPromissoryNote = createAction(
    EReportPromissoryNoteActions.DownloadReportDetailsPromissoryNote,
    props<{payload: 
        {
            filter:IFilterPromissoryNoteDetail,
            exportFile: string
        }
    }>()
)