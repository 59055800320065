import {
  initialSignRequestState,
  ISignRequestState
} from './sign-request.state';
import {
  IAPIResponseError,
  IAPIResponseErrors
} from '@literax/models/http/api/error.model';
import { IAPISignRequest } from '@literax/models/http/api/sign-request/sign-request.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as signRequestAction from '@literax/modules/documents/store/sign-request/sign-request.actions';

function updateErrors({
  errors,
  uuid,
  response
}: {
  errors: IAPIResponseErrors;
  uuid: string;
  response: IAPIResponseError;
}): IAPIResponseErrors {
  const newErrors = { ...errors };
  newErrors[uuid] = response;
  return newErrors;
}

function updateSignRequest({
  requests,
  request
}: {
  requests: IAPISignRequest[];
  request: IAPISignRequest;
}): IAPISignRequest[] {
  const requestList = requests.map((requestItem) =>
    requestItem.id === request.id ? request : requestItem
  );
  return requestList;
}

function removeSignRequest({
  requests,
  request
}: {
  requests: IAPISignRequest[];
  request: IAPISignRequest;
}): IAPISignRequest[] {
  const requestList = requests.filter(
    (requestItem) => requestItem.id !== request?.id
  );
  return requestList;
}

function addMeToSigners({
  requests,
  request,
  addMe
}: {
  requests: IAPISignRequest[];
  request: IAPISignRequest;
  addMe: boolean;
}): IAPISignRequest[] {
  if (addMe) {
    return updateSignRequest({ requests, request });
  } else {
    return removeSignRequest({ requests, request });
  }
}

export const initialState = initialSignRequestState;
const signRequestsReducers = createReducer(
  initialState,
  on(signRequestAction.CleanSignRequestFormStates, (state, action) => {
    const successSignRequestNotificationSuccess = null;
    const signRequestErrors = {};
    const addMeStatus = { payload: null, success: false };
    const signRequests = [];
    return {
      ...state,
      signRequests,
      signRequestErrors,
      addMeStatus,
      successSignRequestNotificationSuccess
    };
  }),
  on(signRequestAction.InitializeSignRequestFormStates, (state, action) => {
    return {
      ...state,
      signRequests: action.payload as IAPISignRequest[],
      signRequestErrors: {}
    };
  }),
  on(signRequestAction.DeleteSignRequestSuccess, (state, action) => {
    const signRequests = removeSignRequest({
      requests: state.signRequests,
      request: action.payload.payload.sign_request
    });
    return {
      ...state,
      signRequests,
      successSignRequestNotificationSuccess: null
    };
  }),
  on(signRequestAction.SendSignRequestNotificationSuccess, (state, action) => ({
    ...state,
    successSignRequestNotificationSuccess: action.payload.success
  })),
  on(signRequestAction.AddMeToSignersSuccess, (state, action) => {
    const addMeStatus = { payload: action.payload.request, success: true };
    const signRequests = addMeToSigners({
      requests: state.signRequests,
      request: action.payload.response.sign_request,
      addMe: action.payload.request.addMe
    });
    return { ...state, signRequests, addMeStatus };
  }),
  on(signRequestAction.AddMeToSignersError, (state, action) => {
    return { ...state, error: action.payload };
  }),
  on(signRequestAction.GetContactsResponse, (state, action) => ({
    ...state,
    contacts: action.payload.contacts
  })),
  on(signRequestAction.createNaturalPersonError, (state, action) => {
    return { ...state, createNaturalPersonErrors: action.payload.error };
  }),
  on(signRequestAction.UpdateNaturalPersonError, (state, action) => {
    return { ...state, updateNaturalPersonErrors: action.payload.error };
  }),
  on(signRequestAction.CreateLegalRepresentativesError, (state, action) => {
    return { ...state, createLegalRepresentativeErrors: action.payload.error };
  }),
  on(signRequestAction.UpdateLegalRepresentativesError, (state, action) => {
    return { ...state, updateLegalRepresentativeErrors: action.payload.error };
  }),
  on(signRequestAction.GetHistoryEmailResponse, (state, action) => {
    return { ...state, signRequest: action.payload };
  }),
  on(signRequestAction.GetCollaboratorsSuccess, (state, action) => {
    return { ...state, collaborators: action.payload };
  })
);

export function signRequestReducers(state: ISignRequestState, action: Action) {
  return signRequestsReducers(state, action);
}
