import { IAttachment } from '@literax/models/http/attachment.model';
import { IAPIResponseError, IAPIError } from '@literax/models/http/api/error.model';

export interface IAttachmentState {
  attachments: IAttachment[];
  selectedAttachment: IAttachment;
  uploadErrors: IAPIResponseError;
  errorResponse: IAPIResponseError;
  deleteAttachmentError: IAPIError;
  getAttachmentError: IAPIResponseError;
  lastAttachment: IAttachment;
}

export const initialAttachmentState: IAttachmentState = {
  attachments: [],
  uploadErrors: null,
  errorResponse: null,
  deleteAttachmentError: null,
  selectedAttachment: null,
  getAttachmentError: null,
  lastAttachment: null
};
