import { createAction, props } from '@ngrx/store';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { ITermsResponse } from '@literax/models/terms.model';

export const GetTerms = createAction(
  '[Terms] GetTerms'
);

export const GetTermsSuccess = createAction(
  '[Terms] GetTermsSuccess',
  props<{ payload: ITermsResponse }>()
);

export const GetTermsError = createAction(
  '[Terms] GetTermsError',
  props<{ payload: IAPIResponseError }>()
);

export const AcceptTerms = createAction(
  '[Terms] AcceptTerms'
);

export const AcceptTermsSuccess = createAction(
  '[Terms] AcceptTermsSuccess'
);

export const AcceptTermsError = createAction(
  '[Terms] AcceptTermsError',
  props<{ payload: IAPIResponseError }>()
);