<literax-auth-navbar
  [title]="'AUTH.TITLE.NAVBAR' | translate"
></literax-auth-navbar>
<div class="main-container">
  <section id="stepper">
    <nz-steps [nzCurrent]="currentStep">
      <nz-step></nz-step>
      <nz-step></nz-step>
      <nz-step *ngIf="!isTrial"></nz-step>
      <nz-step></nz-step>
    </nz-steps>

    <div class="steps-content">
      <div id="step0" *ngIf="currentStep === 0">
        <h3 class="step-title">{{ 'AUTH.STEP' | translate }}</h3>
        <h6 class="step-subtitle">{{ 'AUTH.NOTICE' | translate }}</h6>
        <div class="legal-buttons">
          <button
            nz-button nzType="primary"
            class="personal-btn"
            type="button"
            (click)="togglePersonType(false)"
            [ngStyle]="{
              'background-color': !isLegalPerson
                ? selectedCardColor
                : 'transparent',
              border: !isLegalPerson ? '0' : 'solid 1px #9E9E9E',
              color: !isLegalPerson ? 'white' : '#757575'
            }"
          >
            <i class="fal fa-user-alt"></i>
            {{ 'AUTH.FIELDS.ACCOUNT_PERSONAL' | translate }}
          </button>
          <button
            nz-button nzType="default"
            class="enterprise-btn"
            type="button"
            (click)="togglePersonType(true)"
            [ngStyle]="{
              'background-color': isLegalPerson
                ? selectedCardColor
                : 'transparent',
              border: isLegalPerson ? '0' : 'solid 1px #9E9E9E',
              color: isLegalPerson ? 'white' : '#757575'
            }"
          >
            <i class="fal fa-building"></i>
            {{ 'AUTH.FIELDS.ACCOUNT_ENTERPRISE' | translate }}
          </button>
        </div>
        <form
          [formGroup]="form"
          class="mat-elevation-z6 card"
        >
          <div>
            <div class="row">
              <div
                formGroupName="user"
                class="col-md-4"
                *ngIf="!isLegalPerson"
              >
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.NAME' | translate"
                    formControlName="name"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div
                formGroupName="user"
                class="col-md-4"
                *ngIf="!isLegalPerson"
              >
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.LAST_NAME' | translate"
                    formControlName="last_name"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div
                formGroupName="user"
                class="col-md-4 col-md-3-mobile"
                *ngIf="!isLegalPerson"
              >
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.RFC' | translate"
                    [upperCase]="true"
                    formControlName="rfc"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="isLegalPerson">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.BUSINESS_NAME' | translate"
                    formControlName="business_name"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="isLegalPerson">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.TRADE_NAME' | translate"
                    formControlName="tradename"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="isLegalPerson">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.RFC' | translate"
                    [upperCase]="true"
                    formControlName="tax_id"
                  >
                  </literax-form-field>
                </div>
              </div>
            </div>

            <div formGroupName="user" class="row" *ngIf="!isLoggedIn">
              <div class="col-md-4">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.EMAIL' | translate"
                    [pattern]="emailPattern"
                    formControlName="email"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="!isLoggedIn">
                <div class="gdx-form-group">
                  <literax-form-field 
                    type="password"
                    [label]="'AUTH.FIELDS.PASSWORD' | translate"
                    formControlName="password"
                  >
                  </literax-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-md-3-mobile-country">
              <div class="gdx-form-group">
                <label>
                  {{ 'AUTH.FIELDS.COUNTRY' | translate }}
                </label>
                <div>
                  <nz-select
                    formControlName="country"
                    nzShowSearch
                  >
                    <nz-option 
                      *ngFor="let option of countries" 
                      [nzValue]="option" 
                      [nzLabel]="option.label"
                    >
                    </nz-option>
                  </nz-select>
                  <literax-api-error
                    [control]="form.get('country')"
                  ></literax-api-error>
                </div>
                

              </div>
            </div>

            <div class="col-md-3 col-md-2-mobile">
              <div class="gdx-form-group">
                <literax-form-field
                  [label]="'AUTH.FIELDS.POSTAL_CODE' | translate"
                  [maxlength]="5"
                  formControlName="postal_code"
                >
                </literax-form-field>
              </div>
            </div>

            <div class="col-md-4">
              <div class="gdx-form-group">
                <literax-form-field
                  [label]="'AUTH.FIELDS.PHONE' | translate"
                  formControlName="phone"
                >
                </literax-form-field>
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              nz-button nzType="default"
              class="back-btn"
              (click)="onCancelClicked()"
              tabindex="5"
            >
              {{ 'AUTH.BUTTONS.CANCEL' | translate }}
            </button>
            <button
              nz-button nzType="primary"
              class="registration-btn button--primary"
              [disabled]="!form.valid"
              tabindex="4"
              type="button"
              (click)="validateClientInfo()"
            >
              {{ 'AUTH.BUTTONS.NEXT' | translate }}
            </button>
          </div>
        </form>
      </div>
      <div id="step1" *ngIf="currentStep === 1">
        <h3 class="step-title">{{ 'PLANS.TITLE' | translate }}</h3>
        <div class="mat-elevation-z6 card">
          <div class="container-fluid plans-container">
            <div class="row">
              <div
                *ngFor="let plan of plans; let i = index"
                class="col-lg-3 col-sm-5 col-xs-12 plan-card mat-elevation-z3"
                (click)="onCardClicked(i, plan?.productId, plan?.isTrial)"
              >
                <div
                  class="plan-title"
                  [ngStyle]="{
                    'background-color':
                      selectedCard == i
                        ? selectedCardColorPlanHeader
                        : defaultCardColor
                  }"
                >
                  <div
                    [ngStyle]="{
                      color: selectedCard == i ? 'white' : newTextColor
                    }"
                    class="size"
                  >
                    {{ plan?.name }}
                  </div>
                </div>
                <div class="plan-specs">
                  <div
                    *ngFor="let product of plan?.description?.split('|')"
                    class="spec"
                  >
                    <div
                      [ngStyle]="{
                        color:
                          selectedCard == i
                            ? selectedCardColorPlanDescription
                            : newArrowGray
                      }"
                    >
                      - {{ product }}
                    </div>
                  </div>
                </div>
                <div
                  class="plan-price"
                  [ngStyle]="{
                    color:
                      selectedCard == i
                        ? selectedCardColorPlanDescription
                        : newTextColor
                  }"
                >
                  <div>$ {{ plan?.price }}</div>
                  <div
                    class="disclaimer"
                    [ngStyle]="{
                      color:
                        selectedCard == i
                          ? selectedCardColorPlanDescription
                          : newArrowGray
                    }"
                  >
                    {{ 'PLANS.DISCLAIMER' | translate }}
                  </div>
                </div>
                <div class="plan-buttons"></div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button
              nz-button nzType="default"
              class="back-btn"
              tabindex="5"
              (click)="pre()"
            >
              {{ 'PLANS.BUTTONS.BACK' | translate }}
            </button>
            <button
              nz-button nzType="primary"
              class="registration-btn button--primary"
              [disabled]="!(!!selectedProduct)"
              tabindex="4"
              type="button"
              (debounceClick)="onPaymentClicked()"
              [debounceTime]="300"
            >
              {{ 'PLANS.BUTTONS.NEXT' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div id="step2" *ngIf="currentStep === 2 && !isTrial">
        <h3>{{ 'PAYMENT.TITLE' | translate }}</h3>
        <div class="mat-elevation-z6 card">
          <div class="payments-container">
            <div
              #paymentWidget
              id="gdx-paymentmanager-widget"
              class="payment-widget-container"
            >
          </div>
          </div>
          <div class="buttons">
            <button
              nz-button nzType="default"
              class="back-btn"
              tabindex="5"
              (click)="pre()"
            >
              {{ 'PAYMENT.BUTTONS.BACK' | translate }}
            </button>
            <button
              nz-button nzType="primary"
              class="registration-btn button--primary"
              [disabled]="!paymentSuccess"
              tabindex="4"
              type="button"
              (debounceClick)="onRegistrationClicked()"
              [debounceTime]="300"
            >
              {{ 'PAYMENT.BUTTONS.NEXT' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div id="step3" *ngIf="currentStep === 3">
        <h3 class="step-title">{{ 'FINISH.TITLE' | translate }}</h3>
        <form class="mat-elevation-z6 card">
          <div class="finish-content">
            <div class="finish-icon" *ngIf="registrationSuccess">
              <i class="fal fa-check-circle"></i>
            </div>
            <h3 class="finish-message" *ngIf="registrationSuccess">
              {{ 'FINISH.MESSAGE' | translate }}
              {{ createClientRequest.user.email }}
            </h3>
            <h3 class="finish-message" *ngIf="registrationSuccess">
              {{ 'FINISH.CONFIRM' | translate }}
            </h3>
            <div class="finish-icon" *ngIf="!registrationSuccess">
              <i class="fal fa-exclamation"></i>
            </div>
            <h3 class="finish-message" *ngIf="!registrationSuccess">
              {{ 'FINISH.ERROR' | translate }}
            </h3>
            <div class="finish-home" *ngIf="registrationSuccess">
              <h4 class="finish-invitation">
                {{ 'FINISH.INVITATION' | translate }}
              </h4>
              <button
                nz-button nzType="primary"
                class="finish-button"
                (click)="goToInterfirma()"
              >
                {{ 'FINISH.BUTTONS.BACK' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
