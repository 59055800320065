import { Observable } from 'rxjs';
import { LoadingService } from './../../../services/loading/loading.service';
import { Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-not-hired',
  templateUrl: './not-hired.component.html',
  styleUrls: ['./not-hired.component.scss'],
})
export class NotHiredComponent implements OnInit {
  loading$: Observable<boolean>;
  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loading$ = this.loadingService.loading$;
  }
}
