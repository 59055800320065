export interface IReport {
  name: string;
  key: string;
  statistic_results: IResultReport[] | IUserSection;
}

export const initialIReport: IReport = {
  name: '',
  key: '',
  statistic_results: []
};

export interface IOperationReport {
  name: string;
  flow_key: string;
  results: IOperationResult;
}

export const initialIOperationReport: IOperationReport = {
  name: '',
  flow_key: '',
  results: null
};

export interface IOperationResult {
  status_sent_by_period: IOperationCount[];
  status_sent_by_client_active: IOperationCount[];
}

export interface IOperationCount {
  next_status_id?: number;
  key?: string;
  promedio: number;
  color?: string;
  total?: number;
}

export interface IUserSection {
  users: IUserReport[];
  departments?: IUserReport[];
  companies?: IUserReport[];
  users_departments: IDepartmentCounter[];
}

export const initialIUserSection: IUserSection = {
  users: null,
  departments: null,
  companies: null,
  users_departments: null
};

export interface IUserReport {
  user_id?: number;
  user_name?: string;
  department_id?: number;
  color?: string;
  name?: string;
  company_id?: number | string;
  company_name?: string;
  created_at?: Date;
  deleted_at?: Date;
  total?: number;
  review?: number;
  reviewed?: number;
  approval?: number;
  approved?: number;
  sign?: number;
  signed?: number;
}

export interface IDepartmentCounter {
  id: number;
  name: string;
  total: number;
  color?: string;
}

export interface IUSerCount {
  flow_status: string;
  count: {
    internal_process: number;
    signed_process: number;
  };
}

export interface IDocumentReport {
  flow_key: string;
  name: string;
  documents: IDocumentsDataReport[];
}

export const initialIDocumentReport: IDocumentReport = {
  flow_key: '',
  name: '',
  documents: []
};

export interface IDocumentsDataReport {
  kind: string;
  total_docs: number;
  sign?: IDocumentAverage;
  approval?: IDocumentAverage;
  review?: IDocumentAverage;
}

export interface IDocumentAverage {
  result: number;
  color: string;
}

export type IResultReport =
  | IOperationReport
  | IUserReport
  | IDepartmentCounter
  | IDocumentReport;

export interface ISignerReport {
  _id: number;
  id: number;
  name: string;
  email: string;
  rfc: string;
  signatory_role: string;
  active: boolean;
}

export interface IReportFilter {
  month?: number;
  year?: number;
  departments?: number[];
  signers1?: string[];
  signers2?: string[];
  user_department?: number;
  companies?: string[];
}

export enum ETabsUsers {
  members = 'MEMBERS',
  teams = 'TEAMS',
  companies = 'COMPANIES'
}
