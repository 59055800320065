import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidatorContainerComponent } from './components/validator-container/validator-container.component';
import { UuidValidationComponent } from './components/uuid-validation/uuid-validation.component';
import { XmlValidationComponent } from './components/xml-validation/xml-validation.component';
import { ValidatorComponent } from './validator/validator.component';
import { XmlValidationDocumentsComponent } from './components/xml-validation-documents/xml-validation-documents.component';
import { ShowSignaturesComponent } from './components/show-signatures/show-signatures.component';
import { UuidValidationDocumentComponent } from './components/uuid-validation-document/uuid-validation-document.component';

const routes: Routes = [
  {
    path: '',
    component: ValidatorContainerComponent,
    children: [
      { path: '', component: ValidatorComponent },
      { path: 'uuid/:uuid', component: UuidValidationDocumentComponent },
      { path: 'uuid', component: UuidValidationComponent },
      { path: 'xml', component: XmlValidationComponent },
      { path: 'xml/document/signatures', component: ShowSignaturesComponent },
      { path: 'xml/document', component: XmlValidationDocumentsComponent },
      { path: ':uuid', redirectTo: 'uuid/:uuid' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ValidatorRoutingModule {}
