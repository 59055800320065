import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LiteraxNotificationServiceService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  translate(
    {
      bodyKey,
      titleKey,
    }: {
      bodyKey: string;
      titleKey: string;
    },
    params?: Object
  ): Observable<any> {
    return this.translateService
      .stream([bodyKey, titleKey], params)
      .pipe(take(1));
  }

  success(bodyKey?: string, titleKey?: string, params?: Object) {
    this.translate({ bodyKey, titleKey }, params).subscribe((i18n) => {
      this.toastr.success(i18n[bodyKey], i18n[titleKey], { enableHtml: true });
    });
  }

  error(bodyKey?: string, titleKey?: string, params?: Object) {
    this.translate({ bodyKey, titleKey }, params).subscribe((i18n) => {
      this.toastr.error(i18n[bodyKey], i18n[titleKey], { enableHtml: true });
    });
  }

  warning(bodyKey?: string, titleKey?: string, params?: Object) {
    this.translate({ bodyKey, titleKey }, params).subscribe((i18n) => {
      this.toastr.warning(i18n[bodyKey], i18n[titleKey], { enableHtml: true });
    });
  }

  info(bodyKey?: string, titleKey?: string, params?: Object) {
    this.translate({ bodyKey, titleKey }, params).subscribe((i18n) => {
      this.toastr.info(i18n[bodyKey], i18n[titleKey], { enableHtml: true });
    });
  }

  show(bodyKey?: string, titleKey?: string, params?: Object) {
    this.translate({ bodyKey, titleKey }, params).subscribe((i18n) => {
      this.toastr.show(i18n[bodyKey], i18n[titleKey], { enableHtml: true });
    });
  }
}
