import { IAppState } from '@literax/store';
import { IRegistrationState } from './registration.state';
import { createSelector } from '@ngrx/store';

const selectRegistration = (state: IAppState) => state.registration;

export const selectValidateClient = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.validateClient
);

export const selectPlans = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.plans
);

export const selectPlanReference = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.planReference
);

export const selectClientCreation = createSelector(
  selectRegistration,
  (state: IRegistrationState) => state.clientCreation
);
