<div class="dialog">
  <div class="message-container">
    <p class="message" *ngIf="!showRejectTextarea">
      {{ message | translate }}
    </p>
    <ng-container [ngTemplateOutlet]="rejectContent" *ngIf="showRejectTextarea"></ng-container>
  </div>
  <div *nzModalFooter class="custom-footer">
    <button nz-button class="cancel" (click)="cancel()">
      {{ cancelButton ?? 'TYC_MODAL.BUTTON_CANCEL' | translate }}
    </button>
    <button nz-button class="ok" nzType="primary" (click)="ok()" [disabled]="rejectForm.invalid">
      {{ acceptButton ?? 'WORKSPACE.FOOTER.SEND' | translate }}
    </button>
  </div>
</div>

<ng-template #rejectContent>
  <nz-alert nzType="warning" [nzMessage]="alertTitle" [nzDescription]="
      'MODULES.WORKSPACE.MODALS.MESSAGES.REJECT.ALERT.MESSAGE' | translate
    "></nz-alert>
  <ng-template #alertTitle>
    <b>{{
      'MODULES.WORKSPACE.MODALS.MESSAGES.REJECT.ALERT.TITLE' | translate
      }}</b>
  </ng-template>
  <form nz-form [formGroup]="rejectForm" nzLayout="vertical">
    <nz-form-item>
      <nz-form-label nzRequired>{{
        'MODULES.WORKSPACE.MODALS.MESSAGES.REJECT.FORM.LABEL' | translate
        }}</nz-form-label>
      <nz-form-control [nzErrorTip]="errorTip">
        <nz-textarea-count [nzMaxCharacterCount]="100">
          <textarea maxlength="100" rows="4" formControlName="reason" nz-input [placeholder]="
              'MODULES.WORKSPACE.MODALS.MESSAGES.REJECT.FORM.PLACEHOLDER'
                | translate
            "></textarea>
        </nz-textarea-count>
        <ng-template #errorTip let-control>
          <ng-container *ngIf="control.hasError('required')">
            {{
            'LTX-COMPONENTS.ERRORS.REQUIRED'
            | translate
            : {
            field:
            'MODULES.WORKSPACE.MODALS.MESSAGES.REJECT.FORM.LABEL'
            | translate
            }
            }}
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-template>
