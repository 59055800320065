import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ActionModalComponent implements OnInit {
  @Input() showRejectTextarea = false;
  title: string;
  message: string;
  rejectMessage: string;
  cancelButton: string;
  acceptButton: string;
  rejectForm: FormGroup;
  constructor(public modalRef: NzModalRef) { }

  ngOnInit(): void {
    if (this.showRejectTextarea) {
      this.rejectForm = new FormGroup({
        reason: new FormControl('', [Validators.required]),
      });
    }
  }

  cancel(): void {
    this.modalRef.triggerCancel();
  }

  ok(): void {
    this.modalRef.triggerOk();
  }
}
