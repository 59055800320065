import { createSelector } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { ConfigState } from '@literax/modules/configurations/store/config/config.state';

const selectConfig = (state: IAppState) => state.config;

export const selectCurrentLang = createSelector(
  selectConfig,
  (state: ConfigState) => state.lang
);
