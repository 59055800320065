<div
  class="select capitalize"
  (click)="toggleSelect()"
  [class.disabled]="readonly"
  [ngClass]="selectActive || selectedOptions.length > 0 ? 'select-active' : ''"
>
  {{ selectedOption }}
</div>
<div class="options" *ngIf="selectActive">
  <label class="container capitalize" *ngFor="let option of options"
    >{{ option.name }}
    <input
      type="checkbox"
      (click)="optionSelected(option)"
      [checked]="selectedOptions.includes(option.name)"
    />
    <span class="checkmark"></span>
  </label>
</div>
