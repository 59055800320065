import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ISignRequest,
  IUser
} from '@literax/modules/documents/store/document/document.state';
import { Store, select } from '@ngrx/store';

import { IAppState } from './../../../../store/index';
import { ICancelatioReason } from '@literax/models/http/document/document.model';
import { ISignature } from '@literax/models/http/api/signature.model';
import { MatDialog } from '@angular/material/dialog';
import { ShowCommentRejectedComponent } from '../show-comment-rejected/show-comment-rejected.component';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-signers',
  templateUrl: './signers.component.html',
  styleUrls: ['./signers.component.scss']
})
export class SignersComponent implements OnInit, OnDestroy {
  emailDelivered: boolean;
  signRequest: ISignRequest;
  @Input() free: boolean;
  @Input() signature: ISignature;
  @Input() labelButton: string;
  @Input() actionButtonEnable: boolean;
  @Input() typePromissoryNote: boolean;
  @Input() hasObservers: boolean;
  @Output() actionButton = new EventEmitter();
  @Output() historyButton = new EventEmitter();
  @Input() status: string;
  @Input() userLoginObservable: boolean;
  @Input() user: IUser;
  @Input() userDocument: IUser;
  @Input() set signer(signer: ISignRequest) {
    this.signRequest = signer;
    this.emailDelivered =
      ['delivered', 'openend', 'clicked'].indexOf(
        signer.last_email_event?.status
      ) >= 0;
  }
  get signer(): ISignRequest {
    return this.signRequest;
  }
  @Input() comment: ICancelatioReason[];

  showInfo = false;
  signerIcons = {
    signatory: 'fa-pen-nib',
    payee: 'fa-money-bill',
    proxy_holder: 'fa-money-bill-wave-alt',
    property_holder: 'fa-money-bill',
    subscriptor: 'fa-money-check-alt',
    guarantor: 'fa-money-check-edit',
    root: 'fa-money-bill',
    proxy: 'fa-money-bill-wave-alt',
    property: 'fa-money-bill'
  };

  showSignature(event: Event) {
    this.showInfo = !this.showInfo;
  }

  constructor(private dialog: MatDialog, private store: Store<IAppState>) {}

  ngOnInit() {}

  ngOnDestroy() {}

  openCommentDetails(): void {
    const dialogRef = this.dialog.open(ShowCommentRejectedComponent, {
      width: '656px',
      height: 'auto',
      disableClose: true,
      data: {
        comment: this.comment,
        user: this.signer.name
      }
    });
  }
}
