import { IAPIError } from '@literax/models/http/api/error.model';
import { IAppState } from '@literax/store/index';
import { IValidatorState } from './validator.state';
import { createSelector } from '@ngrx/store';

const selectSignatures = (state: IAppState) => state.validator;

export const selectSignatureResult = createSelector(
  selectSignatures,
  (state: IValidatorState) => state.signatureResult
);

export const selectShowSignatureResult = createSelector(
  selectSignatures,
  (state: IValidatorState) => state?.showSignatureResult
);

export const selectSignatureErrors = createSelector(
  selectSignatures,
  (state: IValidatorState) => state.errors
);

export const selectSignatureErrorDetails = createSelector(
  selectSignatureErrors,
  (state: IAPIError) => state && state.detail
);

export const selectCertificateErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) => state && state.cert
);

export const selectKeyErrors = createSelector(
  selectSignatureErrorDetails,
  (state: any) =>
    state &&
    (state.key || state['signatures.sign_data'] || state['signatures.expiry'])
);
