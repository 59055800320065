<div class="validator-selector">
  <div nz-row class="page-header">
    <div nz-col nzSpan="24" class="headline">
      <button
        nz-button
        nzType="text"
        class="back-button"
        routerLink="/validator"
      >
        <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
      </button>
      <h4 class="validator-selector-title">
        {{ 'XML_SCREEN.HEADLINE' | translate }}
      </h4>
    </div>
  </div>
  <div nz-row class="form-container">
    <div nz-col nzSpan="24">
      <nz-card [nzActions]="[actionButton]">
        <nz-card-meta
          [nzDescription]="'XML_SCREEN.FORM.TITLE' | translate"
        ></nz-card-meta>
        <nz-upload
          nzType="drag"
          [nzMultiple]="true"
          [nzCustomRequest]="nzCustomRequest"
          [nzAccept]="permitedFiles"
          (nzChange)="handleChange($event)"
          [nzShowUploadList]="false"
        >
          <p class="ant-upload-drag-icon">
            <i nz-icon nzType="inbox"></i>
          </p>
          <p class="ant-upload-text">
            {{ 'XML_SCREEN.FORM.FILE_INPUT.DESCRIPTION' | translate }}
          </p>
          <p class="ant-upload-hint">
            {{ 'XML_SCREEN.FORM.FILE_INPUT.HINT' | translate }}
          </p>
        </nz-upload>

        <div *ngIf="files.length > 0" class="file-list">
          <h6>{{ 'XML_SCREEN.FILE_LIST.TITLE' | translate }}</h6>
          <ul class="files">
            <li *ngFor="let item of files; let i = index">
              <span class="kind">
                <i
                  nz-icon
                  nzType="file-zip"
                  *ngIf="item?.kind === 'zip'"
                  nzTheme="outline"
                ></i>
                <i
                  nz-icon
                  nzType="file-text"
                  *ngIf="item?.kind === 'xml'"
                  nzTheme="outline"
                ></i>
              </span>
              <span class="name">{{ item?.file?.name }}</span>
              <span class="actions">
                <button nz-button nzType="text" (click)="removeFile(item, i)">
                  <span nz-icon>
                    <i class="fal fa-trash-alt"></i>
                  </span>
                </button>
              </span>
            </li>
          </ul>
        </div>

        <re-captcha
          style="width: 100%; display: block"
          (resolved)="resolved($event)"
          [siteKey]="captchaKey"
        ></re-captcha>
      </nz-card>
      <ng-template #actionButton>
        <button
          nz-button
          nzBlock
          nzType="primary"
          [disabled]="verifyButtonDisabled"
          (click)="testFiles()"
        >
          {{ 'XML_SCREEN.FORM.ACTION_BUTTON' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="errorModalShow"
  [nzTitle]="null"
  [nzFooter]="null"
  [nzClosable]="false"
  [nzCentered]="true"
  [nzWidth]="800"
>
  <div *nzModalContent>
    <ng-template #errorIcon>
      <span nz-icon>
        <i class="fal fa-times-circle"></i>
      </span>
    </ng-template>
    <nz-result
      [nzIcon]="errorIcon"
      nzStatus="error"
      [nzTitle]="'XML_SCREEN.MODALS.ERROR.TITLE' | translate"
      [nzSubTitle]="errorMessage"
    >
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="errorModalShow = false">
          {{ 'XML_SCREEN.MODALS.AWARE_BUTTON' | translate }}
        </button>
      </div>
    </nz-result>
  </div>
</nz-modal>
