export const Flows = {
  draft: 'draft',
  signature: 'signature',
  legacy_signature: 'legacy_signature',
  signature_request: 'signature_request',
  legacy_signature_request: 'legacy_signature_request',
  promissory_note_in_effect: 'promissory_note_in_effect',
  promissory_note_extinct: 'promissory_note_extinct',
  promissory_note_expired: 'promissory_note_expired',
  data_collection: 'data_collection',
  template_draft: 'template_draft',
  template_published: 'template_published',
  template_unpublished: 'template_unpublished',
  template_publishing: 'template_publishing',
  template_work_in_progress: 'template_work_in_progress',
  review: 'review',
  approval: 'approval',
  request: 'request',
  form: 'form',
  template: 'template',
};

export const ModelFlow = {
  document: 'document',
  sign_request: 'sign_request',
  template: 'template',
  profile: 'profile',
};

export const DocumentListType = {
  PROJECTS: 'projects',
  SENT: 'sent',
  RECEIVED: 'received',
  SHARED: 'shared',
};
