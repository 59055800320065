import { WorkspaceState, initialWorkspaceState } from './workspace.state';
import { createReducer, on } from '@ngrx/store';

import { AttachmentReducers } from './reducers/attachment.reducers';
import { DocumentReducers } from './reducers/document.reducers';
import { ParticipantReducers } from './reducers/participant.reducers';
import { WorkspaceActions } from './workspace.actions';

export const workspaceReducer = createReducer(
  initialWorkspaceState,
  on(WorkspaceActions.clearState, (state: WorkspaceState) => ({
    ...state,
    ...initialWorkspaceState,
  })),
  on(WorkspaceActions.handleAction, (state, action) => ({
    ...state,
    actionToDispatch: action.payload.action,
  })),
  on(WorkspaceActions.handleActionSuccess, (state, action) => ({
    ...state,
    actionToDispatch: null,
  })),
  on(WorkspaceActions.getDocumentSuccess, DocumentReducers.getDocumentSuccess),
  on(WorkspaceActions.getDocumentError, DocumentReducers.getDocumentError),
  on(
    WorkspaceActions.moveParticipantCanvasSuccess,
    DocumentReducers.moveParticipantCanvasSuccess
  ),
  on(
    WorkspaceActions.getSignerEmailHistorySuccess,
    ParticipantReducers.getSignerEmailHistorySuccess
  ),
  on(
    WorkspaceActions.updateDocumentSuccess,
    DocumentReducers.updateDocumentSuccess
  ),

  on(WorkspaceActions.viewAttachment, AttachmentReducers.viewAttachment),
  on(
    WorkspaceActions.viewAttachmentSuccess,
    AttachmentReducers.viewAttachmentSuccess
  ),
  on(
    WorkspaceActions.replaceAttachmentSuccess,
    AttachmentReducers.replaceAttachmentSuccess
  ),
  on(WorkspaceActions.setAttachmentBase64, AttachmentReducers.setBase64),
  on(
    WorkspaceActions.redactAttachmentSuccess,
    AttachmentReducers.redactAttachmentSuccess
  ),
  on(WorkspaceActions.setCurrentWorkspaceTab, (state, action) => ({
    ...state,
    activeTab: action.payload.id,
  })),
  on(
    WorkspaceActions.deleteAttachmentSuccess,
    AttachmentReducers.deleteAttachmentSuccess
  ),
  on(WorkspaceActions.sendReadStatus, AttachmentReducers.sendReadStatus),
  on(WorkspaceActions.getUserInfoSuccess, DocumentReducers.getUserInfoSuccess),
  on(
    WorkspaceActions.getAccessTokensSuccess,
    DocumentReducers.getAccessTokensSuccess
  ),
  on(WorkspaceActions.setFormBuilderRequiredFieldsStatus, (state, action) => ({
    ...state,
    formBuilderRequiredFieldsFilled: action.payload,
  })),
  on(
    WorkspaceActions.transformDocxToPDF,
    AttachmentReducers.transformDocxToPDF
  ),
  on(
    WorkspaceActions.transformDocxToPDFSuccess,
    AttachmentReducers.transformDocxToPDFSuccess
  ),
  on(WorkspaceActions.setWorkspaceMode, (state, action) => ({
    ...state,
    workingModeType: action.payload.mode,
  })),
  on(WorkspaceActions.setGuestToken, (state, action) => ({
    ...state,
    guestToken: action.payload,
  })),
  on(
    WorkspaceActions.getSignatureQuotesSuccess,
    ParticipantReducers.getSignatureQuotesSuccess
  ),
  on(WorkspaceActions.getVerificationTypesSuccess, DocumentReducers.getVerificationTypesSuccess)
);
