import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  constructor(private b2cAuthService: B2CAuthService, private router: Router) {}

  ngOnInit() {}

  login() {
    this.b2cAuthService.loginRedirect('/auth/onboarding/active');
  }

  onBoarding() {
    this.router.navigate(['/auth/onboarding']);
  }
}
