import * as validatorActions from './validator.actions';
import * as _ from 'lodash';
import { Action, createReducer, on } from '@ngrx/store';
import { IValidatorState, initialValidatorState } from './validator.state';
import { EParticipantKind } from '@literax/enums/participant.enum';
import { XmlValidationResponse } from '../validator.interfaces';
import { ESigningMethod } from '@literax/enums/document.enum';

const formatParticipants = (
  data: XmlValidationResponse
): XmlValidationResponse => {
  const legalPersons =
    data.signRequests?.legalPersons?.reduce((previous, current, index) => {
      const legalRepresentative = _.omit(
        {
          ...current,
          kind: EParticipantKind.NATURAL_PERSON,
        },
        ['companyName', 'companyTaxId', 'orderingTurn', 'idDocumentRequest']
      );
      const legalPerson = {
        signRequestId: new Date().getTime() + index,
        companyName: current.companyName,
        companyTaxId: current.companyTaxId,
        expiryAt: current.expiryAt,
        orderingTurn: current.orderingTurn,
        idDocumentRequest: current.idDocumentRequest,
        kind: EParticipantKind.LEGAL_PERSON,
        legalRepresentatives: [legalRepresentative],
      };

      const exists = previous?.find((participant) =>
        data?.signingConfiguration?.signingMethod !==
        ESigningMethod.DIGITAL_SIGNATURE
          ? participant.companyName === current.companyName
          : participant.companyName === current.companyName &&
            participant.companyTaxId === current.companyTaxId
      );

      exists !== undefined
        ? exists.legalRepresentatives.push(legalRepresentative)
        : previous.push(legalPerson);

      return previous;
    }, []) || [];
  const naturalPersons =
    data.signRequests?.naturalPersons.map((naturalPerson) => ({
      ...naturalPerson,
      kind: EParticipantKind.NATURAL_PERSON,
    })) || [];
  const observers =
    data.signRequests?.observers.map((observer) => ({
      ...observer,
      kind: EParticipantKind.OBSERVER,
    })) || [];

  return { ...data, signRequests: { legalPersons, naturalPersons, observers } };
};

const reducer = createReducer(
  initialValidatorState,
  on(validatorActions.ValidatedFilesResponse, (state, action) => ({
    ...state,
    XMLValidatedDocument: formatParticipants(action.payload),
    errors: null,
  })),
  on(validatorActions.XmlValidationError, (state, action) => {
    return {
      ...state,
      errors: action.payload,
    };
  }),
  on(validatorActions.DissmisErrorMessage, (state, action) => ({
    ...state,
    errors: null,
  })),
  on(validatorActions.ShowSignatureSuccess, (state, action) => ({
    ...state,
    showSignatureResult: action.payload,
    errors: null,
  })),
  on(validatorActions.ShowSignatureError, (state, action) => ({
    ...state,
    showSignatureResult: null,
    errors: action.payload.error.error,
  }))
);

export function validatorReducer(
  state: IValidatorState | undefined,
  action: Action
) {
  return reducer(state, action);
}
