<form #identityForm="ngForm" [formGroup]="identityValidationForm">
  <div class="modal-body row">
    <div
      class="col-12"
      *ngIf="
        documentRequest === officialIdentification.ID_DOCUMENT ||
        documentRequest === officialIdentification.INE
      "
    >
      <literax-file-input
        #fileInput
        formControlName="id_image"
        [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
        [fileTypes]="['image/*']"
        [captureCamera]="true"
        [labelTag]="frontFileLabel | translate"
        [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
        [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
      >
      </literax-file-input>
      <literax-file-input
        #fileInput
        formControlName="id_image_back"
        [currentFileName]="'COMMON.FILE_INPUT_LABEL' | translate"
        [fileTypes]="['image/*']"
        [captureCamera]="true"
        [labelTag]="frontFileLabel | translate"
        [labelTag]="backFileLabel | translate"
        [buttonChangeTag]="'FORM.FILE_INPUT.CHANGE_BUTTON' | translate"
        [buttonSelectTag]="'FORM.FILE_INPUT.SELECT_BUTTON' | translate"
      >
      </literax-file-input>
    </div>
    <div class="col-12" *ngIf="faceMatch">
      <record-biometric-video
        #recordBiometric
        width="320"
        height="240"
        threshold="{0.7}"
        show-controls="{true}"
        max-record-time="{30}"
        (recordFinished)="sendBiometrics($event)"
        mode="record"
      >
      </record-biometric-video>
    </div>
  </div>
</form>
