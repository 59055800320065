import {
  IPromissoryNote_Report_suscriptor_Filter,
  IPromissory_Note_Report,
  IPromissory_Note_Report_Detail,
  IPromissoryNote_Report_Beneficiaries_Filter,
  IBeneficiaries,
} from '@literax/models/report-promissory-note.model';
import { ChartDataSets } from 'chart.js';

export interface IPromissoryNoteReportState {
  promissory_note_report: IPromissory_Note_Report;
  promissory_note_report_detail: IPromissory_Note_Report_Detail;
  promissory_note_report_beneficiaries_filter: IPromissoryNote_Report_Beneficiaries_Filter;
  promissory_note_report_suscriptor_filter: IPromissoryNote_Report_suscriptor_Filter;

  beneficiaries?: {
    chart?: {
      labels?: string[];
      datasets?: ChartDataSets[];
      centerData?: {
        total?: number;
        label?: string;
      };
    };
    total?: Partial<IBeneficiaries>[];
  };
}

export const initialPromissoryNoteReportState: IPromissoryNoteReportState = {
  promissory_note_report: {
    header: {
      total: 0,
      total_amount: 0,
      kinds: [
        { _id: 'Ordinario', count: 0 },
        { _id: 'Especial', count: 0 },
      ],
      characteristic: [
        { _id: 'A la orden', count: 0 },
        { _id: 'No negociable', count: 0 },
      ],
      rates: [
        { _id: 'Sin interés', count: 0 },
        { _id: 'Fija', count: 0 },
        { _id: 'Variable', count: 0 },
      ],
      avg_rates: [
        { _id: 'Fija', avg: 0 },
        { _id: 'Variable', avg: 0 },
      ],
    },
    overdues: {
      in_effect: [
        { order: 1, count: 0.0, amount: 0.0 },
        { order: 2, count: 0.0, amount: 0.0 },
        { order: 3, count: 0.0, amount: 0.0 },
        { order: 4, count: 0, amount: 0.0 },
        { order: 5, count: 0.0, amount: 0.0 },
        { order: 6, count: 0.0, amount: 0.0 },
      ],
      expired: [
        { order: 1, count: 0.0, amount: 0.0 },
        { order: 2, count: 0.0, amount: 0.0 },
        { order: 3, count: 0.0, amount: 0.0 },
        { order: 4, count: 0.0, amount: 0.0 },
        { order: 5, count: 0.0, amount: 0.0 },
        { order: 6, count: 0.0, amount: 0.0 },
      ],
    },
    promissory_note_status: [
      { _id: 'in_effect', count: 0, total_amount: 0.0 },
      { _id: 'extinc', count: 0, total_amount: 0.0 },
      { _id: 'expired', count: 0, total_amount: 0.0 },
    ],
    beneficiaries: [
      {
        _id: 'N/A',
        promissory_notes: 0,
        total_amount: 0,
      },
    ],
    endorsees: [
      {
        _id: 'N/A',
        promissory_notes: 0,
        total_amount: 0,
      },
    ],
    jurisdictions: [
      {
        _id: {
          jurisdiction: '',
          subscription: '',
        },
        promissory_notes: 0,
        total_amount: 0,
      },
    ],
  },
  promissory_note_report_detail: null,
  promissory_note_report_beneficiaries_filter: null,
  promissory_note_report_suscriptor_filter: null,
  beneficiaries: {
    chart: {
      labels: [],
      datasets: [],
      centerData: {
        total: 0,
        label: 'REPORTS.PROMISSORY_NOTE.LABEL',
      },
    },
    total: []
  },
};
