import { createAction, props } from '@ngrx/store';
import {
  IReport,
  IReportFilter,
  ISignerReport
} from '@literax/models/reports.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';

export enum EReportActions {

  ChangeMonth = '[Report] Change month',
  ChangeYear = '[Report] Change year',
  ChangeUserDepartment = '[Report] change user department',
  ChangeDepartments = '[Report] change departments',
  ChangeCompanies = '[Report] change companies',
  ChangeSigners1 = '[Report] change signers 1',
  ChangeSigners2 = '[Report] change signers 2',

  GetReport = '[Report] Get report',
  GetReportSuccess = '[Report] Get report success',
  GetReportError = '[Report] Get report error',

  GetSigners = '[Report] Get signers',
  GetSignersSuccess = '[Report] Get signers success',
  GetSignersError = '[Report] Get signers error',

  ExportDocument = '[Report] Export document',
  ExportDocumentError = '[Report] Export document error'
}

export const ChangeMonth = createAction(
  EReportActions.ChangeMonth,
  props<{payload: number}>()
);

export const ChangeYear = createAction(
  EReportActions.ChangeYear,
  props<{payload: number}>()
);

export const ChangeUserDepartment = createAction(
  EReportActions.ChangeUserDepartment,
  props<{payload: number}>()
);

export const ChangeDepartments = createAction(
  EReportActions.ChangeDepartments,
  props<{payload: number[]}>()
);

export const ChangeCompanies = createAction(
  EReportActions.ChangeCompanies,
  props<{payload: string[]}>()
);

export const ChangeSigners1 = createAction(
  EReportActions.ChangeSigners1,
  props<{payload: string[]}>()
);

export const ChangeSigners2 = createAction(
  EReportActions.ChangeSigners2,
  props<{payload: string[]}>()
);

export const GetReport = createAction(
  EReportActions.GetReport,
  props<{ payload: IReportFilter }>()
);

export const GetReportSuccess = createAction(
  EReportActions.GetReportSuccess,
  props<{ payload: IReport[] }>()
);

export const GetReportError = createAction(
  EReportActions.GetReportError,
  props<{ payload: IAPIResponseError }>()
);

export const GetSignersReport = createAction(
  EReportActions.GetSigners,
  props<{ payload: string }>()
);

export const GetSignersSuccess = createAction(
  EReportActions.GetSignersSuccess,
  props<{ payload: ISignerReport[] }>()
);

export const GetSignersError = createAction(
  EReportActions.GetSignersError,
  props<{ payload: IAPIResponseError }>()
);

export const ExportDocument = createAction(
  EReportActions.ExportDocument,
  props<{ payload: string }>()
);

export const ExportDocumentError = createAction(
  EReportActions.ExportDocumentError
);
