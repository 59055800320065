<div class="reviewer-request">
  <div class="wrapper">
    <div class="check-container">
      <div
        class="rectangle"
        [ngClass]="
          reviewer?.status?.key === 'reviewed' ? 'rectangle-success' : ''
        "
      >
        <i
          class="fal fa-check"
          [ngClass]="
            reviewer?.status?.key === 'reviewed'
              ? 'color-reviewed'
              : 'color-unchecked'
          "
        ></i>
      </div>
    </div>
    <div class="reviewers">
      <div class="reviewer-info">
        <p class="reviewer name">
          {{ reviewer?.name }}
          <span *ngIf="reviewer?.is_author" class="author-label">
            - {{ 'DOCUMENTS.SIGNERS.AUTHOR' | translate }}
          </span>
        </p>
        <div class="actions-container">
          <literax-popover
            *ngIf="
              userDocument?.id === user?.id &&
              reviewer?.status?.key !== 'reviewed' &&
              !reviewer?.is_author &&
              actionButtonEnable &&
              status !== 'draft' &&
              status !== 'rejected'
            "
            [last_event_email]="reviewer?.last_email_event"
            [signer]="reviewer"
            [statusDocument]="document.status.key"
            (historyButton)="historyButton.emit($event)"
            (actionButton)="actionButton.emit($event)"
          ></literax-popover>
        </div>
      </div>
      <div class="reviewer-extra-info">
        <div class="info-cell">
          <span class="title">
            {{ 'DOCUMENTS.SIGNERS.EMAIL' | translate }}:
          </span>
          <span class="extra-info">{{ reviewer?.email }}</span>
        </div>
        <div class="info-cell">
          <span class="title">
            {{ 'DOCUMENTS.SIGNERS.EXPIRY' | translate }}:
          </span>
          <span class="extra-info">
            {{ reviewer?.expiry | date: 'dd/MM/yyyy' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="delegated-participant" *ngIf="reviewer?.delegate">
    <div class="delegated-title">
      {{
        'DOCUMENTS.SIGNERS.DELEGATED_TO_TITLE'
          | translate: { process: 'WORKSPACE.FOOTER.REVIEW' | translate }
      }}
    </div>
    <div class="wrapper">
      <div class="check-container">
        <div
          class="rectangle"
          [ngClass]="
            reviewer.delegate?.status?.key === 'reviewed'
              ? 'rectangle-success'
              : ''
          "
        >
          <i
            class="fal fa-check"
            [ngClass]="
              reviewer.delegate?.status?.key === 'reviewed'
                ? 'color-reviewed'
                : 'color-unchecked'
            "
          ></i>
        </div>
      </div>
      <div class="reviewers">
        <div class="reviewer-info">
          <p class="reviewer name">
            {{ reviewer?.delegate?.name }}
            <span class="author-label">
              - {{ 'DOCUMENTS.SIGNERS.DELEGATE' | translate }}
            </span>
          </p>
        </div>
        <div class="reviewer-extra-info">
          <div class="info-cell">
            <span class="title">
              {{ 'DOCUMENTS.SIGNERS.EMAIL' | translate }}:
            </span>
            <span class="extra-info">{{ reviewer?.delegate?.email }}</span>
          </div>
          <div class="info-cell">
            <span class="title">
              {{ 'DOCUMENTS.SIGNERS.EXPIRY' | translate }}:
            </span>
            <span class="extra-info">
              {{ reviewer?.delegate?.expiry | date: 'dd/MM/yyyy' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
