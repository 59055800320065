import * as UsersActions from './users.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '@literax/store';
import { IFilterParams } from '@literax/interfaces/filter-param.interface';
import { IProcess } from '@literax/components/platform/profiles/types/user-ptofiles';
import { IProfile } from '../../profiles/models/profiles';
import { Injectable } from '@angular/core';
import { LiteraxNotificationServiceService } from '@literax/services/literax-notification-service.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UsersServices } from '../services/users.services';
import { of } from 'rxjs';
import { IDepartmentReceivers } from '../models/users.interface';

@Injectable()
export class UsersEffect {
  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private usersServices: UsersServices,
    private toastr: LiteraxNotificationServiceService,
    private toastrReg: I18nToastrService,
    private router: Router
  ) {}

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UsersActions.getAllUsers,
        UsersActions.updateUsersbyIdSucess,
        UsersActions.deleteUsersSuccess,
        UsersActions.saveUsersSucess,
        UsersActions.changeSortColumn,
        UsersActions.changeUserKindFilter
      ),
      withLatestFrom(this.store.select((state) => state.users.pagination)),
      withLatestFrom(this.store.select((state) => state.users.sortColumn)),
      withLatestFrom(this.store.select((state) => state.users.userKind)),
      distinctUntilChanged(),
      switchMap(([[[action, pagination], sort], userKind]) => {
        const filterParms: IFilterParams = {
          user_kind: userKind,
        };
        return this.usersServices.getAll(pagination, sort, filterParms).pipe(
          map((response) =>
            UsersActions.getAllUsersSuccess({
              client_users: response.client_users,
              pagination: response.pagination,
            })
          ),
          catchError((error) => of(UsersActions.errorConf({ error })))
        );
      })
    )
  );

  getDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UsersActions.getUsersDashboard,
        UsersActions.saveUsersSucess,
        UsersActions.deleteUsersSuccess
      ),
      switchMap(() =>
        this.usersServices.getDashboard().pipe(
          map((response) =>
            UsersActions.getUsersDashboardSuccess({
              payload: response.process as IProcess,
            })
          ),
          catchError((error) =>
            of(UsersActions.getUsersDashboardError({ payload: error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.updateUsersbyId),
      mergeMap((action) =>
        this.usersServices.update(action.users).pipe(
          map(() => UsersActions.updateUsersbyIdSucess()),
          finalize(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.UPDATE_SUCCESS');
          }),
          catchError((err: HttpErrorResponse) => {
            this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  registerUserConfirmations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.registerClientUsersConfirmations),
      mergeMap((action) => {
        return this.usersServices.userConfirm(action.user, action.token).pipe(
          map((e) => {
            return UsersActions.registerClientUsersConfirmationsSucess();
          }),
          tap(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.SAVE_SUCCESS');
          }),
          catchError((error) => {
            this.setErrorMessage(error);
            return of(UsersActions.errorConf({ error: error.error }));
          })
        );
      })
    )
  );

  loginClientUserConfirmations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.loginClientUsersConfirmations),
      mergeMap((action) =>
        this.usersServices.userLoginConfirm(action.token, action.b2cToken).pipe(
          map(() => UsersActions.loginClientUsersConfirmationsSucess()),
          catchError((response) =>
            of(UsersActions.errorloginConf({ error: response.error }))
          )
        )
      )
    )
  );

  loginClientUserSucess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersActions.loginClientUsersConfirmationsSucess),
        tap((action) => this.router.navigate(['platform', 'home']))
      ),
    { dispatch: false }
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.deleteUsers),
      mergeMap((action) =>
        this.usersServices.delete(action.userId).pipe(
          map(() => UsersActions.deleteUsersSuccess()),
          finalize(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.DELETE_SUCCESS');
          }),
          catchError((error) => of(UsersActions.errorConf({ error })))
        )
      )
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.saveUsers),
      mergeMap((action) =>
        this.usersServices.save(action.user).pipe(
          map(() => UsersActions.saveUsersSucess()),
          finalize(() => {
            this.toastr.success('TOAST_NOTIFICATIONS.SAVE_SUCCESS');
          }),
          catchError((err: HttpErrorResponse) => {
            this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  getUserProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getUserProfiles),
      mergeMap(() =>
        this.usersServices.getUserProfiles().pipe(
          map((response) =>
            UsersActions.getUserProfilesSuccess({
              payload: { profiles: response.profiles as IProfile[] },
            })
          ),
          catchError((err: HttpErrorResponse) => {
            this.evaluateErrorCode(err);
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  setDefaultUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.SetDefaultUser),
      mergeMap((action) =>
        this.usersServices.setDefaultUser(action.payload).pipe(
          map(() => UsersActions.SetDefaultUserSuccess()),
          catchError((err: HttpErrorResponse) => {
            this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  getReceivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.getReceivers),
      mergeMap((action) =>
        this.usersServices.getReceivers(action.excludeOneself).pipe(
          map((response) =>
            UsersActions.getReceiversSuccess({
              payload: {
                departmentReceivers: response as IDepartmentReceivers[],
              },
            })
          ),
          catchError((err: HttpErrorResponse) => {
            this.evaluateErrorCode(err);
            return of(UsersActions.errorConf({ error: err.error }));
          })
        )
      )
    )
  );

  setErrorMessage(error: any) {
    const message = 'TOAST_NOTIFICATIONS.MESSAGE_GENERIC_SAVE';
    this.toastrReg.error(message, '');
  }

  disableTaxMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DisableTaxMessage),
      switchMap(() =>
        this.usersServices.disableTaxMessage().pipe(
          map(() => UsersActions.DisableTaxMessageSuccess()),
          catchError((error) =>
            of(UsersActions.DisableTaxMessageError({ payload: error }))
          )
        )
      )
    )
  );

  evaluateErrorCode(error: HttpErrorResponse) {
    const Error = error.error.error;
    const StatusCodeHttp = Error.status;
    const Message = Error.title;
    switch (StatusCodeHttp) {
      case 401:
        console.error(Message, Error);
        break;
      case 500:
        this.toastr.error('TOAST_NOTIFICATIONS.INTERNAL_SERVER_ERROR');
        break;
      default:
        console.error(Message, Error);
        break;
    }
  }
}
