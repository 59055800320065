<div class="pdfViewerContainer">
    <literax-if-pdf [standalone]="true" [base64]="documentB64" [documentId]="0" [signRequests]="[]"></literax-if-pdf>
</div>
<label nz-checkbox [(ngModel)]="checked">{{ 'TYC_MODAL.ACCEPT_TERMS' | translate }}</label>
<div class="warning" *ngIf="hasBiometrics">
    <i class="fal fa-exclamation-circle"></i>
    <section class="warning-body">
        <h6>{{ 'WARNING.HEADER' | translate }}</h6>
        <br>
        <p>{{ 'WARNING.BODY' | translate }}</p>
    </section>
</div>
<div *nzModalFooter>
    <button nz-button nzType="default" (click)="cancel()">{{ 'TYC_MODAL.BUTTON_CANCEL' | translate }}</button>
    <button nz-button nzType="primary" [disabled]="!checked" (click)="accept()">{{ 'TYC_MODAL.BUTTON_ACCEPT' | translate
        }}</button>
</div>