import { IAppState } from '@literax/store';
import { IFreeTermsState } from './free-terms.state';
import { createSelector } from '@ngrx/store';

const selectTerms = (state: IAppState) => state.freeTerms;

export const selectFreeDocTerms = createSelector(
    selectTerms,
    (state: IFreeTermsState) => state.terms_of_service
);
