<div class="validator-workspace">
  <div class="info">
    <div class="title">
      <div class="icon">
        <button
          nz-button
          nzType="text"
          class="back-button"
          routerLink="/validator/xml"
        >
          <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
        </button>
      </div>
      <div class="headline">
        {{ 'XML_SCREEN.WORKSPACE_HEADLINE' | translate }}
      </div>
    </div>
    <div class="date">
      <div class="title">
        {{ 'XML_SCREEN.DATE' | translate }}
      </div>
      <div class="metadata">
        <ng-container *ngIf="currentLang === 'es'">
          {{
            document?.verifiedDate * 1000
              | date
                : "EEEE d 'de' MMMM 'de' y 'a las' h:mm a"
                : undefined
                : 'es'
          }}
        </ng-container>
        <ng-container *ngIf="currentLang === 'en'">
          {{
            document?.verifiedDate * 1000
              | date : "EEEE, MMMM d, y 'at' h:mm a" : undefined : 'en'
          }}
        </ng-container>
      </div>
    </div>
  </div>
  <div class="workspace">
    <div class="tab-headers">
      <nz-tabset [nzSelectedIndex]="currentTabIndex">
        <nz-tab
          [nzTitle]="tabTitle"
          *ngFor="let tab of tabOptions"
          (nzClick)="setCurrentTab(tab.key)"
        >
          <ng-template #tabTitle>
            <div
              class="tab-title"
              [class]="'tab-header-' + (tabs[tab.key] | lowercase)"
            >
              <span nz-icon>
                <i [class]="tab.icon"></i>
              </span>
              <span class="hide-on-mobile">{{ tab.label | translate }}</span>
            </div>
          </ng-template>
        </nz-tab>
      </nz-tabset>
    </div>
    <div class="main">
      <div class="viewer">
        <literax-if-pdf
          [standalone]="true"
          [url]="currentAttachment?.filePath"
        ></literax-if-pdf>
      </div>
      <div class="sidebar" [class.show-doc]="currentTab === tabs.DOCUMENT">
        <ng-container *ngIf="currentTab === tabs.FILES">
          <ng-container *ngTemplateOutlet="files"></ng-container>
        </ng-container>
        <ng-container *ngIf="currentTab === tabs.COLLABORATORS">
          <ng-container *ngTemplateOutlet="collaborators"></ng-container>
        </ng-container>
        <ng-container *ngIf="currentTab === tabs.INFO">
          <ng-container *ngTemplateOutlet="info"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="left">
      <button nz-button routerLink="/validator">
        <span nz-icon>
          <i class="fal fa-home"></i>
        </span>
        <span class="hide-on-mobile">{{
          'XML_SCREEN.BUTTONS.BACK' | translate
        }}</span>
      </button>
    </div>
    <div class="right">
      <nz-space>
        <button nz-button *nzSpaceItem (click)="downloadFile()">
          <span nz-icon>
            <i class="fal fa-cloud-download"></i>
          </span>
          <span class="hide-on-mobile">{{
            'XML_SCREEN.BUTTONS.DOWNLOAD' | translate
          }}</span>
        </button>
        <button
          nz-button
          *nzSpaceItem
          nzType="primary"
          routerLink="/validator/xml/document/signatures"
        >
          <span nz-icon>
            <i class="fal fa-pen-fancy"></i>
          </span>
          <span class="hide-on-mobile">{{
            'XML_SCREEN.BUTTONS.SIGNATURES' | translate
          }}</span>
        </button>
      </nz-space>
    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="null"
  [nzFooter]="null"
  [nzClosable]="false"
  [nzCentered]="true"
  [nzWidth]="800"
>
  <ng-template #successIcon>
    <span nz-icon>
      <i class="fal fa-check-circle"></i>
    </span>
  </ng-template>
  <div *nzModalContent>
    <nz-result
      [nzIcon]="successIcon"
      nzStatus="success"
      [nzTitle]="'XML_SCREEN.MODALS.SUCCESS.TITLE' | translate"
      [nzSubTitle]="
        'XML_SCREEN.MODALS.SUCCESS.DESCRIPTION'
          | translate : { filename: document.name }
      "
    >
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="isVisible = false">
          {{ 'XML_SCREEN.MODALS.AWARE_BUTTON' | translate }}
        </button>
      </div>
    </nz-result>
  </div>
</nz-modal>

<ng-template #files>
  <div class="title">{{ 'XML_SCREEN.TABS.ATTACHMENTS.TITLE' | translate }}</div>
  <div class="content">
    <div class="blocks">
      <div class="attachment-block">
        <div class="ab-title">
          <div class="title-wrapper">
            {{ 'XML_SCREEN.TABS.ATTACHMENTS.MAIN_ATTACHMENT' | translate }}
          </div>
        </div>
        <div class="ab-body">
          <div
            class="ab-box"
            [class.current]="mainAttachment?.id === currentAttachment?.id"
          >
            <div
              class="ab-box-label"
              (click)="viewVerifiedAttachment(mainAttachment)"
            >
              <span nz-icon>
                <i class="far fa-file-pdf"></i>
              </span>
              {{ mainAttachment?.name }}
            </div>
            <div
              class="ab-box-action"
              [class.verified]="mainAttachment?.verified"
            >
              <span nz-icon>
                <i class="fal fa-check-circle"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="attachment-block attachments">
        <div class="ab-title">
          <div class="title-wrapper">
            {{ 'XML_SCREEN.TABS.ATTACHMENTS.ATTACHMENTS' | translate }}
          </div>
        </div>
        <div class="ab-body">
          <div
            class="ab-box"
            *ngFor="let attachment of attachments"
            [class.current]="attachment?.id === currentAttachment?.id"
          >
            <div
              class="ab-box-label"
              [class.clickable]="attachment.verified"
              (click)="viewVerifiedAttachment(attachment)"
            >
              <span nz-icon>
                <i class="far fa-file-pdf"></i>
              </span>
              {{ attachment?.name }}
            </div>
            <div class="ab-box-action" [class.verified]="attachment?.verified">
              <i
                class="fal fa-info-circle"
                *ngIf="!attachment?.verified"
                nz-tooltip
                [nzTooltipTitle]="
                  'XML_SCREEN.TOOLTIPS.UNVERIFIED_MESSAGE' | translate
                "
                [nzTooltipPlacement]="'top'"
              ></i>
              <i class="fal fa-check-circle" *ngIf="attachment?.verified"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #collaborators>
  <div class="title">
    {{ 'XML_SCREEN.TABS.COLLABORATORS.TITLE' | translate }}
  </div>
  <div class="content">
    <literax-xml-validation-participants></literax-xml-validation-participants>
  </div>
</ng-template>

<ng-template #info>
  <div class="title">{{ 'XML_SCREEN.TABS.INFO.TITLE' | translate }}</div>
  <div class="content">
    <literax-xml-validation-info></literax-xml-validation-info>
  </div>
</ng-template>
