<div class="headers">
  <div
    *ngFor="let item of columns; let i = index"
    class="title"
    [ngStyle]="item?.style"
    (click)="onSortChange($event, item, i)"
    [ngClass]="{ pointer: item.sortColumn }"
  >
    {{ item.label | translate }}
    <div *ngIf="item.sortColumn" class="sortIcon pl-2">
      <div class="sortUp">
        <i
          class="fal fa-sort-up"
          [ngClass]="{ fas: item?.sort?.active && item?.sort?.dir === 'asc' }"
        ></i>
      </div>
      <div class="sortDown">
        <i
          class="fal fa-sort-down"
          [ngClass]="{ fas: item?.sort?.active && item?.sort?.dir === 'desc' }"
        ></i>
      </div>
      <div
        class="sort-order"
        *ngIf="activeSortsCount > 1 && item?.sort?.active"
      >
        {{ item?.sort?.order }}
      </div>
    </div>
  </div>
</div>
<literax-virtual-scroll
  [list]="list"
  [height]="height"
  [itemRowSize]="46"
  [rowTem]="rowTemplate"
  (nextBatchEmitter)="nexBatch.emit($event)"
>
</literax-virtual-scroll>
