<ng-container *ngIf="!detailPromissory">
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.DOCUMENT' | translate
    }}</span>
    <span class="info">{{ document.name }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PUBLICATION-DATE' | translate
    }}</span>
    <span class="info">{{ document.created_at | date: 'dd/MM/yyyy' }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.AUTHOR' | translate
    }}</span>
    <span class="info">{{ document.user.name }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.PROCESS' | translate
    }}</span>
    <span class="info">{{ document.status_name }}</span>
  </div>
  <div *ngIf="document.rule" class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.RULE' | translate
    }}</span>
    <span class="info">{{ document.rule }}</span>
  </div>
  <div *ngIf="document.expiry" class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.DEADLINE' | translate
    }}</span>
    <span class="info">{{ document.expiry | date: 'dd/MM/yyyy' }}</span>
  </div>
  <div class="info-item">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.MAIN-DOCUMENT' | translate
    }}</span>
    <span class="info">{{ document.name }}</span>
  </div>
  <div
    *ngIf="attachmentNames && document?.attachments.length > 1"
    class="info-item"
  >
    <span class="label"
      >{{ 'DOCUMENT-PREVIEW.INFORMATION.FORM.ATTACHMENTS' | translate }}
      {{ '(' }} {{ attachmentCount }} {{ ')' }}</span
    >
    <span class="info"> {{ attachmentNames }}</span>
  </div>
  <div class="info-item" *ngIf="document?.transaction_type === 'request'">
    <span class="label">{{
      'DOCUMENT-PREVIEW.INFORMATION.FORM.COMMITMENT_DATE' | translate
    }}</span>

    <span *ngIf="!isDateVisible" class="info">{{
      delivery_date | date: 'dd/MM/yyyy'
    }}</span>
    <span *ngIf="isDateVisible" class="info"> ... </span>
  </div>
</ng-container>
<ng-container *ngIf="detailPromissory">
  <div class="info-item">
    <span>{{ 'DOCUMENT-PREVIEW.INFORMATION.FORM.DOCUMENT' | translate }}</span>
    <span>{{ document.name }}</span>
  </div>
  <div class="info-item">
    <span>
      {{
        'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.BENEFICIARY' | translate
      }}
    </span>
    <span>{{ beneficiaryName }}</span>
  </div>
  <div class="info-item">
    <span>
      {{
        'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.SUBSCRIBER' | translate
      }}
    </span>
    <span>{{ subscriberName }}</span>
  </div>
  <div class="info-item" *ngFor="let guarantor of arrayGuarantor">
    <span>
      {{ 'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.GUARANTEE' | translate }}
    </span>
    <span>{{ guarantor.name }}</span>
  </div>
  <div class="info-item">
    <span>
      {{ 'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.AMOUNT' | translate }}
    </span>
    <span>
      {{ balanceAmount | currency: promissoryNoteCicle.currency }}
    </span>
  </div>
  <div class="info-item">
    <span>
      {{ 'DOCUMENT-PREVIEW.INFORMATION.FORM.PROMISSORY.STATUS' | translate }}
    </span>
    <span class="info">
      <nz-badge nzColor="{{ promissoryNoteCicle?.main_document.status.color }}">
      </nz-badge>
      {{ document.status_name | translate }}
    </span>
  </div>
</ng-container>
<ng-container *ngIf="promissoryNoteCicle?.main_document.attachments.length > 1">
  <div
    class="info-item"
    *ngFor="let attachment of promissoryNoteCicle?.main_document.attachments"
  >
    <span>
      {{ 'DOCUMENT-PREVIEW.INFORMATION.FORM.ATTACHMENTS' | translate }}
    </span>
    <span *ngIf="!attachment.primary">{{ attachment.name }}</span>
  </div>
</ng-container>
