import { EDeviceType } from '@literax/enums/application.enum';
export interface ICoreState {
  isDesktop: boolean;
  deviceType: EDeviceType;
}

export const initialAplicationState: ICoreState = {
  isDesktop: true,
  deviceType: EDeviceType.DESKTOP,
};
