<nz-result
    [nzStatus]="isFailed ? 'error' : 'success'"
    [nzIcon]="isFailed ? errorIcon : successIcon"
    [nzTitle]="(isFailed ? 'EXPIRED_LINK.MODAL.TITLE_ERROR' : 'EXPIRED_LINK.MODAL.TITLE_SUCCESS') | translate"
    [nzSubTitle]="(isFailed ? 
        'EXPIRED_LINK.MODAL.SUBTITLE_ERROR' : 
        'EXPIRED_LINK.MODAL.SUBTITLE_SUCCESS') | translate"
>
    <div nz-result-extra>
        <button nz-button nzType="primary" (click)="closeModal()">
            {{ 'EXPIRED_LINK.MODAL.BUTTON'  | translate }}
        </button>
    </div>
</nz-result>
<ng-template #successIcon>
    <i class="fal fa-check-circle fa-5x"></i>
</ng-template>
<ng-template #errorIcon>
    <i class="fal fa-times-circle fa-5x"></i>
</ng-template>