<div class="">
  <p class="gray-color">
    {{ 'MODULES.WORKSPACE.MODALS.MESSAGES.SEND_SIGNED_DOCUMENT.FORM.INSTRUCTIONS' | translate }}
  </p>
  <nz-collapse>
    <nz-collapse-panel
      *ngIf="signers.length > 0"
      [nzHeader]="'MODULES.WORKSPACE.MODALS.MESSAGES.SEND_SIGNED_DOCUMENT.FORM.LABEL_SIGNERS' | translate"
      [nzActive]="true"
    >
      <label
        *ngFor="let signer of signers"
        nz-checkbox
        [(ngModel)]="collaboratorsModel[signer.id]"
        (ngModelChange)="addRemoveCollaborator(signer.id, $event)"
        >{{ signer.name }}</label
      >
    </nz-collapse-panel>
    <nz-collapse-panel *ngIf="observers.length > 0" [nzHeader]="'MODULES.WORKSPACE.MODALS.MESSAGES.SEND_SIGNED_DOCUMENT.FORM.LABEL_OBSERVERS' | translate">
      <label *ngFor="let observer of observers" nz-checkbox>{{
        observer.name
      }}</label>
    </nz-collapse-panel>
  </nz-collapse>
  <br />
  <label
    [(ngModel)]="selectAllCheck"
    nz-checkbox
    (ngModelChange)="selectAll($event)"
    >{{ 'MODULES.WORKSPACE.MODALS.MESSAGES.SEND_SIGNED_DOCUMENT.FORM.LABEL_SELECT_ALL' | translate }}</label
  >
</div>
