import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { IFlow } from '@literax/models/flow.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-processes-tab-bar',
  templateUrl: './processes-tab-bar.component.html',
  styleUrls: ['./processes-tab-bar.component.scss'],
})
export class ProcessesTabBarComponent implements OnInit, AfterViewChecked {
  @Input() isPagare?: boolean = false;
  @Input() isPromissoryNoteTemplate?: boolean = false;

  private _flowKey: string;
  @Input() set tabsList(tabs: IFlow[]) {
    this.tabs = tabs;
    this.updateFlowKey();
  }
  @Input() set flowKey(flowKey: string) {
    this._flowKey = flowKey;
    this.updateFlowKey();
  }
  get flowKey(): string {
    return this._flowKey;
  }

  @Input() permissions: string[];

  @Output() tabChanged = new EventEmitter();
  activeTab = 0;
  tabs = [];

  updateFlowKey() {
    setTimeout(() => {
      if (
        Array.isArray(this.tabs) &&
        this.tabs.length &&
        this.flowKey &&
        this.flowKey !== ''
      ) {
        const i = this.tabs.findIndex((v) => v.flow_key === this.flowKey);
        this.activeTab = i > 0 ? i : 0;
      }
    });
  }

  ngAfterViewChecked(): void {}

  ngOnInit() {
    this.updateFlowKey();
  }

  onTabClicked(index: number, flowKey: IFlow) {
    this.activeTab = index;
    this.tabChanged.emit(flowKey);
  }

  constructor() {}
}
