export interface IStatus {
  id: number;
  name: string;
  key: string;
  color: string;
}

export interface IStatusState {
  statuses: IStatus[];
}

export const initialStatusState: IStatusState = {
  statuses: []
};
