import { Component, Input, OnInit } from '@angular/core';
import { ESigningMethod } from '@literax/enums/document.enum';

import { EIdentityValidationType } from '@literax/enums/official-identification.enum';
import { IViewSignRequest } from '@literax/models/document.model';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  @Input() digital: boolean;
  @Input() viewSignature: IViewSignRequest; //v3
  @Input() document;

  signingMethods = ESigningMethod;
  isVisible = false;
  isHaveIdRequest= true;

  public get identityValidationType(): typeof EIdentityValidationType {
    return EIdentityValidationType;
  }

  constructor() {}

  ngOnInit() {
    if(this.viewSignature.idDocumentRequestInfo === null || this.viewSignature.idDocumentRequestInfo.idImageBase64 === null) {
      this.isHaveIdRequest = false;
    }
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
}
