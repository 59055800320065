<nz-header
  [ngStyle]="{
    background: lookFeel?.webNavigationColor
      ? lookFeel?.webNavigationColor
      : '#434343'
  }"
>
  <div
    class="menu-btn-container"
    [ngStyle]="{
      background: lookFeel?.webEmphasisColor
        ? lookFeel?.webEmphasisColor
        : '#b80027'
    }"
  >
    <div
      (click)="changeCollapse()"
      class="threebar"
      [class.hamburger]="isCollapsed"
      [class.arrow]="!isCollapsed"
    >
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>

  <img
    *ngIf="lookFeel?.webLogo64 || logoUrl"
    class="logo"
    [src]="lookFeel?.webLogo64 || logoUrl"
    alt="Company logo"
  />
  <div
    class="toolbar-container"
    [ngStyle]="{
      background: lookFeel?.webEmphasisColor
        ? lookFeel?.webEmphasisColor
        : '#001529'
    }"
  >
    <div class="tool-bar-icons-container">
      <span
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="search"
        nz-icon
        nzType="search"
        nzTheme="outline"
        class="icon"
      ></span>
      <nz-dropdown-menu #search="nzDropdownMenu">
        <div class="search-box">
          <nz-input-group [nzSuffix]="suffixIconSearch">
            <input
              placeholder="{{ 'DASHBOARD.NAVBAR.SEARCH' | translate }}"
              nz-input
              [nzAutocomplete]="auto"
              [formControl]="myControl"
              aria-label="Number"
              type="text"
              (blur)="clearSearch()"
            />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>

          <nz-autocomplete #auto>
            <nz-auto-option
              *ngFor="let option of filteredOptions | async"
              [nzLabel]="option.name"
              [nzValue]="option"
              (click)="sendToDocument(option)"
            >
              <div class="search-document-row">
                <span
                  class="status-color"
                  [ngStyle]="{ 'background-color': option.status_color }"
                ></span>
                <span class="document-name">{{ option.name }}</span>
              </div>
            </nz-auto-option>

          </nz-autocomplete>
        </div>
      </nz-dropdown-menu>

      <span
        nz-icon
        nzType="question-circle"
        nzTheme="outline"
        class="icon"
        [routerLink]="menuItems.bottom[3].path"
        *ngIf="menuItems && menuItems.bottom[3].display"
        [title]="menuItems.bottom[3].header | translate"
      ></span>
      <span
        nz-icon
        nzType="global"
        nzTheme="outline"
        class="icon"
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="lang"
      ></span>
      <nz-dropdown-menu #lang="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="setLang('es')">
            <span>Español</span>
          </li>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="setLang('en')">
            <span>English</span>
          </li>
        </ul>
      </nz-dropdown-menu>

      <span
        nz-icon
        nzType="bell"
        nzTheme="outline"
        class="icon"
        *ngIf="false"
      ></span>

      <div>
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
          <div class="user-dropdown">
            <nz-avatar
              [nzText]="currentClient?.name | nameInitials"
              nzSrc="{{ _profileImage }}"
              style="background-color: #b5f5ec; color: #13c2c2"
            ></nz-avatar>
            <div class="user">
              <span class="name">{{ currentClient?.name }}</span>
            </div>
            <i nz-icon nzType="down" class="icon-down"></i>
          </div>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li
              nz-menu-item
              *ngFor="let client of clients"
              (click)="change($event, client)"
            >
              <nz-avatar
                [nzText]="client?.name | nameInitials"
                style="background-color: #b5f5ec; color: #13c2c2"
              ></nz-avatar>
              <span>{{ client.name }}</span>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item (debounceClick)="logout()">
              {{ 'SPA.NAV.LOGOUT' | translate }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</nz-header>

<nz-layout>
  <nz-sider
    [(nzCollapsed)]="isCollapsed"
    [nzCollapsedWidth]="48"
    nzBreakpoint="xxl"
    *ngIf="!menuItems"
  ></nz-sider>
  <nz-sider
    [(nzCollapsed)]="isCollapsed"
    [nzCollapsedWidth]="48"
    nzBreakpoint="xxl"
    *ngIf="menuItems"
  >
    <ul nz-menu nzTheme="light" nzMode="inline">
      <!-- Home -->
      <li
        *ngIf="menuItems.top[0].display"
        nz-menu-item
        routerLinkActive="active"
        [routerLink]="menuItems.top[0].path"
      >
        <i class="fal fa-home-alt fa-lg"></i>
        <span>{{ menuItems.top[0].header | translate }}</span>
      </li>

      <!-- Operation -->
      <li
        nz-submenu
        [nzTitle]="operationTemplate"
        *ngIf="menuItems.top[1].display"
      >
        <ul>
          <li
            nz-menu-item
            *ngFor="let subMenuItem of menuItems.top[1].children"
            routerLinkActive="active"
            [routerLink]="subMenuItem.path"
            [hidden]="!subMenuItem.display"
          >
            <i class="{{ subMenuItem.icon }} fa-icon-size mr-3"></i>
            <span>{{ subMenuItem.label | translate }}</span>
          </li>
        </ul>
      </li>
      <ng-template #operationTemplate>
        <i class="fal fa-tasks-alt fa-lg"></i>
        <span>{{ menuItems.top[1].header | translate }}</span>
      </ng-template>

      <!-- File -->
      <li
        nz-menu-item
        routerLinkActive="active"
        [routerLink]="menuItems.top[2].path"
        *ngIf="menuItems.top[2].display"
      >
        <i class="fal fa-folder fa-lg"></i>
        <span>{{ menuItems.top[2].header | translate }}</span>
      </li>

      <!-- Contact -->
      <li
        nz-menu-item
        routerLinkActive="active"
        [routerLink]="menuItems.top[3].path"
        *ngIf="menuItems.top[3].display"
      >
        <i class="fal fa-address-book fa-lg"></i>
        <span>{{ menuItems.top[3].header | translate }}</span>
      </li>

      <!-- Certificate -->
      <li
        nz-menu-item
        routerLinkActive="active"
        [routerLink]="menuItems.top[4].path"
        *ngIf="menuItems.top[4].display"
      >
        <i nz-icon nzType="folder"></i>
        <span>{{ menuItems.top[4].header | translate }}</span>
      </li>

      <li class="spacer"></li>

      <!-- Template -->
      <li
        nz-submenu
        [nzTitle]="templateTemplate"
        *ngIf="menuItems.bottom[0].display"
      >
        <ul>
          <li
            nz-menu-item
            *ngFor="let subMenuItem of menuItems.bottom[0].children"
            routerLinkActive="active"
            [routerLink]="subMenuItem.path"
            [hidden]="!subMenuItem.display"
          >
            <i class="{{ subMenuItem.icon }} fa-icon-size mr-3"></i>
            <span>{{ subMenuItem.label | translate }}</span>
          </li>
        </ul>
      </li>
      <ng-template #templateTemplate>
        <i class="fal fa-books fa-lg"></i>
        <span>{{ menuItems.bottom[0].header | translate }}</span>
      </ng-template>

      <!-- Reporting -->
      <li nz-submenu [nzTitle]="reportTemplate">
        <ul>
          <li
            nz-menu-item
            *ngFor="let subMenuItem of menuItems.bottom[1].children"
            [routerLink]="subMenuItem.path"
            routerLinkActive="active"
            [hidden]="!subMenuItem.display"
          >
            <i class="{{ subMenuItem.icon }} fa-icon-size mr-3"></i>
            <span>{{ subMenuItem.label | translate }}</span>
          </li>
        </ul>
      </li>
      <ng-template #reportTemplate>
        <i class="fal fa-chart-area fa-lg"></i>
        <span>{{ menuItems.bottom[1].header | translate }}</span>
      </ng-template>

      <!-- Configuration -->
      <li
        nz-menu-item
        routerLinkActive="active"
        [routerLink]="menuItems.bottom[2].path"
        *ngIf="menuItems.bottom[2].display"
      >
        <i class="fal fa-cog fa-lg"></i>
        <span>{{ menuItems.bottom[2].header | translate }}</span>
      </li>

      <!-- help center -->
      <li
        nz-menu-item
        routerLinkActive="active"
        [routerLink]="menuItems.bottom[3].path"
        *ngIf="menuItems.bottom[3].display"
      >
        <i class="fal fa-question-circle fa-lg"></i>
        <span>{{ menuItems.bottom[3].header | translate }}</span>
      </li>
    </ul>
  </nz-sider>
  <nz-content>
    <div class="container-fluid max-vh">
      <div
        class="disabled-content"
        *ngIf="!isCollapsed && isMovileDevice"
        (click)="changeCollapse()"
      ></div>
      <ng-content></ng-content>
    </div>
  </nz-content>
</nz-layout>
