import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { SharedModule } from '@literax/modules/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { ValidatorContainerComponent } from './components/validator-container/validator-container.component';
import { ValidatorComponent } from './validator/validator.component';
import { ValidatorRoutingModule } from './validator-routing.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { XmlValidationComponent } from './components/xml-validation/xml-validation.component';
import { UuidValidationComponent } from './components/uuid-validation/uuid-validation.component';
import { RECAPTCHA_LANGUAGE, RecaptchaModule } from 'ng-recaptcha';
import { XmlValidationDocumentsComponent } from './components/xml-validation-documents/xml-validation-documents.component';
import { ShowSignaturesComponent } from './components/show-signatures/show-signatures.component';
import { ValidatorService } from './validator.service';
import { ValidatorEffects } from './store/validator.effects';
import { XmlValidationInfoComponent } from './components/xml-validation-documents/xml-validation-info/xml-validation-info.component';
import { XmlValidationParticipantsComponent } from './components/xml-validation-documents/xml-validation-participants/xml-validation-participants.component';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es-MX';
import { UuidValidationDocumentComponent } from './components/uuid-validation-document/uuid-validation-document.component';
import { ValidatorSignersComponent } from './components/validator-signers/validator-signers.component';
import { AuthGuard } from './auth.guard';

registerLocaleData(es);
class ValidatorLangLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
    return this.http.get('/assets/i18n/validator/validator_' + lang + '.json');
  }
}

@NgModule({
  imports: [
    SharedModule,
    EffectsModule.forFeature([ValidatorEffects]),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: ValidatorLangLoader,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    RecaptchaModule,
    ValidatorRoutingModule,
  ],
  declarations: [
    ValidatorComponent,
    ValidatorContainerComponent,
    UuidValidationComponent,
    UuidValidationDocumentComponent,
    ValidatorSignersComponent,
    XmlValidationComponent,
    XmlValidationDocumentsComponent,
    ShowSignaturesComponent,
    XmlValidationInfoComponent,
    XmlValidationParticipantsComponent,
  ],
  providers: [
    ValidatorService,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValidatorModule {
  constructor(private translate: TranslateService) {
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }
}
