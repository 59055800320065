import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { IAPISignRequestMessageConfig } from '@literax/models/http/api/sign-request/sign-request.model';
import { ILinkValidateResponse } from '@literax/models/document.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuestService extends BaseService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * It takes a token as a parameter, and returns an Observable of type ILinkValidateResponse
   * @param {string} token - The token that was generated by the backend.
   * @returns Observable<ILinkValidateResponse>
   */
  public validateLink(token: string): Observable<ILinkValidateResponse> {
    return this.get('/guests/visit_document', token);
  }

  /**
   * This function updates the link to the document to visited
   * @param {boolean} visited - boolean - this is the value that will be sent to the API.
   * @param {string} token - The token that was returned from the login call.
   * @returns An observable of any type.
   */
  public updateLink(visited: boolean, token: string): Observable<any> {
    return this.put('/guests/visit_document', { visited }, true, {
      headers: this.createHeader(token),
    });
  }

  /**
   * This function sends a new link to the signer
   * @param {number} documentId - The ID of the document you want to send a link for.
   * @param {IAPISignRequestMessageConfig[]} params - IAPISignRequestMessageConfig[]
   * @param {string} token - The token you get from the login API
   * @returns Observable<any>
   */
  public sendNewLink(
    documentId: number,
    signRequestId: number,
    token: string
  ): Observable<any> {
    return this.post(
      `/documents/${documentId}/signrequests/${signRequestId}/sendemail`,
      null,
      true,
      { headers: this.createHeader(token) }
    );
  }
}
