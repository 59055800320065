import { createAction, props } from '@ngrx/store';

import { IStatus } from './status.state';

export const getStatuses = createAction(
  '[Status] GetStatuses',
  props<{ payload: { type: string; sort: string } }>()
);

export const getStatusesSuccess = createAction(
  '[Status] GetStatusesSuccess',
  props<{ payload: IStatus[] }>()
);
