import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class AuthInterceptor implements HttpInterceptor {
  private urlsToNotUse: string[] = [];
  private isProissoryNote: boolean = false;
  constructor(
    private authService: B2CAuthService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    if (
      this.route.snapshot['_routerState'].url &&
      this.route.snapshot['_routerState'].url === '/auth/onboarding'
    ) {
      this.urlsToNotUse = ['plans', 'onboardings', '/users'];
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.route.snapshot['_routerState'].url &&
      this.route.snapshot['_routerState'].url === '/auth/onboarding'
    ) {
      this.urlsToNotUse = ['plans', 'onboardings', '/users'];
    }
    if (this.isValidRequestForInterceptor(req.url)) {
      const isLoggedIn = this.authService.isLoggedIn();
      if (
        isLoggedIn &&
        req.url.indexOf(
          'https://gdx-cdn.azurewebsites.net/ecosystem/platforms/Literax'
        ) === -1
      ) {
        this.isProissoryNote =
          req?.body?.attachment?.kind === 'promissory_note';
        const headers = this.createHeader();
        req = req.clone({
          setHeaders: headers,
        });
      }
    }
    return next.handle(req);
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = 'api/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substr(
        position + positionIndicator.length
      );
      for (let address of this.urlsToNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }

  createHeader(extraHeaders?: object): any {
    let headers: { [key: string]: string } = {
      ...extraHeaders,
    };
    if (this.isProissoryNote) {
      headers = {
        'Content-Type': 'application/json',
        ...extraHeaders,
      };
    }
    const storedHeaders = {
      company: localStorage.getItem('company'),
      locale:
        localStorage.getItem('locale') ||
        JSON.parse(localStorage.getItem('config')).lang,
    };
    Object.entries(storedHeaders).some(([key, val]) => {
      if (val && val !== 'null') {
        headers[key] = val;
      }
    });
    return headers;
  }
}
