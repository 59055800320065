import { createAction, props } from '@ngrx/store';

import { IApiDetailedError } from '@literax/models/common';
import { IDashboard } from '@literax/models/dashboard.model';

export const getDashboard = createAction('[Dashboard] Get Dashboard');

export const getDashboardSuccess = createAction(
  '[Dashboard] Get Dashboard Success',
  props<{ payload: IDashboard }>()
);

export const getDashboardError = createAction(
  '[Dashboard] Get Dashboard Error',
  props<{ payload: { error: IApiDetailedError | string } }>()
);
