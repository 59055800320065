import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EDocumentStatus } from '@literax/enums/document.enum';
import { ILastEmailEvent } from '@literax/models/http/api/sign-request/last-email-event';
import {
  ILegalPerson,
  INaturalPerson,
} from '@literax/models/participant.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { HistoryComponent } from '@literax/components/platform/workspace/history/history.component';
import { WorkspaceActions } from '@literax/modules/documents/store/workspace/workspace.actions';
import { select, Store } from '@ngrx/store';
import { IAppState } from '@literax/store';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  emailDelivered: boolean;
  lastEventEmail: ILastEmailEvent;
  screenHeight: any;
  documentStatus = EDocumentStatus;

  isDisabled: boolean;

  @Input() signer: ILegalPerson | INaturalPerson;
  @Output() historyButton = new EventEmitter();
  @Output() actionButton = new EventEmitter();

  @Input() emailStatusName: string;

  @Input() set last_event_email(last_event_email: ILastEmailEvent) {
    this.lastEventEmail = last_event_email;
    this.emailDelivered =
      ['delivered', 'openend', 'clicked'].indexOf(this.emailStatusName) >= 0;
  }

  histoyIcons = {
    processed: 'fal fa-check neutro fa-stack-1x',
    dropped: 'fal fa-exclamation-triangle fa-stack-1x',
    deferred: 'fal fa-exclamation-triangle fa-stack-1x',
    bounce: 'fal fa-exclamation-triangle fa-stack-1x',
    delivered: 'fal fa-check neutro fa-stack-1x',
    opened: 'fal fa-check green fa-stack-1x',
    clicked: 'fal fa-check green fa-stack-1x',
    unsubscribed: 'fal fa-exclamation-triangle fa-stack-1x', //no implementado
    spam_report: 'fal fa-exclamation-triangle fa-stack-1x',
  };

  secondHistoyIcons = {
    processed: 'fa fa-circle neutro fa-stack-2x',
    dropped: 'fa fa-circle yellow fa-stack-2x',
    deferred: 'fa fa-circle yellow fa-stack-2x',
    bounce: 'fa fa-circle yellow fa-stack-2x',
    delivered: 'fa fa-circle neutro fa-stack-2x',
    opened: 'fa fa-circle green fa-stack-2x',
    clicked: 'fa fa-circle green fa-stack-2x',
    unsubscribed: 'fa fa-circle yellow fa-stack-2x', //no implementado
    spam_report: 'fa fa-circle yellow fa-stack-2x',
  };

  @Input() statusDocument = '';

  constructor(
    private modalService: NzModalService,
    private translate: TranslateService,
    private store: Store<IAppState>
  ) {}

  ngOnInit() {
    this.isDisabled = this.signer.status?.key === EDocumentStatus.SIGNED;
    this.store
      .pipe(select(WorkspaceSelectors.selectDocumentStatus))
      .subscribe((status) => (this.statusDocument = status?.key));
  }

  actionClicked(event: Event) {
    if (!this.isDisabled) {
      this.store.dispatch(
        WorkspaceActions.sendSignerEmail({
          payload: {
            signerId: this.signer.signRequestId,
            name: this.signer.name,
          },
        })
      );
    }
  }

  showHistory() {
    this.translate
      .get('DOCUMENTS.SIGNERS.HISTORY.DIALOG_TITTLE')
      .subscribe((nzTitle) => {
        this.modalService.create({
          nzMask: false,
          nzTitle,
          nzContent: HistoryComponent,
          nzWidth: 450,
          nzComponentParams: {
            signer: this.signer,
            statusDocument: this.statusDocument
          },
          nzOnCancel: () => {
            this.modalService.closeAll();
          },
        });
      });
  }
}
