import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-upload-button-control',
  templateUrl: './upload-button-control.component.html',
  styleUrls: ['./upload-button-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadButtonControlComponent),
      multi: true,
    },
  ],
})
export class UploadButtonControlComponent
  implements OnInit, ControlValueAccessor
{
  file: any;
  disabled: boolean;
  @Input() placeholder = 'FORM.FILE_INPUT.SELECT_BUTTON';
  @Input() returnAsBase64 = false;
  @Input() accept: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private onTouched = () => {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  private onChanged = (_: any) => {};

  constructor() {}

  writeValue(obj: any): void {
    if (obj) {
      this.file = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  removeFile(): void {
    this.file = null;
    this.onChanged(null);
  }

  onFileChange({ file, fileList }: NzUploadChangeParam): void {
    this.createObjectForFile(file);
  }

  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    this.createObjectForFile(file);
    return false;
  };

  createObjectForFile(file: any): void {
    if (this.returnAsBase64) {
      this.file = {
        file: '',
        base64: '',
      };
      this.convertFileToBase64(file)
        .pipe(filter((loadedFile) => !!loadedFile))
        .subscribe((responseFile) => {
          this.file.file = file;
          this.file.base64 = responseFile;
          this.onTouched();
          this.onChanged(this.file);
        });
    } else {
      this.file = file;
      this.onTouched();
      this.onChanged(this.file);
    }
  }

  convertFileToBase64(file: any): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        observer.next(reader.result.toString());
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }
}
