import { EClientConfigKind } from '@literax/enums/clients.enum';
import { IClientState } from './client.state';
import { createSelector } from '@ngrx/store';

const selectClients = (state) => state.clients;

export const selectClientList = createSelector(
  selectClients,
  (state: IClientState) => state.clients
);

export const selectCurrentClient = createSelector(
  selectClients,
  (state: IClientState) => state?.currentClient
);

export const selectCurrentClientKind = createSelector(
  selectClients,
  (state) => state?.currentClient?.kind
);

export const getClient = createSelector(
  selectClients,
  (state: IClientState) => state?.client
);

export const getSignatureConfigs = createSelector(
  selectClients,
  (state: IClientState) => state?.currentClient?.configurations
);

export const getClientConfigs = createSelector(
  selectClients,
  (state: IClientState) => state?.currentClient?.configurations
);

export const getClientId = createSelector(
  selectClients,
  (state: IClientState) => state?.currentClient?.id
);


export const getLegalRepresentatives = createSelector(
  selectClients,
  (state: IClientState) => state?.legalRepresentatives
);

export const isElectronicSignatureVisible = createSelector(
  selectClients,
  (state: IClientState) => {
    const config = state?.currentClient?.configurations.find(c => c.kind === EClientConfigKind.ELECTRONIC_SIGNATURE);
    if (config)
      return config.value as boolean;
    
    return false;
  }
);

export const isElectronicSignaturePlusVisible = createSelector(
  selectClients,
  (state: IClientState) => {
    const config = state?.currentClient?.configurations.find(c => c.kind === EClientConfigKind.ELECTRONIC_SIGNATURE_PLUS);
    if (config)
      return config.value as boolean;
    
    return false;
  }
);

export const isDigitalSignatureVisible = createSelector(
  selectClients,
  (state: IClientState) => {
    const config = state?.currentClient?.configurations.find(c => c.kind === EClientConfigKind.DIGITAL_SIGNATURE);
    if (config)
      return config.value as boolean;
    
    return false;
  }
);

export const getClientDelegate = createSelector(
  selectClients,
  (state: IClientState) => state?.clientDelegate
);

export const ClientsCurrentUserV3 = createSelector(
  selectClients,
  (state: IClientState) => state?.clients
);

export const CurrentClientV3 = createSelector(
  selectClients,
  (state: IClientState) => state?.currentClient
);