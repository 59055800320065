import {
  EAccumulatedStatesKeyPromissoryNote,
  ETypePromissoryNote,
  IFolder,
  IPromissoryNoteCountersResponse,
  IPromissoryNoteList,
  IPromissoryNoteTemplate,
  IPromissoryNotes,
  PromissoryNotePayment
} from '@literax/models/promissory-notes.model';
import { IQueryParametersPromissoryNote, IQueryParametersPromissoryNoteCounters } from '@literax/interfaces/query-parameters.interface';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IPagination } from '@literax/models/pagination.model';

export interface IPromissoryNotesState {
  promissoryNotes: IFolder[]; //!Deprecate: Usado en v2
  selectPromissoryNote: IFolder;
  currencys: [];
  payments: PromissoryNotePayment[];
  selectPayment: PromissoryNotePayment;
  configData: IPromissoryNotes;
  error: IAPIResponseError;
  currentPage: number;
  promissoryNoteTemplates: IPromissoryNoteTemplate[];
  promissoryNoteTemplateSelect: IPromissoryNoteTemplate;
  promissoryNoteWithData: IPromissoryNotes;
  templateId: number;
  pagination: IPagination;
  activeFilters: IFilterParams;
  //* Pagarés V3
  counterQueryParameters: IQueryParametersPromissoryNoteCounters;
  promissoryNoteStatusCounters: IPromissoryNoteCountersResponse;

  promissoryNoteType: ETypePromissoryNote;
  promissoryNoteStatus: number;
  promissoryNoteAccumulatedStatusKey: EAccumulatedStatesKeyPromissoryNote;

  queryParameters: IQueryParametersPromissoryNote;
  promissoryNoteList: IPromissoryNoteList;
}

export const initialPromissoryNotesState: IPromissoryNotesState = {
  promissoryNotes: [],
  selectPromissoryNote: null,
  currencys: [],
  payments: [],
  selectPayment: null,
  configData: null,
  error: null,
  currentPage: 1,
  templateId: 0,
  promissoryNoteTemplates: [],
  promissoryNoteTemplateSelect: null,
  promissoryNoteWithData: null,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    total_count: null,
    total_pages: null
  },
  activeFilters: null,
  //* Pagarés V3
  counterQueryParameters: {
    type: ETypePromissoryNote.AUTHOR,
    filterFields: []
  },
  promissoryNoteStatusCounters: null,

  promissoryNoteType: ETypePromissoryNote.AUTHOR,
  promissoryNoteStatus: 0,
  promissoryNoteAccumulatedStatusKey: EAccumulatedStatesKeyPromissoryNote.IN_EFFECT,
  
  queryParameters: null,
  promissoryNoteList: {
    promissoryNoteItems: [],
    pagination: {
      nextPage: false,
      prevPage: false,
      currentPage: 0,
      sizePage: 0,
      totalRecords: 0,
      pages: 0
    }
  },
};

export interface IFilterParams {
  name?: string;
}