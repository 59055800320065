<div *ngIf="!(loading$ | async)">
  <form class="mat-elevation-z6 mat-modal">
    <div class="finish-container">
      <div class="finish-content">
        <div class="finish-icon"><i class="fal fa-info-circle"></i></div>
        <h3>{{ 'NOT_HIRED.MESSAGE_DENIED_TITLE' | translate }}</h3>
        <h3 class="finish-message">
          {{ 'NOT_HIRED.MESSAGE_DENIED' | translate }}
        </h3>
        <div class="finish-home">
          <h4 class="finish-invitation">
            {{ 'NOT_HIRED.MESSAGE_INVITATION' | translate }}
          </h4>
          <button
            nz-button nzType="primary"
            class="finish-button"
            class="primary"
          >
            {{ 'NOT_HIRED.MESSAGE_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
