import { User } from '@literax/models/http/onboarding/user.model';
import { Department, Field } from '@literax/models/templates-fields.model';
import { IStatus as IStatusV2 } from '@literax/store/status/status.state';
export interface Template {
  template: ITemplates;
}

export enum statusTemplate {
  published = 'published',
  collection = 'data_collection',
  draft = 'draft',
  workInProgress = 'to_work_in_process',
  unpublished = 'unpublished',
  canEdit = 'work_in_progress',
  inProcess = 'in_process',
}
export interface ITemplateDepartmentAttributes {
  department_id: number;
}
export interface ITemplates {
  id?: number;
  name?: string;
  description?: string;
  form_builder_uid?: string;
  file_url?: string;
  file_pdf_url?: string;
  preview_url?: string;
  created_at?: string;
  id_subscriber?: string;
  updated_at?: string;
  user?: User;
  status?: IStatusV2;
  template_departments_attributes?: ITemplateDepartmentAttributes[];
  departments?: Department[];
  fields?: Field[];
  tags?: FieldsOnlyOffice[];
  template_fields?: TemplateField[];
  lock?: boolean;
  kind?: string;
  client_user?: User;
  profile_id?: number;
}
export interface FieldsOnlyOffice {
  id?: number;
  field_id: number;
  label: string;
  required: boolean;
  section: string;
  text: string;
  uuid: string;
}
export interface TemplateField {
  id: number;
  template_id: number;
  field_id: number;
  required: boolean;
  onlyoffice_uuid: null;
}

export interface ITemplateResponse {
  template: ITemplates;
  success: boolean;
}

export interface ITemplateKinds {
  document?: string;
  ticket?: string;
  form?: string;
}

export interface ITemplateProfile {
  id: number;
  name: string;
  template_uid: string;
  user_name?: string;
}

export interface ITemplateProfileList {
  profiles: ITemplateProfile[];
}

export interface IProfile {
  id?: number;
  name: string;
  description: string;
  departments?: number[];
  profile_departments_attributes?: any;
  template_uid?: string;
}

export const initialTemplateProfiles: ITemplateProfileList = {
  profiles: [],
};
export const initialITemplateKinds: ITemplateKinds = {
  document: '',
  ticket: '',
  form: '',
};

export class ITemplate implements Template {
  template: ITemplates;
  constructor(template: { [k: string]: any }) {
    if (!template.hasOwnProperty('name')) return;
    if (
      !template.hasOwnProperty('department') ||
      template.department.lenght == 0
    )
      return;
    this.template.template_departments_attributes = template.department.map(
      (tda) =>
        ({
          department_id: tda,
        } as ITemplateDepartmentAttributes)
    );
  }
}

export enum ETemplateTabs {
  document = 'document',
  ticket = 'ticket',
}

export enum ETransactionType {
  document = 'signature',
  ticket = 'request',
}

//V3
export interface IQueryParameters {
  pageNumber?: number;
  pageSize?: number;
  sortField?: ITemplateColumnSorting;
  filterFields?: ITemplateFilterField[];
}

export interface ITemplateColumnSorting {
  name: string;
  type: string;
}

export interface ITemplateFilterField {
  name: string;
  term: string;
  type: string;
  operator: string;
}

export interface ITemplate {
  templateId: number;
  name: string;
  kind: 0;
  kindName: string;
  description: string;
  createdAt: number;
  status: IStatus;
  canEdit: boolean;
  canDelete: boolean;
  canSee: boolean;
  canUnpublish: boolean;
  departments: string[];
  formBuilderUid: string;
  textEditorConfig: ITextEditorConfig;
}

export interface IStatus {
  id: number;
  name: string;
  key: string;
  color: string;
}

export interface ITextEditorConfig {
  subscriberId: number;
  key: string;
}

export interface ITemplatePayload{
  name: string;
  kind: number;
  departments: number[];
  receiverId?: number;
  profileId?: number,

}

export interface ITemplateRelationMetaDataPayload{
  fieldId: number,
  onlyofficeUuid: string,
  required: boolean
}

export interface ITemplateById{
  id: number;
  name: string;
  description: string;
  filePath: string;
  kind: number;
  textEditorConfig: ITextEditorConfig;
  next_actions: string[];
  fields: IFields[];
  author: IAuthor;
  status: IStatus;
  createdAt: number;
  flowName: string;
  departments: IDeparment[];
  signatureQuoteConfig:ISignatureQuoteConfig;
  ticketConfig: ITicketConfig; 
}

export interface IFields{
  templateFieldId: number;
  fieldId: number;
  required: boolean;
  section: string;
  label: string;
  description: string;
  text: string;
  uuid: string;
}
export interface IAuthor{
  userName: string;
  id: number;
  lastName: string;
  name: string;
  departmentName: string;
  departmentId: number
}
export interface IDeparment{
  templateDepartmentId: number;
  departmentId: number;
  name: string;
}
export interface ISignatureQuoteConfig{
  profileId: number;
  profileName: string;
}
export interface ITicketConfig{
  receiverId: number;
  receiverName: string;
}

export interface FieldsOnlyOfficeV3 {
  templateFieldId?: number;
  fieldId: number;
  label: string;
  required: boolean;
  section: string;
  text: string;
  uuid: string;
  description: string
}
//end v3