import {
  EDocumentRequest,
  ESignatureVerificationTypeId,
} from '@literax/enums/document.enum';

import { ColumnSort } from '@literax/models/table.model';
import { IFilterParams } from '@literax/interfaces/filter-param.interface';
import { ISort } from '@literax/interfaces/sort-descriptor.interface';
import { OptionSelect } from '@literax/models/options.model';
import { saveAs } from 'file-saver';
import { IDocFilterField } from '@literax/models/document.model';

export class AppUtils {
  static numerUno = 1;
  static downloadFile(textFile, nameModule) {
    const file = new File([textFile], this.generateNameFile(nameModule), {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(file);
  }

  static scan(str: string, regexp: any): string[] {
    if (!regexp.global) {
      throw new Error('RegExp without global (g) flag is not supported.');
    }

    let result: string[] = [];
    let m: string;

    while ((m = regexp.exec(str))) {
      if (m.length >= 2) {
        result.push(m.slice(1));
      } else {
        result.push(m[0]);
      }
    }
    return result;
  }

  static generateNameFile(nameModule: string): string {
    const fecha = new Date();
    const name =
      nameModule +
      fecha.getFullYear() +
      '_' +
      (fecha.getMonth() + 1) +
      '_' +
      fecha.getDate() +
      '.csv';
    return name;
  }

  static extensionFromMimeType(mimeType: string): string {
    let format = '';

    switch (mimeType) {
      case 'application/pdf':
        format = 'pdf';
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        format = 'pptx';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        format = 'xlsx';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        format = 'docx';
        break;
      case 'application/zip':
        format = 'zip';
        break;
      case 'image/png':
        format = 'png';
        break;
      case 'image/jpeg':
        format = 'jpg';
        break;

      default:
        format = 'none';
    }

    return format;
  }

  static UpdatePrimaryColor(color: string) {
    color = color ? color : '#1b3266';
    document.querySelector('html').style.setProperty('--primaryColor', color);
  }

  static emailPattern = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,63}){1,3})$/;

  static getMonthName(month: number): string {
    let monthName: string;
    switch (month) {
      case 1:
        monthName = 'MONTHS.JANUARY';
        break;
      case 2:
        monthName = 'MONTHS.FEBRUARY';
        break;
      case 3:
        monthName = 'MONTHS.MARCH';
        break;
      case 4:
        monthName = 'MONTHS.APRIL';
        break;
      case 5:
        monthName = 'MONTHS.MAY';
        break;
      case 6:
        monthName = 'MONTHS.JUNE';
        break;
      case 7:
        monthName = 'MONTHS.JULY';
        break;
      case 8:
        monthName = 'MONTHS.AUGUST';
        break;
      case 9:
        monthName = 'MONTHS.SEPTEMBER';
        break;
      case 10:
        monthName = 'MONTHS.OCTOBER';
        break;
      case 11:
        monthName = 'MONTHS.NOVEMBER';
        break;
      case 12:
        monthName = 'MONTHS.DECEMBER';
        break;
    }
    return monthName;
  }

  static getMessageMultipleSigners(key: any) {
    let messaje = '';
    switch (key) {
      case key['signature.sign_data']:
        messaje =
          key['signature.sign_data'].length > 2
            ? key['signature.sign_data'][1]
            : key['signature.sign_data'][0];
        break;
      case key['signature.id_image']:
        messaje =
          key['signature.id_image'].length > 2
            ? key['signature.id_image'][1]
            : key['signature.id_image'][0];
        break;
    }

    return messaje;
  }

  static parseFilterParams(filter: IFilterParams = {}): string {
    return Object.entries(filter)
      .filter(([key, value]) => value)
      .map(([key, value]) => `&filter_params[${key}]=${value}`)
      .join('');
  }

  static parseSortParams(sortDescriptor: ISort[] = []): string {
    return sortDescriptor
      .map((sort) => `&sort[${sort.field}]=${sort.dir}`)
      .join('');
  }

  static parseParams(filterParams: IDocFilterField[] = []) : string {
    const generalFilter: string[] =[];
    for(const key in filterParams){
      if(filterParams.hasOwnProperty(key)){
        const element = filterParams[key];
        generalFilter.push(`&filterFields[${key}][name]=${encodeURIComponent(element.name)}`);
        generalFilter.push(`&filterFields[${key}][term]=${encodeURIComponent(element.term)}`);
        generalFilter.push(`&filterFields[${key}][type]=${encodeURIComponent(element.type)}`);
        generalFilter.push(`&filterFields[${key}][operator]=${encodeURIComponent(element.operator)}`);
      }
    }
    return generalFilter.join('');
  }

  static parseColumnSortParams(sortDescriptor: ColumnSort[] = []): string {
    return sortDescriptor
      .map((sort) => `&sort[${sort.columnName}]=${sort.direction}`)
      .join('');
  }

  static regexRFC =
    '[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$';
  static regexName = '^[a-zA-z]* ?[a-zA-Z]*$';
  static login = false;

  static pad(n) {
    return n < 10 ? '0' + n : n;
  }

  static getValueOfObjectFromDotNotationString(
    obj: object,
    dotNotation: string
  ) {
    let keys = dotNotation.split('.');
    while (keys.length && (obj = obj[keys.shift()]));
    return obj;
  }
}

export function fileToDataUrl(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function removeDuplicates(array: any[], key: string) {
  return array.filter((obj, index, arr) => {
    return arr.map((mapObj) => mapObj[key]).indexOf(obj[key]) === index;
  });
}

export const stringToColor = (str: string): string => {
  if (!str) return '#ffffff';
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const ToUnixTimestamp = (date: Date): number => {
  return Math.round(date.getTime() / 1000);
};

export const UnixTimestapToDate = (timestamp: number): number => {
  const milliseconds = timestamp * 1000;
  return milliseconds;
};

export const expired = (date: number): boolean =>
  ToUnixTimestamp(new Date()) > date;

export const officialIdentificationOptions = (): OptionSelect[] => {
  return Object.keys(EDocumentRequest)
    .map((o) => Number(o))
    .filter((e) => !isNaN(e) && e !== EDocumentRequest.INE)
    .map((value) => ({
      value,
      label: `API.OFFICIAL_IDENTIFICATION.${EDocumentRequest[value]}`,
    }));
};

export const verificationTokenOptions = (): OptionSelect[] => {
  return Object.keys(ESignatureVerificationTypeId)
    .map((o) => Number(o))
    .filter((e) => !isNaN(e))
    .map((value) => ({
      value,
      label: `API.TOKE_RSA.${ESignatureVerificationTypeId[value]}`,
    }));
};
