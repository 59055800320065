import * as EPersonalizationActions from './personalization.actions';

import { Action, createReducer, on } from '@ngrx/store';
import { IPersonalizationConfigurations, initialPersonalizationConfigurationsState } from './personalization.state';

const reducer = createReducer(
  initialPersonalizationConfigurationsState,
  on(EPersonalizationActions.GetSuccess, (state, action) => ({
    ...state,
    emailConfig: action.emailConfig,
  })),
  on(EPersonalizationActions.PatchSuccess, (state, action) => ({
    ...state,
    emailConfig: action.emailConfig,
  })),
  on(EPersonalizationActions.PostSuccess, (state, action) => ({
    ...state,
    emailConfig: action.emailConfig,
  }))
);

export function personalizationConfigurationsReducer(
  state: IPersonalizationConfigurations,
  action: Action
) {
  return reducer(state, action);
}
