import { createAction, props } from '@ngrx/store';
import { ICompanyAdd, ICompanyList } from '@literax/models/company.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';

export enum ECompanyActions {
  CreateCompany = '[Company] Create company',
  CreateCompanySuccess = '[Company] Create company success',
  CreateCompanyError = '[Company] Create company error',

  GetCompanies = '[Company] Get companies',
  GetCompaniesSuccess = '[Company] Get companies success',
  GetCompaniesError = '[Company] Get companies error',

  DeleteCompany = '[Company] delete company',
  DeleteCompanySuccess = '[Company] delete company success',
  DeleteCompanyError = '[Company] delete company error'
}

export const CreateCompany = createAction(
  ECompanyActions.CreateCompany,
  props<{ payload: ICompanyAdd }>()
);

export const CreateCompanySuccess = createAction(
  ECompanyActions.CreateCompanySuccess,
  props<{ payload: ICompanyAdd }>()
);

export const CreateCompanyError = createAction(
  ECompanyActions.CreateCompanyError,
  props<{ payload: IAPIResponseError }>()
);

export const GetCompanies = createAction(ECompanyActions.GetCompanies);

export const GetCompaniesSuccess = createAction(
  ECompanyActions.GetCompaniesSuccess,
  props<{ payload: ICompanyList[] }>()
);

export const GetCompaniesError = createAction(
  ECompanyActions.GetCompaniesError,
  props<{ payload: IAPIResponseError }>()
);

export const DeleteCompany = createAction(
  ECompanyActions.DeleteCompany,
  props<{ payload: number }>()
);

export const DeleteCompanySuccess = createAction(
  ECompanyActions.DeleteCompanySuccess
);

export const DeleteCompanyError = createAction(
  ECompanyActions.DeleteCompanyError,
  props<{ payload: IAPIResponseError }>()
);
