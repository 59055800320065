import {BaseService} from '@literax/services/base-config/base.service';
import {HttpClient} from '@angular/common/http';
import {IProfile, IValidateRFC} from '../models/profiles';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceResponse} from '@literax/interfaces/service-response';
import {serialize} from '@literax/utils/json-to-form-data';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  constructor(private base: BaseService, private http: HttpClient) {}

  getAllProfiles(): Observable<ServiceResponse> {
    return this.base.get(`/users`);
  }

  update(user: IProfile): Observable<ServiceResponse> {
    return this.base.patch(`/users`, serialize({ user }));
  }

  getEmails(): Observable<ServiceResponse> {
    return this.base.get(`/user_emails`);
  }

  registerEmail(userEmail: {
    name: string;
    email: string;
  }): Observable<ServiceResponse> {
    return this.base.post(`/user_emails`, {
      user_email: userEmail
    });
  }

  deleteEmail(emailId: number): Observable<ServiceResponse> {
    return this.base.delete(`/user_emails/${emailId}`);
  }

  sendNotification(emailId: number): Observable<ServiceResponse> {
    return this.base.put(`/user_emails/${emailId}/send_notification`, {});
  }

  sendToValidateRFC(validateRFC: IValidateRFC): Observable<ServiceResponse> {
    return this.base.patch(`/users/tax_id_validation`, validateRFC);
  }
}
