<div
  class="select"
  (click)="toggleSelect()"
  [ngClass]="selectActive || hasValue ? 'select-active' : ''"
>
  {{ placeholder }}
</div>
<div class="options" *ngIf="selectActive">
  <label>{{ fromLabel }}:</label>
  <mat-form-field appearance="outline" class="form-field">
    <input
      matInput
      [matDatepicker]="fromDate"
      [formControl]="fromControl"
      autocomplete="off"
    />
    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
    <mat-datepicker #fromDate></mat-datepicker>
  </mat-form-field>
  <label>{{ toLabel }}:</label>
  <mat-form-field appearance="outline" class="form-field">
    <input
      matInput
      [matDatepicker]="toDate"
      [min]="toMinDate"
      [formControl]="toControl"
      autocomplete="off"
    />
    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
    <mat-datepicker #toDate></mat-datepicker>
  </mat-form-field>
</div>
