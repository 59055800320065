export enum ETypeFilterField {
    TEXT = 'text',
    NUMERIC = 'numeric',
    TIMESTAMP = 'timestamp'
}

export enum EOperatorFilterField {
    EQ = 'eq',
    ILI = 'ili',
    LT = 'lt',
    LTE = 'lte',
    GT = 'gt',
    GTE = 'gte'
}
