import { createAction, props } from '@ngrx/store';

import { IHeaderRSA, ITokenRSA, IFormRSA } from '../models/token-rsa.model';
import { IApiDetailedError } from '../../../../../models/common';

export enum ETokenRSAActions {
  Get = '[TokenRSA] Get',
  GetSuccess = '[TokenRSA] GetSuccess',
  GetError = '[TokenRSA] GetError',

  Put = '[TokenRSA] Put',
  PutSuccess = '[TokenRSA] PutSuccess',
  PutError = '[TokenRSA] PutError',
}

export const Get = createAction(
  ETokenRSAActions.Get,
  props<{ clientId: number }>()
);

export const GetSuccess = createAction(
  ETokenRSAActions.GetSuccess,
  props<{ tokenRSA: ITokenRSA }>()
);

export const GetError = createAction(
  ETokenRSAActions.GetError,
  props<{ payload: { error: IApiDetailedError } }>()
);

export const Put = createAction(
  ETokenRSAActions.Put,
  props<{ clientId: number; payload: ITokenRSA }>()
);

export const PutSuccess = createAction(
  ETokenRSAActions.PutSuccess,
  props<{ tokenRSA: ITokenRSA }>()
);

export const PutError = createAction(
  ETokenRSAActions.PutError,
  props<{ payload: { error: IApiDetailedError } }>()
);

export const SaveHeaders = createAction(
  '[TokenRSA] SaveHeaders',
  props<{ headers: IHeaderRSA[] }>()
);

export const SaveForms = createAction(
  '[TokenRSA] SaveForms',
  props<{ forms: IFormRSA[] }>()
);
