import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  finalize,
  map
} from 'rxjs/operators';

import { CurrentClientV3 } from '@literax/modules/configurations/store/client/client.selectors';
import { IAppState } from '@literax/store';
import { IClient } from '@literax/models/http/api/client/client.model';
import { Injectable } from '@angular/core';
import { LoadingService } from '@literax/services/loading/loading.service';
import { Router } from '@angular/router';
import { SetClientStrategy } from '@literax/enums/clients.enum';
import { SetCurrentClientToken } from '@literax/modules/auth/store/auth.actions';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class B2CAuthService {
  currentUser$ = this.store.pipe(select(CurrentClientV3));
  companyString = '';
  currentClient: Partial<IClient>;
  currentAzureAccount: AccountInfo;
  constructor(
    private msalService: MsalService,
    private store: Store<IAppState>,
    private loadingService: LoadingService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService
  ) {
    let currentClientStrategy = localStorage.getItem('setClientStrategy');

    this.currentUser$
    .pipe(
      filter((client) => !!client),
      distinctUntilKeyChanged('uuid')
    )
    .subscribe((currentClient) => {
      if (currentClientStrategy !== SetClientStrategy.FIRST_LOGIN) {
        const account = this.msalService.instance.getAllAccounts().pop();
        this.msalService.instance.setActiveAccount(account);
        if (account) {
          this.companyString = currentClient.taxId;
          this.currentClient = currentClient;
          localStorage.setItem('organization', currentClient.taxId);
          this.loadingService.show();
          this.getToken(true, currentClient.taxId)
          .pipe(finalize(() => this.loadingService.hide()))
          .subscribe((token) => {
            this.store.dispatch(
              SetCurrentClientToken({ payload: { token } })
            );
          });
        }
      }
      localStorage.setItem('setClientStrategy', SetClientStrategy.CLIENT_SELECTED);
      currentClientStrategy = localStorage.getItem('setClientStrategy');
    });

    this.msalBroadcastService.msalSubject$
      .toPromise()
      .then((msalEvent: EventMessage) => {
        if (msalEvent.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          this.msalService.instance.setActiveAccount(
            msalEvent.payload['account']
          );
          this.currentAzureAccount = msalEvent.payload['account'];
          const accountRoles =
            msalEvent.payload['account']['idTokenClaims']['Roles'];
          let roles: { orgRFC: string; roles: [] } = {
            orgRFC: '',
            roles: []
          };
          if (accountRoles) {
            roles = JSON.parse(accountRoles)['organization'];
          }
          if (
            roles.orgRFC === null ||
            roles.orgRFC === undefined ||
            roles.orgRFC === ''
          ) {
            const confLogout = {
              authority: environment.b2cConfig.authority,
              onRedirectNavigate: () => false
            };
            this.msalService.logout(confLogout).subscribe(() => {
              this.router.navigate(['auth/onboarding'], {
                queryParams: {
                  [environment.onboarding365JoinQueryKey]: encodeURIComponent(
                    btoa(
                      JSON.stringify({
                        email: this.currentAzureAccount?.idTokenClaims['email'],
                        firstname:
                          this.currentAzureAccount?.idTokenClaims[
                            'preferred_username'
                          ]?.split(' ')[0],
                        lastname:
                          this.currentAzureAccount?.idTokenClaims[
                            'preferred_username'
                          ]?.split(' ')[1]
                      })
                    )
                  )
                }
              });
            });
          }
        }
      })
      .catch((error) => {
        console.error('msalBroadcastService error', error);
        return error;
      });
  }

  login(): void {
    this.msalService.loginRedirect();
  }

  async loginRedirect(path: string) {
    await this.msalService.instance.handleRedirectPromise();
    const accounts = this.msalService.instance.getAllAccounts();

    if (accounts || accounts.length === 0) {
      this.msalService.instance.loginRedirect({
        scopes: [],
        onRedirectNavigate: (url) => {},
        redirectStartPage: path
      });
    }
  }

  logout(): Observable<void> {
    const config = this.getConfigStorage();
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.setConfigStorege(config);
    localStorage.setItem('setClientStrategy', SetClientStrategy.FIRST_LOGIN);
    return this.msalService.logoutRedirect({
      authority: environment.b2cConfig.authority
    });
  }

  isLoggedIn(): boolean {
    return this.getUserInfo() !== null;
  }

  getUserInfo(): AccountInfo {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
    }
    return activeAccount;
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  getToken(forceRefresh: boolean = false, defaultClientId?: string): Observable<string | null> {
    this.checkAndSetActiveAccount();
    const account = this.getUserInfo();
    return this.msalService
      .acquireTokenSilent({
        scopes: environment.b2cConfig.scopes,
        account,
        tokenQueryParameters: {
          organization: defaultClientId
        },
        forceRefresh
      })
      .pipe(
        map((auth) => {
          this.msalService.instance.setActiveAccount(auth.account);
          return auth.accessToken;
        }),
        catchError((err) => {
          return of(err);
        })
      );
  }

  getConfigStorage() {
    return window.localStorage.getItem('config');
  }

  setConfigStorege(config) {
    window.localStorage.setItem('config', config);
  }
}
