import { IInfo, IUser, IDepartmentReceivers } from '../models/users.interface';

import { IAPIResponseError } from '@literax/models/http/api/error.model';
import { IPagination } from '@literax/models/pagination.model';
import { IProcess } from '@literax/components/platform/profiles/types/user-ptofiles';
import { IProfile } from '../../profiles/models/profiles';
import { UserKindEnum } from '../enums/user-kind.enum';

export interface ICLientUsersState {
  users: IUser[];
  info: IInfo;
  userIdEdit: number;
  errorLogin: IAPIResponseError;
  errors: {};
  erroRegister: IAPIResponseError;
  sortColumn: string;
  userKind: UserKindEnum;
  profiles: IProfile[];
  dashboard: IProcess;
  pagination: IPagination;
  departmentReceivers: IDepartmentReceivers[];
}

export const initialClientUserState: ICLientUsersState = {
  users: [],
  info: null,
  userIdEdit: null,
  errors: {},
  errorLogin: {
    error: {
      title: '',
      status: null,
      detail: '',
      code: null,
    },
    success: null,
  },
  erroRegister: {
    error: {
      title: '',
      status: null,
      detail: '',
      code: null,
    },
    success: null,
  },
  sortColumn: 'id:desc',
  userKind: null,
  profiles: [],
  dashboard: null,
  pagination: {
    currentPage: 1,
    pageSize: 10,
    total_count: null,
    total_pages: null
  },
  departmentReceivers: []
};