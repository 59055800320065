<div class="container">
    <div class="modal-title d-flex justify-content-between">
        <h5 class="mt-3 title">
            {{ title | translate }}
        </h5>
        
        <button type="button">
            <i class="fal fa-times"></i>
        </button>
    </div>
    <div class="modal-body row">
        <literax-stepper>
            <ng-container *ngIf="imagePaths">
                <literax-step *ngFor="let imagePath of imagePaths">
                    <div class="col-12">
                        <a class="col-1" literaxStepperPrevious *ngIf="imagePaths.length > 1">
                            <i class="fal fa-chevron-circle-left fa-3x"></i>
                        </a>
                        <img class="view-image-img" [src]="imagePath">
                        <a class="col-1" literaxStepperNext *ngIf="imagePaths.length > 1">
                            <i class="fal fa-chevron-circle-right fa-3x"></i>
                        </a>
                    </div>
                </literax-step>
            </ng-container>
            <literax-step *ngIf="videoPath">
                <div class="col-12">
                    <video [src]="videoPath"></video>
                </div>
            </literax-step>
        </literax-stepper>
    </div>
</div>