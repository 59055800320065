import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { dataURItoBlob } from '@literax/utils/signature';
import SignaturePad from '@literax/utils/signature_pad/signature_pad';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  })
export class DrawerComponent
  implements OnInit, AfterViewInit
{
  canvasElement: SignaturePad;
  currentDraw: string;
  @Output() signatureDone: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('signaturePad', { static: true, read: ElementRef })
  signaturePad: ElementRef<HTMLCanvasElement>;

  constructor(private elementRef: ElementRef<HTMLElement>) {}


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCanvasRatio();
    this.canvasElement.clear();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setCanvasRatio();
    }, 100);
  }

  ngOnInit(): void {
    this.canvasElement = new SignaturePad(this.signaturePad.nativeElement);
    this.canvasElement.addEventListener('endStroke', () => {
      this.currentDraw = this.canvasElement.toDataURL();
      this.signatureDone.emit(this.currentDraw);
    });

    this.canvasElement.addEventListener('clearData', () => {
      this.currentDraw = '';
    });
  }

  setCanvasRatio(): void {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.signaturePad.nativeElement.width =
      this.signaturePad.nativeElement.offsetWidth * ratio;
    this.signaturePad.nativeElement.height =
      this.signaturePad.nativeElement.offsetHeight * ratio;
    this.signaturePad.nativeElement.getContext('2d').scale(ratio, ratio);
  }
}
