import {
  IFreeSignatureState,
  initialFreeSignatureState
} from './free-signature.state';
import { createReducer, on, Action } from '@ngrx/store';
import * as freeSignatureActions from './free-signature.actions';

const signature = createReducer(
  initialFreeSignatureState,
  on(freeSignatureActions.CreateSignatureSuccess, (state, action) => ({
    ...state,
    signatureResult: action.payload,
    errors: null
  })),
  on(freeSignatureActions.CreateSignatureError, (state, action) => ({
    ...state,
    signatureResult: null,
    errors: action.payload.error.error
  })),
  on(freeSignatureActions.createSimpleSignatureError, (state, action) => ({
    ...state,
    signatureResult: null,
    errors: action.payload.error.error
  })),
  on(freeSignatureActions.ClearSignatureResult, (state, action) => ({
    ...state,
    signatureResult: null,
    errors: null
  })),
  on(freeSignatureActions.ShowSignatureSuccess, (state, action) => ({
    ...state,
    signatureResult: action.payload,
    errors: null
  })),
  on(freeSignatureActions.ShowSignatureError, (state, action) => ({
    ...state,
    showSignatureResult: null,
    errors: action.payload.error.error
  }))
);
export function freeSignatureReducer(
  state: IFreeSignatureState,
  action: Action
) {
  return signature(state, action);
}
