import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { IAppState } from '@literax/store';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { environment } from '@environments/environment';


/**
 * Not found component displayed when the user enters an invalid url request
 */
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-rejected',
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.scss'],
})
export class RejectedComponent implements OnDestroy {
  link = environment.domain + '/auth/registration';
  error$ = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.errorDocument)
  );

  constructor(private store: Store<IAppState>) {}

  ngOnDestroy(): void {}
}
