import {
  ButtonAction,
  CONFIG_BUTTONS,
  FLOW,
} from '@literax/utils/buttons-bar-configs';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store, select } from '@ngrx/store';

import { BreakpointObserver } from '@angular/cdk/layout';
import { IAppState } from '@literax/store';
import { IUser } from '@literax/models/user.model';
import { filter, tap } from 'rxjs/operators';

import { ETransactionType } from '@literax/enums/document.enum';
import { WorkspaceSelectors } from '@literax/modules/documents/store/workspace/workspace.selectors';
import { IDocumentResponse } from '@literax/models/document.model';
import { combineLatest, Observable } from 'rxjs';
import { IAttachment } from '@literax/models/attachment.model';
import { selectCurrentUser } from '@literax/modules/auth/store/auth.selectors';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-buttons-bar-v2',
  templateUrl: './buttons-bar-v2.component.html',
  styleUrls: ['./buttons-bar-v2.component.scss'],
})
export class ButtonsBarV2Component implements OnInit, OnDestroy {
  @Input() document: IDocumentResponse;
  @Input() user: IUser;
  @Input() attachment: IAttachment;
  @Input() recordFilled: boolean;
  @Input() showBack: boolean;

  @Input() onlyofficePresent = false;

  @Output() action: EventEmitter<ButtonAction> = new EventEmitter();

  currentDocumentTransaction: ETransactionType;
  currentDocumentStatus: string;
  currentDocumentActions: string[];

  buttons: {
    left: ButtonAction[];
    right: ButtonAction[];
  } = { left: [], right: [] };

  isMobileDevice: boolean;

  documentTransaction$: Observable<ETransactionType> = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.selectDocumentTransactionType)
  );

  document$: Observable<IDocumentResponse> = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.selectDocument),
    tap((document) => (this.document = document))
  );

  recordFilled$: Observable<boolean> = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.selectRecordState)
  );

  currentUser$: Observable<any> = this.store.pipe(
    untilDestroyed(this),
    select(selectCurrentUser)
  );

  isPromissoryNote$: Observable<boolean> = this.store.pipe(
    untilDestroyed(this),
    select(WorkspaceSelectors.selectIsPromissoryNote)
  );

  constructor(
    private store: Store<IAppState>,
    public breakpointObserver: BreakpointObserver
  ) {
    this.isMobileDevice =
      this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    combineLatest([this.document$, this.currentUser$, this.recordFilled$])
      .pipe(untilDestroyed(this), filter(([document, user, record]) => !!document))
      .subscribe(([document, currentUser, recordFilled]) => {
        this.recordFilled = recordFilled;
        this.user = currentUser;
        this.generateButtons(document);
      });
  }

  generateButtons(document: IDocumentResponse): void {
    if (document) {
      const currentStatus = document.status.key;
      const buttons = [
        ...FLOW[ETransactionType[document.transactionType].toLowerCase()],
        ...CONFIG_BUTTONS,
      ].filter((flowAction) => {
        if (Array.isArray(flowAction.status)) {
          return flowAction.status.some(
            (status) => status === currentStatus || status === '*'
          );
        } else {
          return (
            flowAction.status === currentStatus || flowAction.status === '*'
          );
        }
      });
      if (buttons.length >= 1) {
        this.buttons = { left: [], right: [] };
        buttons.forEach((button) => {
          if (Array.isArray(button.action)) {
            button['withActions'] = true;
            Array.prototype.forEach.call(button.action, (btn) => {
              this.evalConditions(btn);
            });
          }

          this.appendToButtons(button);
        });
      }
    }
  }

  evalConditions(button: ButtonAction): ButtonAction {
    if (button.conditions) {
      // this.store
      //   .pipe(
      //     untilDestroyed(this),
      //     select(WorkspaceSelectors.selectAttachments),
      //     filter((attachments) => attachments.length > 0)
      //   )
      //   .subscribe((attachments) => {
          Object.entries(button.conditions).forEach(([key, func]) =>
            func(
              this.document,
              this.attachment,
              this.user,
              button,
              {
                isConfig: this.showBack,
                isRecordFilled: this.recordFilled,
                isOnlyOfficeVisible: this.onlyofficePresent,
              }
            )
          );
        // });
    }
    return button;
  }

  appendToButtons(button: ButtonAction): void {
    this.evalConditions(button);
    this.buttons[button.position].push(button);
    this.buttons.right = this.movePrimarysToEnd(this.buttons.right);
  }

  movePrimarysToEnd(buttons: ButtonAction[]): ButtonAction[] {
    const buttonsPrimary = buttons.filter(
      (button) => button.class && button.class === 'primary'
    );
    const buttonsSecondary = buttons.filter(
      (button) => !button.hasOwnProperty('class')
    );
    return [...buttonsSecondary, ...buttonsPrimary];
  }
}
