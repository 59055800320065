import * as documentActions from './document.actions';
import * as positionSignature from '@literax/modules/documents/store/signature-position/singature-position.actions';

import { Action, createReducer, on } from '@ngrx/store';
import {
  IDocument,
  ILegalRepresentative,
} from '@literax/models/http/document/document.model';
import {
  IDocumentState,
  ISignRequest,
  ISignaturePosition,
  initialDocumentState,
} from './document.state';

import { EAttachmentKind } from '@literax/enums/attachment-kind.enum';
import {
  EParticipantTypes,
  ETypeDocConsult,
} from '@literax/enums/document.enum';
import { IAPISignRequest } from '@literax/models/http/api/sign-request/sign-request.model';
import { IAttachment } from '@literax/models/http/attachment.model';
import { checkAttachmentSuccess } from '../attachment/attachment.actions';
import { DocumentListType } from '../../const/flows.conts';

export const initialState = initialDocumentState;

function refreshCurrentDocument(
  currentDocument: IDocument,
  newDocument: IDocument
): IDocument {
  if (currentDocument?.id !== newDocument?.id) {
    return newDocument;
  }
  const tmp = { ...newDocument, attachments: [...newDocument.attachments] };

  tmp.attachments = newDocument.attachments.map((element: IAttachment) => {
    const attachment = currentDocument.attachments.find(
      (currentAttachment: IAttachment) => element.id === currentAttachment.id
    );
    if (attachment?.updated_at === element?.updated_at) {
      return attachment;
    }
    return element;
  });
  return tmp;
}

function updateSignRequest(
  requests: IAPISignRequest[],
  request: IAPISignRequest
): IAPISignRequest[] {
  const requestList = requests.map((requestItem) =>
    requestItem?.id === request?.id ? request : requestItem
  );
  return requestList;
}

function removeSignRequest(
  requests: IAPISignRequest[],
  request: IAPISignRequest
): IAPISignRequest[] {
  const requestList = requests.filter(
    (requestItem) => requestItem.id !== request.id
  );
  return requestList;
}
function updatePositionLegalRepresentative(
  legalOriginal: ILegalRepresentative[],
  signerId: number,
  newPosition: ISignaturePosition
) {
  const newLegalRepresentative: ILegalRepresentative[] = [];
  legalOriginal.forEach((legalPerson: ILegalRepresentative) => {
    if (
      legalPerson.legal_representatives.filter((e) => e.id === signerId)
        .length > 0
    ) {
      const arrayLegalRepresentatives = legalPerson.legal_representatives;
      const signersLegalRepresentatives = updatePosition(
        arrayLegalRepresentatives,
        signerId,
        newPosition
      );
      newLegalRepresentative.push({
        ...legalPerson,
        legal_representatives: signersLegalRepresentatives,
      });
    } else {
      newLegalRepresentative.push(legalPerson);
    }
  });
  return newLegalRepresentative;
}
function updatePosition(
  signOriginal: ISignRequest[],
  signerId: number,
  newPosition: ISignaturePosition
) {
  const arraySigner: ISignRequest[] = [];
  const newArrayPosition: ISignaturePosition[] = [];
  signOriginal.forEach((signer) => {
    if (signer.id === signerId) {
      signer.signature_positions.forEach((position) => {
        if (position.attachment_id === newPosition.attachment_id) {
          newArrayPosition.push(newPosition);
        } else {
          newArrayPosition.push(position);
        }
      });
      const newSigner = {
        ...signer,
        signature_positions: newArrayPosition,
      };
      arraySigner.push(newSigner);
    } else {
      arraySigner.push(signer);
    }
  });

  return arraySigner;
}

function updateAttachments(
  attachments: IAttachment[],
  newAttachment: IAttachment
) {
  const localAttachmen = { ...newAttachment };

  const fileText = attachments.filter(
    (attach) => attach.id === localAttachmen.id
  )[0]?.text;

  localAttachmen.text = fileText;

  const newArrayAttachment =
    attachments.filter((attach) => attach.id !== localAttachmen.id) || [];

  newArrayAttachment.push(localAttachmen);
  newArrayAttachment.sort((a: IAttachment, b: IAttachment) => a.id - b.id);
  return newArrayAttachment;
}

const documentReducers = createReducer(
  initialState,
  on(documentActions.GetDocuments, (state, action) => {
    const queryParameters = {
      ...state.queryParameters,
      ...action.queryParameters,
    };
    return { ...state, queryParameters };
  }),
  on(documentActions.GetDocumentsSuccess, (state, action) => {
    const documents = action.documents;
    const pagination = { ...state.pagination, ...action.pagination };
    return { ...state, documents, pagination };
  }),
  on(documentActions.CleanSelectedDocumentState, (state, _) => ({
    ...state,
    selectedDocument: null,
    selectAttachment: null,
    attachments: [],
  })),
  on(documentActions.UpdateSignRequestsSuccess, (state, action) => {
    const signRequest = action.payload.sign_request;
    const userSigner = updateSignRequest(
      state.selectedDocument.user_signer as any,
      signRequest
    );
    const signRequests = updateSignRequest(
      state.selectedDocument.sign_requests as any,
      signRequest
    );
    const observers = updateSignRequest(
      state.selectedDocument.observers as any,
      signRequest
    );
    return {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        sign_requests: signRequests,
        user_signer: userSigner,
        observers,
      },
    };
  }),
  on(
    documentActions.GetDocumentSuccess || documentActions.UpdateLocalDocument,
    (state, action) => {
      const newDocument = refreshCurrentDocument(
        state.selectedDocument,
        action.payload as IDocument
      );
      return {
        ...state,
        selectedDocument: newDocument,
        facialRecognitionRequired: newDocument.facematch,
        identificationRequired:
          newDocument?.user_signer[0]?.id_document_request,
        selectAttachment: newDocument?.attachments?.filter((a) => a.primary)[0],
        attachments: newDocument.attachments,
        approvers: newDocument?.sign_requests?.filter(
          (participant) =>
            participant.signatory_role.name === EParticipantTypes.APPROVER
        ),
        reviewers: newDocument?.sign_requests?.filter(
          (participant) =>
            participant.signatory_role.name === EParticipantTypes.REVIEWER
        ),
      };
    }
  ),
  on(documentActions.GetViewDocumentSignaturesSuccess, (state, action) => {
    return {
      ...state,
      selectedDocumentViewSignature: action.payload,
    };
  }),
  on(documentActions.GetDocumentError, (state, action) => ({
    ...state,
    getDocumentError: action.payload,
  })),
  on(documentActions.DeleteDocumentError, (state, action) => ({
    ...state,
    deleteDocumentError: action.payload,
  })),
  on(documentActions.CleanDocumentFormStates, (state, _) => ({
    ...state,
    lastDocument: null,
    uploadErrors: { success: false, error: null },
  })),
  // TODO: Remove suffix V3 Verificar si ocupamos la propiedad del state que se actualiza
  on(documentActions.CreateDocumentV3Success, (state, action) => ({
    ...state,
    lastDocumentV3: action.payload,
    uploadErrors: {
      ...state.uploadErrors,
      success: true,
    },
  })),
  // TODO: Remove suffix V3
  on(documentActions.CreateDocumentV3Error, (state, action) => ({
    ...state,
    uploadErrors: action.payload,
  })),
  // ! Deprecated: change reducer for CreateDocumentV3Success
  on(documentActions.CreateDocumentSuccess, (state, action) => ({
    ...state,
    lastDocument: action.payload.document,
    uploadErrors: {
      ...state.uploadErrors,
      success: action.payload.success,
    },
  })),
  // ! Deprecated: change reducer for CreateDocumentV3Error
  on(documentActions.CreateDocumentError, (state, action) => ({
    ...state,
    uploadErrors: action.payload,
  })),
  on(documentActions.CleanDocumentsState, (state, _) => ({
    ...state,
    documents: [],
    currentPage: 1,
    pagination: null,
    queryParameters: {
      ...state.queryParameters,
      pageNumber: 1,
      pageSize: 10,
      show: 0,
      sortField: null,
    },
  })),
  on(documentActions.GetDocumentAttachmentSuccess, (state, action) => {
    const attachments = state.selectedDocument?.attachments?.map(
      (attachment: IAttachment) => {
        return attachment?.id === action.payload?.id &&
          attachment?.text == null &&
          attachment.kind !== EAttachmentKind.DOCX
          ? action.payload
          : attachment;
      }
    );

    return {
      ...state,
      selectAttachment: attachments.find(
        (attachment) => attachment.id === action.payload.id
      ),
      attachments,
      selectedDocument: { ...state.selectedDocument, attachments },
      getAttachmentError: null,
    };
  }),
  on(documentActions.ChangeAttachmentSelect, (state, action) => ({
    ...state,
    selectAttachment: action.payload,
  })),
  on(documentActions.GetDocumentAttachmentError, (state, action) => ({
    ...state,
    getAttachmentError: action.payload,
  })),
  on(documentActions.ChangeSort, (state, action) => ({
    ...state,
    sortColumn: action.payload,
    documents: [],
    currentPage: 1,
    pagination: null,
  })),
  on(
    positionSignature.signaturePositionActions.UpdateSignaturePosition,
    (state, action) => ({ ...state, signerCoordinates: action.payload })
  ),
  on(
    positionSignature.signaturePositionActions.UpdateSignaturePositionSuccess,
    (state, action) => {
      const newPosition: ISignaturePosition = action.payload[0];
      let newPositionSignature = {};
      state?.signerCoordinates.sign_request_id.forEach((id) => {
        const signerIdChange = id;
        if (
          state.selectedDocument.sign_requests.find(
            (signer) => signer.id === signerIdChange
          )
        ) {
          const signOriginal = state.selectedDocument.sign_requests.slice();
          const array = updatePosition(
            signOriginal,
            signerIdChange,
            newPosition
          );
          newPositionSignature = {
            ...state.selectedDocument,
            sign_requests: array,
          };
        }
        state.selectedDocument.legal_representative.forEach(
          (legal: ILegalRepresentative) =>
            legal.legal_representatives.find((legalPerson) => {
              if (legalPerson.id === signerIdChange) {
                const person =
                  state.selectedDocument.legal_representative.slice();
                const array = updatePositionLegalRepresentative(
                  person,
                  signerIdChange,
                  newPosition
                );
                newPositionSignature = {
                  ...state.selectedDocument,
                  legal_representative: array,
                };
              }
            })
        );
      });
      return { ...state, selectedDocument: newPositionSignature };
    }
  ),
  on(documentActions.GetNavbarDocumentSuccess, (state, action) => {
    return { ...state, navbarDocument: action.payload };
  }),

  on(documentActions.SetOnlyOfficeKey, (state, action) => {
    const selectedAttachmentId = state.selectAttachment.id;

    const attachments = [...state.selectedDocument.attachments].map((a) => {
      return a.id === selectedAttachmentId
        ? { ...a, onlyoffice_key: action.payload }
        : a;
    });
    const newState = {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        attachments,
      },
      selectAttachment: {
        ...state.selectAttachment,
        onlyoffice_key: action.payload,
      },
    };
    return newState;
  }),
  on(documentActions.DocumentsHidden, (state, action) => {
    return { ...state, hidden: action.payload };
  }),
  on(documentActions.DocumentsHiddenTypeSuccess, (state, action) => {
    return { ...state, currentPage: 1 };
  }),
  on(documentActions.DocumentMarkAsSuccess, (state, action) => {
    return {
      ...state,
      selectedDocument: refreshCurrentDocument(
        state.selectedDocument,
        action.payload as IDocument
      ),
    };
  }),
  on(documentActions.GetCancellationReasonsSuccess, (state, action) => {
    return {
      ...state,
      commentRejected: action.payload,
    };
  }),
  on(documentActions.SaveDateGenerateSuccess, (state, action) => {
    return {
      ...state,
      currentPage: 1,
    };
  }),
  on(checkAttachmentSuccess, (state, action) => {
    return {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        attachments: updateAttachments(
          state?.selectedDocument?.attachments,
          action.payload
        ),
      },
    };
  }),
  on(documentActions.SetDocumentAttachmentBase64Data, (state, action) => {
    const attachments: IAttachment[] = [...state.attachments].map(
      (attachment) => {
        if (attachment.id === action.payload.attachmentId) {
          return { ...attachment, text: action.payload.data };
        } else {
          return attachment;
        }
      }
    );

    const selectedAttachment = {
      ...state.selectAttachment,
      text: action.payload.data,
    };
    return {
      ...state,
      selectedDocument: {
        ...state.selectedDocument,
        attachments: [...attachments],
      },
      attachments: [...attachments],
      selectAttachment: selectedAttachment,
    };
  }),
  on(documentActions.SetLastUploadedAttachment, (state, action) => {
    return {
      ...state,
      lastUploadedAttachment: action.payload,
    };
  }),
  on(documentActions.ClearLastUploadedAttachment, (state, action) => ({
    ...state,
    lastUploadedAttachment: null,
  })),

  on(documentActions.GetPendingMessagesSuccess, (state, action) => ({
    ...state,
    pending_seend: action.payload,
  })),
  on(documentActions.ClearDocumentAttachmentsBase64Data, (state, action) => {
    const attachments: IAttachment[] = [...state.attachments].map(
      (attachment) => ({ ...attachment, text: null })
    );
    const selectedDocument = {
      ...state.selectedDocument,
      attachments: [...attachments],
    };
    return {
      ...state,
      selectedDocument,
      attachments,
    };
  }),
  on(documentActions.CleanSelectDocumentViewSignatureState, (state) => ({
    ...state,
    selectedDocumentViewSignature: null,
  })),
  on(documentActions.SetFlowId, (state, action) => ({
    ...state,
    flowId: action.payload.id,
    flow: action.payload.flow,
    queryParameters: {
      ...state.queryParameters,
      pageNumber: 1,
    },
  })),
  on(documentActions.SetProcessType, (state, action) => ({
    ...state,
    type: action.payload.type,
    queryParameters: {
      ...state.queryParameters,
      pageNumber: 1,
      type: action.payload.type as ETypeDocConsult,
    },
  })),
  on(documentActions.SetFilterParams, (state, action) => ({
    ...state,
    filterParams: action.payload.filters,
    queryParameters: {
      ...state.queryParameters,
      pageNumber: 1,
      filterFields: action.payload.filters,
    },
  })),
  on(documentActions.GetDocumentAcumulatedStatussesSuccess, (state, action) => {
    const flowAccumulations = Object.values(action.payload).find(
      (v) => v !== null
    );
    const acumulatedStatuses = Object.values(flowAccumulations)?.filter(
      (accumulation) =>
        state.type === 'projects'
          ? accumulation?.key === 'draft'
          : accumulation?.key !== 'draft'
    );
    return { ...state, acumulatedStatuses };
  }),
  on(documentActions.ClearDocumentListsState, (state, action) => ({
    ...state,
    acumulatedStatuses: undefined,
    filterParams: [],
    flowId: initialDocumentState.flowId,
    type: initialDocumentState.type,
    flow: initialDocumentState.flow,
  })),
  on(documentActions.SetFlowIdTabSuccess, (state, action) => {
    const flowAccumulations = Object.values(action.payload).find(
      (v) => v !== null
    );
    const acumulatedStatuses = Object.values(flowAccumulations)?.filter(
      (accumulation) =>
        state.type === 'projects'
          ? accumulation?.key === 'draft'
          : accumulation?.key !== 'draft'
    );
    return { ...state, acumulatedStatusesTab:acumulatedStatuses };
  }),
);

export function reducer(state: IDocumentState, action: Action) {
  return documentReducers(state, action);
}
