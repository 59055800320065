import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { EOfficialIdentification } from '@literax/enums/official-identification.enum';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-identity-validation-form',
  templateUrl: './identity-validation-form.component.html',
  styleUrls: ['./identity-validation-form.component.scss']
})
export class IdentityValidationFormComponent implements OnInit {
  @Input() faceMatch = false;
  @Input() documentRequest: EOfficialIdentification = EOfficialIdentification.NO_REQUESTED;

  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataIdValidation: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('idValidationAlert') private idValidationAlertElement: ElementRef;
  @ViewChild('recordBiometric') private recordBiometricElement: ElementRef;

  public get officialIdentification(): typeof EOfficialIdentification {
    return EOfficialIdentification;
  }

  identityValidationForm: FormGroup;
  frontFileLabel: string;
  backFileLabel: string;

  constructor(
    private formBuilder: FormBuilder
  ) { 
    this.createForm();
  }

  ngOnInit(): void {
    if (this.faceMatch) {
      this.identityValidationForm.addControl(
        'video',
        new FormControl(null, Validators.required)
      )
    }
    if (this.documentRequest === EOfficialIdentification.ID_DOCUMENT) {
      this.frontFileLabel = 'IDENTITY_VALIDATION.FORM.OFFICIAL_ID_FRONT';
      this.backFileLabel = 'IDENTITY_VALIDATION.FORM.OFFICIAL_ID_BACK';
    } else if (this.documentRequest === EOfficialIdentification.INE) {
      this.frontFileLabel = 'IDENTITY_VALIDATION.FORM.INE_FRONT';
      this.backFileLabel = 'IDENTITY_VALIDATION.FORM.INE_BACK';
    }
  }

  createForm() {
    this.identityValidationForm = this.formBuilder.group({
      id_image: [null, [Validators.required]],
      id_image_back: [null, [Validators.required]]
    });
  }

  removeAlert() {
    this.idValidationAlertElement.nativeElement.remove();
  }

  sendBiometrics(event) {
    const finalFile = new File([event.detail.file], "biometric.webm", { type: 'video/mp4; codecs="avc1.424028, mp4a.40.2"' });
    this.identityValidationForm.controls['video'].setValue(finalFile);
  }

  sendData(data) {
    this.dataIdValidation.emit(data);
  }

}
