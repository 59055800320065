import { IUserProfileDashboard } from '../types/user-ptofiles';
import { createAction, props } from '@ngrx/store';

import { IPaginationV3 } from '@literax/models/pagination.model';
import { IProfile } from '@literax/models/profile.model';
import { IAPIResponseError } from '@literax/models/http/api/error.model';
import {
  IProfilePatchRequest,
  IProfileRequest,
  IQueryParameters,
} from '../models/interfaces/profile.interface';

const ProfilesActions = {
  //V3
  ChangeTab: '[Profiles] ChangeTab',

  GetListProfiles: '[Profiles] GetListProfiles',
  GetListProfilesSuccess: '[Profiles] GetListProfilesSuccess',
  GetListProfilesError: '[Profiles] GetListProfilesError',

  GetProfile: '[Profiles] GetProfile',
  GetProfileSuccess: '[Profiles] GetProfileSuccess',
  GetProfileError: '[Profiles] GetProfileError',

  PostProfile: '[Profiles] PostProfile',
  PostProfileSuccess: '[Profiles] PostProfileSuccess',
  PostProfileError: '[Profiles] PostProfileError',

  PatchProfile: '[Profiles] PatchProfile',
  PatchProfileSuccess: '[Profiles] PatchProfileSuccess',
  PatchProfileError: '[Profiles] PatchProfileError',

  DeleteProfile: '[Profiles] DeleteProfile',
  DeleteProfileSuccess: '[Profiles] DeleteProfileSuccess',
  DeleteProfileError: '[Profiles] DeleteProfileError',

  ProcessProfile: '[Profiles] ProcessProfile',
  ProcessProfileSuccess: '[Profiles] ProcessProfileSuccess',
  ProcessProfileError: '[Profiles] ProcessProfileError',

  ExportListProfiles: '[Profiles] ExportListProfiles',
  ExportListProfilesSuccess: '[Profiles] ExportListProfilesSuccess',
  ExportListProfilesError: '[Profiles] ExportListProfilesError',
};

//#region READ
export const GetUsersProfilesDashboard = createAction(
  '[User Profiles] GetUsersProfilesDashboard'
);

export const GetUsersProfilesDashboardSuccess = createAction(
  '[User Profiles] GetUsersProfilesDashboardSuccess',
  props<{ payload: IUserProfileDashboard }>()
);

//V3
export const ChangeTab = createAction(
  ProfilesActions.ChangeTab,
  props<{ status?: string }>()
);

export const GetListProfiles = createAction(
  ProfilesActions.GetListProfiles,
  props<{ payload?: IQueryParameters }>()
);

export const GetListProfilesSuccess = createAction(
  ProfilesActions.GetListProfilesSuccess,
  props<{ payload: { profiles: IProfile[]; pagination: IPaginationV3 } }>()
);

export const GetListProfilesError = createAction(
  ProfilesActions.GetListProfilesError,
  props<{ payload: IAPIResponseError }>()
);

export const GetProfile = createAction(
  ProfilesActions.GetProfile,
  props<{ profileId: number }>()
);

export const GetProfileSuccess = createAction(
  ProfilesActions.GetProfileSuccess,
  props<{ profile: IProfile }>()
);

export const GetProfileError = createAction(
  ProfilesActions.GetProfileError,
  props<{ payload: IAPIResponseError }>()
);

export const PostProfile = createAction(
  ProfilesActions.PostProfile,
  props<{ profile: IProfileRequest }>()
);

export const PostProfileSuccess = createAction(
  ProfilesActions.PostProfileSuccess,
  props<{ profile: IProfile }>()
);

export const PostProfileError = createAction(
  ProfilesActions.PostProfileError,
  props<{ payload: IAPIResponseError }>()
);

export const PatchProfile = createAction(
  ProfilesActions.PatchProfile,
  props<{ profileId: number; path: IProfilePatchRequest[] }>()
);

export const PatchProfileSuccess = createAction(
  ProfilesActions.PatchProfileSuccess,
  props<{ profile: IProfile }>()
);

export const PatchProfileError = createAction(
  ProfilesActions.PatchProfileError,
  props<{ payload: IAPIResponseError }>()
);

export const DeleteProfile = createAction(
  ProfilesActions.DeleteProfile,
  props<{ profileId: number }>()
);

export const DeleteProfileSuccess = createAction(
  ProfilesActions.DeleteProfileSuccess,
  props<{ message: string }>()
);

export const DeleteProfileError = createAction(
  ProfilesActions.DeleteProfileError,
  props<{ payload: IAPIResponseError }>()
);

export const ProcessProfile = createAction(
  ProfilesActions.ProcessProfile,
  props<{ action: string; profileId: number }>()
);

export const ProcessProfileSuccess = createAction(
  ProfilesActions.ProcessProfileSuccess,
  props<{ profile: IProfile }>()
);

export const ProcessProfileError = createAction(
  ProfilesActions.ProcessProfileError,
  props<{ payload: IAPIResponseError }>()
);


export const ExportListProfiles = createAction(
  ProfilesActions.ExportListProfiles
);

export const ExportListProfilesSuccess = createAction(
  ProfilesActions.ExportListProfilesSuccess
);

export const ExportListProfilesError = createAction(
  ProfilesActions.ExportListProfilesError,
  props<{ payload: IAPIResponseError }>()
);
