import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[onDragEnter]'
})
export class OnDragEnterDirective {
  @Output() onDragEnter = new EventEmitter();

  @HostListener('dragenter', ['$event']) public onDragEnterDirective(
    event: any
  ) {
    event.stopPropagation();
    event.preventDefault();
    this.onDragEnter.emit(event);
  }
}
