import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ICLientUsersState } from './users.state';
import { IClientState } from '@literax/modules/configurations/store/client/client.state';
import { PermissionsConstants } from '../../../../modules/auth/constans/permissions.constants';
import { ServicesConstants } from '../../../../modules/auth/constans/services.constants';

export const getUserState = createFeatureSelector<ICLientUsersState>('users');

const selectClients = createFeatureSelector<IClientState>('clients');

export const getLookFeel = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.info?.lookFeelConfig
);

export const getSelectAll = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.users
);

export const getErrorLogin = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.errorLogin && state.errorLogin.error && state.errorLogin.error.title
);

export const getErrorRegistro = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.erroRegister &&
    state.erroRegister.error &&
    state.erroRegister.error.title
);

export const getErrorRegisterTitle = createSelector(
  getUserState,
  (state: ICLientUsersState) => {
    let errorsDetail: string[] = [];
    errorsDetail.push(state?.erroRegister?.error?.title);
    for (const errorsDetailKey in state?.erroRegister?.error.detail) {
      errorsDetail = [
        ...errorsDetail,
        state?.erroRegister?.error.detail[errorsDetailKey],
      ];
    }
    return errorsDetail;
  }
);

/*
wip: preparacion para modificacion de endpoint
export const getFormErrors = createSelector(getUserState, (state:ICLientUsersState) => state.errors);
:wip preparacion para modificacion de endpoint
*/
export const getErrorRegisterDetail = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.erroRegister &&
    state.erroRegister.error &&
    state.erroRegister.error.detail
);
export const getErrorLoginDetail = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state.errorLogin && state.errorLogin.error && state.errorLogin.error.detail
);

export const selectEmailError = createSelector(
  getErrorRegisterDetail,
  (state: any) => state && state.email
);

export const selectPasswordError = createSelector(
  getErrorRegisterDetail,
  (state: any) => state && state.password
);

export const selectRFCError = createSelector(
  getErrorRegisterDetail,
  (state: any) => state && state.rfc
);

export const selectLoginEmailError = createSelector(
  getErrorLoginDetail,
  (state: any) => state && state.email
);

export const selectLoginPasswordError = createSelector(
  getErrorLoginDetail,
  (state: any) => state && state.password
);

export const selectUserPermissions = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.info?.permissions
);

export const selectUserMainProfile = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.info?.mainProfile
);

export const selectUserProfiles = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.info?.assignedProfiles
);

export const isFirstLogin = createSelector(
  getUserState,
  (state: ICLientUsersState) => !!state?.info?.welcomeTourVisited
);

export const getClientSignatureType = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.info?.clientSignatureTypeId
);

export const getInfo = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.info
);

export const isRoleColaborator = createSelector(getUserState, (state: any) =>
  state.info?.permissions.some(
    (p) => p === PermissionsConstants.SHOW_CLIENT_USER
  )
);

export const isProfileVisible = createSelector(getUserState, (state) =>
  state.info?.permissions.some(
    (p) => p === PermissionsConstants.SHOW_USER_PROFILE
  )
);

export const isSignVisible = createSelector(getUserState, (state) =>
  state.info?.permissions.some((p) => p === PermissionsConstants.SHARE_DOCUMENT)
);

export const isCompanyVisible = createSelector(getUserState, (state) =>
  state.info?.permissions.some(
    (p) => p === PermissionsConstants.UPDATE_CLIENT_PROFILE
  )
);

export const getUserDefault = createSelector(
  getUserState,
  (state: ICLientUsersState) =>
    state && state.info && state.info.defaultClientId
);

export const userKind = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.userKind
);

export const GetCompanyPermissions = createSelector(
  getUserState,
  (state: ICLientUsersState) => {
    const permissions = {
      createCompany: state.info?.permissions.some(
        (p) => p === PermissionsConstants.CREATE_COMPANY
      ),
      updateCompany: state.info?.permissions.some(
        (p) => p === PermissionsConstants.UPDATE_COMPANY
      ),
      deleteCompany: state.info?.permissions.some(
        (p) => p === PermissionsConstants.DELETE_COMPANY
      ),
      showCompany: state.info?.permissions.some(
        (p) => p === PermissionsConstants.SHOW_COMPANY
      ),
    };
    return permissions;
  }
);

export const isApiIntegratorVisible = createSelector(getUserState, (state) =>
  state.info?.permissions.some(
    (p) => p === PermissionsConstants.SHOW_CONFIG_API
  )
);

export const GetUsersReceiversActive = createSelector(getUserState, (state) => {
  const users = [];
  state.users.forEach((user) => {
    if (
      user.status === 'active' &&
      user.receive_documents &&
      user.role.kind !== 'api_integrator'
    ) {
      users.push({
        value: user.id,
        label: user.full_name,
        department: user.department.name,
      });
    }
  });
  return users;
});

export const GetDefaultExpiryDays = createSelector(
  getUserState,
  (state) => state?.info?.defaultExpiryDays || 30
);

export const selectDefaultUser = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.info?.defaultClientId
);

export const selectUsersDashboard = createSelector(
  getUserState,
  (state: ICLientUsersState) => state?.dashboard?.flows
);

export const selectDepartmentReceivers = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.departmentReceivers
);

export const selectUserPermission = (permission: string) =>
  createSelector(getUserState, (state: ICLientUsersState) =>
    state?.info.permissions?.some((p) => p === permission)
  );

export const selectClientServices = createSelector(
  selectClients,
  (clientState: IClientState) => clientState.currentClient.services
);

export const isAppearanceVisible = createSelector(
  getUserState,
  selectClients,
  (state, clientState: IClientState) =>
    state.info?.permissions.some(
      (p) => p === PermissionsConstants.SHOW_CLIENT_PROFILE
    ) &&
    clientState.currentClient.services.some(
      (s) => s === ServicesConstants.LOOKANDFEEL_CONFIG
    )
);

export const isPersonalizationVisible = createSelector(
  getUserState,
  selectClients,
  (state, clientState: IClientState) =>
    state.info?.permissions.some(
      (p) => p === PermissionsConstants.SHOW_CLIENT_PROFILE
    ) &&
    (clientState.currentClient.services.some(
      (s) => s === ServicesConstants.LOOKANDFEEL_CONFIG
    ) ||
      clientState.currentClient.services.some(
        (s) => s === ServicesConstants.EMAIL_CONFIG
      ))
);

export const isTokenRSAVisible = createSelector(
  getUserState,
  selectClients,
  (state, clientState: IClientState) =>
    state.info?.permissions.some(
      (p) => p === PermissionsConstants.SHOW_CONFIG_API
    ) &&
    clientState.currentClient.services.some(
      (s) => s === ServicesConstants.TOKEN_RSA
    )
);

export const selectPaginationData = createSelector(
  getUserState,
  (state: ICLientUsersState) => state.pagination
);

export const selectUserInfo = createSelector(
  getUserState,
  //selectClients,
  (state: ICLientUsersState) => state?.info
);
