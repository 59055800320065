import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EFileTypes } from '@literax/components/platform/wizard-v3/enums/file-types.enum';


import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileInputComponent,
      multi: true,
    },
  ],
})
export class FileInputComponent implements OnInit, ControlValueAccessor {
  @Input() labelTag: string;
  @Input() buttonSelectTag: string;
  @Input() buttonChangeTag: string;
  @Input() fileTypes: string[];
  @Input() currentFileName: string;
  @Input() minimal: boolean;
  @Input() captureCamera = false;
  @Input() returnBase64 = false;
  @Output() fileType: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeBase64: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  fileName = '';
  // tslint:disable-next-line: variable-name
  private _base64 = '';
  // tslint:disable-next-line: variable-name
  private _file: File | null = null;
  // tslint:disable-next-line: ban-types
  onChange: Function = () => {};
  // tslint:disable-next-line: ban-types
  onTouched: Function = () => {};

  isDocumentUpload: boolean = false;
  fileTypeUpload: string = '';
  typeDocument: string = '';
  
  constructor(private host: ElementRef<HTMLInputElement>) {}

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    this.setFiles(event);
  }

  setFiles(files: FileList) {
    this._file = files && files.item(0);
    if (files.item(0) != null) {
      this.isDocumentUpload = true;
      this.fileName = this._file.name;
      this.readBase64(this._file);
      this.getTypeDocumentUpload();
    }
    this.currentButtonLabel();
  }

  get base64(): string {
    return this._base64;
  }

  get name(): string {
    return this.fileName;
  }

  currentButtonLabel() {
    return this.file === null ? this.buttonSelectTag : this.buttonChangeTag;
  }

  get file(): File {
    return this._file;
  }

  dropOverHandler(e: Event) {}
  dropHandler(e: Event) {}

  readBase64(file: File, name?: string): boolean {
    if (file === null) {
      return false;
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      this._base64 = reader.result.toString();
      this.returnBase64
        ? this.onChange({ file, base64: this._base64 })
        : this.onChange(file);
      this.changeBase64.emit(true);

      if (!!this.minimal) {
        this._file = null;
        this.fileName = null;
      }
    };

    reader.onerror = (error) => {};
    this.fileTypeUpload = file.type;
    this.fileType.emit(file.type);
    return true;
  }

  ngOnInit() {
    this.onChange(null);
  }

  acceptString(): string {
    return this.fileTypes && this.fileTypes.join(',');
  }

  writeValue(obj: any): void {
    this.host.nativeElement.value = null;
    this._file = null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getTypeDocumentUpload(){

      if(this.fileTypeUpload === EFileTypes.docx){
        this.typeDocument = 'docx';
      }else{
        this.typeDocument = 'pdf';
      }

  }

  updateDocumentUpload(){
    this.isDocumentUpload=false;
    this.fileName = '' ;
    this.currentFileName = '';
    this.changeBase64.emit(false);
  }
}
