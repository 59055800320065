import * as titleNavbarActions from '@literax/store/title-navbar/title-navbar.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  initialTitleNavbarState,
  ITitleNavbarState
} from './title-navbar.state';

export const initialState = initialTitleNavbarState;
const titleNavBarReducers = createReducer(
  initialState,
  on(titleNavbarActions.AddToStack, (state, action) => {
    let currentStack = [...state.stack];
    if (Array.isArray(action.payload)) {
      let incomingStack = action.payload.filter((incomingStackEl) =>
        currentStack.some(
          (currentStackEl) =>
            currentStackEl.title === incomingStackEl.title &&
            currentStackEl.color === incomingStackEl.color
        )
          ? false
          : incomingStackEl
      );

      return {
        ...state,
        stack: [...state.stack, ...incomingStack]
      };
    } else {
      return currentStack.some((stackEl) => stackEl === action.payload)
        ? state
        : { ...state, stack: [...state.stack, action.payload] };
    }
  }),

  on(titleNavbarActions.SetNavNodes, (state, action) => ({
    ...state,
    nav_nodes: { ...state.nav_nodes, ...action.payload }
  })),

  on(titleNavbarActions.ReplaceStack, (state, action) => ({
    ...state,
    stack: action.payload
  })),
  on(titleNavbarActions.ClearStack, (state) => ({
    ...state,
    stack: [],
    nav_nodes: { ...initialTitleNavbarState.nav_nodes }
  })),
  on(titleNavbarActions.RemoveFromStack, (state, action) => ({
    ...state,
    stack: [...state.stack.slice(0, action.payload)]
  }))
);
export function titleNavbarReducers(state: ITitleNavbarState, action: Action) {
  return titleNavBarReducers(state, action);
}
