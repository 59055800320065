import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  selector: '[onDragOver]'
})
export class OnDragOverDirective {
  @Output() onDragOver = new EventEmitter();

  @HostListener('dragover', ['$event']) public onDragOverDirective(event: any) {
    event.preventDefault();
    this.onDragOver.emit(event);
  }
}
