import { BaseService } from '@literax/services/base-config/base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '@literax/interfaces/service-response';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(private base: BaseService) {}

  getTerms(): Observable<ServiceResponse> {
    const path = `/terms_of_services/latest`;
    return this.base.get(path);
  }

  acceptTerms(): Observable<ServiceResponse> {
    const path = `/user_terms_of_services/`;
    return this.base.post(path, null);
  }
  
}