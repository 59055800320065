import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceResponse } from '@literax/interfaces/service-response';
import { SignService } from './sign.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TermsSignService {
  constructor(private base: SignService) {}

  getTerms(token: string): Observable<ServiceResponse> {
    const path = `/signatories/${environment.apiVersion}/terms_of_services/latest`;
    return this.base.get(path, token);
  }

  acceptTerms(
    signRequestId: number,
    documentId: number,
    token: string
  ): Observable<ServiceResponse> {
    const path = `/migr/api/${environment.apiVersionMigr}/documents/${documentId}/signrequests/${signRequestId}/terms_and_conditions/accept`;
    return this.base.post(path, {}, token);
  }
}
