import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { IDocumentResponse } from '@literax/models/document.model';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'literax-modal-send-signed-doc',
  templateUrl: './modal-send-signed-doc.component.html',
  styleUrls: ['./modal-send-signed-doc.component.scss'],
})
export class ModalSendSignedDocComponent implements OnInit {
  @Input() data: { document: IDocumentResponse };

  selectAllCheck = false;
  signers = [];
  observers = [];
  selectedCollaborators = [];
  collaboratorsModel = {};

  constructor(public modalRef: NzModalRef) {}

  ngOnInit() {
    this.data.document.signRequests.naturalPersons.map((x) => {
      const npIfo = {
        id: x.signRequestId,
        name: x.name,
      };
      this.signers.push(npIfo);
      this.collaboratorsModel[x.signRequestId] = false;
    });
    this.data.document.signRequests.legalPersons.map((x) => {
      const lpInfo = {
        id: x.signRequestId,
        name: x.name,
      };
      this.signers.push(lpInfo);
      this.collaboratorsModel[x.signRequestId] = false;
    });
    this.observers = this.data.document.signRequests.observers;

    this.toggleDisabledOkButton();
  }

  addRemoveCollaborator(id: number, event: EventEmitter<boolean>) {
    if (event) {
      this.selectedCollaborators.push(id);
    } else {
      const index = this.selectedCollaborators.indexOf(id);
      if (index !== 1) {
        this.selectedCollaborators.splice(index, 1);
      }
    }
    this.toggleDisabledOkButton();
  }

  selectAll(event: EventEmitter<boolean>) {
    const keys = Object.keys(this.collaboratorsModel);
    this.selectedCollaborators = [];
    for (const key of keys) {
      this.collaboratorsModel[key] = event;
      if(event){
        this.selectedCollaborators.push(key);
      }
    }
    this.toggleDisabledOkButton();
  }

  ok(): void {
    this.modalRef.triggerOk();
  }

  toggleDisabledOkButton(){
    this.modalRef.getConfig().nzOkDisabled = this.selectedCollaborators.length === 0;
  }
}
